import React, { useState } from "react";
//import "../Supporting.scss"
import { IconButton, InputAdornment, TextField, Tooltip } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import { useAppSelector } from "../../Store/hooks";

interface Props {
  title: string;
  value: string | Date | null;
  attrName: any;
  value_update: Function;
  error_message: string;
  warn_status: boolean;
  class_name: string;
  disableFuture?: string | Date | null;
  info_status?: boolean;
  info_message?: string;
  required?: boolean;
  show_future?: boolean;
  min_date?: Date | null | string;
  max_date?: Date | null;
  disabled?: boolean;
  // defaultValue?: Date | null;
}

const DatePicker: React.FC<Props> = ({
  title,
  value,
  value_update,
  attrName,
  error_message,
  warn_status,
  class_name,
  disableFuture,
  info_status,
  info_message,
  required,
  show_future,
  min_date,
  max_date,
  disabled,
  // defaultValue,
}) => {
  const user_type_dateFormat = useAppSelector((state) => state.userDetailsSlice.localInformation.dateFormat) || "MM/DD/YYYY";
  const [error, setError] = useState(false); // State to track errors

  return (
    <div className={`${class_name} datepicker`}>
      <LocalizationProvider fullWidth dateAdapter={AdapterDateFns}>
        <MobileDatePicker
          disabled={disabled}
          label={title}
          value={value}
          minDate={min_date}
          //inputFormat="yyyy-MM-dd"
          maxDate={max_date}
          onChange={(e) => {
            value_update(attrName, e);
          }}
          onError={(reason) => {
            // Check for specific error cases and update error state
            if (reason === "invalidDate") {
              setError(true);
            } else {
              setError(false);
            }
          }}
          renderInput={(params) => (
            <TextField fullWidth {...params} required={required}  InputLabelProps={{
              style: {
                color: error ? "#0000008f" : "#0000008f", // Change label color to red on error
              },
            }}/>
          )}
          inputFormat={user_type_dateFormat.replace(/YYYY/g, "yyyy").replace(/DD/g, "dd")}
          disableFuture={show_future}
          showToolbar={false}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton edge="end" onClick={() => { }}>
                  <CalendarMonthOutlinedIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </LocalizationProvider>
      {info_status === true ? (
        <Tooltip title={info_message === undefined ? "" : info_message}>
          <span className="infoMessage">
            <InfoOutlinedIcon />
          </span>
        </Tooltip>
      ) : null}
      {warn_status === true ? (
        <span className="error">{error_message}</span>
      ) : null}
    </div>
  );
};

export default DatePicker;
