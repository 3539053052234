import React, { useEffect, useState } from "react";
import "../../NewSettings.scss";
import Grid from "@mui/material/Unstable_Grid2";
import { useAppSelector } from "../../../../../../Store/hooks";
import { Box, Button, Link } from "@mui/material";
import ChangePassword from "./ChangePassword/ChangePassword";
import { useNavigate } from "react-router-dom";
import {
  add_date_format,
  titleCase,
} from "../../../../../../Supporting files/HelpingFunction";
import { EMPLOYER_SETTING_SERVICES } from "../../../../../../Services/EmployerServices/Settings/SetingServices";
import { EMPLOYER_URL_CONSTANTS } from "../../../../../../APIVar/EmployerUrlConstant";
import NewLoader from "../../../../../../Components/NewLoader/NewLoader";

function PersonalSettings() {
  const navigate = useNavigate();
  const [changePassword, setChangePassword] = React.useState(false);
  const [personalSettingdata, setpersonalSettingdata] = React.useState({
    department: "",
    logo: "",
    gender: "",
    dob: "",
    building: "",
    area: "",
    country: "",
    state: "",
    city: "",
    mobileNumber: "",
    phoneNumber: "",
    name: "",
    pinCode: "",
    roleName: "",
    email: "",
    companyCode: "",
  });
  const [showLoader, setShowLoader] = useState(true);

  const updateMasterState = (
    attrName: any,
    value: boolean | string | number
  ) => {
    if (attrName === "setChangePassword" && typeof value === "boolean") {
      setChangePassword(value);
    } else {
      attrName(value);
    }
  };
  const userData: any = useAppSelector(({ userDetailsSlice }) => {
    return userDetailsSlice;
  });

  //get personal setting data
  const get_personal_settings = () => {
    const onSuccess = (res: any) => {
      if (res) {
        setTimeout(() => {
          setShowLoader(false);
        }, 1500);
      }
      const obj = {
        area: res.data.data.area || res.data.data["Area Name"],
        department: res.data.data.department || res.data.data.Departments,
        logo: res.data.data.logo,
        gender: res.data.data.gender,
        dob: res.data.data.dob,
        building:
          res.data.data.building || res.data.data["Building/Street Name"],
        country: res.data.data.country || res.data.data.Country,
        state: res.data.data.state || res.data.data.State,
        city: res.data.data.city || res.data.data.City,
        mobileNumber:
          res.data.data.mobileNumber ||
          res.data.data["Organization Phone Number"],
        phoneNumber:
          res.data.data.phoneNumber ||
          res.data.data["Organization Phone Number"],
        name: res.data.data.name || res.data.data.Organisation,
        pinCode: res.data.data.pinCode || res.data.data.Pincode,
        roleName: res.data.data.roleName,
        email: res.data.data.email || res.data.data["Organization Email"],
        companyCode: res.data.data.companyCode || res.data.data["Company Id"],
      };
      setpersonalSettingdata(obj);
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    userData.parentsId
      ? EMPLOYER_SETTING_SERVICES.get_personal_settings(
          EMPLOYER_URL_CONSTANTS.get_personal_settings,
          onSuccess,
          onError
        )
      : EMPLOYER_SETTING_SERVICES.get_personal_settings(
          EMPLOYER_URL_CONSTANTS.get_emp_personal_settings,
          onSuccess,
          onError
        );
  };
  useEffect(() => {
    get_personal_settings();
    setShowLoader(true);
  }, []);

  return (
    <>
      {showLoader ? (
        <NewLoader />
      ) : (
        <Box className="settingMenuWrapper">
          <ChangePassword
            attrName={setChangePassword}
            open_status={changePassword}
            value_update={updateMasterState}
            update_api_url={
              userData.parentsId
                ? EMPLOYER_SETTING_SERVICES.update_emp_password
                : EMPLOYER_SETTING_SERVICES.update_password
            }
          />
          <Grid container spacing={3}>
            <Grid xs={4}>
              <div className="personal_logosection">
                <h6>Profile</h6>
                <img
                  src={
                    personalSettingdata?.logo ?? "images/nobody_original.svg"
                  }
                  alt=""
                  className="company_logo"
                />

                <h5>{titleCase(personalSettingdata?.name ?? "")}</h5>
                <p className="mb-2">
                  {titleCase(personalSettingdata?.roleName ?? "")}
                </p>
                <p>{titleCase(personalSettingdata?.department ?? "")}</p>
              </div>
              <div className="personal_logosection">
                <h6>Profile Details</h6>
                <div className="Companydetail">
                  <div className="text-left">
                    <p>Gender</p>
                    <h6 style={{ minHeight: "10px" }}>
                      {personalSettingdata?.gender}
                    </h6>
                  </div>
                  <div className="text-right">
                    <h4>DOB</h4>
                    <h6 style={{ minHeight: "10px" }}>
                      {/* {add_date_format(personalSettingdata?.dob)} */}
                    </h6>
                  </div>
                </div>
                <div className="Companydetail">
                  <div className="text-left">
                    <p>Mobile Number</p>
                    <h6>{/* {personalSettingdata?.mobileNumber} */}</h6>
                  </div>
                  <div className="text-right">
                    <h4>Phone Number</h4>
                    <h6>{personalSettingdata?.phoneNumber}</h6>
                  </div>
                </div>
                <div className="Companydetail">
                  <div className="text-left">
                    <p>User ID</p>
                    <h6>{personalSettingdata?.companyCode}</h6>
                  </div>
                  <div className="text-right">
                    <h4>Email ID</h4>
                    <h6>{personalSettingdata?.email}</h6>
                  </div>
                </div>
              </div>
            </Grid>

            <Grid xs={8}>
              <Box>
                <Grid container padding={0}>
                  <Grid xs={12} md={12} lg={12}>
                    <div className="password_section">
                      <h5>
                        Change Password
                        <span>Change password if you want</span>
                      </h5>
                      <Link
                        className="chng_pwd_link"
                        underline="none"
                        onClick={() => {
                          setChangePassword(true);
                        }}
                      >
                        <img src="images/change_password_icon.svg" alt="" />
                        Change Password
                      </Link>
                    </div>
                  </Grid>
                </Grid>
              </Box>
              <div className="address_details_section">
                <Grid container>
                  <Grid xs={12}>
                    <h5>Address</h5>
                    {/* <p className="mb-7">
                  You can use the following email addresses to sign in to your
                  account and also to reset your password if you ever forget it.
                </p> */}
                    <Box>
                      <Grid container className="contact_details_innerBox mb-5">
                        <Grid xs={6}>
                          <h6>Street name</h6>
                          <h5>{personalSettingdata?.building}</h5>
                        </Grid>
                        <Grid xs={6}>
                          <h6>Area name</h6>
                          <h5>{personalSettingdata?.area}</h5>
                        </Grid>
                      </Grid>
                      <Grid container className="contact_details_innerBox">
                        <Grid xs={3}>
                          <h6>Country</h6>
                          <h5>{personalSettingdata?.country}</h5>
                        </Grid>
                        <Grid xs={3}>
                          <h6>State</h6>
                          <h5>{personalSettingdata?.state}</h5>
                        </Grid>
                        <Grid xs={3}>
                          <h6>City</h6>
                          <h5>{personalSettingdata?.city}</h5>
                        </Grid>
                        <Grid xs={3}>
                          <h6>Zip code</h6>
                          <h5>{personalSettingdata?.pinCode}</h5>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
          {/* Footer Section */}
          <Grid container spacing={3} className="footerSection">
            <Grid xs={12} className="ctaBtn">
              <Button
                variant="contained"
                className="submitBtn"
                onClick={() => {
                  navigate("/edit-personal-details");
                }}
              >
                Edit
              </Button>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
}

export default PersonalSettings;
