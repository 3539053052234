import { URL_CONSTANTS } from "../../APIVar/URLConstants";
import { GetAPI, PostAPI, DeleteAPI, PatchAPI } from "../API";
import {
  groupTERMAddDTO,
  groupTERMDeleteDTO,
  groupTERMExculsionAddDTO,
  groupTermFeatureAddDTO,
} from "../DTO/ProductConfigurator/GroupTermDto";

const createCatageory = (
  api_url: string,
  dto: groupTERMAddDTO[],
  onSuccess: any,
  config: any,
  onError: any
) => {
  PostAPI.call(api_url, dto, onSuccess, {}, onError);
};
const getCatageory = (
  api_url: string,
  onSuccess: (data: any) => void,
  onError: (err: any) => void
) => {
  GetAPI.call(api_url, onSuccess, onError);
};
const deleteCatageory = (
  api_url: string,
  dto: groupTERMDeleteDTO,
  onSuccess: (data: any) => void,
  config: any,
  onError: (err: any) => void
) => {
  DeleteAPI.call(api_url, dto, onSuccess, {}, onError);
};
const updateCatageory = (
  api_url: string,
  dto: groupTERMAddDTO,
  onSuccess: any,
  config: any,
  onError: any,
  id: string | undefined
) => {
  PatchAPI.call(`${api_url}${id}`, dto, onSuccess, {}, onError);
};
// exculsion api method
const getExculsion = (
  searchBarFilter:string,
  onSuccess: (data: any) => void,
  onError: (err: any) => void
) => {
  GetAPI.call(`${URL_CONSTANTS.get_group_term_exclusion}${searchBarFilter}`, onSuccess, onError);
};
const createExcuslion = (
  api_url: string,
  dto: groupTERMExculsionAddDTO[],
  onSuccess: any,
  config: any,
  onError: any
) => {
  PostAPI.call(api_url, dto, onSuccess, {}, onError);
};
const updateExclusion = (
  dto: groupTERMExculsionAddDTO,
  onSuccess: any,
  config: any,
  onError: any,
  id: string | undefined
) => {
  PatchAPI.call(
    `${URL_CONSTANTS.update_group_term_exclusion}${id}`,
    dto,
    onSuccess,
    {},
    onError
  );
};
const deleteExclusion = (
  api_url: string,
  dto: groupTERMDeleteDTO,
  onSuccess: (data: any) => void,
  config: any,
  onError: (err: any) => void
) => {
  DeleteAPI.call(api_url, dto, onSuccess, {}, onError);
};
// feature api method
const getFeature = (
  searchBarFilter:string,
  onSuccess: (data: any) => void,
  onError: (err: any) => void
) => {
  GetAPI.call(`${URL_CONSTANTS.get_group_term_feature}${searchBarFilter}`, onSuccess, onError);
};
const createFeature = (
  api_url: string,
  dto: groupTermFeatureAddDTO[],
  onSuccess: any,
  config: any,
  onError: any
) => {
  PostAPI.call(api_url, dto, onSuccess, {}, onError);
};
const deleteFeature = (
  api_url: string,
  dto: groupTERMDeleteDTO,
  onSuccess: (data: any) => void,
  config: any,
  onError: (err: any) => void
) => {
  DeleteAPI.call(api_url, dto, onSuccess, {}, onError);
};
const updateFeature = (
  api_url: string,
  dto: groupTermFeatureAddDTO,
  onSuccess: any,
  config: any,
  onError: any,
  id: string | undefined
) => {
  PatchAPI.call(`${api_url}${id}`, dto, onSuccess, {}, onError);
};
export const GROUP_TERM_SERVICES = {
  createCatageory,
  getCatageory,
  deleteCatageory,
  updateCatageory,
  getExculsion,
  createExcuslion,
  updateExclusion,
  deleteExclusion,
  getFeature,
  createFeature,
  deleteFeature,
  updateFeature,
};
