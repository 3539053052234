import { Box, Grid } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { ALL_ROUTES } from "../../../../Routes/all_routes";
import { useAppSelector } from "../../../../Store/hooks";
import TabList from "../TabList/Tablist";
import "./SideNavBar.scss";

function SideNavBar() {
  const location = useLocation();
  const { BROKER } = useAppSelector((state) => state.permissionSlice);

  return (
    <Grid className="menuCol" item xs>
      <Box className="menus">
        {BROKER?.ProductConfigurator?.view &&
        (location.pathname === ALL_ROUTES.PRODUCT_CONFIG_GROUP_HEALTH ||
          location.pathname === ALL_ROUTES.PRODUCT_CONFIG_GROUP_TERM ||
          location.pathname === ALL_ROUTES.PRODUCT_CONFIG_SUPER_TOPUP ||
          location.pathname ===
            ALL_ROUTES.PRODUCT_CONFIG_GROUP_PERSONAL_ACCIDENT ||
          location.pathname ===
            ALL_ROUTES.PRODUCT_CONFIG_GROUP_CRITICAL_ILLNESS ||
          location.pathname === ALL_ROUTES.PRODUCT_CONFIG_GROUP_TRAVEL ||
          location.pathname === ALL_ROUTES.PRODUCT_CONFIG_INSURERS ||
          location.pathname === ALL_ROUTES.PRODUCT_CONFIG_TPA) ? (
          <div className="subMenu">
            <ul>
              <li className="product">Products</li>
              <li>
                <Link
                  to={`${ALL_ROUTES.PRODUCT_CONFIG_GROUP_HEALTH}`}
                  className={
                    location.pathname ===
                    `${ALL_ROUTES.PRODUCT_CONFIG_GROUP_HEALTH}`
                      ? "active"
                      : ""
                  }
                >
                  Group Health
                </Link>
              </li>
              <li>
                <Link
                  to={ALL_ROUTES.PRODUCT_CONFIG_SUPER_TOPUP}
                  className={
                    location.pathname ===
                    `${ALL_ROUTES.PRODUCT_CONFIG_SUPER_TOPUP}`
                      ? "active"
                      : ""
                  }
                >
                  Group Super Top Up
                </Link>
              </li>
              <li>
                <Link
                  to={ALL_ROUTES.PRODUCT_CONFIG_GROUP_TERM}
                  className={
                    location.pathname ===
                    `${ALL_ROUTES.PRODUCT_CONFIG_GROUP_TERM}`
                      ? "active"
                      : ""
                  }
                >
                  Group Term
                </Link>
              </li>
              <li>
                <Link
                  to={ALL_ROUTES.PRODUCT_CONFIG_GROUP_PERSONAL_ACCIDENT}
                  className={
                    location.pathname ===
                    `${ALL_ROUTES.PRODUCT_CONFIG_GROUP_PERSONAL_ACCIDENT}`
                      ? "active"
                      : ""
                  }
                >
                  Group Personal Accident
                </Link>
              </li>
              <li>
                <Link
                  to={ALL_ROUTES.PRODUCT_CONFIG_GROUP_CRITICAL_ILLNESS}
                  className={
                    location.pathname ===
                    `${ALL_ROUTES.PRODUCT_CONFIG_GROUP_CRITICAL_ILLNESS}`
                      ? "active"
                      : ""
                  }
                >
                  Group Critical Illness
                </Link>
              </li>
              {/* <li>
                <Link
                  to={ALL_ROUTES.PRODUCT_CONFIG_GROUP_TRAVEL}
                  className={
                    location.pathname ===
                    `${ALL_ROUTES.PRODUCT_CONFIG_GROUP_TRAVEL}`
                      ? "active"
                      : ""
                  }>
                  Group Travel
                </Link>
              </li> */}
              <li className="product">
                <Link
                  to={ALL_ROUTES.PRODUCT_CONFIG_INSURERS}
                  className={
                    location.pathname ===
                    `${ALL_ROUTES.PRODUCT_CONFIG_INSURERS}`
                      ? "active"
                      : ""
                  }
                >
                  Insurers
                </Link>
              </li>
              <li className="product">
                <Link
                  to={ALL_ROUTES.PRODUCT_CONFIG_TPA}
                  className={
                    location.pathname === `${ALL_ROUTES.PRODUCT_CONFIG_TPA}`
                      ? "active"
                      : ""
                  }
                >
                  TPA
                </Link>
              </li>
            </ul>
          </div>
        ) : null}
        <TabList
          className={
            location.pathname === ALL_ROUTES.DASHBOARD
              ? "dashboard active"
              : "dashboard"
          }
          buttonText={
            <p>
              <span>D</span>ashboard
            </p>
          }
          path={ALL_ROUTES.DASHBOARD}
        />
        {BROKER?.Contact?.view && (
          <TabList
            className={
              location.pathname === ALL_ROUTES.CONTACTS
                ? "contacts active"
                : "contacts"
            }
            buttonText={
              <p>
                Co<span>n</span>tacts
              </p>
            }
            path={ALL_ROUTES.CONTACTS}
          />
        )}
        {BROKER?.Clients?.view && (
          // user_type.permission.Clients.enable &&
          <TabList
            className={
              location.pathname === ALL_ROUTES.CLIENTS
                ? "clients active"
                : "clients"
            }
            buttonText={
              <p>
                <span>C</span>lients
              </p>
            }
            path={ALL_ROUTES.CLIENTS}
          />
        )}
        {BROKER?.Quotes?.view && (
          <TabList
            className={
              location.pathname === ALL_ROUTES.QUOTES ? "quote active" : "quote"
            }
            buttonText={
              <p>
                <span>Q</span>uotes
              </p>
            }
            path={ALL_ROUTES.QUOTES}
          />
        )}
        {BROKER?.Policies?.view && (
          <TabList
            className={
              location.pathname === ALL_ROUTES.POLICIES
                ? "policies active"
                : "policies"
            }
            buttonText={
              <p>
                <span>P</span>olicies
              </p>
            }
            path={ALL_ROUTES.POLICIES}
          />
        )}
        {/* {BROKER?.Renewals?.view && (
          <TabList
            className={
              location.pathname === ALL_ROUTES.POLICY_RENEWAL
                ? "endorsement active"
                : "endorsement"
            }
            buttonText={
              <p>
                <span>R</span>enewals
              </p>
            }
            path={ALL_ROUTES.POLICY_RENEWAL}
          />
        )} */}

        {BROKER?.Employees?.view && (
          <TabList
            className={
              location.pathname === ALL_ROUTES.EMPLOYEE
                ? "employe active"
                : "employe"
            }
            buttonText={
              <p>
                <span>M</span>embers
              </p>
            }
            path={ALL_ROUTES.EMPLOYEE}
          />
        )}

        {BROKER?.Claims?.view && (
          <TabList
            className={
              location.pathname === ALL_ROUTES.CLIAM_ASSISTANT
                ? "claimassistant active"
                : "claimassistant"
            }
            buttonText={
              <p>
                C<span>l</span>aim
              </p>
            }
            path={ALL_ROUTES.CLIAM_ASSISTANT}
          />
        )}
        {BROKER?.Endorsement?.view && (
          <TabList
            className={
              location.pathname === ALL_ROUTES.ENDORSEMENT
                ? "endorsement active"
                : "endorsement"
            }
            buttonText={
              <p>
                Endorsemen<span>t</span>
              </p>
            }
            path={ALL_ROUTES.ENDORSEMENT}
          />
        )}

        {BROKER?.ProductConfigurator?.view && (
          <TabList
            className={
              location.pathname === ALL_ROUTES.PRODUCT_CONFIG_GROUP_HEALTH ||
              location.pathname === ALL_ROUTES.PRODUCT_CONFIG_GROUP_TERM ||
              location.pathname ===
                ALL_ROUTES.PRODUCT_CONFIG_GROUP_PERSONAL_ACCIDENT ||
              location.pathname ===
                ALL_ROUTES.PRODUCT_CONFIG_GROUP_CRITICAL_ILLNESS ||
              location.pathname === ALL_ROUTES.PRODUCT_CONFIG_GROUP_TRAVEL ||
              location.pathname === ALL_ROUTES.PRODUCT_CONFIG_INSURERS ||
              location.pathname === ALL_ROUTES.PRODUCT_CONFIG_TPA
                ? "settings active"
                : "settings"
            }
            buttonText={
              <p>
                <span>C</span>onfigurator
              </p>
            }
            path={ALL_ROUTES.PRODUCT_CONFIG_GROUP_HEALTH}
          />
        )}

        {/* {
          <TabList
            className={
              location.pathname === ALL_ROUTES.TRENDING_QUESTION
                ? "trendingquestions active"
                : "trendingquestions"
            }
            buttonText={
              <p>
                Trendin<span>g</span> Questions
              </p>
            }
            path={ALL_ROUTES.TRENDING_QUESTION}
          />
        } */}
        {/* {
          <TabList
            className={
              location.pathname === ALL_ROUTES.COMMUNITY
                ? "community active"
                : "community"
            }
            buttonText={
              <p>
                Co<span>m</span>munity
              </p>
            }
            path={ALL_ROUTES.COMMUNITY}
          />
        } */}
        {/* {BROKER?.Reports.view && (
          <TabList
            className={
              location.pathname === ALL_ROUTES.REPORT
                ? "report active"
                : "report"
            }
            buttonText={
              <p>
                Rep<span>o</span>rt
              </p>
            }
            path={ALL_ROUTES.REPORT}
          />
        )} */}
        {BROKER?.Support?.view && (
          <TabList
            className={
              location.pathname === ALL_ROUTES.SUPPORT
                ? "support active"
                : "support"
            }
            buttonText={
              <p>
                S<span>u</span>pport
              </p>
            }
            path={ALL_ROUTES.SUPPORT}
          />
        )}
      </Box>
    </Grid>
  );
}

export default SideNavBar;
