import { Box, Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { URL_CONSTANTS } from "../../../../APIVar/URLConstants";
import { GetTicketDTO } from "../../../../Services/DTO/SupportDTO";
import { EMPLOYER_SETTING_SERVICES } from "../../../../Services/EmployerServices/Settings/SetingServices";
import { SUPPORT_SERVICES } from "../../../../Services/Support/SupportServices";
import { RootState } from "../../../../Store/Store";
import { useAppSelector } from "../../../../Store/hooks";
import { uploadImage } from "../../../../Supporting files/HelpingFunction";
import RKTextField from "../../../../Supporting files/RKTextField/RKTextField";
import SelectDropdown from "../../../../Supporting files/SupportDropDown/SelectDropdown";
import "./Support.scss";

function Support() {
  const userData = useAppSelector((state: RootState) => state.userDetailsSlice);
  const [disableSave, setDisableSave] = useState<boolean>(false);
  const [supportTicket, setSupportTicket] = useState<any>({
    description: "",
    reasons: "",
    subject: "",
    upload: "",
    emailId: userData.support_email,
    username: userData?.clientId,
    organisationName: userData?.Organisation,
  });
  const [warnInfo, setwarnInfo] = useState({subject:false,reason:false,description : false,upload:false});
  const [filename, setFilename] = useState<string>("");
  const [getTicket, setGetTicket] = useState<GetTicketDTO>([]);
  const [uploadFile, setUploadFile] = React.useState("");

  const reason_data = [
    { key: "01-Facing Issue", value: "Facing Issue" },
    { key: "01-Complaint", value: "Complaint" },
    { key: "00-Feedback", value: "Feedback" },
  ];

  const faqs = [
    {
      expanded: "panel1",
      title: "How to raise a request for claim intimation?",
      description:
        "Reach out to your insurance company as soon as possible to inform them about the incident or event that may lead to a claim. You can find their contact information on your insurance policy documents or their website.",
    },
    {
      expanded: "panel2",
      title: "How to update password?",
      description: "kdsgjsdkjgkdsjgksd dskgsdkgs gksdjgk",
    },
    {
      expanded: "panel3",
      title: "What is riskmap?",
      description:
        "A risk score map is a visual representation or a graphical representation that indicates the level of risk associated with different areas or regions. It is used to identify and communicate the varying degrees of risk across a specific geographical area or within a particular domain.",
    },
    {
      expanded: "panel4",
      title: "How does group health insurance policy work?",
      description:
        "An employer purchases a group healthcare coverage plan for their respective employees based on the number of workers. The employer entirely bears the cost of group healthcare. In return, the employees can avail medical insurance as per the sum insured.",
    },
  ];

  const updateMasterState = (attrName: any, value: string) => {
    if (attrName === "setSupportTicket") {
      setSupportTicket({ ...supportTicket, reasons: value });
    } else if (attrName === "subject") {
      setSupportTicket({ ...supportTicket, subject: value });
    } else {
      attrName(value);
    }
  };

  const handleFileUpload = (e: any) => {
    const file = e.target.files[0];
    const fileSize = file?.size;
    const maxSizeBytes = 8 * 1024 * 1024;
    if (fileSize && fileSize > maxSizeBytes) {
      e.target.files = null;
      e.target.value = null;
      return toast.error("File size exceeds 8 MB limit");
    }
    if (file) {
      uploadImage(file, (base64Image: any, base64String: any) => {
        setFilename(file.name);
        setSupportTicket({
          ...supportTicket,
          upload: base64Image,
        });
      });
    }
    e.target.files = null;
    e.target.value = null;
  };
  const getSupportTicket = () => {
    const onSuccess = (res: any) => {
      setGetTicket(res?.data?.data);
    };
    const onError = (err: any) => {
      // toast.error("Error getting support ticket");
    };
    const url =
      userData.userType === "BROKER" ? URL_CONSTANTS.get_support_ticket : "";
    EMPLOYER_SETTING_SERVICES.fetch_data(url, onSuccess, onError);
  };

  // useEffect(() => {
  //   getSupportTicket();
  // }, [supportTicket]);

  const submitSupportTicket = () => {
    if (disableSave) return;
  
    let hasError = false;
  
    // Create a new object for warn
    let warn = {
      reason: !supportTicket.reasons?.trim(),
      description: !supportTicket.description?.trim(),
      subject: !supportTicket.subject?.trim(),
      upload: !supportTicket.upload?.trim()
    };
  
    console.log("Sfsdfsdfs", warn);
    setwarnInfo(warn);
  
    // Check if any warning is true
    if (warn.reason || warn.description || warn.subject || warn.upload) {
      hasError = true;
    }
  
    const onSuccess = (res: any) => {
      toast.success("Submitted support ticket successfully!");
      setFilename("");
      setSupportTicket({
        description: "",
        reasons: "",
        subject: "",
        upload: "",
        emailId: userData.support_email,
        username: userData?.clientId,
        organisationName: userData?.Organisation,
      });
      setTimeout(() => {
        setDisableSave(false);
      }, 1500);
    };
  
    const onError = (err: any) => {
      setDisableSave(false);
      toast.error("Error submitting support ticket");
    };
  
    if (!hasError) {
      setDisableSave(true);
      SUPPORT_SERVICES.create_emp_support_ticket(supportTicket, onSuccess, onError);
    }
  };
  
  return (
    <Box className="supportWrapper">
      <Grid container spacing={3}>
        <Grid xs={12} className="pageTitle">
          <h2>
            <span>Get</span> Support
          </h2>
          {/* <p>
            What incredible experiences are unfolding within you? Kindly grace
            us with the gift of sharing.
          </p> */}
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid xs={12}>
          <>
            <Grid container spacing={3}>
              <Grid xs={4}>
                <img src="./images/confused.svg" />
              </Grid>
              <Grid xs={8}>
                <>
                  <Grid container spacing={3}>
                    <Grid xs={12}>
                      <SelectDropdown
                        class_name="inputField"
                        title="Reason of writing to us"
                        value={supportTicket.reasons}
                        attrName={"setSupportTicket"}
                        value_update={updateMasterState}
                        dropdown_data={reason_data}
                        warn_status={warnInfo.reason}
                        error_messg="Select Reason of writing to us"
                      />
                    </Grid>
                    <Grid xs={12}>
                      <RKTextField
                        class_name="inputField"
                        title="Subject"
                        value={supportTicket.subject}
                        attrName={"subject"}
                        value_update={updateMasterState}
                        warn_status={warnInfo.subject}
                      />
                    </Grid>
                    <Grid xs={12}>
                      <p>Describe here</p>
                      <textarea
                        className="textareabox"
                        placeholder="Write here..."
                        value={supportTicket.description}
                        onChange={(e) => {
                          setSupportTicket({
                            ...supportTicket,
                            description: e.target.value,
                          });
                        }}
                      ></textarea>
                      <div className="inputField">
                      <span className="error">{warnInfo.description &&"Enter description"}</span>

                      </div>

                    </Grid>
                    <Grid xs={12}>
                      <Box>
                        <Grid container spacing={0} className="uploadFile" padding={0}>
                          <Grid xs={10} className="inputField">
                            <RKTextField
                              class_name="inputField"
                              title={"Upload .jpg .png .pdf file"}
                              value={filename}
                              attrName={setUploadFile}
                              value_update={updateMasterState}
                              warn_status={false}
                            />
                          </Grid>
                          <Grid xs={2}>
                            <Button
                              variant="contained"
                              className="browsebtn"
                              component="label"
                            >
                              Browse
                              <input
                                hidden
                                accept="application/pdf, image/*"
                                multiple
                                type="file"
                                style={{ zIndex: "99" }}
                                onChange={handleFileUpload}
                              />
                            </Button>
                          </Grid>
                          <Grid xs={12}>
                            <div className="uploadAttachmentInfo">
                              <span>Maximum size of attachment is 8 MB*</span>
                            </div>
                            <div className="inputField">
                      <span className="error">{warnInfo.upload &&"Upload attachment"}</span>

                      </div>
                          </Grid>
  
                        </Grid>
                      </Box>
                    </Grid>
                    <Grid xs={12} className="ctaBtn">
                      <Button
                        variant="contained"
                        className="submitBtn"
                        onClick={submitSupportTicket}
                        disabled={disableSave}
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </>
              </Grid>
            </Grid>
            {/* <Grid container spacing={3}>
              <Grid xs={12}>
                <h3>
                  <span>General</span> Questions
                </h3>
                <p>
                  You will discover the solutions you seek. Our mission is to
                  offer a seamless and user-friendly experience, ensuring that
                  you can access valuable information promptly to address your
                  frequently asked questions.
                </p>
              </Grid>
              <Grid xs={12}>
                {faqs.map((data, index) => {
                  return (
                    <FAQ
                      data={{
                        description: data.description,
                        expanded: data.expanded,
                        title: data.title,
                      }}
                    />
                  );
                })}
              </Grid>
            </Grid> */}
          </>
        </Grid>
        {/* <Grid xs={4} className="requestSection">
          <Box textAlign="center">
            <img src="./images/no-requests.svg" />
            <p>No Request Pending</p>
          </Box>
          <ul className="requestList">
            {getTicket.map((ticket: GetTicketsDTO) => {
              return (
                <li>
                  <Collapse
                    in={status}
                    collapsedSize={80}
                    className="cancelled"
                  >
                    <h6>{ticket.ticketId}</h6>
                    <span className="status">{ticket.status}</span>
                    <h5>{ticket.reasons}</h5>
                    <p>{ticket.description}</p>
                    <Link
                      className="viewMore"
                      onClick={() => setStatus(!status)}
                    >
                      <ExpandMoreIcon />
                    </Link>
                  </Collapse>
                </li>
              );
            })}
          </ul>
        </Grid>  */}
      </Grid>
    </Box>
  );
}

export default Support;
