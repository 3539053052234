import { Autocomplete, TextField, Tooltip } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import React from "react";

interface Props {
  attrName: any;
  title: string;
  value: { label: string; id: string } | string | null | undefined;
  value_update: Function;
  data: Array<any>;
  error_message?: string;
  warn_status?: boolean;
  class_name?: string;
  info_status?: boolean;
  info_message?: string;
  required?: boolean | undefined;
  params?: any;
  id?: string;
  value_type_id_value?: boolean;
  disabled?: boolean
}

const defaultProps = {
  title: "",
  value: "",
  attrName: "",
  warn_status: false,
  value_update: () => { },
  // data: [],
  value_type_id_value: false,
};

const SearchDropdown: React.FC<Props> = ({
  params,
  id,
  attrName,
  title,
  value,
  data,
  class_name,
  error_message= `Select ${title}`,
  warn_status,
  value_update,
  info_status,
  info_message,
  required,
  value_type_id_value,
  disabled
}) => {
  // console.log("search",data)
  return (
    <div className={class_name}>
      <Autocomplete
        disablePortal
        options={data}
        disabled={disabled ? true : false}
        renderInput={(params) => (
          <TextField {...params} label={title} required={required} disabled={disabled} />
        )}
        value={value}
        // this will work for product config search dropdown
        // value={!value_type_id_value ? data.find(item => item.value === value)?.label : value}

        onChange={(event, new_team) => {
          let data = new_team;
          if (new_team === null) {
            data = { id: "", label: "" };
          }
          value_type_id_value === false
            ? value_update(attrName, data)
            : value_update(attrName, data.label);
        }}
        onKeyPress={(e) => {
          e.key === "Enter" && e.preventDefault();
        }}
      />
      {info_status === true ? (
        <Tooltip title={info_message === undefined ? "" : info_message}>
          <span className="infoMessage">
            <InfoOutlinedIcon />
          </span>
        </Tooltip>
      ) : null}
      {warn_status === true ? (
        <span className="error">{error_message}</span>
      ) : null}
    </div>
  );
};

SearchDropdown.defaultProps = defaultProps;
export default SearchDropdown;
