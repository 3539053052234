import { useSelector } from 'react-redux';
import AddNewSectionPopup from '../CustomModulePopups/AddSectionPopup';
import {
    SingleLinePropertie, 
    CheckboxPropertie, 
    ContactPropertie, 
    DatePropertie, 
    EmailPropertie, 
    NumberPropertie, 
    PicklistPropertie, 
    SearchSelectPropertie, 
    URLPropertie,
    CurrencyPropertie,
    MultiLinePropertie, 
    MultiSelectPropertie,
    EditSubSectionName,
    AddressProperties
} from '../CustomModulePopups/index';

function GetModal() {
    const modal = useSelector((state)=>{ return state.modalReducer })

    const sendModal = ({data})=>{
        const modalName = data?data.field_type : "";
        switch (modalName) {
            case "multiline":
                return (<MultiLinePropertie open_status={modal.isOpen} attrName={modal.title} />)
                break;
        
            case "singleline":
                return (<SingleLinePropertie open_status={modal.isOpen} attrName={modal.title} />)
                break;
            
            case "currency": 
                return (<CurrencyPropertie open_status={modal.isOpen} attrName={modal.title}/>)
                break;
            
            case "phone":
                return (<ContactPropertie open_status={modal.isOpen} attrName={modal.title}/>)
                break;
            
            case "singleselect":
                return (<PicklistPropertie open_status={modal.isOpen} attrName={modal.title}/>)
                break;

            case "checkbox": 
                return (<CheckboxPropertie open_status={modal.isOpen} attrName={modal.title}/>)
                break;

            case "datepicker":
                return (<DatePropertie open_status={modal.isOpen} attrName={modal.title}/>)
                break;

            case "email":
                return (<EmailPropertie open_status={modal.isOpen} attrName={modal.title}/>)
                break;
            
            case "number":
                return (<NumberPropertie open_status={modal.isOpen} attrName={modal.title}/>)
                break;

            case "searchselect":
                return (<SearchSelectPropertie open_status={modal.isOpen} attrName={modal.title}/>)
                break;
            
            case "url":
                return (<URLPropertie open_status={modal.isOpen} attrName={modal.title}/>)
                break;
            
            case "multiselect":
                return (<MultiSelectPropertie open_status={modal.isOpen} attrName={modal.title}/>)

            case "addSection": 
                return (<AddNewSectionPopup open_status={modal.isOpen} attrName={modal.title}/>)

            case "editSubSectionName": 
                return (<EditSubSectionName open_status={modal.isOpen} attrName={modal.title}/>)
            
            case "address": 
                return (<AddressProperties open_status={modal.isOpen} attrName={modal.title}/>)
            default:
                break;
        }
    }

  return (
    <>
        {sendModal(modal)}
    </>
  )
}

export default GetModal