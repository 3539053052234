import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { URL_CONSTANTS } from "../../../../../APIVar/URLConstants";
import GlobalDeletePopup from "../../../../../Components/Broker/GlobalDeletePopup/GlobalDeletePopup";
import "../../../../../Components/Broker/PolicyHistory/PolicyHistory.scss";
import BulkUploadForm from "../../../../../Components/Common/BulkUploadFormSP/BulkUploadForm";
import { PROFILE_SERVICE } from "../../../../../Services/Employee/ProfileService";
import { useAppSelector } from "../../../../../Store/hooks";
import { add_date_format, downloadAttachement, updateTimeFormat, } from "../../../../../Supporting files/HelpingFunction";
import AddFamily from "./AddPopup/AddFamily";
import AddFamilyKyc from "./AddPopup/AddKyc";
import AddProperty from "./AddPopup/AddProperty";
import AddVehcile from "./AddPopup/AddVehicle";
import EditProperty from "./EditPopup/Edit Property";
import EditFamily from "./EditPopup/EditFamily";
import EditVehcile from "./EditPopup/EditVehicle";

function EmployeeDetailProfile() {
  const { userType } = useAppSelector((state) => state.userDetailsSlice);
  const [selectedVehicleData, setSelectedVehicleData] = useState<any>({});
  const [removePopup, setRemovePopup] = useState<boolean>(false);
  const [deleteStatusStep, setDeleteStatusStep] = useState(1);
  const [addKyc, setAddKyc] = useState<boolean>(false);
  const [employee_kyc_data, setEmployee_kyc_data] = useState<any[]>([]);
  const [uploadFile, setUploadFile] = useState(false);
  const [addFamily, setAddFamily] = useState(false);
  const [editFamily, setEditFamily] = useState(false);
  const [addVehicle, setAddVehicle] = useState(false);
  const [editVehicle, setEditVehicle] = useState(false);
  const [addProperty, setAddProperty] = useState(false);
  const [editProperty, setEditProperty] = useState(false);
  const [addKycEmployee, setAddKycEmployee] = useState(false);
  const user_type_dateFormat = useAppSelector((state) => state.userDetailsSlice.localInformation.dateFormat);
  const [property_data, setProperty_Data] = useState<any[]>([]);
  const [vehicles_data, setVehicles_data] = useState<any[]>([]);
  const [selectedPropertyData, setSelectedPropertyData] = useState<any>({});
  const [selectedFamilyData, setSelectedFamilyData] = useState<any>({});
  const [family_data, setFamily_data] = useState<any[]>([]);
  const [family_kyc, setFamily_kyc] = useState<any>([]);
  const [id, setId] = useState("");
  const [kyc_id, setKyc_id] = useState<string>("");
  const [deleteStepDiff, setDeleteStepDiff] = useState<number>(0);
  const [delete_kyc, setDelete_kyc] = useState("");
  const [sectionStatus, SetSectionStatus] = useState<string>("familyDeatils");
  const familyDeatils = useRef(null);
  const vehicleDetails = useRef(null);
  const propertyDetails = useRef(null);
  const employeeKYC = useRef(null);
  const { BROKER, EMPLOYER } = useAppSelector((state) => state.permissionSlice);
  const [editPermission, setEditPermission] = useState(true);

  useEffect(() => {
    if (userType === "BROKER") {
      setEditPermission(BROKER?.Employees.edit === undefined ? false : BROKER?.Employees.edit);
    } else if (userType === "EMPLOYER") {
      setEditPermission(EMPLOYER?.Employees.edit === undefined ? false : EMPLOYER?.Employees.edit);
    }
  }, [])

  const scrollToSection = (elementRef: any) => { elementRef.current?.scrollIntoView({ behavior: "smooth" }); };

  let ids = selectedVehicleData?._id || selectedPropertyData?._id || selectedFamilyData?._id
    ? [
      {
        ids:
          selectedVehicleData?._id ||
          selectedPropertyData?._id ||
          selectedFamilyData?._id,
      },
    ]
    : [];
  const updateMasterState = (attrName: any, value: any) => {
    if (attrName === setRemovePopup) {
      return setRemovePopup(false);
    } else if (attrName === "delete_step") {
      setDeleteStatusStep(value);
    } else {
      attrName(value);
    }
  };

  useEffect(() => {
    const windowURL = window.location.search;
    const params = new URLSearchParams(windowURL);
    const id = params.get("id");
    get_family_detail(id ?? "");
    get_family_kyc_detail();
  }, [removePopup, addFamily, editFamily, addKyc]);

  useEffect(() => {
    const windowURL = window.location.search;
    const params = new URLSearchParams(windowURL);
    const id = params.get("id");
    get_vehicle_detail(id ?? "");
  }, [addVehicle, editVehicle]);

  useEffect(() => {
    const windowURL = window.location.search;
    const params = new URLSearchParams(windowURL);
    const id = params.get("id");
    setId(id ?? "");
    get_property_detail(id ?? "");
  }, [removePopup, addProperty, editProperty]);

  useEffect(() => {
    const windowURL = window.location.search;
    const params = new URLSearchParams(windowURL);
    const id = params.get("id");
    setId(id ?? "");
    get_employee_kyc(id ?? "");
  }, [removePopup, addKycEmployee]);

  const get_vehicle_detail = (id: string) => {
    const onSuccess = (res: any) => {
      let data: any = [];
      res.data.data.forEach((el: any) => {
        switch (el.type) {
          case "Car":
            data.push({
              ...el,
              img: "./images/car_3.svg",
            });
            break;
          case "Bike":
            data.push({
              ...el,
              img: "./images/two_wheeler_icon.svg",
            });
            break;
          default:
            break;
        }
      });
      setVehicles_data(data);
      setSelectedVehicleData(!selectedVehicleData._id && data[0])
    };
    const onError = (err: any) => {
      // console.log("err", err);
    };
    PROFILE_SERVICE.get_vehicle_detail_list(onSuccess, onError, id);
  };

  const get_property_detail = (id: string) => {
    const onSuccess = (res: any) => {
      setProperty_Data(res.data.data);
      setSelectedPropertyData(res.data.data[0]);
    };
    const onError = (err: any) => {
      // console.log("err", err);
    };
    PROFILE_SERVICE.get_property_detail_list(
      onSuccess,
      onError,
      id,
      userType?.toLowerCase()
    );
  };

  const get_family_detail = (id: string) => {
    const onSuccess = (res: any) => {
      let data: any = [];
      res.data.data[0]?.members?.forEach((el: any) => {
        switch (el.relationWithEmployee?.toLowerCase()) {
          case "daughter":
            data.push({
              ...el,
              img: "./images/girl.svg",
            });
            break;
          case "son":
            data.push({
              ...el,
              img: "./images/boy.svg",
            });
            break;
          case "sister":
            data.push({
              ...el,
              img: "./images/wife.svg",
            });
            break;
          case "brother":
            data.push({
              ...el,
              img: "./images/husband.svg",
            });
            break;
          case "husband":
            data.push({
              ...el,
              img: "./images/husband.svg",
            });
            break;
          case "wife":
            data.push({
              ...el,
              img: "./images/wife.svg",
            });
            break;
          case "father":
            data.push({
              ...el,
              img: "./images/father.svg",
            });
            break;
          case "mother":
            data.push({
              ...el,
              img: "./images/mother.svg",
            });
            break;
          case "mother-in-law":
            data.push({
              ...el,
              img: "./images/mother.svg",
            });
            break;
          case "mother in law":
            data.push({
              ...el,
              img: "./images/mother.svg",
            });
            break;
          case "father-in-law":
            data.push({
              ...el,
              img: "./images/father.svg",
            });
            break;
          case "father in law":
            data.push({
              ...el,
              img: "./images/father.svg",
            });
            break;
          default:
            break;
        }
      });
      if (!selectedFamilyData?.fullName && data.length > 0) {
        setSelectedFamilyData(data[0]);
      }

      get_family_kyc_detail(id && data.length > 0 && data[0]._id);
      setDelete_kyc(data.length > 0 && data[0]._id);
      console.log("data", data, res.data.data[0]?.members)
      setFamily_data(data);
    };
    const onError = (err: any) => {
      // console.log("err", err);
    };
    PROFILE_SERVICE.get_family_detail_list(onSuccess, onError, id, userType.toLocaleLowerCase());
  };

  const get_family_kyc_detail = (_id?: string) => {
    const windowURL = window.location.search;
    const params = new URLSearchParams(windowURL);
    const id: any = params.get("id");
    const onSuccess = (res: any) => {
      setFamily_kyc(res?.data?.data);
    };
    const onError = (err: any) => {
      // console.log("err", err);
    };
    let memberId = _id ? _id : delete_kyc

    // if (id) {
    PROFILE_SERVICE.get_family_kyc_detail_list(
      onSuccess,
      onError,
      `${URL_CONSTANTS.get_family_kyc_detail(id, memberId, userType.toLocaleLowerCase())}`
    );
    // }
  };

  // get employee kyc detail
  const get_employee_kyc = (id: string) => {
    const onSuccess = (res: any) => {
      let data = res.data.data;
      setEmployee_kyc_data(data?.kyc);
    };
    const onError = (err: any) => { };
    PROFILE_SERVICE.get_employee_kyc_detail_list(onSuccess, onError, id, userType.toLocaleLowerCase());
  };

  //common delete function
  const delete_property_vehicle = () => {
    const onSuccess = (res: any) => {
      get_vehicle_detail(id)
      setDeleteStatusStep(2);
      setSelectedVehicleData({});
      setDeleteStepDiff(0);
    };
    const onError = (err: any) => {
      console.log("error", err);
    };
    if (selectedVehicleData?._id && deleteStepDiff === 2) {
      return PROFILE_SERVICE.delete_detail(
        `${URL_CONSTANTS.delete_vehicle_delete}${selectedVehicleData?._id}`,
        onSuccess,
        onError
      );
    } else if (selectedPropertyData?._id && deleteStepDiff === 3) {
      return PROFILE_SERVICE.delete_detail(
        `${URL_CONSTANTS.delete_property_detail}${selectedPropertyData?._id}`,
        onSuccess,
        onError
      );
    } else if (selectedFamilyData?._id && deleteStepDiff === 1) {
      let remian = `&type=members&id=${selectedFamilyData._id}`
      return PROFILE_SERVICE.delete_detail(
        `${URL_CONSTANTS.delete_family_detail(id, remian, userType.toLocaleLowerCase())}`,
        onSuccess,
        onError
      );
    } else if (deleteStepDiff === 4) {
      let remian = `&type=kyc&id=${kyc_id}`
      PROFILE_SERVICE.delete_detail(
        `${URL_CONSTANTS.delete_family_detail(id, remian, userType.toLocaleLowerCase())}`,
        onSuccess,
        onError
      );
    } else if (deleteStepDiff === 5) {
      PROFILE_SERVICE.delete_detail(
        `${URL_CONSTANTS.delete_kyc_detail}${kyc_id}`,
        onSuccess,
        onError
      );
    } else {
      toast.error("Select Data to delete");
    }
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid
          xs={2}
          style={{
            maxWidth: "260px",
            position: "relative",
            top: "-75px",
            paddingLeft: "0",
          }}
        >
          <div
            className="leftSection h-100"
            style={{ position: "fixed", paddingLeft: "8px" }}
          >
            <Link
              className={
                sectionStatus === "familyDeatils"
                  ? "active sectionLink familyDeatils"
                  : "sectionLink familyDeatils"
              }
              onClick={() => {
                scrollToSection(familyDeatils);
                SetSectionStatus("familyDeatils");
              }}
            >
              <span> Family Details</span>
            </Link>
            <Link
              className={
                sectionStatus === "vehicleDetails"
                  ? "active sectionLink vehicleDetails"
                  : "sectionLink vehicleDetails"
              }
              onClick={() => {
                scrollToSection(vehicleDetails);
                SetSectionStatus("vehicleDetails");
              }}
            >
              <span> Vehicle Details</span>
            </Link>
            <Link
              className={
                sectionStatus === "propertyDetails"
                  ? "active sectionLink propertyDetails"
                  : "sectionLink propertyDetails"
              }
              onClick={() => {
                scrollToSection(propertyDetails);
                SetSectionStatus("propertyDetails");
              }}
            >
              <span> Property Details</span>
            </Link>
            <Link
              className={
                sectionStatus === "employeeKYC"
                  ? "active sectionLink employeeKYC"
                  : "sectionLink employeeKYC"
              }
              onClick={() => {
                scrollToSection(employeeKYC);
                SetSectionStatus("employeeKYC");
              }}
            >
              <span> Eployee Kyc Document</span>
            </Link>
          </div>
        </Grid>
        <Grid xs>
          <Box className="detailSection mt-4" ref={familyDeatils}>
            <BulkUploadForm
              open_status={uploadFile}
              attrName={setUploadFile}
              value_update={updateMasterState}
            />
            <div className="sectionTitle">
              <h4>Family Details</h4>
              <div className="actionBtns ">
                {deleteStepDiff === 1 && !selectedFamilyData.dependentId && (
                  <>
                    <Link
                      underline="none"
                      className="redBtn delete"
                      onClick={() => {
                        if (!editPermission) { return toast.error("Permissions Not Allowed") }
                        if (selectedFamilyData.dependentId) {
                          return toast.error("Cannot delete family member which is a dependent");
                        } else {
                          setRemovePopup(true);
                          setDeleteStepDiff(1);
                        }
                        // if (
                        //   deleteStepDiff === 0 ||
                        //   deleteStepDiff === 2 ||
                        //   deleteStepDiff === 3
                        // ) {
                        //   return toast.error("Select Family Member to delete");
                        // } else {
                        //   setRemovePopup(true);
                        //   setDeleteStepDiff(1);
                        // }
                      }}
                    >
                      Remove
                    </Link>

                    <Link
                      underline="none"
                      className="blueBtn edit"
                      onClick={() => {
                        if (!editPermission) { return toast.error("Permissions Not Allowed") }
                        if (selectedFamilyData._id && !selectedFamilyData.dependentId) {
                          setEditFamily(true);
                        } else if (selectedFamilyData.dependentId) {
                          toast.error('Cannot edit family member which is a dependent')
                        } else {
                          toast.error("Select family member");
                        }
                      }}
                    >
                      Edit
                    </Link>
                  </>
                )}
                <Link
                  className="blueBtn addfile mr-3"
                  sx={{
                    textDecoration: "none",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (!editPermission) { return toast.error("Permissions Not Allowed") }
                    setAddFamily(true)
                  }}
                >
                  Add Family Member
                </Link>
                <EditFamily
                  open_status={editFamily}
                  attrName={setEditFamily}
                  value_update={updateMasterState}
                  data={selectedFamilyData}
                  addedMemberData={family_data}
                />
              </div>
            </div>
            <Box className="profile_detail">
              {family_data?.map((data, index) => (
                <div className="profileTypeSection">
                  <div
                    className={
                      selectedFamilyData._id === data._id
                        ? "radioBox_two check"
                        : "radioBox_two"
                    }
                    onClick={() => {
                      setDeleteStepDiff(1);
                      setSelectedFamilyData(data);
                      get_family_kyc_detail(data._id);
                      setDelete_kyc(data._id);
                    }}
                  >
                    <input
                      type="radio"
                      id="individual"
                      name="plantype"
                      defaultChecked
                    />
                    <label>
                      <img src={data.img} alt="" />
                    </label>

                    <h6>{data.fullName}</h6>
                    <p>
                      {data.relationWithEmployee}
                      <span>{add_date_format(data.dob)}</span>
                      <span>{data.dependentId}</span>
                    </p>
                  </div>
                </div>
              ))}

              {/* table data */}
              <Grid container spacing={3}>
                <Grid xs={12} style={{ textAlign: "end", paddingRight: "0" }}>
                  <div className="actionBtns ">
                    <Link
                      className="blueBtn addfile"
                      sx={{
                        textDecoration: "none",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        if (!editPermission) { return toast.error("Permissions Not Allowed") }
                        if (selectedFamilyData?._id) {
                          setAddKyc(true);
                        } else {
                          toast.error("select family  Member");
                        }
                      }}
                    >
                      Add KYC Document
                    </Link>
                  </div>
                </Grid>
                <Grid xs={12}>
                  <div className="table">
                    <div
                      className="attatchment_table"
                      style={{ overflow: "hidden" }}
                    >
                      <ul className="heading">
                        <li>File Name</li>
                        <li>Document Type</li>
                        <li>Description</li>
                        <li>Date</li>
                        <li>Actions</li>
                      </ul>

                      {family_kyc.map((data: any) => (
                        <ul className="subheading">
                          <li>{data.kyc.doc_name}</li>
                          <li>{data.kyc.doc_type}</li>
                          <li>{data.kyc.description}</li>
                          <li>
                            <p className="date">
                              {updateTimeFormat(data.kyc.date, user_type_dateFormat)}
                            </p>
                          </li>
                          <li
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                            }}
                          >
                            <Link
                              className="blueBtn download ml-0"
                              target="_blank"
                              // href={data.kyc.downloadLink}
                              onClick={()=>downloadAttachement(data.kyc.downloadLink,userType)}
                              sx={{
                                textDecoration: "none",
                                cursor: "pointer",
                              }}
                            ></Link>
                            <Link
                              className="redBtn delete"
                              onClick={() => {
                                if (!editPermission) { return toast.error("Permissions Not Allowed") }
                                setKyc_id(data?.kyc?._id);
                                setDeleteStepDiff(4);
                                setRemovePopup(true);
                                // setDelete_kyc(data._id);
                                // delete_property_vehicle(data._id);
                              }}
                              sx={{
                                textDecoration: "none",
                                cursor: "pointer",
                              }}
                            ></Link>
                          </li>
                        </ul>
                      ))}
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Box>
          </Box>
          {/* car details */}
          <Box className="detailSection mt-4" ref={vehicleDetails}>
            <BulkUploadForm
              open_status={uploadFile}
              attrName={setUploadFile}
              value_update={updateMasterState}
            />
            <div className="sectionTitle">
              <h4>Vehicle Details</h4>
              <div className="actionBtns" style={{ marginLeft: "auto" }}>
                {deleteStepDiff === 2 && (
                  <>
                    <Link
                      underline="none"
                      className="redBtn delete"
                      onClick={() => {
                        if (!editPermission) { return toast.error("Permissions Not Allowed") }
                        setRemovePopup(true);
                        setDeleteStepDiff(2);
                        // if (deleteStepDiff === 3 || deleteStepDiff === 1) {
                        //   return toast.error("Select Vehicle to delete");
                        // } else {
                        //   setRemovePopup(true);
                        //   setDeleteStepDiff(2);
                        // }
                      }}
                    >
                      Remove
                    </Link>

                    <Link
                      underline="none"
                      className="blueBtn edit"
                      onClick={() => {
                        if (!editPermission) { return toast.error("Permissions Not Allowed") }
                        if (selectedVehicleData._id) {
                          setEditVehicle(true);
                        } else {
                          toast.error("Select id");
                        }
                      }}
                    >
                      Edit
                    </Link>
                  </>
                )}
                <Link
                  className="blueBtn addfile"
                  sx={{
                    textDecoration: "none",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (!editPermission) { return toast.error("Permissions Not Allowed") }
                    setAddVehicle(true)
                  }}
                >
                  Add vehicle
                </Link>
              </div>
              <AddVehcile
                attrName={setAddVehicle}
                open_status={addVehicle}
                value_update={updateMasterState}
                get_profile={get_vehicle_detail}
              />
              <EditVehcile
                attrName={setEditVehicle}
                open_status={editVehicle}
                value_update={updateMasterState}
                data={selectedVehicleData}
              />
            </div>
            <Box className="profile_detail">
              {vehicles_data?.map((data, index) => (
                <div className="profileTypeSection">
                  <div
                    className="radioBox "
                    onClick={() => {
                      setSelectedVehicleData(data);
                      setDeleteStepDiff(2);
                    }}
                  >
                    <input type="radio" id="individual" name="vehicletypes" defaultChecked={index === 0} />
                    <label>
                      <img
                        src={data.img}
                        alt=""
                        style={{
                          width: data.type === "Bike" ? "72px" : "none",
                        }}
                      />
                    </label>
                    <h6>{data.registrationNumber}</h6>
                    <p>{data.makeModal}</p>
                  </div>
                </div>
              ))}
              {selectedVehicleData?._id ? (
                <Grid container spacing={3}>
                  <Grid xs={12}>
                    <ul className="vehicle_details property_details">
                      <li>
                        <p>
                          Vehicle Type<span>{selectedVehicleData.type}</span>
                        </p>
                      </li>
                      <li>
                        <p>
                          Make & Model
                          <span>{selectedVehicleData.makeModal}</span>
                        </p>
                      </li>
                      <li>
                        <p>
                          Fuel Type<span>{selectedVehicleData.fuelType}</span>
                        </p>
                      </li>
                      <li>
                        <p>
                          Variant<span>{selectedVehicleData.variant}</span>
                        </p>
                      </li>
                      <li>
                        <p>
                          Registration Date
                          <span>
                            {updateTimeFormat(
                              selectedVehicleData.registerDate,
                              user_type_dateFormat
                            )}
                          </span>
                        </p>
                      </li>
                      <li>
                        <p>
                          Policy Expiry Date
                          <span>
                            {updateTimeFormat(
                              selectedVehicleData.policyExpireDate,
                              user_type_dateFormat
                            )}
                          </span>
                        </p>
                      </li>
                      <li>
                        <p>
                          Previous Insurer
                          <span>{selectedVehicleData.previousInsurer}</span>
                        </p>
                      </li>
                    </ul>
                  </Grid>
                </Grid>
              ) : null}
            </Box>
          </Box>
          {/* property details */}
          <Box className="detailSection mt-4" ref={propertyDetails}>
            <BulkUploadForm
              open_status={uploadFile}
              attrName={setUploadFile}
              value_update={updateMasterState}
            />
            <div className="sectionTitle">
              <h4>Property Details</h4>
              <div className="actionBtns" style={{ marginLeft: "auto" }}>
                {deleteStepDiff === 3 && (
                  <>
                    <Link
                      underline="none"
                      className="redBtn delete"
                      onClick={() => {
                        if (!editPermission) { return toast.error("Permissions Not Allowed") }
                        setRemovePopup(true);
                        setDeleteStepDiff(3);
                        // if (
                        //   deleteStepDiff === 0 ||
                        //   deleteStepDiff === 2 ||
                        //   deleteStepDiff === 1
                        // ) {
                        //   return toast.error("Select Property Type to delete");
                        // } else {
                        //   setRemovePopup(true);
                        //   setDeleteStepDiff(3);
                        // }
                      }}
                    >
                      Remove
                    </Link>

                    <Link
                      underline="none"
                      className="blueBtn edit"
                      onClick={() => {
                        if (!editPermission) { return toast.error("Permissions Not Allowed") }
                        if (selectedPropertyData._id) {
                          setEditProperty(true);
                        } else {
                          toast.error("Select id");
                        }
                      }}
                    >
                      Edit
                    </Link>
                  </>
                )}
                <Link
                  className="blueBtn addfile"
                  sx={{
                    textDecoration: "none",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (!editPermission) { return toast.error("Permissions Not Allowed") }
                    setAddProperty(true)
                  }}
                >
                  Add Property
                </Link>
              </div>
            </div>
            <Box className="profile_detail">
              {property_data?.map((data, index) => (
                <div className="profileTypeSection">
                  <div
                    className="radioBox "
                    onClick={() => {
                      setDeleteStepDiff(3);
                      setSelectedPropertyData(data);
                    }}
                  >
                    <input
                      type="radio"
                      id="individual"
                      name="propertytype"
                      defaultChecked={index === 0}
                    />
                    <label>
                      <img src="./images/property1.svg" alt="" />{" "}
                    </label>
                    <h6>{data.propertyType}</h6>
                    <p>{data.city}</p>
                  </div>
                </div>
              ))}
              <Grid container spacing={3}>
                {selectedPropertyData?._id ? (
                  <Grid xs={12}>
                    <ul className="property_details">
                      <li>
                        <p>
                          Property Type
                          <span>{selectedPropertyData.buildingType}</span>
                        </p>
                      </li>
                      <li>
                        <p>
                          Property Age
                          <span>{selectedPropertyData.propertyAge}</span>
                        </p>
                      </li>
                      <li>
                        <p>
                          Building/Street Name
                          <span>{selectedPropertyData.streetName}</span>
                        </p>
                      </li>
                      <li>
                        <p>
                          Area Name<span>{selectedPropertyData.areaName}</span>
                        </p>
                      </li>
                      <li>
                        <p>
                          Country<span>{selectedPropertyData.countryName}</span>
                        </p>
                      </li>
                      <li>
                        <p>
                          State<span>{selectedPropertyData.stateName}</span>
                        </p>
                      </li>
                      <li>
                        <p>
                          City<span>{selectedPropertyData.city}</span>
                        </p>
                      </li>
                      <li>
                        <p>
                          Zip Code<span>{selectedPropertyData.zipCode}</span>
                        </p>
                      </li>
                    </ul>
                  </Grid>
                ) : null}
              </Grid>
            </Box>
          </Box>
          {/* Employee KYC Documents */}
          <Box className="detailSection" ref={employeeKYC}>
            <div className="sectionTitle">
              <h4>Employee KYC Documents</h4>
              <div className="actionBtns ">
                <Link
                  className="blueBtn addfile"
                  sx={{
                    textDecoration: "none",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (!editPermission) { return toast.error("Permissions Not Allowed") }
                    setAddKycEmployee(true)
                  }}
                >
                  Add KYC Document
                </Link>
              </div>
            </div>
            <Box>
              <Grid container spacing={3}>
                <Grid xs={12}>
                  <div className="table">
                    <div
                      className="attatchment_table"
                      style={{ overflow: "hidden" }}
                    >
                      <ul className="heading">
                        <li>File Name</li>
                        <li>Document Type</li>
                        <li>Description</li>
                        <li>Date</li>
                        <li>Actions</li>
                      </ul>

                      {employee_kyc_data?.length > 0 && employee_kyc_data?.map((data, index) => (
                        <ul className="subheading">
                          <li>{data.doc_name}</li>
                          <li>{data.doctType || data.doc_type}</li>
                          <li>{data.description}</li>
                          <li>
                            <p className="date">
                              {updateTimeFormat(data.date, user_type_dateFormat)}
                            </p>
                          </li>
                          <li
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                            }}
                          >
                            <Link
                              className="blueBtn download ml-0"
                              // href={data.downloadLink}
                              onClick={()=>downloadAttachement(data.downloadLink,userType)}
                              target="_blank"
                              sx={{
                                textDecoration: "none",
                                cursor: "pointer",
                              }}
                            ></Link>
                            <Link
                              className="redBtn delete"
                              onClick={() => {
                                if (!editPermission) { return toast.error("Permissions Not Allowed") }
                                setKyc_id(data._id);
                                setRemovePopup(true);
                                setDeleteStepDiff(5);
                              }}
                              sx={{
                                textDecoration: "none",
                                cursor: "pointer",
                              }}
                            ></Link>
                          </li>
                        </ul>
                      ))}
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Box>
            <AddFamily
              attrName={setAddFamily}
              open_status={addFamily}
              value_update={updateMasterState}
              apiUrl={URL_CONSTANTS.create_family_detail(userType.toLocaleLowerCase)}
              filterDropDown={true}
              addedMemberData={family_data}
            />
            <AddProperty
              attrName={setAddProperty}
              open_status={addProperty}
              value_update={updateMasterState}
            />
            <EditProperty
              open_status={editProperty}
              attrName={setEditProperty}
              value_update={updateMasterState}
              data={selectedPropertyData}
            />
          </Box>
        </Grid>
      </Grid>
      <AddFamilyKyc
        attrName={setAddKyc}
        open_status={addKyc}
        value_update={updateMasterState}
        id={selectedFamilyData?._id}
        apiUrl={''}
      />
      <AddFamilyKyc
        attrName={setAddKycEmployee}
        open_status={addKycEmployee}
        value_update={updateMasterState}
        id={""}
        apiUrl={URL_CONSTANTS.create_kyc_detail}
      />
      <GlobalDeletePopup
        open_status={removePopup}
        value_update={updateMasterState}
        attrName={setRemovePopup}
        delete_step={deleteStatusStep}
        delete_item={() => {
          get_vehicle_detail(id ?? "");
          delete_property_vehicle();
        }}
        ids={deleteStatusStep != 1 || 2 || 3 ? [{ ids: "123" }] : ids}
        title="Data"
      />
    </>
  );
}

export default EmployeeDetailProfile;
