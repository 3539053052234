import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Box, Button, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { URL_CONSTANTS } from "../../../../../../APIVar/URLConstants";
import { ACCESS_LEVEL_SERVICES } from "../../../../../../Services/Settings/AccessLevelServices";
import { get_client_list, validateEmail, } from "../../../../../../Supporting files/HelpingFunction";
import "../../../../../../formLayout.scss";
import { useAppSelector } from "../../../../../../Store/hooks";
import RKTextField from "../../../../../../Supporting files/RKTextField/RKTextField";
import SearchDropdown from "../../../../../../Supporting files/SearchDropdown/SearchDropdown";
import SelectDropdown from "../../../../../../Supporting files/SelectDropdown/SelectDropdown";
import { EMPLOYEE_SERVICES } from "../../../../../../Services/Employee/EmployeeService";

function CreateUsers() {
  const navigate = useNavigate();
  const [disableSave, setDisableSave] = useState<boolean>(false);
  const [usersRole, setUsersRole] = useState<any>([]);
  const [hidePassword, setHidePassword] = useState<any>(false);
  const [officialMailId, setOfficialMailId] = useState<any>("");
  const [sectionStatus, SetSectionStatus] = useState<string>("userInformation");
  const [subSection, setSubSection] = useState<string>("basics");
  const [editUser, setEditUser] = useState<any>({
    name: "",
    email: "",
    dob: null,
    roleName: "",
    branch: "",
    department: "",
    clientName: "",
    client: { label: "", id: "" },
    warnName: false,
    warnEmail: false,
    warnRoleName: false,
    warnBranch: false,
    warnDepartment: false,
  });

  const [department_data, setDepartment_data] = useState<Array<{
    id: string;
    label: string;
  }>>([]);
  const [branch_data, setBranch_data] = useState<Array<{
    id: string;
    label: string;
  }>>([]);
  const userDetails = useAppSelector((state: any) => state.userDetailsSlice);
  const [password, setPassword] = useState("");
  const [warn_password, setWarn_Password] = useState(false);
  const [clientListData, setClientListData] = useState<Array<{
    checkbox_status: boolean;
    label: string;
    id: string;
  }>>([]);
  const userInformation = useRef(null);
  const basics = useRef(null);
  const [trueConditions, setTrueConditions] = useState(0);
  const [passwordValidityObj, setPasswordValidityObj] = useState<{
    isValid: boolean;
    containsNameOrEmail: boolean;
    hasMinLength: boolean;
    hasSymbolOrNumber: boolean;
  }>({
    containsNameOrEmail: false,
    hasMinLength: false,
    hasSymbolOrNumber: false,
    isValid: false,
  });
  const isValidPassword = () => {
    const containsNameOrEmail = password.includes(editUser?.email);
    // password.includes(userName) ||
    // password.includes(userEmail);
    const hasMinLength = password.length >= 8;
    const symbolOrNumberRegex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?0-9]+/;
    const hasSymbolOrNumber = symbolOrNumberRegex.test(password);
    const isValid = !containsNameOrEmail && hasMinLength && hasSymbolOrNumber;
    const returnObj: any = {
      isValid,
      containsNameOrEmail,
      hasMinLength,
      hasSymbolOrNumber,
    };
    let numTrues: number = 0;
    for (let i = 0; i < Object.keys(returnObj).length; i++) {
      if (returnObj[Object.keys(returnObj)[i]]) {
        numTrues += 1;
      }
    }

    setTrueConditions(numTrues);
    setPasswordValidityObj(returnObj);
  };

  useEffect(() => {
    isValidPassword();
  }, [password]);

  useEffect(() => {
    // ACCESS_LEVEL_SERVICES.user_data_by_id
    const windowURL = window.location.search;
    const params = new URLSearchParams(windowURL);
    const id = params.get("id");
    user_data(id ?? "");
    get_Dropdown_branch_list(URL_CONSTANTS.get_branch_dropdown_list);
    get_Dropdown_branch_list(URL_CONSTANTS.get_department_dropdown_list);
    client_list();
    user_role_data();
    // setTimeout(() => {
    //   setPassword("");
    // }, 1000);
  }, []);

  const get_Dropdown_branch_list = (url: string) => {
    const onSuccess = (res: any) => {
      if (url === URL_CONSTANTS.get_branch_dropdown_list) {
        setBranch_data(res.data.data);
      } else if (url === URL_CONSTANTS.get_department_dropdown_list) {
        setDepartment_data(res.data.data.map((data: any) => ({ value: data.label, key: data.id })));

      }
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    ACCESS_LEVEL_SERVICES.get_branch_department_dropdown_list(
      url,
      onSuccess,
      onError
    );
  };

  const user_data = (id: string) => {
    const onSuccess = (res: any) => {
      setEditUser(res.data.data[0]);
      setClientListData(res.data.data[0].clientName);
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    ACCESS_LEVEL_SERVICES.user_data_by_id(id, onSuccess, onError);
  };

  const updateMasterState = (attrName: any, value: any) => {
    let mainState = attrName[0];
    let key = attrName[1];
    if (mainState === "editUser") {
      if (key === "email") {
        setOfficialMailId(value)
      }
      setEditUser({
        ...editUser,
        [key]: value,
        [`warn${key.charAt(0).toUpperCase()}${key.slice(1)}`]: false,
      });
    } else {
      attrName(value);
      setWarn_Password(false);
    }
  };
  const scrollToSection = (elementRef: any) => { elementRef.current?.scrollIntoView({ behavior: "smooth" }); };

  const handleClicks = (ref: any) => { ref.current?.scrollIntoView({ behavior: "smooth" }); };

  const client_list = () => {
    const onSuccess = (res: any) => {
      let data: any = [];
      res.data.data?.forEach((e: any) => {
        data.push({
          ...e,
          checkbox_status: false,
        });
      });
      setClientListData(data);
    };
    const onError = (err: any) => {
      console.log("false", err);
    };
    get_client_list(userDetails?.userType?.toLowerCase(), onSuccess, onError);
  };

  // user role data
  const user_role_data = () => {
    const onSuccess = (res: any) => {
      let statusData = res.data.data.map((data: any) => { return { label: data.id, value: data.label } })
      setUsersRole(statusData);
    };
    const onError = (err: any) => {
      console.log("false", err);
    };
    ACCESS_LEVEL_SERVICES.get_user_role_data("", onSuccess, onError);
  };
  const detail_form_submit = () => {
    if (disableSave) return;
    const currentDate = new Date();
    const year = currentDate.getFullYear().toString().slice(-2);
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    const dynamicPart = Math.random().toString().slice(2, 6);
    let companyId = `USER${month}${year}${dynamicPart}`;
    let data: any = editUser;
    data.warnName = editUser?.name.length === 0 ? true : false;
    data.warnEmail = !validateEmail(editUser.email);
    data.warnRoleName = editUser?.roleName === "" ? true : false;
    data.warnBranch = editUser.branch === "" ? true : false;
    data.warnDepartment = editUser.department === "" ? true : false;
    setWarn_Password(password === "" ? true : false);
    setEditUser({ ...data });
    if (
      (hidePassword || trueConditions === 3) &&
      data.warnName === false &&
      data.warnEmail === false &&
      data.warnRoleName === false &&
      data.warnBranch === false &&
      data.warnDepartment === false &&
      (hidePassword || password !== "")
    ) {
      let params = {
        companyCode: companyId,
        parentsId: userDetails.parentsId || userDetails.companyCode,
        name: editUser.name,
        email: editUser.email,
        username: editUser.email,
        userType: userDetails.userType,
        role: editUser.roleName,
        branch: editUser.branch.id ? editUser.branch.id : editUser.branch,
        Department: editUser.department.id ?? editUser.department,
        client: "All",
        clientName: [],
        password: hidePassword ? "123456" : password,
      };
      const onSuccess = (res: any) => {
        navigate(-1);
        toast.success("User Created Successfully");
      };
      const onError = (err: any) => {
        setDisableSave(false);
        toast.error(err.response.data.errors[0].message);
      };
      setDisableSave(true);
      ACCESS_LEVEL_SERVICES.create_new_user(params, onSuccess, onError);
    }
  };
  const checkMailIsExist = (currentValue: string) => {
    const onSuccess = (res: any) => {
      if (res.data.message === "Email not used previously" || res.data.data.passwordExists === false) {
        return setHidePassword(false)
      }
      if (res.data.data.passwordExists === true) {
        setHidePassword(true)
        toast.info(res.data.message, {
          autoClose: false,
        });

      }


    };
    const onError = (err: any) => {
      toast.error(err?.response?.data?.errors[0]?.message);
    };

    EMPLOYEE_SERVICES.checkIsMailUsed(
      onSuccess,
      onError,
      currentValue,
      userDetails.userType.toLowerCase(),
      "user"
    );
  }

  let timeoutId: any;
  useEffect(() => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    const delayedApiCall = () => {
      if (officialMailId) {
        checkMailIsExist(officialMailId);
      }

    };

    timeoutId = setTimeout(delayedApiCall, 1000);

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [officialMailId]);

  return (
    <Box className="layoutWrapper">
      {/* Page header */}
      <Grid container spacing={3} className="pageHeader">
        <Grid xs={6} className="pageTitle">
          <Link
            className="backStep"
            onClick={() => {
              navigate(-1);
            }}>
            <ChevronLeftIcon />
          </Link>
          <div>
            <h3>Create New User</h3>
          </div>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid xs={12}>
          <Grid container spacing={3}>
            <Grid xs className="leftSection">
              <Link
                className={
                  sectionStatus === "userInformation"
                    ? "active sectionLink userInformation"
                    : "sectionLink userInformation"
                }
                onClick={() => {
                  handleClicks(sectionStatus);
                  scrollToSection(userInformation);
                  SetSectionStatus("userInformation");
                }}>
                <span> User Information</span>
              </Link>
              <ul className="accordion_list">
                <li>
                  <Link
                    className={
                      subSection === "basics"
                        ? "inner_link active basics"
                        : "inner_link  basics"
                    }
                    onClick={() => {
                      handleClicks(subSection);
                      scrollToSection(basics);
                      setSubSection("basics");
                    }}>
                    Basic Info
                  </Link>
                </li>
              </ul>
            </Grid>
            <Grid xs className="rightSection">
              <Box className="detailSection" ref={userInformation}>
                <div onMouseEnter={() => { setSubSection("basics") }} ref={basics}>
                  <div className="sectionTitle">
                    <h4>User Information</h4>
                  </div>
                  <Grid container spacing={3}>
                    <Grid xs={12}>
                      <h5 className="subHeading">Basic Info</h5>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid sm={6} md={4} lg={3} xl={3}>
                      <RKTextField
                        required
                        validation_type="name"
                        class_name="inputField"
                        title={"Name"}
                        value={editUser?.name}
                        attrName={["editUser", "name"]}
                        value_update={updateMasterState}
                        warn_status={editUser?.warnName}
                      />
                    </Grid>
                    <Grid sm={6} md={4} lg={3} xl={3}>
                      <RKTextField
                        required
                        validation_type="email"
                        class_name="inputField"
                        title={"User ID / E-mail"}
                        value={editUser?.email}
                        attrName={["editUser", "email"]}
                        value_update={updateMasterState}
                        warn_status={editUser?.warnEmail}
                      />
                    </Grid>
                    {/* <Grid sm={6} md={4} lg={3} xl={3}>
                      <RKTextField
                        validation_type="email"
                        class_name="inputField"
                        title={"Support Email"}
                        value={editUser?.support_email}
                        attrName={["editUser", "support_email"]}
                        value_update={updateMasterState}
                        warn_status={editUser?.warnSupportEmail}
                      />
                    </Grid> */}
                    <Grid sm={6} md={4} lg={3} xl={3}>
                      <SelectDropdown
                        required
                        class_name="inputField"
                        title="Role"
                        value={editUser?.roleName === undefined ? "" : editUser?.roleName}
                        attrName={["editUser", "roleName"]}
                        value_update={updateMasterState}
                        dropdown_data={usersRole}
                        warn_status={editUser?.warnRoleName}
                        error_messg="Select Role"
                      />
                    </Grid>
                    <Grid sm={6} md={4} lg={3} xl={3}>
                      <SearchDropdown
                        required
                        class_name="inputField"
                        title="Branch"
                        value={editUser?.branch === undefined ? "" : editUser?.branch}
                        attrName={["editUser", "branch"]}
                        value_update={updateMasterState}
                        data={branch_data}
                        warn_status={editUser.warnBranch}
                        error_message="Select Branch"
                        info_status={true}
                        info_message="This is the branch list that you have created in the Organisation Settings"
                      />
                    </Grid>
                    {/* <Grid sm={6} md={5} lg={3} xl={3}>
                      <SearchDropdown
                        class_name="inputField"
                        title={"Department"}
                        value={editUser?.department}
                        attrName={["editUser", "department"]}
                        value_update={updateMasterState}
                        data={department_data}
                        warn_status={editUser.warnDepartment}
                        error_message="Select department"
                        info_status={true}
                        info_message="This is the department list that you have created in the Organisation Settings"
                      />
                    </Grid> */}
                    <Grid sm={6} md={5} lg={3} xl={3}>
                      <SelectDropdown
                        required
                        class_name="inputField"
                        title={"Department"}
                        value={editUser?.department}
                        attrName={["editUser", "department"]}
                        value_update={(attrName: any, value: any) => { updateMasterState(attrName, value.value) }}
                        dropdown_data={department_data}
                        error_messg="Select department"
                        warn_status={editUser.warnDepartment}
                        value_type_id_value
                      />
                    </Grid>
                    {!hidePassword &&
                      <Grid sm={6} md={5} lg={3} xl={3}>
                        <RKTextField
                          password_status
                          required
                          class_name="inputField"
                          title="Password"
                          value={password}
                          attrName={setPassword}
                          value_update={updateMasterState}
                          warn_status={warn_password}
                          error_messg="Enter Password"
                          type="password"
                          autoComplete="new-password"
                        />
                      </Grid>
                    }
                  </Grid>
                </div>
                {!hidePassword && (
                  <Grid xs={12}>
                    <div className="confirmation_content">
                      <p>
                        Password Strength:{" "}
                        {trueConditions >= 3 ? (
                          <span>Strong</span>
                        ) : trueConditions <= 1 ? (
                          <span style={{ color: "red" }}>Weak</span>
                        ) : (
                          <span style={{ color: "yellow" }}>Medium</span>
                        )}
                      </p>
                      <ul>
                        {passwordValidityObj.containsNameOrEmail ? (
                          <li className="error">
                            Must not contain your name or email
                          </li>
                        ) : (
                          <li className="success">
                            Must not contain your name or email
                          </li>
                        )}
                        {passwordValidityObj.hasMinLength ? (
                          <li className="success">At least 8 characters</li>
                        ) : (
                          <li className="error">At least 8 characters</li>
                        )}
                        {passwordValidityObj.hasSymbolOrNumber ? (
                          <li className="success">
                            Contains a symbol or a number
                          </li>
                        ) : (
                          <li className="error">Contains a symbol or a number</li>
                        )}
                      </ul>
                    </div>
                  </Grid>
                )}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* Footer Section */}
      <Grid container spacing={3} className="footerSection">
        <Grid xs={12} className="ctaBtn">
          <Button
            variant="outlined"
            className="borderBtn"
            onClick={() => {
              navigate(-1);
            }}>
            Cancel
          </Button>

          <Button
            variant="contained"
            className="submitBtn" disabled={disableSave}
            onClick={detail_form_submit}>
            Save
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

export default CreateUsers;
