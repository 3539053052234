import React from "react";
import "../Supporting.scss";

interface Props {
  title: any;
  name?: string;
  value: boolean;
  attrName: any;
  id?: string;
  value_update: Function;
  default_check: boolean;
  disable?: boolean;
}

const CheckBox: React.FC<Props> = ({
  title,
  name,
  id,
  value,
  attrName,
  value_update,
  default_check,
  disable,
}) => {
  return (
    <div className="checkBox">
      <input
        id={id}
        type="checkbox"
        checked={value}
        name={name}
        disabled={disable}
        onChange={(e) => {
          value_update(attrName, e.target.checked);
        }}
      />
      <label htmlFor={id}>{title}</label>
    </div>
  );
};

export default CheckBox;
