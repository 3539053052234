import CloseIcon from "@mui/icons-material/Close";
import { Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useEffect } from "react";
import SlidingPanel from "react-sliding-side-panel";
import { GROUP_HEALTH_SERVICES } from "../../../../../Services/ProductConfigurator/GroupHealthServices";
import RKTextField from "../../../../../Supporting files/RKTextField/RKTextField";
import TextAreaField from "../../../../../Supporting files/TextAreaField/TextAreaField";
import { GROUP_SUPER_TOPUP } from "../../../../../Services/ProductConfigurator/GroupSuperTopUp";

interface Props {
  open_status: boolean;
  attrName: any;
  value_update: Function;
  table_data: any;
  api_url: string;
  shortcutDisable?: boolean, setShortcutDisable?: Function
}

const EditExculsions: React.FC<Props> = ({
  open_status,
  attrName,
  value_update,
  table_data,
  api_url,
  shortcutDisable, setShortcutDisable
}) => {
  const [editExclusion, setEditExclusion] = React.useState<{
    exclusion: {
      title: string;
      description: string;
    };
  }>(table_data);

  const updateMasterState = (attrName: any, value: any) => {
    if (attrName[1] === "title") {
      attrName[0].title = value;
    }
    if (attrName[1] === "description") {
      attrName[0].exclusion.description = value;
    }
    setEditExclusion({ ...editExclusion });
  };

  // edit catageory
  const updateCategory = () => {
    const onSuccess = (res: any) => {
      value_update(attrName, false);
      if (setShortcutDisable) {
        setShortcutDisable(false);
      }
    };
    const onError = (err: any) => {
      console.log("err", err);
    };

    GROUP_SUPER_TOPUP.updateExclusion(
      api_url,
      {
        exclusion: {
          title: editExclusion.exclusion.title,
          description: editExclusion.exclusion.description,
        },
      },
      onSuccess,
      {},
      onError,
      table_data?.id
    );
  };

  useEffect(() => {
    setEditExclusion({
      exclusion: {
        title: table_data.name,
        description: table_data.description,
      },
    });
  }, [table_data]);

  const removePopup = () => {
    value_update(attrName, false);
    setEditExclusion({ ...editExclusion });
  };

  return (
    <SlidingPanel type={"right"} isOpen={open_status} size={75}>
      <div>
        <Grid container spacing={3} alignItems="center" className="mt-0">
          <Grid xs={10}>
            <h4 className="mb-0">Edit Exclusion</h4>
          </Grid>
          <Grid xs={2} className="pt-3 text-right">
            <CloseIcon onClick={removePopup} />
          </Grid>
          <Grid xs={12}>
            <hr />
          </Grid>
        </Grid>
        <div className="scrollable_area">
          <Grid container spacing={3}>
            <Grid xs={8}>
              <RKTextField
                class_name="inputField"
                title={"Exclusion Name"}
                value={editExclusion.exclusion.title}
                attrName={[editExclusion?.exclusion, "title"]}
                value_update={updateMasterState}
                warn_status={
                  editExclusion?.exclusion?.title?.length === 0 ? true : false
                }
                error_messg={"Enter Exclusion name"}
              />
            </Grid>
            <Grid xs={12}>
              <TextAreaField
                class_name="inputField multiline"
                title={"Brief Description"}
                value={editExclusion.exclusion.description}
                attrName={[editExclusion, "description"]}
                value_update={updateMasterState}
                warn_status={
                  editExclusion?.exclusion?.description?.length === 0 ? true : false
                }
                error_messg={"Please describe the exclusion"}
                alphanumeric={true}
                specialcharacter={true}
              />
            </Grid>
          </Grid>
        </div>
        <Grid container spacing={3}>
          <Grid xs={12} className="ctaBtn mt-4">
            <Button
              variant="contained"
              className="submitBtn fixedbtn"
              onClick={updateCategory}
            >
              Update
            </Button>
          </Grid>
        </Grid>
      </div>
    </SlidingPanel>
  );
};

export default EditExculsions;
