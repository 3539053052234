import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useState } from "react";
import SlidingPanel from "react-sliding-side-panel";
import { toast } from "react-toastify";
import RKTextField from "../../../../../../../Supporting files/RKTextField/RKTextField";


interface Props {
  open_status: boolean;
  attrName: any;
  value_update: Function;
  update_api_url?: any;
}

const ChangePassword: React.FC<Props> = ({
  open_status,
  attrName,
  value_update,
  update_api_url,
}) => {

  const [password, setPassword] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [passwordWarnMessage, setPasswordWarnMessage] = useState("");

  const [passwordWarn, setPasswordWarn] = useState({
    oldPassword: false,
    newPassword: false,
    confirmPassword: false,
  });

  const [pageStatus, setPageStatus] = useState<number>(1);

  const updateMasterState = (attrName: any, value: any) => {
    let key = attrName[0];
    let mainkey = attrName[1];
    if (key === "password") {
      setPassword({ ...password, [mainkey]: value });
      setPasswordWarn({ ...passwordWarn, [mainkey]: false });
    }
  };


  //change password
  // const update_password = () => {
  //   let data: any = passwordWarn;
  //   data.oldPassword = password.oldPassword ? false : true;
  //   data.newPassword = (password.newPassword === password.oldPassword || !password.newPassword) ? true : false;
  //   // data.newPassword = password.newPassword ? false : true;
  //   data.confirmPassword = password.confirmPassword ? false : true;
  //   data.confirmPassword = password.confirmPassword === password.newPassword ? false : true;

  //   setPasswordWarn({ ...data });
  //   if (password.newPassword === password.confirmPassword) {
  //     setPasswordWarnMessage("Please enter confirm password");
  //   } else {
  //     setPasswordWarnMessage("Password and confirm password should be same");
  //   }
  //   if (
  //     data.oldPassword === false &&
  //     data.newPassword === false &&
  //     data.confirmPassword === false &&
  //     password.newPassword === password.confirmPassword
  //   ) {
  //     let params: any = {
  //       old: password.oldPassword,
  //       new: password.newPassword,
  //       confirm: password.confirmPassword,
  //     };

  //     const onSuccess = (res: any) => {
  //       setPageStatus(pageStatus + 1);
  //       setTimeout(() => {
  //         setPageStatus(1);
  //       }, 2000);
  //       setTimeout(() => {
  //         value_update(attrName, false);
  //       }, 2000);
  //       setPassword({
  //         oldPassword: "",
  //         newPassword: "",
  //         confirmPassword: "",
  //       });
  //     };
  //     const onError = (err: any) => {
  //       toast.error(err.response.data.errors[0].message);
  //     };
  //     update_api_url(params, onSuccess, onError);
  //   }
  // };
  const update_password = () => {
    // Clone passwordWarn to avoid direct mutation
    let data: any = { ...passwordWarn };
  
    // Validate old password
    data.oldPassword = password.oldPassword ? false : true;
    
    // Validate new password
    data.newPassword = (password.newPassword === password.oldPassword || !password.newPassword) ? true : false;
    
    // Validate confirm password
    data.confirmPassword = password.confirmPassword ? false : true;
    data.confirmPassword = password.confirmPassword === password.newPassword ? false : true;
  
    // Update state with new warnings
    setPasswordWarn(data);
  
    // Set warning message based on password and confirmPassword match
    if (!password.newPassword) {
      setPasswordWarnMessage("Please enter a new password");
    } else if (password.newPassword !== password.confirmPassword) {
      setPasswordWarnMessage("Password and confirm password should be the same");
    } else {
      setPasswordWarnMessage("");
    }
  
    // Check if all conditions are met before making the API call
    if (
      !data.oldPassword &&
      !data.newPassword &&
      !data.confirmPassword &&
      password.newPassword === password.confirmPassword
    ) {
      const params = {
        old: password.oldPassword,
        new: password.newPassword,
        confirm: password.confirmPassword,
      };
  
      const onSuccess = (res: any) => {
        setPageStatus(pageStatus + 1);
        setTimeout(() => {
          setPageStatus(1);
        }, 2000);
        setTimeout(() => {
          value_update(attrName, false);
        }, 2000);
        setPassword({
          oldPassword: "",
          newPassword: "",
          confirmPassword: "",
        });
      };
  
      const onError = (err: any) => {
        toast.error(err.response.data.errors[0].message);
      };
  
      if (typeof update_api_url === 'function') {
        update_api_url(params, onSuccess, onError);
      } else {
        console.error("update_api_url function is not provided");
      }
    }
  };
  
  return (
    <SlidingPanel
      type={"right"}
      isOpen={open_status}
      size={75}
      panelClassName="slidingPanel"

    >
      <Box className="pt-3 pl-3 pr-3">
        <div>
          <Grid container spacing={3} alignItems="center">
            <Grid xs={8}>
              <h4 className="mb-0 text-center">Change Password</h4>
              <Link
                className="close-button"
                onClick={() => {
                  value_update(attrName, false);
                  setPassword({
                    oldPassword: "",
                    newPassword: "",
                    confirmPassword: "",
                  });
                }}
              />
            </Grid>
            <Grid xs={4} className="pt-3 text-right">
              <CloseIcon onClick={() => {
                value_update("setChangePassword", false);
                setPasswordWarnMessage("");
                setPassword({
                  oldPassword: "",
                  newPassword: "",
                  confirmPassword: "",
                });
                setPasswordWarn({
                  oldPassword: false,
                  newPassword: false,
                  confirmPassword: false,
                });
              }} />
            </Grid>
            <Grid xs={12} className="mb-5">
              <hr />
            </Grid>
          </Grid>
          <div className="scrollable_area">
            {pageStatus !== 2 ? (
              <Grid
                container
                spacing={2}
                justifyContent="center"
                className="mb-3"
              >
                <Grid xs={12}>
                  <RKTextField
                    class_name="inputField"
                    title={"Old Password"}
                    value={password.oldPassword}
                    attrName={["password", "oldPassword"]}
                    value_update={updateMasterState}
                    warn_status={passwordWarn.oldPassword}
                  />
                </Grid>
                <Grid xs={12}>
                  <RKTextField
                    class_name="inputField"
                    title={"New Password"}
                    value={password.newPassword}
                    attrName={["password", "newPassword"]}
                    value_update={updateMasterState}
                    warn_status={passwordWarn.newPassword}
                    error_messg={
                      (password.newPassword?.length > 0) && password.newPassword === password.oldPassword ?
                        "Old Password & New Password should't be same" : "Enter New Password"
                    }
                  />
                </Grid>
                <Grid xs={12}>
                  <RKTextField
                    class_name="inputField"
                    title={"Confirm Password"}
                    value={password.confirmPassword}
                    attrName={["password", "confirmPassword"]}
                    value_update={updateMasterState}
                    warn_status={passwordWarn.confirmPassword}
                    error_messg={passwordWarnMessage}
                  />
                </Grid>
              </Grid>
            ) : null}
            {pageStatus === 2 ? (
              <Grid
                container
                spacing={2}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <Grid xs={12}>
                  <svg
                    className="checkmark"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 52 52"
                  >
                    <circle
                      className="checkmark__circle"
                      cx="26"
                      cy="26"
                      r="25"
                      fill="none"
                    />
                    <path
                      className="checkmark__check"
                      fill="none"
                      d="M14.1 27.2l7.1 7.2 16.7-16.8"
                    />
                  </svg>
                  <p className="quote_success text-center">
                    Your Password has been added updated successfully!
                  </p>
                </Grid>
              </Grid>
            ) : null}
            {pageStatus !== 2 ? (
              <Grid container spacing={3}>
                <Grid xs={12} className="ctaBtn text-center">
                  <Button
                    variant="contained"
                    className="submitBtn"
                    onClick={() => {
                      update_password();
                    }}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            ) : null}
          </div>
        </div>
      </Box>
    </SlidingPanel>
  );
};

export default ChangePassword;
