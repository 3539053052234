import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import SlidingPanel from "react-sliding-side-panel";
import RKTextFieldDisable from "../../../../../../../Supporting files/RKTextFieldDisable/RKTextFieldDisable";
import RKTextField from "../../../../../../../Supporting files/RKTextField/RKTextField";
import { Button, Box, Link, Modal } from "@mui/material";
import { DEPARTMENT_SERVICES } from "../../../../../../../Services/customModule/departmentServices";
import { toast } from "react-toastify";
import { GridCloseIcon } from "@mui/x-data-grid";

interface Props {
  open_status: boolean;
  attrName: any;
  value_update: Function;
  departmentName: any;
  departmentId: any;
}

const EditDepartmentInformation: React.FC<Props> = ({
  open_status,
  attrName,
  value_update,
  departmentName,
  departmentId,
}) => {
  const [disableSave, setDisableSave] = useState<boolean>(false);
  const [departmentNamedata, setDepartmentNamedata] = React.useState("");
  const [departmentWarn, setDepartmentWarn] = React.useState(false);
  const updateMasterState = (attrName: any, value: any) => {
    let key = attrName[0];
    if (key === "department") {
      setDepartmentNamedata(value);
      setDepartmentWarn(false);
    }
  };

  //update department data
  const update_department_data = () => {
    if (disableSave) return;
    let data: any = departmentWarn;
    data = departmentNamedata.length === 0 ? true : false;
    setDepartmentWarn(data);

    if (data === false) {
      let body = {
        departmentName: departmentNamedata,
      };
      const onSuccess = (res: any) => {
        value_update("EditcloseButton", false);
        toast.success(" Updated successfully");
        setDisableSave(false);
      };
      const onError = (err: any) => {
        setDisableSave(false);
        console.log("Branches", err);
      };
      setDisableSave(true);
      DEPARTMENT_SERVICES.update_department(
        body,
        onSuccess,
        onError,
        departmentId
      );
    }
  };

  function handleKeyPress(event: any) {
    if (event.key === "Enter") {
      update_department_data();
    }
  }

  useEffect(() => {
    setDepartmentNamedata(departmentName);
  }, [open_status, departmentName]);

  return (
    <SlidingPanel
      type={"right"}
      isOpen={open_status}
      size={75}
      backdropClicked={() => value_update(attrName, false)}
    >
      <div onKeyDown={handleKeyPress} tabIndex={0}>
        <Grid container spacing={3} alignItems="center">
          <Grid xs={10}>
            <h4 className="mb-0 mt-4">Edit Department</h4>
          </Grid>
          <Grid xs={2} textAlign="end" className="mt-3">
            <GridCloseIcon
              onClick={() => value_update("EditcloseButton", false)}
            />
          </Grid>
          <Grid xs={12} className="mb-5">
            <hr />
          </Grid>
        </Grid>

        <div className="scrollable_area">
          <Grid container spacing={3}>
            <Grid xs={12}>
              <RKTextField
                class_name="inputField"
                title="Department Name"
                value={departmentNamedata}
                attrName={["department"]}
                value_update={updateMasterState}
                warn_status={departmentWarn}
              />
            </Grid>
          </Grid>
        </div>
        <Grid container spacing={3}>
          <Grid xs={12} className="ctaBtn mt-4">
            <Button
              variant="contained"
              className="submitBtn fixedbtn"
              onClick={update_department_data}
              disabled={disableSave}
            >
              Update
            </Button>
          </Grid>
        </Grid>
      </div>
    </SlidingPanel>
  );
};

export default EditDepartmentInformation;
