import { Button, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useEffect, useState } from "react";
import { URL_CONSTANTS } from "../../../../../APIVar/URLConstants";
import AddCategory from "../../../../../Components/Broker/ProductConfigurator/Common/Categories/AddCategory";
import EditCategory from "../../../../../Components/Broker/ProductConfigurator/Common/Categories/EditCategory";
import RemoveCategoryPopup from "../../../../../Components/Broker/ProductConfigurator/Common/Categories/RemoveCategoryPopup";
import AddExclusions from "../../../../../Components/Broker/ProductConfigurator/Common/Exclusions/AddExclusions";
import EditExculsions from "../../../../../Components/Broker/ProductConfigurator/Common/Exclusions/EditExclusions";
import RemoveExclusionPopup from "../../../../../Components/Broker/ProductConfigurator/Common/Exclusions/RemoveExclusionPopup";
import AddFeatures from "../../../../../Components/Broker/ProductConfigurator/Common/Features/AddFeatures";
import EditFeature from "../../../../../Components/Broker/ProductConfigurator/Common/Features/EditFeature";
import RemoveFeaturePopup from "../../../../../Components/Broker/ProductConfigurator/Common/Features/RemoveFeaturePopup";
import { groupTravelInsuranceFeatureAddDTO } from "../../../../../Services/DTO/ProductConfigurator/GroupTravelInsuranceDTO";
import { E_BE_FILTER_CODE } from "../../../../../Services/Enum/E_UGTable";
import { GROUP_TRAVEL_INSURANCE_SERVICES } from "../../../../../Services/ProductConfigurator/GroupTravelInsuranceService";
import { useAppSelector } from "../../../../../Store/hooks";
import SearchBox from "../../../../../Supporting files/SearchBarAnimation/SearchBox";
import UGTable from "../../../../../Supporting files/UGTable/UGTable";
import "../ProductConfig.scss";
import { GROUP_HEALTH_SERVICES } from "../../../../../Services/ProductConfigurator/GroupHealthServices";
import PoliciesWarningpopup from "../../../../../Components/Broker/WarningForPlicies/PoliciesWarningpopup";
import { date_time_format } from "../../../../../Supporting files/HelpingFunction";
import { RootState } from "../../../../../Store/Store";

function GroupTravelInsurance() {
  const user_type_dateFormat = useAppSelector(
    (state) => state.userDetailsSlice.localInformation.dateFormat
  );
  const tz = useAppSelector(
    (state: RootState) => state.userDetailsSlice.localInformation.timeZone
  );
  const [productTabsStatus, setProductTabsStatus] =
    React.useState("Categories");
  const [searchBarFilter, setsearchBarFilter] = useState("");
  const [warningpopup, setwarningpopup] = useState<boolean>(false);

  const [removeCategory, setRemoveCategory] = React.useState(false);
  //Features Variables
  const [addNewFeatures, setAddNewFeatures] = React.useState(false);
  const [addExclusions, setAddExclusions] = React.useState(false);
  const [editExclusions, setEditExclusions] = React.useState(false);
  const [addNewCategory, setAddNewCategory] = React.useState(false);
  const [editCategory, setEditCategory] = React.useState(false);
  const [removeExclusion, setRemoveExclusion] = React.useState(false);
  const [removeFeature, setRemoveFeature] = React.useState(false);
  const [sendFeatureData, setSendFetureData] = useState<any>({});
  const [editFeature, setEditFeature] = React.useState(false);
  const [isFeatureAssigned, setIsFeatureAssigned] = React.useState(false);
  const [isExclusionAssigned, setIsExclusionAssigned] = React.useState(false);
  const [sendEditData, setSendEditData] = React.useState<{
    category: {
      name: string;
      description: string;
    };
    _id: string;
  }>();

  const { BROKER } = useAppSelector((state) => state.permissionSlice);

  const [productConfiratorAddPermission, setProductConfiratorAddPermission] =
    useState(false);
  const [
    productConfiratorRemovePermission,
    setProductConfiratorRemovePermission,
  ] = useState(false);
  useEffect(() => {
    setProductConfiratorAddPermission(
      BROKER?.ProductConfigurator.add === undefined
        ? false
        : BROKER.ProductConfigurator.add
    );
    setProductConfiratorRemovePermission(
      BROKER?.ProductConfigurator.remove === undefined
        ? false
        : BROKER.ProductConfigurator.remove
    );
  }, []);
  const [array_delete, setArray_Delete] = useState<Array<{ ids: number }>>([]);
  let selected_data: Array<{ id: number }> = [];
  const [sendEditExclusionsData, setSendEditExclusionsData] = React.useState<{
    exclusion: {
      title: string;
      description: string;
    };
    _id: string;
  }>();

  const [rowsCategory, setRowsCategory] = React.useState<
    Array<{
      id: Number;
      sr_no: Number;
      category_name: string;
      description: string;
      action_btns: string;
      deleteable: boolean;
    }>
  >([]);

  const [rowFeature, setRowFeature] = React.useState<
    groupTravelInsuranceFeatureAddDTO[]
  >([]);
  //Insurer Data
  const [insurerColumns, setInsurerColumns] = React.useState([
    {
      field: "sr_no",
      headerName: "Sr. No.",
      width: 80,
      check_status: true,
    },
    {
      field: "insurer_name",
      headerName: "Insurer Name",
      flex: 3,
      minWidth: 340,
      check_status: true,
    },
    {
      field: "website",
      headerName: "Website",
      minWidth: 320,
      check_status: true,
      renderCell: (ValueFormatterParams: any) => {
        const { row } = ValueFormatterParams;
        return (
          <Link href={row.website} target="_blank" underline="none">
            {row.website}
          </Link>
        );
      },
    },
    {
      field: "network_hospitals",
      headerName: "Network Hospitals",
      minWidth: 160,
      check_status: true,
    },
    {
      field: "action_btns",
      headerName: "Action",
      width: 120,
      check_status: true,
      renderCell: (ValueFormatterParams: any) => {
        const { row } = ValueFormatterParams;
        return (
          <Link
            className="blueBtn edit ml-0"
            underline="none"
            onClick={() => {}}
          >
            Edit
          </Link>
        );
      },
    },
  ]);
  const insurerrows = [
    {
      id: 0,
      sr_no: "1",
      insurer_name: "Aviva Life Insurance Co. India Ltd.",
      website: "https://www.avivaindia.com/",
      network_hospitals: "5,000",
      action_btns: "Edit",
    },
    {
      id: 1,
      sr_no: "2",
      insurer_name:
        "Canara HSBC Oriental Bank of Commerce Life Insurance Company Limited",
      website: "https://www.canarahsbclife.com/index.html",
      network_hospitals: "10,000",
      action_btns: "Edit",
    },
    {
      id: 2,
      sr_no: "3",
      insurer_name: "Kotak Mahindra General Insurance Co Ltd",
      website: "https://www.kotakgeneralinsurance.com/",
      network_hospitals: "15,000",
      action_btns: "Edit",
    },
    {
      id: 3,
      sr_no: "4",
      insurer_name: "Royal Sundaram General Insurance Co Ltd",
      website: "https://www.royalsundaram.in/",
      network_hospitals: "8,000",
      action_btns: "Edit",
    },
  ];
  //Features Data
  const [featureColumns, setFeatureColumns] = React.useState([
    {
      field: "categoryName",
      headerName: "Category Name",
      width: 300,
      check_status: true,
      renderCell: (ValueFormatterParams: any) => {
        const { row } = ValueFormatterParams;
        return (
          <span className="">
            {row.categoryName
              ? row.categoryName
              : "Category not avialable"}
          </span>
        );
      },
    },
    {
      field: "name",
      headerName: "Feature Name",
      width: 300,
      check_status: true,
    },
    {
      field: "fieldType",
      headerName: "Field Type",
      width: 200,
      check_status: true,
    },
    {
      field: "valueType",
      headerName: "Value Type",
      width: 200,
      check_status: true,
    },
    {
      field: "unit",
      headerName: "Unit",
      width: 150,
      check_status: true,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 3,
      minWidth: 400,
      check_status: true,
      renderCell: (ValueFormatterParams: any) => {
        const { row } = ValueFormatterParams;
        return <span className="truncate_text">{row.description.length > 100 ? row.description.substring(0, 100) + "..." : row.description}</span>;
      },
    },
    {
      field: "createdAt",
      headerName: "Created at",
      width: 200,
      check_status: true,
      renderCell: (ValueFormatterParams: any) => {
        const { row } = ValueFormatterParams;
        return <p>{date_time_format(row.createdAt, user_type_dateFormat, tz)}</p>;
      },
    },
    {
      field: "action_btns",
      headerName: "Action",
      width: 150,
      check_status: true,
      renderCell: (ValueFormatterParams: any) => {
        const { row } = ValueFormatterParams;
        return (
          <Link
            className="blueBtn edit ml-0"
            underline="none"
            onClick={() => {
              setSendFetureData(row);
              setEditFeature(true);
            }}
          >
            Edit
          </Link>
        );
      },
    },
  ]);

  const [columns, setColumns] = React.useState([
    {
      field: "name",
      headerName: "Category Name",
      width: 300,
      check_status: true,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 3,
      minWidth: 150,
      check_status: true,
    },
    {
      field: "createdAt",
      headerName: "Created at",
      width: 200,
      check_status: true,
      renderCell: (ValueFormatterParams: any) => {
        const { row } = ValueFormatterParams;
        return <p>{date_time_format(row.createdAt, user_type_dateFormat, tz)}</p>;
      },
    },
    {
      field: "_id",
      headerName: "Action",
      width: 150,
      check_status: true,
      renderCell: (ValueFormatterParams: any) => {
        const { row } = ValueFormatterParams;
        return (
          <Link
            className="blueBtn edit ml-0"
            underline="none"
            onClick={() => {
              setSendEditData(row);
              setEditCategory(true);
            }}
          >
            Edit
          </Link>
        );
      },
    },
  ]);
  //Exclusions Data
  const [exclusionColumns, setExclusionColumns] = React.useState([
    {
      field: "name",
      headerName: "Category Name",
      width: 300,
      check_status: true,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 3,
      minWidth: 150,
      check_status: true,

    },    {
      field: "createdAt",
      minWidth: 200,
      check_status: true,
      headerName: "Created At",
      renderCell: (ValueFormatterParams: any) => {
        const { row } = ValueFormatterParams;
        return (
          <span>
            {date_time_format(row.createdAt, user_type_dateFormat, tz)}
          </span>
        );
      },
    },
    {
      field: "updatedAt",
      minWidth: 200,
      check_status: true,
      headerName: "Updated At",
      renderCell: (ValueFormatterParams: any) => {
        const { row } = ValueFormatterParams;
        return (
          <span>
            {date_time_format(row.updatedAt, user_type_dateFormat, tz)}
          </span>
        );
      },
    },
    {
      field: "_id",
      headerName: "Action",
      width: 150,
      check_status: true,
      renderCell: (ValueFormatterParams: any) => {
        const { row } = ValueFormatterParams;
        return (
          <Link
            className="blueBtn edit ml-0"
            underline="none"
            onClick={() => {
              setSendEditExclusionsData(row);
              setEditExclusions(true);
            }}
          >
            Edit
          </Link>
        );
      },
    },
  ]);
  const [rowExclusion, setRowExclusion] = React.useState<
    Array<{
      id: Number;
      title: String;
      description: string;
      action_btns: string;
      deleteable: boolean;
    }>
  >([]);
  // get category list
  useEffect(() => {
    if (addNewCategory === false || removeCategory === false)
      getCatageoryetails();
  }, [addNewCategory, removeCategory, editCategory]);
  // get feature detail
  useEffect(() => {
    if (addNewFeatures === false || removeFeature === false)
      getFeatureDetails();
  }, [addNewFeatures, removeFeature, editFeature]);
  useEffect(() => {
    if (addExclusions === false || removeExclusion === false)
      getExclusionDetails();
  }, [addExclusions, removeExclusion, editExclusions]);
  const updateMasterState = (attrName: any, value: any) => {
    if (attrName === setAddNewFeatures) {
      setAddNewFeatures(value);
    } else if (attrName === "table_data") {
      setRowsCategory(value);
    } else if (attrName === "data_feature") {
      setRowFeature(value);
    } else if (attrName === "selected_data") {
      selected_data = value;
      setArray_Delete(value);
    } else if (attrName === "searchBarfilter") {
      setsearchBarFilter(value);
    } else {
      attrName(value);
    }
  };
  // get category details
  const getCatageoryetails = () => {
    const onSuccess = (res: any) => {
      let arrdata: any = [];
      res.data.data.forEach((e: any) => {
        arrdata.push({ id: e._id, name: e.category.name, description: e.category.description, createdAt: e.createdAt,updatedAt:e.updatedAt });
      });
      setRowsCategory(arrdata.reverse());
    };
    const onError = (err: any) => {
      console.log("false", err);
    };
    GROUP_TRAVEL_INSURANCE_SERVICES.getCatageory(
      searchBarFilter,
      onSuccess,
      onError
    );
  };

  // get feature
  const getFeatureDetails = () => {
    const onSuccess = (res: any) => {
      let arrdata: any = res.data.data.map((e: any) => {
        return {
          id: e._id,
          categoryName: e.categoryId.category.name,
          dropDownOptions: e.featureDetails?.dropDownOptions,
          name: e.featureDetails?.name,
          fieldType: e.featureDetails?.fieldType,
          valueType: e.featureDetails?.valueType,
          unit: e.featureDetails?.unit,
          customUnitType: e.featureDetails?.customUnitType,
          description: e.featureDetails?.description,
          createdAt: e.createdAt,
          updatedAt:e.updatedAt
        };
      });
      setRowFeature(arrdata);
    };
    const onError = (err: any) => {
      console.log("false", err);
    };
    GROUP_TRAVEL_INSURANCE_SERVICES.getFeature(
      searchBarFilter,
      onSuccess,
      onError
    );
  };

  // get exclusions deatils
  const getExclusionDetails = () => {
    const onSuccess = (res: any) => {
      let arrdata: any = res.data.data.map((e: any) => {
        return { id: e._id, name: e.exclusion.title, description: e.exclusion.description, createdAt: e.createdAt,updatedAt:e.updatedAt };
      });

      setRowExclusion(arrdata);
    };
    const onError = (err: any) => {
      console.log("false", err);
    };
    GROUP_TRAVEL_INSURANCE_SERVICES.getExculsion(
      searchBarFilter,
      onSuccess,
      onError
    );
  };

  let timeoutId: any;
  useEffect(() => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    const delayedApiCall = () => {
      getCatageoryetails();
      getFeatureDetails();
      getExclusionDetails();
    };

    timeoutId = setTimeout(delayedApiCall, 500);

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [searchBarFilter]);

  useEffect(() => {
    let exclusionAssigned = false;

    if (productTabsStatus === "Exclusions") {
      array_delete?.forEach((item: any, index: number) => {
        const onSuccess = (res: any) => {
          if (!exclusionAssigned) {
            exclusionAssigned = res.data.data.isAttached;
            setIsExclusionAssigned(exclusionAssigned);
          }
        };

        const onError = (err: any) => {
          console.log("false", err);
        };

        if (!exclusionAssigned) {
          GROUP_HEALTH_SERVICES.isexclusionAssigned(item, onSuccess, onError);
        }
      });
    }

    let featureAssigned = false;

    if (productTabsStatus === "Features") {
      array_delete?.forEach((item: any, index: number) => {
        const onSuccess = (res: any) => {
          if (!featureAssigned) {
            featureAssigned = res.data.data.isAttached;
            setIsFeatureAssigned(featureAssigned);
          }
        };

        const onError = (err: any) => {
          console.log("false", err);
        };

        if (!featureAssigned) {
          GROUP_HEALTH_SERVICES.isfeatureAssigned(item, onSuccess, onError);
        }
      });
    }
  }, [array_delete]);

  return (
    <Grid container spacing={3}>
      <Grid xs={12}>
        <h3 className="mb-0">Group Travel Insurance</h3>
      </Grid>

      <>
        <Grid xs={8} style={{ display: "flex", alignItems: "center" }}>
          <div className="productTabs mr-3">
            <Button
              className={
                productTabsStatus === "Categories"
                  ? "defaultTab active"
                  : "defaultTab"
              }
              onClick={() => {
                updateMasterState(setProductTabsStatus, "Categories");
              }}
            >
              Categories
            </Button>
            <Button
              className={
                productTabsStatus === "Features"
                  ? "defaultTab active"
                  : "defaultTab"
              }
              onClick={() => {
                updateMasterState(setProductTabsStatus, "Features");
              }}
            >
              Features
            </Button>
            <Button
              className={
                productTabsStatus === "Exclusions"
                  ? "defaultTab active"
                  : "defaultTab"
              }
              onClick={() => {
                updateMasterState(setProductTabsStatus, "Exclusions");
              }}
            >
              Exclusions
            </Button>
            {/* <Button
                className={
                  productTabsStatus === "Insurers"
                    ? "defaultTab active"
                    : "defaultTab"
                }
                onClick={() => {
                  updateMasterState(setProductTabsStatus, "Insurers");
                  setProductTabsStatus("Insurers");
                }}>
                Insurers
              </Button> */}
          </div>
          <SearchBox value_update={updateMasterState} />
        </Grid>
        <Grid xs={4} className="text-right">
          {productTabsStatus === "Categories" ? (
            <>
              {productConfiratorRemovePermission && (
                <Link
                  className="redBtn delete"
                  underline="none"
                  onClick={() => {
                    setRemoveCategory(true);
                  }}
                >
                  Remove
                </Link>
              )}
              {productConfiratorAddPermission && (
                <Link
                  className="greenBtn addfile"
                  underline="none"
                  onClick={() => {
                    setAddNewCategory(true);
                  }}
                >
                  Add New
                </Link>
              )}
            </>
          ) : null}
          {productTabsStatus === "Features" ? (
            <>
              {productConfiratorRemovePermission && (
                <Link
                  className={
                    isFeatureAssigned ? `redBtnlight delete` : `redBtn delete`
                  }
                  underline="none"
                  onClick={() => {
                    isFeatureAssigned
                      ? setwarningpopup(true)
                      : setRemoveFeature(true);
                  }}
                >
                  Remove
                </Link>
              )}
              {productConfiratorAddPermission && (
                <Link
                  className="greenBtn addfile"
                  underline="none"
                  onClick={() => {
                    updateMasterState(setAddNewFeatures, true);
                  }}
                >
                  Add New
                </Link>
              )}
            </>
          ) : null}
          {!isExclusionAssigned && productTabsStatus === "Exclusions" ? (
            <>
              {productConfiratorRemovePermission && (
                <Link
                  className={
                    isExclusionAssigned ? `redBtnlight delete` : `redBtn delete`
                  }
                  underline="none"
                  onClick={() => {
                    isExclusionAssigned
                      ? setwarningpopup(true)
                      : setRemoveExclusion(true);
                  }}
                >
                  Remove
                </Link>
              )}
              {productConfiratorAddPermission && (
                <Link
                  className="greenBtn addfile"
                  underline="none"
                  onClick={() => {
                    updateMasterState(setAddExclusions, true);
                  }}
                >
                  Add New
                </Link>
              )}
            </>
          ) : null}
          {productTabsStatus === "Insurers" ? <></> : null}
        </Grid>
        <Grid xs={12}>
          {productTabsStatus === "Categories" ? (
            <div style={{ height: 400, width: "100%" }}>
              <UGTable
                header_data={columns}
                data={rowsCategory}
                value_update={updateMasterState}
                attrName={"UGTable"}
                BE_filter_code={
                  E_BE_FILTER_CODE.GROUP_TRAVEL_INSURANCE_CATEGORIES
                }
              />
            </div>
          ) : null}
          {productTabsStatus === "Features" ? (
            <div style={{ height: 400, width: "100%" }}>
              <UGTable
                header_data={featureColumns}
                data={rowFeature}
                value_update={updateMasterState}
                attrName={"UGTable"}
                BE_filter_code={
                  E_BE_FILTER_CODE.GROUP_TRAVEL_INSURANCE_FEATURES
                }
              />
            </div>
          ) : null}
          {productTabsStatus === "Exclusions" ? (
            <div style={{ height: 400, width: "100%" }}>
              <UGTable
                header_data={exclusionColumns}
                data={rowExclusion}
                value_update={updateMasterState}
                attrName={"UGTable"}
                BE_filter_code={
                  E_BE_FILTER_CODE.GROUP_TRAVEL_INSURANCE_EXCLUSIONS
                }
              />
            </div>
          ) : null}
          {productTabsStatus === "Insurers" ? (
            <div style={{ height: 400, width: "100%" }}>
              <UGTable
                header_data={insurerColumns}
                data={insurerrows}
                value_update={updateMasterState}
                attrName={"UGTable"}
              />
            </div>
          ) : null}
        </Grid>
      </>
      <AddCategory
        attrName={setAddNewCategory}
        value_update={updateMasterState}
        open_status={addNewCategory}
        api_url={URL_CONSTANTS.create_group_travel_catageory}
      />
      <EditCategory
        attrName={setEditCategory}
        value_update={updateMasterState}
        open_status={editCategory}
        table_data={
          sendEditData === undefined
            ? {
                category: {
                  name: "",
                  description: "",
                },
              }
            : sendEditData
        }
        api_url={URL_CONSTANTS.update_group_travel_catageory}
      />
      <RemoveCategoryPopup
        open_status={removeCategory}
        attrName={setRemoveCategory}
        value_update={updateMasterState}
        ids={array_delete}
        get_api_url={URL_CONSTANTS.delete_group_travel_catageory}
      />
      <AddExclusions
        open_status={addExclusions}
        attrName={setAddExclusions}
        value_update={updateMasterState}
        api_url={URL_CONSTANTS.create_group_travel_exclusion}
      />
      <PoliciesWarningpopup
        popupStatus={warningpopup}
        setPopupStatus={setwarningpopup}
        title={`One of the ${productTabsStatus} is assigned to the policy, so you will not be able to delete that ${productTabsStatus}`}
      />
      <EditExculsions
        open_status={editExclusions}
        attrName={setEditExclusions}
        value_update={updateMasterState}
        table_data={
          sendEditExclusionsData === undefined
            ? {
                exclusion: {
                  title: "",
                  description: "",
                },
              }
            : sendEditExclusionsData
        }
        api_url={URL_CONSTANTS.update_group_travel_exclusion}
      />
      <RemoveExclusionPopup
        open_status={removeExclusion}
        attrName={setRemoveExclusion}
        value_update={updateMasterState}
        ids={array_delete}
        get_api_url={URL_CONSTANTS.delete_group_travel_exclusion}
      />
      <AddFeatures
        open_status={addNewFeatures}
        attrName={setAddNewFeatures}
        value_update={updateMasterState}
        api_url={URL_CONSTANTS.create_group_travel_feature}
        get_category_api_url={URL_CONSTANTS.get_group_travel_catageory}
      />
      <EditFeature
        open_status={editFeature}
        attrName={setEditFeature}
        value_update={updateMasterState}
        table_data={
          sendFeatureData === undefined
            ? {
                categoryId: "",
                featureDetails: {
                  name: "",
                  fieldType: "",
                  valueType: "",
                  unit: "",
                  description: "",
                },
              }
            : sendFeatureData
        }
        api_url={URL_CONSTANTS.update_group_travel_feature}
        get_category_api_url={URL_CONSTANTS.get_group_travel_catageory}
      />
      <RemoveFeaturePopup
        open_status={removeFeature}
        attrName={setRemoveFeature}
        value_update={updateMasterState}
        ids={array_delete}
        get_api_url={URL_CONSTANTS.delete_group_travel_feature}
      />
    </Grid>
  );
}

export default GroupTravelInsurance;
