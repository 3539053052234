import React, { useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Unstable_Grid2";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import "../../../../../../../formLayout.scss";
import { Link, Box, Button, Modal } from "@mui/material";
import RKTextField from "../../../../../../../Supporting files/RKTextField/RKTextField";
import SearchDropdown from "../../../../../../../Supporting files/SearchDropdown/SearchDropdown";
import { BRANCHES_SERVICES } from "../../../../../../../Services/customModule/branchesServices";
import {
  get_city_list,
  get_city_list_via_name,
  get_country_list,
} from "../../../../../../../Supporting files/HelpingFunction";
import { get_state_list } from "../../../../../../../Supporting files/HelpingFunction";
import { toast } from "react-toastify";

interface Props {
  open_status: boolean;
  attrName: any;
  value_update: Function;
}

function CreateBranch() {
  const navigate = useNavigate();
  const [deleteStatus, setDeleteStatus] = React.useState(false);
  const [sectionStatus, SetSectionStatus] =
    React.useState<string>("userInformation");
  const [subSection, setSubSection] = React.useState<string>("basics");
  const [countryData, setCountryData] = React.useState<
    Array<{ label: string; id: string }>
  >([]);
  const [stateData, setStateData] = React.useState<
    Array<{ label: string; id: string }>
  >([]);
  const [cityData, setCityData] = React.useState<
    Array<{ label: string; id: string }>
  >([]);
  const [postBranchesdata, setpostBranchesdata] = React.useState<{
    locationType: any;
    locationName: any;
    building: any;
    area: any;
    country: any;
    state: any;
    city: any;
    pinCode: any;
  }>({
    locationType: "",
    locationName: "",
    building: "",
    area: "",
    country: "",
    state: "",
    city: "",
    pinCode: "",
  });
  const [branchWarnStatus, setbranchWarnStatus] = React.useState<{
    locationType: boolean;
    locationName: boolean;
    building: boolean;
    area: boolean;
    country: boolean;
    state: boolean;
    city: boolean;
    pinCode: boolean;
  }>({
    locationType: false,
    locationName: false,
    building: false,
    area: false,
    country: false,
    state: false,
    city: false,
    pinCode: false,
  });
  const [selectedCountryStateCity, setselectedCountryStateCity] =
    React.useState<{
      country: any;
      state: any;
      city: any;
    }>({
      country: "",
      state: "",
      city: "",
    });

  const updateMasterState = (attrName: any, value: any) => {
    let keys = attrName[0];
    let mainstate = attrName[1];
    if (mainstate === "inputfields") {
      setbranchWarnStatus({ ...branchWarnStatus, [keys]: false });
      setpostBranchesdata({ ...postBranchesdata, [keys]: value });
      let label = attrName[2];
      if (label === "label") {
        setpostBranchesdata({ ...postBranchesdata, [keys]: value?.label });
        if (keys === "country" || keys === "state" || keys === "city") {
          setselectedCountryStateCity({
            ...selectedCountryStateCity,
            [keys]: value?.label,
          });
          if (keys === "country") {
            let country = { id: "", label: value?.label };
            get_state_list(country, (cb: any) => {
              setStateData(cb);
            });
          } else if (keys === "state") {
            let state = { id: "", label: value?.label };
            let country = { id: "", label: selectedCountryStateCity.country };
            get_city_list_via_name(country, state, (cb: any) => {
              setCityData(cb);
            });
          }
        }
      }
    }
  };

  //post Branches data
  const post_branches_data = () => {
    let data: any = branchWarnStatus;
    data.locationType =
      postBranchesdata.locationType.length === 0 ? true : false;

    data.locationName =
      postBranchesdata.locationName.length === 0 ? true : false;
    data.building = postBranchesdata.building.length === 0 ? true : false;
    data.area = postBranchesdata.area.length === 0 ? false : false;
    data.country = postBranchesdata.country.length === 0 ? true : false;
    data.state = postBranchesdata.state.length === 0 ? true : false;
    data.city = postBranchesdata.city.length === 0 ? true : false;
    data.pinCode = postBranchesdata.pinCode.length === 6 ? false : true;
    setbranchWarnStatus({ ...data });

    if (
      data.locationType === false &&
      data.locationName === false &&
      data.building === false &&
      data.country === false &&
      data.state === false &&
      data.city === false &&
      data.pinCode === false
    ) {
      const onSuccess = (res: any) => {
        toast.success(" Saved successfully");
        navigate(-1);
      };
      const onError = (err: any) => {
        console.log("Branches", err);
      };
      BRANCHES_SERVICES.post_employer_branch_data(postBranchesdata, onSuccess, onError);
    }
  };

  // for scrolling
  const userInformation = useRef(null);
  const basics = useRef(null);
  const clientInfo = useRef(null);

  const scrollToSection = (elementRef: any) => {
    //  ;
    elementRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleClicks = (ref: any) => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  function handleKeyPress(event: any) {
    if (event.key === "Enter") {
      post_branches_data();
    }
  }

  useEffect(() => {
    get_country_list((cb: any) => {
      setCountryData(cb);
    });
  }, []);

  return (
    <Box className="layoutWrapper" onKeyDown={handleKeyPress} tabIndex={0}>
      {/* Page header */}
      <Grid container spacing={3} className="pageHeader">
        <Grid xs={6} className="pageTitle">
          <Link
            className="backStep"
            onClick={() => {
              navigate(-1);
            }}>
            <ChevronLeftIcon />
          </Link>

          <div>
            <h3>Create New Branch</h3>
          </div>
        </Grid>
        <Grid xs={6}></Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid xs={12}>
          <Grid container spacing={3}>
            <Grid xs className="leftSection">
              <Link
                className={
                  sectionStatus === "userInformation"
                    ? "active sectionLink userInformation"
                    : "sectionLink userInformation"
                }
                onClick={() => {
                  handleClicks(sectionStatus);
                  scrollToSection(userInformation);
                  SetSectionStatus("userInformation");
                }}>
                <span> Branch Information</span>
              </Link>
              <ul className="accordion_list">
                <li>
                  <Link
                    className={
                      subSection === "basics"
                        ? "inner_link active basics"
                        : "inner_link  basics"
                    }
                    onClick={() => {
                      handleClicks(subSection);
                      scrollToSection(basics);
                      setSubSection("basics");
                    }}>
                    Basic Info
                  </Link>
                </li>
                <li>
                  <Link
                    className={
                      subSection === "clientInfo"
                        ? "inner_link active clientInfo"
                        : "inner_link clientInfo"
                    }
                    onClick={() => {
                      setSubSection("clientInfo");
                      handleClicks(subSection);
                      scrollToSection(clientInfo);
                    }}>
                    Address
                  </Link>
                </li>
              </ul>
            </Grid>
            <Grid xs className="rightSection">
              <Box className="detailSection" ref={userInformation}>
                <div ref={basics}>
                  <div className="sectionTitle">
                    <h4>Branch Information</h4>
                  </div>
                  <Grid container spacing={3}>
                    <Grid xs={12}>
                      <h5 className="subHeading">Basic Info</h5>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid sm={6} md={4} lg={3} xl={3}>
                      <RKTextField
                        class_name="inputField"
                        title={"Location Name"}
                        value={postBranchesdata.locationName}
                        attrName={["locationName", "inputfields"]}
                        value_update={updateMasterState}
                        warn_status={branchWarnStatus.locationName}
                      />
                    </Grid>
                    <Grid sm={6} md={4} lg={3} xl={3}>
                      <RKTextField
                        class_name="inputField"
                        title={"Location Type"}
                        value={postBranchesdata.locationType}
                        attrName={["locationType", "inputfields"]}
                        value_update={updateMasterState}
                        warn_status={branchWarnStatus.locationType}
                      />
                    </Grid>
                  </Grid>
                </div>
                <div ref={clientInfo}>
                  <Grid container spacing={3}>
                    <Grid xs={12}>
                      <h5 className="subHeading">Address</h5>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid sm={6} md={6} lg={6} xl={6}>
                      <RKTextField
                        class_name="inputField"
                        title={"Building/Street Name"}
                        value={postBranchesdata.building}
                        attrName={["building", "inputfields"]}
                        value_update={updateMasterState}
                        warn_status={branchWarnStatus.building}
                      />
                    </Grid>
                    <Grid sm={6} md={6} lg={6} xl={6}>
                      <RKTextField
                        class_name="inputField"
                        title={"Area Name"}
                        value={postBranchesdata.area}
                        attrName={["area", "inputfields"]}
                        value_update={updateMasterState}
                        warn_status={false}
                      />
                    </Grid>
                    <Grid sm={6} md={5} lg={3} xl={3}>
                      <SearchDropdown
                        class_name="inputField"
                        title="Country"
                        value={postBranchesdata.country}
                        attrName={["country", "inputfields", "label"]}
                        value_update={updateMasterState}
                        data={countryData}
                        warn_status={branchWarnStatus.country}
                        error_message="Please select country"
                      />
                    </Grid>
                    <Grid sm={6} md={5} lg={3} xl={3}>
                      <SearchDropdown
                        class_name="inputField"
                        title="State"
                        value={postBranchesdata.state}
                        attrName={["state", "inputfields", "label"]}
                        value_update={updateMasterState}
                        data={stateData}
                        warn_status={branchWarnStatus.state}
                        error_message="Please select country"
                      />
                    </Grid>
                    <Grid sm={6} md={5} lg={3} xl={3}>
                      <SearchDropdown
                        class_name="inputField"
                        title="City"
                        value={postBranchesdata.city}
                        attrName={["city", "inputfields", "label"]}
                        value_update={updateMasterState}
                        data={cityData}
                        warn_status={branchWarnStatus.city}
                        error_message="Please select country"
                      />
                    </Grid>
                    <Grid sm={6} md={4} lg={3} xl={3}>
                      <RKTextField
                        class_name="inputField"
                        title={"Pincode"}
                        value={postBranchesdata.pinCode}
                        attrName={["pinCode", "inputfields"]}
                        value_update={updateMasterState}
                        warn_status={branchWarnStatus.pinCode}
                      />
                    </Grid>
                  </Grid>
                </div>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* Footer Section */}
      <Grid container spacing={3} className="footerSection">
        <Grid xs={12} className="ctaBtn">
          <Button variant="outlined" className="borderBtn">
            Cancel
          </Button>

          <Button
            variant="contained"
            className="submitBtn"
            onClick={post_branches_data}>
            Save
          </Button>
        </Grid>
      </Grid>

      <Modal open={deleteStatus} className="modalWrapper">
        <Box className="modalInner">
          <Grid container spacing={1}>
            <Grid xs={12}>
              <Link
                className="close-button"
                onClick={() => {
                  setDeleteStatus(false);
                }}
              />
              <h5 className="popup-heading">Are you sure ?</h5>
            </Grid>
            <Grid xs={12}>
              <Link className="md-link" underline="none">
                You are about to Delete this Attatchment.
              </Link>
            </Grid>
            <Grid xs={12} className="ctaBtn" textAlign={"center"}>
              <Button variant="contained" className="submitBtn">
                Yes, Delete
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </Box>
  );
}

export default CreateBranch;
