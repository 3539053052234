import Axios from "axios";
import { ALL_ROUTES } from "../Routes/all_routes";
// import { AUTH_SERVICE } from "./AuthService";

export default class AxiosAPIInstance {
  public api;
  public cancelApi;
  constructor() {
    const user_token = sessionStorage.getItem("usertoken");
    if (!user_token) {
      window.location.pathname = ALL_ROUTES.LOGIN;
    }
    const CancelToken = Axios.CancelToken;
    const source = CancelToken.source();
    this.api = Axios.create({
      cancelToken: source.token,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        // "Content-Type": "multipart/form-data",
        // "Access-Token": access_token,
        auth: user_token,
      },
    });
    this.api.interceptors.response.use(
      (config) => config,
      (err) => this.handleError(err)
    );
    this.cancelApi = source.cancel;
  }
  private handleError(error:any) {
    const originalConfig = error.config;

    if (error?.response?.status === 401 && !originalConfig._retry) {
      window.location.href = ALL_ROUTES.LOGIN;
      originalConfig._retry = true;

      // If you need to handle token refresh logic, include it here
      // AUTH_SERVICE.getRefreshToken(onSuccess, onError);

      return Promise.reject(error);
    }

    if (error?.response?.status === 400 || error?.response?.status === 409) {
      return Promise.reject(error);
    }

    return Promise.reject(error);
  }
}
