import { Box, Button, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useState } from "react";
import SlidingPanel from "react-sliding-side-panel";
import CloseIcon from "@mui/icons-material/Close";
import EmployeeCarForm from "./EmployeeCarForm/EmployeeCarForm";
import EmployeeBikeForm from "./EmployeeBikeForm/EmployeeBikeForm";
import EmployeeTermForm from "./EmployeeTermForm/EmployeeTermForm";
import EmployeeTravelForm from "./EmployeeTravelForm/EmployeeTravelForm";
import EmployeeHealthForm from "./EmployeeHealthForm/EmployeeHealthForm";
import EmployeeInvestForm from "./EmployeeInvestForm.tsx/EmployeeInvestForm";
 // Defining Props interface for component
interface Props {
  open_status: boolean;
  attrName: any;
  value_update: Function;
}

const AddRetailPolicy: React.FC<Props> = ({
  open_status,
  attrName,
  value_update,
}) => {
  const [carForm, setCarForm] = React.useState<boolean>(false);
  const [bikeForm, setBikeForm] = React.useState<boolean>(false);
  const [healthForm, setHealthForm] = React.useState<boolean>(false);
  const [termForm, setTermForm] = React.useState<boolean>(false);
  const [investForm, setInvestForm] = React.useState<boolean>(false);
  const [travelForm, setTravelForm] = React.useState<boolean>(false);
  const [activeClass, setActiveClass] = React.useState("");

  
  return (
    <>
      <SlidingPanel
        type={"right"}
        isOpen={open_status}
        size={75}
        panelClassName="slidingPanel"
        // backdropClicked={() => value_update(attrName, false)}
      >
        <>
          <Grid container spacing={2} alignItems="center">
            <Grid xs={10} className="headingTitle">
              <h3>
                <span>Add Retail Policy</span>
              </h3>
            </Grid>
            <Grid xs={2} textAlign="right">
              <Link
                className="closeIcon"
                onClick={() => {
                  value_update(attrName, false);
                }}
              >
                <CloseIcon />
              </Link>
            </Grid>
            <Grid xs={12}>
              <hr />
            </Grid>
          </Grid>
          <Box className="scrollable_area">
            <Grid container spacing={3}>
              <Grid xs={12}>
                <div className="productTypeSection">
                  <div
                    className="radioBox "
                    onClick={() => {
                      setHealthForm(true);
                      setCarForm(false);
                      setBikeForm(false);
                      setTermForm(false);
                      setInvestForm(false);
                      setTravelForm(false);
                      setActiveClass("health");
                    }}
                  >
                    <span
                      className={
                        activeClass === "health"
                          ? "active producttype health"
                          : "producttype health"
                      }
                    ></span>
                    {/* <input type="radio" id="individual" name="plantype" />
                    <label className="producttype health"></label> */}
                    <h6>Health</h6>
                  </div>

                  <div
                    className="radioBox"
                    onClick={() => {
                      setCarForm(true);
                      setHealthForm(true);
                      setBikeForm(false);
                      setTermForm(false);
                      setInvestForm(false);
                      setTravelForm(false);
                      setActiveClass("car");
                    }}
                  >
                    <span
                      className={
                        activeClass === "car"
                          ? "active producttype car"
                          : "producttype car"
                      }
                    ></span>
                    <h6>Car</h6>
                  </div>

                  <div
                    className="radioBox "
                    onClick={() => {
                      setBikeForm(true);
                      setHealthForm(true);
                      setCarForm(false);
                      setTermForm(false);
                      setInvestForm(false);
                      setTravelForm(false);
                      setActiveClass("bike");
                    }}
                  >
                    <span
                      className={
                        activeClass === "bike"
                          ? "active producttype bike"
                          : "producttype bike"
                      }
                    ></span>
                    <h6> Bike</h6>
                  </div>
                  <div
                    className="radioBox "
                    onClick={() => {
                      setTermForm(true);
                      setHealthForm(true);
                      setCarForm(false);
                      setBikeForm(false);
                      setInvestForm(false);
                      setTravelForm(false);
                      setActiveClass("term");
                    }}
                  >
                    <span
                      className={
                        activeClass === "term"
                          ? "active producttype term"
                          : "producttype term"
                      }
                    ></span>
                    <h6>Term</h6>
                  </div>
                  <div
                    className="radioBox "
                    onClick={() => {
                      setInvestForm(true);
                      setHealthForm(true);
                      setCarForm(false);
                      setBikeForm(false);
                      setTermForm(false);
                      setTravelForm(false);
                      setActiveClass("nonterm");
                    }}
                  >
                    <span
                      className={
                        activeClass === "nonterm"
                          ? "active producttype nonterm"
                          : "producttype nonterm"
                      }
                    ></span>
                    <h6>Invest</h6>
                  </div>
                  <div
                    className="radioBox "
                    onClick={() => {
                      setTravelForm(true);
                      setHealthForm(true);
                      setCarForm(false);
                      setBikeForm(false);
                      setTermForm(false);
                      setInvestForm(false);
                      setActiveClass("travel");
                    }}
                  >
                    <span
                      className={
                        activeClass === "travel"
                          ? "active producttype travel"
                          : "producttype travel"
                      }
                    ></span>
                    <h6>Travel</h6>
                  </div>
                </div>
              </Grid>

              {carForm === true ? (
                <EmployeeCarForm />
              ) : bikeForm === true ? (
                <EmployeeBikeForm />
              ) : termForm === true ? (
                <EmployeeTermForm />
              ) : investForm === true ? (
                <EmployeeInvestForm />
              ) : travelForm === true ? (
                <EmployeeTravelForm />
              ) : healthForm === true ? (
                <EmployeeHealthForm />
              ) : (
                ""
              )}
            </Grid>
          </Box>
          <Grid container spacing={3}>
            <Grid xs={12} className="ctaBtn mt-4">
              <Button
                variant="contained"
                className="submitBtn fixedbtn"
                onClick={() => {}}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </>
      </SlidingPanel>
    </>
  );
};

export default AddRetailPolicy;
