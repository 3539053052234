import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React from "react";
import { RootState } from "../../../Store/Store";
import { useAppSelector } from "../../../Store/hooks";
import { date_time_format } from "../../../Supporting files/HelpingFunction";
import "../NotesSection/NotesSection.scss";
import moment from "moment";

interface Props {
  data: Array<{ name: string; date: Date | string; description?: string }>;
}

const NotesSection: React.FC<Props> = ({ data }) => {
  const { dateFormat, timeZone } = useAppSelector((state: RootState) => state.userDetailsSlice.localInformation);
  return (
    <Box>
      {data?.map((data, index) => (
        <div className="notessection">
          <Grid container spacing={2}>
            <Grid xs={12}>
              <h6 className="username">{data?.name}</h6>
              <p className="creation_date">{
                date_time_format(
                  data?.date,
                  dateFormat,
                  timeZone)
              }</p>
              <p className="usernotes">{data?.description}</p>
            </Grid>
          </Grid>
        </div>
      ))}
    </Box>
  );
};

export default NotesSection;
