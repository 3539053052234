import { EMPLOYEE_URL_CONSTANTS } from "../../../APIVar/EmployeeUrlConstant";
import { EMPLOYER_URL_CONSTANTS } from "../../../APIVar/EmployerUrlConstant";

import { DeleteAPI, GetAPI, PatchAPI, PostAPI } from "../../API";

// Location list data for the Settings page api
const get_location_list = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void
) => {
  GetAPI.call(
    EMPLOYER_URL_CONSTANTS.get_employer_location_list,
    onSuccess,
    {},
    onError
  );
};

const get_personal_settings = (
  url: string,
  onSuccess: (data: any) => void,
  onError: (err: any) => void
) => {
  GetAPI.call(url, onSuccess, {}, onError);
};

// api call for settings - department list view
const get_department_list = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void
) => {
  GetAPI.call(
    EMPLOYER_URL_CONSTANTS.get_employer_department_list,
    onSuccess,
    {},
    onError
  );
};

// api call for settings - designation list view
const get_designation_list = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void
) => {
  GetAPI.call(
    EMPLOYER_URL_CONSTANTS.get_employer_designation_list,
    onSuccess,
    {},
    onError
  );
};

// api call for settings - salary_bracket list view
const get_salary_bracket_list = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void
) => {
  GetAPI.call(
    EMPLOYER_URL_CONSTANTS.get_employer_salary_bracket_list,
    onSuccess,
    {},
    onError
  );
};

// api call for settings - Organisation data
const get_organisation_data = (
  url: string,
  onSuccess: (data: any) => void,
  onError: (err: any) => void
) => {
  GetAPI.call(url, onSuccess, {}, onError);
};

const update_password = (
  dto: any,
  onSuccess: (data: any) => void,
  onError: (err: any) => void
) => {
  PatchAPI.call(
    `${EMPLOYER_URL_CONSTANTS.update_password}`,
    dto,
    onSuccess,
    {},
    onError
  );
};

const update_password_employee = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
  dto: any,
  usertype: string
) => {
  PatchAPI.call(
    `${EMPLOYEE_URL_CONSTANTS.change_password}`,
    dto,
    onSuccess,
    {},
    onError
  );
};

const update_emp_password = (
  dto: any,
  onSuccess: (data: any) => void,
  onError: (err: any) => void
) => {
  PatchAPI.call(
    `${EMPLOYER_URL_CONSTANTS.update_emp_password}`,
    dto,
    onSuccess,
    {},
    onError
  );
};
const update_personal_settings = (
  dto: any,
  onSuccess: (data: any) => void,
  onError: (err: any) => void
) => {
  PatchAPI.call(
    `${EMPLOYER_URL_CONSTANTS.update_personal_settings}`,
    dto,
    onSuccess,
    {},
    onError
  );
};

const fetch_data = (
  url: string,
  onSuccess: (data: any) => void,
  onError: (err: any) => void
) => {
  GetAPI.call(url, onSuccess, {}, onError);
};
export const EMPLOYER_SETTING_SERVICES = {
  get_location_list,
  get_department_list,
  get_designation_list,
  get_salary_bracket_list,
  get_organisation_data,
  update_password,
  get_personal_settings,
  update_personal_settings,
  update_emp_password,
  fetch_data,
  update_password_employee,
};
