import { Logout } from "@mui/icons-material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PasswordIcon from "@mui/icons-material/Password";
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { URL_CONSTANTS } from "../../../../APIVar/URLConstants";
import { EMPLOYEE_ALL_ROUTES } from "../../../../Routes/employee_all_routes";
import { APPREANCE_SERVICES } from "../../../../Services/Settings/OrganisationSettings/Appearance";
import { TUserData } from "../../../../Services/Types/TUserData";
import Session from "../../../../Session/Session";
import { userDetailsSlice } from "../../../../Store/Slice_Reducer/UserLogin/UserDetailsSlice";
import { useAppSelector } from "../../../../Store/hooks";
import { Post } from "../../../../Supporting files/CallAPI";
import "./HeaderEmployee.scss";
import ChangePassword from "../../../../Pages/Desktop/Broker/Settings/GeneralSettings/PersonalSettings/ChangePassword/ChangePassword";
import { EMPLOYER_SETTING_SERVICES } from "../../../../Services/EmployerServices/Settings/SetingServices";
import { ALL_ROUTES } from "../../../../Routes/all_routes";

function Header() {
  const {
    userType,
    portals,
    username,
    "Organization Email": organizationEmail,
    organizationDetails,
  } = useAppSelector((state) => state.userDetailsSlice);
  const usertypeinfo = useAppSelector((state) => state.userDetailsSlice) as {
    [key: string]: any;
  };
  const [userSignOutStatus, setUserSignOutStatus] = useState<null | HTMLElement>(null);
  const [changePasswordOpen, setChangePasswordOpen] = useState(false);
  const openSignOut = Boolean(userSignOutStatus);
  const [notificationMenuStatus, setNotificationMenuStatus] = useState<null | HTMLElement>(null);
  const [darkThemeStatus, setDarkThemeStatus] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userState: TUserData = useAppSelector((state: any) => state.userDetailsSlice);
  const [selectedValue, setSelectedValue] = useState(userState.appearance.theme);
  const [portalChange, setPortalChange] = useState<null | HTMLElement>(null);
  const openpcMenu = Boolean(portalChange);
  const [userDropdown, setUserDropdown] = useState<null | HTMLElement>(null);
  const [notificationDropdown, setNotificationDropdown] = useState<null | HTMLElement>(null);
  const open = Boolean(userDropdown);
  const openNotification = Boolean(notificationDropdown);
  const { origin } = window.location;

  const add_dark_theme = (status: number) => {
    if (status === 1) {
      document.body.classList.add("theme-dark");
    } else {
      document.body.classList.remove("theme-dark");
    }
  };

  const save_appearance_data = (data: number) => {
    let dto = {
      appearance: {
        theme: data,
      },
    };
    const onSuccess = (res: any) => { };
    const onError = (err: any) => {
      console.log(err);
    };
    {
      userState.userType === "EMPLOYER"
        ? APPREANCE_SERVICES.save_appearance_data_employer(
          onSuccess,
          onError,
          dto
        )
        : APPREANCE_SERVICES.save_appearance_data(
          onSuccess,
          onError,
          dto,
          userType.toLowerCase()
        );
    }
  };

  const handleChange = (data: number) => {
    setSelectedValue(data);
    save_appearance_data(data);
    dispatch(userDetailsSlice.actions.updateAppearance(data));
  };

  useEffect(() => {
    add_dark_theme(selectedValue);
    if (
      Session.getTokken().toString().length === 0 &&
      Session.getUserType() != "broker"
    ) {
      //navigate("/");
      const windowURL = window.location.search;
      const params = new URLSearchParams(windowURL);
      let page = params.get("page");
    }
  }, []);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setUserDropdown(event.currentTarget);
  };
  const notificationClick = (event: React.MouseEvent<HTMLElement>) => {
    setNotificationDropdown(event.currentTarget);
  };
  const nofiticationClose = () => {
    setNotificationDropdown(null);
  };
  const handleClose = () => {
    setUserDropdown(null);
  };

  const updateMasterState = (attrName: any, value: any) => {
    if (attrName === "setChangePassword") {
      return setChangePasswordOpen(value);
    }
    attrName(value);
  };

  const logout = () => {
    let params = {};
    Post(URL_CONSTANTS.user_logout_employee, params).then((data: any) => {
      let error = data[0];
      let response = data[1];
      if (error !== "failed") {
        // navigate("/");
        sessionStorage.clear();
        // const today = new Date();
        // const yesterday = new Date(today.getTime() - 24 * 60 * 60 * 1000);
        // document.cookie = `usertoken=; expires=${yesterday}; path=/`;
        // document.cookie = `access_tokken=; expires=${yesterday}; path=/`;
        window.location.href = "/";
      }
    });
  };

  const handleMenuItemClick = (value: any) => {
    changeAccountType(value);
    setPortalChange(null);
    //setSelectedValue(value); // Update the selected value state
  };

  const changeAccountType = (type: string) => {
    let params = {
      username: username ? username : organizationEmail,
      switch_to: type.toUpperCase(),
    };
    Post(URL_CONSTANTS.changeAccountType, params).then((data: any) => {
      let status = data[0];
      let response = data[1];

      if (response && status === "success") {
        sessionStorage.setItem("usertoken", response.userToken);
        // document.cookie = "usertoken=" + response.userToken + "; path=/;";
        dispatch(
          userDetailsSlice.actions.updateUserDetails([
            {
              key: "userType",
              value: response.userType,
            },
            {
              key: "portals",
              value: response.portals,
            },
          ])
        );
        if (response.userType === "EMPLOYEE") {
          setTimeout(() => {
            window.location.href = `${origin}/${EMPLOYEE_ALL_ROUTES.DASHBOARD}`;
          }, 1000);
        } else {
          navigate(ALL_ROUTES.DASHBOARD)
        }
      }
      if (data[0] && data[0].status === "failed") {
        // let param = data[0].errors[0].param;
        // let error = data[0].errors[0].message;
        // setParam(param);
        // setErrorLogin(error);
      }
    });
  };

  const userTypeData = (type: string) => {
    if (type === "broker_portal") {
      return "Broker";
    }
    if (type === "client_portal") {
      return "Employer";
    }
    if (type === "employee_portal") {
      return "Employee";
    }
  };
  let keysWithTrueValues = Object.values(portals).filter((value) => value);

  return (
    <>
      <Grid container spacing={3} alignItems="center">
        <Grid className="logoCol" xs>
          <Box>
            <img alt="Logo" height={"60px"} width={'100%'}
              src={organizationDetails?.logo || "/images/eb_logo.svg"}
              onClick={() => navigate(EMPLOYEE_ALL_ROUTES.LOGIN)}
            />
          </Box>
        </Grid>
        <Grid className="topNavbar pl-0" xs>
          <Box>
            {/* <Grid xs={12}>
            <Button
              className="searchBox"
              onClick={() => {
                // setSearchopen(true);
              }}>
              Search here
            </Button>
          </Grid> */}

            <Grid container justifyContent="flex-end" alignItems="center" xs={12}>
              {keysWithTrueValues?.length > 1 && (
                <React.Fragment>
                  <Box className="portalChangeTab">
                    <IconButton
                      onClick={(e) => {
                        setPortalChange(e.currentTarget);
                      }}
                      aria-controls={portalChange ? "account-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={portalChange ? "true" : undefined}
                    >
                      Switch To <KeyboardArrowDownIcon />
                    </IconButton>
                  </Box>
                  <Menu
                    className="signout_option"
                    anchorEl={portalChange}
                    id="account-menu"
                    open={openpcMenu}
                    onClose={(e) => {
                      setPortalChange(null);
                    }}
                    transformOrigin={{ horizontal: "right", vertical: "top" }}
                    anchorOrigin={{
                      horizontal: "right",
                      vertical: "bottom",
                    }}
                  >
                    {Object.entries(portals).map(([portalKey, portalValue]) =>
                      portalValue ? (
                        <MenuItem
                          className={`${userTypeData(portalKey)?.toLowerCase() ===
                            userType.toLowerCase()
                            ? "selected"
                            : ""
                            }`}
                          key={portalKey}
                          onClick={() => {
                            if (userTypeData(portalKey)?.toLowerCase() === userType.toLowerCase()) return;
                            else { handleMenuItemClick(userTypeData(portalKey)); }
                          }}
                        >
                          {userTypeData(portalKey)}
                        </MenuItem>
                      ) : null
                    )}
                  </Menu>
                </React.Fragment>
              )}

              <Box className="navLinks">
                {/* <Tooltip title="Task Management">
                <Link>
                  <AssignmentTurnedInOutlinedIcon />
                </Link>
              </Tooltip>
              <Tooltip title="Custom Settings">
                <Link>
                  <TuneOutlinedIcon />
                </Link>
              </Tooltip> */}
                {/* <Tooltip title="Theme Change">
                <Link
                  onClick={() => {
                    handleChange(selectedValue === 0 ? 1 : 0);
                  }}
                  className="themeToggle"
                >
                  {selectedValue === 0 ? <LightModeIcon /> : <NightsStayIcon />}
                </Link>
              </Tooltip> */}

                {/* Notification */}
                {/* <Box className="notificationDropdown">
                <Tooltip title="Notifications">
                  <IconButton
                    onClick={notificationClick}
                    size="small"
                    aria-controls={
                      openNotification ? "notifiction-menu" : undefined
                    }
                    aria-haspopup="true"
                    aria-expanded={openNotification ? "true" : undefined}
                  >
                    <NotificationsNoneRoundedIcon />
                  </IconButton>
                </Tooltip>

                <Menu
                  anchorEl={notificationDropdown}
                  id="notifiction-menu"
                  open={openNotification}
                  onClose={nofiticationClose}
                  onClick={nofiticationClose}
                  className="notificationMenu"
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.1))",
                      borderRadius: "8px",
                      mt: 1,
                      "&:before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: "#EDF1FE",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                  <Box className="listMenu">
                    <div className="title">
                      Notifications <Link>View All</Link>
                    </div>
                    <li>
                      <h5>Health Insurance</h5>
                      <p>You have been given a health insurance policy.</p>
                    </li>
                    <li>
                      <h5>Claim Request</h5>
                      <p>Your cashless claim request has been approved.</p>
                    </li>
                    <li>
                      <h5>Health Insurance</h5>
                      <p>You have been given a health insurance policy.</p>
                    </li>
                    <li>
                      <h5>Claim Request</h5>
                      <p>Your cashless claim request has been approved.</p>
                    </li>
                    <li>
                      <h5>Health Insurance</h5>
                      <p>You have been given a health insurance policy.</p>
                    </li>
                    <li>
                      <h5>Claim Request</h5>
                      <p>Your cashless claim request has been approved.</p>
                    </li>
                  </Box>
                </Menu>
              </Box> */}

                <Box className="userDropdown">
                  <Tooltip title="User Account">
                    <IconButton
                      onClick={handleClick}
                      size="small"
                      aria-controls={open ? "account-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                    >
                      <img src={userState?.logo ? userState.logo : "./images/transparent_face_img.svg"} alt="logo" />
                    </IconButton>
                  </Tooltip>
                  <Menu
                    anchorEl={userDropdown}
                    id="account-menu"
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.1))",
                        borderRadius: "8px",
                        mt: 1,
                        "& .MuiAvatar-root": {
                          width: 22,
                          height: 22,
                          mr: 1,
                        },
                        "&:before": {
                          content: '""',
                          display: "block",
                          position: "absolute",
                          top: 0,
                          right: 14,
                          width: 10,
                          height: 10,
                          bgcolor: "background.paper",
                          transform: "translateY(-50%) rotate(45deg)",
                          zIndex: 0,
                        },
                      },
                    }}
                    transformOrigin={{ horizontal: "right", vertical: "top" }}
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                  >
                    <MenuItem
                      onClick={() => {
                        handleClose();
                        navigate(EMPLOYEE_ALL_ROUTES.PROFILE);
                      }}
                    >
                      <Avatar />
                      {usertypeinfo["First Name"]}
                    </MenuItem>
                    <Divider />
                    <MenuItem onClick={() => setChangePasswordOpen(true)}>
                      <ListItemIcon>
                        <PasswordIcon fontSize="small" />
                      </ListItemIcon>
                      Change Password
                    </MenuItem>
                    <MenuItem onClick={logout}>
                      <ListItemIcon>
                        <Logout fontSize="small" />
                      </ListItemIcon>
                      Logout
                    </MenuItem>
                  </Menu>
                </Box>
              </Box>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      <ChangePassword
        open_status={changePasswordOpen}
        attrName={setChangePasswordOpen}
        value_update={updateMasterState}
        update_api_url={EMPLOYER_SETTING_SERVICES.update_password_employee}
      />
    </>
  );
}

export default Header;
