import { PayloadAction } from "@reduxjs/toolkit";
import { TAddPolicy } from "../../../Services/Types/TAddPolicy";

function setAddPolicy(state: TAddPolicy, action: PayloadAction<TAddPolicy>) {
  return action.payload;
}

export const ADD_POLICY_REDUCERS = {
  setAddPolicy,
};

export type AddPolicyReducer = typeof ADD_POLICY_REDUCERS;
