import { Box, Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { GridCloseIcon } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import SlidingPanel from "react-sliding-side-panel";
import { toast } from "react-toastify";
import { URL_CONSTANTS } from "../../../../../../APIVar/URLConstants";
import {  AddVehicleDTO} from "../../../../../../Services/DTO/ProfileDTO";
import { EMPLOYEE_SERVICES } from "../../../../../../Services/Employee/EmployeeService";
import { PROFILE_SERVICE } from "../../../../../../Services/Employee/ProfileService";
import { EVehicleType } from "../../../../../../Services/Enum/EVehicleType";
import { useAppSelector } from "../../../../../../Store/hooks";
import DatePicker from "../../../../../../Supporting files/DatePicker/DatePicker";
import RKTextField from "../../../../../../Supporting files/RKTextField/RKTextField";
import SearchDropdown from "../../../../../../Supporting files/SearchDropdown/SearchDropdown";
import moment from 'moment';

interface Props {
  open_status: boolean;
  attrName: any;
  value_update: Function;
  data: any;
  get_profile?: Function;
}

const EditVehicle: React.FC<Props> = ({
  open_status,
  attrName,
  value_update,
  data,
  get_profile
}) => {
  const { userType, id } = useAppSelector((state) => (state.userDetailsSlice));
  const [vehicle_detail, setVehicle_detail] = useState<AddVehicleDTO>({
    registorNumber: "",
    vehicleType: { label: "", id: "" },
    makeModel: { label: "", id: "" },
    fuelType: { label: "", id: "" },
    variant: { label: "", id: "" },
    registrationDate: null,
    policyExpiryDate: null,
    previousInsurer: { label: "", id: "" },
    warnRegistorNumber: false,
    warnVehicleType: false,
    warnMakeModel: false,
    warnFuelType: false,
    warnVariant: false,
    warnRegistrationDate: false,
    warnPolicyExpiryDate: false,
    warnPreviousInsurer: false,
  });
  const [disableSave, setDisableSave] = useState<boolean>(false);
  const [vehicleType_data, setVehicleType_data] = useState<any>([
    {
      value: "Car",
      label: "Car",
    },
    {
      value: "Bike",
      label: "Bike",
    },
  ]);
  const [makeModel_data, setMakeModel_data] = useState<any[]>([]);
  const [fuelType_data, setFuelType_data] = useState([]);
  const [variant_data, setVariant_data] = useState([]);
  const [previousInsurer_data, setpreviousInsurer_data] = useState([]);

  useEffect(() => {
    setVehicle_detail({
      id: data._id,
      registorNumber: data.registrationNumber,
      vehicleType: { label: data.type, id: data.type },
      makeModel: { label: data.makeModal, id: data.makeModal },
      fuelType: { label: data.fuelType, id: data.fuelType },
      variant: { label: data.variant, id: data.variant },
      registrationDate: data.registerDate,
      policyExpiryDate: data.policyExpireDate,
      previousInsurer: {
        label: data.previousInsurer,
        id: data.previousInsurer,
      },
      warnRegistorNumber: false,
      warnVehicleType: false,
      warnMakeModel: false,
      warnFuelType: false,
      warnVariant: false,
      warnRegistrationDate: false,
      warnPolicyExpiryDate: false,
      warnPreviousInsurer: false,
    });
  }, [open_status]);
  useEffect(() => {
    get_insurer_list();
  }, [open_status]);


  const get_insurer_list = () => {
    const onSuccess = (res: any) => {
      let data = res.data.data.map((val: any) => {
        return { label: val.label, id: val._id };
      });
      setpreviousInsurer_data(data);
    };
    const onError = (err: any) => {
      console.log(err);
    };
    PROFILE_SERVICE.getPreviousInsurer(onSuccess, onError);
  };
  const updateMasterState = (attrName: any, value: any) => {
    let key = attrName[1];
    let mainState = attrName[0];
    const onSuccess = (res: any) => {
      if (key === "vehicleType") {
        let strAscending: any[] = [...res.data.data].sort((a, b) =>
          a.label.localeCompare(b.label)
        );
        setMakeModel_data(strAscending);
        setFuelType_data([]);
        setVariant_data([]);
        setVehicle_detail({
          ...vehicle_detail,
          vehicleType: { label: value.label, id: value.id },
          warnVehicleType:false,
          fuelType: { label: "", id: "" },
          makeModel: { label: "", id: "" },
          variant: { label: "", id: "" },
        });
      } else if (key === "makeModel") {
        setFuelType_data(res.data.data);
        setVehicle_detail({
          ...vehicle_detail,
          warnMakeModel:false,
          makeModel: { label: value.label, id: value.id },
          variant: { label: "", id: "" },
        });
        setVariant_data([]);
      } else if (key === "fuelType") {
        setVariant_data(res.data.data);
      }
    };
    const onError = (err: any) => {
      console.log("err--", err);
    };
    if (mainState === "vehicleDetail") {
      setVehicle_detail({
        ...vehicle_detail,
        [key]: value,
        [`warn${key.charAt(0).toUpperCase()}${key.slice(1)}`]: false,
      });
      switch (key) {
        case "vehicleType":
          const urlBike = URL_CONSTANTS.get_bike_make_model;
          const urlCar = URL_CONSTANTS.get_car_make_model;

          PROFILE_SERVICE.get_dropdown_list_property_data(
            onSuccess,
            onError,
            value.label === EVehicleType.BIKE ? urlBike : urlCar
          );
          break;
        case "makeModel":
          let urlbikefuel = `${URL_CONSTANTS.get_bike_fuel_type}=${value.label}`;
          let urlcarFuel = `${URL_CONSTANTS.get_car_fuel_type}=${value.label}`;
          PROFILE_SERVICE.get_dropdown_list_property_data(
            onSuccess,
            onError,
            vehicle_detail.vehicleType.label === EVehicleType.BIKE
              ? urlbikefuel
              : urlcarFuel
          );
          break;
        case "fuelType":
          let urlVariantbike = `${URL_CONSTANTS.get_bike_variant}=${vehicle_detail.makeModel.label}&fuel_type=${value.label}`;
          let urlVariantCar = `${URL_CONSTANTS.get_car_variant}=${vehicle_detail.makeModel.label}&fuel_type=${value.label}`;
          PROFILE_SERVICE.get_dropdown_list_property_data(
            onSuccess,
            onError,
            vehicle_detail.vehicleType.label === EVehicleType.BIKE
              ? urlVariantbike
              : urlVariantCar
          );
          break;

        default:
          break;
      }
    } else {
      attrName(value);
    }
  };

  const form_submit = () => {
    if (disableSave) return;
    let data: any = vehicle_detail;
    data.warnRegistorNumber =
      vehicle_detail.registorNumber.length === 0 ? true : false;
    data.warnVehicleType =
      vehicle_detail.vehicleType.label.length === 0 ? true : false;
    data.warnMakeModel =
      vehicle_detail.makeModel.label.length === 0 ? true : false;
    data.warnFuelType =
      vehicle_detail.fuelType.label.length === 0 ? true : false;
    data.warnVariant = vehicle_detail.variant.label.length === 0 ? true : false;
    data.warnRegistrationDate =
      vehicle_detail.registrationDate === null ? true : false;
    data.warnPolicyExpiryDate =
      vehicle_detail.policyExpiryDate === null ? true : false;
    data.warnPreviousInsurer =
      vehicle_detail.previousInsurer.label.length === 0 ? true : false;

    setVehicle_detail({ ...data });
    if (
      data.warnRegistorNumber === false &&
      data.warnVehicleType === false &&
      data.warnMakeModel === false &&
      data.warnFuelType === false &&
      data.warnVariant === false &&
      data.warnRegistrationDate === false &&
      data.warnPolicyExpiryDate === false &&
      data.warnPreviousInsurer === false
    ) {
      const windowURL = window.location.search;
      const params = new URLSearchParams(windowURL);
      const Id = params.get("id");
      let param = {
        employeeId: Id ? Id : id,
        registrationNumber: vehicle_detail.registorNumber,
        type: vehicle_detail.vehicleType.label,
        makeModal: vehicle_detail.makeModel.label,
        variant: vehicle_detail.variant.label,
        fuelType: vehicle_detail.fuelType.label,
        registerDate: vehicle_detail.registrationDate,
        policyExpireDate: vehicle_detail.policyExpiryDate,
        previousInsurer: vehicle_detail.previousInsurer.label,
      };
      const onSuccess = (res: any) => {

        value_update(attrName, false);
        toast.success(res.data.message);
        setDisableSave(false);
        setVehicle_detail({
          registorNumber: "",
          vehicleType: { label: "", id: "" },
          makeModel: { label: "", id: "" },
          fuelType: { label: "", id: "" },
          variant: { label: "", id: "" },
          registrationDate: null,
          policyExpiryDate: null,
          previousInsurer: { label: "", id: "" },
          warnRegistorNumber: false,
          warnVehicleType: false,
          warnMakeModel: false,
          warnFuelType: false,
          warnVariant: false,
          warnRegistrationDate: false,
          warnPolicyExpiryDate: false,
          warnPreviousInsurer: false,
        });
        if (get_profile) {
          get_profile();
        }
      };
      const onError = (err: any) => {
        toast.error(err.response.data.errors[0].message);
        setDisableSave(false);
      };
     if(disableSave === false) {
      setDisableSave(true);
      PROFILE_SERVICE.update_vehicle_detail(param, onSuccess, onError, data.id, userType?.toLowerCase());
     }
    }
  };

  return (
    <Box>
      <SlidingPanel
        type={"right"}
        isOpen={open_status}
        size={75}
        panelClassName="sliding-panel"
      // backdropClicked={() => setAddVehicle(false)}
      >
        <div>
          <Grid container spacing={3} alignItems="center">
            <Grid xs={10}>
            <h3
                className="mb-0 mt-3 text-left"
                style={{ fontSize: "18px", fontWeight: "600" }}
              >Edit Vehicle</h3>
            </Grid>
            <Grid xs={2} textAlign="end" className="mt-2">
              <GridCloseIcon
                onClick={() => {
                  value_update(attrName, false);
                  setVehicle_detail({
                    registorNumber: "",
                    vehicleType: { label: "", id: "" },
                    makeModel: { label: "", id: "" },
                    fuelType: { label: "", id: "" },
                    variant: { label: "", id: "" },
                    registrationDate: null,
                    policyExpiryDate: null,
                    previousInsurer: { label: "", id: "" },
                    warnRegistorNumber: false,
                    warnVehicleType: false,
                    warnMakeModel: false,
                    warnFuelType: false,
                    warnVariant: false,
                    warnRegistrationDate: false,
                    warnPolicyExpiryDate: false,
                    warnPreviousInsurer: false,
                  });
                }}
              />
            </Grid>
            <Grid xs={12}>
              <hr />
            </Grid>
          </Grid>
          <div className="scrollable_area">
            <div>
              <Grid container spacing={3}>
                <Grid xs={6}>
                  <RKTextField
                    class_name="inputField"
                    title={"Registration Number"}
                    value={vehicle_detail.registorNumber}
                    attrName={["vehicleDetail", "registorNumber"]}
                    value_update={updateMasterState}
                    warn_status={vehicle_detail.warnRegistorNumber}
                  />
                </Grid>
                <Grid xs={6}>
                  <SearchDropdown
                    class_name="inputField"
                    title="Vehicle Type"
                    value={vehicle_detail.vehicleType.label}
                    attrName={["vehicleDetail", "vehicleType"]}
                    value_update={updateMasterState}
                    data={vehicleType_data}
                    warn_status={vehicle_detail.warnVehicleType}
                  />
                </Grid>
                <Grid xs={6}>
                  <SearchDropdown
                    class_name="inputField"
                    title="Make & Model"
                    value={vehicle_detail.makeModel.label}
                    attrName={["vehicleDetail", "makeModel"]}
                    value_update={updateMasterState}
                    data={makeModel_data}
                    warn_status={vehicle_detail.warnMakeModel}
                  />
                </Grid>
                <Grid xs={6}>
                  <SearchDropdown
                    class_name="inputField"
                    title="Fuel Type"
                    value={vehicle_detail.fuelType.label}
                    attrName={["vehicleDetail", "fuelType"]}
                    value_update={updateMasterState}
                    data={fuelType_data}
                    warn_status={vehicle_detail.warnFuelType}
                  />
                </Grid>
                <Grid xs={6}>
                  <SearchDropdown
                    class_name="inputField"
                    title="Variant"
                    value={vehicle_detail.variant.label}
                    attrName={["vehicleDetail", "variant"]}
                    value_update={updateMasterState}
                    data={variant_data}
                    warn_status={vehicle_detail.warnVariant}
                  />
                </Grid>
                <Grid xs={6}>
                  <DatePicker
                    class_name="inputField"
                    title="Registration Date"
                    max_date={new Date()}
                    min_date={new Date(moment().subtract(50, 'years').toDate())}
                    value={vehicle_detail.registrationDate}
                    attrName={["vehicleDetail", "registrationDate"]}
                    value_update={updateMasterState}
                    error_message="Please enter reg date"
                    warn_status={vehicle_detail.warnRegistrationDate}
                  />
                </Grid>
                <Grid xs={6}>
                  <DatePicker
                    class_name="inputField"
                    title="Policy Expiry Date"
                    value={vehicle_detail.policyExpiryDate}
                    attrName={["vehicleDetail", "policyExpiryDate"]}
                    value_update={updateMasterState}
                    error_message="Please enter policy expiry"
                    warn_status={vehicle_detail.warnPolicyExpiryDate}
                    disabled={!vehicle_detail.registrationDate}
                    min_date={
                      vehicle_detail.registrationDate
                        ? vehicle_detail.registrationDate
                        : new Date()
                    }
                  />
                </Grid>
                <Grid xs={6}>
                  <SearchDropdown
                    class_name="inputField"
                    title="Previous Insurer"
                    value={vehicle_detail.previousInsurer.label}
                    attrName={["vehicleDetail", "previousInsurer"]}
                    value_update={updateMasterState}
                    data={previousInsurer_data}
                    warn_status={vehicle_detail.warnPreviousInsurer}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid
                  xs={12}
                  className="ctaBtn"
                  style={{ textAlign: "center" }}
                >
                  <Button
                    variant="contained"
                    className="submitBtn"
                    onClick={form_submit}
                    disabled={disableSave}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </SlidingPanel>
    </Box>
  );
};

export default EditVehicle;
