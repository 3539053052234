import { Box, Button, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PERSONAL_SETTINGS_SERVICES } from "../../../../../../Services/customModule/personalSettingsServices";
import {
  add_date_format,
  titleCase,
} from "../../../../../../Supporting files/HelpingFunction";
import "../../NewSettings.scss";
import ChangePassword from "./ChangePassword/ChangePassword";
import NewLoader from "../../../../../../Components/NewLoader/NewLoader";
import { toast } from "react-toastify";
import { useAppSelector } from "../../../../../../Store/hooks";
import { RootState } from "../../../../../../Store/Store";

function PersonalSettings() {
  const { BROKER } = useAppSelector((state:RootState) => state.permissionSlice);
  const navigate = useNavigate();
  const [changePassword, setChangePassword] = useState(false);
  const [editPermission, setEditPermission] = useState<any>(null);
  const [personalSettingdata, setpersonalSettingdata] = useState({
    department: "",
    profilePicture: "",
    gender: "",
    primaryContact: {
      email: "",
      name: "",
      designation: "",
    },
    addressDetails: {
      country: "",
      state: "",
      city: "",
      pinCode: 0,
      phoneNumber: 0,
      mobileNumber: 0,
      address1: "",
      address2: "",
    },
    dob: "",
    building: "",
    area: "",
    country: "",
    state: "",
    city: "",
    mobileNumber: "",
    phoneNumber: "",
    name: "",
    pinCode: "",
    roleName: "",
    email: "",
    companyCode: "",
  });
  const [showLoader, setShowLoader] = useState(true);

  const updateMasterState = (
    attrName: any,
    value: string | boolean | number
  ) => {
    if (attrName === "setChangePassword" && typeof value === "boolean") {
      setChangePassword(value);
    } else {
      attrName(value);
    }
  };

  //get personal setting data
  const get_personal_settings = () => {
    const onSuccess = (res: any) => {
      setTimeout(() => {
        setShowLoader(false);
      }, 1500);
      setpersonalSettingdata(res.data.data);
    };
    const onError = (err: any) => {
      console.log("Branches", err);
      setShowLoader(false);
    };
    PERSONAL_SETTINGS_SERVICES.get_personal_settings(onSuccess, onError);
  };

  useEffect(() => {
    setEditPermission(BROKER?.personalSetting.edit === undefined ? false : BROKER?.personalSetting?.edit);
    get_personal_settings();
    setShowLoader(true);
  }, []);

  return (
    <>
      {showLoader && (<NewLoader />)}
      <Box className="settingMenuWrapper">
        <ChangePassword
          attrName={setChangePassword}
          open_status={changePassword}
          value_update={updateMasterState}
          update_api_url={PERSONAL_SETTINGS_SERVICES.update_password}
        />
        <Grid container spacing={3}>
          <Grid xs={4}>
            <div className="personal_logosection">
              <h6>Profile</h6>
              <img
                src={
                  personalSettingdata?.profilePicture ??
                  "images/nobody_original.svg"
                }
                alt=""
                className="company_logo"
              />

              <h5>
                {titleCase(personalSettingdata?.primaryContact?.name ?? "")}
              </h5>

              <p>
                {titleCase(
                  personalSettingdata?.primaryContact?.designation ?? ""
                )}
                ({titleCase(personalSettingdata?.roleName ?? "")})
              </p>
            </div>
            <div className="personal_logosection">
              <h6>Profile Details</h6>
              <div className="Companydetail">
                <div className="text-left">
                  <p>Gender</p>
                  <h6>{personalSettingdata?.gender}</h6>
                </div>
                <div className="text-right">
                  <h4>DOB</h4>
                  <h6>{personalSettingdata?.dob ? add_date_format(personalSettingdata?.dob) : ""}</h6>
                </div>
              </div>
              <div className="Companydetail">
                <div className="text-left">
                  <p>Office Number</p>
                  <h6>{personalSettingdata?.addressDetails?.mobileNumber}</h6>
                </div>
                <div className="text-right">
                  <h4>Phone Number</h4>
                  <h6>{personalSettingdata?.addressDetails?.phoneNumber}</h6>
                </div>
              </div>
              <div className="Companydetail">
                <div className="text-left">
                  <p>User ID</p>
                  <h6>{personalSettingdata?.companyCode}</h6>
                </div>
                <div className="text-right">
                  <h4>Email ID</h4>
                  <h6>{personalSettingdata?.primaryContact?.email}</h6>
                </div>
              </div>
            </div>
          </Grid>

          <Grid xs={8}>
            <Box>
              <Grid container padding={0}>
                <Grid xs={12} md={12} lg={12}>
                  <div className="password_section">
                    <h5>
                      Change Password
                      <span>Change password if you want</span>
                    </h5>
                    <Link
                      className="chng_pwd_link"
                      underline="none"
                      onClick={() => {
                        if(!editPermission) return toast.error("Edit permissions not allowed");
                        setChangePassword(true);
                      }}
                    >
                      <img src="images/change_password_icon.svg" alt="" />
                      Change Password
                    </Link>
                  </div>
                </Grid>
              </Grid>
            </Box>
            <div className="address_details_section">
              <Grid container>
                <Grid xs={12}>
                  <h5>Address</h5>
                  <p className="mb-7">
                    You can use the following email addresses to sign in to
                    your account and also to reset your password if you ever
                    forget it.
                  </p>
                  <Box>
                    <Grid container className="contact_details_innerBox mb-5">
                      <Grid xs={6}>
                        <h6>Street name</h6>
                        <h5>
                          {personalSettingdata?.addressDetails?.address1}
                        </h5>
                      </Grid>
                      <Grid xs={6}>
                        <h6>Area name</h6>
                        <h5>
                          {personalSettingdata?.addressDetails?.address2}
                        </h5>
                      </Grid>
                    </Grid>
                    <Grid container className="contact_details_innerBox">
                      <Grid xs={3}>
                        <h6>Country</h6>
                        <h5>
                          {personalSettingdata?.addressDetails?.country}
                        </h5>
                      </Grid>
                      <Grid xs={3}>
                        <h6>State</h6>
                        <h5>{personalSettingdata?.addressDetails?.state}</h5>
                      </Grid>
                      <Grid xs={3}>
                        <h6>City</h6>
                        <h5>{personalSettingdata?.addressDetails?.city}</h5>
                      </Grid>
                      <Grid xs={3}>
                        <h6>Zip code</h6>
                        <h5>
                          {personalSettingdata?.addressDetails?.pinCode}
                        </h5>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
        {/* Footer Section */}
        <Grid container spacing={3} className="footerSection">
          <Grid xs={12} className="ctaBtn">
            <Button
              variant="contained"
              className="submitBtn"
              onClick={() => {
                if(!editPermission) return toast.error("Edit permissions not allowed");
                navigate(
                  "/setting-module-form?page=editPersonalSettings&section=general"
                );
              }}
            >
              Edit
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default PersonalSettings;
