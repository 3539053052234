import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import { Box, Button, Link, Modal } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { BRANCHES_SERVICES } from "../../../../../../../Services/customModule/branchesServices";
import {
  get_city_list_via_name,
  get_country_list,
  get_state_list,
} from "../../../../../../../Supporting files/HelpingFunction";
import RKTextField from "../../../../../../../Supporting files/RKTextField/RKTextField";
import SearchDropdown from "../../../../../../../Supporting files/SearchDropdown/SearchDropdown";
import SelectDropdown from "../../../../../../../Supporting files/SelectDropdown/SelectDropdown";
import "../../../../../../../formLayout.scss";
import { getUrlParam } from "../../../../CustomModules/CustomModuleV2/helpers";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import NewLoader from "../../../../../../../Components/NewLoader/NewLoader";
import { useAppSelector } from "../../../../../../../Store/hooks";

interface Props {
  open_status: boolean;
  attrName: any;
  value_update: Function;
}

function EditBranch() {
  const navigate = useNavigate();
  const [priorityStatus, setPriorityStatus] = useState(7);
  const [prioritystatusData, setprioritystatusData] = useState([]);
  const [postBranchesdata, setpostBranchesdata] = useState<{
    locationType: any;
    locationName: any;
    building: any;
    area: any;
    country: any;
    state: any;
    city: any;
    pinCode: any;
  }>({
    locationType: "",
    locationName: "",
    building: "",
    area: "",
    country: "",
    state: "",
    city: "",
    pinCode: "",
  });
  const [branchWarnStatus, setbranchWarnStatus] = useState<{
    locationType: boolean;
    locationName: boolean;
    building: boolean;
    area: boolean;
    country: boolean;
    state: boolean;
    city: boolean;
    pinCode: boolean;
  }>({
    locationType: false,
    locationName: false,
    building: false,
    area: false,
    country: false,
    state: false,
    city: false,
    pinCode: false,
  });
  const [countryData, setCountryData] = useState<Array<{ label: string; id: string }>>([]);
  const [stateData, setStateData] = useState<Array<{ label: string; id: string }>>([]);
  const [cityData, setCityData] = useState<Array<{ label: string; id: string }>>([]);
  const [selectedCountryStateCity, setselectedCountryStateCity] = useState<{
    country: any;
    state: any;
    city: any;
  }>({
    country: "",
    state: "",
    city: "",
  });
  const [deleteStatus, setDeleteStatus] = useState(false);
  const [expanded, setExpanded] = useState<string | false>("panel1");
  const [sectionStatus, SetSectionStatus] = useState<string>("userInformation");
  const [subSection, setSubSection] = useState<string>("basics");
  const id: any = getUrlParam("id");
  const [showLoader, setShowLoader] = useState(true);
  const [disableSave, setDisableSave] = useState(false);
  const userInformation = useRef(null);
  const basics = useRef(null);
  const clientInfo = useRef(null);
  const { BROKER } = useAppSelector((state) => state.permissionSlice);
  const [branchEditPermission, setbranchEditPermission] = useState(false);

  const get_status = () => {
    const onSuccess = (res: any) => {
      let statusData = res.data.data.map((data: any) => ({
        label: data.value,
        value: data.label,
      }));
      setprioritystatusData(statusData);
    };

    const onError = (err: any) => {
      console.log("err", err);
    };

    BRANCHES_SERVICES.get_status(onSuccess, onError);
  };

  const update_status = (value: number, id: string) => {
    const onSuccess = (res: any) => {
      get_indivisual_branch_data();
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    BRANCHES_SERVICES.update_status(
      {
        value: value,
      },
      onSuccess,
      onError,
      id
    );
  };
  const updateMasterState = (attrName: any, value: any) => {
    let keys = attrName[0];
    let mainstate = attrName[1];
    if (mainstate === "inputfields") {
      setbranchWarnStatus({ ...branchWarnStatus, [keys]: false });
      setpostBranchesdata({ ...postBranchesdata, [keys]: value });
      let label = attrName[2];
      if (label === "label") {
        setselectedCountryStateCity({
          ...selectedCountryStateCity,
          [keys]: value?.label,
        });
        if (keys === "country") {
          let country = { id: "", label: value?.label };
          get_state_list(country, (cb: any) => {
            setStateData(cb);
          });
          setpostBranchesdata({
            ...postBranchesdata,
            [keys]: value?.label,
            state: "",
            city: "",
          });
        } else if (keys === "state") {
          let state = { id: "", label: value?.label };
          let country = { id: "", label: selectedCountryStateCity.country };
          get_city_list_via_name(country, state, (cb: any) => {
            setCityData(cb);
          });
          setpostBranchesdata({
            ...postBranchesdata,
            [keys]: value?.label,
            city: "",
          });
        } else if (keys === "city") {
          setpostBranchesdata({ ...postBranchesdata, [keys]: value?.label });
        }
      }
    } else if (attrName === setPriorityStatus) {
      update_status(value, id);
    }
  };

  //indivisual Branches data
  const get_indivisual_branch_data = () => {
    const onSuccess = (res: any) => {
      if (res.status === 200) {
        setTimeout(() => {
          setShowLoader(false);
        }, 1500);
      }
      let data: any = {
        locationType: res.data.data.locationType,
        locationName: res.data.data.locationName,
        building: res.data.data.building,
        area: res.data.data.area,
        country: res.data.data.country,
        state: res.data.data.state,
        city: res.data.data.city,
        pinCode: res.data.data.pinCode?.toString(),
      };
      setpostBranchesdata(data);
      setPriorityStatus(res.data.data.status);
      let country = { id: "", label: res.data.data.country };
      get_state_list(country, (cb: any) => {
        setStateData(cb);
      });

      let state = { id: "", label: res.data.data.state };
      get_city_list_via_name(country, state, (cb: any) => {
        setCityData(cb);
      });
    };
    const onError = (err: any) => {
      console.log("Branches", err);
    };
    BRANCHES_SERVICES.branch_detail_view(onSuccess, onError, id);
  };

  //update Branches data
  const update_branch_data = () => {
    if (!branchEditPermission) return toast.error("Update permission not allowed")
    let data: any = branchWarnStatus;
    data.locationType = postBranchesdata.locationType.length === 0 ? true : false;
    data.locationName = postBranchesdata.locationName.length === 0 ? true : false;
    data.building = postBranchesdata.building.length === 0 ? true : false;
    data.area = postBranchesdata.area.length === 0 ? false : false;
    data.country = postBranchesdata.country.length === 0 ? true : false;
    data.state = postBranchesdata.state.length === 0 ? true : false;
    data.city = postBranchesdata.city.length === 0 ? true : false;
    data.pinCode = postBranchesdata.pinCode.length === 6 ? false : true;
    setbranchWarnStatus({ ...data });

    if (
      data.locationType === false &&
      data.locationName === false &&
      data.building === false &&
      data.country === false &&
      data.state === false &&
      data.city === false &&
      data.pinCode === false
    ) {
      setDisableSave(true);
      setShowLoader(true);
      let data: any = {
        locationType: postBranchesdata.locationType,
        locationName: postBranchesdata.locationName,
        building: postBranchesdata.building,
        area: postBranchesdata.area,
        country: postBranchesdata.country,
        state: postBranchesdata.state,
        city: postBranchesdata.city,
        pinCode: postBranchesdata.pinCode,
      };
      const onSuccess = (res: any) => {
        navigate(-1);
        toast.success(" Updated successfully");
        setShowLoader(false);
      };
      const onError = (err: any) => {
        console.log("Branches", err);
        setShowLoader(false);
      };
      BRANCHES_SERVICES.update_branch(data, onSuccess, onError, id);
    }
  };

  const scrollToSection = (elementRef: any) => {
    elementRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleClicks = (ref: any) => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  function handleKeyPress(event: any) {
    if (event.key === "Enter") {
      update_branch_data();
    }
  }

  useEffect(() => {
    get_status();
    setShowLoader(true);
    get_indivisual_branch_data();
    get_country_list((cb: any) => {
      setCountryData(cb);
    });
    setbranchEditPermission(
      BROKER?.Branches?.edit === undefined
        ? false : BROKER?.Branches?.edit
    );
  }, []);

  return (
    <>
      {showLoader && (<NewLoader />)}
      <Box className="layoutWrapper" onKeyDown={handleKeyPress} tabIndex={0}>
        {/* Page header */}
        <Grid container spacing={3} className="pageHeader">
          <Grid xs={6} className="pageTitle">
            <Link
              className="backStep"
              onClick={() => {
                navigate(-1);
              }}
            >
              <ChevronLeftIcon />
            </Link>

            <div>
              <h3>Edit
                {/* {postBranchesdata.locationName} */}
                {" "}Branch</h3>
            </div>
          </Grid>
          <Grid
            xs={6}
            textAlign={"right"}
            sx={{ display: "flex", justifyContent: "end" }}
          >
            <div id={`_${priorityStatus.toString()}`}>
              <div
                className="status_dropdown"
                id={`_${priorityStatus.toString()}`}
              >
                {priorityStatus === 7 ? (
                  <CheckCircleIcon id={`_${priorityStatus.toString()}`} />
                ) : priorityStatus === 8 ? (
                  <HighlightOffIcon id={`_${priorityStatus.toString()}`} />
                ) : null}

                <SelectDropdown
                  class_name="inputFieldd"
                  title=""
                  value={priorityStatus}
                  attrName={setPriorityStatus}
                  value_update={updateMasterState}
                  dropdown_data={prioritystatusData}
                  warn_status={false}
                />
              </div>
            </div>
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid xs={12}>
            <Grid container spacing={3}>
              <Grid xs className="leftSection">
                <Link
                  className={
                    sectionStatus === "userInformation"
                      ? "active sectionLink userInformation"
                      : "sectionLink userInformation"
                  }
                  onClick={() => {
                    handleClicks(sectionStatus);
                    scrollToSection(userInformation);
                    SetSectionStatus("userInformation");
                  }}
                >
                  <span> Branch Information</span>
                </Link>
                <ul className="accordion_list">
                  <li>
                    <Link
                      className={
                        subSection === "basics"
                          ? "inner_link active basics"
                          : "inner_link  basics"
                      }
                      onClick={() => {
                        handleClicks(subSection);
                        scrollToSection(basics);
                        setSubSection("basics");
                      }}
                    >
                      Basic Info
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={
                        subSection === "clientInfo"
                          ? "inner_link active clientInfo"
                          : "inner_link clientInfo"
                      }
                      onClick={() => {
                        setSubSection("clientInfo");
                        handleClicks(subSection);
                        scrollToSection(clientInfo);
                      }}
                    >
                      Address
                    </Link>
                  </li>
                </ul>
              </Grid>
              <Grid xs className="rightSection">
                <Box className="detailSection" ref={userInformation}>
                  <div
                    onMouseEnter={() => {
                      setSubSection("basics");
                    }}
                    ref={basics}
                  >
                    <div className="sectionTitle">
                      <h4>Branch Information</h4>
                    </div>
                    <Grid container spacing={3}>
                      <Grid xs={12}>
                        <h5 className="subHeading">Basic Info</h5>
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid sm={6} md={4} lg={3} xl={3}>
                        <RKTextField
                          class_name="inputField"
                          title={"Location Name"}
                          value={postBranchesdata.locationName}
                          attrName={["locationName", "inputfields"]}
                          value_update={updateMasterState}
                          warn_status={branchWarnStatus.locationName}
                        />
                      </Grid>

                      <Grid sm={6} md={4} lg={3} xl={3}>
                        <RKTextField
                          class_name="inputField"
                          title={"Location Type"}
                          value={postBranchesdata.locationType}
                          attrName={["locationType", "inputfields"]}
                          value_update={updateMasterState}
                          warn_status={branchWarnStatus.locationType}
                        />
                      </Grid>
                    </Grid>
                  </div>
                  <div
                    onMouseEnter={() => {
                      setSubSection("clientInfo");
                    }}
                    ref={clientInfo}
                  >
                    <Grid container spacing={3}>
                      <Grid xs={12}>
                        <h5 className="subHeading">Address</h5>
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid sm={6} md={6} lg={6} xl={6}>
                        <RKTextField
                          class_name="inputField"
                          title={"Building/Street Name"}
                          value={postBranchesdata.building}
                          attrName={["building", "inputfields"]}
                          value_update={updateMasterState}
                          warn_status={branchWarnStatus.building}
                        />
                      </Grid>
                      <Grid sm={6} md={6} lg={6} xl={6}>
                        <RKTextField
                          class_name="inputField"
                          title={"Area Name"}
                          value={postBranchesdata.area}
                          attrName={["area", "inputfields"]}
                          value_update={updateMasterState}
                          warn_status={false}
                        />
                      </Grid>
                      <Grid sm={6} md={5} lg={3} xl={3}>
                        <SearchDropdown
                          class_name="inputField"
                          title="Country"
                          value={postBranchesdata.country}
                          attrName={["country", "inputfields", "label"]}
                          value_update={updateMasterState}
                          data={countryData}
                          warn_status={branchWarnStatus.country}
                          error_message="Please select country"
                        />
                      </Grid>
                      <Grid sm={6} md={5} lg={3} xl={3}>
                        <SearchDropdown
                          class_name="inputField"
                          title="State"
                          value={
                            postBranchesdata.state ||
                            postBranchesdata.state.label
                          }
                          attrName={["state", "inputfields", "label"]}
                          value_update={updateMasterState}
                          data={stateData}
                          warn_status={branchWarnStatus.state}
                          error_message="Please select state"
                        />
                      </Grid>
                      <Grid sm={6} md={5} lg={3} xl={3}>
                        <SearchDropdown
                          class_name="inputField"
                          title="City"
                          value={postBranchesdata.city}
                          attrName={["city", "inputfields", "label"]}
                          value_update={updateMasterState}
                          data={cityData}
                          warn_status={branchWarnStatus.city}
                          error_message="Please select city"
                        />
                      </Grid>
                      <Grid sm={6} md={4} lg={3} xl={3}>
                        <RKTextField
                          class_name="inputField"
                          title={"Pincode"}
                          value={postBranchesdata.pinCode}
                          attrName={["pinCode", "inputfields"]}
                          value_update={updateMasterState}
                          warn_status={branchWarnStatus.pinCode}
                          charcterlimit={6}
                          validation_type="numeric"
                        />
                      </Grid>
                    </Grid>
                  </div>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* Footer Section */}
        <Grid container spacing={3} className="footerSection">
          <Grid xs={12} className="ctaBtn">
            <Button
              variant="outlined"
              className="borderBtn"
              onClick={() => {
                navigate(-1);
              }}
            >
              Cancel
            </Button>

            <Button
              disabled={disableSave}
              variant="contained"
              className="submitBtn"
              onClick={update_branch_data}
            >
              Update
            </Button>
          </Grid>
        </Grid>

        <Modal open={deleteStatus} className="modalWrapper">
          <Box className="modalInner">
            <Grid container spacing={1}>
              <Grid xs={12}>
                <Link
                  className="close-button"
                  onClick={() => {
                    setDeleteStatus(false);
                  }}
                />
                <h5 className="popup-heading">Are you sure ?</h5>
              </Grid>
              <Grid xs={12}>
                <Link className="md-link" underline="none">
                  You are about to Delete this Attatchment.
                </Link>
              </Grid>
              <Grid xs={12} className="ctaBtn" textAlign={"center"}>
                <Button variant="contained" className="submitBtn">
                  Yes, Delete
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Modal>
      </Box>
    </>
  );
}

export default EditBranch;
