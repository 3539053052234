import React from "react";
import { Grid } from "@mui/material";

import UGTable from "../../../../Supporting files/UGTable/UGTable";
import CommingSoon from "../../../../Components/Common/CommingSoon/CommingSoon";
import { E_BE_FILTER_CODE } from "../../../../Services/Enum/E_UGTable";

function PolicyRenewal() {
  //Table Data
  const [columns, setColumns] = React.useState([
    {
      field: "policy_id",
      headerName: "Policy ID",
      width: 150,
      check_status: true,
    },
    {
      field: "client_name",
      headerName: "Client Name",
      width: 220,
      check_status: true,
    },
    {
      field: "client_id",
      headerName: "Client ID",
      width: 170,
      check_status: true,
    },
    {
      field: "policy_type",
      headerName: "Policy Type",
      width: 200,
      check_status: true,
    },
    {
      field: "insurer",
      headerName: "Insurer",
      width: 180,
      check_status: true,
    },
    {
      field: "policy_number",
      headerName: "Policy Number",
      width: 200,
      check_status: true,
    },
    {
      field: "start_date",
      headerName: "Start Date",
      width: 100,
      check_status: true,
    },
    {
      field: "end_date",
      headerName: "End Date",
      width: 100,
      check_status: true,
    },
    {
      field: "status",
      headerName: "Status",
      width: 130,
      check_status: true,
      renderCell: (ValueFormatterParams: any) => {
        const { row } = ValueFormatterParams;
        return <span className={row.status}>{row.status}</span>;
      },
    },
    {
      field: "nol",
      headerName: "NOL",
      width: 100,
      check_status: true,
    },
    {
      field: "dependents",
      headerName: "Dependents",
      width: 100,
      check_status: true,
    },
    {
      field: "dependentstype",
      headerName: "Dependent Type",
      width: 130,
      check_status: true,
    },
    {
      field: "active_dependents",
      headerName: "No. of Dependents",
      width: 130,
      check_status: true,
    },
    {
      field: "active_lives",
      headerName: "Active Lives",
      width: 130,
      check_status: true,
    },
    {
      field: "feature_list",
      headerName: "Feature list/Limitation",
      width: 170,
      check_status: true,
    },
    {
      field: "premium",
      headerName: "Premium",
      width: 130,
      check_status: true,
    },
    {
      field: "sum_assured",
      headerName: "Sum Insured/Assured",
      width: 180,
      check_status: true,
    },
    {
      field: "city_country",
      headerName: "City - Country",
      width: 200,
      check_status: true,
    },
  ]);
  const rows = [
    {
      id: 1,
      policy_id: "EV2342342346",
      client_name: "Evervents",
      client_id: "EV3234",
      policy_type: "Group Health Policy",
      insurer: "Reliance",
      nol: "234",
      dependents: "Yes",
      dependentstype: "Spouse, Child",
      policy_number: "BG234234HGFG",
      start_date: "22-02-2022",
      end_date: "22-02-2023",
      status: "Active",
      active_lives: "1221",
      active_dependents: "611",
      feature_list: "2",
      premium: "₹ 2,35,234",
      sum_assured: "2L, 3L, 5L",
      city_country: " Mumbai - India",
    },
    {
      id: 2,
      policy_id: "EV234234234",
      client_name: "Evervent",
      client_id: "EV3234",
      policy_type: "Group Health Policy",
      insurer: "Reliance",
      nol: "234",
      dependents: "No",
      dependentstype: "Spouse, Child",
      policy_number: "BG234234HGFG",
      start_date: "22-02-2022",
      end_date: "22-02-2023",
      status: "Rejected",
      active_lives: "1221",
      active_dependents: "0",
      feature_list: "2",
      premium: "₹ 2,35,234",
      sum_assured: "2L, 3L, 5L",
      city_country: "Mohali - India",
    },
    {
      id: 3,
      policy_id: "EV234234234",
      client_name: "Evervent",
      client_id: "EV3234",
      policy_type: "Group Health Policy",
      insurer: "Reliance",
      nol: "234",
      dependents: "Yes",
      dependentstype: "Spouse, Child",
      policy_number: "BG234234HGFG",
      start_date: "22-02-2022",
      end_date: "22-02-2023",
      status: "Active",
      active_lives: "1221",
      active_dependents: "611",
      feature_list: "2",
      premium: "₹ 2,35,234",
      sum_assured: "2L, 3L, 5L",
      city_country: "Chennai - India",
    },
    {
      id: 4,
      policy_id: "EV234234234",
      client_name: "Evervent",
      client_id: "EV3234",
      policy_type: "Group Health Policy",
      insurer: "Reliance",
      nol: "234",
      dependents: "No",
      dependentstype: "Spouse, Child",
      policy_number: "BG234234HGFG",
      start_date: "22-02-2022",
      end_date: "22-02-2023",
      status: "Rejected",
      active_lives: "1221",
      active_dependents: "0",
      feature_list: "2",
      premium: "₹ 2,35,234",
      sum_assured: "2L, 3L, 5L",
      city_country: "Chandigarh - India",
    },
    {
      id: 5,
      policy_id: "EV234234234",
      client_name: "Evervent",
      client_id: "EV3234",
      policy_type: "Group Health Policy",
      insurer: "Reliance",
      nol: "234",
      dependents: "Yes",
      dependentstype: "Spouse, Child",
      policy_number: "BG234234HGFG",
      start_date: "22-02-2022",
      end_date: "22-02-2023",
      status: "Active",
      active_lives: "1221",
      active_dependents: "611",
      feature_list: "2",
      premium: "₹ 2,35,234",
      sum_assured: "2L, 3L, 5L",
      city_country: "Mohali - India",
    },
  ];

  const updateMasterState = (attrName: any, value: any) => {
    attrName(value);
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <h3 className="mb-0">Policy Renewal</h3>
      </Grid>

      <Grid item xs={12}>
        <CommingSoon />
      </Grid>
    </Grid>
  );
}
export default PolicyRenewal;
