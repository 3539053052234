import { Box, Button, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React from "react";
import SlidingPanel from "react-sliding-side-panel";
import CloseIcon from "@mui/icons-material/Close";
import SearchDropdown from "../../../../../Supporting files/SearchDropdown/SearchDropdown";
import DatePicker from "../../../../../Supporting files/DatePicker/DatePicker";
import RKTextField from "../../../../../Supporting files/RKTextField/RKTextField";
import SelectDropdown from "../../../../../Supporting files/SelectDropdown/SelectDropdown";
interface Props {
  open_status: boolean;
  attrName: any;
  value_update: Function;
}

const TermPopup: React.FC<Props> = ({
  open_status,
  attrName,
  value_update,
}) => {
  const [annualIncome, setAnnualIncome] = React.useState("");
  const [smoke, setSmoke] = React.useState("");
  const [gender, setGender] = React.useState("");
  const [age, setAge] = React.useState("");
  const [pincode, setPincode] = React.useState("");
  const [mobile, setMobile] = React.useState("");

  const updateMasterState = (attrName: any, value: any) => {
    attrName(value);
  };

  const annualIncome_data = [
    { label: "₹ 2 Lakh", value: "₹ 2 Lakh" },
    { label: "₹ 3 Lakh", value: "₹ 3 Lakh" },
    { label: "₹ 4 Lakh", value: "₹ 4 Lakh" },
    { label: "₹ 5 Lakh", value: "₹ 5 Lakh" },
  ];
  const smoke_data = [
    { Key: "Yes", value: "Yes" },
    { Key: "No", value: "No" },
  ];
  const gender_data = [
    { Key: "Yes", value: "Yes" },
    { Key: "No", value: "No" },
  ];
  const age_data = [
    { label: "18 Years", value: "18 Years" },
    { label: "19 Years", value: "19 Years" },
    { label: "20 Years", value: "20 Years" },
    { label: "21 Years", value: "21 Years" },
  ];

  return (
    <>
      <SlidingPanel
        type={"right"}
        isOpen={open_status}
        size={75}
        panelClassName="slidingPanel"
        // backdropClicked={() => value_update(attrName, false)}
      >
        <>
          <Grid container spacing={2} alignItems="center">
            <Grid xs={10} className="headingTitle">
              <h3>
                <span>Add Your Details</span>
              </h3>
              <p>Kindly provide essential details about your Travel.</p>
            </Grid>
            <Grid xs={2} textAlign="right">
              <Link
                className="closeIcon"
                onClick={() => {
                  value_update(attrName, false);
                }}
              >
                <CloseIcon />
              </Link>
            </Grid>
            <Grid xs={12}>
              <hr />
            </Grid>
          </Grid>
          <Box className="scrollable_area">
            <Grid container spacing={3}>
              <Grid xs={6}>
                <SearchDropdown
                  class_name="inputField"
                  title="Annual Income"
                  value={annualIncome}
                  attrName={setAnnualIncome}
                  value_update={updateMasterState}
                  data={annualIncome_data}
                  warn_status={false}
                  error_message={"please select type"}
                />
              </Grid>
              <Grid xs={6}>
                <SelectDropdown
                  class_name="inputField"
                  title="Do You Smoke"
                  value={smoke}
                  attrName={setSmoke}
                  value_update={updateMasterState}
                  dropdown_data={smoke_data}
                  warn_status={false}
                />
              </Grid>
              <Grid xs={6}>
                <SelectDropdown
                  class_name="inputField"
                  title="Gender"
                  value={gender}
                  attrName={setGender}
                  value_update={updateMasterState}
                  dropdown_data={gender_data}
                  warn_status={false}
                />
              </Grid>
              <Grid xs={6}>
                <SearchDropdown
                  class_name="inputField"
                  title="Age"
                  value={age}
                  attrName={setAge}
                  value_update={updateMasterState}
                  data={age_data}
                  warn_status={false}
                  error_message={"please select age"}
                />
              </Grid>
              <Grid xs={6}>
                <RKTextField
                  class_name="inputField"
                  title="Pin Code"
                  value={pincode}
                  attrName={setPincode}
                  value_update={updateMasterState}
                  warn_status={false}
                />
              </Grid>

              <Grid xs={6}>
                <RKTextField
                  class_name="inputField"
                  title="Mobile"
                  value={mobile}
                  attrName={setMobile}
                  value_update={updateMasterState}
                  warn_status={false}
                />
              </Grid>
            </Grid>
          </Box>
          <Grid container spacing={3}>
            <Grid xs={12} className="ctaBtn mt-4">
              <Button
                variant="contained"
                className="submitBtn fixedbtn"
                onClick={() => {}}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </>
      </SlidingPanel>
    </>
  );
};

export default TermPopup;
