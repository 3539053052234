import Box from "@mui/material/Box";
import { Grid, Link, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import NewLoader from "../../../../../Components/NewLoader/NewLoader";
import { CLIENT_SERVICES } from "../../../../../Services/Clients/ClientService";
import { EMPLOYEE_SERVICES } from "../../../../../Services/Employee/EmployeeService";
import { useAppSelector } from "../../../../../Store/hooks";
import { useFormatCurrency } from "../../../../../Supporting files/CustomHooks";
import UGTable from "../../../../../Supporting files/UGTable/UGTable";
import "./../Policies.scss";
import Employed from "@mui/icons-material/Badge";
import Active from "@mui/icons-material/CheckCircle";
import Terminated from "@mui/icons-material/DirectionsRun";
import Void from "@mui/icons-material/DoDisturbOff";
import Inactive from "@mui/icons-material/HighlightOff";
import Probation from "@mui/icons-material/ModelTraining";
import NA from "@mui/icons-material/NotAccessible";
import Resigned from "@mui/icons-material/SubtitlesOff";

import { E_BE_FILTER_CODE } from "../../../../../Services/Enum/E_UGTable";
import SelectDropdown from "../../../../../Supporting files/SelectDropdown/SelectDropdown";
import {
  capitalize,
  date_time_format,
} from "../../../../../Supporting files/HelpingFunction";
import FilterComponent from "../../../../../Supporting files/Filter/Filter";
import SearchDropdown from "../../../../../Supporting files/SearchDropdown/SearchDropdown";
interface MainMember {
  name: string;
  dob: string;
  relation: string;
  memberType: string;
  employeeId: string;
  gender: string;
  email: string;
  createdAt: string;
  updatedAt: string;
  department: string;
  designation: string;
  doj: string;
  salaryBracket: string;
  ldow: string;
  Salary: string;
  employement_status: number;
  status: string;
  _id: string;
}

const AssignBulkPolicy: React.FC<{
  clientId: string;
  id: string;
  policyNumber: string;
  Client: string;
}> = ({ clientId, id, policyNumber, Client }) => {
  const { formatCurrency } = useFormatCurrency();

  const user_type_dateFormat = useAppSelector(
    (state) => state.userDetailsSlice.localInformation.dateFormat
  );
  const tz = useAppSelector(
    (state) => state.userDetailsSlice.localInformation.timeZone
  );
  const userType = useAppSelector((state) => state.userDetailsSlice.userType);
  const [columns, setColumns] = useState<any>([]);
  const [selectedIds, setSelectedIds] = useState<any[]>([]);
  const [employeeData, setEmployeeData] = useState<any[]>([]); 
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [isLoader, setIsLoader] = useState<boolean>(true);
  const [pageStatus, setpageStatus] = useState<number>(1);
  const [downloadCsvData, setdownloadCsvData] = useState<any>({});
  const [assignedResponse, setAssignedResponse] = useState<any>({
    errors: [],
    successData: [],
  });
  const [selectedGrade, setselectedGrade] = useState("");
  const [selectedGradeDropDown, setselectedGradeDropDown] = useState([]);
  const [isCustomPolicy, setisCustomPolicy] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [tableLimit, setTableLimit] = useState();
  const [tableSkip, setTableSkip] = useState();
  const [employementStatusData, setEmployementStatusData] = useState<any[]>([]);
  const [prioritystatusData, setprioritystatusData] = useState([]);
  const [errorFileStatus, seterrorFileStatus] = useState<string>("errors");
  let memStatus: number = 32;

  useEffect(() => {
    get_status();
    get_status_employement();
    get_custom_dropDown();
    //fetchEmployeeData();
  }, []);
  const get_status = () => {
    const onSuccess = (res: any) => {
      let statusData = res.data.data.map((data: any) => ({
        label: data.value,
        value: data.label,
      }));
      setprioritystatusData(statusData);
    };

    const onError = (err: any) => {
      console.log("err", err);
    };

    EMPLOYEE_SERVICES.get_status(onSuccess, onError);
  };

  const get_status_employement = () => {
    const onSuccess = (res: any) => {
      let statusData = res.data.data.map((data: any) => ({
        label: data.value,
        value: data.label,
      }));
      setEmployementStatusData(statusData);
    };

    const onError = (err: any) => {
      console.log("err", err);
    };

    EMPLOYEE_SERVICES.get_status_employement(onSuccess, onError);
  };
  const get_custom_dropDown = () => {
    const onSuccess = (res: any) => {
      let dd = res.data.data.grades.map((data: any) => ({
        label: data,
        value: data,
      }));
      setselectedGradeDropDown(dd);
      setisCustomPolicy(res.data.data.isCustomGrade);
    };

    const onError = (err: any) => {
      console.log("err", err);
    };

    EMPLOYEE_SERVICES.get_custom_dropDown(onSuccess, onError, id);
  };

  const fetchEmployeeData = (
    tableLimit?: number,
    tableSkip?: number,
    query?: string
  ) => {
    setIsLoader(true);
    let url;
    if (query) {
      url = `clientId=${clientId}&policyId=${id}&${query}&limit=${
        tableLimit ?? 25
      }&skip=${tableSkip ?? 0}`;
    } else {
      url = `clientId=${clientId}&policyId=${id}&limit=${
        tableLimit ?? 25
      }&skip=${tableSkip ?? 0}`;
    }

    const onSuccess = (res: any) => {
      setTotalRecords(res.data.data.count);
      const response = res.data.data.memberData.map((data: MainMember) => ({
        ...data, // Spread the existing properties
        id: data._id, // Add or overwrite the `id` property
      }));
      setEmployeeData(response);
      setColumns([
        {
          field: "email",
          headerName: "email",
          width: 220,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return row.email;
          },
        },
        {
          field: "Office Contact Number",
          headerName: "Office Contact Number",
          flex: 2,
          minWidth: 200,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return row["Office Contact Number"];
          },
        },
        {
          field: "status",
          headerName: "Member Status",
          minWidth: 200,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            let { row, index } = ValueFormatterParams;
            return (
              <div id={`_${memStatus}`}>
                <div className="status_dropdown" id={`_${memStatus}`}>
                  {memStatus === 32 ? (
                    <Active id={`_${memStatus.toString()}`} />
                  ) : memStatus === 33 ? (
                    <Inactive id={`_${memStatus.toString()}`} />
                  ) : memStatus === 34 ? (
                    <Void id={`_${memStatus.toString()}`} />
                  ) : null}
                  <SelectDropdown
                    class_name="inputFieldd"
                    title=""
                    value={memStatus}
                    attrName={["updatestatus"]}
                    value_update={() => {}}
                    dropdown_data={[{ label: "32", value: "Active" }]}
                    warn_status={false}
                    disabled={true}
                  />
                </div>
              </div>
            );
          },
        },
        {
          field: "employement_status",
          headerName: "Employment Status",
          minWidth: 200,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row, index } = ValueFormatterParams;
            return (
              <div
                id={`_${row.employement_status}`}
                style={{ borderRadius: "20px" }}
              >
                <div
                  className="status_dropdown"
                  id={`_${row.employement_status?.toString()}`}
                >
                  {row.employement_status === 35 ? (
                    <Resigned id={`_${row.employement_status?.toString()}`} />
                  ) : row.employement_status === 36 ? (
                    <Terminated id={`_${row.employement_status?.toString()}`} />
                  ) : row.employement_status === 37 ? (
                    <Employed id={`_${row.employement_status?.toString()}`} />
                  ) : row.employement_status === 38 ? (
                    <Probation id={`_${row.employement_status?.toString()}`} />
                  ) : row.employement_status === 39 ? (
                    <NA id={`_${row.employement_status?.toString()}`} />
                  ) : null}
                  <SelectDropdown
                    class_name="inputFieldd"
                    title=""
                    value={row.employement_status}
                    attrName={["updatestatusEmployment", row, index]}
                    value_update={() => {}}
                    dropdown_data={[
                      {
                        value: "Resigned",
                        label: 35,
                      },
                      {
                        value: "Terminated",
                        label: 36,
                      },
                      {
                        value: "Employed",
                        label: 37,
                      },
                      {
                        value: "Probation",
                        label: 38,
                      },
                      {
                        value: "NA",
                        label: 39,
                      },
                    ]}
                    warn_status={false}
                    disabled={true}
                  />
                </div>
              </div>
            );
          },
        },
        {
          field: "dob",
          minWidth: 200,
          check_status: true,
          headerName: "Date Of Birth",
          renderCell: (ValueFormatterParams: any) => {
            let { row } = ValueFormatterParams;
            return (
              <span>
                {row["dob"] === "--" ||
                row["dob"] === null ||
                row["dob"] === "" ||
                row["dob"] === undefined
                  ? "--"
                  : date_time_format(row["dob"], user_type_dateFormat, tz)}
              </span>
            );
          },
        },
        {
          field: "doj",
          minWidth: 200,
          check_status: true,
          headerName: "Date Of Joining",
          renderCell: (ValueFormatterParams: any) => {
            let { row } = ValueFormatterParams;
            return (
              <span>
                {row["doj"] === "--" ||
                row["doj"] === null ||
                row["doj"] === "" ||
                row["doj"] === undefined
                  ? "--"
                  : date_time_format(row["doj"], user_type_dateFormat, tz)}
              </span>
            );
          },
        },
        {
          field: "ldow",
          minWidth: 200,
          check_status: true,
          headerName: "Last Day Of Working",
          renderCell: (ValueFormatterParams: any) => {
            let { row } = ValueFormatterParams;
            return (
              <span>
                {row["ldow"] === "--" ||
                row["ldow"] === null ||
                row["ldow"] === "" ||
                row["ldow"] === undefined
                  ? "--"
                  : date_time_format(row["ldow"], user_type_dateFormat, tz)}
              </span>
            );
          },
        },
        {
          field: "Organization Employee ID",
          minWidth: 200,
          check_status: true,
          headerName: "Organization Employee ID",
        },
        {
          field: "employeeId",
          minWidth: 200,
          check_status: true,
          headerName: "employeeId",
        },
        {
          field: "department",
          minWidth: 200,
          check_status: true,
          headerName: "Department",
        },
        {
          field: "designation",
          minWidth: 200,
          check_status: true,
          headerName: "Designation",
        },
        {
          field: "email",
          minWidth: 200,
          check_status: true,
          headerName: "email",
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return (
              <Link
                underline="none"
                style={{ cursor: "pointer" }}
                href={`mailto:${row.email}`}
              >
                {row["Email"]}
              </Link>
            );
          },
        },
        {
          field: "Salary",
          minWidth: 200,
          check_status: true,
          headerName: "Salary",
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return (
              <span>
                {row["Salary"] === "--" ||
                row["Salary"] === null ||
                row["Salary"] === ""
                  ? "--"
                  : formatCurrency(row["Salary"])}
              </span>
            );
          },
        },
        {
          field: "gender",
          minWidth: 200,
          check_status: true,
          headerName: "Gender",
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return capitalize(row.gender);
          },
        },
        {
          field: "createdAt",
          minWidth: 200,
          check_status: true,
          headerName: "Created At",
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return (
              <span>
                {date_time_format(
                  row.createdAt,
                  `${user_type_dateFormat} hh:mm A`,
                  tz
                )}
              </span>
            );
          },
        },
        {
          field: "updatedAt",
          minWidth: 200,
          check_status: true,
          headerName: "Updated At",
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return (
              <span>
                {date_time_format(
                  row.updatedAt,
                  `${user_type_dateFormat} hh:mm A`,
                  tz
                )}
              </span>
            );
          },
        },
      ]);
      setTimeout(() => {
        setIsLoader(false);
      }, 1500);
    };

    const onError = (err: any) => {
      console.log("Error fetching data:", err);
    };
    EMPLOYEE_SERVICES.get_employee_dependant_list(
      onSuccess,
      onError,
      userType?.toLowerCase(),
      url
    );
  };

  const downloadCsv = (CsvData: any) => {
    const onSuccess = async (res: any) => {
      const data = await res;
      const blob = new Blob([data.data], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "bulkAssignPolicy.csv";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    };
    const onError = (err: any) => {
      console.log("sucess", err);
    };
    const updatedArr = CsvData.errors?.map((item: any) => {
      return item;
    });
    let dto = {
      headers: ["memberName", "error"],
      data: updatedArr,
    };

    CLIENT_SERVICES.csvDownload(
      onSuccess,
      onError,
      dto,
      userType?.toLowerCase(),
      "bulkAssignPolicy"
    );
  };

  const assignPolicy = () => {
    let payload = {
      members: selectedIds,
    };
    const onSuccess = (res: any) => {
      setpageStatus(2);
      setAssignedResponse(res.data.data);
      setdownloadCsvData(res.data.data);
      //downloadCsv(res.data.data);
    };

    const onError = (err: any) => {
      console.log("Error fetching data:", err);
    };
    let hasError = false;

    if (isCustomPolicy && !selectedGrade) {
      hasError = true;
    }
    {
      !hasError &&
        EMPLOYEE_SERVICES.assignPolicy(
          payload,
          onSuccess,
          onError,
          userType?.toLowerCase(),
          id,
          selectedGrade
        );
    }
  };

  const createFilterUrl = (
    Limit?: number,
    Skip?: number,
    filters?: { [key: string]: string[] },
    type?: string
  ) => {
    if (type === "clear") {
      setSearchQuery("");
    }
    if (filters) {
      const queryParts = Object.entries(filters)?.map(([key, values]) => {
        if (Array.isArray(values)) {
          return `${key.toLowerCase().replaceAll(" ", "")}=${values.join(",")}`;
        } else {
          return `${key.toLowerCase().replaceAll(" ", "")}=${Object.values(
            values
          ).join(",")}`;
        }
      });
      let query = queryParts.join("&");
      setSearchQuery(query);
      // Join all parts with "&" to form the complete query string as a single string
      fetchEmployeeData(
        Limit ?? tableLimit,
        Skip ?? tableSkip,
        query ? query : searchQuery
      );
    } else {
      fetchEmployeeData(
        Limit ?? tableLimit,
        Skip ?? tableSkip,
        type === "clear" ? "" : searchQuery
      );
    }
  };

  const updateMasterState = (attrName: any, value: any) => {
    if (attrName === "selected_data") {
      setSelectedIds(value);
    }
  };
  return (
    <>
      {isLoader && <NewLoader />}
      {pageStatus === 1 && (
        <>
          <Grid xs paddingLeft={"0"}>
            <Box>
              <Grid container>
                <Grid xs={12} textAlign={"right"}>
                  <FilterComponent
                    allFilterNameApiService={EMPLOYEE_SERVICES.dynamicFilter}
                    apiFiltervaluesByName={
                      EMPLOYEE_SERVICES.getFiltervaluesByName
                    }
                    clientId={clientId}
                    sendData={createFilterUrl}
                  />
                </Grid>
              </Grid>
              <Grid xs={12} className="px-0 py-0">
                <Grid xs={12} className="detailBox mb-5 mt-3">
                  <Grid container padding="0">
                    <Grid xs={12} width={"100%"}>
                      <UGTable
                        header_data={columns}
                        data={employeeData}
                        value_update={updateMasterState}
                        attrName={"UGTable"}
                        BE_filter_code={E_BE_FILTER_CODE.EMPLOYEE}
                        totalRecords={totalRecords}
                        refetch_data={createFilterUrl}
                        setTableLimit={setTableLimit}
                        setTableSkip={setTableSkip}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid container spacing={3}>
            <Grid xs={12} className="ctaBtn" style={{ textAlign: "center" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "100px",
                }}
              >
                <Box>
                  <Button
                    variant="contained"
                    className="submitBtn"
                    onClick={assignPolicy}
                  >
                    Assign policy
                  </Button>
                </Box>
                {isCustomPolicy && (
                  <Box maxWidth={"300px"} width={"100%"}>
                    <SearchDropdown
                      class_name="inputField"
                      title="Select Grade"
                      value={selectedGrade}
                      attrName={"sdfds"}
                      value_update={(e: any, v: any) => {
                        setselectedGrade(v.value);
                      }}
                      data={selectedGradeDropDown}
                      error_message="select grade"
                      warn_status={
                        isCustomPolicy && !selectedGrade ? true : false
                      }
                    />
                  </Box>
                )}
              </Box>
            </Grid>
            {/* {isCustomPolicy && (
              <Grid xs={2} className="ctaBtn" style={{ textAlign: "center" }}>
                <SearchDropdown
                  class_name="inputField"
                  title="Select Grade"
                  value={selectedGrade}
                  attrName={"sdfds"}
                  value_update={(e: any, v: any) => {
                    setselectedGrade(v.value);
                  }}
                  data={selectedGradeDropDown}
                  error_message="select grade"
                  warn_status={isCustomPolicy && !selectedGrade ? true : false}
                />
              </Grid>
            )} */}
          </Grid>
        </>
      )}

      {pageStatus === 2 && (
        <Box className="BulklayoutWrapper" style={{ paddingTop: "10px" }}>
          <Grid container spacing={3}>
            <Grid xs={12} className="innerSection pl-10 pr-10 mt-3">
              <Grid container spacing={3}>
                <Grid xs={12} className="mapfields_instructions mt-3 mb-4">
                  <Grid container spacing={3} padding="0" className="mt-3 ml-3">
                    <Grid xs={8} textAlign={"center"}>
                      <ul className="recordViewList">
                        <li className="black_li">
                          Total Members:{" "}
                          <span>
                            {assignedResponse?.errors?.length +
                              assignedResponse?.successData?.length}
                          </span>
                        </li>
                        <li
                          onClick={() => {
                            seterrorFileStatus("successData");
                          }}
                          style={
                            errorFileStatus === "successData"
                              ? {
                                  textDecoration: "underline",
                                  cursor: "pointer",
                                }
                              : { cursor: "pointer" }
                          }
                        >
                          Added:{" "}
                          <span>{assignedResponse?.successData?.length}</span>
                        </li>
                        <li
                          onClick={() => {
                            seterrorFileStatus("errors");
                          }}
                          style={
                            errorFileStatus === "errors"
                              ? {
                                  textDecoration: "underline",
                                  cursor: "pointer",
                                }
                              : { cursor: "pointer" }
                          }
                        >
                          Skipped:{" "}
                          <span>{assignedResponse?.errors?.length}</span>
                        </li>
                        <li>
                          <Link
                            underline="none"
                            className="blueBtn download"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              downloadCsv(downloadCsvData);
                            }}
                          >
                            Download CSV
                          </Link>
                        </li>
                      </ul>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid xs={12} padding="0">
                  <div className="table" style={{ overflowX: "hidden" }}>
                    <div
                      className="renewal-table2"
                      style={{ overflow: "visible" }}
                    >
                      <ul
                        className="heading"
                        style={{ justifyContent: "start" }}
                      >
                        <li>
                          <p>Member Name</p>
                        </li>
                        <li>
                          <p>Status/Reason</p>
                        </li>
                      </ul>
                      {assignedResponse[errorFileStatus]?.length > 0 &&
                        assignedResponse[errorFileStatus]?.map((data: any) => (
                          <ul
                            className="subheading"
                            style={{ justifyContent: "start" }}
                          >
                            <li>
                              <p className="record_p">{data.memberName}</p>
                            </li>
                            <li>
                              <p
                                className="record_p"
                                style={{ width: "500px" }}
                              >
                                {errorFileStatus === "successData"
                                  ? "Policy has been assigned Successfully"
                                  : data.error}
                              </p>
                            </li>
                          </ul>
                        ))}
                      {assignedResponse[errorFileStatus]?.length === 0 && (
                        <div
                          className="no_records"
                          style={{ background: "none" }}
                        >
                          <span>No Records Available</span>
                        </div>
                      )}
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};

export default AssignBulkPolicy;
