import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Box, Button, Link, Modal } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ModulePermissionAccessControl from "../../../../../../Components/Broker/ModulePermissionAccessControl/ModulePermissionAccessControl";
import "../../../../../../formLayout.scss";
import { ACCESS_LEVEL_SERVICES } from "../../../../../../Services/Settings/AccessLevelServices";
import RKTextField from "../../../../../../Supporting files/RKTextField/RKTextField";

function EditCreateRole() {
  const navigate = useNavigate();
  const roleInformation = useRef(null);
  const rolename = useRef(null);
  const modulePermission = useRef(null);
  const [roleName, setRoleName] = React.useState("");
  const [perviouRoleName, setPerviousRoleName] = React.useState({
    parentId: "",
    rolename: "",
  });
  const [accessData, setAccessData] = useState<any[]>([]);
  const [perrsmission, setPerrsmission] = React.useState("");
  const [role_warn, setRole_warn] = useState<boolean>(false);
  const [sectionStatus, SetSectionStatus] =    React.useState<string>("roleInformation");
  const [subSection, setSubSection] = React.useState<string>("rolename");
  const [id, setId] = React.useState<string>("");
  const updateMasterState = (attrName: any, value: any) => {    attrName(value);  };

  useEffect(() => {
    // ACCESS_LEVEL_SERVICES.user_data_by_id
    const windowURL = window.location.search;
    const params = new URLSearchParams(windowURL);
    const id = params.get("id");
    setId(id ?? "");
    user_data(id ?? "");
  }, []);
  const getData = (attr: any, data: any) => {
    setAccessData(data);
  };
  const scrollToSection = (elementRef: any) => {
    elementRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  // update
  const update_data = () => {
    let data: boolean = false;
    data =
      roleName.length === 0 || perviouRoleName.rolename.length === 0
        ? true
        : false;
    setRole_warn(data);
    if (data === false) {
      let params = {
        _id: id,
        roleName: roleName,
        previousRoleName: perviouRoleName.rolename,
        parentsId: perviouRoleName.parentId,
        perrsmission: accessData,
      };
      const onSuccess = (res: any) => {
        toast.success(res.data.message);
        navigate(-1);
      };
      const onError = (err: any) => {
        console.log("err", err);
      };
      ACCESS_LEVEL_SERVICES.user_access_control_update(
        params,
        onSuccess,
        onError
      );
    }
  };

  const handleClicks = (ref: any) => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };
  
  const user_data = (id: string) => {
    const onSuccess = (res: any) => {
      setRoleName(res.data.data[0].roleName);
      setAccessData(res.data.data[0].perrsmission);
      setPerviousRoleName({
        rolename: res.data.data[0].roleName,
        parentId: res.data.data[0].parentsId,
      });
    };
    const onError = (err: any) => {
      console.log(".......,,", err);
    };
    ACCESS_LEVEL_SERVICES.access_control_user_data_by_id(
      id,
      onSuccess,
      onError
    );
  };

  return (
    <Box className="layoutWrapper">
      {/* Page header */}
      <Grid container spacing={3} className="pageHeader">
        <Grid xs={6} className="pageTitle">
          <Link
            className="backStep"
            onClick={() => {
              navigate(-1);
            }}>
            <ChevronLeftIcon />
          </Link>

          <div>
            <h3>Edit {roleName} Role</h3>
          </div>
        </Grid>
        <Grid xs={6}></Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid xs={12}>
          <Grid container spacing={3}>
            <Grid xs className="leftSection">
              <Link
                className={
                  sectionStatus === "roleInformation"
                    ? "active sectionLink roleInformation"
                    : "sectionLink roleInformation"
                }
                onClick={() => {
                  handleClicks(sectionStatus);
                  scrollToSection(roleInformation);
                  SetSectionStatus("roleInformation");
                }}>
                <span> Role Information</span>
              </Link>
              <ul className="accordion_list">
                <li>
                  <Link
                    className={
                      subSection === "rolename"
                        ? "inner_link active rolename"
                        : "inner_link  rolename"
                    }
                    onClick={() => {
                      handleClicks(subSection);
                      scrollToSection(rolename);
                      setSubSection("rolename");
                    }}>
                    Role Name
                  </Link>
                </li>
                <li>
                  <Link
                    className={
                      subSection === "modulePermission"
                        ? "inner_link active modulePermission"
                        : "inner_link modulePermission"
                    }
                    onClick={() => {
                      setSubSection("modulePermission");
                      handleClicks(subSection);
                      scrollToSection(modulePermission);
                    }}>
                    Module Permission
                  </Link>
                </li>
              </ul>
            </Grid>
            <Grid xs className="rightSection">
              <Box className="detailSection" ref={roleInformation}>
                <div ref={rolename}>
                  <div className="sectionTitle">
                    <h4>Role Name</h4>
                  </div>
                  <Grid container spacing={3}>
                    <Grid xs={12}>
                      <h5 className="subHeading">Role Name</h5>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid sm={6} md={4} lg={3} xl={3}>
                      <RKTextField
                        validation_type="name"
                        class_name="inputField"
                        title={"Role Name"}
                        value={roleName}
                        attrName={setRoleName}
                        value_update={updateMasterState}
                        warn_status={role_warn}
                      />
                    </Grid>
                  </Grid>
                </div>
                <div ref={modulePermission}>
                  <ModulePermissionAccessControl
                    value_update={getData}
                    dataArray={accessData === undefined ? [] : accessData}
                  />
                </div>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* Footer Section */}
      <Grid container spacing={3} className="footerSection">
        <Grid xs={12} className="ctaBtn">
          <Button
            variant="outlined"
            className="borderBtn"
            onClick={() => {
              navigate(-1);
            }}>
            Cancel
          </Button>

          <Button
            variant="contained"
            className="submitBtn"
            onClick={update_data}>
            Update
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

export default EditCreateRole;
