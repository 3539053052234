import InfoIcon from "@mui/icons-material/Info";
import { Box, Link, ToggleButton, ToggleButtonGroup } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Highcharts from "highcharts";
import Highcharts3D from "highcharts/highcharts-3d";
import HighchartsAccessibility from "highcharts/modules/accessibility";
import HighchartsExportData from "highcharts/modules/export-data";
import HighchartsExporting from "highcharts/modules/exporting";
import moment from "moment";
import { useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { URL_CONSTANTS } from "../../../../APIVar/URLConstants";
import NewLoader from "../../../../Components/NewLoader/NewLoader";
import { EMPLOYER_ALL_ROUTES } from "../../../../Routes/employer_all_routes";
import { EMPLOYER_DASHBOARD_SERVICES } from "../../../../Services/EmployerServices/Dashboard/Employer_Dashboard_Services";
import { Get } from "../../../../Supporting files/CallAPI";
import { useFormatCurrency } from "../../../../Supporting files/CustomHooks";
import SearchDropdown from "../../../../Supporting files/SearchDropdown/SearchDropdown";
import UGDateRangePicker from "../../../../Supporting files/UGDateRangePicker/UGDateRangePicker";
import UGGraph from "../../../../Supporting files/UGGraph/UGGraph";
import "./Dashboard.scss";

Highcharts3D(Highcharts);
HighchartsExporting(Highcharts);
HighchartsExportData(Highcharts);
HighchartsAccessibility(Highcharts);


const optionsDonutChart = {
  pieHole: 0.4,
  is3D: true,
  height: 400,
  legend: {
    position: "bottom",
    alignment: "center",
    textStyle: {
      color: "#666",
      fontSize: 12,
    },
  },
};

const barColorCode = [
  "#580DE0",
  "#576CEF",
  "#F3B03D",
  "#E45C8B",
  "#dfa088",
  "#32c1b4",
  "#580DE0",
  "#576CEF",
  "#F3B03D",
  "#E45C8B",
  "#dfa088",
  "#32c1b4",
];
interface EndrArrItem {
  [key: string]: number | string;
}

const Dashboard = () => {
  const navigate = useNavigate();
  const { formatCurrency } = useFormatCurrency();
  const [requestType, setRequestType] = useState<"endoresement" | "claim" | null>("endoresement");
  const [productType, setProductType] = useState<string | null>("all");
  const [graphData, setGraphData] = useState<any[]>([]);
  const [graphDataDateCreated, setGraphDataDateCreated] = useState<any[]>([]);
  const [countData, setCountData] = useState<{
    criticalBalance: any[];
    activePolicies: any;
    totalPremium: any;
    lives: any;
    alertData: any;
    claimGraph: any[];
    endorsementGraph: any[];
    clientData: any[];
    liveDivision: any[];
    livesGraph: any[];
  }>({
    criticalBalance: [],
    activePolicies: {},
    totalPremium: {},
    lives: {},
    alertData: {},
    claimGraph: [],
    endorsementGraph: [],
    clientData: [],
    liveDivision: [],
    livesGraph: [],
  });
  const [policies, setPolicies] = useState<any[]>([]);
  const [gradedTotalSum, setGradedTotalSum] = useState<string>("");
  const [activeMember, setActiveMember] = useState<any[]>([]);
  const [policyType, setPolicyType] = useState<any[]>([]);
  const [premium, setPremium] = useState<any[]>([]);
  const [viewTabsStatus, setViewTabsStatus] = useState<string>("India");
  const [origin, setOrigin] = useState<any>({
    label: "India",
    value: "IN",
    origin: "IN",
  });
  const [mapType, setMapType] = useState<"India" | "World" | null>("India");
  const [geoChart, setGeoChart] = useState<any>([]);
  const [endorsementChartBarData, setEndorsementChartBarData] = useState<any[]>([]);
  const [claimChartBarData, setClaimChartBarData] = useState<any[]>([]);
  const today = new Date();
  var currentDate = new Date();
  currentDate.setDate(currentDate.getDate() - 7);
  const [showLoader, setShowLoader] = useState(true);
  const [startEndDate, setStartEndDate] = useState<any>({
    startDate: moment(currentDate).format("YYYY-MM-DD"),
    endDate: moment(new Date()).format("YYYY-MM-DD"),
  });
  const [countryData, setCountryData] = useState<Array<{ label: string; id: string; origin: string }>>([]);
  const [livesTabValue, setlivesTabValue] = useState<"DOJ" | "DC">("DC")
  const alertSummary = [
    {
      class_name: "claimicon",
      title: "Claim",
      value: countData?.alertData?.totalClaimCount ?? "0",
    },
    {
      class_name: "endoreseicon",
      title: "Endorsement",
      value: countData?.alertData?.totalEndorsementCount ?? "0",
    },
  ];
  const optionsGeoChart = {
    region: origin.origin,
    domain: origin.origin,
    displayMode: "regions",
    tooltip: { isHtml: true, trigger: "both" }, // Display tooltip on hover and selection
    resolution: "provinces", // Show state boundaries
    defaultColor: "#ffffff",
    colorAxis: {
      colors: [
        "#e5ef88", // Light color, likely representing lower values.
        "#d4b114", // A middle color, representing intermediate values.
        "#e85a03", // Dark color, representing higher values.
      ],
    },
  };
  const [settings] = useState({
    className: "center",
    // centerMode: true,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: false,
    speed: 5000,
    autoplaySpeed: 3000,
    // cssEase: "linear",
    arrows: false,
    dots: true,
  });

  const onChangeDate = (attrName: any, value: any) => {
    if (attrName === "rangedatepicker") {
      setStartEndDate({ startDate: value[0], endDate: value[1] });
    }
  };

  const get_count = () => {
    const onSuccess = (res: any) => {
      if (res.status === 200) {
        setTimeout(() => {
          setShowLoader(false);
        }, 1500);
      }
      const response = res.data.data;
      setCountData(response);

      // active members pie chart
      let activeMem = [["Hours per Day", "Total Clients"]];
      response?.lives?.departmentDivision?.map((data: any) => {
        if (data?.department) {
          activeMem.push([data.department, data.count]);
        }
      });
      setActiveMember(activeMem);

      // policy type as % pie chart
      let polType = [["Task", "Hours per Day"]];
      response?.activePolicies?.policyDivision?.map((data: any) => {
        if (data?.name) {
          polType.push([data.name, data.count]);
        }
      });
      setPolicyType(polType);

      // premium pie chart
      let prem: any[] = [];
      response?.totalPremium?.premiumDivision?.map((data: any) => {
        prem.push([data.name, data.total]);
      });
      setPremium(prem);

      // no. of lives graph
      let livesGraph: any[] = [];
      response?.livesGraph?.map((data: any) => {
        livesGraph.push({
          name: moment(data.name).format("DD MMM YY"),
          Dependent: data.DEPENDENT,
          Employee: data.EMPLOYEE,
        });
      });
      setGraphData(livesGraph);
      // no. of lives graph
      let livesGraphCreated: any[] = [];
      response?.livesGraphCreatedAt?.map((data: any) => {
        livesGraphCreated.push({
          name: moment(data.name).format("DD MMM YY"),
          Dependent: data.DEPENDENT,
          Employee: data.EMPLOYEE,
        });
      });
      setGraphDataDateCreated(livesGraphCreated);

      // geo map
      let arrGeo: any = [["State", "Employees"]];
      if (response.clientData[0]?.regionalClientData?.length > 0) {
        response.clientData[0]?.regionalClientData?.map((data: any) => {
          if (data?.count) {
            {
              arrGeo.push([data.state, data.count]);
            }
          }
        });
      }
      setGeoChart(arrGeo);

      // request map endorsment graph
      let endrArr: any[] = [];
      response.endorsementGraph?.map((item: any) => {
        const capitalizedItem: any = {};
        for (const key in item) {
          if (Object.prototype.hasOwnProperty.call(item, key)) {
            const capitalizedKey = key.charAt(0).toUpperCase() + key.slice(1);
            const replacedKey = capitalizedKey.replace(/_/g, ' ');
            capitalizedItem[replacedKey] = item[key];
          }
        }
        endrArr.push({ ...capitalizedItem, Date: moment(item.date).format("DD MMM YY") });
      })
      // Get all keys of endorsment
      const ekeys = Object.keys(endrArr[0]);
      const keysToKeep = ekeys?.filter((key: any) => {
        return endrArr?.some((item: EndrArrItem) => item[key] !== 0);
      });
      const newData = endrArr.map((item: EndrArrItem) => {
        const newItem: EndrArrItem = {};
        keysToKeep.forEach((key: string) => {
          newItem[key] = item[key];
        });
        return newItem;
      });
      setEndorsementChartBarData(newData);


      // request map claims graph
      let claimArr: any[] = [];
      response.claimGraph?.map((item: any) => {
        const capitalizedItem: any = {};
        for (const key in item) {
          if (Object.prototype.hasOwnProperty.call(item, key)) {
            const capitalizedKey = key.charAt(0).toUpperCase() + key.slice(1);
            const replacedKey = capitalizedKey.replace(/_/g, ' '); // Replace underscores with spaces
            capitalizedItem[replacedKey] = item[key];
          }
        }
        claimArr.push({ ...capitalizedItem, Date: moment(item.date).format("DD MMM YY") });
      })
      // Get all keys of claims
      const ckeys = Object.keys(claimArr[0]);
      const keysToKeep2 = ckeys?.filter((key: any) => {
        return claimArr?.some((item: EndrArrItem) => item[key] !== 0);
      });
      const newData2 = claimArr.map((item: EndrArrItem) => {
        const newItem: EndrArrItem = {};
        keysToKeep2.forEach((key: string) => {
          newItem[key] = item[key];
        });
        return newItem;
      });
      setClaimChartBarData(newData2)

    };
    const onError = (err: any) => {
      console.log("..err", err);
      setTimeout(() => {
        setShowLoader(false);
      }, 1500);
    };

    EMPLOYER_DASHBOARD_SERVICES.employerDashboardCount(
      onSuccess,
      onError,
      startEndDate.startDate
        ? moment(startEndDate.startDate).format("YYYY-MM-DD")
        : "",
      startEndDate.endDate
        ? moment(startEndDate.endDate).format("YYYY-MM-DD")
        : "",
      origin.label ?? ""
    );
  };

  const getActivePolicies = () => {
    const onSuccess = (res: any) => {
      const response = res.data.data;
      setPolicies(response);
      let gradSum = "";
      response?.map((item: any) => {
        if (item?.planType?.name === "GRADED") {
          item?.benefitsDetailsGrade?.map((data: any) => {
            if (Array.isArray(data?.sumInsured)) {
              gradSum += data?.sumInsured.join(", ");
            } else if (data?.sumInsured) {
              gradSum += data?.sumInsured;
            }
          });
        }
      });
      setGradedTotalSum(gradSum);
    };
    const onError = (err: any) => {
      console.log("..err", err);
    };

    EMPLOYER_DASHBOARD_SERVICES.employerActivePolicies(onSuccess, onError);
  };

  useEffect(() => {
    get_count();
    getActivePolicies();
    setShowLoader(true);
    get_country_list((cb: any) => {
      setCountryData(cb);
    });
  }, []);

  useEffect(() => {
    get_count();
  }, [startEndDate]);

  useEffect(() => {
    if (origin.label || origin.label != "" || origin.label != "N/A") {
      get_count();
    }
  }, [origin.label]);

  useEffect(() => {
    if (premium?.length > 0) {
      (Highcharts as any)?.chart("premium_policy_pie_chart", {
        chart: {
          type: "pie",
          options3d: {
            enabled: true,
            alpha: 40,
          },
        },
        plotOptions: {
          pie: {
            innerSize: 100,
            depth: 45,
          },
        },
        series: [
          {
            name: "Premium Amount",
            data: premium,
          },
        ],
        tooltip: {
          formatter: function (this: Highcharts.TooltipFormatterContextObject): string {
            return `<b>${formatCurrency(this.point.y)}</b>`;
          },
        },
      });
    }
  }, [premium]);

  const get_country_list = (cb: any) => {
    let data: Array<{ label: string; id: string; origin: string }> = [];
    Get(URL_CONSTANTS.get_country_list)
      ?.then((res: any) => {
        let response = res.data.data;
        for (var i = 0; i < response.length; i++) {
          data.push({
            label: response[i].value,
            id: response[i].label.toString(),
            origin: response[i].origin,
          });
        }
        return cb(data);
      })
      ?.catch((error: any) => {
        console.log(error);
        return [error, {}];
      });
  };

  return (
    <Box>
      <Grid container spacing={3}>
        {showLoader && <NewLoader />}
        <Grid xs={12}>
          <>
            <Grid container>
              <Grid xs={8}>
                <h3 className="mb-0">Dashboard</h3>
              </Grid>
              <Grid xs={4} textAlign={"right"}>
                <UGDateRangePicker
                  value_update={onChangeDate}
                  defaultValue={[currentDate, today]}
                />
              </Grid>
            </Grid>
          </>
        </Grid>
        <Grid xs={8} xl={9} className="pl-0">
          <>
            <Grid container spacing={3}>
              <Grid xs={12} lg={6} xl={4}>
                <>
                  <Grid
                    container
                    alignItems="center"
                    className="totalClients"
                    spacing={0}
                  >
                    <Grid xs={12} md={6} className="pl-4">
                      <h3>{countData?.lives?.totalLivesCount ?? "0"}</h3>
                      <p>Active Members</p>
                    </Grid>
                    <Grid xs={12} md={6}>
                      <span className="charts">
                        {activeMember?.length > 1 && (
                          <Chart
                            chartType="PieChart"
                            data={activeMember}
                            options={{
                              is3D: true,
                              legend: "none",
                            }}
                            width={"150px"}
                            height={"150px"}
                          />
                        )}
                      </span>
                    </Grid>
                  </Grid>
                </>
              </Grid>
              <Grid xs={12} lg={6} xl={4}>
                <>
                  <Grid
                    container
                    alignItems="center"
                    className="activePolicies"
                    spacing={0}
                  >
                    <Grid xs={"auto"} className="pl-4">
                      <h3>{countData?.activePolicies?.totalCount ?? "0"}</h3>
                      <p>Inforced <br /> Policies</p>
                    </Grid>
                    <Grid xs className="pr-4">
                      <ul>
                        {countData?.activePolicies?.policyDivision?.map(
                          (data: any) => (
                            <li className={
                              data.code === "0" ? "term" :
                                data.code === "1" ? "health" :
                                  data.code === "2" ? "trvl" :
                                    data.code === "3" ? "pa" :
                                      data.code === "4" ? "ci" : "stp"}>
                              {data.name} - {data.count}
                            </li>
                          )
                        )}
                      </ul>
                    </Grid>
                  </Grid>
                </>
              </Grid>
              <Grid xs={12} xl={4}>
                <>
                  <Grid container className="noofLives" spacing={0}>
                    <Grid xs={12} className="totalLives">
                      <img src="./images/noof_lives.svg" />
                      <span>
                        <h3 style={{ textAlign: "center" }}>
                          {countData?.lives?.totalLivesCount ?? "0"}
                        </h3>
                        <p>No. of Lives</p>
                      </span>
                    </Grid>
                    <Grid xs={6} className="pl-4">
                      <h5>
                        {countData?.lives?.livesDivision?.filter(
                          (data: any) => data.type === "EMPLOYEE"
                        )[0]?.count ?? 0}
                      </h5>
                      <p>Employees</p>
                    </Grid>
                    <Grid xs={6} className="pr-4" sx={{ textAlign: "end" }}>
                      <h5>
                        {countData?.lives?.livesDivision?.filter(
                          (data: any) => data.type === "DEPENDENT"
                        )[0]?.count ?? 0}
                      </h5>
                      <p>Dependents</p>
                    </Grid>
                  </Grid>
                </>
              </Grid>
              <Grid container spacing={3} className="employerPolicyWrapper">
                {policies?.length > 0 && (
                  <>
                    <Grid xs={12}>
                      <h4 className="mb-0">Total Policies</h4>
                    </Grid>
                    <Grid xs={12} className="policyslider">
                      {policies?.length > 1 ? (
                        <Slider {...settings}>
                          {policies?.map((item, i) => {
                            const emp =
                              item?.memberDetail?.filter(
                                (data: any) => data?._id === "EMPLOYEE"
                              )[0]?.count ?? "0";
                            const dep =
                              item?.memberDetail?.filter(
                                (data: any) => data?._id === "DEPENDENT"
                              )[0]?.count ?? "0";

                            return (
                              <Box padding={"0 12px"} paddingBottom={"50px"}>
                                <div
                                  className={
                                    item?.productType?.name === "Group Health"
                                      ? "cyanPolicyCard"
                                      : "redPolicyCard"
                                  }
                                >
                                  <Grid
                                    container
                                    spacing={3}
                                    className="employerpolicyDetail"
                                  >
                                    <Grid xs={8}>
                                      <span className="active">Active</span>
                                      <h5>{item?.productType?.name}</h5>
                                    </Grid>

                                    <Grid xs={4} className="text-center" display={'flex'} justifyContent={'end'}>
                                      <img
                                        src={item?.insurerDetails?.logo}
                                        alt="img"
                                      />
                                    </Grid>
                                    <Grid xs={12} className="pt-0">
                                      <p className="small">Policy Number</p>
                                      <h6>{item.policyCase.policyNumber}</h6>
                                    </Grid>
                                    <Grid xs={6} xl={4} className="text-center">
                                      <p className="small">Sum Insured</p>
                                      {item?.planType?.name === "FLAT" && (
                                        <h6>
                                          {formatCurrency(
                                            item?.benefitsDetailsflat?.sumInsured
                                          )}
                                        </h6>
                                      )}
                                      {item?.planType?.name === "GRADED" && (
                                        <h6>
                                          <span className="mr-2">
                                            {item?.benefitsDetailsGrade.length > 1
                                              ? `${formatCurrency(
                                                item?.benefitsDetailsGrade[0]
                                                  .sumInsured
                                              )}...`
                                              : formatCurrency(
                                                item?.benefitsDetailsGrade[0]
                                                  .sumInsured
                                              )}
                                          </span>
                                        </h6>
                                      )}
                                    </Grid>
                                    <Grid xs={6} xl={4} className="text-center">
                                      <p className="small">Valid Till</p>
                                      <h6>
                                        {moment(
                                          item?.policyDetails?.endDate
                                        ).format("DD/MM/YYYY")}
                                      </h6>
                                    </Grid>
                                    <Grid xs={6} xl={4} className="text-center">
                                      <p className="small">No. of lives</p>
                                      <h6>{parseInt(emp) + parseInt(dep)}</h6>
                                    </Grid>
                                  </Grid>

                                  <div className="whitepart">
                                    <div className="col">
                                      <p className="small">CD Balance</p>
                                      <h6>
                                        {formatCurrency(
                                          item?.policyDetails?.cdBalance
                                        )}
                                      </h6>
                                    </div>
                                    <div className="col">
                                      <p className="small">Employees</p>
                                      <h6>{emp}</h6>
                                    </div>
                                    <div className="col" style={{ textAlign: 'right' }}>
                                      <p className="small">Dependents</p>
                                      <h6>{dep}</h6>
                                    </div>
                                    <p className="note">
                                      * CD balance last updated on{" "}
                                      {moment(
                                        item?.cdLedger[item?.cdLedger?.length - 1]?.date
                                      ).format("DD/MM/YYYY")}
                                    </p>
                                  </div>
                                </div>
                              </Box>
                            );
                          })}
                        </Slider>
                      ) : (
                        <>
                          {policies?.map((item, i) => {
                            const emp = item?.memberDetail?.filter((data: any) => data?._id === "EMPLOYEE")[0]?.count ?? "0";
                            const dep = item?.memberDetail?.filter((data: any) => data?._id === "DEPENDENT")[0]?.count ?? "0";
                            return (
                              <Box padding={"0 12px"} width={"50%"}>
                                <div className={item?.productType?.name === "Group Health"
                                  ? "cyanPolicyCard" : "redPolicyCard"
                                }
                                >
                                  <Grid
                                    container
                                    spacing={3}
                                    className="employerpolicyDetail"
                                  >
                                    <Grid xs={8}>
                                      <span className="active">Active</span>
                                      <h5>{item?.productType?.name}</h5>
                                    </Grid>

                                    <Grid xs={4} className="text-center" display={'flex'} justifyContent={'end'}>
                                      <img src={item?.insurerDetails?.logo} alt="img" />
                                    </Grid>
                                    <Grid xs={12} className="pt-0">
                                      <p className="small">Policy Number</p>
                                      <h6>{item.policyCase.policyNumber}</h6>
                                    </Grid>
                                    <Grid xs={6} xl={4} className="text-center">
                                      <p className="small">Sum Insured</p>
                                      {item?.planType?.name === "FLAT" && (
                                        <h6>
                                          {formatCurrency(
                                            item?.benefitsDetailsflat?.sumInsured
                                          )}
                                        </h6>
                                      )}
                                      {item?.planType?.name === "GRADED" && (
                                        <h6>
                                          <span className="mr-2">
                                            {item?.benefitsDetailsGrade.length > 1
                                              ? `${formatCurrency(
                                                item?.benefitsDetailsGrade[0]
                                                  .sumInsured
                                              )}...`
                                              : formatCurrency(
                                                item?.benefitsDetailsGrade[0]
                                                  .sumInsured
                                              )}
                                          </span>
                                        </h6>
                                      )}
                                    </Grid>
                                    <Grid xs={6} xl={4} className="text-center">
                                      <p className="small">Valid Till</p>
                                      <h6>
                                        {moment(
                                          item?.policyDetails?.endDate
                                        ).format("DD/MM/YYYY")}
                                      </h6>
                                    </Grid>
                                    <Grid xs={6} xl={4} className="text-center">
                                      <p className="small">No. of lives</p>
                                      <h6>{parseInt(emp) + parseInt(dep)}</h6>
                                    </Grid>
                                  </Grid>

                                  <div className="whitepart">
                                    <div className="col">
                                      <p className="small">CD Balance</p>
                                      <h6>
                                        {formatCurrency(
                                          item?.policyDetails?.cdBalance
                                        )}
                                      </h6>
                                    </div>
                                    <div className="col">
                                      <p className="small">Employees</p>
                                      <h6>{emp}</h6>
                                    </div>
                                    <div className="col" style={{ textAlign: 'right' }}>
                                      <p className="small">Dependents</p>
                                      <h6>{dep}</h6>
                                    </div>
                                    <p className="note">
                                      * CD balance last updated on{" "}
                                      {moment(
                                        item?.cdLedger[item?.cdLedger?.length - 1]?.date
                                        ).format("DD/MM/YYYY")}
                                    </p>
                                  </div>
                                </div>
                              </Box>
                            );
                          })}
                        </>
                      )}
                    </Grid>
                  </>
                )}
                <>
                  <Grid xs={12}>
                    <h4 className="mb-0">No. of Lives</h4>
                  </Grid>
                  <Grid xs={6}>
                    <ToggleButtonGroup
                      className="loginTypeToggle"
                      value={livesTabValue}
                      size="small"
                      exclusive
                      onChange={(e, val: "DOJ" | "DC" | null) => {
                        // if (val != null && val === "DOJ") {
                        //   setlivesTabValue(val);
                        // }
                        if (val != null) {
                          setlivesTabValue(val);
                        }
                      }}
                    >
                      <ToggleButton value="DC">Member's Date Created</ToggleButton>
                      <ToggleButton value="DOJ">Employee's Date of Joining</ToggleButton>
                    </ToggleButtonGroup>
                  </Grid>
                  {livesTabValue === "DOJ" && (
                    <Grid xs={12}>
                      {graphData?.length > 0 ? (
                        <Box sx={{ maxWidth: '1000px' }}>
                          <UGGraph data={graphData} product_type={productType} />
                        </Box>
                      ) : (<div className="no_records"><span>No Records Available</span></div>)}
                    </Grid>
                  )}
                  {livesTabValue === "DC" && (
                    <Grid xs={12}>
                      {graphDataDateCreated?.length > 0 ? (
                        <Box sx={{ maxWidth: '1000px' }}>
                          <UGGraph data={graphDataDateCreated} product_type={productType} />
                        </Box>
                      ) : (<div className="no_records"><span>No Records Available</span></div>)}
                    </Grid>
                  )}
                </>
              </Grid>
              <Grid md={12} xl={6} className="graphLib mt-2">
                <Box className="graphBox">
                  <h4 className="mb-0">Policy Type as %</h4>
                  <div className="policytypeas">
                    {policyType?.length > 1 && (
                      <Chart
                        chartType="PieChart"
                        width="100%"
                        height="400px"
                        data={policyType}
                        options={optionsDonutChart}
                      />
                    )}
                  </div>
                  <div className="total_count">
                    Total Policies -{" "} {countData?.activePolicies?.totalCount ?? "0"}
                  </div>
                </Box>
              </Grid>
              <Grid md={12} xl={6} className="graphLib mt-2">
                <Box className="graphBox">
                  <h4 className="mb-0">Premium Policy </h4>
                  <div className="policytypeas">
                    {premium?.length > 0 && (
                      <div id="premium_policy_pie_chart"></div>
                    )}
                  </div>
                  <div className="total_count">
                    Total Premium {formatCurrency(Number(countData?.totalPremium?.totalCount || 0))}
                  </div>
                </Box>
              </Grid>
              <Grid xs={12}>
                <Box>
                  <Grid container padding={0} alignItems="center">
                    <Grid xs={12} padding={0}>
                      <h4>Employee Office Geographical Representation</h4>
                    </Grid>
                    <Grid xs={6} className="mb-5">
                      <ToggleButtonGroup
                        className="loginTypeToggle"
                        value={mapType}
                        size="small"
                        exclusive
                        onChange={(e, val: "India" | "World" | null) => {
                          if (val != null && val === "India") {
                            setMapType(val);
                            setOrigin({
                              label: "India",
                              value: "IN",
                              origin: "IN",
                            });
                          }
                          if (val != null) {
                            setMapType(val);
                          }
                        }}
                      >
                        <ToggleButton value="India">India</ToggleButton>
                        <ToggleButton value="World">
                          Different Region
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </Grid>
                    {mapType === "World" && (
                      <Grid xs={12} lg={4} xl={3} className="pl-0">
                        <SearchDropdown
                          class_name="inputField"
                          title="Choose Country"
                          value={origin.label}
                          attrName={setOrigin}
                          value_update={(atr: any, val: any) => {
                            setOrigin(val);
                          }}
                          data={countryData}
                          warn_status={false}
                        />
                      </Grid>
                    )}
                  </Grid>
                  {geoChart?.length > 1 ? (
                    <span className="policytypea">
                      <Chart
                        chartType="GeoChart"
                        width="100%"
                        data={geoChart}
                        options={optionsGeoChart}
                      />
                    </span>
                  ) : (<div className="no_records"><span>No Records Available</span></div>)}
                </Box>
              </Grid>
            </Grid>
          </>
          <>
            <Grid container spacing={3} className="mt-15">
              <Grid xs={12}>
                <h4 className="mb-0">Requests</h4>
              </Grid>
              <Grid xs={12}>
                <ToggleButtonGroup
                  className="loginTypeToggle"
                  value={requestType}
                  size="small"
                  exclusive
                  onChange={(e, val: "endoresement" | "claim" | null) => {
                    if (val != null) {
                      setRequestType(val);
                    }
                  }}
                >
                  <ToggleButton value="claim">Claim</ToggleButton>
                  <ToggleButton value="endoresement">Endorsement</ToggleButton>
                </ToggleButtonGroup>
              </Grid>
              <Grid xs={12} className="claim_endorsment_graph">
                {requestType === "claim" && (
                  <>
                    {claimChartBarData?.length > 0 ? (
                      <ResponsiveContainer width="100%" height={400}>
                        <BarChart barSize={15} data={claimChartBarData}>
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="Date" />
                          <YAxis />
                          <Tooltip />
                          <Legend />
                          {Object.keys(claimChartBarData[0])
                            ?.filter((key) => key !== "Date" && key !== "Total")
                            ?.map((data: any, i) => (
                              <Bar
                                dataKey={data}
                                stackId="a"
                                fill={barColorCode[i + 6]}
                              />
                            ))}
                        </BarChart>
                      </ResponsiveContainer>
                    ) : (
                      <div className="no_records"><span>No Records Available</span></div>
                    )}
                  </>
                )}
                {requestType === "endoresement" && (
                  <>
                    {endorsementChartBarData?.length > 0 ? (
                      <ResponsiveContainer width="100%" height={400}>
                        <BarChart barSize={15} data={endorsementChartBarData}>
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="Date" />
                          <YAxis />
                          <Tooltip />
                          <Legend />
                          {Object.keys(endorsementChartBarData[0])
                            ?.filter((key) => key !== "Date" && key !== "Total")
                            ?.map((data: any, i) => (
                              <Bar
                                dataKey={data}
                                stackId="a"
                                fill={barColorCode[i + 6]}
                              />
                            ))}
                        </BarChart>
                      </ResponsiveContainer>
                    ) : (
                      <div className="no_records"><span>No Records Available</span></div>
                    )}
                  </>
                )}
              </Grid>
            </Grid>
          </>
        </Grid>
        <Grid xs={4} xl={3}>
          <Grid container spacing={3} width={"100%"}>
            <Grid xs={12} className="w-100">
              <div className="alertMessage">

                <h5><InfoIcon /> Critical CD Balance Alerts</h5>
                {countData?.criticalBalance?.length === 0 ? (
                  // <div style={{ width: '88px' }}>
                  //   <span>No Alerts</span>
                  //   </div>
                  <span>No Alerts</span>
                ) : (
                  <ul>
                    {countData?.criticalBalance?.length > 0 &&
                      countData?.criticalBalance?.map((item: any) => {
                        return (
                          <li key={item.title}>
                            <span style={{ fontWeight: 600 }}>{item?.title} -</span> {" "}<span style={{ display: "inline-block" }}>{formatCurrency(Number(item?.cdBalance))}</span>
                          </li>
                        );
                      })}
                  </ul>
                )}
                {/* {criticalCdAlerts.length === 0 ? (
                <span>No Alerts</span>
              ) : (
                <ul>
                  {criticalCdAlerts.map((item) => {
                    return (
                      <li>
                        {item.policyNumber} -{" "}
                        {formatCurrency(Number(item.currentCdBalance))}
                      </li>
                    );
                  })}
                </ul>
              )} */}
              </div>
            </Grid>
            <Grid xs={12} className="w-100 todolist mb-0">
              <h4>To Do</h4>
              <Link
                underline="none"
                className="addpolicy"
                onClick={() => navigate(EMPLOYER_ALL_ROUTES.EMPLOYEE)}
              >
                Upload members
              </Link>
              {/* <Link underline="none" className="riskscore">
                Complete company profile to share policies
              </Link> */}
              {/* <Link underline="none" className="familyprofile" onClick={() => navigate(`${EMPLOYER_ALL_ROUTES.SETTING_MODULE_FORM}?page=accessControl&section=userControl`)}>
              Review Access Controls
            </Link> */}
            </Grid>
            {/* <Grid xs={12}>
            <Box className="policyRenewal">
              <div className="thisMonth">This Month</div>
              <h3>486</h3>
              <p>Policies for Renewal</p>
              <ul>
                {policyRenewal.map((data, index) => (
                  <li className={data.class_name}>
                    <span>{data.value}</span> {data.title}
                  </li>
                ))}
              </ul>
            </Box>
          </Grid> */}
            <Grid xs={12} className="w-100">
              <Box className="alertSummary">
                <h3>Alert Summary</h3>
                <p>Total pendings of claim & endorsement.</p>
                <ul>
                  {alertSummary.map((data, index) => (
                    <li key={index}>
                      <div className={data.class_name}></div>
                      <p>
                        {data.title} <span>{data.value}</span>
                      </p>
                    </li>
                  ))}
                </ul>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box >
  );
};

export default Dashboard;
