import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Link,
  Typography,
} from "@mui/material";

import SlidingPanel from "react-sliding-side-panel";
import CheckBox from "../../../Supporting files/CheckBox/CheckBox";
import RKTextField from "../../../Supporting files/RKTextField/RKTextField";
import SelectDropdown from "../../../Supporting files/SelectDropdown/SelectDropdown";
import "../SelectVoluntryPlans/SelectVoluntryPlans.scss";

interface Props {
  open_status: boolean;
  attrName: any;
  value_update: Function;
}

const SelectvoluntryPlans: React.FC<Props> = ({
  open_status,
  attrName,
  value_update,
}) => {
  const [expanded, setExpanded] = React.useState<string | false>("panel1");
  const [coverage, setCoverage] = React.useState("");
  const [paymentFrequency, setPaymentFrequency] = React.useState("");
  const [unit, setUnit] = React.useState("");
  const [value, setValue] = React.useState("");
  const [coverageOne, setCoverageOne] = React.useState("");
  const [paymentFrequencyOne, setPaymentFrequencyOne] = React.useState("");
  const [unitOne, setUnitOne] = React.useState("");
  const [valueOne, setValueOne] = React.useState("");
  const [coverageTwo, setCoverageTwo] = React.useState("");
  const [paymentFrequencyTwo, setPaymentFrequencyTwo] = React.useState("");
  const [unitTwo, setUnitTwo] = React.useState("");
  const [valueTwo, setValueTwo] = React.useState("");
  const [addQuoteStep, setAddQuoteStep] = React.useState<
    Array<{ insurer: string; premium: string; deleteable: boolean }>
  >([{ insurer: "", premium: "", deleteable: false }]);

  const updateMasterState = (attrName: any, value: any) => {
    if (attrName[0] === "checkboxStatus") {
      setCheckboxStatus({ ...checkboxStatus, [attrName[1]]: value });
    }
    attrName(value);
  };

  var indents = [];
  for (var i = 0; i < addQuoteStep.length; i++) {
    indents.push(<span className="indent" key={i}></span>);
  }

  const [addFeature, setAddFeature] = useState<
    Array<{
      title: string;
      title_class: string;
      border_class: string;
      data: Array<{
        id: number;
        checkbox_status: boolean;
        title: string;
        description: string;
        value: string;
        fieldType: string;
      }>;
    }>
  >([
    {
      title: "Medical Cover",
      title_class: "blue",
      border_class: "blue",
      data: [
        {
          id: 0,
          checkbox_status: false,
          title: "Pre-existing Diseases",
          description:
            "Coverage for any pre-existing disease is provided to you after a certain waiting period. ",
          value: "Test",
          fieldType: "textInput",
        },
        {
          id: 1,
          checkbox_status: false,
          title: "Ayush/Alternative Treatment Cover",
          description:
            "Any health insurance with the inclusion of AYUSH treatment covers all the alternate medical sciences mentioned in the policy",
          value: "50000",
          fieldType: "dropDown",
        },
        {
          id: 2,
          checkbox_status: false,
          title: "Pre & Post Hospitalisation Expenses",
          description:
            "Coverage against medicinal expenses incurred,including pre and post hospitalization ",
          value: "Test",
          fieldType: "textInput",
        },
        {
          id: 3,
          checkbox_status: false,
          title: "Room Rent",
          description:
            "Room rent limit is one of the most common coverage limitations that you can find under medical insurance plans",
          value: "Test",
          fieldType: "textInput",
        },
        {
          id: 4,
          checkbox_status: false,
          title: "Room Rent",
          description:
            "Room rent limit is one of the most common coverage limitations that you can find under medical insurance plans",
          value: "Test",
          fieldType: "textInput",
        },
      ],
    },
    {
      title: "Emergency Cover",
      title_class: "yellow",
      border_class: "yellow",
      data: [
        {
          id: 0,
          checkbox_status: false,
          title: "Worldwide Emergency Cover",
          description:
            "provides coverage for medical expenses that have been incurred abroad.",
          value: "Test",
          fieldType: "textInput",
        },
        {
          id: 1,
          checkbox_status: false,
          title: "Ambulance Cover",
          description: "Up to Rs. 5,000 (dependent on sum insured)",
          value: "50000",
          fieldType: "dropDown",
        },
        {
          id: 2,
          checkbox_status: false,
          title: "Worldwide Emergency Cover",
          description:
            "provides coverage for medical expenses that have been incurred abroad.",
          value: "Test",
          fieldType: "textInput",
        },
        {
          id: 3,
          checkbox_status: false,
          title: "Ambulance Cover",
          description: "Up to Rs. 5,000 (dependent on sum insured)",
          value: "50000",
          fieldType: "dropDown",
        },
        {
          id: 4,
          checkbox_status: false,
          title: "Worldwide Emergency Cover",
          description:
            "provides coverage for medical expenses that have been incurred abroad.",
          value: "Test",
          fieldType: "textInput",
        },
        {
          id: 5,
          checkbox_status: false,
          title: "Ambulance Cover",
          description: "Up to Rs. 5,000 (dependent on sum insured)",
          value: "50000",
          fieldType: "dropDown",
        },
      ],
    },
    {
      title: "Hospitalization Cover",
      title_class: "red",
      border_class: "red",
      data: [
        {
          id: 0,
          checkbox_status: false,
          title: "Worldwide Emergency Cover",
          description:
            "provides coverage for medical expenses that have been incurred abroad.",
          value: "Test",
          fieldType: "textInput",
        },
        {
          id: 1,
          checkbox_status: false,
          title: "Ambulance Cover",
          description: "Up to Rs. 5,000 (dependent on sum insured)",
          value: "Test",
          fieldType: "textInput",
        },
        {
          id: 2,
          checkbox_status: false,
          title: "Worldwide Emergency Cover",
          description:
            "provides coverage for medical expenses that have been incurred abroad.",
          value: "Test",
          fieldType: "textInput",
        },
        {
          id: 3,
          checkbox_status: false,
          title: "Ambulance Cover",
          description: "Up to Rs. 5,000 (dependent on sum insured)",
          value: "Test",
          fieldType: "textInput",
        },
        {
          id: 4,
          checkbox_status: false,
          title: "Worldwide Emergency Cover",
          description:
            "provides coverage for medical expenses that have been incurred abroad.",
          value: "Test",
          fieldType: "textInput",
        },
        {
          id: 5,
          checkbox_status: false,
          title: "Ambulance Cover",
          description: "Up to Rs. 5,000 (dependent on sum insured)",
          value: "Test",
          fieldType: "textInput",
        },
      ],
    },
    {
      title: "Maternity Cover",
      title_class: "green",
      border_class: "green",
      data: [
        {
          id: 0,
          checkbox_status: false,
          title: "New Born Child Cover",
          description:
            "Not all health insurance plans cover a newborn from day.",
          value: "Test",
          fieldType: "textInput",
        },
        {
          id: 1,
          checkbox_status: false,
          title: "Ambulance Cover",
          description: "Up to Rs. 5,000 (dependent on sum insured)",
          value: "Test",
          fieldType: "textInput",
        },
        {
          id: 2,
          checkbox_status: false,
          title: "New Born Child Cover",
          description:
            "Not all health insurance plans cover a newborn from day.",
          value: "Test",
          fieldType: "textInput",
        },
        {
          id: 3,
          checkbox_status: false,
          title: "Ambulance Cover",
          description: "Up to Rs. 5,000 (dependent on sum insured)",
          value: "Test",
          fieldType: "textInput",
        },
        {
          id: 4,
          checkbox_status: false,
          title: "Ambulance Cover",
          description: "Up to Rs. 5,000 (dependent on sum insured)",
          value: "Test",
          fieldType: "textInput",
        },
      ],
    },
  ]);
  const coverage_data = [
    { key: "Employees + Dependents", value: "Employees + Dependents" },
    { key: "Employees + Dependents", value: "Employees + Dependents" },
    { key: "Employees + Dependents", value: "Employees + Dependents" },
  ];
  const paymentFrequency_data = [
    { key: "Monthly", value: "Monthly" },
    { key: "Quarterly", value: "Quarterly" },
    { key: "Yearly", value: "Yearly" },
  ];
  const unit_data = [
    { key: "Currency", value: "Currency" },
    { key: "Currency", value: "Currency" },
    { key: "Currency", value: "Currency" },
  ];
  const value_data = [
    { key: "₹ 400", value: "₹ 400" },
    { key: "₹ 500", value: "₹ 500" },
    { key: "₹ 600", value: "₹ 600" },
  ];
  const coverageOne_data = [
    { key: "Employees + Dependents", value: "Employees + Dependents" },
    { key: "Employees + Dependents", value: "Employees + Dependents" },
    { key: "Employees + Dependents", value: "Employees + Dependents" },
  ];
  const paymentFrequencyOne_data = [
    { key: "Monthly", value: "Monthly" },
    { key: "Quarterly", value: "Quarterly" },
    { key: "Yearly", value: "Yearly" },
  ];
  const unitOne_data = [
    { key: "Currency", value: "Currency" },
    { key: "Currency", value: "Currency" },
    { key: "Currency", value: "Currency" },
  ];
  const valueOne_data = [
    { key: "₹ 400", value: "₹ 400" },
    { key: "₹ 500", value: "₹ 500" },
    { key: "₹ 600", value: "₹ 600" },
  ];
  const coverageTwo_data = [
    { key: "Employees + Dependents", value: "Employees + Dependents" },
    { key: "Employees + Dependents", value: "Employees + Dependents" },
    { key: "Employees + Dependents", value: "Employees + Dependents" },
  ];
  const paymentFrequencyTwo_data = [
    { key: "Monthly", value: "Monthly" },
    { key: "Quarterly", value: "Quarterly" },
    { key: "Yearly", value: "Yearly" },
  ];
  const unitTwo_data = [
    { key: "Currency", value: "Currency" },
    { key: "Currency", value: "Currency" },
    { key: "Currency", value: "Currency" },
  ];
  const valueTwo_data = [
    { key: "₹ 400", value: "₹ 400" },
    { key: "₹ 500", value: "₹ 500" },
    { key: "₹ 600", value: "₹ 600" },
  ];

  const dropdown_data = [
    { key: "50000", value: "Rs. 50 Lakhs" },
    { key: "60000", value: "Rs. 60 Lakhs" },
    { key: "70000", value: "Rs. 70 Lakhs" },
    { key: "80000", value: "Rs. 80 Lakhs" },
    { key: "90000", value: "Rs. 90 Lakhs" },
    { key: "100000", value: "Rs. 1 Crore" },
  ];
  const [checkboxStatus, setCheckboxStatus] = useState<{
    one: boolean;
    two: boolean;
    three: boolean;
  }>({ one: true, two: false, three: false });

  const updateMasterStateArray = (attrName: any, value: any) => {
    const array_data = [...addFeature];

    if (attrName[2] === "value") {
    } else if (attrName[2] === "checkbox_status") {
      array_data[attrName[0]].data[attrName[1]].checkbox_status = value;
    }

    setAddFeature(array_data);
  };
  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  return (
    <SlidingPanel
      type={"right"}
      isOpen={open_status}
      size={75}
      panelClassName="slidingPanel"
      backdropClicked={() => value_update(attrName, false)}
    >
      <div className="select_feature">
        <Grid container spacing={3} alignItems="center">
          <Grid xs={12} className="pt-0">
            <h4 className="mb-3 mt-3">Voluntry Plans</h4>
            <hr />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={3}
          alignItems="center"
          sx={{ paddingTop: "0" }}
        >
          <Grid xs={12} className="px-0">
            <div className="scrollable_area">
              <div className="voluntry_accordion">
                <Accordion
                  className="accordion_section"
                  expanded={expanded === "panel1"}
                  onChange={handleChange("panel1")}
                >
                  <AccordionSummary
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <CheckBox
                      title={""}
                      name={"1"}
                      id={"1"}
                      value={checkboxStatus.one}
                      attrName={["checkboxStatus", "one"]}
                      value_update={updateMasterState}
                      default_check={false}
                    />
                    <p
                      className="title"
                      onClick={() => {
                        updateMasterState(
                          ["checkboxStatus", "one"],
                          !checkboxStatus.one
                        );
                      }}
                    >
                      Group Personal Accident
                      <span>
                        Premium for this plan will be paid by the employee only.
                        This plan provides comprehensive coverage upto 100% of
                        Sum Insured against Accidental Death, Permanent Total
                        Disability (PTD), Permanent Partial Disability (PPD) and
                        upto 1% of Actual Salary incase of Temporary Total
                        Disability (TTD).
                      </span>
                    </p>
                  </AccordionSummary>
                  <AccordionDetails>
                    <>
                      <Grid container spacing={3}>
                        <Grid xs={6}>
                          <SelectDropdown
                            class_name="inputField"
                            title="Coverage"
                            value={coverage}
                            attrName={setCoverage}
                            value_update={updateMasterState}
                            dropdown_data={coverage_data}
                            warn_status={false}
                          />
                        </Grid>
                        <Grid xs={6}>
                          <SelectDropdown
                            class_name="inputField"
                            title="Payment Frequency"
                            value={paymentFrequency}
                            attrName={setPaymentFrequency}
                            value_update={updateMasterState}
                            dropdown_data={paymentFrequency_data}
                            warn_status={false}
                          />
                        </Grid>
                        <Grid xs={6}>
                          <SelectDropdown
                            class_name="inputField"
                            title="Unit"
                            value={unit}
                            attrName={setUnit}
                            value_update={updateMasterState}
                            dropdown_data={unit_data}
                            warn_status={false}
                          />
                        </Grid>
                        <Grid xs={6}>
                          <SelectDropdown
                            class_name="inputField"
                            title="Value"
                            value={value}
                            attrName={setValue}
                            value_update={updateMasterState}
                            dropdown_data={value_data}
                            warn_status={false}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid xs={12}>
                          {addFeature.map((data, index) => (
                            <div
                              className={
                                "coverage_section  mr-3 mb-6 " +
                                data.border_class
                              }
                            >
                              <Grid
                                container
                                spacing={3}
                                style={{ alignItems: "center" }}
                              >
                                <Grid
                                  xs={12}
                                  className={
                                    "coverage_heading " + data.title_class
                                  }
                                >
                                  <h5>{data.title}</h5>
                                </Grid>
                                <div className="mini_scroll">
                                  {data.data.map((value, indexx) => (
                                    <>
                                      <Grid container spacing={3}>
                                        <Grid
                                          xs={8}
                                          className="checkbox_feature"
                                        >
                                          <CheckBox
                                            title={
                                              <p>
                                                {value.title}
                                                <span>{value.description}</span>
                                              </p>
                                            }
                                            name={
                                              index.toString() +
                                              indexx.toString()
                                            }
                                            id={
                                              index.toString() +
                                              indexx.toString()
                                            }
                                            value={value.checkbox_status}
                                            attrName={[
                                              index,
                                              indexx,
                                              "checkbox_status",
                                            ]}
                                            value_update={
                                              updateMasterStateArray
                                            }
                                            default_check={false}
                                          />
                                        </Grid>
                                        <Grid xs={4}>
                                          {value.fieldType === "textInput" ? (
                                            <RKTextField
                                              class_name="inputField"
                                              title="Value"
                                              value={value.value}
                                              attrName={[
                                                index,
                                                indexx,
                                                "value",
                                              ]}
                                              value_update={
                                                updateMasterStateArray
                                              }
                                              warn_status={false}
                                            />
                                          ) : (
                                            <SelectDropdown
                                              class_name="inputField"
                                              title="Policy"
                                              value={value.value}
                                              attrName={[
                                                index,
                                                indexx,
                                                "value",
                                              ]}
                                              value_update={
                                                updateMasterStateArray
                                              }
                                              dropdown_data={dropdown_data}
                                              warn_status={false}
                                            />
                                          )}
                                        </Grid>
                                        <Grid
                                          xs={12}
                                          className="mb-0 pt-0 pb-0"
                                        >
                                          {indexx + 1 ===
                                          data.data.length ? null : (
                                            <hr />
                                          )}
                                        </Grid>
                                      </Grid>
                                    </>
                                  ))}
                                </div>
                              </Grid>
                            </div>
                          ))}
                        </Grid>
                      </Grid>
                    </>
                  </AccordionDetails>
                </Accordion>
                <Accordion className="accordion_section">
                  <AccordionSummary
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <CheckBox
                      title={""}
                      name={"2"}
                      id={"2"}
                      value={checkboxStatus.two}
                      attrName={["checkboxStatus", "two"]}
                      value_update={updateMasterState}
                      default_check={false}
                    />
                    <p
                      className="title"
                      onClick={() => {
                        updateMasterState(
                          ["checkboxStatus", "two"],
                          !checkboxStatus.two
                        );
                      }}
                    >
                      Group Critical Illness
                      <span>
                        Premium for this plan will be paid by the employee only.
                        This plan provides comprehensive coverage upto 100% of
                        Sum Insured against Accidental Death, Permanent Total
                        Disability (PTD), Permanent Partial Disability (PPD) and
                        upto 1% of Actual Salary incase of Temporary Total
                        Disability (TTD).
                      </span>
                    </p>
                  </AccordionSummary>
                  <AccordionDetails>
                    <>
                      <Grid container spacing={3}>
                        <Grid xs={6}>
                          <SelectDropdown
                            class_name="inputField"
                            title="Coverage"
                            value={coverageOne}
                            attrName={setCoverageOne}
                            value_update={updateMasterState}
                            dropdown_data={coverageOne_data}
                            warn_status={false}
                          />
                        </Grid>
                        <Grid xs={6}>
                          <SelectDropdown
                            class_name="inputField"
                            title="Payment Frequency"
                            value={paymentFrequencyOne}
                            attrName={setPaymentFrequencyOne}
                            value_update={updateMasterState}
                            dropdown_data={paymentFrequencyOne_data}
                            warn_status={false}
                          />
                        </Grid>
                        <Grid xs={6}>
                          <SelectDropdown
                            class_name="inputField"
                            title="Unit"
                            value={unitOne}
                            attrName={setUnitOne}
                            value_update={updateMasterState}
                            dropdown_data={unitOne_data}
                            warn_status={false}
                          />
                        </Grid>
                        <Grid xs={6}>
                          <SelectDropdown
                            class_name="inputField"
                            title="Value"
                            value={valueOne}
                            attrName={setValueOne}
                            value_update={updateMasterState}
                            dropdown_data={valueOne_data}
                            warn_status={false}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid xs={12}>
                          {addFeature.map((data, index) => (
                            <div
                              className={
                                "coverage_section  mr-3 mb-6 " +
                                data.border_class
                              }
                            >
                              <Grid
                                container
                                spacing={3}
                                style={{ alignItems: "center" }}
                              >
                                <Grid
                                  xs={12}
                                  className={
                                    "coverage_heading " + data.title_class
                                  }
                                >
                                  <h5>{data.title}</h5>
                                </Grid>

                                {data.data.map((value, indexx) => (
                                  <>
                                    <Grid xs={8} className="checkbox_feature">
                                      <CheckBox
                                        title={
                                          <p>
                                            {value.title}
                                            <span>{value.description}</span>
                                          </p>
                                        }
                                        name={
                                          index.toString() + indexx.toString()
                                        }
                                        id={
                                          index.toString() + indexx.toString()
                                        }
                                        value={value.checkbox_status}
                                        attrName={[
                                          index,
                                          indexx,
                                          "checkbox_status",
                                        ]}
                                        value_update={updateMasterStateArray}
                                        default_check={false}
                                      />
                                    </Grid>
                                    <Grid xs={4}>
                                      {value.fieldType === "textInput" ? (
                                        <RKTextField
                                          class_name="inputField"
                                          title="Value"
                                          value={value.value}
                                          attrName={[index, indexx, "value"]}
                                          value_update={updateMasterStateArray}
                                          warn_status={false}
                                        />
                                      ) : (
                                        <SelectDropdown
                                          class_name="inputField"
                                          title="Policy"
                                          value={value.value}
                                          attrName={[index, indexx, "value"]}
                                          value_update={updateMasterStateArray}
                                          dropdown_data={dropdown_data}
                                          warn_status={false}
                                        />
                                      )}
                                    </Grid>
                                    <Grid xs={12} className="mb-0 pt-0 pb-0">
                                      {indexx + 1 ===
                                      data.data.length ? null : (
                                        <hr />
                                      )}
                                    </Grid>
                                  </>
                                ))}
                              </Grid>
                            </div>
                          ))}
                        </Grid>
                      </Grid>
                    </>
                  </AccordionDetails>
                </Accordion>
                <Accordion className="accordion_section">
                  <AccordionSummary
                    aria-controls="panel3a-content"
                    id="panel3a-header"
                  >
                    <CheckBox
                      title={""}
                      name={"3"}
                      id={"3"}
                      value={checkboxStatus.three}
                      attrName={["checkboxStatus", "three"]}
                      value_update={updateMasterState}
                      default_check={false}
                    />
                    <p
                      className="title"
                      onClick={() => {
                        updateMasterState(
                          ["checkboxStatus", "three"],
                          !checkboxStatus.three
                        );
                      }}
                    >
                      Group Personal Accident
                      <span>
                        Premium for this plan will be paid by the employee only.
                        This plan provides comprehensive coverage upto 100% of
                        Sum Insured against Accidental Death, Permanent Total
                        Disability (PTD), Permanent Partial Disability (PPD) and
                        upto 1% of Actual Salary incase of Temporary Total
                        Disability (TTD).
                      </span>
                    </p>
                  </AccordionSummary>
                  <AccordionDetails>
                    <>
                      <Grid container spacing={3}>
                        <Grid xs={6}>
                          <SelectDropdown
                            class_name="inputField"
                            title="Coverage"
                            value={coverageTwo}
                            attrName={setCoverageTwo}
                            value_update={updateMasterState}
                            dropdown_data={coverageTwo_data}
                            warn_status={false}
                          />
                        </Grid>
                        <Grid xs={6}>
                          <SelectDropdown
                            class_name="inputField"
                            title="Payment Frequency"
                            value={paymentFrequencyTwo}
                            attrName={setPaymentFrequencyTwo}
                            value_update={updateMasterState}
                            dropdown_data={paymentFrequencyTwo_data}
                            warn_status={false}
                          />
                        </Grid>
                        <Grid xs={6}>
                          <SelectDropdown
                            class_name="inputField"
                            title="Unit"
                            value={unitTwo}
                            attrName={setUnitTwo}
                            value_update={updateMasterState}
                            dropdown_data={unitTwo_data}
                            warn_status={false}
                          />
                        </Grid>
                        <Grid xs={6}>
                          <SelectDropdown
                            class_name="inputField"
                            title="Value"
                            value={valueTwo}
                            attrName={setValueTwo}
                            value_update={updateMasterState}
                            dropdown_data={valueTwo_data}
                            warn_status={false}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid xs={12}>
                          {addFeature.map((data, index) => (
                            <div
                              className={
                                "coverage_section  mr-3 mb-6 " +
                                data.border_class
                              }
                            >
                              <Grid
                                container
                                spacing={3}
                                style={{ alignItems: "center" }}
                              >
                                <Grid
                                  xs={12}
                                  className={
                                    "coverage_heading " + data.title_class
                                  }
                                >
                                  <h5>{data.title}</h5>
                                </Grid>

                                {data.data.map((value, indexx) => (
                                  <>
                                    <Grid xs={8} className="checkbox_feature">
                                      <CheckBox
                                        title={
                                          <p>
                                            {value.title}
                                            <span>{value.description}</span>
                                          </p>
                                        }
                                        name={
                                          index.toString() + indexx.toString()
                                        }
                                        id={
                                          index.toString() + indexx.toString()
                                        }
                                        value={value.checkbox_status}
                                        attrName={[
                                          index,
                                          indexx,
                                          "checkbox_status",
                                        ]}
                                        value_update={updateMasterStateArray}
                                        default_check={false}
                                      />
                                    </Grid>
                                    <Grid xs={4}>
                                      {value.fieldType === "textInput" ? (
                                        <RKTextField
                                          class_name="inputField"
                                          title="Value"
                                          value={value.value}
                                          attrName={[index, indexx, "value"]}
                                          value_update={updateMasterStateArray}
                                          warn_status={false}
                                        />
                                      ) : (
                                        <SelectDropdown
                                          class_name="inputField"
                                          title="Policy"
                                          value={value.value}
                                          attrName={[index, indexx, "value"]}
                                          value_update={updateMasterStateArray}
                                          dropdown_data={dropdown_data}
                                          warn_status={false}
                                        />
                                      )}
                                    </Grid>
                                    <Grid xs={12} className="mb-0 pt-0 pb-0">
                                      {indexx + 1 ===
                                      data.data.length ? null : (
                                        <hr />
                                      )}
                                    </Grid>
                                  </>
                                ))}
                              </Grid>
                            </div>
                          ))}
                        </Grid>
                      </Grid>
                    </>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>
          </Grid>

          <Grid xs={12} className="footer_sec">
            <Link className="save_btn">Submit</Link>
          </Grid>
        </Grid>
      </div>
    </SlidingPanel>
  );
};

export default SelectvoluntryPlans;
