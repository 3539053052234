import { Button, TextareaAutosize } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { GridCloseIcon } from "@mui/x-data-grid";
import React, { useState } from "react";
import SlidingPanel from "react-sliding-side-panel";

interface Props {
  open_status: boolean;
  attrName: any;
  value_update: Function;
  type?: string | undefined;
  data?: Function;
  api_url?: string;
}

const PostQueryForm: React.FC<Props> = ({
  open_status,
  attrName,
  value_update,
  type,
  data,
  api_url,
}) => {
  const [companyphonenumber, setCompanyPhoneNumber] = useState("");
  const [pageStatus, setPageStatus] = useState<number>(1);
  const [description, setDescription] = useState<string>("");
  let [error, setError] = useState("");
  const ErrorFunction = (description: string) => {
    let error: string = "";
    if (!description) {
      error = "Enter the description";
    }
    return error;
  };
  const updateMasterState = (
    attrName: any,
    value: string | boolean | number
  ) => {
    attrName(value);
  };
  
  return (
    <SlidingPanel type={"right"} isOpen={open_status} size={75}>
      <div>
        {pageStatus !== 2 ? (
          <Grid container spacing={3} alignItems="center">
            <Grid xs={10}>
              <h4 className="mb-0 mt-3">What's on your mind</h4>
            </Grid>
            <Grid xs={2} textAlign="right" className="mt-4">
              <GridCloseIcon
                onClick={() => {
                  value_update(attrName, false);
                  attrName(false);
                  setDescription("");
                  setError("");
                }}
              />
            </Grid>
            <Grid xs={12}>
              <hr />
            </Grid>
          </Grid>
        ) : null}
        <div className="scrollable_area mt-6">
          {pageStatus !== 2 ? (
            <Grid
              container
              spacing={2}
              justifyContent="center"
              className="mb-3"
            >
              <Grid xs={12} className="textAreaSection">
                <TextareaAutosize
                  value={description}
                  onChange={(e) => {
                    setDescription(e.target.value);
                    let data: any = error;
                    if (e.target.value.trim().trimStart().length === 0) {
                      data = "Enter the description";
                    } else {
                      data = "";
                    }
                    setError(data);
                  }}
                  className="text_area"
                  aria-label="empty textarea"
                  placeholder="Description"
                />
                <span
                  className="textAreaerror"
                  style={{
                    color: "red",
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  {error}
                </span>
              </Grid>

              {/* <Grid xs={12}>
                <RKTextField
                  class_name="inputField"
                  title={"Write your question here"}
                  value={companyphonenumber}
                  attrName={setCompanyPhoneNumber}
                  value_update={updateMasterState}
                  warn_status={false}
                />
              </Grid> */}
            </Grid>
          ) : null}
          {pageStatus === 2 ? (
            <Grid
              container
              spacing={2}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <Grid xs={12}>
                <svg
                  className="checkmark"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 52 52"
                >
                  <circle
                    className="checkmark__circle"
                    cx="26"
                    cy="26"
                    r="25"
                    fill="none"
                  />
                  <path
                    className="checkmark__check"
                    fill="none"
                    d="M14.1 27.2l7.1 7.2 16.7-16.8"
                  />
                </svg>
                <p className="quote_success text-center">
                  Your Query has been added successfully!
                </p>
              </Grid>
            </Grid>
          ) : null}
        </div>
        {pageStatus !== 2 ? (
          <Grid container spacing={3}>
            <Grid xs={12} className="ctaBtn mt-4">
              <Button
                variant="contained"
                className="submitBtn fixedbtn"
                onClick={() => {
                  setError(ErrorFunction(description));
                  if (description?.trim() === "") {
                    setError("Enter the description")
                    return false;
                  } else {
                    value_update(attrName, false);
                    value_update("notes_new_data", description);
                    setDescription("");
                  }
                }}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        ) : null}
      </div>
    </SlidingPanel>
  );
};

export default PostQueryForm;
