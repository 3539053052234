import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Box, Button, Link, Modal } from "@mui/material";
import SelectDropdown from "../../../../../Supporting files/SelectDropdown/SelectDropdown";
import RKTextField from "../../../../../Supporting files/RKTextField/RKTextField";
import CheckBox from "../../../../../Supporting files/CheckBox/CheckBox";
import SlidingPanel from "react-sliding-side-panel";
import "./../CustomModules.scss"
import { useSelector, useDispatch } from "react-redux";
import { ToggelModalAction } from "../../../../../Store/actions/modalAction";
import { UpdateLayoutPropertiesAction } from "../../../../../Store/actions/layoutAction";
import { checkErrors } from "../CustomModuleV1/helpers";
import { ROW, COLUMN } from "../CustomModuleV1/constants";
import shortid from "shortid";

const EditSubSectionName = ({open_status}) => {
    const modal = useSelector((state)=> {return state.modalReducer});
    const layoutData = useSelector(({layoutReducer})=>{return layoutReducer});

    const [title, setTitle] = React.useState(modal.data.subectionName);
    const [characterLimit, setCharacterLimit] = React.useState(modal.data.characterLimit?modal.data.characterLimit:'');

    const [isError, setError] = React.useState(false);
    const [isSuccess, setSuccess] = React.useState(false);

    const updateMasterState = (attrName, value) => {
        attrName(value);
    };
    const dispatch = useDispatch();
    const submitHandler = ()=>{
        let path = modal.path.split("-");
        let isError = checkErrors(title);

        layoutData.layout[path[0]].children[path[1]].name = title

        if(!isError){
            dispatch(UpdateLayoutPropertiesAction(layoutData.layout,{})); 
            setError(false);   
            setSuccess(true); 
            dispatch(ToggelModalAction(modal.isOpen, "", null))
        }else{
            setError(true); 
            setSuccess(false);
        }     
    }

    useEffect(() => {
        if(isError || isSuccess){
            setTimeout(()=>{
                setError(false);
                setSuccess(false);
            }, 2000)
        }
    }, [isError, isSuccess])

    const checkErrorOnBackdrop = ()=>{
        let isError = checkErrors(title);
        if(!isError){
            //dispatch(UpdateLayoutPropertiesAction(layoutData.layout,objectToUpdate)); 
            //dispatch(ToggelModalAction(modal.isOpen, "", null))
          setError(false);  
        }else{
          setError(true);  
        }
    }

    return (
        <SlidingPanel
            type={"right"}
            isOpen={open_status}
            size={75}
            backdropClicked={checkErrorOnBackdrop}>
            <div>
                <Grid container spacing={3} alignItems="center">
                    <Grid xs={12} className="pt-3">
                        <h4 className="mb-0">Edit Sub-Section Name</h4>
                    </Grid>

                    <Grid xs={12} className="mb-4">
                        <hr />
                    </Grid>
                </Grid> 
                <div className="scrollable_area pt-3">
                    <Grid container spacing={3}>
                        <Grid xs={12}>
                            <RKTextField
                                class_name="inputField"
                                title='Sub Section Name'
                                value={title}
                                attrName={setTitle}
                                value_update={updateMasterState}
                                error_messg = "Sub Section Name is Required!"
                                warn_status={isError}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={3} >
                    <Grid xs={12} className="ctaBtn mt-4 text-center">
                        <Button
                            variant="contained"
                            className="submitBtn "
                            onClick={submitHandler}>
                            Done
                        </Button>
                    </Grid>
                </Grid>
                </div>
            </div>
        </SlidingPanel>
    );
};

export default EditSubSectionName;
