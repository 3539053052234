import { EMPLOYER_URL_CONSTANTS } from "../../../APIVar/EmployerUrlConstant";
import { GetAPI } from "../../API";

const member_get_unique_field_data = (
  user:string,
  onSuccess: (data: any) => void,
  onError: (err: any) => void
) => {
  GetAPI.call(
    EMPLOYER_URL_CONSTANTS.member_unique_field_data(user),
    onSuccess,
    onError
  );
};

const LOAD_FIELD_DATA_FOR_BULK_UPLOAD = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
  userType: string,
) => {
  GetAPI.call(
    `${EMPLOYER_URL_CONSTANTS.employer_member_field_data_for_bulk_upload}?key=${userType.toUpperCase()}`,
    onSuccess,
    onError
  );
};

export const EMPLOYER_BULK_UPLOAD_SERVICES = {
  member_get_unique_field_data,
  LOAD_FIELD_DATA_FOR_BULK_UPLOAD,
};
