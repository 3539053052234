import React, { useRef, useState } from "react";
import { useDrag } from "react-dnd";
import { ROW } from "./constants";
import DropZone from "./DropZone";
import Column from "./Column";
import { Link } from "@mui/material";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import { useSelector } from "react-redux";
import { RemoveItem, UpdateLayoutPropertiesAction } from "../../../../../Store/actions/layoutAction";
import { useDispatch } from "react-redux";

const style = {};
const Row = ({ data, components, handleDrop, path, childrenCount }) => {
  const ref = useRef(null);
  const [isEditAble, setIsEditable] = useState(true);
  const editRef = useRef(null)
  const modal = useSelector(({modalReducer})=>{return modalReducer});
  //console.log(editRef.current)
  const layoutData = useSelector(({layoutReducer})=>{return layoutReducer});
  const dispatch = useDispatch();
  const [headingText, setHeadingText] = useState('');
  const [currentIndex, setcurrentIndex] = useState();
  //const [foundContactId, setFoundContactId] = useState(false);

  const [{ isDragging }, drag] = useDrag({
    item: {
      type: ROW,
      id: data.id,
      children: data.children,
      path
    },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  });

  const opacity = isDragging ? 0 : 1;
  drag(ref);

  const removeRow = ()=>{
    dispatch(RemoveItem(data, components, path, layoutData));
  }

  const renderColumn = (column, currentPath, childrenCount) => {
    // console.log(currentPath)
    return (
      <Column
        key={column.id}
        data={column}
        components={components}
        handleDrop={handleDrop}
        path={currentPath}
        childrenCount= {childrenCount}
      />
    );
  };

  const handleHeadingChange = function (event) {
    const index = path.split("-");
    setHeadingText(event.target.innerText);
    setcurrentIndex(index);
  }

  const handleBlur = ()=>{
    console.log("left the cursor")
    layoutData.layout[currentIndex[0]].children[currentIndex[1]].name = headingText
    dispatch(UpdateLayoutPropertiesAction(layoutData.layout,{})); 
  }

  return (
    <div ref={ref} style={{ ...style, opacity }} className="draggable row">
      {/* <h6 className="subTitle" contentEditable={true} onInput={handleHeadingChange} onBlur={handleBlur}>
        {data.name}
        <div className="actionBtns text-right">
          {childrenCount!==1 && !data.preDefiend?
          <Link
            onClick={removeRow}
            className="delete_btn">
            <DeleteForeverIcon />
          </Link>:""}
        </div>
      </h6> */}

      <div className="columns">
        {data.children.map((column, index) => {
          const currentPath = `${path}-${index}`;
          //console.log(column)
          return (
            <React.Fragment key={column.id}>
              <DropZone
                data={{
                  path: currentPath,
                  childrenCount: data.children.length,
                  type : data
                }}
                onDrop={handleDrop}
                className="horizontalDrag"
              />
              {renderColumn(column, currentPath, data.children.length)}
            </React.Fragment>
          );
        })}
        <DropZone
          data={{
            path: `${path}-${data.children.length}`,
            childrenCount: data.children.length,
            type : data
          }}
          onDrop={handleDrop}
          className="horizontalDrag"
          isLast
        />
      </div>
    </div>
  );
};
export default Row;
