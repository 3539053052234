//used
import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Unstable_Grid2";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { Box, Button, Link, TextField, Tooltip } from "@mui/material";
import "../../../../../../formLayout.scss";
import RKTextField from "../../../../../../Supporting files/RKTextField/RKTextField";
import SelectDropdown from "../../../../../../Supporting files/SelectDropdown/SelectDropdown";
import SearchDropdown from "../../../../../../Supporting files/SearchDropdown/SearchDropdown";
import RemovePopup from "../../../../../../Components/Common/CarForm/RemovePopup/RemovePopup";
import SlidingPanel from "react-sliding-side-panel";
import "../../../../../../formLayout.scss";
import { ALL_ROUTES } from "../../../../../../Routes/all_routes";
function VoluntaryDetails() {
  const navigate = useNavigate();
  const [viewTabsStatus, setViewTabsStatus] = React.useState("overview");
  const [productName, setProductName] = React.useState("");
  const [coverage, setCoverage] = React.useState("");
  const [paymentFrequency, setPaymentFrequency] = React.useState("");
  const [fieldType, setFieldType] = React.useState("");
  const [unit, setUnit] = React.useState("");
  const [value, setValue] = React.useState("");
  const [exclusionTitle, setExclusionTitle] = React.useState("");
  const [openCategory, setOpenCategory] = React.useState(false);
  const [openExclusion, setOpenExclusion] = React.useState(false);
  const [openFeatures, setOpenFeatures] = React.useState(false);
  const [describe, setDescribe] = React.useState("");
  const [categoryName, setCategoryName] = React.useState("");
  const [category, setCategory] = React.useState("");
  const [sectionStatus, SetSectionStatus] =
    React.useState<string>("personalDetails");
  const [subSection, setSubSection] = React.useState("basicinfo");
  const [removeCategory, setRemoveCategory] = React.useState(false);
  const [show, setShow] = useState(false);
  const [voluntaryData, setVoluntaryData] = React.useState([
    {
      CategoryName: "",
      Description: "",
      actionBtn: "",
    },
  ]);
  useEffect(() => {
    setVoluntaryData([
      {
        CategoryName: "Maternity Cover",
        Description:
          "This policy provides comprehensive coverage upto 100% of Sum Insured against Accidental Death, Permanent Total Disability (PTD), Permanent Partial Disability (PPD) and upto 1% of Actual Salary incase of Temporary Total Disability (TTD)....more",
        actionBtn: "images/edit_icon.svg",
      },
      {
        CategoryName: "Maternity Cover",
        Description:
          "This policy provides comprehensive coverage upto 100% of Sum Insured against Accidental Death, Permanent Total Disability (PTD), Permanent Partial Disability (PPD) and upto 1% of Actual Salary incase of Temporary Total Disability (TTD)....more",
        actionBtn: "images/edit_icon.svg",
      },
      {
        CategoryName: "Maternity Cover",
        Description:
          "This policy provides comprehensive coverage upto 100% of Sum Insured against Accidental Death, Permanent Total Disability (PTD), Permanent Partial Disability (PPD) and upto 1% of Actual Salary incase of Temporary Total Disability (TTD)....more",
        actionBtn: "images/edit_icon.svg",
      },
    ]);
  }, []);

  const category_data = [
    { key: "Pre Existing Disease", value: "Pre Existing Disease" },
    { key: "Ambulance Cover", value: "Ambulance Cover" },
    { key: "Maternity Cover", value: "Maternity Cover" },
  ];

  const units_data = [
    { key: "30 Days", value: "30 Days" },
    { key: "60 Days", value: "60 Days" },
    { key: "45 Days", value: "45 Days" },
  ];
  const field_data = [
    { key: "Pre Existing Disease", value: "Pre Existing Disease" },
    { key: "Ambulance Cover", value: "Ambulance Cover" },
    { key: "Maternity Cover", value: "Maternity Cover" },
  ];
  const [voluntaryExclusionData, setVoluntaryExclusionData] = React.useState([
    {
      checkbox_status: true,
      CategoryName: "",
      Description: "",
      actionBtn: "",
    },
  ]);
  useEffect(() => {
    setVoluntaryExclusionData([
      {
        checkbox_status: false,
        CategoryName: "Maternity Cover",
        Description:
          "This policy provides comprehensive coverage upto 100% of Sum Insured against Accidental Death, Permanent Total Disability (PTD), Permanent Partial Disability (PPD) and upto 1% of Actual Salary incase of Temporary Total Disability (TTD)....more",
        actionBtn: "images/edit_icon.svg",
      },
      {
        checkbox_status: false,
        CategoryName: "Maternity Cover",
        Description:
          "This policy provides comprehensive coverage upto 100% of Sum Insured against Accidental Death, Permanent Total Disability (PTD), Permanent Partial Disability (PPD) and upto 1% of Actual Salary incase of Temporary Total Disability (TTD)....more",
        actionBtn: "images/edit_icon.svg",
      },
      {
        checkbox_status: false,
        CategoryName: "Maternity Cover",
        Description:
          "This policy provides comprehensive coverage upto 100% of Sum Insured against Accidental Death, Permanent Total Disability (PTD), Permanent Partial Disability (PPD) and upto 1% of Actual Salary incase of Temporary Total Disability (TTD)....more",
        actionBtn: "images/edit_icon.svg",
      },
    ]);
  }, []);

  const [voluntaryFeatureData, setVoluntaryFeatureData] = React.useState([
    {
      CategoryName: "",
      featureName: "",
      fieldtype: "",
      fieldvalue: "",
      unit: "",
      Description: "",
      actionBtn: "",
    },
  ]);

  useEffect(() => {
    setVoluntaryFeatureData([
      {
        CategoryName: "Maternity Cover",
        featureName: "Evervent",
        fieldtype: "Maternity Cover",
        fieldvalue: "Alphanumeric",
        unit: "60 Days",
        Description:
          "This policy provides comprehensive coverage upto 100% of Sum Insured against Accidental Death, Permanent Total Disability (PTD), Permanent Partial Disability (PPD) and upto 1% of Actual Salary incase of Temporary Total Disability (TTD)....more",
        actionBtn: "images/edit_icon.svg",
      },
      {
        CategoryName: "Maternity Cover",
        featureName: "Evervent",
        fieldtype: "Maternity Cover",
        fieldvalue: "Alphanumeric",
        unit: "60 Days",
        Description:
          "This policy provides comprehensive coverage upto 100% of Sum Insured against Accidental Death, Permanent Total Disability (PTD), Permanent Partial Disability (PPD) and upto 1% of Actual Salary incase of Temporary Total Disability (TTD)....more",
        actionBtn: "images/edit_icon.svg",
      },
      {
        CategoryName: "Maternity Cover",
        featureName: "Evervent",
        fieldtype: "Maternity Cover",
        fieldvalue: "Alphanumeric",
        unit: "60 Days",
        Description:
          "This policy provides comprehensive coverage upto 100% of Sum Insured against Accidental Death, Permanent Total Disability (PTD), Permanent Partial Disability (PPD) and upto 1% of Actual Salary incase of Temporary Total Disability (TTD)....more",
        actionBtn: "images/edit_icon.svg",
      },
    ]);
  }, []);

  // for scrolling
  const personalDetails = useRef(null);

  const basicinfo = useRef(null);

  const scrollToSection = (elementRef: any) => {
    //  ;
    elementRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleClicks = (ref: any) => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  const updateMasterState = (attrName: any, value: string) => {
    attrName(value);
  };
  const coverage_data = [
    { key: "Employee Only", value: "Employee Only" },
    { key: "Employees + Dependents", value: "Employees + Dependents" },
    { key: "Dependents Only", value: "Dependents Only" },
  ];
  const paymentfrequency_data = [
    { key: "Monthly", value: "Monthly" },
    { key: "Quaterly", value: "Quaterly" },
    { key: "Half yearly", value: "Half yearly" },
    { key: "Yearly", value: "Yearly" },
  ];
  const unit_data = [{ key: "Currency", value: "Currency" }];
  const value_data = [
    { label: "Dollars", value: "Dollars" },
    { label: "Dirham", value: "Dirham" },
    { label: "Pound", value: "Pound" },
    { label: "Yen", value: "Yen" },
  ];

  return (
    <Box className="layoutWrapper">
      {/* Page header */}
      <Grid container spacing={3} className="pageHeader">
        <Grid xs={6} className="pageTitle">
          <Link
            className="backStep"
            onClick={() => {
              navigate(-1);
            }}
          >
            <ChevronLeftIcon />
          </Link>
          <span className="detailIcon">D</span>
          <div>
            <h3>Digit</h3>
          </div>
        </Grid>
        <Grid
          xs={6}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
          }}
        >
          <div>
            <Tooltip title="Global Edit">
              <Link
                className="actionLinks"
                onClick={() => {
                  navigate(ALL_ROUTES.DASHBOARD);
                }}
              >
                <BorderColorIcon />
              </Link>
            </Tooltip>
          </div>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid xs={12}>
          <Grid container spacing={3}>
            <Grid xs className="leftSection h-100">
              <Link
                className={
                  sectionStatus === "personalDetails"
                    ? "active sectionLink personalDetails"
                    : "sectionLink personalDetails"
                }
                onClick={() => {
                  scrollToSection(personalDetails);
                  SetSectionStatus("personalDetails");
                }}
              >
                <span> Basic Info</span>
              </Link>
              <ul className="accordion_list">
                <li>
                  <Link
                    className={
                      subSection === "basicinfo"
                        ? "inner_link active basicinfo"
                        : "inner_link basicinfo"
                    }
                    onClick={() => {
                      setSubSection("basicinfo");
                      handleClicks(subSection);
                      scrollToSection(basicinfo);
                    }}
                  >
                    Plan Details
                  </Link>
                </li>
              </ul>
            </Grid>
            <Grid xs className="rightSection h-100">
              <Grid container spacing={3} alignItems="center">
                <Grid lg={7} md={7} xs={12} className="pl-0">
                  <div className="productTabs ">
                    <Button
                      className={
                        viewTabsStatus === "overview"
                          ? "defaultTab active"
                          : "defaultTab"
                      }
                      onClick={() => {
                        updateMasterState(setViewTabsStatus, "overview");
                      }}
                    >
                      Overview
                    </Button>
                    <Button
                      className={
                        viewTabsStatus === "categories"
                          ? "defaultTab active"
                          : "defaultTab"
                      }
                      onClick={() => {
                        updateMasterState(setViewTabsStatus, "categories");
                      }}
                    >
                      Categories
                    </Button>
                    <Button
                      className={
                        viewTabsStatus === "features"
                          ? "defaultTab active"
                          : "defaultTab"
                      }
                      onClick={() => {
                        updateMasterState(setViewTabsStatus, "features");
                      }}
                    >
                      Features
                    </Button>
                    <Button
                      className={
                        viewTabsStatus === "exclusions"
                          ? "defaultTab active"
                          : "defaultTab"
                      }
                      onClick={() => {
                        updateMasterState(setViewTabsStatus, "exclusions");
                      }}
                    >
                      Exclusions
                    </Button>
                  </div>
                </Grid>

                {/* {show === false ? null : ( */}
                {viewTabsStatus === "categories" ? (
                  <Grid
                    lg={5}
                    md={5}
                    xs={12}
                    style={{ textAlign: "right" }}
                    className="text-sm-center"
                  >
                    <Link
                      className="redBtn delete"
                      underline="none"
                      onClick={() => {
                        setRemoveCategory(true);
                      }}
                    >
                      Delete
                    </Link>
                    <Link
                      className="greenBtn addfile"
                      underline="none"
                      onClick={() => {
                        setOpenCategory(true);
                      }}
                    >
                      Add New
                    </Link>
                  </Grid>
                ) : null}
                {viewTabsStatus === "features" ? (
                  <Grid
                    lg={5}
                    md={5}
                    xs={12}
                    style={{ textAlign: "right" }}
                    className="text-sm-center"
                  >
                    <Link
                      className="redBtn delete"
                      underline="none"
                      onClick={() => {
                        setRemoveCategory(true);
                      }}
                    >
                      Delete
                    </Link>
                    <Link
                      className="greenBtn addfile"
                      underline="none"
                      onClick={() => {
                        setOpenFeatures(true);
                      }}
                    >
                      Add New
                    </Link>
                  </Grid>
                ) : null}
                {viewTabsStatus === "exclusions" ? (
                  <Grid
                    lg={5}
                    md={5}
                    xs={12}
                    style={{ textAlign: "right" }}
                    className="text-sm-center"
                  >
                    <Link
                      className="redBtn delete"
                      underline="none"
                      onClick={() => {
                        setRemoveCategory(true);
                      }}
                    >
                      Delete
                    </Link>
                    <Link
                      className="greenBtn addfile"
                      underline="none"
                      onClick={() => {
                        setOpenExclusion(true);
                      }}
                    >
                      Add New
                    </Link>
                  </Grid>
                ) : null}
              </Grid>
              {viewTabsStatus === "overview" ? (
                <>
                  <Box className="detailSection" ref={personalDetails}>
                    <div className="sectionTitle mb-0">
                      <h4>Basic Info</h4>
                      <div className="actionBtns">
                        <Link
                          className="blueBtn edit"
                          sx={{ textDecoration: "none", cursor: "pointer" }}
                        >
                          Edit
                        </Link>
                      </div>
                    </div>
                    <div ref={basicinfo}>
                      <Grid container spacing={3}>
                        <Grid xs={12}>
                          <h5 className="subHeading">Plan Details</h5>
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid sm={4} md={4} lg={3} xl={2}>
                          <RKTextField
                            class_name="inputField"
                            title="Product Name"
                            value={productName}
                            attrName={setProductName}
                            value_update={updateMasterState}
                            warn_status={false}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid xs={12}>
                          <RKTextField
                            class_name="inputField"
                            title="Description"
                            value={describe}
                            attrName={setDescribe}
                            value_update={updateMasterState}
                            warn_status={false}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid sm={4} md={4} lg={3} xl={2}>
                          <SelectDropdown
                            class_name="inputField"
                            title="Coverage"
                            value={coverage}
                            attrName={setCoverage}
                            value_update={updateMasterState}
                            dropdown_data={coverage_data}
                            warn_status={false}
                          />
                        </Grid>
                        <Grid sm={4} md={4} lg={3} xl={2}>
                          <SelectDropdown
                            class_name="inputField"
                            title="Payment Frequency"
                            value={paymentFrequency}
                            attrName={setPaymentFrequency}
                            value_update={updateMasterState}
                            dropdown_data={paymentfrequency_data}
                            warn_status={false}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid sm={4} md={4} lg={3} xl={2}>
                          <SelectDropdown
                            class_name="inputField"
                            title="Unit"
                            value={unit}
                            attrName={setUnit}
                            value_update={updateMasterState}
                            dropdown_data={unit_data}
                            warn_status={false}
                          />
                        </Grid>
                        <Grid sm={4} md={4} lg={3} xl={2}>
                          <SearchDropdown
                            class_name="inputField"
                            title="Value"
                            value={value}
                            attrName={setValue}
                            value_update={updateMasterState}
                            data={value_data}
                            error_message="Please select client ID"
                            warn_status={false}
                          />
                        </Grid>
                      </Grid>
                    </div>
                  </Box>
                </>
              ) : null}
              {viewTabsStatus === "categories" ? (
                <>
                  <Box className="detailSection">
                    <div className="table">
                      <div className="voluntary-table">
                        <ul className="heading">
                          <li>
                            <div className="Checkbox">
                              <input type="checkbox" name="check" />
                              <label className="employee"></label>
                            </div>
                            Category Name
                            <button className="sorting-btn">
                              {/* <img src="./images/sorting_btn.svg" alt="" /> */}
                            </button>
                          </li>

                          <li>
                            Description
                            <button className="sorting-btn">
                              {/* <img src="./images/sorting_btn.svg" alt="" /> */}
                            </button>
                          </li>

                          <li>
                            Actions
                            <button className="sorting-btn mr-0">
                              {/* <img src="./images/sorting_btn.svg" alt="" /> */}
                            </button>
                          </li>
                        </ul>

                        {voluntaryData.map((data, index) => (
                          <ul className="subheading">
                            <li>
                              <div className="Checkbox">
                                <input type="checkbox" name="check" />
                                <label className="employee contentbox"></label>
                              </div>

                              {data.CategoryName}
                            </li>
                            <li>{data.Description}</li>
                            <li>
                              <Link className="edit_address_link">
                                <img src={data.actionBtn} alt="" />
                              </Link>
                            </li>
                          </ul>
                        ))}
                      </div>
                    </div>
                  </Box>
                </>
              ) : null}
              {viewTabsStatus === "features" ? (
                <>
                  <Box className="detailSection">
                    <div className="table">
                      <div className="voluntaryfeaturetable">
                        <ul className="headingdata">
                          <li>
                            <div className="Checkbox">
                              <input type="checkbox" name="check" />
                              <label className="employee"></label>
                            </div>
                            Category Name
                            <button className="sorting-btn">
                              {/* <img src="./images/sorting_btn.svg" alt="" /> */}
                            </button>
                          </li>
                          <li>
                            Feature Name
                            <button className="sorting-btn">
                              {/* <img src="./images/sorting_btn.svg" alt="" /> */}
                            </button>
                          </li>
                          <li>
                            Field Type
                            <button className="sorting-btn">
                              {/* <img src="./images/sorting_btn.svg" alt="" /> */}
                            </button>
                          </li>
                          <li>
                            Field Value
                            <button className="sorting-btn">
                              {/* <img src="./images/sorting_btn.svg" alt="" /> */}
                            </button>
                          </li>
                          <li>
                            Unit
                            <button className="sorting-btn">
                              {/* <img src="./images/sorting_btn.svg" alt="" /> */}
                            </button>
                          </li>
                          <li>
                            Description
                            <button className="sorting-btn">
                              {/* <img src="./images/sorting_btn.svg" alt="" /> */}
                            </button>
                          </li>

                          <li>
                            Actions
                            <button className="sorting-btn mr-0">
                              {/* <img src="./images/sorting_btn.svg" alt="" /> */}
                            </button>
                          </li>
                        </ul>
                        {voluntaryFeatureData.map((data, index) => (
                          <ul className="subheadingdata">
                            <li>
                              <div className="Checkbox">
                                <input type="checkbox" name="check" />
                                <label className="employee contentbox"></label>
                              </div>
                              {data.CategoryName}
                            </li>
                            <li>{data.featureName}</li>
                            <li>{data.fieldtype}</li>
                            <li>{data.fieldvalue}</li>
                            <li>{data.unit}</li>
                            <li>{data.Description}</li>
                            <li>
                              <Link className="edit_address_link">
                                <img src={data.actionBtn} alt="" />
                              </Link>
                            </li>
                          </ul>
                        ))}
                      </div>
                    </div>
                  </Box>
                </>
              ) : null}
              {viewTabsStatus === "exclusions" ? (
                <>
                  <Box className="detailSection">
                    <div className="table">
                      <div className="voluntaryexclusiontable">
                        <ul className="heading">
                          <li>
                            <div className="Checkbox">
                              <input type="checkbox" name="check" />
                              <label className="employee"></label>
                            </div>
                            Title
                            <button className="sorting-btn">
                              {/* <img src="./images/sorting_btn.svg" alt="" /> */}
                            </button>
                          </li>

                          <li>
                            Description
                            <button className="sorting-btn">
                              {/* <img src="./images/sorting_btn.svg" alt="" /> */}
                            </button>
                          </li>

                          <li>
                            Actions
                            <button className="sorting-btn mr-0">
                              {/* <img src="./images/sorting_btn.svg" alt="" /> */}
                            </button>
                          </li>
                        </ul>

                        {voluntaryExclusionData.map((data, index) => (
                          <ul className="subheading">
                            <li>
                              <div className="Checkbox">
                                <input type="checkbox" name="check" />
                                <label className="employee contentbox"></label>
                              </div>
                              {data.CategoryName}
                            </li>
                            <li>{data.Description}</li>
                            <li>
                              <Link className="edit_address_link">
                                <img src={data.actionBtn} alt="" />
                              </Link>
                            </li>
                          </ul>
                        ))}
                      </div>
                    </div>
                  </Box>
                </>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={3} className="footerSection">
        <Grid xs={12} className="ctaBtn">
          <Button variant="outlined" className="borderBtn">
            Cancel
          </Button>
          <Button variant="outlined" className="borderBtn">
            Save Draft
          </Button>
          <Button variant="contained" className="submitBtn">
            Save & Continue
          </Button>
        </Grid>
      </Grid>
      <RemovePopup
        open_status={removeCategory}
        attrName={setRemoveCategory}
        value_update={updateMasterState}
      />
      <SlidingPanel
        type={"right"}
        isOpen={openCategory}
        size={75}
        // backdropClicked={() => value_update(attrName, false)}
      >
        <div>
          <Grid container spacing={3} alignItems="center">
            <Grid xs={12}>
              <h4 className="mb-0 mt-4 ml-0">Add New Category</h4>
            </Grid>
            <Grid xs={12} className="mb-4">
              <hr />
            </Grid>
          </Grid>
          <div className="scrollable_area">
            {/* <div className="locations_section"> */}
            <Grid container spacing={3}>
              <Grid xs={12}>
                <RKTextField
                  class_name="inputField"
                  title="Category Name"
                  value={categoryName}
                  attrName={setCategoryName}
                  value_update={updateMasterState}
                  warn_status={false}
                />
              </Grid>

              <Grid xs={12} className="mb-4">
                {/* <p>Describe here</p> */}
                <textarea
                  className="textareabox"
                  placeholder="Describe"
                ></textarea>
              </Grid>
            </Grid>
          </div>
          <Grid container spacing={3}>
            <Grid xs={12} className="ctaBtn mt-4">
              <Button
                variant="contained"
                className="submitBtn fixedbtn"
                onClick={() => {
                  setOpenCategory(false);
                }}
              >
                Add Category
              </Button>
            </Grid>
          </Grid>
        </div>
      </SlidingPanel>
      <SlidingPanel
        type={"right"}
        isOpen={openExclusion}
        size={75}
        // backdropClicked={() => value_update(attrName, false)}
      >
        <div>
          <Grid container spacing={3} alignItems="center">
            <Grid xs={12}>
              <h4 className="mb-0 mt-4 ml-0">Add Exclusions</h4>
            </Grid>
            <Grid xs={12} className="mb-4">
              <hr />
            </Grid>
          </Grid>
          <div className="scrollable_area">
            {/* <div className="locations_section"> */}
            <Grid container spacing={3}>
              <Grid xs={12}>
                <RKTextField
                  class_name="inputField"
                  title="Title"
                  value={exclusionTitle}
                  attrName={setExclusionTitle}
                  value_update={updateMasterState}
                  warn_status={false}
                />
              </Grid>

              <Grid xs={12} className="mb-4">
                {/* <p>Describe here</p> */}
                <textarea
                  className="textareabox"
                  placeholder="Describe"
                ></textarea>
              </Grid>
            </Grid>
          </div>
          <Grid container spacing={3}>
            <Grid xs={12} className="ctaBtn mt-4">
              <Button
                variant="contained"
                className="submitBtn fixedbtn"
                onClick={() => {
                  setOpenExclusion(false);
                }}
              >
                Add Exclusion
              </Button>
            </Grid>
          </Grid>
        </div>
      </SlidingPanel>
      <SlidingPanel
        type={"right"}
        isOpen={openFeatures}
        size={75}
        // backdropClicked={() => value_update(attrName, false)}
      >
        <div>
          <Grid container spacing={3} alignItems="center">
            <Grid xs={12}>
              <h4 className="mb-0 mt-4 ml-0">Add Feature</h4>
            </Grid>
            <Grid xs={12} className="mb-4">
              <hr />
            </Grid>
          </Grid>
          <div className="scrollable_area">
            {/* <div className="locations_section"> */}
            <Grid container spacing={3}>
              <Grid xs={12}>
                <SelectDropdown
                  class_name="inputField"
                  title="Category"
                  value={category}
                  attrName={setCategory}
                  value_update={updateMasterState}
                  dropdown_data={category_data}
                  warn_status={false}
                />
              </Grid>
              <Grid xs={12}>
                <RKTextField
                  class_name="inputField"
                  title="Feature Name"
                  value={exclusionTitle}
                  attrName={setExclusionTitle}
                  value_update={updateMasterState}
                  warn_status={false}
                />
              </Grid>

              <Grid xs={12}>
                <SelectDropdown
                  class_name="inputField"
                  title="Field Type"
                  value={fieldType}
                  attrName={setFieldType}
                  value_update={updateMasterState}
                  dropdown_data={field_data}
                  warn_status={false}
                />
              </Grid>

              <Grid xs={12}>
                <SelectDropdown
                  class_name="inputField"
                  title="Unit"
                  value={unit}
                  attrName={setUnit}
                  value_update={updateMasterState}
                  dropdown_data={units_data}
                  warn_status={false}
                />
              </Grid>

              <Grid xs={12} className="mb-4">
                {/* <p>Describe here</p> */}
                <textarea
                  className="textareabox"
                  placeholder="Describe"
                ></textarea>
              </Grid>
            </Grid>
          </div>
          <Grid container spacing={3}>
            <Grid xs={12} className="ctaBtn mt-4">
              <Button
                variant="contained"
                className="submitBtn fixedbtn"
                onClick={() => {
                  setOpenFeatures(false);
                }}
              >
                Add Feature
              </Button>
            </Grid>
          </Grid>
        </div>
      </SlidingPanel>
    </Box>
  );
}
export default VoluntaryDetails;
