import { Button, FormControlLabel, Link, Radio, RadioGroup } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { GridCloseIcon } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import SlidingPanel from "react-sliding-side-panel";
import { toast } from "react-toastify";
import { EMPLOYEE_URL_CONSTANTS } from "../../../../APIVar/EmployeeUrlConstant";
import "../../../../formLayout.scss";
import { nomineeDTO } from "../../../../Services/DTO/Employee/EmployeeDTO";
import { EMPLOYEE_POLICY_SERVICES } from "../../../../Services/Employee/EmployeePolicyService";
import { EMPLOYEE_NOMINEE_SERVICES } from "../../../../Services/Employee/NomineeService";
import { useAppSelector } from "../../../../Store/hooks";
import DatePicker from "../../../../Supporting files/DatePicker/DatePicker";
import { calculateAgeFromDob } from "../../../../Supporting files/HelpingFunction";
import RKTextField from "../../../../Supporting files/RKTextField/RKTextField";
import SelectDropdown from "../../../../Supporting files/SelectDropdown/SelectDropdown";
interface Props {
  open_status: boolean;
  attrName?: any;
  value_update?: any;
  id: string;
  refetchData: any;
  apiUrl?: string;
  policyIdEmployee?: string;
  existingNominees?: any[];
  onlyNew?: boolean;
  policyBeingWorkedOn?: any;
}

const AddNominee: React.FC<Props> = ({
  open_status,
  attrName,
  value_update,
  id,
  refetchData,
  apiUrl,
  policyIdEmployee,
  existingNominees,
  onlyNew,
  policyBeingWorkedOn
}) => {
  const [disableSave, setDisableSave] = useState<boolean>(false);
  const { userType, id: employeeId, memberId, Client: clientLabel, clientId } = useAppSelector((state: any) => state.userDetailsSlice);
  const currentDate = new Date();
  const minAge = new Date(currentDate.getFullYear() - 18, currentDate.getMonth(), currentDate.getDate());
  const [addNominee, setAddNominee] = useState<nomineeDTO>({
    employeeId: id,
    fullName: "",
    relation: "",
    dob: null,
    age: "",
    warnDob: false,
  });
  const [allRelationValues, setAllRelationValues] = useState<any[]>([
    { key: "Husband", value: "Husband", type: "S" },
    { key: "Wife", value: "Wife", type: "S" },
    { key: "Brother", value: "Brother", type: "S" },
    { key: "Sister", value: "Sister", type: "S" },
    { key: "Daughter", value: "Daughter", type: "C" },
    { key: "Son", value: "Son", type: "C" },
    { key: "Father", value: "Father", type: "P" },
    { key: "Mother", value: "Mother", type: "P" },
    { key: "Mother-In-Law", value: "Mother-In-Law", type: "PIL" },
    { key: "Father-In-Law", value: "Father-In-Law", type: "PIL" },
  ])
  const [toggle, setToggle] = useState(true);
  const [nomineeDob, setNomineeDob] = useState<Date | string>("");
  const [selectedNominee, setSelectedNominee] = useState("");
  const [employeeNominees, setEmployeeNominees] = useState([]);
  const [nomineeToAdd, setNomineeToAdd] = useState<{
    label: string;
    relation: string;
    _id: string;
    dob: string;
  }>({ label: "", relation: "", _id: "", dob: "" });
  const windowURL = window.location.search;
  const params = new URLSearchParams(windowURL);
  const empid = params.get("id");
  const [employeeMembers, setEmployeeMembers] = useState<{
    fullName: string;
    relation: string;
    _id: string;
  }[]>([]);

  const CreateEndorsementForNomineeAndMember = (
    endorsementFor: string,
    nomineeId?: string
  ) => {
    let data = {
      memberId: employeeId, // wrong "65f91b55df711f0ba0411773"
      member: memberId, // wrong "EMP0027"
      policyId: policyBeingWorkedOn.policyId,
      policy: policyBeingWorkedOn.policyNumber,
      clientId: clientId,
      client: clientLabel,
      type: endorsementFor.includes("Member") ? "COMMERCIAL" : "NON COMMERCIAL",
      endorsementFor: endorsementFor,
      old: "",
      new: {
        dob: "",
      },
      attachment: {},
      nomineeId,
    };
    const onSuccess = (res: any) => { };

    const onError = (err: any) => {
      console.log(err);
    };

    EMPLOYEE_POLICY_SERVICES.CreateEndorsementForNominee(
      onSuccess,
      onError,
      data,
      userType.toLocaleLowerCase()
    );
  };

  const updateMasterState = (attrName: any, value: any) => {
    let mainState = attrName[0];
    let key = attrName[1];
    let dateOfBirth: any = value === null ? null : new Date(value);
    if (mainState === "nomineeDetail") {
      setAddNominee({
        ...addNominee,
        [key]: value,
        [`warn${key.charAt(0).toUpperCase()}${key.slice(1)}`]: false,
        age: key === "dob" ? calculateAgeFromDob(dateOfBirth) : "",
      });
    } else if (mainState === setSelectedNominee) {
      const selectedNomineeToAdd: any = employeeNominees?.filter((item: any) => item?._id === value);
      if (existingNominees?.find((nom: any) => nom?._id === selectedNomineeToAdd[0]?._id)) {
        setSelectedNominee("");
        setNomineeToAdd({ label: "", relation: "", _id: "", dob: "" })
        setAddNominee({
          employeeId: id,
          fullName: "",
          relation: "",
          dob: null,
          age: "",
          warnDob: false,
        })
        return toast.info("Nominee already exists in the policy");
      }
      setNomineeToAdd(selectedNomineeToAdd[0]);
      setSelectedNominee(value);
      setAddNominee({
        employeeId: id,
        fullName: selectedNomineeToAdd[0]?.label,
        relation: selectedNomineeToAdd[0]?.relation,
        dob: selectedNomineeToAdd[0]?.dob || null,
        age: calculateAgeFromDob(selectedNomineeToAdd[0]?.dob) || "",
        warnDob: false
      })
    } else {
      attrName(value);
    }
  };
  const checkRelationExist = (relation: string) => {
    if (relation === "Wife" || relation === "Husband") {
      const isExist = employeeMembers?.some(
        (member) => member?.relation === "Wife" || member?.relation === "Husband"
      );
      if (isExist) {
        toast.warning("Spouse already exists");
      }

      return !isExist;
    } else {
      return true;
    }
  };

  const onSubmit = () => {
    if (disableSave) return;
    let data: any = { ...addNominee };
    data.warnFullName = addNominee.fullName.length === 0 ? true : false;
    data.warnRelation = addNominee.relation.length === 0 ? true : false;
    data.warnDob = addNominee.dob === null ? true : false;
    setAddNominee({ ...data });
    if (
      data.warnFullName === false &&
      data.warnRelation === false &&
      data.warnDob === false
    ) {
      const onSuccess = (res: any) => {
        value_update(attrName, false);
        setToggle(true);
        setSelectedNominee("");
        setDisableSave(false);
        setNomineeToAdd({
          label: "", relation: "", _id: "", dob: ""
        });
        setAddNominee({
          employeeId: id,
          fullName: "",
          relation: "",
          dob: null,
          age: "",
          warnDob: false,
        })
        refetchData();
        toast.success(res.data.message);
        const policyEndDate = new Date(policyBeingWorkedOn.endDate);
        const policyStartDate = new Date(policyBeingWorkedOn.startDate);
        const currentDate = new Date();
        const isTodayBetweenPolicyDates = currentDate >= policyStartDate && currentDate <= policyEndDate;
        if (isTodayBetweenPolicyDates &&
          (policyBeingWorkedOn?.status === 60 || policyBeingWorkedOn?.status === 41)) {
          CreateEndorsementForNomineeAndMember("Nominee Addition", res.data.data._id);
        }
      };
      const onError = (err: any) => {setDisableSave(false); };
      let parmas;
      if (toggle) {
        const data = {
          policyId: policyIdEmployee,
          nomineeId: selectedNominee,
        };
        parmas = data;
      } else {
        const data = {
          policyId: policyIdEmployee,
          fullName: addNominee.fullName,
          relation: addNominee.relation,
          dob: addNominee.dob || "",
          age: calculateAgeFromDob(addNominee.dob),
          employeeId: id || empid
        };
        parmas = data;
      }
      if (nomineeDob === null) {
        return toast.error("Enter DOB")
      }
      if (checkRelationExist(addNominee.relation)) {
        setDisableSave(true);
        EMPLOYEE_NOMINEE_SERVICES.create_Nominee(
          parmas,
          onSuccess,
          {},
          onError,
          apiUrl ? `${apiUrl}` : `${EMPLOYEE_URL_CONSTANTS.add_nomine}${id}&code=${toggle ? "0" : "1"}`
        );
      }
    }
  };

  const Clicktoogle = () => {
    setToggle(true);
    setSelectedNominee("");
    setNomineeToAdd({
      label: "", relation: "", _id: "", dob: ""
    })
  };
  const Clicked = () => {
    setToggle(false);
    setAddNominee({
      employeeId: id,
      fullName: "",
      relation: "",
      dob: null,
      age: "",
      warnDob: false,
    })
  };

  const getNominees = () => {
    const onSuccess = (res: any) => {
      setEmployeeNominees(res.data.data);
    };

    const onError = (err: any) => {
      console.log(err);
    };
    EMPLOYEE_POLICY_SERVICES.get_nominees(
      onSuccess,
      onError,
      id,
      userType.toLocaleLowerCase()
    );
  }

  const getMembers = () => {
    const onSuccess = (res: any) => {
      setEmployeeMembers(res.data.data);
    };

    const onError = (err: any) => {
      console.log(err);
    };

    EMPLOYEE_POLICY_SERVICES.get_members(
      onSuccess,
      onError,
      (id || empid as any),
      userType.toLowerCase()
    );
  };

  useEffect(() => {
    if (open_status) {
      getNominees();
      getMembers();
    }
  }, [open_status])

  useEffect(() => {
    const availableRelations = allRelationValues?.filter(rel => {
      if (rel.key?.toLowerCase() === "son" || rel.key?.toLowerCase() === "daughter") {
        return true; // Include "Son" and "Daughter"
      } else {
        return !employeeMembers?.some(
          (item: any) =>
            item.relationWithEmployee?.toLowerCase() === rel.key?.toLowerCase() ||
            item.relationShip?.toLowerCase() === rel.key?.toLowerCase() ||
            item.relation?.toLowerCase() === rel.key?.toLowerCase()
        );
      }
    });
    setAllRelationValues(availableRelations)
  }, [employeeMembers])

  useEffect(() => {
    if (onlyNew) {
      setToggle(false);
      setAddNominee({
        employeeId: id, fullName: "", relation: "",
        dob: null, age: "", warnDob: false,
      })
      setSelectedNominee("");
      setNomineeToAdd({
        label: "", relation: "", _id: "", dob: ""
      })
    }
  }, [open_status])

  return (
    <>
      <SlidingPanel
        type={"right"}
        isOpen={open_status}
        size={75}
        panelClassName="sliding-panel"
      >
        <div>
          <Grid container spacing={3} alignItems="center">
            <Grid xs={10}>
              <h4 className="mb-0 mt-3 text-left">Fill Nominee Details</h4>
            </Grid>
            <Grid xs={2} textAlign="end" className="mt-2">
              <GridCloseIcon
                onClick={() => {
                  value_update(attrName, false);
                  setToggle(true);
                  setSelectedNominee("");
                  setNomineeToAdd({
                    label: "", relation: "", _id: "", dob: ""
                  })
                  setAddNominee({
                    employeeId: id, fullName: "", relation: "",
                    dob: null, age: "", warnDob: false,
                  })
                  setDisableSave(false);
                }}
              />
            </Grid>
            <Grid xs={12}>
              <hr />
            </Grid>
          </Grid>
          {onlyNew ? (
            <>
              <Grid
                container
                spacing={3}
                alignItems="center"
                sx={{ paddingTop: "0" }}
              >
                <Grid xs={12}>
                  <div className="scrollable_area">
                    <Grid container spacing={3}>
                      <>
                        <Grid xs={6}>
                          <RKTextField
                            class_name="inputField"
                            title="Full Name"
                            value={addNominee.fullName}
                            attrName={["nomineeDetail", "fullName"]}
                            value_update={updateMasterState}
                            warn_status={addNominee.warnFullName}
                          />
                        </Grid>
                        <Grid xs={6}>
                          <SelectDropdown
                            class_name="inputField"
                            title="Relationship with employee"
                            value={addNominee.relation}
                            attrName={["nomineeDetail", "relation"]}
                            value_update={updateMasterState}
                            dropdown_data={allRelationValues}
                            warn_status={addNominee.warnRelation}
                          />
                        </Grid>
                        <Grid xs={6}>
                          <DatePicker
                            class_name="inputField"
                            title="Date of Birth"
                            value={addNominee.dob}
                            attrName={["nomineeDetail", "dob"]}
                            value_update={updateMasterState}
                            error_message="Enter Date of Birth"
                            warn_status={addNominee.warnDob === undefined ? false : addNominee.warnDob}
                            max_date={minAge}
                          />
                        </Grid>
                        <Grid xs={6}>
                          <p className="health_ecard">
                            Age
                            <span>{calculateAgeFromDob(addNominee.dob)}</span>
                          </p>
                        </Grid>
                      </>
                    </Grid>
                  </div>
                </Grid>
                <Grid xs={12} className="footer_sec">
                  <Link
                    className="save_btn"
                    onClick={onSubmit}
                  >
                    Submit
                  </Link>
                </Grid>

              </Grid>
            </>
          ) : (
            <>
              <Grid
                container
                spacing={3}
                alignItems="center"
                sx={{ paddingTop: "0" }}
              >
                <Grid xs={12} className="radio_button no-border">
                  <>
                    <RadioGroup row defaultValue="existing">
                      <FormControlLabel
                        value="existing"
                        control={<Radio />}
                        label="Choose from existing nominee"
                        onClick={Clicktoogle}
                      />
                      <FormControlLabel
                        // checked
                        value="new"
                        control={<Radio />}
                        label="Add new nominee"
                        onClick={Clicked}
                      />
                    </RadioGroup>
                  </>
                </Grid>
                <Grid xs={12}>
                  <div className="scrollable_area">
                    <Grid container spacing={3}>
                      {toggle ? (
                        <>
                          <Grid xs={6}>
                            <SelectDropdown
                              class_name="inputField"
                              title="Select Nominee"
                              value={selectedNominee}
                              attrName={[setSelectedNominee]}
                              value_update={updateMasterState}
                              dropdown_data={employeeNominees?.map(
                                (item: any) => {
                                  return {
                                    key: item?._id,
                                    value: item?.label,
                                  };
                                }
                              )}
                              warn_status={false}
                              error_messg="Select RelationShip"
                            />
                          </Grid>
                          <Grid xs={6}>
                            <p className="health_ecard">
                              Relationship with Employee
                              <span>{nomineeToAdd?.relation}</span>
                            </p>
                          </Grid>
                        </>
                      ) : (
                        <>
                          <Grid xs={6}>
                            <RKTextField
                              class_name="inputField"
                              title="Full Name"
                              value={addNominee.fullName}
                              attrName={["nomineeDetail", "fullName"]}
                              value_update={updateMasterState}
                              warn_status={addNominee.warnFullName}
                            />
                          </Grid>
                          <Grid xs={6}>
                            <SelectDropdown
                              class_name="inputField"
                              title="Relationship with employee"
                              value={addNominee.relation}
                              attrName={["nomineeDetail", "relation"]}
                              value_update={updateMasterState}
                              dropdown_data={allRelationValues}
                              warn_status={addNominee.warnRelation}
                            />
                          </Grid>
                          <Grid xs={6}>
                            <DatePicker
                              class_name="inputField"
                              title="Date of Birth"
                              value={addNominee.dob}
                              attrName={["nomineeDetail", "dob"]}
                              value_update={updateMasterState}
                              error_message="Enter Date of Birth"
                              warn_status={addNominee.warnDob === undefined ? false : addNominee.warnDob}
                              max_date={minAge}
                            />
                          </Grid>
                          <Grid xs={6}>
                            <p className="health_ecard">
                              Age
                              <span>{calculateAgeFromDob(addNominee.dob)}</span>
                            </p>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </div>
                </Grid>
                <Grid xs={12} className="footer_sec">
                  <Button
                    className="save_btn"
                    onClick={onSubmit}
                    disabled={disableSave}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </>
          )}
        </div>
      </SlidingPanel>
    </>
  );
};
export default AddNominee;
