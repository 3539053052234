import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import SelectDropdown from "../../../../../../Supporting files/SelectDropdown/SelectDropdown";
import SearchDropdown from "../../../../../../Supporting files/SearchDropdown/SearchDropdown";
import RKTextField from "../../../../../../Supporting files/RKTextField/RKTextField";
import DatePicker from "../../../../../../Supporting files/DatePicker/DatePicker";

function EmployeeTravelForm() {
  const [travel, setTravel] = React.useState("");
  const [planType, setPlanType] = React.useState("");
  const [frequency, setFrequency] = React.useState("");
  const [mobile, setMobile] = React.useState("");
  const [memberDate, setMemberDate] = React.useState<Date | null>(null);
  const [endDate, setEndDate] = React.useState<Date | null>(null);

  const updateMasterState = (attrName: any, value: any) => {
    attrName(value);
  };

  const travel_data = [
    { label: "India", value: "India" },
    { label: "New Zealand", value: "New Zealand" },
    { label: "England", value: "England" },
    { label: "Australia", value: "Australia" },
  ];
  const planType_data = [
    { Key: "Individual/ Family", value: "Individual/ Family" },
    { Key: "Student", value: "Student" },
  ];
  const frequency_data = [
    { Key: "Single Trip", value: "Single Trip" },
    { Key: "Multi Trip", value: "Multi Trip" },
  ];
  return (
    <Grid container spacing={3}>
      <Grid xs={6}>
        <SearchDropdown
          class_name="inputField"
          title="Where you want to travel?"
          value={travel}
          attrName={setTravel}
          value_update={updateMasterState}
          data={travel_data}
          warn_status={false}
          error_message={"please select type"}
        />
      </Grid>
      <Grid xs={6}>
        <SelectDropdown
          class_name="inputField"
          title="Plan Type"
          value={planType}
          attrName={setPlanType}
          value_update={updateMasterState}
          dropdown_data={planType_data}
          warn_status={false}
        />
      </Grid>
      <Grid xs={6}>
        <SelectDropdown
          class_name="inputField"
          title="Frequency"
          value={frequency}
          attrName={setFrequency}
          value_update={updateMasterState}
          dropdown_data={frequency_data}
          warn_status={false}
        />
      </Grid>

      <Grid xs={6}>
        <DatePicker
          class_name="inputField"
          title="Start Date"
          value={memberDate}
          attrName={setMemberDate}
          value_update={updateMasterState}
          error_message="Please enter Start Date"
          warn_status={false}
        />
      </Grid>
      <Grid xs={6}>
        <DatePicker
          class_name="inputField"
          title="End Date"
          value={endDate}
          attrName={setEndDate}
          value_update={updateMasterState}
          error_message="Please enter End Date"
          warn_status={false}
        />
      </Grid>
      <Grid xs={6}>
        <RKTextField
          class_name="inputField"
          title="Mobile"
          value={mobile}
          attrName={setMobile}
          value_update={updateMasterState}
          warn_status={false}
        />
      </Grid>
    </Grid>
  );
}

export default EmployeeTravelForm;
