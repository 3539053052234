//in used
import CloseIcon from "@mui/icons-material/Close";
import { Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React from "react";
import SlidingPanel from "react-sliding-side-panel";
import { toast } from "react-toastify";
import { POLICIES_SERVICES } from "../../../Services/Policies/PoliciesServices";
import { useFormatCurrency } from "../../../Supporting files/CustomHooks";
import RKTextField from "../../../Supporting files/RKTextField/RKTextField";
import SelectDropdown from "../../../Supporting files/SelectDropdown/SelectDropdown";
import TextAreaField from "../../../Supporting files/TextAreaField/TextAreaField";

interface Props {
  open_status: boolean;
  attrName: any;
  value_update: Function;
  currentCdBal?: any;
}

const AddCdBalance: React.FC<Props> = ({
  open_status,
  attrName,
  value_update,
  currentCdBal,
}) => {
  const { formatCurrency } = useFormatCurrency();

  const windowURL = window.location.search;
  const params = new URLSearchParams(windowURL);
  const id = params.get("id");

  const [uploadFileStep, setUploadFileStep] = React.useState(1);
  const [uploadFile, setUploadFile] = React.useState(false);
  const [addPolicyStatus, setAddPolicyStatus] = React.useState(false);
  const [currentcd, setCurrentCd] = React.useState("");
  const [cdDate, setCdDate] = React.useState<Date | null>(new Date());
  const [cdtime, setCdTime] = React.useState("");
  const [cdamount, setCdAmount] = React.useState("");
  const [paymentmode, setPaymentMode] = React.useState("");
  const [chequenumber, setChequeNumber] = React.useState("");
  const [enterPassword, setEnterPassword] = React.useState("");
  const [cdamountData, setCdAmountData] = React.useState({
    description: "",
    addAmount: 0,
    paymentMode: "",
    transactionId: "",
  });
  const [cdamountDataWarn, setCdAmountDataWarn] = React.useState({
    description: false,
    addAmount: false,
    paymentMode: false,
    transactionId: false,
  });
  const updateMasterState = (
    attrName: any,
    value: string | boolean | number
  ) => {
    if (attrName[0] === "addCdBalance") {
      let data = cdamountData;
      let dataWarn = cdamountDataWarn;

      data = {
        ...data,
        [attrName[1]]: value,
      };
      dataWarn = { ...dataWarn, [attrName[1]]: false };

      setCdAmountData(data);
      setCdAmountDataWarn(dataWarn);
    }
  };
  const payment_data = [
    { key: "Cash", value: "Cash" },
    { key: "Cheque", value: "Cheque" },
    { key: "Demand Draft", value: "Demand Draft" },
    { key: "UPI Transaction", value: "UPI Transaction" },
    { key: "Net Banking", value: "Net Banking" },
    { key: "Credit/Debit Card", value: "Credit/Debit Card" },
  ];

  const post_cd_balance = () => {
    const onSuccess = (res: any) => {
      toast.success(" Update successfully");
      value_update("setAddCdBalance", false);
      setCdAmountData({
        description: "",
        addAmount: 0,
        paymentMode: "",
        transactionId: "",
      });
    };
    const onError = (err: any) => {
      console.log("err", err);
    };

    POLICIES_SERVICES.post_cd_balance(
      { ...cdamountData, addAmount: Number(cdamountData.addAmount) },
      onSuccess,
      onError,
      id
    );
  };

  const onSubmit = () => {
    let datawarn: any = { ...cdamountDataWarn };
    datawarn.addAmount = cdamountData.addAmount ? false : true;
    datawarn.paymentMode = cdamountData.paymentMode ? false : true;
    datawarn.description = cdamountData.description ? false : true;
    if (cdamountData.paymentMode === "Cash") {
      datawarn.transactionId = false;
    } else if (cdamountData.transactionId) {
      datawarn.transactionId = false;
    } else {
      datawarn.transactionId = true;
    }

    setCdAmountDataWarn({ ...datawarn });

    if (
      datawarn.addAmount === false &&
      datawarn.paymentMode === false &&
      datawarn.transactionId === false &&
      datawarn.description === false
    ) {
      post_cd_balance();
    }
  };

  return (
    <SlidingPanel
      type={"right"}
      isOpen={open_status}
      size={75}
      panelClassName="sliding-panel"
      backdropClicked={() => value_update("setAddCdBalance", false)}
    >
      <div>
        <Grid container spacing={3} alignItems="center">
          <Grid xs={8}>
            {uploadFileStep === 1 ? (
              <h4 className="mb-0 mt-3 text-left">Add CD Balance</h4>
            ) : (
              <h4 className="mb-0 mt-3 text-left">
                Add CD Balance Confirmation
              </h4>
            )}
          </Grid>
          <Grid xs={4} className="pt-3 text-right">
            <CloseIcon onClick={() => value_update("setAddCdBalance", false)} />
          </Grid>
          <Grid xs={12} className="mb-4">
            <hr />
          </Grid>
        </Grid>
        <div className="scrollable_area">
          {uploadFileStep === 1 ? (
            <div>
              <Grid
                container
                spacing={3}
                justifyContent="center"
                className="mb-5"
              >
                <Grid xs={12}>
                  <RKTextField
                    class_name="inputField"
                    title="Current CD Balance"
                    value={formatCurrency(currentCdBal)}
                    attrName={["addCdBalance", "cdBalance"]}
                    value_update={updateMasterState}
                    warn_status={false}
                    disabled={true}
                  />
                </Grid>

                <Grid xs={12}>
                  <RKTextField
                    class_name="inputField"
                    title="Add Amount"
                    value={formatCurrency(cdamountData.addAmount)}
                    attrName={["addCdBalance", "addAmount"]}
                    value_update={updateMasterState}
                    warn_status={cdamountDataWarn.addAmount}
                    validation_type="numeric"
                  />
                </Grid>
                <Grid xs={12}>
                  <SelectDropdown
                    class_name="inputField"
                    title="Payment Mode"
                    value={cdamountData.paymentMode}
                    attrName={["addCdBalance", "paymentMode"]}
                    value_update={updateMasterState}
                    dropdown_data={payment_data}
                    warn_status={cdamountDataWarn.paymentMode}
                  />
                </Grid>

                <Grid xs={12} className="mb-0">
                  <TextAreaField
                    class_name="inputField"
                    title="Description"
                    value={cdamountData.description}
                    attrName={["addCdBalance", "description"]}
                    value_update={updateMasterState}
                    warn_status={cdamountDataWarn.description}
                    charcterlimit={600}
                    error_messg="Enter description"
                  />
                </Grid>
                <Grid xs={12}>
                  <div
                    style={{
                      color: "#cb6d35",
                      fontSize: "12px",
                      lineHeight: "18px",
                      textAlign: "left",
                    }}
                  >
                    Remaining Character -{" "}
                    {600 - cdamountData.description.length}
                  </div>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid
                  xs={12}
                  className="ctaBtn"
                  style={{ textAlign: "center" }}
                >
                  <Button
                    variant="contained"
                    className="submitBtn"
                    onClick={onSubmit}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </div>
          ) : (
            <h1></h1>
          )}
          {uploadFileStep === 2 ? (
            <div>
              <Grid container spacing={3}>
                <Grid xs={12}>
                  <RKTextField
                    class_name="inputField"
                    title="Enter Password"
                    value={enterPassword}
                    attrName={setEnterPassword}
                    value_update={updateMasterState}
                    warn_status={false}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid
                  xs={12}
                  className="ctaBtn"
                  style={{ textAlign: "center" }}
                >
                  <Button
                    variant="contained"
                    className="submitBtn"
                    onClick={() => {
                      setUploadFileStep(3);
                    }}
                  >
                    Confirm
                  </Button>
                </Grid>
              </Grid>
            </div>
          ) : (
            <h1></h1>
          )}
          {uploadFileStep === 3 ? (
            <div>
              <Grid container spacing={3}>
                <Grid xs={12}>
                  <p className="cdbalance_text">
                    CD Balance of ₹ 1,00,000 has been added to the Policy Number
                    1259874563 of Client ID EV43
                  </p>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid
                  xs={12}
                  className="ctaBtn"
                  style={{ textAlign: "center" }}
                >
                  <Button variant="contained" className="submitBtn">
                    Close
                  </Button>
                </Grid>
              </Grid>
            </div>
          ) : (
            <h1></h1>
          )}
        </div>
      </div>
    </SlidingPanel>
  );
};
export default AddCdBalance;
