import React, { useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Box, Button, Link, Modal } from "@mui/material";

import SlidingPanel from "react-sliding-side-panel";
import RKTextField from "../../../../../../../Supporting files/RKTextField/RKTextField";

interface Props {
  open_status: boolean;
  attrName: any;
  value_update: Function;
}

const AddNewTaxGroup: React.FC<Props> = ({
  open_status,
  attrName,
  value_update,
}) => {
  const [groupname, setGroupName] = React.useState("");

  const updateMasterState = (attrName: any, value: any) => {
    attrName(value);
  };
  const grouptaxData = [
    {
      taxname: "Tax 1",
      rate: "3.5",
    },
    {
      taxname: "Tax 2",
      rate: "8",
    },
    {
      taxname: "Tax 3",
      rate: "24",
    },
    {
      taxname: "Tax 4",
      rate: "20",
    },
    {
      taxname: "Tax 5",
      rate: "15",
    },
  ];

  return (
    <SlidingPanel
      type={"right"}
      isOpen={open_status}
      size={75}
      backdropClicked={() => value_update(attrName, false)}
    >
      <div>
        <Grid container spacing={3} alignItems="center">
          <Grid xs={12}>
            <h4 className="mb-0 mt-4 ml-3">New Tax</h4>
          </Grid>

          <Grid xs={12} className="mb-0">
            <hr />
          </Grid>
        </Grid>
        <div className="scrollable_area">
          <Grid container spacing={3}>
            <Grid xs={12}>
              <RKTextField
                class_name="inputField"
                title={"Tax Group Name"}
                value={groupname}
                attrName={setGroupName}
                value_update={updateMasterState}
                warn_status={false}
              />
            </Grid>
            <Grid xs={12}>
              <h5 className="associate_tax">Associate Taxes</h5>
              <div className="tax_list_table">
                <ul className="tax_list_heading">
                  <li>Tax Name</li>
                  <li>Rate (%)</li>
                </ul>
                {grouptaxData.map((data, index) => (
                  <ul className="tax_list_subheading">
                    <li>
                      <div className="Checkbox">
                        <input type="checkbox" name="check" />
                        <label className="employee"></label>
                      </div>
                      {data.taxname}
                    </li>
                    <li>{data.rate}</li>
                  </ul>
                ))}
              </div>
            </Grid>
            <Grid xs={12}>
              <h6 className="group_note pl-4">
                Note:
                <span>
                  Taxes that are applicable only during a specified period will
                  not be listed above.
                </span>
              </h6>
            </Grid>
          </Grid>
        </div>
        <Grid container spacing={3}>
          <Grid xs={12} className="ctaBtn mt-4">
            <Button
              variant="contained"
              className="submitBtn fixedbtn"
              onClick={() => {}}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </div>
    </SlidingPanel>
  );
};

export default AddNewTaxGroup;
