import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useDrag } from "react-dnd";
import {Link } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { openRemoveModal, RemoveUnusedFields } from "../../../../../Store/actions/layoutAction";

function UnusedFieldsSideBarItems({data}) {
  //console.log(data)
  
  const [{ opacity }, drag] = useDrag({
    item: data,
    collect: monitor => ({
      opacity: monitor.isDragging() ? 0.4 : 1
    })
  });

  const dispatch = useDispatch();

  const layoutData = useSelector(({ layoutReducer }) => {
    return layoutReducer;
  });

  const removeUnusedField = (item)=>{
      dispatch(openRemoveModal({layoutData, item, modalType:"PR" }));
      // dispatch(RemoveUnusedFields(layoutData, item));
  }

  return (
    <li className={data.class_name} ref={drag} style={{ opacity }}>
      <label>{data.component.field_type}</label> <span></span> {data.component.field_lable}
      <Link className="removeIcon" onClick={()=>{
        removeUnusedField(data)
      }}>
        <DeleteForeverIcon />
      </Link>
    </li>
  )
}

export default UnusedFieldsSideBarItems