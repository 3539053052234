import React, { useEffect, useState } from "react";
import { ReactMultiEmail } from "react-multi-email";
import "react-multi-email/style.css";
import "../Supporting.scss";
import UGEmailInput from "./UGEmailInput";
import CheckIcon from "@mui/icons-material/Check";
import EditIcon from "@mui/icons-material/Edit";
import { Box, Link } from "@mui/material";

const UGEmailInputDisable = ({
    title,
    value,
    value_update,
    attrName,
    warn_status,
    class_name,
    error_messg,
    required,
    disabled,
    params,
}: {
    title: string;
    value: any;
    value_update: Function;
    attrName: any;
    warn_status: boolean | undefined;
    class_name: string;
    error_messg?: string;
    required?: boolean;
    disabled?: boolean;
    params?: any;
}) => {
    const [disbaleToggle, setDisableToggle] = useState<boolean>(false);
    const [focus, setFocus] = useState<boolean>(false);
    const edit = () => {
        setDisableToggle(!disbaleToggle);
        setFocus(!focus);
        if (disbaleToggle === true) {
            if (attrName) return params(attrName);
            params();
        }
    };


    return (
        <Box className="individualEdit">
            {disbaleToggle === false ? (
                <div
                    className={
                        value != null
                            ? value?.length === 0
                                ? "staticField nullValue"
                                : "staticField"
                            : "staticField"
                    }
                >
                    <label>{title}</label>
                    <p className="truncate_text">{value && typeof value === 'object' ? value.join() : value}</p>
                </div>
            ) : (
                <UGEmailInput
                    class_name={class_name}
                    title={title}
                    value={value}
                    attrName={attrName}
                    value_update={value_update}
                    warn_status={warn_status}
                    required={required}
                    disabled={disabled ? true : false}
                    error_messg={error_messg}
                />

            )}
            {!disabled && (
                <Link
                    className={
                        disbaleToggle === true ? "editUpdate visible" : "editUpdate"
                    }
                    onClick={edit}
                >
                    {disbaleToggle === false ? <EditIcon /> : <CheckIcon />}
                </Link>
            )}

        </Box>
        // <div onFocus={handleFocus} onBlur={handleBlur}
        //   className={`inputField react-multiemail-box ${isFocused || emails.length > 0 ? "focused" : ""} ${disabled ? "not_allowed" : ""}`}>
        //   <ReactMultiEmail
        //     emails={emails}
        //     validateEmail={(email) => {
        //       return true;
        //     }}
        //     onChange={(_emails: string[]) => {
        //       let newEmail = _emails.filter(data => data !== "undefined")
        //       value_update(attrName, newEmail);
        //       setEmails(newEmail);
        //       setIsFieldEmpty(newEmail.length === 0);
        //     }}
        //     getLabel={(
        //       email: string,
        //       index: number,
        //       removeEmail: (index: number) => void
        //     ) => {
        //       return (
        //         <div className="ug_email" data-tag key={index}>
        //           {email}
        //           <span data-tag-handle onClick={() => removeEmail(index)}>
        //             ×
        //           </span>
        //         </div>
        //       );
        //     }}
        //   />
        //   <p className={`label ${isFocused || emails.length > 0 ? "focused" : ""}`}>
        //     {title}
        //   </p>
        // </div>
    );
};

export default UGEmailInputDisable;