import { Box, Button, Link, Step, StepLabel, Stepper } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useEffect, useState } from "react";
import SlidingPanel from "react-sliding-side-panel";
import CloseIcon from "@mui/icons-material/Close";
import { ENDORSEMENT_SERVICES } from "../../../../../Services/Endorsement/EndorsementService";
import { date_time_format } from "../../../../../Supporting files/HelpingFunction";
import { useAppSelector } from "../../../../../Store/hooks";
import { RootState } from "../../../../../Store/Store";

// Defining Props interface for component
interface Props {
  open_status: boolean;
  attrName: any;
  data: any;
  value_update: Function;
}

const EndorsementDetailPopup: React.FC<Props> = ({
  open_status,
  attrName,
  value_update,
  data,
}) => {
  const { timeZone, dateFormat } = useAppSelector(
    (state: RootState) => state.userDetailsSlice.localInformation
  );
  const [prioritystatusDataEndorsment, setprioritystatusDataEndorsment] =
    useState<{ label: number; value: string }[]>([]);
  const steps = ["Pending", "Inprogress", "Active"];
  const [count, setCount] = useState<number>(1);
  useEffect(() => {
    get_status_endorsement();
  }, []);

  useEffect(() => {
    if (data && data?.status === 50) {
      // Aproved
      setCount(3);
    } else if ((data && data?.status === 54) || data?.status === 55) {
      //Requested, Sent to TPA/Insurer
      setCount(2);
    } else {
      setCount(1);
    }
  }, [data]);

  const get_status_endorsement = () => {
    const onSuccess = (res: any) => {
      let statusData = res.data.data.map((data: any) => ({
        label: data.value,
        value: data.label,
      }));
      setprioritystatusDataEndorsment(statusData);
    };
    const onError = (err: any) => {
      console.log("err", err);
    };

    ENDORSEMENT_SERVICES.get_status(onSuccess, onError);
  };

  return (
    <>
      <SlidingPanel
        type={"right"}
        isOpen={open_status}
        size={75}
        panelClassName="slidingPanel"
      >
        <>
          <Grid container spacing={2} alignItems="center">
            <Grid xs={10} className="headingTitle">
              <h3>
                <span>Endorsement Detail</span>
              </h3>
            </Grid>
            <Grid xs={2} textAlign="right">
              <Link
                className="closeIcon"
                onClick={() => {
                  value_update(attrName, false);
                }}
              >
                <CloseIcon />
              </Link>
            </Grid>
            <Grid xs={12}>
              <hr />
            </Grid>
          </Grid>
          <Box className="scrollable_area">
            <Grid container spacing={3}>
              <Grid xs={12}>
                <div className="cashlessClaim">
                  <div className="request_heading">
                    <h5>Status</h5>
                    <span className="pending">
                      {
                        prioritystatusDataEndorsment?.find(
                          (item: any) => item.label === data?.status
                        )?.value
                      }
                    </span>
                  </div>
                </div>

                {/* <Stepper activeStep={count} alternativeLabel>
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper> */}
                {/* <ul className="status_data">
                  <li>
                    <div className="circle">
                      <span className="active">Active</span>
                    </div>
                  </li>
                  <li>
                    <div className="circle">
                      <span className="pending">Pending</span>
                    </div>
                  </li>
                  <li>
                    <div className="circle">
                      <span className="rejected">Rejected</span>
                    </div>
                  </li>
                  <li>
                    <div className="circle">
                      <span className="inprogress">Inprogress</span>
                    </div>
                  </li>
                </ul> */}
              </Grid>
              {data?.clientId && (
                <Grid xs={6}>
                  <p className="health_ecard">
                    Client Id
                    <span>{data?.clientId}</span>
                  </p>
                </Grid>
              )}
              {data?.memberId && (
                <Grid xs={6}>
                  <p className="health_ecard">
                    Member Id
                    <span>{data?.memberId + "-" + data?.memberName}</span>
                  </p>
                </Grid>
              )}
              {data?.policy && (
                <Grid xs={6}>
                  <p className="health_ecard">
                    Policy <span>{data?.policy}</span>
                  </p>
                </Grid>
              )}
              {/* {data?.policyId && (
                <Grid xs={6}>
                  <p className="health_ecard">
                    Policy Id <span>{data?.policyId}</span>
                  </p>
                </Grid>
              )} */}
              {data?.policyNumber && (
                <Grid xs={6}>
                  <p className="health_ecard">
                    Policy Number
                    <span>{data?.policyNumber}</span>
                  </p>
                </Grid>
              )}
              {data?.type && (
                <Grid xs={6}>
                  <p className="health_ecard">
                    Endorsement Type
                    <span>{data?.type}</span>
                  </p>
                </Grid>
              )}
              {data?.endorsementFor && (
                <Grid xs={6}>
                  <p className="health_ecard">
                    Endorsement Value
                    <span>{data?.endorsementFor}</span>
                  </p>
                </Grid>
              )}
              {!(
                data?.endorsementFor.includes("Member") ||
                data?.endorsementFor.includes("Nominee")
              ) && (
                <>
                  <Grid xs={6}>
                    <p className="health_ecard">
                      Old Value: <span>{data?.old}</span>
                    </p>
                  </Grid>

                  <Grid xs={6}>
                    <p className="health_ecard">
                      New Value:{" "}
                      <span>
                        {data?.endorsementFor === "Name"
                          ? `${data?.new?.firstName} ${data?.new?.lastName}`
                          : data?.endorsementFor === "Contact Number"
                          ? data?.new?.contactNumber
                          : data?.endorsementFor === "Gender"
                          ? data?.new?.gender
                          : data?.endorsementFor === "Email"
                          ? data?.new?.email
                          : data?.endorsementFor === "Date of Birth"
                          ? data?.new?.dob
                          : data?.endorsementFor === "Personal Address"
                          ? `${data?.new?.street} ${data?.new?.area} ${data?.new?.city} ${data?.new?.state} ${data?.new?.country}  ${data?.new?.zip}`
                          : ""}
                      </span>
                    </p>
                  </Grid>
                </>
              )}

              {data?.insurer && (
                <Grid xs={6}>
                  <p className="health_ecard">
                    Insurer
                    <span>{data?.insurer}</span>
                  </p>
                </Grid>
              )}
              {data?.endorsementId && (
                <Grid xs={6}>
                  <p className="health_ecard">
                    Endorsment Id
                    <span>{data?.endorsementId}</span>
                  </p>
                </Grid>
              )}
              {data?.nomineeDetail !== null && (
                <>
                  <Grid xs={12}>
                    <div className="cashlessClaim">
                      <div className="request_heading">
                        <h5>Nominee Detail</h5>
                      </div>
                    </div>
                  </Grid>

                  {data?.nomineeDetail.fullName && (
                    <Grid xs={6}>
                      <p className="health_ecard">
                        Nominee Name
                        <span>{data?.nomineeDetail.fullName}</span>
                      </p>
                    </Grid>
                  )}
                  {data?.nomineeDetail.relation && (
                    <Grid xs={6}>
                      <p className="health_ecard">
                        Relation
                        <span>{data?.nomineeDetail.relation}</span>
                      </p>
                    </Grid>
                  )}

                  {data?.nomineeDetail.dob && (
                    <Grid xs={6}>
                      <p className="health_ecard">
                        Date Of Birth
                        <span>
                          {date_time_format(
                            data.nomineeDetail.dob,
                            dateFormat,
                            timeZone
                          )}
                        </span>
                      </p>
                    </Grid>
                  )}
                  {data?.nomineeDetail.age && (
                    <Grid xs={6}>
                      <p className="health_ecard">
                        Age
                        <span>{data?.nomineeDetail.age}</span>
                      </p>
                    </Grid>
                  )}
                </>
              )}

              {/* <Box>
                <Grid xs={6}>
                  <p className="health_ecard">
                    Client Id
                    <span>EV 005 - Evervent</span>
                  </p>
                </Grid>
                <Grid xs={6}>
                  <p className="health_ecard">
                    Member Id
                    <span>EV 065 - Vikas Saini</span>
                  </p>
                </Grid>
                <Grid xs={6}>
                  <p className="health_ecard">
                    Policy Number <span>PROH89346794567454</span>
                  </p>
                </Grid>
                <Grid xs={6}>
                  <p className="health_ecard">
                    Endorsement Value
                    <span>Address</span>
                  </p>
                </Grid>
                <Grid xs={6}>
                  <p className="health_ecard">
                    Endorsement Type
                    <span>Non Commercial Endorsement</span>
                  </p>
                </Grid>
                <Grid xs={6}>
                  <p className="health_ecard">
                    Old Value: <span>Female</span>
                  </p>
                </Grid>
                <Grid xs={6}>
                  <p className="health_ecard">
                    New Value: <span>Male</span>
                  </p>
                </Grid>
                <Grid xs={6}>
                  <p className="health_ecard">
                    Policy Id
                    <span>PB152412- Group Health</span>
                  </p>
                </Grid>
                <Grid xs={6}>
                  <p className="health_ecard">
                    Insurer
                    <span>Reliance General Insurance</span>
                  </p>
                </Grid>
                <Grid xs={6}>
                  <p className="health_ecard">
                    Old Personal Address
                    <span>
                      Next57,341,phase 9, Industrial area Mohali, Punjab, India,{" "}
                    </span>
                  </p>
                </Grid>
                <Grid xs={6}>
                  <p className="health_ecard">
                    Building/Street Name
                    <span>Next62,345,phase 9,</span>
                  </p>
                </Grid>
                <Grid xs={6}>
                  <p className="health_ecard">
                    Area Name
                    <span> Industrial area</span>
                  </p>
                </Grid>
                <Grid xs={6}>
                  <p className="health_ecard">
                    Country
                    <span>India</span>
                  </p>
                </Grid>
                <Grid xs={6}>
                  <p className="health_ecard">
                    State
                    <span>UK</span>
                  </p>
                </Grid>
                <Grid xs={6}>
                  <p className="health_ecard">
                    City
                    <span>Dehradun</span>
                  </p>
                </Grid>
                <Grid xs={6}>
                  <p className="health_ecard">
                    ZipCode
                    <span>248142</span>
                  </p>
                </Grid>
              </Box> */}
            </Grid>
          </Box>
          <Grid container spacing={3}>
            <Grid xs={12} className="ctaBtn mt-4">
              {/* <Button
                variant="contained"
                className="submitBtn fixedbtn"
                onClick={() => {}}
              >
                Submit
              </Button> */}
            </Grid>
          </Grid>
        </>
      </SlidingPanel>
    </>
  );
};

export default EndorsementDetailPopup;
