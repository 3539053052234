import { useState, useEffect } from "react";
import { Box, Link, Modal } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React from "react";
import "../PolicyFeatures/PolicyFeatures.scss";
import { EMPLOYEE_ENROLMENT_SERVICES } from "../../../../../../Services/Employee/EmployeeEnrolmentService";

interface Props {
  open_status: boolean;
  attrName: any;
  value_update: Function;
  policyId: string;
  maxWidth?: string;
}

const PolicyFeatures: React.FC<Props> = ({
  open_status,
  attrName,
  value_update,
  policyId,
  maxWidth,
}) => {
  const [policyFeature, setPolicyFeature] = useState<Array<any>>([]);
  useEffect(() => {
    get_features();
  }, [policyId]);

  const updateMasterState = (attrName: any, value: any) => {
    attrName(value);
  };

  const get_features = () => {
    const onSuccess = (res: any) => {
      // setPolicyFeature(res.data.data[0].featureData);
      setPolicyFeature(res.data.data);
    };

    const onError = (err: any) => { };
    if (policyId) {
      EMPLOYEE_ENROLMENT_SERVICES.get_policy_features_exclusions(
        onSuccess,
        onError,
        policyId
      );
    }
  };


  return (
    <Modal open={open_status} className="modalWrapper">
      <Box className="modalInner xxl-width" width={maxWidth ?? ""}>
        <Grid container spacing={2}>
          <Grid xs={12}>
            <Link
              className="close-button"
              onClick={() => {
                attrName(false);
              }}
            />
            <h5 className="popup-heading text-left mb-2">Features</h5>
          </Grid>
        </Grid>
        <Grid container spacing={2} textAlign="center">
          <Grid xs={12}>
            <ul className="features_box">
              {policyFeature?.length > 0 && policyFeature[0]?.name ? (
                <>
                  {policyFeature[0]?.featureData?.map((item: any) => {
                    return (
                      <>
                        {item?.features?.map((item1: any) => {
                          return item1?.featureDetails ? (
                            <>
                              <li className="features_list">
                                <div className="feature_inerlist">
                                  <span className="medical"></span>
                                  <div className="feature_content">
                                    <h5>{item?.category}</h5>
                                    <ul>
                                      <li>
                                        {item1?.featureDetails?.name}{" "}
                                        <span>
                                          {item1?.value}{" "}
                                          {item1?.featureDetails?.unit ===
                                            "Percentage"
                                            ? "%"
                                            : item1?.featureDetails?.unit ===
                                              "Currency"
                                              ? ""
                                              : item1?.featureDetails?.unit ===
                                                "Free Text"
                                                ? item1?.featureDetails?.customUnitType
                                                : item1?.featureDetails?.unit}
                                        </span>
                                      </li>
                                      <p>{item1?.featureDetails?.description}</p>
                                    </ul>
                                  </div>
                                </div>
                              </li>
                            </>
                          ) : (
                            <>
                              <li className="features_list">
                                <div className="feature_inerlist">
                                  <span className="medical"></span>
                                  <div className="feature_content">
                                    <h5>{item?.category}</h5>
                                    <ul>
                                      <li>
                                        {item1?.name}{" "}
                                        <span>
                                          {item1?.value}{" "}
                                          {item1?.unit === "Percentage"
                                            ? "%"
                                            : item1?.unit === "Currency"
                                              ? ""
                                              : item1?.unit === "Free Text"
                                                ? item1?.customUnitType
                                                : item1?.unit}
                                        </span>
                                      </li>
                                      <p>{item1?.description}</p>
                                    </ul>
                                  </div>
                                </div>
                              </li>
                            </>
                          )
                        })}
                      </>
                    )
                  })}
                </>
              ) : (
                <>
                  {policyFeature?.map((item: any) => {
                    return (
                      <>
                        {item?.featureData?.map((feaData: any) => (
                          <>
                            {feaData?.features?.map((item1: any) => {
                              return item1?.featureDetails ? (
                                <>
                                  <li className="features_list">
                                    <div className="feature_inerlist">
                                      <span className="medical"></span>
                                      <div className="feature_content">
                                        <h5>{feaData?.category}</h5>
                                        <ul>
                                          <li>
                                            {item1?.featureDetails?.name}{" "}
                                            <span>
                                              {item1?.value}{" "}
                                              {item1?.featureDetails?.unit ===
                                                "Percentage"
                                                ? "%"
                                                : item1?.featureDetails?.unit ===
                                                  "Currency"
                                                  ? ""
                                                  : item1?.featureDetails?.unit ===
                                                    "Free Text"
                                                    ? item1?.featureDetails?.customUnitType
                                                    : item1?.featureDetails?.unit}
                                            </span>
                                          </li>
                                          <p>{feaData?.description}</p>
                                        </ul>
                                      </div>
                                    </div>
                                  </li>
                                </>
                              ) : (
                                <>
                                  <li className="features_list">
                                    <div className="feature_inerlist">
                                      <span className="medical"></span>
                                      <div className="feature_content">
                                        <h5>{feaData?.category}</h5>
                                        <ul>
                                          <li>
                                            {item1?.name}{" "}
                                            <span>
                                              {item1?.value}{" "}
                                              {item1?.unit === "Percentage"
                                                ? "%"
                                                : item1?.unit === "Currency"
                                                  ? ""
                                                  : item1?.unit === "Free Text"
                                                    ? item1?.customUnitType
                                                    : item1?.unit}
                                            </span>
                                          </li>
                                          <p>{item1?.description}</p>
                                        </ul>
                                      </div>
                                    </div>
                                  </li>
                                </>
                              )
                            })}

                          </>
                        ))}

                      </>
                    )
                  })}
                </>
              )}
            </ul>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default PolicyFeatures;
