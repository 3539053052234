import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { persistReducer, persistStore } from "redux-persist";
import sessionStorage from "redux-persist/lib/storage/session";
import thunk from "redux-thunk";

// Importing individual slice reducers
import { addPolicySlice } from "./Slice_Reducer/AddNewPolicy/AddPolicySlice";

// Importing the previous store
import { layoutReducer, modalReducer } from "./reducers/index";
import { bulkUploadSlice } from "./Slice_Reducer/BulkUpload/BulkUploadSlice";
import { userDetailsSlice } from "./Slice_Reducer/UserLogin/UserDetailsSlice";
import { permissionSlice } from "./Slice_Reducer/Permission/PermissionSlice";

// Combine the previous reducers with the new reducer
const rootReducer = combineReducers({
  modalReducer,
  layoutReducer,
  addNewPolicy: addPolicySlice.reducer,
  bulkUpload: bulkUploadSlice.reducer,
  userDetailsSlice: userDetailsSlice.reducer,
  permissionSlice: permissionSlice.reducer,
});

// Configure Redux Persist with session storage
const persistConfig = {
  key: "root",
  storage: sessionStorage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// Create the new Redux store
const store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(thunk))
);

// Create the Redux Persist store
const persistor = persistStore(store);

export { store, persistor };
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
