import "./NewLoader.scss";

const MobileLoader = () => {

    return (
        <div className="mob_loader">
            <div className="loader_box">
                <div className="spinner">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        </div>
    )
}

export default MobileLoader;