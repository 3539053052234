import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Box, Button, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PERSONAL_SETTINGS_SERVICES } from "../../../../../../../Services/customModule/personalSettingsServices";
import { useAppSelector } from "../../../../../../../Store/hooks";
import DatePicker from "../../../../../../../Supporting files/DatePicker/DatePicker";
import { get_city_list_via_name, get_country_list, get_state_list, uploadImage, validateEmail, validateMobileNumber, validatePincode } from "../../../../../../../Supporting files/HelpingFunction";
import RKTextField from "../../../../../../../Supporting files/RKTextField/RKTextField";
import SearchDropdown from "../../../../../../../Supporting files/SearchDropdown/SearchDropdown";
import "../../../../../../../formLayout.scss";
import { toast } from "react-toastify";
import { ALL_ROUTES } from "../../../../../../../Routes/all_routes";

function EditPersonalDetails() {
  const navigate = useNavigate();
  const userType = useAppSelector((state) => state.userDetailsSlice.userType);
  const [selectedCountryStateCity, setselectedCountryStateCity] = useState<{
    country: any;
    state: any;
    city: any;
  }>({
    country: "",
    state: "",
    city: "",
  });
  const { BROKER } = useAppSelector((state:any) => state.permissionSlice);
  const [branchEditPermission, setbranchEditPermission] = useState(false);
  const [countryData, setCountryData] = useState<Array<{ label: string; id: string }>>([]);
  const [departmentData, setdepartmentData] = useState<Array<{ label: string; id: string }>>([]);
  const [stateData, setStateData] = useState<Array<{ label: string; id: string }>>([]);
  const [cityData, setCityData] = useState<Array<{ label: string; id: string }>>([]);
  const [personalSetting, setpersonalSetting] = useState<any>()
  const [personalSettingdata, setpersonalSettingdata] = useState({
    profilePicture: "",
    name: "",
    designation:"",
    branch:"",
    department: "",
    roleName: "",
    gender: "",
    dob: "",
    phoneNumber: "",
    mobileNumber: "",
    email: "",
    street: "",
    area: "",
    state: "",
    city: "",
    pinCode: "",
    country: "",
  });
  const [displayLogo, setDisplayLogo] = useState<{ logo: string; warning: boolean; }>({ logo: "", warning: false });
  const [personalSettingdataWarn, setpersonalSettingdataWarn] = useState({
    logo: false,
    name: false,
    designation: false,
    branch:false,
    department: false,
    roleName: false,
    gender: false,
    dob: false,
    phoneNumber: false,
    mobileNumber: false,
    email: false,
    street: false,
    area: false,
    state: false,
    city: false,
    pinCode: false,
    country: false,
  });
  const gender_data = [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
  ];

  useEffect(() => {
    setbranchEditPermission(
      BROKER?.personalSetting?.edit === undefined
        ? false : BROKER?.personalSetting?.edit
    );

  }, []);

  const updateMasterState = (attrName: any, value: any) => {
    let keys = attrName[0];
    let mainstate = attrName[1];
    if (keys === "inputfields") {
      setpersonalSettingdata({ ...personalSettingdata, [mainstate]: value });
      setpersonalSettingdataWarn({ ...personalSettingdataWarn, [mainstate]: false });
      let label = attrName[2];
      if (label === "label_field") {
        if (mainstate === "gender") {
          setpersonalSettingdata({ ...personalSettingdata, [mainstate]: value?.label, });
        } else {
          setselectedCountryStateCity({ ...selectedCountryStateCity, [mainstate]: value?.label, });
        }
        if (mainstate === "country") {
          let country = { id: "", label: value?.label };
          get_state_list(country, (cb: any) => {
            setStateData(cb);
          });
          setpersonalSettingdata({
            ...personalSettingdata,
            [mainstate]: value?.label, state: "", city: ""
          });
        } else if (mainstate === "state") {
          let state = { id: "", label: value?.label };
          let country = { id: "", label: selectedCountryStateCity.country };
          get_city_list_via_name(country, state, (cb: any) => {
            setCityData(cb);
          });
          setpersonalSettingdata({
            ...personalSettingdata,
            [mainstate]: value?.label, city: ""
          });
        } else if (mainstate === "city") {
          setpersonalSettingdata({ ...personalSettingdata, [mainstate]: value?.label, });
        }
      }
    }
  };

  //get personal setting data
  const get_personal_settings = () => {
    const onSuccess = (res: any) => {
      const data = res.data.data;
      setpersonalSetting(data);
      if (userType === "BROKER") {
        setpersonalSettingdata({
          profilePicture: data?.profilePicture,
          name: data?.primaryContact?.name,
          department:data?.department?.departmentName,
          branch:data?.branch?.locationName,
          designation: data?.primaryContact?.designation,
          roleName: data?.roleName,
          gender: data?.gender,
          dob: data?.dob,
          phoneNumber: data?.addressDetails?.phoneNumber,
          mobileNumber: data?.addressDetails?.mobileNumber,
          email: data?.email,
          street: data?.addressDetails?.address1,
          area: data?.addressDetails?.address2,
          state: data?.addressDetails?.state,
          city: data?.addressDetails?.city,
          pinCode: data?.addressDetails?.pinCode,
          country: data?.addressDetails?.country,
        })
      }

      // setpersonalSettingdata(res.data.data);
      setDisplayLogo({ logo: data.profilePicture, warning: true });
      setselectedCountryStateCity({
        country: data.addressDetails.country,
        state: data.addressDetails.state,
        city: data.addressDetails.city,
      });
    };
    const onError = (err: any) => {
      console.log("Branches", err);
    };
    PERSONAL_SETTINGS_SERVICES.get_personal_settings(onSuccess, onError);
  };

  //get personal setting data
  const update_personal_settings = () => {
     
    if(!branchEditPermission) return toast.warn("You are not allowed to Update personal settings")
    let data: any = personalSettingdataWarn;
    data.logo = personalSettingdata.profilePicture ? false : true;
    data.name = personalSettingdata.name ? false : true;
    data.roleName = personalSettingdata.roleName ? false : true;
    data.gender = personalSettingdata.gender ? false : true;
    data.dob = personalSettingdata.dob ? false : true;
    data.phoneNumber = validateMobileNumber(personalSettingdata.phoneNumber) ? false : true;
    data.mobileNumber = validateMobileNumber(personalSettingdata.mobileNumber) ? false : true;
    data.email = validateEmail(personalSettingdata.email) ? false : true;
    data.street = personalSettingdata.street ? false : true;
    data.area = personalSettingdata.area ? false : true;
    data.state = personalSettingdata.state ? false : true;
    data.city = personalSettingdata.city ? false : true;
    data.pinCode =  validatePincode(personalSettingdata.pinCode) ? false : true;
    data.country = personalSettingdata.country ? false : true;
    setpersonalSettingdataWarn({ ...data });
    let hasError = false;
    Object.values(data)?.map((item) => {
      if (item) {
        hasError = true
      }
    })
    if (!hasError) {
      let sman = { ...personalSetting };
      sman = {
        ...sman,
        profilePicture: personalSettingdata.profilePicture ?? sman.addressDetails.logo,
        roleName: personalSettingdata.roleName ?? sman.addressDetails.roleName,
        gender: personalSettingdata.gender ?? sman.addressDetails.gender,
        dob: personalSettingdata.dob ?? sman.addressDetails.dob,
        primaryContact: {
          ...sman.primaryContact,
          name: personalSettingdata.name ?? sman.primaryContact.name,
          designation: personalSettingdata?.designation ?? sman?.primaryContact?.designation,
          email: personalSettingdata.email ?? sman.primaryContact.email,
        },
        addressDetails: {
          ...sman.addressDetails,
          phoneNumber: personalSettingdata.phoneNumber ?? sman.addressDetails.phoneNumber,
          mobileNumber: personalSettingdata.mobileNumber ?? sman.addressDetails.mobileNumber,
          address1: personalSettingdata.street ?? sman.addressDetails.address1,
          address2: personalSettingdata.area ?? sman.addressDetails.address2,
          country: personalSettingdata.country ?? sman.addressDetails.country,
          state: personalSettingdata.state ?? sman.addressDetails.state,
          city: personalSettingdata.city ?? sman.addressDetails.city,
          pinCode: personalSettingdata.pinCode?.toString() ?? sman.addressDetails.pinCode?.toString(),
        },
      }
      delete sman._id;
      delete sman.branch;
      delete sman.department;
      delete sman.email;


      const onSuccess = (res: any) => {
        navigate(-1);
        toast.success(" Updated successfully");
      };
      const onError = (err: any) => {
        console.log("err", err);
      };
      PERSONAL_SETTINGS_SERVICES.update_personal_settings(
        sman,
        onSuccess,
        onError
      );
    }
  };

  //get department data
  const get_department_list = () => {
    const onSuccess = (res: any) => {
      setdepartmentData(res.data.data);
    };
    const onError = (err: any) => {
      console.log("department", err);
    };
    PERSONAL_SETTINGS_SERVICES.department_list(onSuccess, onError);
  };

  useEffect(() => {
    get_personal_settings();
    get_country_list((cb: any) => {
      setCountryData(cb);
    });
    get_department_list();
  }, []);

  return (
    <Box className="layoutWrapper">
      {/* Page header */}
      <Grid container spacing={3} className="pageHeader p-0">
        <Grid xs={6} className="pageTitle">
          <Link
            className="backStep"
            onClick={() => {
              navigate(`${ALL_ROUTES.SETTING_MODULE_FORM}?page=personalSettings&section=general`);
            }}>
            <ChevronLeftIcon />
          </Link>
          <div>
            <h3>Edit</h3>
          </div>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid xs={12}>
          <Grid container spacing={3}>
            <Grid xs className="rightSection">
              <Box className="detailSection">
                <div className="sectionTitle">
                  <h4>Personal Details</h4>
                </div>

                <Grid container spacing={3}>
                  <Grid xs={3} className="align-item-center text-center">
                    <div className="profile_section">
                      <h6 className="mb-2">Profile Picture</h6>
                      <div className="profile_inner_section">
                        <img
                          src={
                            displayLogo.logo
                              ? displayLogo.logo
                              : "images/nobody_original.svg"
                          }
                          alt=""
                          className="edit_profile_logo"
                        />
                        <Link className="upload_img" component="label">
                          <input
                            hidden
                            accept="image/*"
                            multiple
                            type="file"
                            onChange={(e: any) => {
                              let displaydata: any = displayLogo;
                              displaydata.warning = displayLogo.logo !== "" ? true : false;
                              setDisplayLogo({ ...displaydata });
                              const file = e.target.files[0];
                              uploadImage(file, (base64Image: any, base64String: any) => {
                                setpersonalSettingdata({
                                  ...personalSettingdata,
                                  profilePicture: base64Image,
                                });
                                setpersonalSettingdataWarn({
                                  ...personalSettingdataWarn, logo: false
                                })
                                setDisplayLogo({
                                  logo: base64String,
                                  warning: false,
                                });
                              }
                              );
                            }}
                          />
                          <img src="images/edit_image_icon.svg" alt="" />
                        </Link>
                        {personalSettingdataWarn.logo === true ? (
                          <span
                            className="inputField"
                            style={{
                              color: "#eb5757",
                              fontSize: "11px",
                              position: "relative",
                              right: "0",
                              bottom: "-56px",
                            }}>
                            Update Profile Picture
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </Grid>
                  <Grid xs={9}>
                    <Box>
                      <Grid container spacing={3}>
                        <Grid sm={4} md={4} lg={4} xl={4}>
                          <RKTextField
                          required
                          
                            class_name="inputField"
                            title={"Name"}
                            value={personalSettingdata.name}
                            attrName={["inputfields", "name"]}
                            value_update={updateMasterState}
                            warn_status={personalSettingdataWarn.name}
                          />
                        </Grid>
                        <Grid xs={4} md={4} lg={4} xl={4}>
                          <RKTextField
                          required
                            class_name="inputField"
                            title={"Department"}
                            value={personalSettingdata.department}
                            attrName={["inputfields", "department"]}
                            value_update={updateMasterState}
                            warn_status={personalSettingdataWarn.department}
                            disabled
                          />
                        </Grid>
                        <Grid xs={4} md={4} lg={4} xl={4}>
                          <RKTextField
                          required
                            class_name="inputField"
                            title={"Branch"}
                            value={personalSettingdata.branch}
                            attrName={["inputfields", "branch"]}
                            value_update={updateMasterState}
                            warn_status={personalSettingdataWarn.branch}
                            disabled
                          />
                        </Grid>
                        <Grid xs={4} md={4} lg={4} xl={4}>
                          <RKTextField
                          required
                            class_name="inputField"
                            title={"Role"}
                            value={personalSettingdata.roleName}
                            attrName={["inputfields", "roleName"]}
                            value_update={updateMasterState}
                            warn_status={personalSettingdataWarn.roleName}
                            disabled
                          />
                        </Grid>
                        <Grid xs={4} md={4} lg={4} xl={4}>
                          <SearchDropdown
                          required
                            class_name="inputField"
                            title="Gender"
                            value={personalSettingdata.gender}
                            attrName={["inputfields", "gender", "label_field"]}
                            value_update={updateMasterState}
                            data={gender_data}
                            warn_status={personalSettingdataWarn.gender}
                            
                          />
                        </Grid>
                        <Grid xs={4} md={4} lg={4} xl={4}>
                          <DatePicker
                          required
                            class_name="inputField"
                            title="Date of Birth"
                            value={personalSettingdata.dob ?? null}
                            attrName={["inputfields", "dob"]}
                            value_update={updateMasterState}
                            error_message="Choose Date of Birth"
                            warn_status={personalSettingdataWarn.dob}
                            max_date={new Date()}
                          />
                        </Grid>
                        <Grid sm={4} md={4} lg={4} xl={4}>
                          <RKTextField
                          required
                            class_name="inputField"
                            title={"Phone Number"}
                            value={personalSettingdata.phoneNumber}
                            attrName={["inputfields", "phoneNumber"]}
                            value_update={updateMasterState}
                            warn_status={personalSettingdataWarn.phoneNumber}
                            error_messg={personalSettingdata?.phoneNumber?.length > 0 ? "Enter valid Phone Number" :"Enter Phone Number" }
                            validation_type="numeric"
                            charcterlimit={10}
                          />
                        </Grid>
                        <Grid sm={4} md={4} lg={4} xl={4}>
                          <RKTextField
                          required
                            class_name="inputField"
                            title={"Office Number"}
                            value={personalSettingdata.mobileNumber}
                            attrName={["inputfields", "mobileNumber"]}
                            value_update={updateMasterState}
                            warn_status={personalSettingdataWarn.mobileNumber}
                            error_messg={personalSettingdata?.mobileNumber?.length > 0 ? "Enter valid Office Number" :"Enter Office Number" }
                            validation_type="numeric"
                            charcterlimit={10}
                          />
                        </Grid>
                        <Grid sm={4} md={4} lg={4} xl={4}>
                          <RKTextField
                          required
                            class_name="inputField"
                            title={"Username / ID"}
                            value={personalSettingdata.email}
                            attrName={["inputfields", "email"]}
                            value_update={updateMasterState}
                            warn_status={personalSettingdataWarn.email}
                            error_messg="Email should be valid"
                          disabled
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>

                {/* Address Details */}
                <Grid container spacing={3}>
                  <Grid xs={12}>
                    <h5 className="subHeading">Address Details</h5>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid sm={6} md={6} lg={6} xl={6}>
                    <RKTextField
                    required
                      class_name="inputField"
                      title={"Street Name"}
                      value={personalSettingdata.street}
                      attrName={["inputfields", "street"]}
                      value_update={updateMasterState}
                      warn_status={personalSettingdataWarn.street}
                    />
                  </Grid>
                  <Grid sm={6} md={6} lg={6} xl={6}>
                    <RKTextField
                    required
                      class_name="inputField"
                      title={"Area Name"}
                      value={personalSettingdata.area}
                      attrName={["inputfields", "area"]}
                      value_update={updateMasterState}
                      warn_status={personalSettingdataWarn.area}
                    />
                  </Grid>
                  <Grid sm={3} md={3} lg={3} xl={3}>
                    <SearchDropdown
                    required
                      class_name="inputField"
                      title="Country"
                      value={personalSettingdata.country}
                      attrName={["inputfields", "country", "label_field"]}
                      value_update={updateMasterState}
                      data={countryData}
                      warn_status={personalSettingdataWarn.country}
                      error_message="Select country"
                    />
                  </Grid>
                  <Grid sm={3} md={3} lg={3} xl={3}>
                    <SearchDropdown
                    required
                      class_name="inputField"
                      title="State"
                      value={personalSettingdata.state}
                      attrName={["inputfields", "state", "label_field"]}
                      value_update={updateMasterState}
                      data={stateData}
                      warn_status={personalSettingdataWarn.state}
                      error_message="Select state"
                    />
                  </Grid>
                  <Grid sm={3} md={3} lg={3} xl={3}>
                    <SearchDropdown
                    required
                      class_name="inputField"
                      title="City"
                      value={personalSettingdata.city}
                      attrName={["inputfields", "city", "label_field"]}
                      value_update={updateMasterState}
                      data={cityData}
                      warn_status={personalSettingdataWarn.city}
                      error_message="Select city"
                    />
                  </Grid>
                  <Grid sm={3} md={3} lg={3} xl={3}>
                    <RKTextField
                    required
                      class_name="inputField"
                      title={"Pincode"}
                      value={personalSettingdata.pinCode}
                      attrName={["inputfields", "pinCode"]}
                      value_update={updateMasterState}
                      warn_status={personalSettingdataWarn.pinCode}
                      error_messg={personalSettingdata?.pinCode?.length > 0 ? "Enter valid Pincode" :"Enter Pincode" }
                      validation_type="numeric"
                      charcterlimit={6}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* Footer Section */}
      <Grid container spacing={3} className="footerSection">
        <Grid xs={12} className="ctaBtn">
        <Button
                        variant="outlined"
                        className="borderBtn"
                        onClick={() => {
                          navigate(-1);
                        }}
                      >
                        Cancel
                      </Button>
          <Button
            onClick={update_personal_settings}
            variant="contained"
            className="submitBtn">
            Update
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

export default EditPersonalDetails;
