import { EMPLOYEE_URL_CONSTANTS } from "../../APIVar/EmployeeUrlConstant";
import { EMPLOYER_URL_CONSTANTS } from "../../APIVar/EmployerUrlConstant";
import { URL_CONSTANTS } from "../../APIVar/URLConstants";
import { DeleteAPI, GetAPI, PatchAPI, PostAPI } from "../API";
import { assignPolicyDTO } from "../DTO/Employee/EmployeePoliciesDTO";
import { PolicyDeassignEntityType } from "../Enum/EEmployeePolicyDetail";

const get_assignable_policies = (
  onSuccess: (res: any) => void,
  onError: (err: any) => void,
  clientId: string
) => {
  GetAPI.call(
    `${URL_CONSTANTS.get_employee_assignable_policies(clientId)}`,
    onSuccess,
    {},
    onError
  );
};

const get_dep_assignable_policies = (
  onSuccess: (res: any) => void,
  onError: (err: any) => void,
  empId: string
) => {
  GetAPI.call(
    `${URL_CONSTANTS.get_dep_assignable_policies(empId)}`,
    onSuccess,
    {},
    onError
  );
};

const save_assigned_policies = (
  dto: assignPolicyDTO,
  onSuccess: any,
  onError: any,
  empId: string
) => {
  PatchAPI.call(
    `${URL_CONSTANTS.assign_employee_policies(empId)}`,
    dto,
    onSuccess,
    {},
    onError
  );
};

const get_assigned_policies = (onSuccess: any, onError: any, empId: string) => {
  GetAPI.call(
    `${URL_CONSTANTS.get_assigned_policies(empId)}`,
    onSuccess,
    {},
    onError
  );
};

const add_member_to_policy = (
  onSuccess: any,
  onError: any,
  dto: any,
  empId: string,
  code: number,
  isDep: string,
  usertypeinfo: string,
) => {
  PostAPI.call(
    `${URL_CONSTANTS.add_member_to_policy(empId, code, isDep, usertypeinfo)}`,
    dto,
    onSuccess,
    {},
    onError
  );
};

const get_members = (onSuccess: any, onError: any, empId: string, usertypeinfo: string) => {
  GetAPI.call(`${URL_CONSTANTS.get_members(empId, usertypeinfo)}`, onSuccess, onError);
};
const get_nominees = (onSuccess: any, onError: any, empId: string, usertypeinfo: string) => {
  GetAPI.call(`${URL_CONSTANTS.get_nominees(empId, usertypeinfo)}`, onSuccess, onError);
};

const deassign_employee_policy = (
  onSuccess: any,
  onError: any,
  empId: string,
  relationId: string
) => {
  DeleteAPI.call(
    `${URL_CONSTANTS.deassign_employee_policy(empId, relationId)}`,
    {},
    onSuccess,
    {},
    onError
  );
};

const create_policy_ecard = (
  onSuccess: any,
  onError: any,
  dto: any,
  empId: string,
  usertypeinfo: string
) => {
  PostAPI.call(
    `${URL_CONSTANTS.create_policy_ecard(empId, usertypeinfo)}`,
    dto,
    onSuccess,
    {},
    onError
  );
};

const deassign_entity_policy = (
  onSuccess: any,
  onError: any,
  type: PolicyDeassignEntityType,
  empId: string,
  policyId: string,
  relationId: string,
  usertypeinfo: string,
) => {
  DeleteAPI.call(
    `${URL_CONSTANTS.policy_entity_deassign(
      type,
      empId,
      policyId,
      relationId,
      usertypeinfo
    )}`,
    {},
    onSuccess,
    {},
    onError
  );
};

const create_nominee_in_policy = (
  onSuccess: any,
  onError: any,
  empId: string,
  dto: any,
  usertypeinfo: string,
  code?: string,
) => {
  PostAPI.call(
    `${URL_CONSTANTS.create_nominee_in_policy(empId, usertypeinfo, code || "0")}`,
    dto,
    onSuccess,
    {},
    onError
  );
};

const CreateEndorsementForNominee = (
  onSuccess: any,
  onError: any,
  dto: any,
  userType: string
) => {
  PostAPI.call(
    `${URL_CONSTANTS.CreateEndorsementForNominee(userType)}`,
    dto,
    onSuccess,
    {},
    onError
  );
};

const policy_grade_dropdown = (
  onSuccess: any,
  onError: any,
  policyId: string,
  userType: string
) => {
  GetAPI.call(
    `${URL_CONSTANTS.policy_grade_dropdown(policyId, userType)}`,
    onSuccess,
    {},
    onError
  );
};

const add_member_to_assign_policy = (
  onSuccess: any,
  onError: any,
  dto: any,
  apiUrl: string
) => {
  PostAPI.call(apiUrl, dto, onSuccess, {}, onError);
};

const get_member_list = (onSuccess: any, onError: any, user: string) => {
  GetAPI.call(
    `${EMPLOYEE_URL_CONSTANTS.get_member_list(user)}`,
    onSuccess,
    {},
    onError
  );
};



// update status
const update_status_policy = (dto: any, onSuccess: any, onError: any, id: string, childId: string, usertype: string) => {
  PatchAPI.call(
    `${URL_CONSTANTS.policy__enroll_status_change(usertype, id, childId)}`,
    dto,
    onSuccess,
    {},
    onError
  );
};
// update status
const update_status_nominee = (dto: any, onSuccess: any, onError: any, id: string, policyId: string, memberId: string, usertypeinfo: string) => {
  PatchAPI.call(
    `${URL_CONSTANTS.update_status_nominee(id, policyId, memberId, usertypeinfo)}`,
    dto,
    onSuccess,
    {},
    onError
  );
};
// update status dependent
const update_status_dependent_policy = (dto: any, onSuccess: any, onError: any, id: string, childId: string, policyId: string, userType: string) => {
  PatchAPI.call(
    `${URL_CONSTANTS.policy__enroll_dependent_status_change(id, childId, policyId, userType)}`,
    dto,
    onSuccess,
    {},
    onError
  );
};

const employeeEcards = (
  apiUrl: string,
  onSuccess: any,
  onError: any,
) => {
  GetAPI.call(
    apiUrl,
    onSuccess,
    onError
  );
};


export const EMPLOYEE_POLICY_SERVICES = {
  get_assignable_policies,
  get_dep_assignable_policies,
  save_assigned_policies,
  get_assigned_policies,
  add_member_to_policy,
  get_members,
  get_nominees,
  deassign_employee_policy,
  create_policy_ecard,
  deassign_entity_policy,
  create_nominee_in_policy,
  policy_grade_dropdown,
  add_member_to_assign_policy,
  get_member_list,
  update_status_policy,
  update_status_dependent_policy,
  update_status_nominee,
  CreateEndorsementForNominee,
  employeeEcards,
};
