export const GET_LAYOUT = "GET_LAYOUT"; 
export const UPDATE_LAYOUT_FIELD_PROPERTIES = "UPDATE_LAYOUT_FIELD_PROPERTIES";
export const NEW_SECTION_OR_SUBSECTION_ADDED = "NEW_SECTION_OR_SUBSECTION_ADDED";
export const DELETE = "DELETE";
export const CLEAR = "CLEAR";
export const ITEMSWAPPED = "ITEMSWAPPED";
export const GET_UNUSED_FIELDS = "GET_UNUSED_FIELDS";
export const SET_UNUSED_FIELDS = "SET_UNUSED_FIELDS";
export const REMOVE_UNUSED_FIELDS = "REMOVE_UNUSED_FIELDS";
export const CALL_REMOVE_MODAL = "CALL_REMOVE_MODAL";
export const CLOSE_REMOVE_MODAL = "CLOSE_REMOVE_MODAL";