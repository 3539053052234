import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "../../NewSettings.scss";
import Grid from "@mui/material/Unstable_Grid2";
import { Box, Button, Link, Menu, MenuItem } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import TabList from "../../../../../../Components/TabList/TabList";
import { ALL_ROUTES } from "../../../../../../Routes/all_routes";
import { MODULE_SETTINGS_SERVICES } from "../../../../../../Services/Settings/Customisation/Mudules";
import moment from "moment";
import { useAppSelector } from "../../../../../../Store/hooks";
import { date_time_format } from "../../../../../../Supporting files/HelpingFunction";
import { RootState } from "../../../../../../Store/Store";

function Modules() {
  const navigate = useNavigate();
  const user_type_dateFormat = useAppSelector(
    (state) => state.userDetailsSlice.localInformation.dateFormat
  );
  const tz = useAppSelector(
    (state: RootState) => state.userDetailsSlice.localInformation.timeZone
  );
  const [section, setSection] = useState<string>("general");
  const [module_table, set_module_table_data] = useState([
    {
      display_name: "",
      module_name: "",
      last_modified: "",
      url: "",
    },
  ]);

  const [show, setShow] = React.useState(false);
  const [subSection, setSubSection] = React.useState("businesscard");
  const [addQuoteStep, setAddQuoteStep] = React.useState<
    Array<{ insurer: string; premium: string; deleteable: boolean }>
  >([{ insurer: "", premium: "", deleteable: false }]);

  var indents = [];
  for (var i = 0; i < addQuoteStep.length; i++) {
    indents.push(<span className="indent" key={i}></span>);
  }
  const [activePage, setActivePage] = useState("dashboard");

  useEffect(() => {
    get_modified_data();
  }, [])

  const get_modified_data = () => {
    const onSuccess = (res: any) => {
      set_module_table_data(res.data.data);
    };
    const onError = (err: any) => {
      console.log("err policy", err);
    };
    MODULE_SETTINGS_SERVICES.get_Module_Modified_Data(onSuccess, onError);
  };

  // function updateMasterState(attrName: string, value: any) {
  //   switch (attrName) {
  //     case "activePage":
  //       setActivePage(value);
  //       break;
  //     default:
  //       console.log(attrName, value);
  //       break;
  //   }
  // }
  const updateMasterState = (
    attrName: any,
    value: string | boolean | number
  ) => {
    attrName(value);
  };

  // for scrolling
  const currencies = useRef(null);
  const company = useRef(null);
  const branches = useRef(null);
  const departments = useRef(null);
  const taxation = useRef(null);
  const personal = useRef(null);
  const email = useRef(null);
  const appearance = useRef(null);
  const users = useRef(null);
  const access = useRef(null);
  const modules = useRef(null);
  const templates = useRef(null);
  const employee = useRef(null);
  const employer = useRef(null);
  const storage = useRef(null);
  const exports = useRef(null);
  const activitylog = useRef(null);
  const auditlog = useRef(null);
  const integration = useRef(null);
  const webhooks = useRef(null);

  const scrollToSection = (elementRef: any) => {
    //  ;
    elementRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleClicks = (ref: any) => {
    console.log("handle click", ref);
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <Box className="settingMenuWrapper">
      <Grid container spacing={3}>
        <Grid xs={12}>
          <Grid container spacing={3}>
            <Grid xs className="rightSection">
              <Box className="detailSection">
                <Grid container spacing={3}>
                  <Grid xs={12} style={{ paddingBottom: "0", paddingTop: "0" }}>
                    <ul className="moduleHeading">
                      <li>Display Name</li>
                      <li>Module Name</li>
                      <li>Last Modified</li>
                    </ul>
                  </Grid>
                  {module_table.map((data, index) => (
                    <>
                      <Grid
                        xs={12}
                        style={{ paddingTop: "0px", paddingBottom: "0" }}>
                        <ul className="module_list">
                          <li>
                            <Link className="moduleLink">
                              {data.display_name}
                            </Link>
                            <ul className="module_sublist">
                              <li>
                                <Link
                                  className="Sublist_module"
                                  onClick={() =>
                                    navigate(
                                      `${ALL_ROUTES.CUSTOM_MODULES}?layout=${data.display_name}`
                                    )
                                  }>
                                  {data.display_name}
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="Sublist_module"
                                  onClick={() =>
                                    navigate(
                                      `${ALL_ROUTES.CUSTOM_MODULES}?layout=${data.display_name}`
                                    )
                                  }></Link>
                              </li>
                              <li>
                                <Link
                                  className="Sublist_module"
                                  onClick={() =>
                                    navigate(
                                      `${ALL_ROUTES.CUSTOM_MODULES}?layout=${data.display_name}`
                                    )
                                  }></Link>
                              </li>
                            </ul>
                          </li>
                          <li>{data.module_name}</li>
                          <li>
                            <img src={data.url} />
                            {date_time_format(data.last_modified, user_type_dateFormat, tz)}
                          </li>
                        </ul>
                      </Grid>
                      <Grid xs={12} className="mb-0 pt-0 pb-0">
                        {index + 1 === module_table.length ? null : (
                          <hr className="mb-0" />
                        )}
                      </Grid>
                    </>
                  ))}
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Modules;
