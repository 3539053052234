import React from "react";
import ReactPlayer from "react-player";
import "./Wellness.scss";
import Grid from "@mui/material/Unstable_Grid2";
const VIDEO_PATH = "https://youtu.be/0BIaDVnYp2A";

const Wellness = () => {
  const playerRef = React.useRef(null);
  return (
    <Grid xs className="wellness_wrapper">
      <Grid container spacing={3}>
        <Grid xs={12} className="main_heading mb-6">
          <h5>Wellness</h5>
          <p>
            Wellness is the act of practicing healthy habits on a daily basis to
            attain better physical and mental health outcomes.
          </p>
        </Grid>
        <Grid sm={12} md={6} lg={3}>
          <ReactPlayer ref={playerRef} url={VIDEO_PATH} controls={true} />
        </Grid>
        <Grid sm={12} md={6} lg={3}>
          <ReactPlayer ref={playerRef} url={VIDEO_PATH} controls={true} />
        </Grid>
        <Grid sm={12} md={6} lg={3}>
          <ReactPlayer ref={playerRef} url={VIDEO_PATH} controls={true} />
        </Grid>
        <Grid sm={12} md={6} lg={3}>
          <ReactPlayer ref={playerRef} url={VIDEO_PATH} controls={true} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Wellness;
