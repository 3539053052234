import { useState, useEffect } from "react";
import { Box, Link, Modal } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React from "react";
import "../PolicyExclusion/PolicyExclusion.scss";
import { EMPLOYEE_ENROLMENT_SERVICES } from "../../../../../../Services/Employee/EmployeeEnrolmentService";

interface Props {
  open_status: boolean;
  attrName: any;
  value_update: Function;
  policyId: string;
  maxWidth?: string;
}

const PolicyExclusion: React.FC<Props> = ({
  open_status,
  attrName,
  value_update,
  policyId,
  maxWidth,
}) => {
  const [exclusions, setExclusions] = useState([]);
  useEffect(() => {
    get_exclusions();
  }, [policyId]);

  const updateMasterState = (attrName: any, value: any) => {
    attrName(value);
  };

  const get_exclusions = () => {
    const onSuccess = (res: any) => {
      setExclusions(res.data.data[0].exclusion);
    };

    const onError = (err: any) => {};

    EMPLOYEE_ENROLMENT_SERVICES.get_policy_features_exclusions(
      onSuccess,
      onError,
      policyId
    );
  };

  return (
    <Modal open={open_status} className="modalWrapper">
      <Box className="modalInner xxl-width" width={ maxWidth ?? ""}>
        <Grid container spacing={2}>
          <Grid xs={12}>
            <Link
              className="close-button"
              onClick={() => {
                attrName(false);
              }}
            />
            <h5 className="popup-heading text-left mb-2">Exclusions</h5>
          </Grid>
        </Grid>
        <Grid container spacing={2} textAlign="center">
          <Grid xs={12}>
            <ul className="exclusion_box">
              {exclusions.map((item: any) => {
                return (
                  <li className="exclusion_list">
                    <div className="exclusion_inerlist">
                      <span className="aids"></span>
                      <div className="exclusion_content">
                        <h5>{item.name || item.title}</h5>
                        <p>{item.description}</p>
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default PolicyExclusion;
