import KeyboardArrowLeftRoundedIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import {
  Box,
  Button,
  Link,
  Tooltip
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { EMPLOYEE_ALL_ROUTES } from "../../../../Routes/employee_all_routes";
import "./HomeEmployees.scss";

function HomeEmployees() {
  const location = useLocation();
  const navigate = useNavigate();

  const [darkThemeStatus, setDarkThemeStatus] = useState(false);
  // User Dropdown
  const [userDropdown, setUserDropdown] = useState<null | HTMLElement>(null);
  const [notificationDropdown, setNotificationDropdown] = useState<null | HTMLElement>(null);

  const open = Boolean(userDropdown);
  const openNotification = Boolean(notificationDropdown);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setUserDropdown(event.currentTarget);
  };
  const notificationClick = (event: React.MouseEvent<HTMLElement>) => {
    setNotificationDropdown(event.currentTarget);
  };
  const nofiticationClose = () => {
    setNotificationDropdown(null);
  };
  const handleClose = () => {
    setUserDropdown(null);
  };

  const [isMenuActive, setIsMenuActive] = useState(false);
  const toggleExpand = () => {
    setIsMenuActive(!isMenuActive);
  };

  const [activePage, setActivePage] = useState<string>("Dashboard");
  const changePage = (page: any) => {
    setActivePage(page);
  };

  return (
    <Grid className="menuCol" xs>
      <Box className={isMenuActive ? "menuList expand" : "menuList"}>
        <Link className="expandView" onClick={toggleExpand}>
          {isMenuActive ? (
            <KeyboardArrowLeftRoundedIcon />
          ) : (
            <KeyboardArrowRightRoundedIcon />
          )}
        </Link>
        <Tooltip title="Dashboard" placement="bottom">
          <Button
            className={
              location.pathname === EMPLOYEE_ALL_ROUTES.DASHBOARD
                ? "dashboard active"
                : "dashboard"
            }
            onClick={() => navigate(EMPLOYEE_ALL_ROUTES.DASHBOARD)}
          >
            Dashboard
          </Button>
        </Tooltip>

        <Tooltip title="Policy Wallet" placement="bottom">
          <Button
            className={
              location.pathname === EMPLOYEE_ALL_ROUTES.POLICY_WALLET
                ? "policywallet active" : "policywallet"
            }
            onClick={() => navigate(EMPLOYEE_ALL_ROUTES.POLICY_WALLET)}
          >
            Policy Wallet
          </Button>
        </Tooltip>

        {/* <Tooltip title="Riskmap" placement="bottom">
          <Button
            className={
              location.pathname === EMPLOYEE_ALL_ROUTES.RISKMAP
                ? "riskmap active"
                : "riskmap"
            }
            onClick={() => navigate(EMPLOYEE_ALL_ROUTES.RISKMAP)}
          >
            Riskmap
          </Button>
        </Tooltip> */}

        <Tooltip title="Profile" placement="bottom">
          <Button
            className={
              location.pathname === EMPLOYEE_ALL_ROUTES.PROFILE
                ? "userprofile active"
                : "userprofile"
            }
            onClick={() => navigate(EMPLOYEE_ALL_ROUTES.PROFILE)}
          >
            Profile
          </Button>
        </Tooltip>
        {/* <Tooltip title="My Document" placement="bottom">
          <Button
            className={
              location.pathname === EMPLOYEE_ALL_ROUTES.MY_DOCUMENT
                ? "mydocument active"
                : "mydocument"
            }
            onClick={() => navigate(EMPLOYEE_ALL_ROUTES.MY_DOCUMENT)}
          >
            My Document
          </Button>
        </Tooltip> */}

        <Tooltip title="Your Request" placement="bottom">
          <Button
            className={
              location.pathname === EMPLOYEE_ALL_ROUTES.CLAIM_ASSISTANT
                ? "claimassistant active"
                : "claimassistant"
            }
            onClick={() => navigate(EMPLOYEE_ALL_ROUTES.CLAIM_ASSISTANT)}
          >
            Community
          </Button>
        </Tooltip>

        {/* <Tooltip title="Community" placement="bottom">
          <Button
            className={
              location.pathname === EMPLOYEE_ALL_ROUTES.COMMUNITY
                ? "community active"
                : "community"
            }
            onClick={() => navigate(EMPLOYEE_ALL_ROUTES.COMMUNITY)}
          >
            Community
          </Button>
        </Tooltip> */}

        <Tooltip title="Support" placement="bottom">
          <Button
            className={
              location.pathname === EMPLOYEE_ALL_ROUTES.SUPPORT
                ? " support active"
                : "support"
            }
            onClick={() => navigate(EMPLOYEE_ALL_ROUTES.SUPPORT)}
          >
            Support
          </Button>
        </Tooltip>

        {/* <Tooltip title="Wellness" placement="bottom">
          <Button
            className={
              location.pathname === EMPLOYEE_ALL_ROUTES.WELLNESS
                ? "wellness active"
                : "wellness"
            }
            onClick={() => navigate(EMPLOYEE_ALL_ROUTES.WELLNESS)}
          >
            Wellness
          </Button>
        </Tooltip> */}

        {/* <Tooltip title="Doctor Online" placement="bottom">
          <Button
            className={
              location.pathname === EMPLOYEE_ALL_ROUTES.DOCTOR_ONLINE
                ? "doctoronline active"
                : "doctoronline"
            }
            onClick={() => navigate(EMPLOYEE_ALL_ROUTES.DOCTOR_ONLINE)}
          >
            Doctor Online
          </Button>
        </Tooltip> */}

        {/* <Tooltip title="Repair Vehicle" placement="bottom">
          <Button
            className={
              location.pathname === EMPLOYEE_ALL_ROUTES.REPAIR_VEHICLE
                ? "repairvehicle active"
                : "repairvehicle"
            }
            onClick={() => navigate(EMPLOYEE_ALL_ROUTES.REPAIR_VEHICLE)}
          >
            Repair Vehicle
          </Button>
        </Tooltip> */}
      </Box>
    </Grid>
  );
}

export default HomeEmployees;
