import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Box, Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import image from "../../../../../../Assets/images/storage-icon.png";
import { RootState } from "../../../../../../Store/Store";
import { useAppSelector } from "../../../../../../Store/hooks";
import RKTextField from "../../../../../../Supporting files/RKTextField/RKTextField";
import SelectDropdown from "../../../../../../Supporting files/SelectDropdown/SelectDropdown";
import { userDetailsSlice } from "../../../../../../Store/Slice_Reducer/UserLogin/UserDetailsSlice";
import { STORAGE_SETTINGS_SERVICES } from "../../../../../../Services/Settings/Storage/StorageConfigService";

function StorageSettings() {
  const [productTabsStatus, setProductTabsStatus] = useState("");
  const [security, setSecurity] = useState("None");
  const [isEdit, setIsEdit] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { _id, config_settings } = useAppSelector(
    (state: RootState) => state.userDetailsSlice
  );
  console.log("user_id", productTabsStatus);
  const stroageId = useAppSelector(
    (state: RootState) => state.userDetailsSlice.config_settings.storage
  );
  console.log("storageId", stroageId);
  const [storageConfig, setStorageConfig] = useState<any>({
    type: "",
    account_name: "",
    sas_token: "",
    storage_connection_string: "",
    creds: {},
  });
  const [isValid, setIsValid] = useState(false);
  console.log("storageConfig", storageConfig);

  const [dropDownData, setDropDownData] = useState<any[]>([]);
  console.log("storage", storageConfig, productTabsStatus);
  const [inputFields, setInputFields] = useState<any>();
  console.log("inputFields", inputFields);
  useEffect(() => {
    //getStorageConfig();
  }, [isEdit]);

  useEffect(() => {
    getStorageDropDownData();
  }, [isEdit]);

  useEffect(() => {
    getStorageConfigData();
  }, [isEdit]);
  const getStorageConfigData = () => {
    const onSuccess = (res: any) => {
      if (res?.data?.data !== null) {
        //setDropDownData(res?.data?.data);
        setStorageConfig(res.data.data);
        console.log("details tab", res.data.data);
        setProductTabsStatus(res.data.data.sub_type);
        setInputFields(res.data.data);
      }
    };
    const onError = (err: any) => {
      console.log("false", err);
    };

    STORAGE_SETTINGS_SERVICES.get_storage_config_data(
      onSuccess,
      onError,
      stroageId
    );
  };
  //api calling functions
  const getStorageDropDownData = () => {
    const onSuccess = (res: any) => {
      if (res?.data?.data !== null) {
        setDropDownData(res?.data?.data);
        console.log("res", res.data.data);
        //setProductTabsStatus(res.data.data[0].label)
      }
    };
    const onError = (err: any) => {
      console.log("false", err);
    };

    STORAGE_SETTINGS_SERVICES.get_storage_dropdown_data(
      productTabsStatus,
      onSuccess,
      onError
    );
  };

  // const updateStorageConfig = () => {

  //   const onSuccess = (res: any) => {

  //     if (res?.data?.data !== null) {
  //       setStorageConfig(res?.data?.data);
  //     }
  //   };
  //   const onError = (err: any) => {
  //     console.log("false", err);
  //   };

  //   STORAGE_SETTINGS_SERVICES.updateStorageConfig(
  //     _id,
  //     config_settings.storage,
  //    onSuccess, onError);
  // };

  //master state update function
  const updateMasterState = (attrName: any, value: any) => {
    console.log("update master value", value);
    if (attrName[0] === "input_fields") {
      setIsValid(false)
      let data = inputFields;
      const field = attrName[1];
      data = {
        ...data,
        creds: {
          ...data.creds,
          [field]: value,
        },
      };
      setInputFields(data);
    } else if (attrName[1] === "dropdown") {
      const settingId = dropDownData.find((item: any) => item?.label === value);
      updateStorage(settingId.id);
      setProductTabsStatus(value);
      dispatch(userDetailsSlice.actions.updateStorageConfig(settingId.id));

      // dispatch(userDetailsSlice.actions.updateEmailConfig(settingId));
      //updateStorageConfig();
    }
    // if (typeof attrName === 'function') return attrName(value)
    // setStorageConfig({ ...storageConfig, [attrName[1]]: value });
  };

  //form submits functions
  const detail_form_submit = async () => {
    //set form error for required true field
    setIsEdit(!isEdit);
    // updateStorageConfig();
  };

  const updateStorage = (stroageId: string) => {
    const onSuccess = (res: any) => {
      console.log("updateStorage res", res.data.data);
    };
    const onError = (err: any) => {
      console.log("false", err);
    };
    STORAGE_SETTINGS_SERVICES.updateStorageConfig(
      _id,
      stroageId,
      onSuccess,
      onError
    );
  };
  const updateStorageConfigDetails = () => {
    const data = { ...inputFields };
    delete data._id;
    delete data.createdAt;
    delete data.createdBy;
    delete data.userId;
    delete data.updatedAt;
    delete data.__v;
    const onSuccess = (res: any) => {
      if (res?.data?.data !== null) {
        //setMailConfig(res?.data?.data);
        setInputFields(res?.data?.data);
        setIsEdit(!isEdit);
      }
      //setDisableSave(false);
    };
    const onError = (err: any) => {
      //setDisableSave(false);
      console.log("false", err);
      setIsEdit(!isEdit);
    };
    //setDisableSave(true);
    STORAGE_SETTINGS_SERVICES.updateStorageConfigDetails(
      { ...data },
      onSuccess,
      onError
    );
  };
  // const performTest = () => {
  //   callTestApi();
  // }
  const testlink = () => {
    const selectedObject = dropDownData.find(
      (item) => item.label === productTabsStatus
    );

    const settingId = selectedObject.id;
    const data = { ...inputFields.creds,storage_type:inputFields.sub_type };
    console.log("post test", data);
    const onSuccess = (res: any) => {
      setIsValid(true);
      if (res?.data?.data !== null) {
        Swal.fire({
          title: "Mail Setting Configured!",
          text: "Check your inbox for test mail!",
          icon: "success",
        });
      }
    };
    const onError = (err: any) => {
      console.log("false", err);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `Failed to configure storage settings: This might be due to the server being down, network issues, or incorrect server settings. Please Try again!`,
      });
    };
    STORAGE_SETTINGS_SERVICES.storageTestMail(data, onSuccess, onError);
  };

  //dynamic jsx function call
  const showEditFields = (isEdit: boolean) => {
    console.log(isEdit);
    return isEdit ? (
      <Grid container spacing={3}>
        <Grid xs={12}>
          <Grid container spacing={3}>
            <Grid xs className="rightSection">
              <Box className="detailSection">
                <div className="sectionTitle">
                  <h4>Storage Settings</h4>
                </div>
                {/* <Grid container spacing={3}>
                  <Grid xs={12}>
                    <h5 className="subHeading">Smtp</h5>
                  </Grid>
                </Grid> */}
                <Grid container spacing={3}>
                  {/* <Grid sm={6} md={6} lg={6} xl={6}>
                    <RKTextField
                      class_name="inputField"
                      title={"Account Name"}
                      value={storageConfig?.account_name}
                      attrName={["input_fields", "account_name"]}
                      value_update={updateMasterState}
                      warn_status={false}
                    />
                  </Grid>
                  <Grid sm={6} md={6} lg={6} xl={6}>
                    <RKTextField
                      class_name="inputField"
                      title={"Sas Token"}
                      value={storageConfig?.sas_token}
                      attrName={["input_fields", "sas_token"]}
                      value_update={updateMasterState}
                      warn_status={false}
                    />
                  </Grid>
                  <Grid sm={6} md={6} lg={6} xl={6}>
                    <RKTextField
                      class_name="inputField"
                      title={"Storage Connection String"}
                      value={storageConfig?.storage_connection_string}
                      attrName={["input_fields", "storage_connection_string"]}
                      value_update={updateMasterState}
                      warn_status={false}
                    />
                  </Grid> */}
                  {Object.entries(inputFields?.creds || {}).map(
                    ([key, value]) => (
                      <Grid sm={6} md={6} lg={6} xl={6}>
                        <RKTextField
                          class_name="inputField"
                          title={key.replace(/_/g, " ").toUpperCase()}
                          value={value}
                          attrName={["input_fields", key]}
                          value_update={updateMasterState}
                          warn_status={false}
                        />
                      </Grid>
                    )
                  )}
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    ) : (
      <div className="logosection">
        <h4 style={{ textAlign: "left" }}>Configuration Settings</h4>
        <div className="detail_list" style={{ display: "flex" }}>
          <ul style={{ width: "40%" }}>
            {!isEdit &&
              Object.entries(storageConfig?.creds || {}).map(([key, value]) => (
                <div
                  style={{
                    display: "flex",

                    flexDirection: "column",
                    width: "100%",
                    gap: "20px",
                  }}
                >
                  <li key={key} className="mb-5">
                    <div>
                      <p>{key.replace(/_/g, " ").toUpperCase()}</p>
                      <h6 style={{ textAlign: "left" }}>{String(value)}</h6>
                    </div>
                  </li>
                </div>
              ))}
          </ul>

          <div style={{ padding: 0, margin: 0, width: "50%" }}>
            <img
              alt=""
              className="companylogo"
              src={image}
              style={{ width: "100%", height: "300px", objectFit: "contain" }}
            />
          </div>
        </div>
      </div>
    );
  };
  console.log("productTabsStatus", productTabsStatus);

  return (
    <Box className="settingMenuWrapper">
      <Grid container spacing={3}>
        <Grid xs={12}>
          <h3 className="mb-0">Storage Configurations</h3>
        </Grid>
        <Grid xs={5}>
          <div className="status_dropdown" id={"1"}>
            {true ? (
              <CheckCircleIcon id={`Active`} />
            ) : true ? (
              <HighlightOffIcon id={`Active`} />
            ) : null}
            <SelectDropdown
              class_name="inputFieldd"
              title="Choose Storage Configuration"
              value={productTabsStatus}
              attrName={["email_config", "dropdown"]}
              value_update={updateMasterState}
              dropdown_data={dropDownData}
              warn_status={false}
            />
          </div>
        </Grid>

        <Grid xs={12}>
          {productTabsStatus == "Azure" || "S3" ? (
            <>
              {showEditFields(isEdit)}
              <Grid container spacing={3} className="footerSection">
                <Grid xs={12} className="ctaBtn">
                  <Button
                    onClick={testlink}
                    variant="contained"
                    className="submitBtn"
                  >
                    test link
                  </Button>
                  {isEdit ? (
                    <Button
                      onClick={() => {
                        setIsEdit(!isEdit);
                        // navigate(ALL_ROUTES.CLIENTS);
                      }}
                      variant="outlined"
                      className="borderBtn"
                    >
                      Cancel
                    </Button>
                  ) : (
                    <></>
                  )}

                  {isEdit && isValid && (
                    <Button
                      onClick={updateStorageConfigDetails}
                      variant="contained"
                      className="submitBtn"
                    >
                      Update
                    </Button>
                  )}
                  {!isEdit && (
                    <Button
                      onClick={detail_form_submit}
                      variant="contained"
                      className="submitBtn"
                    >
                      Edit
                    </Button>
                  )}
                  {/* <Button
                    onClick={testlink}
                    variant="contained"
                    className="submitBtn"
                  >
                    test link
                  </Button> */}
                </Grid>
              </Grid>
            </>
          ) : null}
        </Grid>
      </Grid>
    </Box>
  );
}

export default StorageSettings;
