import CheckIcon from "@mui/icons-material/Check";
import EditIcon from "@mui/icons-material/Edit";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box, Link, Tooltip } from "@mui/material";
import React, { useState } from "react";
import DatePicker from "../DatePicker/DatePicker";
import { add_date_format } from "../HelpingFunction";
interface Props {
  title: string;
  value: string | Date | null;
  attrName: any;
  value_update: Function;
  error_message: string;
  warn_status: boolean;
  class_name: string;
  disableFuture?: string | Date | null;
  info_status?: boolean;
  info_message?: string;
  required?: boolean;
  params?: any;
  id?: string;
  min_date?: Date | null | any;
  max_date?: Date | null;
  disabled?: boolean;
}

const DatePickerDisable: React.FC<Props> = ({
  title,
  value,
  value_update,
  attrName,
  error_message,
  warn_status,
  class_name,
  disableFuture,
  info_status,
  info_message,
  required,
  params,
  id,
  min_date,
  max_date,
  disabled,
}) => {
  const [disbaleToggle, setDisableToggle] = useState<boolean>(false);
  const updateMasterState = (attrName: any, value: any) => {
    value_update(attrName, value);
  };
  // individual edit
  const edit = () => {
    setDisableToggle(!disbaleToggle);
    if (disbaleToggle === true) {
      params(attrName);
    }
  };
  return (
    <Box className="individualEdit">
      {disbaleToggle === false ? (
        <div className={!value ? "staticField nullValue" : "staticField"}>
          <label>{title}</label>
          <p>{!value ? " " : add_date_format(value)}</p>
        </div>
      ) : (
        <DatePicker
          class_name={class_name}
          title={title}
          value={value}
          attrName={attrName}
          value_update={updateMasterState}
          error_message={error_message}
          warn_status={warn_status}
          info_status={info_status}
          info_message={info_message}
          required={required}
          min_date={min_date}
          max_date={max_date}
          disabled={disabled}
        />
      )}
      {info_status === true ? (
        <Tooltip title={info_message === undefined ? "" : info_message}>
          <span className="infoMessage">
            <InfoOutlinedIcon />
          </span>
        </Tooltip>
      ) : null}
      {!disabled && (
        <Link
          className={
            disbaleToggle === true ? "editUpdate visible" : "editUpdate"
          }
          onClick={edit}
        >
          {disbaleToggle === false ? <EditIcon /> : <CheckIcon />}
        </Link>
      )}
    </Box>
  );
};

export default DatePickerDisable;
