import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import DatePicker from "../../../../../../Supporting files/DatePicker/DatePicker";
import SearchDropdown from "../../../../../../Supporting files/SearchDropdown/SearchDropdown";
import RKTextField from "../../../../../../Supporting files/RKTextField/RKTextField";

function EmployeeBikeForm() {
    const [fullName, setFullName] = React.useState("");
    const [relationship, setRelationship] = React.useState("");
    const [memberDate, setMemberDate] = React.useState<Date | null>(null);

    const updateMasterState = (attrName: any, value: any) => {
      attrName(value);
    };

    const relationship_data = [
      { label: "Splendor +", value: "Splendor +" },
      { label: "Pulsar 150", value: "Pulsar 150" },
      { label: "Honda Activa", value: "Honda Activa" },
    ];
    const fueltype_data = [
      { label: "Petrol", value: "Petrol" },
      { label: "Electric", value: "Electric" },
    ];
  return (
    <Grid container spacing={3}>
      <Grid xs={6}>
        <RKTextField
          class_name="inputField"
          title={"Registration Number"}
          value={fullName}
          attrName={setFullName}
          value_update={updateMasterState}
          warn_status={false}
        />
      </Grid>
      <Grid xs={6}>
        <SearchDropdown
          class_name="inputField"
          title="Make & Model"
          value={relationship}
          attrName={setRelationship}
          value_update={updateMasterState}
          data={relationship_data}
          warn_status={false}
          error_message={"please select make & model"}
        />
      </Grid>
      <Grid xs={6}>
        <SearchDropdown
          class_name="inputField"
          title="Fuel Type"
          value={relationship}
          attrName={setRelationship}
          value_update={updateMasterState}
          data={fueltype_data}
          warn_status={false}
          error_message={"please select fuel type"}
        />
      </Grid>
      <Grid xs={6}>
        <SearchDropdown
          class_name="inputField"
          title="Variant"
          value={relationship}
          attrName={setRelationship}
          value_update={updateMasterState}
          data={relationship_data}
          warn_status={false}
          error_message={"please select variant"}
        />
      </Grid>
      <Grid xs={6}>
        <SearchDropdown
          class_name="inputField"
          title="Insurer"
          value={relationship}
          attrName={setRelationship}
          value_update={updateMasterState}
          data={relationship_data}
          warn_status={false}
          error_message={"please select insurer"}
        />
      </Grid>
      <Grid xs={6}>
        <DatePicker
          class_name="inputField"
          title="Reg. Date"
          value={memberDate}
          attrName={setMemberDate}
          value_update={updateMasterState}
          error_message="Please enter DOB"
          warn_status={false}
        />
      </Grid>
      <Grid xs={6}>
        <DatePicker
          class_name="inputField"
          title="Policy Expiry"
          value={memberDate}
          attrName={setMemberDate}
          value_update={updateMasterState}
          error_message="Please enter DOB"
          warn_status={false}
        />
      </Grid>
    </Grid>
  );
}

export default EmployeeBikeForm;
