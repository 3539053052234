import { Box, Link } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { EMPLOYEE_URL_CONSTANTS } from "../../../../../APIVar/EmployeeUrlConstant";
import { URL_CONSTANTS } from "../../../../../APIVar/URLConstants";
import InitiateAClaim from "../../../../../Components/Broker/Claim/InitiateAClaimSp/InitiateAClaim";
import ViewDocuments from "../../../../../Components/Broker/Claim/ViewDocuments/ViewDocuments";
import AddNewRequest from "../../../../../Components/Broker/Endorsment/AddNewRequestSP/AddNewRequest";
import AddMember from "../../../../../Components/Common/AddMember/AddMember";
import AddNominee from "../../../../../Components/Common/Nominee/NomineDetail/AddNomineeDetail";
import { EMPLOYEE_ALL_ROUTES } from "../../../../../Routes/employee_all_routes";
import { EMPLOYEE_POLICY_SERVICES } from "../../../../../Services/Employee/EmployeePolicyService";
import { useAppSelector } from "../../../../../Store/hooks";
import "./InsurancePolicyDetail.scss";
import { toast } from "react-toastify";
import { countOccurrencesInCoverageConfigString } from "../../../../../Supporting files/HelpingFunction";

const InsurancePolicyDetails = ({
  data,
  index,
  getWallet,
}: {
  data: any;
  index: number;
  getWallet: Function;
}) => {
  const navigate = useNavigate();
  const [addNewRequest, setAddNewRequest] = useState(false);
  const { clientId, id, userType } = useAppSelector((state) => state.userDetailsSlice);
  const [addNewMember, setAddMember] = useState(false);
  const [InitiateClaim, setInitiateClaim] = useState(false);
  const [viewDocuments, setViewDocuments] = useState(false);
  const [openNomine, setOpenNomine] = useState<boolean>(false);
  const [ecard, setEcard] = useState([]);
  let enroledMembers: number = data.members?.filter((data1: any) => data1?.enrolment_status === 41)?.length;
  if (data?.status === 41 //&& selectedMemberType === "Employee"
  ) {
    enroledMembers += 1;
  }
  let enroledNominees: number = data?.nominee?.filter((data1: any) => data1?.enrolment_status === 44)?.length || 0;

  const updateMasterState = (attrName: any, value: string) => {
    attrName(value);
  };

  const getEcards = (id: string) => {
    const onSuccess = (res: any) => {
      setEcard(res.data.data.ecard);
    };
    const onError = (err: any) => {
      console.log(err, "err");
    };
    let api = `${URL_CONSTANTS.get_e_cards(userType.toLowerCase())}${id}`;
    EMPLOYEE_POLICY_SERVICES.employeeEcards(api, onSuccess, onError);
  };

  const parseCoverageString = (basicConfig: any, parentConfig: any) => {
    let EmployeeStr = "";
    let ParentStr = "";
    if (!basicConfig) {
      EmployeeStr = "Employee";
    } else {
      if (parentConfig === "Nill") {
        if (basicConfig?.includes("+")) {
          const spouseOccurences = countOccurrencesInCoverageConfigString(basicConfig, "S");
          const childrenOccurences = countOccurrencesInCoverageConfigString(basicConfig, "C");
          if (spouseOccurences && childrenOccurences) {
            EmployeeStr = `1 Employee, ${spouseOccurences} Spouse & ${childrenOccurences} ${childrenOccurences === 1 ? "Child" : "Children"}`;
          } else if (spouseOccurences) {
            EmployeeStr = `1 Employee & ${spouseOccurences} Spouse`;
          } else if (childrenOccurences) {
            EmployeeStr = `1 Employee & ${childrenOccurences} ${childrenOccurences === 1 ? "Child" : "Children"}`;
          }
        } else {
          EmployeeStr = "Employee";
        }
        return { EmployeeStr }
      } else {
        if (basicConfig?.includes("+")) {
          const spouseOccurences = countOccurrencesInCoverageConfigString(basicConfig, "S");
          const childrenOccurences = countOccurrencesInCoverageConfigString(basicConfig, "C");
          if (spouseOccurences && childrenOccurences) {
            EmployeeStr = `1 Employee, ${spouseOccurences} Spouse, ${childrenOccurences} ${childrenOccurences === 1 ? "Child" : "Children"}`;
          } else if (spouseOccurences) {
            EmployeeStr = `1 Employee, ${spouseOccurences} Spouse`;
          } else if (childrenOccurences) {
            EmployeeStr = `1 Employee, ${childrenOccurences} ${childrenOccurences === 1 ? "Child" : "Children"}`;
          }
        } else {
          EmployeeStr = "Employee";
        }
        if (parentConfig) {
          if (parentConfig?.includes("+")) {
            const inLawsOccurences = countOccurrencesInCoverageConfigString(parentConfig, "PIL");
            const parentsOccurences = countOccurrencesInCoverageConfigString(parentConfig, "P");
            if (parentsOccurences && inLawsOccurences) {
              ParentStr = `${parentsOccurences} Parents & ${inLawsOccurences} Parents-In-Law`;
            } else if (parentsOccurences) {
              ParentStr = `${parentsOccurences} Parents`;
            } else if (inLawsOccurences) {
              ParentStr = `${inLawsOccurences} Parents-In-Law`;
            }
          }
        }
      }
    }
    return { EmployeeStr, ParentStr };
  };

  return (
    <>
      <AddNominee
        id={id}
        open_status={openNomine}
        attrName={setOpenNomine}
        value_update={updateMasterState}
        apiUrl={""}
        policyIdEmployee={data.policyId}
        refetchData={getWallet}
        existingNominees={data.nominee}
        policyBeingWorkedOn={data}
      />
      <AddNewRequest
        attrName={setAddNewRequest}
        open_status={addNewRequest}
        value_update={updateMasterState}
      />
      <InitiateAClaim
        attrName={setInitiateClaim}
        open_status={InitiateClaim}
        value_update={updateMasterState}
        client_id_status={true}
      />
      <AddMember
        attrName={setAddMember}
        open_status={addNewMember}
        value_update={updateMasterState}
        apiUrl=""
        policyId={data.policyId}
        policyBeingWorkedOn={data}
        memberId={id}
        policyMember={data.members}
      />
      <ViewDocuments
        attrName={setViewDocuments}
        open_status={viewDocuments}
        value_update={updateMasterState}
        data={ecard}
      />
      <Box>
        <div className="insurance_box">
          <svg
            viewBox="0 0 500 150"
            preserveAspectRatio="none"
            className={`viewBox  ${data.productType === "Group Health"
              ? "health"
              : data.productType === "Group Super TopUp"
                ? "super"
                : data.productType === "Group Term"
                  ? "termins"
                  : data.productType === "Group Travel"
                    ? "criticalillness"
                    : data.productType === "Group Personal Accident"
                      ? "personalAccdt"
                      : data.productType === "Group Critical Illness"
                        ? "criticalillness"
                        : null
              }`}
          >
            <defs>
              <linearGradient
                id={`blue-gradient-${index}`}
                x1="50%"
                y1="-2.48949813e-15%"
                x2="50%"
                y2="100%"
              >
                <stop className="stop-color-red" offset="0%" />
                <stop className="stop-color-yellow" offset="100%" />
              </linearGradient>
            </defs>
            <path
              d="M0.00,49.98 C254.51,72.06 306.43,22.41 500.00,49.98 L500.00,150.00 L0.00,150.00 Z"
              fill={`url(#${"blue-gradient-"}${index})`}
            ></path>
          </svg>
          <div className={`inner_box green`}>
            <img
              src={`${data.productType === "Group Health"
                ? "./images/hi_productLogo.png"
                : data.productType === "Group Super TopUp"
                  ? "./images/stu_productLogo.png"
                  : data.productType === "Group Term"
                    ? "./images/ti_productLogo.png"
                    : data.productType === "Group Travel"
                      ? "./images/travel_productLogo.png"
                      : data.productType === "Group Personal Accident"
                        ? "./images/pa_productLogo.png"
                        : data.productType === "Group Critical Illness"
                          ? "./images/ci_productLogo.png"
                          : null
                }`}
              alt="Product Logo"
              height={"40px"}
            />
            <h5>
              {data?.productType} <span>{data?.spanheading1}</span>
            </h5>
          </div>
          <div className="policy_box">
            <Box className="com_box" textAlign="right">
              <img src={data.insurerlogo} alt="Insurer Logo" />
            </Box>
            <Box className="com_box">
              <h5>
                Policy No.
                <span>{data?.policyNumber}</span>
              </h5>
            </Box>
          </div>
          <div className="coverage_section">
            <p className="coverage_p">
              Coverage for{" "}
              <span>
                {parseCoverageString(
                  data.planType.basicConfig,
                  data.planType.parentConfig
                )?.ParentStr
                  ? parseCoverageString(
                    data.planType.basicConfig,
                    data.planType.parentConfig
                  )?.EmployeeStr +
                  ", " +
                  parseCoverageString(
                    data.planType.basicConfig,
                    data.planType.parentConfig
                  )?.ParentStr
                  : parseCoverageString(
                    data.planType.basicConfig,
                    data.planType.parentConfig
                  )?.EmployeeStr}
              </span>
            </p>
          </div>
          <div className="module_overlay">
            <ul>
              <li>
                <Link
                  className="module_list1"
                  onClick={() => {
                    setAddNewRequest(true);
                  }}
                >
                  Endorsement
                </Link>
              </li>
              <li>
                <Link
                  className="module_list2"
                  onClick={() => {
                    setInitiateClaim(true);
                  }}
                >
                  Register Claim
                </Link>
              </li>
              {data?.productType != "Group Term" && (
                <li>
                  <Link
                    className="module_list3"
                    onClick={() => {
                      if (enroledMembers >= data?.policySettings?.maxMemberPerFamily) {
                        return toast.warning("Maximun number of family members Enroled limit exceeds")
                      }
                      if (data.status === 61 || data.status === 42) {
                        return toast.warn("Since the Employee has been Opt-Out/Opt-Out Requested from this policy, no new member may be added to this Policy.");
                      }
                      setAddMember(true);
                    }}
                  >
                    Add Member
                  </Link>
                </li>
              )}
              <li>
                <Link
                  className="module_list4"
                  onClick={() => {
                    navigate(
                      `${EMPLOYEE_ALL_ROUTES.GROUP_POLICY_DETAILS}?id=${data.policyId}`
                    );
                  }}
                >
                  Policy Details
                </Link>
              </li>
              {data?.policySettings?.nomination && (
                <li>
                  <Link
                    className="module_list3"
                    onClick={() => {
                      // if (!data.policySettings.nomination) {
                      //   return toast.error("You can't add Nominees")
                      // } else 
                      if (enroledNominees >= data?.policySettings?.maxNomination) {
                        return toast.error("Maximun number of nominees Enroled limit exceeds")
                      } else {
                        setOpenNomine(true);
                      }
                    }}
                  >
                    Add Nominee
                  </Link>
                </li>
              )}

              <li>
                <Link
                  sx={{ height: "25px" }}
                  className="module_list5"
                  onClick={() => {
                    setViewDocuments(true);
                    getEcards(data.policyId);
                  }}
                >
                  Download E-Card
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </Box>
    </>
  );
};
// Exporting the component
export default InsurancePolicyDetails;
