import { PayloadAction } from "@reduxjs/toolkit";
import {
  TBulkUpload,
  TBulkUploadField,
} from "../../../Services/Types/TBulkUpload";
import { TBulkUploadResponse } from "../../../Services/Types/TBulkUploadResponse";

function setBulkUploadData(
  state: TBulkUpload,
  action: PayloadAction<TBulkUpload>
) {
  return action.payload;
}

function setBulkUploadFieldData(
  state: TBulkUpload,
  action: PayloadAction<TBulkUploadField>
) {
  return { ...state, map_field_data: action.payload };
}

type ValueTypes = keyof TBulkUpload;
function updateBulkUploadData(
  state: TBulkUpload,
  action: PayloadAction<{ key: ValueTypes; value: any }[]>
) {
  let updatedState = { ...state };

  action.payload.forEach(({ key, value }) => {
    updatedState = { ...updatedState, [key]: value };
  });

  return updatedState;
}

function setBulkUploadApiResponse(
  state: TBulkUpload,
  action: PayloadAction<TBulkUploadResponse>
) {
  return { ...state, map_field_api_response: action.payload };
}

function setBulkUploadPageStatus(
  state: TBulkUpload,
  action: PayloadAction<
    "BULK_UPLOAD_PAGE" | "MAP_FIELD" | "MAP_FIELD_CONFIRMATION"
  >
) {
  return { ...state, bulk_upload_page_status: action.payload };
}

export const BULK_UPLOAD_REDUCERS = {
  setBulkUploadData,
  setBulkUploadFieldData,
  updateBulkUploadData,
  setBulkUploadApiResponse,
  setBulkUploadPageStatus,
};

export type BulkUploadReducer = typeof BULK_UPLOAD_REDUCERS;
