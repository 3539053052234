import { URL_CONSTANTS } from "../../../APIVar/URLConstants";
import { Post } from "../../../Supporting files/CallAPI";
import { GetAPI, PatchAPI, PostAPI } from "../../API";

const get_storage_dropdown_data = (
  type: string,
  onSuccess: (data: any) => void,
  onError: (err: any) => void
) => {
  GetAPI.call(
    URL_CONSTANTS.get_storage_config_settings,
    onSuccess,
    {},
    onError
  );
};
const get_storage_config_data = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
  id: string
) => {
  GetAPI.call(
    URL_CONSTANTS.get_storage_config_data(id),
    onSuccess,
    {},
    onError
  );
};
const updateStorageConfig = (
  // data: {
  //   type: string,
  //   account_name: string,
  //   sas_token: string,
  //   storage_connection_string: string
  // },
  user_id: any,
  setting_id: any,

  onSuccess: (data: any) => void,
  onError: (err: any) => void
) => {
  PatchAPI.call(
    URL_CONSTANTS.update_storage_config_settings(user_id, setting_id),
    onSuccess,
    onError
  );
};

const updateStorageConfigDetails = (
  data: any,
  onSuccess: (data: any) => void,
  onError: (err: any) => void
) => {
  PostAPI.call(
    URL_CONSTANTS.update_storage_config_details,
    data,
    onSuccess,
    onError
  );
};

const storageTestMail = (
  data: any,
  onSuccess: (data: any) => void,
  onError: (err: any) => void
) => {
  PostAPI.call(
    URL_CONSTANTS.test_storage_config_settings,
    data,
    onSuccess,
    {},
    onError
  );
};
export const STORAGE_SETTINGS_SERVICES = {
  get_storage_dropdown_data,
  updateStorageConfig,
  get_storage_config_data,
  updateStorageConfigDetails,
  storageTestMail,
};

//broker/storage?id=66d56666f08ecf6794379601
