import { useDrag } from "react-dnd";

const SideBarItem = ({ data }) => {
  //console.log(data)
  const [{ opacity }, drag] = useDrag({
    item: data,
    collect: monitor => ({
      opacity: monitor.isDragging() ? 0.4 : 1
    })
  });
  
  return (
    <li className={data.class_name} ref={drag} style={{ opacity }}>
      {data.component.type}
    </li>
  );
};
export default SideBarItem;
