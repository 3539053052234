import { Box, Button } from '@mui/material';
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ALL_ROUTES } from '../../../../../../Routes/all_routes';
import { DEVELOPER_SERVICES } from '../../../../../../Services/Settings/Developer/DeveloperServices';
import { useAppSelector } from '../../../../../../Store/hooks';
import RKTextField from '../../../../../../Supporting files/RKTextField/RKTextField';
import './Retails.scss';


const Retails = () => {
    const navigate = useNavigate();
    const { userType } = useAppSelector(state => state.userDetailsSlice)
    const [editMode, setEditMode] = useState(false);
    const [product, setProduct] = useState<any[]>([
        // { code: productType.HEALTH, name: "Health Insurance", enable: false, url: "" },
        // { code: productType.CAR, name: "Car Insurance", enable: false, url: "" },
        // { code: productType.BIKE, name: "Bike Insurance", enable: false, url: "" },
        // { code: productType.TERM, name: "Term Insurance", enable: false, url: "" },
        // { code: productType.INVESTMENT, name: "Investment Insurance", enable: false, url: "" },
        // { code: productType.TRAVEL, name: "Travel Insurance", enable: false, url: "" },
    ])

    const getRetailPolicies = () => {
        const onSuccess = (res: any) => {
            const resp = res?.data?.data;
            if (res?.data?.status === "success") {
                setProduct(resp?.retailInsurance);
                // const data = resp?.retailInsurance?.map((item: any) => (
                //     { code: item?.code, name: item?.name, enable: item?.enable, url: item?.url }
                // ))
                // setProduct(data)
            }
        }
        const onError = (err: any) => { console.log("err", err) }
        DEVELOPER_SERVICES.get_retail_policy(userType?.toLowerCase(), onSuccess, onError)
    }

    useEffect(() => {
        getRetailPolicies()
    }, [])

    const updateMaster = (attr: any, value: any) => {
        const [code, key] = attr;
        setProduct((prevProducts) =>
            prevProducts?.map((product) =>
                product.code === code ?
                    { ...product, [key]: value } : product
            )
        );
    }

    const submit = () => {
        if (editMode) {
            const data = product?.map((pro) => {
                if (pro.enable) {
                    if (!(pro.url.includes("https://") || pro.url.includes("http://"))) {
                        return { ...pro, url: `https://${pro.url}` }
                    }
                    return pro;
                }
                return { ...pro, url: "" };
            })
            let dto = {
                retailInsurance: data
            }

            const onSuccess = (res: any) => {
                if (res?.data?.status === "success") {
                    toast.success(res?.data?.message);
                    setEditMode(false);
                    getRetailPolicies();
                }
            }
            const onError = (err: any) => { console.log("err", err) }
            DEVELOPER_SERVICES.submit_retail_policy(userType?.toLowerCase(), dto, onSuccess, onError)

        } else { setEditMode(true) }
    }

    return (
        <>
            <Box className="settingMenuWrapper">
                <Grid container spacing={0} padding={0} >
                    <Grid xs={12}>
                        <h3 className='mb-8'>Retail Insurance</h3>
                    </Grid>
                    <Grid xs={10}>
                        <Box className="retail_section">
                            <Grid container spacing={0} padding={0} columnSpacing={3}>
                                <Grid xs={12}>
                                    {product?.map((item: any, i: number) => (
                                        <Box>
                                            <Grid container columnSpacing={2} className="proContainer">
                                                <Grid xs={6} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                                                    <Box>
                                                        <div className={`product_icon ${item.code}`}></div>
                                                        <h3 className='heading ml-12 mb-0'>{item.name}</h3>
                                                    </Box>
                                                    <Box>
                                                        <input
                                                            type="checkbox"
                                                            className="switch"
                                                            name="products"
                                                            onClick={(e: any) => {
                                                                if (!editMode) return toast.warning("Turn on Edit mode for changes")
                                                                updateMaster([item.code, "enable"], e.target.checked)
                                                            }}
                                                            checked={item.enable}
                                                        // disabled={!editMode}
                                                        />
                                                    </Box>
                                                </Grid>
                                                {item.enable && (
                                                    <Grid xs={6}>
                                                        <Box maxWidth={"400px"}>
                                                            <RKTextField
                                                                title='Url'
                                                                attrName={[item.code, "url"]}
                                                                class_name='inputField inputField2'
                                                                value={item.url}
                                                                value_update={updateMaster}
                                                                validation_type="url"
                                                                warn_status={false}
                                                                // error_messg={item.url.length === 0
                                                                //     ? `Enter Url`
                                                                //     : validateUrl(item.url) === false
                                                                //         ? `Enter a valid Url` : ""}
                                                                disabled={!editMode}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </Box>
                                    ))}
                                </Grid>

                                {/* <Grid xs={12} className="ctaBtn" textAlign={'center'}>
                                    <Button onClick={submit}
                                        variant="contained"
                                        className="submitBtn"
                                    >
                                        {editMode ? "Save" : "Edit"}
                                    </Button>
                                </Grid> */}
                            </Grid>
                            <Grid container spacing={3} className="footerSection">
                                <Grid xs={12} className="ctaBtn">
                                    <Button
                                        onClick={() => navigate(ALL_ROUTES.SETTING)}
                                        variant="outlined"
                                        className="borderBtn"
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        onClick={submit}
                                        variant="contained"
                                        className="submitBtn"
                                    >
                                        {editMode ? "Save" : "Edit"}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid >
                </Grid >
            </Box >
        </>
    )
}

export default Retails