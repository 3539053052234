import { URL_CONSTANTS } from "../../APIVar/URLConstants";
import { GetAPI, PostAPI } from "../API";

const get_selected_columns = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
  code: number | undefined,
  userType:string,
) => {
  GetAPI.call(
    `${URL_CONSTANTS.get_selected_columns(code,userType)}`,
    onSuccess,
    onError
  );
};

const save_selected_columns = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
  code: number | undefined,
  dto: any,
  userType:string,
) => {
  PostAPI.call(
    `${URL_CONSTANTS.save_selected_columns(code,userType)}`,
    dto,
    onSuccess,
    {},
    onError
  );
};

export const TABLE_SERVICES = {
  get_selected_columns,
  save_selected_columns,
};
