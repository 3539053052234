//used
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import { CLIENT_SERVICES } from "../../../../../../Services/Clients/ClientService";
import UGTable from "../../../../../../Supporting files/UGTable/UGTable";
import { E_BE_FILTER_CODE } from "../../../../../../Services/Enum/E_UGTable";

const Contacts = () => {
  const windowURL = window.location.search;
  const params = new URLSearchParams(windowURL);
  const id = params.get("id");
  const [contactList, setcontactList] = useState<any[]>([]);
  const [tableLimit, setTableLimit] = useState(25);
  const [tableSkip, setTableSkip] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const updateMasterState = (attrName: any, value: any) => {};
  const [headerData, setHeaderData] = useState<Array<{}>>([
    {
      field: "First Name",
      headerName: "First Name",
      flex: 2,
      minWidth: 150,
      check_status: true,
    },
    {
      field: "Gender",
      headerName: "Gender",
      flex: 2,
      minWidth: 150,
      check_status: true,
    },
    {
      field: "Email",
      headerName: "Email",
      flex: 2,
      minWidth: 150,
      check_status: true,
    },
    {
      field: "Mobile",
      headerName: "Mobile",
      flex: 8,
      minWidth: 200,
      check_status: true,
    },
    {
      field: "Bulding/Street Name",
      headerName: "Bulding/Street Name",
      flex: 8,
      minWidth: 200,
      check_status: true,
    },
    {
      field: "Area Name",
      headerName: "Area Name",
      flex: 8,
      minWidth: 200,
      check_status: true,
    },
    {
      field: "Country",
      headerName: "Country",
      flex: 8,
      minWidth: 200,
      check_status: true,
    },
    {
      field: "State",
      headerName: "State",
      flex: 8,
      minWidth: 200,
      check_status: true,
    },
    {
      field: "City",
      headerName: "City",
      flex: 8,
      minWidth: 200,
      check_status: true,
    },
  ]);

  // get client contact list
  const client_contact_list = (id: any) => {
    const onSuccess = (res: any) => {
      setcontactList(res.data.data);
    };
    const onError = (err: any) => {
      console.log(".....err", err);
    };
    CLIENT_SERVICES.client_contact_list(onSuccess, onError, id);
  };

  useEffect(() => {
    client_contact_list(id);
  }, []);

  return (
    <>
      <Grid container>
        <Grid xs={12} marginTop={"12px"}>
          <UGTable
            header_data={headerData}
            data={contactList}
            value_update={updateMasterState}
            attrName={"UGTable"}
            BE_filter_code={E_BE_FILTER_CODE.CONTACT}
            refetch_data={() => {
              client_contact_list(id);
            }}
            setTableLimit={setTableLimit}
            setTableSkip={setTableSkip}
            paginationMode="client"
            setPageNumberData={setPageNumber}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Contacts;
