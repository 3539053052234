export enum EMPLOYEE_ALL_ROUTES {
  STAR = "*",
  LOGIN = "/",
  SETTING = "/settings",
  DASHBOARD = "/employee/dashboard",
  CLAIM_ASSISTANT = "/claim-assistant",
  COMMUNITY = "/community",
  DOCTOR_ONLINE = "/doctor-online",
  ENROLLMENT = "/enrollment",
  MY_DOCUMENT = "/my-documents",
  POLICY_WALLET = "/policy-wallet",
  PROFILE = "/profile",
  EMPLOYEE_DETAIL = "/member-detail",
  REPAIR_VEHICLE = "repair-vehicle",
  RISKMAP = "/riskmap",
  SUPPORT = "/support",
  WELLNESS = "/wellness",
  GROUP_POLICY_DETAILS="/group-health-policy",
}
