// import { useEffect } from "react";
// import { BrowserRouter, useLocation } from "react-router-dom";
// import { ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import Check_Route from "./Routes/routeindex";
// import { ORGANISATION_SERVICES } from "./Services/customModule/organisationServices";
// import { userDetailsSlice } from "./Store/Slice_Reducer/UserLogin/UserDetailsSlice";
// import { RootState } from "./Store/Store";
// import { useAppDispatch, useAppSelector } from "./Store/hooks";
// import { applyTheme } from "./ThemeManager";

// function Main() {
//   const usertypeinfo = useAppSelector((state) => state.userDetailsSlice.userType);
//   const location = useLocation();
//   const dispatch = useAppDispatch();

//   useEffect(() => {
//     const search = window.location?.search?.slice(1);
//     const data = search?.split("&")?.[0];
//     if (data === "page=companyDetails") {
//       organisation_details();
//     }
//   }, [location]);

//   const organisation_details = () => {
//     const onSuccess = (res: any) => {
//       dispatch(
//         userDetailsSlice.actions.updateUserDetails([
//           {
//             key: "logo",
//             value: res.data.data[0]?.logo,
//           },
//         ])
//       );
//     };
//     const onError = (err: any) => {
//       console.log("false", err);
//     };
//     ORGANISATION_SERVICES.organisation_details(
//       onSuccess,
//       onError,
//       usertypeinfo.toLocaleLowerCase()
//     );
//   };
//   return (
//     <>
//       <Check_Route />
//     </>
//   );
// }

// function App() {
//   const selectedTheme: number = useAppSelector((state: RootState) => state.userDetailsSlice.appearance.theme);

//   useEffect(() => {
//     applyTheme(selectedTheme);
//   }, [selectedTheme]);

//   return (
//     <>
//       <BrowserRouter>
//         <ToastContainer theme="colored" />
//         <Main />
//       </BrowserRouter>
//     </>
//   );
// }

// export default App;
import React, { useEffect, useCallback } from "react";
import { BrowserRouter, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Check_Route from "./Routes/routeindex";
import { ORGANISATION_SERVICES } from "./Services/customModule/organisationServices";
import { userDetailsSlice } from "./Store/Slice_Reducer/UserLogin/UserDetailsSlice";
import { RootState } from "./Store/Store";
import { useAppDispatch, useAppSelector } from "./Store/hooks";
import { applyTheme } from "./ThemeManager";

const Main = React.memo(() => {
  const usertypeinfo = useAppSelector((state) => state.userDetailsSlice.userType);
  const location = useLocation();
  const dispatch = useAppDispatch();

  const organisation_details = useCallback(() => {
    const onSuccess = (res: any) => {
      dispatch(
        userDetailsSlice.actions.updateUserDetails([
          { key: "logo", value: res.data.data[0]?.logo },
        ])
      );
    };
    const onError = (err: any) => {
      console.log("false", err);
    };
    ORGANISATION_SERVICES.organisation_details(
      onSuccess,
      onError,
      usertypeinfo.toLocaleLowerCase()
    );
  }, [dispatch, usertypeinfo]);

  useEffect(() => {
    const search = window.location?.search?.slice(1);
    const data = search?.split("&")?.[0];
    if (data === "page=companyDetails") {
      organisation_details();
    }
  }, [location, organisation_details]);

  return <Check_Route />;
});

function App() {
  const selectedTheme: number = useAppSelector((state: RootState) => state.userDetailsSlice.appearance.theme);

  useEffect(() => {
    applyTheme(selectedTheme);
  }, [selectedTheme]);

  return (
    <BrowserRouter>
      <ToastContainer theme="colored" />
      <Main />
    </BrowserRouter>
  );
}

export default App;
