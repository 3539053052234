import React, { useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import "../../NewSettings.scss";
import Grid from "@mui/material/Unstable_Grid2";
import { Box, Radio } from "@mui/material";
import LightModeIcon from '@mui/icons-material/LightMode';
import NightsStayIcon from '@mui/icons-material/NightsStay';
import CompareIcon from '@mui/icons-material/Compare';
import { APPREANCE_SERVICES } from '../../../../../../Services/Settings/OrganisationSettings/Appearance';
import { toast } from 'react-toastify';
import { useAppSelector } from '../../../../../../Store/hooks';
import { TUserData } from '../../../../../../Services/Types/TUserData';
import { RootState } from '../../../../../../Store/Store';
import { useDispatch } from 'react-redux';
import { userDetailsSlice } from '../../../../../../Store/Slice_Reducer/UserLogin/UserDetailsSlice';

function Appearance() {
    const { BROKER } = useAppSelector((state: RootState) => state.permissionSlice);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userState: TUserData = useAppSelector((state: RootState) => state.userDetailsSlice);
    const [selectedValue, setSelectedValue] = React.useState(userState.appearance.theme);
    const { userType } = useAppSelector((state) => state.userDetailsSlice);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!BROKER?.Appearance.edit) return toast.error("Edit permissions not allowed");
        let data: number = Number(event.target.value)
        setSelectedValue(data);
        save_appearance_data(data)
        dispatch(userDetailsSlice.actions.updateAppearance(data))

    };

    const save_appearance_data = (data: number) => {
        let dto = {
            appearance: {
                theme: data
            }
        }
        const onSuccess = (res: any) => {
            toast.success("Your theme is updated")
        };

        const onError = (err: any) => {
            console.log(err);
        };
        APPREANCE_SERVICES.save_appearance_data(
            onSuccess,
            onError,
            dto,
            userType.toLowerCase()
        );
    };

    return (
        <Box className="settingMenuWrapper">
            <Grid container spacing={3}>
                <Grid xs={12}>
                    <h3 className='mb-2'>Theme Preferences</h3>
                    <p>Choose how it looks to you. Select a single theme, or automatically switch between day and night themes. </p>
                </Grid>
                <Grid xs={6} md={4} lg={3}>
                    <Box className='themeBox'>
                        <Grid container spacing={0} alignItems="center">
                            <Grid xs={10}>
                                <h5><LightModeIcon /> Light Default</h5>
                            </Grid>
                            <Grid xs={2}>
                                <Radio
                                    checked={selectedValue === 0}
                                    onChange={handleChange}
                                    value={0}
                                    name="radio-buttons"
                                    inputProps={{ 'aria-label': 'A' }}

                                />
                            </Grid>
                        </Grid>
                        <hr />
                        <Grid container spacing={3}>
                            <Grid xs={12} className="text-center">
                                <img src='./images/light_theme_img.svg' />
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                {/* <Grid xs={6} md={4} lg={3}>
                    <Box className='themeBox darkTheme'>
                        <Grid container spacing={0} alignItems="center">
                            <Grid xs={10}>
                                <h5><NightsStayIcon /> Dark Dimmed</h5>
                            </Grid>
                            <Grid xs={2}>
                                <Radio
                                    checked={selectedValue === 1}
                                    onChange={handleChange}
                                    value={1}
                                    name="radio-buttons"
                                    inputProps={{ 'aria-label': 'B' }}

                                />
                            </Grid>
                        </Grid>
                        <hr />
                        <Grid container spacing={3}>
                            <Grid xs={12} className="text-center">
                                <img src='./images/dark_theme_img.svg' />
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                <Grid xs={6} md={4} lg={3}>
                    <Box className='themeBox'>
                        <Grid container spacing={0} alignItems="center">
                            <Grid xs={10}>
                                <h5><CompareIcon /> Auto Switch</h5>
                            </Grid>
                            <Grid xs={2}>
                                <Radio
                                    checked={selectedValue === 2}
                                    onChange={handleChange}
                                    value={2}
                                    name="radio-buttons"
                                    inputProps={{ 'aria-label': 'C' }}
                                />
                            </Grid>
                        </Grid>
                        <hr />
                        <Grid container spacing={3}>
                            <Grid xs={12} className="text-center">
                                <img src='./images/auto_theme_img.svg' />
                            </Grid>
                        </Grid>
                    </Box>
                </Grid> */}
            </Grid>
        </Box>
    )
}

export default Appearance