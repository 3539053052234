import { URL_CONSTANTS } from "../../../APIVar/URLConstants";
import { GetAPI, PostAPI } from "../../API";

const submit_retail_policy = (
    user: string,
    dto: any,
    onSuccess: (data: any) => void,
    onError: (err: any) => void
) => {
    PostAPI.call(
        `${URL_CONSTANTS.get_retail_policies(user)}`,
        dto,
        onSuccess,
        {},
        onError
    );
};

const get_retail_policy = (
    user: string,
    onSuccess: (data: any) => void,
    onError: (err: any) => void
) => {
    GetAPI.call(
        `${URL_CONSTANTS.get_retail_policies(user)}`,
        onSuccess,
        {},
        onError
    );
};

export const DEVELOPER_SERVICES = {
    submit_retail_policy,
    get_retail_policy,
}