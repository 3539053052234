export const type_data = [
  { key: "Name", value: "Name" },
  { key: "Personal Address", value: "Personal Address" },
  { key: "Contact Number", value: "Contact Number" },
  { key: "Gender", value: "Gender" },
  { key: "Email", value: "Email" },
  { key: "Date of Birth", value: "Date of Birth" },
];
export const gender_data = [
  { key: "male", value: "Male" },
  { key: "female", value: "Female" },
  { key: "others", value: "Others" },
];
export const supporting_document_data = [
  { key: "Aadhar", value: "Aadhar" },
  { key: "PanCard", value: "Pan Card" },
  { key: "DrivingLiscense", value: "Driving Liscense" },
  { key: "Passport", value: "Passport" },
  { key: "Others", value: "Others" },
];
