import { URL_CONSTANTS } from "../../APIVar/URLConstants";
import { GetAPI, PostAPI, DeleteAPI, PatchAPI } from "../API";
import {
  groupTravelInsuranceAddDTO,
  groupTravelInsuranceDeleteDTO,
  groupTravelInsuranceExculsionAddDTO,
  groupTravelInsuranceFeatureAddDTO,
} from "../DTO/ProductConfigurator/GroupTravelInsuranceDTO";

const createCatageory = (
  dto: groupTravelInsuranceAddDTO[],
  onSuccess: any,
  config: any,
  onError: any
) => {
  PostAPI.call(
    URL_CONSTANTS.create_group_travel_catageory,
    dto,
    onSuccess,
    {},
    onError
  );
};
const getCatageory = (
  searchBarFilter:any,
  onSuccess: (data: any) => void,
  onError: (err: any) => void
) => {
  GetAPI.call(`${URL_CONSTANTS.get_group_travel_catageory}${searchBarFilter}`, onSuccess, onError);
};
const deleteCatageory = (
  dto: groupTravelInsuranceDeleteDTO,
  onSuccess: (data: any) => void,
  config: any,
  onError: (err: any) => void
) => {
  DeleteAPI.call(
    URL_CONSTANTS.delete_group_travel_catageory,
    dto,
    onSuccess,
    {},
    onError
  );
};
const updateCatageory = (
  dto: groupTravelInsuranceAddDTO,
  onSuccess: any,
  config: any,
  onError: any,
  id: string | undefined
) => {
  PatchAPI.call(
    `${URL_CONSTANTS.update_group_travel_catageory}${id}`,
    dto,
    onSuccess,
    {},
    onError
  );
};
// exculsion api method
const getExculsion = (
  searchBarFilter:any,
  onSuccess: (data: any) => void,
  onError: (err: any) => void
) => {
  GetAPI.call(`${URL_CONSTANTS.get_group_travel_exclsuion}${searchBarFilter}`, onSuccess, onError);
};
const createExcuslion = (
  dto: groupTravelInsuranceExculsionAddDTO[],
  onSuccess: any,
  config: any,
  onError: any
) => {
  PostAPI.call(
    URL_CONSTANTS.create_group_travel_exclusion,
    dto,
    onSuccess,
    {},
    onError
  );
};
const updateExclusion = (
  dto: groupTravelInsuranceExculsionAddDTO,
  onSuccess: any,
  config: any,
  onError: any,
  id: string | undefined
) => {
  PatchAPI.call(
    `${URL_CONSTANTS.update_group_travel_exclusion}${id}`,
    dto,
    onSuccess,
    {},
    onError
  );
};
const deleteExclusion = (
  dto: groupTravelInsuranceDeleteDTO,
  onSuccess: (data: any) => void,
  config: any,
  onError: (err: any) => void
) => {
  DeleteAPI.call(
    URL_CONSTANTS.delete_group_travel_exclusion,
    dto,
    onSuccess,
    {},
    onError
  );
};
// feature api method
const getFeature = (
  searchBarFilter:any,
  onSuccess: (data: any) => void,
  onError: (err: any) => void
) => {
  GetAPI.call(`${URL_CONSTANTS.get_group_travel_feature}${searchBarFilter}`, onSuccess, onError);
};
const createFeature = (
  dto: groupTravelInsuranceFeatureAddDTO[],
  onSuccess: any,
  config: any,
  onError: any
) => {
  PostAPI.call(
    URL_CONSTANTS.create_group_travel_feature,
    dto,
    onSuccess,
    {},
    onError
  );
};
const updateFeature = (
  dto: groupTravelInsuranceFeatureAddDTO,
  onSuccess: any,
  config: any,
  onError: any,
  id: string | undefined
) => {
  PatchAPI.call(
    `${URL_CONSTANTS.update_group_travel_feature}${id}`,
    dto,
    onSuccess,
    {},
    onError
  );
};
const deleteFeature = (
  dto: groupTravelInsuranceDeleteDTO,
  onSuccess: (data: any) => void,
  config: any,
  onError: (err: any) => void
) => {
  DeleteAPI.call(
    URL_CONSTANTS.delete_group_travel_feature,
    dto,
    onSuccess,
    {},
    onError
  );
};
export const GROUP_TRAVEL_INSURANCE_SERVICES = {
  createCatageory,
  getCatageory,
  deleteCatageory,
  updateCatageory,
  getExculsion,
  createExcuslion,
  updateExclusion,
  deleteExclusion,
  getFeature,
  createFeature,
  updateFeature,
  deleteFeature,
};
