import { Box, Button, Link } from "@mui/material";
import React, { useState, useRef, useEffect } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import "./FeaturesAndExclusion.scss";
import { EMPLOYER_POLICIES_SERVICES } from "../../../Services/EmployerServices/Policies/PoliciesServices";
import { EMPLOYER_URL_CONSTANTS } from "../../../APIVar/EmployerUrlConstant";
import NewLoader from "../../NewLoader/NewLoader";
interface Props {
  type: any;
}

const EmployerFeaturesAndExclusion: React.FC<Props> = ({ type }) => {
  const [gradedSectionData, setGradedSectionDta] = useState(0);
  const [featuresAndExclusions, setfeaturesAndExclusions] = useState<any>({});
  const [showLoader, setShowLoader] = useState(true);

  const get_policy_feature_tab = (id: string) => {
    const onSuccess = (res: any) => {
      if (res.status === 200) {
        setTimeout(() => {
          setShowLoader(false);
        }, 1500);
      }
      setfeaturesAndExclusions(res.data.data);
    };
    const onError = (err: any) => {
      console.log("err", err);
      setShowLoader(false);
    };
    EMPLOYER_POLICIES_SERVICES.get_policy_detail_view(
      `${EMPLOYER_URL_CONSTANTS.get_policy_detail_view}${id}&tab=FEATURES`,
      onSuccess,
      onError
    );
  };

  useEffect(() => {
    const windowURL = window.location.search;
    const params = new URLSearchParams(windowURL);
    const id = params.get("id");
    get_policy_feature_tab(id ?? "");
    setShowLoader(true);
  }, []);

  return (
    <>
      {showLoader && (<NewLoader />)}
      <>
        {type === "GRADED" && featuresAndExclusions?.length > 0 && (
          <Box className="voluntry_plans_outer">
            <Grid container spacing={3}>
              <Grid xs={12}>
                <ul className="coverageAmt">
                  {featuresAndExclusions?.map((data: any, index: number) => (
                    <li>
                      <div className="radioBox ">
                        <input
                          type="radio"
                          name="coverages"
                          onClick={() => setGradedSectionDta(index)}
                          defaultChecked={index === 0 ? true : false}
                        />
                        <label>Grade {index + 1}</label>
                      </div>
                    </li>
                  ))}
                </ul>
              </Grid>
            </Grid>
          </Box>
        )}

        <Box className="voluntry_plans">
          <Grid className="voluntry_plans_fea"
            container
            spacing={3}
          >
            <Grid md={6} lg={5}>
              <div className="voluntry_features">
                <div>
                  <div className="feature_img">
                    <img
                      src="../images/voluntry_feature_img.svg"
                      alt=""
                      className="voluntry_feature_img"
                    />
                    <h6>Features</h6>
                    <span className="action_edit">
                      {/* <Link
                      className="action_btn action_btn_feature"
                      onClick={() => {
                        setAddFeature(true);
                      }}>
                      <BorderColorIcon />
                    </Link> */}
                    </span>
                  </div>
                </div>
                {featuresAndExclusions[gradedSectionData]?.featureData?.map((data: any) => (
                  <div className="medical_cover mt-6">
                    <h5>{data.category}</h5>
                    {data?.features?.featureListData?.map((item1: any) => (
                      <>
                        <Grid container spacing={3} className="px-0 pb-2"
                          style={{ borderBottom: "1px solid #F5BE4C" }}
                        >
                          {
                            item1.featureDetails ? (
                              <>
                                <Grid xs={12} className="pb-2">
                                  <h4 className="mb-0" style={{ color: "#de9909" }}>
                                    {item1?.featureDetails?.name}
                                  </h4>
                                </Grid>
                                <Grid xs={12} className="pb-2 pt-0 word_break">
                                  <h6>{item1?.value}{" "}
                                    {item1?.featureDetails?.unit ===
                                      "Percentage"
                                      ? "%"
                                      : item1?.featureDetails?.unit ===
                                        "Currency"
                                        ? ""
                                        : item1?.featureDetails?.unit ===
                                          "Free Text"
                                          ? item1?.featureDetails?.customUnitType
                                          : item1?.featureDetails?.unit}</h6>
                                </Grid>
                                <Grid xs={12} className="pb-0 pt-0">
                                  <p>
                                    {item1?.featureDetails?.description}
                                  </p>
                                </Grid>
                              </>
                            ) : (
                              <>
                                <Grid xs={12} className="pb-2">
                                  <h4 className="mb-0" style={{ color: "#de9909" }}>
                                    {item1?.name}
                                  </h4>
                                </Grid>
                                <Grid xs={12} className="pb-2 pt-0 word_break">
                                  <h6>{item1?.value}{" "}
                                    {item1?.unit === "Percentage"
                                      ? "%"
                                      : item1?.unit === "Currency"
                                        ? ""
                                        : item1?.unit === "Free Text"
                                          ? item1?.customUnitType
                                          : item1?.unit}</h6>
                                </Grid>
                                <Grid xs={12} className="pb-0 pt-0">
                                  <p>{item1?.description} </p>
                                </Grid>
                              </>
                            )}
                        </Grid>
                      </>
                    ))}
                  </div>
                ))}
      
              </div>
            </Grid>
            <Grid md={6} lg={5}>
              <div className="voluntry_exclusion">
                <div className="exclusion_img">
                  <img src="../images/voluntry_exclusion_img.svg" alt="" />
                  <h6>Exclusion</h6>
                  <span className="action_edit">
                    {/* <Link
                    href=""
                    className="action_btn"
                    // onClick={() => {
                    //   setAddExclusion(true);
                    // }}
                  >
                    <BorderColorIcon />
                  </Link> */}
                  </span>
                </div>
                <div className="exclusion_para">
                  {featuresAndExclusions[gradedSectionData]?.exclusion?.map(
                    (data: any, index: any) => (
                      <>
                        <h6 style={{ color: "#e47d73" }}>
                          {index + 1}. {data.name || data.title}
                        </h6>
                        <p>{data.description}</p>
                        {index === data.length - 1 ? null : <hr />}
                      </>
                    )
                  )}
                </div>
              </div>
            </Grid>
          </Grid>
        </Box>
      </>
    </>
  );
}

export default EmployerFeaturesAndExclusion;
