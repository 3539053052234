import { useNavigate } from "react-router-dom";
import { ALL_ROUTES } from "../../../../Routes/all_routes";
import { EMPLOYER_ALL_ROUTES } from "../../../../Routes/employer_all_routes";

import "./TabList.scss";

const TabList = ({
  className,
  buttonText,
  path,
}: {
  className: string;
  buttonText: any;
  path: ALL_ROUTES | EMPLOYER_ALL_ROUTES;
}) => {
  const navigate = useNavigate();
  return (
    <button
      type="submit"
      className={className}
      onClick={() => {
        navigate(path);
      }}>
      {buttonText}
    </button>
  );
};

export default TabList;
