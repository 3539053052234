import { Box, Button, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useState } from "react";
import SlidingPanel from "react-sliding-side-panel";
import CloseIcon from "@mui/icons-material/Close";

// Defining Props interface for component
interface Props {
  open_status: boolean;
  attrName: any;
  value_update: Function;
}

const CarRetailPolicy: React.FC<Props> = ({
  open_status,
  attrName,
  value_update,
}) => {
  return (
    <>
      <SlidingPanel
        type={"right"}
        isOpen={open_status}
        size={75}
        panelClassName="slidingPanel"
        // backdropClicked={() => value_update(attrName, false)}
      >
        <>
          <Grid container spacing={2} alignItems="center">
            <Grid xs={10} className="headingTitle">
              <h3>
                <span>Car Insurance Policy</span>
              </h3>
            </Grid>
            <Grid xs={2} textAlign="right">
              <Link
                className="closeIcon"
                onClick={() => {
                  value_update(attrName, false);
                }}
              >
                <CloseIcon />
              </Link>
            </Grid>
            <Grid xs={12}>
              <hr />
            </Grid>
          </Grid>
          <Box className="scrollable_area">
            <Grid container spacing={3}>
              <Grid xs={6} className="policy_number">
                <h6>
                  Policy <span> #PROHL96839463</span>
                </h6>
              </Grid>
              <Grid xs={6} className="premium text-right">
                <h5>
                  Premium <span>(Incl. GST)</span> <b>₹ 12,086</b>
                </h5>
              </Grid>
              <Grid xs={12} className="healthretail_policy">
                <div className="healthretail_innersection">
                  <ul>
                    <li>
                      <img
                        src="./images/insurerlogo/digit.svg"
                        alt=""
                        height={32}
                      />
                    </li>
                    <li>
                      Vehicle IDV <span>₹ 6,45,986</span>
                    </li>
                    <li className="text-right">
                      Hyundai Creta Diesel, 1.6 SX (O) 1582 cc
                      <span>DL01ABC1234</span>
                    </li>
                  </ul>
                </div>
              </Grid>
              <Grid xs={6}>
                <div className="policy_expiry mb-4">
                  <img src="./images/paymentdone_icon.svg" alt="" height={40} />
                  <h5>
                    Payment Done
                    <span>12th Jun, 2022</span>
                  </h5>
                </div>
                <div className="policy_expiry">
                  <img
                    src="./images/policyexpiry_icon.svg"
                    alt=""
                    height={40}
                  />
                  <h5>
                    Policy Expiry Date
                    <span>24th Jun, 2023</span>
                  </h5>
                </div>
              </Grid>
              <Grid xs={6} className="policy_cover">
                <h5>This Policy Covers:</h5>
                <ul>
                  <li>PA Owner Cover </li>
                  <li>24x7 Roadside Assistance</li>
                  <li>Zero Depreciation</li>
                </ul>
              </Grid>
              <Grid xs={12}>
                <ul className="retailPolicy_button">
                  <li>
                    <Button className="policyBtn endorsement">
                      Endorsement
                    </Button>
                  </li>
                  <li>
                    <Button className="policyBtn claim">Register Claim</Button>
                  </li>
                  <li>
                    <Button className="policyBtn download">Download Policy</Button>
                  </li>
                </ul>
              </Grid>
            </Grid>
          </Box>
          <Grid container spacing={3}>
            <Grid xs={12} className="ctaBtn mt-4">
              <Button
                variant="contained"
                className="submitBtn fixedbtn"
                onClick={() => {}}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </>
      </SlidingPanel>
    </>
  );
};

export default CarRetailPolicy;
