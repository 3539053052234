import React from 'react'
import { useNavigate } from 'react-router-dom'

function Templates() {
  const navigate = useNavigate()
  
  return (
    <div>Templates</div>
  )
}

export default Templates