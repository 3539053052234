import { Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React from "react";
import { useNavigate } from "react-router-dom";
import "../../Broker/SPOCTable/SpocTable.scss";
function SpocTable({ data }: { data: any }) {
  const navigate = useNavigate();
  const [spocContact, setSpocContact] = React.useState("");
  const [contactName, setContactName] = React.useState("");
  const [jobTitle, setJobTitle] = React.useState("");
  const [contactEmail, setContactEmail] = React.useState("");
  const [contactMobile, setContactMobile] = React.useState("");
  const [contactNumber, setContactNumber] = React.useState("");
  const [currentEmployer, setCurrentEmployer] = React.useState("");
  const [contactOrganization, setContactOrganization] = React.useState("");
  const [contactDepartment, setContactDepartment] = React.useState("");

  const updateMasterState = (attrName: any, value: any) => {
    attrName(value);
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid xs={12}>
          <div className="table">
            <div className="spoc-table">
              <ul className="heading">
                <li>
                  Name
                  <button className="sorting-btn"></button>
                </li>
                <li>
                  Type
                  <button className="sorting-btn"></button>
                </li>
         
                <li>
                  Spoc
                  <button className="sorting-btn"></button>
                </li>
                <li>
                  Area Name
                  <button className="sorting-btn"></button>
                </li>
                <li>
                  Street
                  <button className="sorting-btn"></button>
                </li>
                <li>
                  Country
                  <button className="sorting-btn"></button>
                </li>
                <li>
                  State
                  <button className="sorting-btn"></button>
                </li>
                <li>
                  City
                  <button className="sorting-btn"></button>
                </li>
                <li>
                  Zip Code
                  <button className="sorting-btn"></button>
                </li>
       
              </ul>

              {data?.map((data_table: any, index: number) => (
                <ul className="subheading">
                  <li>{data_table?.name}</li>
                  <li>{data_table?.type}</li>
                  <li>{data_table?.spocId}</li>
                  <li>{data_table?.area}</li>
                  <li>{data_table?.street}</li>
                  <li>{data_table?.country}</li>
                  <li>{data_table?.state}</li>
                  <li>{data_table?.city}</li>
                  <li>{data_table?.zip}</li>
                  <li>
                    <Link className="edit_address_link">
                      <img src={data_table?.actionBtn} alt="" />
                    </Link>
                  </li>
                </ul>
              ))}
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
}

export default SpocTable;
