import { URL_CONSTANTS } from "../../APIVar/URLConstants";

import { DeleteAPI, GetAPI, PatchAPI, PostAPI, PutAPI } from "../API";
import {
  AddInsurerAttachmentDTO,
  AddQuoteInsurerDTO,
  AddQuoteNoteDTO,
} from "../DTO/QuotesDTO";

// get quotes list
const get_quotes_list_view = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
  limit: number,
  skip: number,
  starDate: any,
  endDate: any,
  searchValue: string
) => {
  GetAPI.call(
    URL_CONSTANTS.get_quotes_list(limit, skip, starDate, endDate, searchValue),
    onSuccess,
    {},
    onError
  );
};

const submit_add_quotes = (
  dto: any,
  onSuccess: (data: any) => void,
  onError: (err: any) => void
) => {
  PostAPI.call(
    `${URL_CONSTANTS.submit_add_quotes}`,
    dto,
    onSuccess,
    {},
    onError
  );
};

const get_quotes_details = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
  id: any
) => {
  GetAPI.call(
    `${URL_CONSTANTS.get_quotes_details}${id}`,
    onSuccess,
    {},
    onError
  );
};

const get_quote_tab_data = (
  onSuccess: any,
  onError: any,
  quoteId: string,
  tabName: string
) => {
  GetAPI.call(
    URL_CONSTANTS.get_quote_tab_data(quoteId, tabName),
    onSuccess,
    {},
    onError
  );
};

const add_insurer_attachment = (
  onSuccess: any,
  onError: any,
  dto: AddInsurerAttachmentDTO
) => {
  PostAPI.call(
    URL_CONSTANTS.add_insurer_attachment,
    dto,
    onSuccess,
    {},
    onError
  );
};

const add_quote_note = (
  onSuccess: any,
  onError: any,
  dto: AddQuoteNoteDTO,
  quoteId: string
) => {
  PatchAPI.call(
    URL_CONSTANTS.add_quote_note(quoteId),
    dto,
    onSuccess,
    {},
    onError
  );
};

const add_insurer = (onSuccess: any, onError: any, dto: AddQuoteInsurerDTO) => {
  PostAPI.call(URL_CONSTANTS.add_quote_insurer, dto, onSuccess, {}, onError);
};

const update_quotes_details = (
  dto: any,
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
  id: any
) => {
  PatchAPI.call(
    `${URL_CONSTANTS.update_quotes_details}${id}`,
    dto,
    onSuccess,
    {},
    onError
  );
};

const quote_individual_edit = (
  dto: any,
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
  quoteId: string
) => {
  PutAPI.call(
    URL_CONSTANTS.quote_individual_edit(quoteId),
    dto,
    onSuccess,
    {},
    onError
  );
};

const add_quote_attachment = (onSuccess: any, onError: any, dto: any) => {
  PostAPI.call(URL_CONSTANTS.add_quote_attachment, dto, onSuccess, {}, onError);
};

const delete_quote_attachment = (onSuccess: any, onError: any, dto: any, attachmentsId: string, quoteId: string) => {
  DeleteAPI.call(`${URL_CONSTANTS.add_insurer_attachment}?attachmentsId=${attachmentsId}&quoteId=${dto}`, dto, onSuccess, {}, onError);
}
const delete_attachment = (onSuccess: any, onError: any, dto: any, attachmentsId: string) => {
  DeleteAPI.call(`${URL_CONSTANTS.quote_delete_attachment}?attachmentsId=${attachmentsId}&quoteId=${dto}`, dto, onSuccess, {}, onError);
}


const get_status = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
) => {
  GetAPI.call(
    URL_CONSTANTS.get_status,
    onSuccess,
    {},
    onError
  );
};
const update_status = (
  dto: any,
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
  id: any
) => {
  PatchAPI.call(
    `${URL_CONSTANTS.update_status}${id}`,
    dto,
    onSuccess,
    {},
    onError
  );
};



export const QUOTES_SERVICES = {
  get_quotes_list_view,
  submit_add_quotes,
  get_quotes_details,
  get_quote_tab_data,
  add_insurer_attachment,
  add_quote_note,
  add_insurer,
  update_quotes_details,
  quote_individual_edit,
  add_quote_attachment,
  get_status,
  update_status,
  delete_quote_attachment,
  delete_attachment,
};
