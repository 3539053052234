import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, ReferenceLine } from 'recharts';

const UGGraph = ({ data, product_type }: { data: any, product_type: any }) => {
  const allColor = "#AC43FF";
  const carColor = "#E4506F";
  const bikeColor = "#4FA13B";
  const healthColor = "#FC6509";
  const termColor = "#FDC031";
  const investmentColor = "#3569B6";
  const travelColor = "#AC43FF";
  const commercialColor = "#3A4855";
  const giColor = "#1EBC96";
  // const dataa = [
  //   {
  //     "name": "05 Mar 24",
  //     "Dependent": 50,
  //     "Employee": 1
  //   },
  //   {
  //     "name": "06 Mar 24",
  //     "Dependent": 10,
  //     "Employee": 0
  //   },
  //   {
  //     "name": "07 Mar 24",
  //     "Dependent": 0,
  //     "Employee": 20
  //   },
  //   {
  //     "name": "08 Mar 24",
  //     "Dependent": 0,
  //     "Employee": 50
  //   },
  //   {
  //     "name": "09 Mar 24",
  //     "Dependent": 0,
  //     "Employee": 0
  //   },
  //   {
  //     "name": "10 Mar 24",
  //     "Dependent": 0,
  //     "Employee": 0
  //   },
  //   {
  //     "name": "11 Mar 24",
  //     "Dependent": 0,
  //     "Employee": 0
  //   },
  //   {
  //     "name": "12 Mar 24",
  //     "Dependent": 0,
  //     "Employee": 0
  //   },
  //   {
  //     "name": "13 Mar 24",
  //     "Dependent": 0,
  //     "Employee": 0
  //   }
  // ]

  return (
    <AreaChart style={{ width: '100%' }} width={1050} height={300} data={data}
      margin={{ top: 10, right: 10, left: 0, bottom: 0 }}>
      <defs>
        <linearGradient id="color" x1="0" y1="0" x2="0" y2="1">
          <stop offset="5%"
            stopColor={
              product_type === "all"
                ? allColor
                : product_type === "car"
                  ? carColor
                  : product_type === "bike"
                    ? bikeColor
                    : product_type === "health"
                      ? healthColor
                      : product_type === "term"
                        ? termColor
                        : product_type === "investment"
                          ? investmentColor
                          : product_type === "travel"
                            ? travelColor
                            : product_type === "commercial"
                              ? commercialColor
                              : product_type === "gi"
                                ? giColor
                                : ""
            }
            stopOpacity={0.4} />
          <stop offset="95%" stopColor="#fff" stopOpacity={0} />
        </linearGradient>
      </defs>
      <XAxis dataKey="name" />
      <YAxis />
      <CartesianGrid strokeDasharray="0 0" strokeOpacity={0.3} />
      <Tooltip />
      <Area
        type="monotone"
        dataKey="Employee"
        stroke={carColor}
        fillOpacity={1}
        fill="url(#color)"
      />
      <Area
        type="monotone"
        dataKey="Dependent"
        stroke={bikeColor}
        fillOpacity={1}
        fill="url(#color)"
      />
    </AreaChart>

  );
}
export default UGGraph;
