import {
  MODAL_TOGGEL_CLOSE,
  MODAL_TOGGEL_OPEN,
} from "../constants/userConstants";

let initState = {
  title: "",
  isOpen: false,
  data: null,
};

export const modalReducer = (state = initState, action) => {
  switch (action.type) {
    case MODAL_TOGGEL_CLOSE:
      return { isOpen: false };

    case MODAL_TOGGEL_OPEN:
      return { isOpen: true, title: action.title, data: action.data, path: action.path };

    default:
      return state;
  }
};
