import React from "react";
import { SortableElement, SortableElementProps } from "react-sortable-hoc";
import { Checkbox } from "@mui/material";
import DragIndicatorOutlinedIcon from "@mui/icons-material/DragIndicatorOutlined";
import "./UGTable.scss";

interface Props extends SortableElementProps {
  key: string;
  index: number;
  value: any;
  BE_filter_code: any;
  onClickFilterCheckbox: any;
}

const SortableItem: React.FC<Props> = ({
  key,
  index,
  value,
  BE_filter_code,
  onClickFilterCheckbox,
}) => {
  return (
      <div className="draggableItem">
        <Checkbox
          size="small"
          value={value.check_status}
          onChange={(e) => onClickFilterCheckbox(value, e.target.checked)}
          checked={value.check_status}
        />
        {value.headerName}

        {BE_filter_code?.toString() && (
          <DragIndicatorOutlinedIcon
            style={{
              color: "#d1d5db",
              marginLeft: "auto",
              cursor: "grab",
              userSelect: "none",
              height: "18px",
            }}
          />
        )}
      </div>
  );
};

export default SortableElement(SortableItem);
