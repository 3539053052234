import React, { useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import SlidingPanel from "react-sliding-side-panel";
import RKTextField from "../../../../../../../Supporting files/RKTextField/RKTextField";
import { DEPARTMENT_SERVICES } from "../../../../../../../Services/customModule/departmentServices";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Box, Link, Modal } from "@mui/material";
import { GridCloseIcon } from "@mui/x-data-grid";

interface Props {
  open_status: boolean;
  attrName: any;
  value_update: Function;
}

const DepartmentInformation: React.FC<Props> = ({
  open_status,
  attrName,
  value_update,
}) => {
  const [departmentName, setDepartmentName] = React.useState("");
  const [departmentWarn, setDepartmentWarn] = React.useState(false);
  const [disableSave, setDisableSave] = useState<boolean>(false);

  const updateMasterState = (attrName: any, value: any) => {
    let key = attrName[0];
    if (key === "department") {
      setDepartmentName(value);
      setDepartmentWarn(false);
    }
  };

  //post Branches data
  const post_branches_data = () => {
    if (disableSave) return;
    let data: any = departmentWarn;
    data = departmentName.length === 0 ? true : false;
    setDepartmentWarn(data);

    if (data === false) {
      let body = {
        departmentName: departmentName,
      };
      const onSuccess = (res: any) => {
        toast.success(" Saved successfully");
        value_update("createDepartment", false);
        setDepartmentName("");
        setDisableSave(false);
      };
      const onError = (err: any) => {
        setDisableSave(false);
        console.log("Department", err);
      };
      setDisableSave(true);
      DEPARTMENT_SERVICES.post_department_data(body, onSuccess, onError);
    }
  };

  function handleKeyPress(event: any) {
    if (event.key === "Enter") {
      post_branches_data();
    }
  }

  return (
    <SlidingPanel
      type={"right"}
      isOpen={open_status}
      size={75}
      // backdropClicked={() => value_update(attrName, false)}
    >
      <div >
        <Grid container spacing={3} alignItems="center">
          <Grid xs={10}>
            <h4 className="mb-0 mt-4">Department Information</h4>
          </Grid>
          <Grid xs={2} textAlign="end" className="mt-3">
            <GridCloseIcon
              onClick={() => {
                setDepartmentName("");
                value_update("createDepartment", false)
              }}
            />
          </Grid>
          <Grid xs={12} className="mb-5">
            <hr />
          </Grid>
        </Grid>
        <div className="scrollable_area">
          <Grid container spacing={3}>
            <Grid xs={12}>
              <RKTextField
                class_name="inputField"
                title="Department Name"
                value={departmentName}
                attrName={["department"]}
                value_update={updateMasterState}
                warn_status={departmentWarn}
                error_messg="Enter Department"
              />
            </Grid>
          </Grid>
        </div>
        <Grid container spacing={3}>
          <Grid xs={12} className="ctaBtn mt-4">
            <Button
              variant="contained"
              className="submitBtn fixedbtn"
              onClick={post_branches_data}
              disabled={disableSave}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </div>
    </SlidingPanel>
  );
};

export default DepartmentInformation;
