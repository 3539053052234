import { Box, Button, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useState } from "react";
import SlidingPanel from "react-sliding-side-panel";
import CloseIcon from "@mui/icons-material/Close";

// Defining Props interface for component
interface Props {
  open_status: boolean;
  attrName: any;
  value_update: Function;
}

const HealthRetailPolicy: React.FC<Props> = ({
  open_status,
  attrName,
  value_update,
}) => {
  return (
    <>
      <SlidingPanel
        type={"right"}
        isOpen={open_status}
        size={75}
        panelClassName="slidingPanel"
        // backdropClicked={() => value_update(attrName, false)}
      >
        <>
          <Grid container spacing={2} alignItems="center">
            <Grid xs={10} className="headingTitle">
              <h3>
                <span>Health Insurance Policy</span>
              </h3>
            </Grid>
            <Grid xs={2} textAlign="right">
              <Link
                className="closeIcon"
                onClick={() => {
                  value_update(attrName, false);
                }}
              >
                <CloseIcon />
              </Link>
            </Grid>
            <Grid xs={12}>
              <hr />
            </Grid>
          </Grid>
          <Box className="scrollable_area">
            <Grid container spacing={3}>
              <Grid xs={12} className="healthretail_policy">
                <div className="healthretail_innersection">
                  <ul>
                    <li>
                      <img
                        src="./images/insurerlogo/digit.svg"
                        alt=""
                        height={32}
                      />
                    </li>
                    <li>
                      Sum Insured <span>₹ 6 Lakh</span>
                    </li>
                    <li className="text-right">
                      Coverage for <span>2 Adults & 2 Children</span>
                    </li>
                  </ul>
                </div>
              </Grid>
              <Grid xs={6}>
                <div className="policy_expiry">
                  <img
                    src="./images/policyexpiry_icon.svg"
                    alt=""
                    height={40}
                  />
                  <h5>
                    Policy Expiry Date
                    <span>24th Jun, 2023</span>
                  </h5>
                </div>
              </Grid>
              <Grid xs={6} className="text-right" sx={{ alignSelf: "center" }}>
                <div className="actionBtns">
                  <Link
                    className="redBtn delete"
                    sx={{ textDecoration: "none", cursor: "pointer" }}
                  >
                    Remove
                  </Link>
                </div>
              </Grid>
            </Grid>
          </Box>
          <Grid container spacing={3}>
            <Grid xs={12} className="ctaBtn mt-4">
              <Button
                variant="contained"
                className="submitBtn fixedbtn"
                onClick={() => {}}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </>
      </SlidingPanel>
    </>
  );
};

export default HealthRetailPolicy;
