import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Box, Button, Link, Modal } from "@mui/material";
import SelectDropdown from "../../../../../Supporting files/SelectDropdown/SelectDropdown";
import RKTextField from "../../../../../Supporting files/RKTextField/RKTextField";
import DatePicker from "../../../../../Supporting files/DatePicker/DatePicker";
import SearchDropdown from "../../../../../Supporting files/SearchDropdown/SearchDropdown";
import SlidingPanel from "react-sliding-side-panel";
import "./../TaskManagement.scss";
import { TextField } from "@material-ui/core";
import { setPriority } from "os";

interface Props {
  open_status: boolean;
  attrName: any;
  value_update: Function;
}

const CreateTask: React.FC<Props> = ({
  open_status,
  attrName,
  value_update,
}) => {
  const [subject, setSubject] = React.useState("");
  const [module, setModule] = React.useState("");
  const [assignee, setAssignee] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [task, setTask] = React.useState("");
  const [priority, setPriority] = React.useState("Highest");
  const [dueDate, setDueDate] = React.useState("30-12-2022");
  const [addsubTask, setAddSubTask] = React.useState<
    Array<{ task: string; deletable: boolean }>
  >([{ task: "", deletable: false }]);

  const module_data = [
    { key: "Policies", value: "Policies" },
    { key: "Policies", value: "Policies" },
    { key: "Policies", value: "Policies" },
  ];
  const assignee_data = [
    { key: "Rahul", value: "Rahul" },
    { key: "Rohan", value: "Rohan" },
    { key: "Sahil", value: "Sahil" },
  ];
  const priority_data = [
    { key: "Highest", value: "Highest" },
    { key: "Normal", value: "Normal" },
    { key: "Lowest", value: "Lowest" },
  ];
  const dueDate_data = [
    { key: "30-12-2022", value: "30-12-2022" },
    { key: "30-11-2022", value: "30-11-2022" },
    { key: "30-10-2022", value: "30-12-2022" },
  ];

  const updateMasterState = (
    attrName: any,
    value: string | boolean | number
  ) => {
    attrName(value);
  };

  const [pageStatus, setPageStatus] = React.useState<number>(1);

  const changePageStatus = (status: boolean) => {
    if (status === true) {
      if (pageStatus < 2) {
        setPageStatus(pageStatus + 1);
      }
    } else {
      if (pageStatus > 1) {
        setPageStatus(pageStatus - 1);
      }
    }
  };
  return (
    <SlidingPanel
      type={"right"}
      isOpen={open_status}
      size={75}
      panelClassName="sliding-panel"
      backdropClicked={() => value_update(attrName, false)}>
      <div>
        {pageStatus === 1 ? (
          <Grid container alignItems="center">
            <Grid xs={12}>
              <h4 className="mb-0 mt-2 ">Create Task - Quotes</h4>
            </Grid>
            <Grid xs={12}>
              <hr />
            </Grid>
          </Grid>
        ) : null}
        <div className="scrollable_area">
          {pageStatus === 1 ? (
            <Grid container>
              <Grid xs={6}>
                <Grid container alignItems="center" className="newField">
                  <span>DueDate : </span>
                  <Grid xs={6} sx={{ padding: "0" }}>
                    <SelectDropdown
                      class_name="inputField newDropdown"
                      title=""
                      value={dueDate}
                      attrName={setDueDate}
                      value_update={updateMasterState}
                      dropdown_data={dueDate_data}
                      warn_status={false}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid xs={6} className="text-right">
                <Grid container alignItems="center" className="newField">
                  <Grid xs={6} sx={{ padding: "0" }}>
                    <span>Priority : </span>
                  </Grid>
                  <Grid xs={6} sx={{ padding: "0" }}>
                    <SelectDropdown
                      class_name="inputField newDropdown"
                      title=""
                      value={priority}
                      attrName={setPriority}
                      value_update={updateMasterState}
                      dropdown_data={priority_data}
                      warn_status={false}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid xs={12}>
                <RKTextField
                  class_name="inputField"
                  title="Subject"
                  value={subject}
                  attrName={setSubject}
                  value_update={updateMasterState}
                  warn_status={false}
                />
              </Grid>
              <Grid xs={6}>
                <SelectDropdown
                  class_name="inputField"
                  title="In(Module)"
                  value={module}
                  attrName={setModule}
                  value_update={updateMasterState}
                  dropdown_data={module_data}
                  warn_status={false}
                />
              </Grid>
              <Grid xs={6}>
                <SelectDropdown
                  class_name="inputField"
                  title="For (Assignee)"
                  value={assignee}
                  attrName={setAssignee}
                  value_update={updateMasterState}
                  dropdown_data={assignee_data}
                  warn_status={false}
                />
              </Grid>
              <Grid xs={12}>
                <RKTextField
                  class_name="inputField"
                  title="Desciption"
                  value={description}
                  attrName={setDescription}
                  value_update={updateMasterState}
                  warn_status={false}
                />
              </Grid>
              <Grid xs={12}>
                <p className="SubtaskHeading">Subtasks</p>
              </Grid>

              {addsubTask.map((data, index) => (
                <Grid xs={12}>
                  <Grid container>
                    <Grid xs={11} sx={{ paddingLeft: "0" }}>
                      <RKTextField
                        class_name="inputField"
                        title="Task"
                        value={task}
                        attrName={setTask}
                        value_update={updateMasterState}
                        warn_status={false}
                      />
                    </Grid>
                    <Grid xs={1} alignSelf="top">
                      <Link
                        className="redBtn delete ml-0"
                        underline="none"
                        // onClick={() => {
                        //     setAddSubTask((addSubTask) =>
                        //         addSubTask.filter((_, i) => i !== index)
                        //     );
                        // }}
                      ></Link>
                    </Grid>
                  </Grid>
                </Grid>
              ))}

              <Grid xs={12} sx={{ textAlign: "right" }}>
                <Link
                  className="greenBtn addfile ml-0"
                  underline="none"
                  onClick={() => {
                    setAddSubTask((addSubTask) => [
                      ...addSubTask,
                      {
                        task: "5000",
                        deletable: true,
                      },
                    ]);
                  }}>
                  Add More
                </Link>
              </Grid>
              <Grid xs={12}>
                <p className="SubtaskHeading">Attachments</p>
              </Grid>
              <Grid
                xs={10}
                className="inputField uploadFile"
                sx={{ paddingRight: "0" }}>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Upload Document"
                  variant="outlined"
                  className="textarea"
                />
              </Grid>
              <Grid xs={2} sx={{ paddingLeft: "0" }}>
                <Button variant="contained" className="browsebtn">
                  Browse
                </Button>
              </Grid>
            </Grid>
          ) : pageStatus === 2 ? (
            <Grid
              container
              spacing={2}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100vh",
              }}>
              <Grid xs={12}>
                <svg
                  className="checkmark"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 52 52">
                  <circle
                    className="checkmark__circle"
                    cx="26"
                    cy="26"
                    r="25"
                    fill="none"
                  />
                  <path
                    className="checkmark__check"
                    fill="none"
                    d="M14.1 27.2l7.1 7.2 16.7-16.8"
                  />
                </svg>
                <p className="quote_success text-center">
                  New task has been created successfully!
                </p>
              </Grid>
            </Grid>
          ) : null}
        </div>
        {pageStatus === 1 ? (
          <Grid container spacing={3}>
            <Grid xs={12} className="ctaBtn mt-4">
              <Button
                variant="contained"
                className="submitBtn fixedbtn"
                onClick={() => {
                  setPageStatus(pageStatus + 1);
                }}>
                Create Task
              </Button>
            </Grid>
          </Grid>
        ) : null}
      </div>
    </SlidingPanel>
  );
};

export default CreateTask;
