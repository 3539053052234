import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Box, Button, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ORGANISATION_SERVICES } from "../../../../../../../Services/customModule/organisationServices";
import { userDetailsSlice } from "../../../../../../../Store/Slice_Reducer/UserLogin/UserDetailsSlice";
import { RootState } from "../../../../../../../Store/Store";
import { useAppDispatch, useAppSelector, } from "../../../../../../../Store/hooks";
import { currency_list, get_city_list_via_name, get_country_list, get_industry_list, get_state_list, get_subindustry_list, time_zone_list, uploadImage, validateEmail, validateMobileNumber, validatePincode, validateUrl, } from "../../../../../../../Supporting files/HelpingFunction";
import RKTextField from "../../../../../../../Supporting files/RKTextField/RKTextField";
import SearchDropdown from "../../../../../../../Supporting files/SearchDropdown/SearchDropdown";
import "../../../../../../../formLayout.scss";
import "../../../NewSettings.scss";
import logoimg from "./../../../../../../../../src/Assets/images/edit_image_icon.svg";
import logoimg_profile from "./../../../../../../../../src/Assets/images/nobody_original.svg";

function EditOrganisationDetails() {
  const usertypeinfo = useAppSelector((state) => state.userDetailsSlice.userType);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const userState = useAppSelector((state: RootState) => state.userDetailsSlice);
  const [displayLogo, setDisplayLogo] = useState<{
    logo: any;
    warning: boolean;
  }>({ logo: "", warning: false });
  const [selectedCountryStateCity, setselectedCountryStateCity] = useState<{
    country: any;
    state: any;
    city: any;
    industry: any;
  }>({
    country: "",
    state: "",
    city: "",
    industry: "",
  });
  const [IndustryData, setIndustryData] = useState<Array<{ label: string; id: string }>>([]);
  const [timeZone, setTimeZone] = useState<Array<{ label: string; id: string }>>([]);
  const [currencyList, setCurrencyList] = useState<Array<{ label: string; id: string; iso2: string }>>([]);
  const [subIndustryData, setSubIndustryData] = useState<Array<{ label: string; id: string }>>([]);
  const [countryData, setCountryData] = useState<Array<{ label: string; id: string }>>([]);
  const [stateData, setStateData] = useState<Array<{ label: string; id: string }>>([]);
  const [cityData, setCityData] = useState<Array<{ label: string; id: string }>>([]);
  const [organisationData, setOrganisationData] = useState<any>({
    logo: "",
    orgnizationName: "",
    industry: "",
    subIndustry: "",
    support_email: "",
    url: "",
    taxId: "",
    fiscalyear: "",
    companyId: "",
    email: "",
    userName: "",
    name: "",
    designation: "",
    country: "",
    state: "",
    city: "",
    dateFormat: "",
    currency: "",
    timeZone: "",
    reportBasis: "",
    pinCode: "",
    phoneNumber: "",
    mobileNumber: "",
    address1: "",
    address2: "",
    countryCurrencyCode: "",
  });
  const [organisationDataWarn, setOrganisationDataWarn] = useState<any>({
    logo: false,
    orgnizationName: false,
    industry: false,
    subIndustry: false,
    url: false,
    taxId: false,
    fiscalyear: false,
    companyId: false,
    email: false,
    support_email: false,
    name: false,
    designation: false,
    country: false,
    state: false,
    city: false,
    dateFormat: false,
    currency: false,
    timeZone: false,
    reportBasis: false,
    pinCode: false,
    mobileNumber: false,
    address1: false,
    address2: false,
  });
  const fiscalyear_data = [
    { label: "Januray - December", value: "Januray - December" },
    { label: "February - January", value: "February - January" },
    { label: "March - February", value: "March - February" },
    { label: "April - March", value: "April - March" },
    { label: "May - April", value: "May - April" },
    { label: "June - May", value: "June - May" },
    { label: "July - June", value: "July - June" },
    { label: "August - July", value: "August - July" },
    { label: "September - August", value: "September - August" },
    { label: "October - September", value: "October - September" },
    { label: "November - October", value: "November - October" },
    { label: "December - November", value: "December - November" },
  ];
  const dateformat_data = [
    { label: "MM/DD/YYYY", value: "MM/DD/YYYY" },
    { label: "MM/DD/YY", value: "MM/DD/YY" },
    { label: "DD/MM/YYYY", value: "DD/MM/YYYY" },
    { label: "DD/MM/YYYY", value: "DD/MM/YYYY" },
    { label: "DD-MM-YYYY", value: "DD-MM-YYYY" },
    { label: "MM-DD-YYYY", value: "MM-DD-YYYY" },
    { label: "MM-DD-YYYY", value: "MM-DD-YYYY" },
    { label: "MM-DD-YY", value: "MM-DD-YY" },
    { label: "YYYY-MM-DD", value: "YYYY-MM-DD" },
  ];

  const updateMasterState = (attrName: any, value: any) => {
    let key = attrName[0];
    let mainkey = attrName[1];
    let dropDown_key = attrName[2];
    if (key === "input_fields") {
      setOrganisationData({ ...organisationData, [mainkey]: value });
      setOrganisationDataWarn({ ...organisationDataWarn, [mainkey]: false });
      if (dropDown_key === "drop_down") {
        setOrganisationData({ ...organisationData, [mainkey]: value?.label });
        setOrganisationDataWarn({ ...organisationDataWarn, [mainkey]: false });
        if (mainkey === "country" || mainkey === "state" || mainkey === "city") {
          setselectedCountryStateCity({
            ...selectedCountryStateCity,
            [mainkey]: value?.label,
          });
          if (mainkey === "country") {
            let country = { id: "", label: value?.label };
            get_state_list(country, (cb: any) => {
              setStateData(cb);
            });
            setOrganisationData({ ...organisationData, [mainkey]: value?.label, state: "", city: "" });
          } else if (mainkey === "state") {
            let state = { id: "", label: value?.label };
            let country = { id: "", label: selectedCountryStateCity.country };
            get_city_list_via_name(country, state, (cb: any) => {
              setCityData(cb);
            });
            setOrganisationData({ ...organisationData, [mainkey]: value?.label, city: "" });
          } else if (mainkey === "city") {
            setOrganisationData({ ...organisationData, [mainkey]: value?.label });
          }
        } else if (mainkey === "currency") {
          setOrganisationData({ ...organisationData, countryCurrencyCode: value?.iso2, currency: value?.label, });
        } else if (mainkey === "industry") {
          get_subindustry_list(value, (cb: any) => {
            setSubIndustryData(cb);
          });
          setOrganisationData({ ...organisationData, [mainkey]: value?.label, subIndustry: "" });
        } else if (mainkey === "subIndustry") {
          setOrganisationData({ ...organisationData, [mainkey]: value?.label });
        }
      }
    }
  };

  const organisation_details = () => {
    const onSuccess = (res: any) => {
      setOrganisationData(res.data.data[0]);
      let industry_data: any = { id: "", label: res.data.data[0].industry };
      get_subindustry_list(industry_data, (cb: any) => {
        setSubIndustryData(cb);
      });
      setDisplayLogo({ ...displayLogo, logo: res.data.data[0]?.logo });
      setselectedCountryStateCity({
        ...selectedCountryStateCity,
        country: res.data.data[0].country,
        state: res.data.data[0].state,
        city: res.data.data[0].city,
        industry: res.data.data[0].industry,
      });
      let country = { id: "", label: res.data.data[0].country };
      get_state_list(country, (cb: any) => {
        setStateData(cb);
      });
      let state = { id: "", label: res.data.data[0].state };
      get_city_list_via_name(country, state, (cb: any) => {
        setCityData(cb);
      });
    };
    const onError = (err: any) => {
      console.log("false", err);
    };
    ORGANISATION_SERVICES.organisation_details(onSuccess, onError, usertypeinfo.toLocaleLowerCase()
    );
  };

  //edit org details
  const update_organisation_details = () => {
    let data = organisationDataWarn;
    organisationDataWarn.logo = organisationData.logo ? false : true;
    organisationDataWarn.orgnizationName = organisationData.orgnizationName ? false : true;
    organisationDataWarn.url = !validateUrl(organisationData.url);
    organisationDataWarn.taxId = organisationData.taxId ? false : true;
    organisationDataWarn.industry = organisationData.industry ? false : true;
    organisationDataWarn.subIndustry = organisationData.subIndustry ? false : true;
    organisationDataWarn.fiscalyear = organisationData.fiscalyear ? false : true;
    organisationDataWarn.companyId = organisationData.companyId ? false : true;
    organisationDataWarn.email = !validateEmail(organisationData.email);
    organisationDataWarn.name = organisationData.name ? false : true;
    organisationDataWarn.designation = organisationData.designation ? false : true;
    organisationDataWarn.country = organisationData.country ? false : true;
    organisationDataWarn.state = organisationData.state ? false : true;
    organisationDataWarn.city = organisationData.city ? false : true;
    organisationDataWarn.dateFormat = organisationData.dateFormat ? false : true;
    organisationDataWarn.currency = organisationData.currency ? false : true;
    organisationDataWarn.timeZone = organisationData.timeZone ? false : true;
    organisationDataWarn.pinCode = !validatePincode(organisationData.pinCode);
    organisationDataWarn.phoneNumber = !validateMobileNumber(organisationData.mobileNumber);
    organisationDataWarn.mobileNumber = !validateMobileNumber(organisationData.mobileNumber);
    organisationDataWarn.support_email = organisationData.support_email ? false : true;
    organisationDataWarn.address1 = organisationData.address1 ? false : true;
    setOrganisationDataWarn({ ...data });
    if (
      data.logo === false &&
      data.orgnizationName === false &&
      data.url === false &&
      data.taxId === false &&
      data.fiscalyear === false &&
      data.companyId === false &&
      data.email === false &&
      data.name === false &&
      data.designation === false &&
      data.country === false &&
      data.state === false &&
      data.city === false &&
      data.dateFormat === false &&
      data.currency === false &&
      data.timeZone === false &&
      data.pinCode === false &&
      data.phoneNumber === false &&
      data.mobileNumber === false &&
      data.support_email === false &&
      data.subIndustry === false &&
      data.industry === false &&
      data.address1 === false
    ) {
      let params: any = {
        logo: organisationData.logo,
        orgnizationName: organisationData.orgnizationName,
        industry: organisationData.industry,
        subIndustry: organisationData.subIndustry,
        url: organisationData.url,
        taxId: organisationData.taxId,
        fiscalyear: organisationData.fiscalyear,
        companyId: organisationData.companyId,
        name: organisationData.name,
        designation: organisationData.designation,
        email: organisationData.email,
        support_email: organisationData.support_email,
        country: organisationData.country,
        dateFormat: organisationData.dateFormat,
        currency: organisationData.currency,
        timeZone: organisationData.timeZone,
        reportBasis: organisationData.reportBasis,
        state: organisationData.state,
        city: organisationData.city,
        pinCode: organisationData.pinCode,
        phoneNumber: organisationData.phoneNumber,
        mobileNumber: organisationData.mobileNumber,
        address1: organisationData.address1,
        address2: organisationData.address2,
        countryCurrencyCode: organisationData.countryCurrencyCode,
      };
      const onSuccess = (res: any) => {
        navigate(-1);
        toast.success(" Updated successfully");
        dispatch(
          userDetailsSlice.actions.updateUserDetails([
            {
              key: "localInformation",
              value: {
                ...userState.localInformation,
                currency: organisationData.currency,
                countryCurrencyCode: organisationData.countryCurrencyCode,
                timeZone: organisationData.timeZone,
                country: organisationData.country,
                dateFormat: organisationData.dateFormat,
                // reportBasis: organisationData.reportBasis,
              }
            },
            {
              key: "organizationDetails",
              value: {
                ...userState.organizationDetails,
                companyId: organisationData.companyId,
                fiscalyear: organisationData.fiscalyear,
                industry: organisationData.industry,
                orgnizationName: organisationData.orgnizationName,
                subIndustry: organisationData.subIndustry,
                taxId: organisationData.taxId,
                url: organisationData.url,
              }
            },
            // {
            //   key: "primaryContact",
            //   value: {
            //     ...userState.primaryContact,
            //     name:organisationData.name,
            //     designation:organisationData.designation,
            //     email:organisationData.email,
            //   }
            // },
            {
              key: "addressDetails",
              value: {
                ...userState.addressDetails,
                address1: organisationData.address1,
                address2: organisationData.address2,
                city: organisationData.city,
                mobileNumber: organisationData.mobileNumber,
                phoneNumber: organisationData.phoneNumber,
                pinCode: organisationData.pinCode,
                state: organisationData.state,
              }
            },
            {
              key: "support_email",
              value: organisationData.support_email,
            },
          ])
        );
      };
      const onError = (err: any) => {
        toast.error(err);
      };
      ORGANISATION_SERVICES.update_organisation_details(
        params,
        onSuccess,
        onError
      );
    }
  };

  useEffect(() => {
    organisation_details();
    get_country_list((cb: any) => {
      setCountryData(cb);
    });
    get_industry_list((cb: any) => {
      setIndustryData(cb);
    });
    time_zone_list((cb: any) => {
      setTimeZone(cb);
    });
    currency_list((cb: any) => {
      setCurrencyList(cb);
    });
  }, []);

  return (
    <Box className="layoutWrapper">
      {/* Page header */}
      <Grid container spacing={3} className="pageHeader">
        <Grid xs={6} className="pageTitle">
          <Link
            className="backStep"
            onClick={() => {
              navigate("/setting-module-form?page=companyDetails&section=org");
            }}
          >
            <ChevronLeftIcon />
          </Link>

          <div>
            <h3>Update Organisation Details</h3>
          </div>
        </Grid>
        <Grid xs={6}></Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid xs={12}>
          <Grid container spacing={3}>
            <Grid xs className="rightSection">
              <Box className="detailSection">
                <div className="sectionTitle">
                  <h4>Organisation Details</h4>
                </div>
                <Grid container spacing={3}>
                  <Grid xs={3} alignSelf="center">
                    <div className="profile_section" style={{ display: 'flex', justifyContent: 'center' }}>
                      {/* <h6>Organization Logo</h6> */}
                      <div style={{ width: 'calc(100% - 100px)' }} className="profile_inner_section text-center">
                        <img alt="logo" className="edit_profile_logo"
                          src={displayLogo.logo || logoimg_profile}
                          style={{ width: '100%', objectFit: 'contain' }}
                        />
                        <Link className="upload_img" component="label">
                          <input
                            hidden
                            accept="image/*"
                            multiple
                            type="file"
                            onChange={(e: any) => {
                              let displaydata: any = displayLogo;
                              displaydata.warning =
                                displayLogo.logo !== "" ? true : false;
                              setDisplayLogo({ ...displaydata });
                              const file = e.target.files[0];
                              uploadImage(
                                file,
                                (base64Image: any, base64String: any) => {
                                  setOrganisationData({
                                    ...organisationData,
                                    logo: base64Image,
                                  });

                                  setDisplayLogo({
                                    logo: base64String,
                                    warning: false,
                                  });
                                }
                              );
                            }}
                          />
                          <img
                            src={logoimg}
                            alt=""
                            style={{ width: "30px", height: "30px" }}
                          />
                        </Link>
                        {organisationDataWarn.logo === true ? (
                          <span
                            className="inputField"
                            style={{
                              color: "#eb5757",
                              fontSize: "11px",
                              position: "relative",
                              right: "0",
                              bottom: "-56px",
                            }}
                          >
                            Please Update logo
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </Grid>
                  <Grid xs={9}>
                    <>
                      <Grid container spacing={3}>
                        <Grid sm={4} md={4} lg={3} xl={4}>
                          <RKTextField
                            class_name="inputField"
                            required={true}
                            title={"Organization Name"}
                            value={organisationData.orgnizationName}
                            attrName={["input_fields", "orgnizationName"]}
                            value_update={updateMasterState}
                            warn_status={organisationDataWarn.orgnizationName}
                          />
                        </Grid>
                        <Grid sm={4} md={4} lg={3} xl={4}>
                          <SearchDropdown
                            class_name="inputField"
                            title="Industry"
                            value={organisationData.industry}
                            attrName={["input_fields", "industry", "drop_down"]}
                            value_update={updateMasterState}
                            data={IndustryData}
                            warn_status={organisationDataWarn.industry}
                          />
                        </Grid>
                        <Grid sm={4} md={4} lg={3} xl={4}>
                          <SearchDropdown
                            class_name="inputField"
                            title="Sub-Industry"
                            value={organisationData.subIndustry}
                            attrName={[
                              "input_fields",
                              "subIndustry",
                              "drop_down",
                            ]}
                            value_update={updateMasterState}
                            data={subIndustryData}
                            warn_status={organisationDataWarn.subIndustry}
                          />
                        </Grid>
                      </Grid>

                      <Grid container spacing={3}>
                        <Grid sm={4} md={4} lg={4} xl={4}>
                          <RKTextField
                            class_name="inputField"
                            required={true}
                            title={"Website URL"}
                            value={organisationData.url}
                            attrName={["input_fields", "url"]}
                            value_update={updateMasterState}
                            warn_status={organisationDataWarn.url}
                          />
                        </Grid>
                        <Grid sm={4} md={4} lg={4} xl={4}>
                          <RKTextField
                            class_name="inputField"
                            required={true}
                            title={"Tax ID"}
                            value={organisationData.taxId}
                            attrName={["input_fields", "taxId"]}
                            value_update={updateMasterState}
                            warn_status={organisationDataWarn.taxId}
                            alphanumeric={true}
                            charcterlimit={50}
                          />
                        </Grid>
                        <Grid sm={4} md={4} lg={4} xl={4}>
                          <SearchDropdown
                            class_name="inputField"
                            required={true}
                            title="Fiscal year"
                            value={organisationData.fiscalyear}
                            attrName={[
                              "input_fields",
                              "fiscalyear",
                              "drop_down",
                            ]}
                            value_update={updateMasterState}
                            data={fiscalyear_data}
                            warn_status={organisationDataWarn.fiscalyear}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid sm={4} md={4} lg={3} xl={6}>
                          <RKTextField
                            class_name="inputField"
                            required={true}
                            title={"Company ID"}
                            value={organisationData.companyId}
                            attrName={["input_fields", "companyId"]}
                            value_update={updateMasterState}
                            warn_status={organisationDataWarn.companyId}
                          />
                        </Grid>
                        <Grid xs={4} md={4} lg={3} xl={6}>
                          <RKTextField
                            class_name="inputField"
                            required={true}
                            title={"Username / ID"}
                            value={organisationData?.username}
                            attrName={[]}
                            value_update={updateMasterState}
                            warn_status={false}
                            disabled={true}
                            info_status={true}
                            info_message="Registered E-mail ID cannot be edited"
                          />
                        </Grid>
                      </Grid>
                    </>
                  </Grid>
                </Grid>
                {/* Contact Info */}
                <Grid container spacing={3}>
                  <Grid xs={12}>
                    <h5 className="subHeading">Primary Contact</h5>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid sm={6} md={4} lg={3} xl={3}>
                    <RKTextField
                      class_name="inputField"
                      required={true}
                      title={"Full Name"}
                      value={organisationData.name}
                      attrName={["input_fields", "name"]}
                      value_update={updateMasterState}
                      warn_status={organisationDataWarn.name}
                    />
                  </Grid>
                  <Grid sm={6} md={4} lg={3} xl={3}>
                    <RKTextField
                      class_name="inputField"
                      required={true}
                      title={"Designation"}
                      value={organisationData.designation}
                      attrName={["input_fields", "designation"]}
                      value_update={updateMasterState}
                      warn_status={organisationDataWarn.designation}
                    />
                  </Grid>
                  <Grid sm={6} md={4} lg={3} xl={3}>
                    <RKTextField
                      class_name="inputField"
                      required={true}
                      title={"Email ID"}
                      value={organisationData.email}
                      attrName={["input_fields", "email"]}
                      value_update={updateMasterState}
                      warn_status={organisationDataWarn.email}
                    />
                  </Grid>
                  <Grid sm={3}>
                    <RKTextField
                      class_name="inputField"
                      required={true}
                      title={"Mobile Number"}
                      value={organisationData.mobileNumber}
                      attrName={["input_fields", "mobileNumber"]}
                      value_update={updateMasterState}
                      warn_status={organisationDataWarn.mobileNumber}
                      error_messg="Mobile Number should be valid"
                      validation_type="numeric"
                      charcterlimit={10}
                    />
                  </Grid>
                  <Grid sm={3} md={3} lg={3} xl={3}>
                    <RKTextField
                      class_name="inputField"
                      title={"Phone Number"}
                      value={organisationData.phoneNumber}
                      attrName={["input_fields", "phoneNumber"]}
                      value_update={updateMasterState}
                      warn_status={false}
                      error_messg="Phone Number should be valid"
                      validation_type="numeric"
                      charcterlimit={10}
                    />
                  </Grid>
                  <Grid sm={3} md={3} lg={3} xl={3}>
                    <RKTextField
                      class_name="inputField"
                      required={true}
                      title={"Support Email"}
                      value={organisationData.support_email}
                      attrName={["input_fields", "support_email"]}
                      value_update={updateMasterState}
                      error_messg="Support Email should be valid"
                      warn_status={organisationDataWarn.support_email}
                    />
                  </Grid>
                </Grid>
                {/* Location Details */}
                <Grid container spacing={3}>
                  <Grid xs={12}>
                    <h5 className="subHeading">Local Information</h5>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid sm={6} md={4} lg={3} xl={3}>
                    <SearchDropdown
                      class_name="inputField"
                      required={true}
                      title="Country"
                      value={organisationData.country}
                      attrName={["input_fields", "country", "drop_down"]}
                      value_update={updateMasterState}
                      data={countryData}
                      warn_status={organisationDataWarn.country}
                      error_message="Enter valid value"
                    />
                  </Grid>
                  <Grid sm={6} md={4} lg={3} xl={3}>
                    <SearchDropdown
                      class_name="inputField"
                      required={true}
                      title="Date Format"
                      value={organisationData.dateFormat}
                      attrName={["input_fields", "dateFormat", "drop_down"]}
                      value_update={updateMasterState}
                      data={dateformat_data}
                      warn_status={organisationDataWarn.dateFormat}
                    />
                  </Grid>
                  <Grid sm={6} md={4} lg={3} xl={3}>
                    <SearchDropdown
                      class_name="inputField"
                      required={true}
                      title="Currency (Base Currency)"
                      value={organisationData.currency}
                      attrName={["input_fields", "currency", "drop_down"]}
                      value_update={updateMasterState}
                      data={currencyList}
                      warn_status={organisationDataWarn.currency}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid sm={6} md={6} lg={6} xl={6}>
                    <SearchDropdown
                      class_name="inputField"
                      required={true}
                      title="Time Zone"
                      value={organisationData.timeZone}
                      attrName={["input_fields", "timeZone", "drop_down"]}
                      value_update={updateMasterState}
                      data={timeZone}
                      warn_status={organisationDataWarn.timeZone}
                      error_message="Enter valid value"
                    />
                  </Grid>
                  {/* <Grid sm={6} md={6} lg={6} xl={6}>
                    <RKTextField
                      class_name="inputField"
                      title={"Report Basis"}
                      value={organisationData.reportBasis}
                      attrName={["input_fields", "reportBasis"]}
                      value_update={updateMasterState}
                      warn_status={organisationDataWarn.reportBasis}
                    />
                  </Grid> */}
                </Grid>
                {/* Address Details */}
                <Grid container spacing={3}>
                  <Grid xs={12}>
                    <h5 className="subHeading">Address Details</h5>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid sm={6}>
                    <RKTextField
                      class_name="inputField"
                      required={true}
                      title={"Building"}
                      value={organisationData.address1}
                      attrName={["input_fields", "address1"]}
                      value_update={updateMasterState}
                      warn_status={organisationDataWarn.address1}
                    />
                  </Grid>
                  <Grid sm={6}>
                    <RKTextField
                      class_name="inputField"
                      title={"Area"}
                      value={organisationData.address2}
                      attrName={["input_fields", "address2"]}
                      value_update={updateMasterState}
                      warn_status={false}
                    />
                  </Grid>
                  <Grid sm={3} md={3} lg={3} xl={3}>
                    <SearchDropdown
                      class_name="inputField"
                      required={true}
                      title="State"
                      value={organisationData.state}
                      attrName={["input_fields", "state", "drop_down"]}
                      value_update={updateMasterState}
                      data={stateData}
                      warn_status={organisationDataWarn.state}
                      error_message="Enter valid value"
                    />
                  </Grid>
                  <Grid sm={3} md={3} lg={3} xl={3}>
                    <SearchDropdown
                      class_name="inputField"
                      required={true}
                      title="city"
                      value={organisationData.city}
                      attrName={["input_fields", "city", "drop_down"]}
                      value_update={updateMasterState}
                      data={cityData}
                      warn_status={organisationDataWarn.city}
                      error_message="Enter valid value"
                    />
                  </Grid>
                  <Grid sm={3} md={3} lg={3} xl={3}>
                    <RKTextField
                      class_name="inputField"
                      required={true}
                      title={"Pincode"}
                      value={organisationData.pinCode}
                      attrName={["input_fields", "pinCode"]}
                      value_update={updateMasterState}
                      warn_status={organisationDataWarn.pinCode}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* Footer Section */}
      <Grid container spacing={3} className="footerSection">
        <Grid xs={12} className="ctaBtn">
          <Button
            onClick={update_organisation_details}
            variant="contained"
            className="submitBtn"
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

export default EditOrganisationDetails;
