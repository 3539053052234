import Grid from "@mui/material/Unstable_Grid2";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { EMPLOYER_URL_CONSTANTS } from "../../../../../../APIVar/EmployerUrlConstant";
import { ALL_ROUTES } from "../../../../../../Routes/all_routes";
import { EMPLOYER_SETTING_SERVICES } from "../../../../../../Services/EmployerServices/Settings/SetingServices";
import { E_BE_FILTER_CODE } from "../../../../../../Services/Enum/E_UGTable";
import { RootState } from "../../../../../../Store/Store";
import { useAppSelector } from "../../../../../../Store/hooks";
import { date_time_format, generateModuleId, } from "../../../../../../Supporting files/HelpingFunction";
import UGTable from "../../../../../../Supporting files/UGTable/UGTable";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import NewLoader from "../../../../../../Components/NewLoader/NewLoader";

const Branches = () => {
  const [branchList, setBranchList] = useState([]);
  const navigate = useNavigate();
  const userDetails = useAppSelector((state) => state.userDetailsSlice);
  const tz = useAppSelector((state: RootState) => state.userDetailsSlice.localInformation.timeZone);
  const [tableLimit, setTableLimit] = useState();
  const [tableSkip, setTableSkip] = useState();
  const [columns, setColumns] = useState([
    {
      field: "branchId",
      headerName: "Branch ID",
      width: 200,
      check_status: true,
      renderCell: (ValueFormatterParams: any) => {
        const { row } = ValueFormatterParams;
        return (
          <p
            style={{
              cursor: "pointer",
              fontWeight: "400",
              color: "#6b62dc",
              textDecoration: "none",
            }}
          >
            {`B000${row.branchId}`}
          </p>
        );
      },
    },
    {
      field: "name",
      headerName: "Location Name",
      width: 300,
      check_status: true,
    },
    {
      field: "type",
      headerName: "Location Type",
      width: 300,
      check_status: true,
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 200,
      check_status: true,
      renderCell: (ValueFormatterParams: any) => {
        const { row, index } = ValueFormatterParams;
        row.status = 7;
        return (
          <div id={`_${row.status}`}>
            <div className="status_dropdown" id={`_${row.status}`}>
              {row.status === 7 ? (
                <CheckCircleIcon id={`_${row.status.toString()}`} />
              ) : row.status === 8 ? (
                <HighlightOffIcon id={`_${row.status.toString()}`} />
              ) : null}
              <span>Active</span>
            </div>
          </div>
        );
      },
    },
    {
      field: "building",
      headerName: "Address",
      width: 600,
      check_status: true,
      renderCell: (ValueFormatterParams: any) => {
        const { row } = ValueFormatterParams;
        return (
          <p>
            {row.street} {row.area} {row.state} {row.city} {row.zip}
            {" - "}
            {row.country}
          </p>
        );
      },
    },

    {
      field: "createdAt",
      headerName: "Created on",
      width: 200,
      check_status: true,
      renderCell: (ValueFormatterParams: any) => {
        const { row } = ValueFormatterParams;
        return <span>{date_time_format(row.createdAt, "", tz)}</span>;
      },
    },
    {
      field: "updatedAt",
      headerName: "updated on",
      width: 200,
      check_status: true,
      renderCell: (ValueFormatterParams: any) => {
        const { row } = ValueFormatterParams;
        return <span>{date_time_format(row.updatedAt, "", tz)}</span>;
      },
    },
  ]);

  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    setShowLoader(true);
  }, []);

  const updateMasterState = (attrName: any, value: any) => {
    if (attrName === "selected_data") {
    }
  };

  const get_branch_list_data = () => {
    const onSuccess = (res: any) => {
      if (res.status === 200) {
        setTimeout(() => {
          setShowLoader(false);
        }, 1500);
      }
      const updatedArray = res.data?.data.map((obj: any) => {
        obj.id = obj._id;
        return obj;
      });
      setBranchList(updatedArray);
    };

    const onError = (err: any) => {
      console.log("Branches", err);
    };
    const url = userDetails.parentsId
      ? `${EMPLOYER_URL_CONSTANTS.get_employer_location_list}?createdBy=${userDetails.createdBy}`
      : EMPLOYER_URL_CONSTANTS.get_employer_location_list;
    // Call the EMPLOYER_SETTING_SERVICES function to get the location list
    EMPLOYER_SETTING_SERVICES.fetch_data(url, onSuccess, onError);
  };

  useEffect(() => {
    get_branch_list_data();
  }, []);

  return (
    <>
      {showLoader ? (
        <NewLoader />
      ) : (
        <Grid container spacing={2} className="currunciesWrapper">
          <Grid xs={4}>
            <h3 className="mb-0">Branches</h3>
          </Grid>

          <Grid xs={8} className="text-right"></Grid>
          <Grid xs={12} sx={{ width: "100%" }}>
            <div style={{ height: 400 }}>
              <UGTable
                header_data={columns}
                data={branchList}
                value_update={updateMasterState}
                attrName={"UGTable"}
                BE_filter_code={E_BE_FILTER_CODE.BRANCHES}
                refetch_data={get_branch_list_data}
                setTableLimit={setTableLimit}
                setTableSkip={setTableSkip}
                paginationMode="client"
                class_Name={"th-setting"}
              />
            </div>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default Branches;
