import InfoIcon from "@mui/icons-material/Info";
import { Box, Link, ToggleButton, ToggleButtonGroup } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Highcharts from "highcharts";
import Highcharts3D from "highcharts/highcharts-3d";
import HighchartsAccessibility from "highcharts/modules/accessibility";
import HighchartsExportData from "highcharts/modules/export-data";
import HighchartsExporting from "highcharts/modules/exporting";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { Chart } from "react-google-charts";
import { useNavigate } from "react-router-dom";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { URL_CONSTANTS } from "../../../../APIVar/URLConstants";
import NewLoader from "../../../../Components/NewLoader/NewLoader";
import { ALL_ROUTES } from "../../../../Routes/all_routes";
import { HOMEBROKER_SERVICES } from "../../../../Services/HomeBroker/HomeBroker";
import { useAppSelector } from "../../../../Store/hooks";
import { Get } from "../../../../Supporting files/CallAPI";
import { useFormatCurrency } from "../../../../Supporting files/CustomHooks";
import { date_time_format } from "../../../../Supporting files/HelpingFunction";
import SearchDropdown from "../../../../Supporting files/SearchDropdown/SearchDropdown";
import UGDateRangePicker from "../../../../Supporting files/UGDateRangePicker/UGDateRangePicker";
import "./Dashboard.scss";

Highcharts3D(Highcharts);
HighchartsExporting(Highcharts);
HighchartsExportData(Highcharts);
HighchartsAccessibility(Highcharts);

const barColorCode = [
  "#580DE0",
  "#576CEF",
  "#F3B03D",
  "#E45C8B",
  "#dfa088",
  "#32c1b4",
  "#580DE0",
  "#576CEF",
  "#F3B03D",
  "#E45C8B",
  "#dfa088",
  "#32c1b4",
  "#A93226",
  "#58D68D",
  "#EB984E",
  "#AF7AC5",
  "#85C1E9",
  "#F1948A",
];
const optionsTotalClients = {
  is3D: true,
  legend: "none",
  tooltip: { trigger: "hover" },
};
const optionsPolicyTypeChart = {
  pieHole: 0.4,
  is3D: true,
  height: 400,
  legend: {
    position: "bottom",
    alignment: "center",
    textStyle: {
      color: "#666",
      fontSize: 12,
    },
  },
  slices: {
    0: { color: "#A93226" },
    1: { color: "#F3B03D" },
    2: { color: "#F1948A" },
    3: { color: "#AF7AC5" },
    4: { color: "#85C1E9" },
    5: { color: "#F1948A" },
  },
};

interface EndrArrItem {
  [key: string]: number | string;
}

const Dashboard = () => {

  const navigate = useNavigate();
  const { formatCurrency } = useFormatCurrency();
  const [requestType, setRequestType] = useState<
    "endoresement" | "claim" | null
  >("endoresement");
  const [graphType, setGraphType] = useState<"premium" | "count" | null>(
    "premium"
  );
  const [mapType, setMapType] = useState<"India" | "World" | null>("India");
  const [origin, setOrigin] = useState<any>({
    label: "India",
    value: "IN",
    origin: "IN",
  });
  const today = new Date();
  var currentDate = new Date();
  currentDate.setDate(currentDate.getDate() - 7);
  const [startEndDate, setStartEndDate] = useState<any>({
    startDate: moment(currentDate).format("YYYY-MM-DD"),
    endDate: moment(new Date()).format("YYYY-MM-DD"),
  });
const debounceTimeout = useRef<NodeJS.Timeout | null>(null);
const debounceTimeoutCountry = useRef<NodeJS.Timeout | null>(null);

  let abc: any;
  const [policyTypeChart, setPolicyTypeChart] = useState<any>([]);
  const [premiumPolicyChart, setPremiumPolicyChart] = useState<any>([]);
  const [totalClients, settotalClients] = useState<any>([]);
  const [geoChart, setGeoChart] = useState<any>([]);
  const [claimChartBarData, setclaimChartBarData] = useState<any>([]);
  const [endorsementChartBarData, setEndorsementChartBarData] = useState<any>(
    []
  );
  const [policyBarPremiumChartData, setPolicyBarPremiumChartData] = useState<
    any[]
  >([]);
  const [policyBarCountChartData, setPolicyBarCountChartData] = useState<any[]>(
    []
  );
  const [count_data, setCount_data] = useState<{
    activeClient: any;
    activePolicy: any;
    alertSummary: any;
    criticalBalance: any;
    lives: any;
    policyRenewals: any;
    premium: any;
    quote: any;
    policyPremiumGraph: any;
    policyCountGraph: any;
    regionalStats: any;
    yearlyClaims: any;
    yearlyEndorsements: any;
    yearlyPolicies: any;
    claimGraph: any;
    endrosementGraph: any;
  }>({
    activeClient: {},
    activePolicy: {},
    alertSummary: {},
    criticalBalance: {},
    lives: {},
    policyRenewals: {},
    premium: {},
    quote: {},
    policyPremiumGraph: {},
    policyCountGraph: {},
    regionalStats: {},
    yearlyClaims: {},
    yearlyEndorsements: {},
    yearlyPolicies: {},
    claimGraph: {},
    endrosementGraph: {},
  });
  const [showLoader, setShowLoader] = useState(true);
  const [countryData, setCountryData] = useState<
    Array<{ label: string; id: string; origin: string }>
  >([]);
  const alertSummary = [
    {
      class_name: "quoteicon",
      title: "Quotes",
      value: count_data?.alertSummary?.totalQuotes ?? "0",
    },
    {
      class_name: "claimicon",
      title: "Claim",
      value: count_data?.alertSummary?.totalClaims ?? "0",
    },
    {
      class_name: "endoreseicon",
      title: "Endorsement",
      value: count_data?.alertSummary?.totalEndorsements ?? "0",
    },
  ];
  const { dateFormat, timeZone, currency } = useAppSelector(
    (state) => state.userDetailsSlice.localInformation
  );
  let CurrencyArray: string[] = currency.split(" ");
  let Currency: string = CurrencyArray[CurrencyArray.length - 1];
  const [formatters, setFormatters] = useState([]);
  const optionsGeoChart = {
    region: origin.origin,
    domain: origin.origin,
    displayMode: "regions",
    tooltip: { isHtml: true, trigger: "both" },
    resolution: "provinces",
    defaultColor: "#ffffff",
    colorAxis: {
      colors: ["#e5ef88", "#d4b114", "#e85a03"],
    },
  };

  useEffect(() => {
    if (policyBarPremiumChartData.length > 0) {
      const numericColumns = policyBarPremiumChartData[0]
        ?.map((header: any, index: number) =>
          typeof policyBarPremiumChartData[1][index] === "number" ? index : null
        )
        .filter((index: null) => index !== null);
      const formatter = numericColumns.map((column: any) => ({
        type: "NumberFormat",
        column: column,
        options: {
          pattern: `${Currency} #,##,##0`,
        },
      }));
      setFormatters(formatter);
    }
  }, [policyBarPremiumChartData]);

  const onChangeDate = (attrName: any, value: any) => {
    if (attrName === "rangedatepicker") {
      setStartEndDate({ startDate: value[0], endDate: value[1] });
    }
  };
  function areAllValuesZeroExceptDate(claimGraph: any) {
    return claimGraph.every((obj: any) => {
      return Object.entries(obj).every(([key, value]) => {
        return key === "Date" || value === 0;
      });
    });
  }
  // const get_count = () => {
  //   const onSuccess = (res: any) => {
  //     if (res.status === 200) {
  //       setTimeout(() => {
  //         setShowLoader(false);
  //       }, 1000);
  //     }
  //     const response = res.data.response;
  //     setCount_data(response);

  //     // Total client pie chart
  //     let clientGraph = [["Total Clients", "Hours per Day"]];
  //     response?.activeClient?.divisionByType?.map((data: any) => {
  //       if (data?.type) {
  //         clientGraph.push([data.type, data.count]);
  //       }
  //     });
  //     settotalClients(clientGraph[0]?.length > 1 ? clientGraph : []);

  //     // Policy premium graph
  //     const inputData = response.policyPremiumGraph;
  //     const keys: any = Array.from(
  //       new Set(
  //         inputData.flatMap((item: any) =>
  //           Object.keys(item).filter((key) => key !== "date")
  //         )
  //       )
  //     )?.map((key: any) =>
  //       key
  //         .split("_")
  //         ?.map((word: any) => word.charAt(0).toUpperCase() + word.slice(1))
  //         .join(" ")
  //     );
  //     const uniqueKeys: any = Array.from(
  //       new Set(
  //         inputData.flatMap((item: any) =>
  //           Object.keys(item).filter((key) => key !== "date")
  //         )
  //       )
  //     );
  //     const headerRow = ["Years", ...keys];
  //     const dataRows = inputData.map((item: any) => {
  //       const date = date_time_format(item.date, dateFormat, timeZone);
  //       const values = uniqueKeys.map((key: any) => item[key] || 0);
  //       return [date, ...values];
  //     });
  //     let finalData = [headerRow, ...dataRows];
  //     setPolicyBarPremiumChartData(finalData ? finalData : []);

  //     // Policy count graph
  //     const inputCount = response.policyCountGraph;
  //     const countKey: any = Array.from(
  //       new Set(
  //         inputCount.flatMap((item: any) =>
  //           Object.keys(item).filter((key) => key !== "date")
  //         )
  //       )
  //     )?.map((key: any) =>
  //       key
  //         .split("_")
  //         ?.map((word: any) => word.charAt(0).toUpperCase() + word.slice(1))
  //         .join(" ")
  //     );
  //     const uniqueCountKey: any = Array.from(
  //       new Set(
  //         inputCount.flatMap((item: any) =>
  //           Object.keys(item).filter((key) => key !== "date")
  //         )
  //       )
  //     );
  //     const countHeaderRow = ["Years", ...countKey];
  //     const countBodyRows = inputCount.map((item: any) => {
  //       const date = item.date;
  //       const values = uniqueCountKey.map((key: any) => item[key] || 0);
  //       return [date, ...values];
  //     });
  //     let finalCount = [countHeaderRow, ...countBodyRows];
  //     setPolicyBarCountChartData(finalCount ? finalCount : []);

  //     // Policy type % pie chart
  //     let arr = [["Task", "Hours per Day"]];
  //     response?.activePolicy?.divisionByType?.map((data: any) => {
  //       if (data?.name) {
  //         arr.push([data.name, data.count]);
  //       }
  //     });
  //     setPolicyTypeChart(arr);

  //     // Premium policy 3D pie chart
  //     let pre: any = [];
  //     response?.premium?.divisionByType?.map((data: any) => {
  //       pre.push([data.name, data.total]);
  //     });
  //     setPremiumPolicyChart(pre);

  //     // Geo map indian states
  //     let arrGeo: any = [["State", "Clients"]];
  //     if (response.regionalStats?.length > 0) {
  //       response.regionalStats?.map((data: any) => {
  //         if (data?.count) {
  //           {
  //             arrGeo.push([data.state, data.count]);
  //           }
  //         }
  //       });
  //     }
  //     setGeoChart(arrGeo);

  //     // Request map endorsement graph
  //     let endrArr: any[] = [];
  //     response.endrosementGraph?.map((item: any) => {
  //       const capitalizedItem: any = {};
  //       for (const key in item) {
  //         if (Object.prototype.hasOwnProperty.call(item, key)) {
  //           const capitalizedKey = key.charAt(0).toUpperCase() + key.slice(1);
  //           const replacedKey = capitalizedKey.replace(/_/g, " ");
  //           capitalizedItem[replacedKey] = item[key];
  //         }
  //       }
  //       endrArr.push({
  //         ...capitalizedItem,
  //         Date: moment(item.date).format("DD MMM YY"),
  //       });
  //     });
  //     const ekeys = Object.keys(endrArr[0]);
  //     const keysToKeep = ekeys?.filter((key: any) => {
  //       return endrArr?.some((item: any) => item[key] !== 0);
  //     });
  //     const newData = endrArr.map((item: any) => {
  //       const newItem: any = {};
  //       keysToKeep.forEach((key: string) => {
  //         newItem[key] = item[key];
  //       });
  //       return newItem;
  //     });
  //     setEndorsementChartBarData(newData);

  //     // Request map claims graph
  //     let claimArr: any[] = [];
  //     response.claimGraph?.map((item: any) => {
  //       const capitalizedItem: any = {};
  //       for (const key in item) {
  //         if (Object.prototype.hasOwnProperty.call(item, key)) {
  //           const capitalizedKey = key.charAt(0).toUpperCase() + key.slice(1);
  //           const replacedKey = capitalizedKey.replace(/_/g, " ");
  //           capitalizedItem[replacedKey] = item[key];
  //         }
  //       }
  //       claimArr.push({
  //         ...capitalizedItem,
  //         Date: moment(item.date).format("DD MMM YY"),
  //       });
  //     });
  //     const ckeys = Object.keys(claimArr[0]);
  //     const keysToKeep2 = ckeys?.filter((key: any) => {
  //       return claimArr?.some((item: any) => item[key] !== 0);
  //     });
  //     const newData2 = claimArr.map((item: any) => {
  //       const newItem: any = {};
  //       keysToKeep2.forEach((key: string) => {
  //         newItem[key] = item[key];
  //       });
  //       return newItem;
  //     });
  //     setclaimChartBarData(newData2);
  //   };

  //   const onError = (err: any) => {
  //     console.log("..err", err);
  //     setTimeout(() => {
  //       setShowLoader(false);
  //     }, 1500);
  //   };

  //   HOMEBROKER_SERVICES.broker_dashboard_stats(
  //     onSuccess,
  //     onError,
  //     startEndDate.startDate
  //       ? moment(startEndDate.startDate).format("YYYY-MM-DD")
  //       : "",
  //     startEndDate.endDate
  //       ? moment(startEndDate.endDate).format("YYYY-MM-DD")
  //       : "",
  //     origin.label ?? ""
  //   );
  // };
  // don't delete this

  const get_count = () => {
    const onSuccess = (res: any) => {
      if (res.status === 200) {
        setTimeout(() => {
          setShowLoader(false);
        }, 1000);
      }
      const response = res.data.response;
      setCount_data(response);
      // total client pie chart
      let clientGraph = [["Total Clients", "Hours per Day"]];
      response?.activeClient?.divisionByType?.map((data: any) => {
        if (data?.type) {
          clientGraph.push([data.type, data.count]);
        }
      });
      settotalClients(clientGraph[0]?.length > 1 ? clientGraph : []);

      // policy premium graph
      const inputData = response.policyPremiumGraph;
      const keys: any = Array.from(
        new Set(
          inputData.flatMap((item: any) =>
            Object.keys(item).filter((key) => key !== "date")
          )
        )
      )?.map((key: any) =>
        key
          .split("_")
          ?.map((word: any) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ")
      );
      const uniqueKeys: any = Array.from(
        new Set(
          inputData.flatMap((item: any) =>
            Object.keys(item).filter((key) => key !== "date")
          )
        )
      );
      const headerRow = ["Years", ...keys];
      const dataRows = inputData.map((item: any) => {
        const date = date_time_format(item.date, dateFormat, timeZone);
        const values = uniqueKeys.map((key: any) => item[key] || 0);
        return [date, ...values];
      });
      let finalData = [headerRow, ...dataRows];
      // setPolicyBarPremiumChartData(finalData[0]?.length > 1 ? finalData : [])
      // setPolicyBarPremiumChartData(finalData ? finalData : [])

      // This is temporary stuff only
      // if (finalData[0]?.length === 1) {
      //   finalData = [
      //     ["Years", " "],
      //     ...finalData.slice(1).map(date => [date[0], 0])
      //   ];
      // }
      setPolicyBarPremiumChartData(finalData ? finalData : []);
      // policy count graph
      const inputCount = response.policyCountGraph;
      const countKey: any = Array.from(
        new Set(
          inputCount.flatMap((item: any) =>
            Object.keys(item).filter((key) => key !== "date")
          )
        )
      )?.map((key: any) =>
        key
          .split("_")
          ?.map((word: any) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ")
      );
      const uniqueCountKey: any = Array.from(
        new Set(
          inputCount.flatMap((item: any) =>
            Object.keys(item).filter((key) => key !== "date")
          )
        )
      );
      const countHeaderRow = ["Years", ...countKey];
      const countBodyRows = inputCount.map((item: any) => {
        const date = item.date;
        const values = uniqueCountKey.map((key: any) => item[key] || 0);
        return [date, ...values];
      });
      let finalCount = [countHeaderRow, ...countBodyRows];
      // setPolicyBarCountChartData(finalCount[0]?.length > 1 ? finalCount : [])
      // setPolicyBarCountChartData(finalCount ? finalCount : [])

      // This is temporary stuff only
      // if (finalCount[0]?.length === 1) {
      //   finalCount = [
      //     ["Years", " "],
      //     ...finalCount.slice(1).map(date => [date[0], 0])
      //   ];
      // }
      setPolicyBarCountChartData(finalCount ? finalCount : []);

      // policy type % pie chart
      let arr = [["Task", "Hours per Day"]];
      response?.activePolicy?.divisionByType?.map((data: any) => {
        if (data?.name) {
          arr.push([data.name, data.count]);
        }
      });
      setPolicyTypeChart(arr);

      // premium policy 3D pie chart
      let pre: any = [];
      response?.premium?.divisionByType?.map((data: any) => {
        pre.push([data.name, data.total]);
        // pre.push([data.name, formatCurrency(Number(data.total.toFixed()))])
      });
      setPremiumPolicyChart(pre);

      // geo map indian states
      let arrGeo: any = [["State", "Clients"]];
      if (response.regionalStats?.length > 0) {
        response.regionalStats?.map((data: any) => {
          if (data?.count) {
            {
              arrGeo.push([data.state, data.count]);
            }
          }
        });
      }
      setGeoChart(arrGeo);

      // request map endorsment graph
      let endrArr: any[] = [];
      response.endrosementGraph?.map((item: any) => {
        const capitalizedItem: any = {};
        for (const key in item) {
          if (Object.prototype.hasOwnProperty.call(item, key)) {
            const capitalizedKey = key.charAt(0).toUpperCase() + key.slice(1);
            const replacedKey = capitalizedKey.replace(/_/g, " ");
            capitalizedItem[replacedKey] = item[key];
          }
        }
        endrArr.push({
          ...capitalizedItem,
          Date: moment(item.date).format("DD MMM YY"),
        });
      });
      // Get all keys of endorsment
      const ekeys = Object.keys(endrArr[0]);
      const keysToKeep = ekeys?.filter((key: any) => {
        return endrArr?.some((item: EndrArrItem) => item[key] !== 0);
      });
      const newData = endrArr.map((item: EndrArrItem) => {
        const newItem: EndrArrItem = {};
        keysToKeep.forEach((key: string) => {
          newItem[key] = item[key];
        });
        return newItem;
      });
      setEndorsementChartBarData(newData);

      // request map claims graph
      let claimArr: any[] = [];
      response.claimGraph?.map((item: any) => {
        const capitalizedItem: any = {};
        for (const key in item) {
          if (Object.prototype.hasOwnProperty.call(item, key)) {
            const capitalizedKey = key.charAt(0).toUpperCase() + key.slice(1);
            const replacedKey = capitalizedKey.replace(/_/g, " "); // Replace underscores with spaces
            capitalizedItem[replacedKey] = item[key];
          }
        }
        claimArr.push({
          ...capitalizedItem,
          Date: moment(item.date).format("DD MMM YY"),
        });
      });
      // Get all keys of claims
      const ckeys = Object.keys(claimArr[0]);
      const keysToKeep2 = ckeys?.filter((key: any) => {
        return claimArr?.some((item: EndrArrItem) => item[key] !== 0);
      });
      const newData2 = claimArr.map((item: EndrArrItem) => {
        const newItem: EndrArrItem = {};
        keysToKeep2.forEach((key: string) => {
          newItem[key] = item[key];
        });
        return newItem;
      });
      setclaimChartBarData(newData2);
    };

    const onError = (err: any) => {
      console.log("..err", err);
      setTimeout(() => {
        setShowLoader(false);
      }, 1500);
    };

    HOMEBROKER_SERVICES.broker_dashboard_stats(
      onSuccess,
      onError,
      startEndDate.startDate
        ? moment(startEndDate.startDate).format("YYYY-MM-DD")
        : "",
      startEndDate.endDate
        ? moment(startEndDate.endDate).format("YYYY-MM-DD")
        : "",
      origin.label ?? ""
    );
  };

  useEffect(() => {
    //get_count();
    setShowLoader(true);
    if (debounceTimeoutCountry.current) {
      clearTimeout(debounceTimeoutCountry.current); // Clear any existing timeout
    }
    debounceTimeoutCountry.current = setTimeout(() => {
      get_country_list((cb: any) => {
        setCountryData(cb);
      });
    }, 1000); // Debounce delay of 500ms

  // Clean up on unmount to avoid memory leaks
  return () => {
    if (debounceTimeoutCountry.current) {
      clearTimeout(debounceTimeoutCountry.current);
    }
  };

  }, []);

  useEffect(() => {
    
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current); // Clear any existing timeout
      }
      debounceTimeout.current = setTimeout(() => {
        get_count(); // Debounced API call for initial render
      }, 1000); // Debounce delay of 500ms
  
    // Clean up on unmount to avoid memory leaks
    return () => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
    };
  }, [startEndDate, origin.label]);


  // useEffect(() => {
  //   console.log("gdfgfdgdf",startEndDate, origin.label)
  //   if (origin.label || origin.label != "" || origin.label != "N/A") {
  //     get_count();
  //   }
  // }, [origin.label]);

  useEffect(() => {
    if (premiumPolicyChart?.length > 0) {
      (Highcharts as any)?.chart("premium_policy_pie_chart", {
        chart: {
          type: "pie",
          options3d: {
            enabled: true,
            alpha: 40,
          },
        },
        plotOptions: {
          pie: {
            innerSize: 100,
            depth: 45,
          },
        },
        series: [
          {
            name: "Premium Amount",
            data: premiumPolicyChart,
          },
        ],
        tooltip: {
          formatter: function (
            this: Highcharts.TooltipFormatterContextObject
          ): string {
            return `<b>${formatCurrency(this.point.y)}</b>`;
          },
        },
      });
    }
  }, [premiumPolicyChart]);

  const get_country_list = (cb: any) => {
    let data: Array<{ label: string; id: string; origin: string }> = [];
    Get(URL_CONSTANTS.get_country_list)
      .then((res: any) => {
        let response = res.data.data;
        for (var i = 0; i < response.length; i++) {
          data.push({
            label: response[i].value,
            id: response[i].label.toString(),
            origin: response[i].origin,
          });
        }
        return cb(data);
      })
      .catch((error: any) => {
        console.log(error);
        return [error, {}];
      });
  };

  return (
    <Grid container spacing={3}>
      {showLoader && <NewLoader />}
      <Grid xs={12}>
        <>
          <Grid container>
            <Grid xs={8}>
              <h3 className="mb-0">Dashboard</h3>
            </Grid>
            <Grid xs={4} textAlign={"right"}>
              <UGDateRangePicker
                value_update={onChangeDate}
                defaultValue={[currentDate, today]}
              />
            </Grid>
          </Grid>
        </>
      </Grid>
      <Grid xs={9} className="pl-0">
        <>
          <Grid container spacing={3}>
            <Grid xs={12} lg={6} xl={4}>
              <>
                <Grid
                  container
                  alignItems="center"
                  className="totalClients"
                  spacing={0}
                >
                  <Grid xs={12} className="pl-2">
                    <h3>{count_data?.activeClient?.totalCount ?? "0"}</h3>
                    <p>Total Clients</p>
                    <span className="charts">
                      {totalClients?.length > 1 && (
                        <Chart
                          chartType="PieChart"
                          data={totalClients}
                          options={optionsTotalClients}
                          width={"150px"}
                          height={"150px"}
                        />
                      )}
                    </span>
                  </Grid>
                </Grid>
              </>
            </Grid>
            <Grid xs={12} lg={6} xl={4}>
              <>
                <Grid
                  container
                  alignItems="center"
                  className="activePolicies"
                  spacing={0}
                >
                  <Grid xs="auto" className="pl-2">
                    <h3>{count_data?.activePolicy?.totalCount ?? "0"}</h3>
                    <p>
                      Inforced <br /> Policies
                    </p>
                  </Grid>
                  <Grid xs className="pr-4">
                    <ul>
                      {count_data?.activePolicy?.divisionByType?.map(
                        (data: any) => (
                          <li
                            key={data._id}
                            className={
                              data.code === "0"
                                ? "term"
                                : data.code === "1"
                                  ? "health"
                                  : data.code === "2"
                                    ? "trvl"
                                    : data.code === "3"
                                      ? "pa"
                                      : data.code === "4"
                                        ? "ci"
                                        : "stp"
                            }
                          >
                            {data.name} - {data.count}
                          </li>
                        )
                      )}
                    </ul>
                  </Grid>
                </Grid>
              </>
            </Grid>
            <Grid xs={12} xl={4}>
              <>
                <Grid container className="noofLives" spacing={0}>
                  <Grid xs={12} className="totalLives">
                    <img src="./images/noof_lives.svg" alt="img" />
                    <span>
                      <h3 style={{ textAlign: "center" }}>
                        {count_data?.lives?.totalCount ?? "0"}
                      </h3>
                      <p>Total Lives</p>
                    </span>
                  </Grid>
                  <Grid xs={6} className="pl-2">
                    <h5>
                      {count_data?.lives?.divisionByType?.filter(
                        (data: any) => data.type === "EMPLOYEE"
                      )[0]?.count ?? 0}
                    </h5>
                    <p>Employees</p>
                  </Grid>
                  <Grid xs={6} className="pr-2" sx={{ textAlign: "end" }}>
                    <h5>
                      {count_data?.lives?.divisionByType?.filter(
                        (data: any) => data.type === "DEPENDENT"
                      )[0]?.count ?? 0}
                    </h5>
                    <p>Dependants</p>
                  </Grid>
                </Grid>
              </>
            </Grid>
            <Grid xs={12}>
              <Box>
                <Grid container padding={0}>
                  {policyBarPremiumChartData?.length > 0 && (
                    <>
                      <Grid xs={12}>
                        <h4>Total Policies</h4>
                      </Grid>
                      <Grid xs={12} className="mb-5">
                        <ToggleButtonGroup
                          className="loginTypeToggle"
                          value={graphType}
                          size="small"
                          exclusive
                          onChange={(e, val: "premium" | "count" | null) => {
                            if (val != null) {
                              setGraphType(val);
                            }
                          }}
                        >
                          <ToggleButton value="premium">
                            Policy Premium
                          </ToggleButton>
                          <ToggleButton value="count">
                            Policy Count
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </Grid>
                      <Grid xs={12}>
                        {graphType === "premium" ? (
                          <>
                            {policyBarPremiumChartData[0]?.length === 1 ? (
                              <div className="no_records">
                                <span>No Records Available</span>
                              </div>
                            ) : (
                              <Chart
                                chartType="Bar"
                                width="100%"
                                height="400px"
                                data={policyBarPremiumChartData}
                                options={{
                                  vAxis: {
                                    ticks: [0, 10, 20, 30, 40],
                                    format: "0",
                                  },
                                  colors: [
                                    "#A93226",
                                    "#F3B03D",
                                    "#F1948A",
                                    "#AF7AC5",
                                    "#85C1E9",
                                    "#F1948A",
                                  ], // Specify your colors here
                                }}
                                formatters={formatters}
                              />
                            )}
                          </>
                        ) : (
                          <>
                            {policyBarCountChartData[0]?.length === 1 ? (
                              <div className="no_records">
                                <span>No Records Available</span>
                              </div>
                            ) : (
                              <Chart
                                chartType="Bar"
                                width="100%"
                                height="400px"
                                data={policyBarCountChartData}
                                options={{
                                  title: "My Daily Activities",
                                  vAxis: {
                                    ticks: [0, 1, 2, 3, 4],
                                    format: "0",
                                  },
                                  colors: [
                                    "#A93226",
                                    "#F3B03D",
                                    "#F1948A",
                                    "#AF7AC5",
                                    "#85C1E9",
                                    "#F1948A",
                                  ],
                                }}
                              />
                            )}
                          </>
                        )}
                      </Grid>
                    </>
                  )}
                </Grid>
              </Box>
            </Grid>
            <Grid md={12} xl={6} className="graphLib mt-2">
              <Box className="graphBox">
                <h4 className="mb-0">Policy Type as %</h4>
                <div className="policytypeas">
                  {policyTypeChart?.length > 1 && (
                    <Chart
                      chartType="PieChart"
                      width="100%"
                      data={policyTypeChart}
                      options={optionsPolicyTypeChart}
                    />
                  )}
                </div>
                <div className="total_count">
                  Total Policies - {count_data?.activePolicy?.totalCount ?? "0"}
                </div>
              </Box>
            </Grid>
            <Grid md={12} xl={6} className="graphLib mt-2">
              <Box className="graphBox">
                <h4 className="mb-0">Premium Policy </h4>
                <div className="policytypeas">
                  {premiumPolicyChart?.length > 0 && (
                    <div id="premium_policy_pie_chart"></div>
                  )}
                </div>
                <div className="total_count">
                  Total Premium{" "}
                  {formatCurrency(
                    Number(count_data?.premium?.totalPremium || 0)
                  )}
                </div>
              </Box>
            </Grid>
            <Grid xs={12}>
              <Box>
                <Grid container padding={0} alignItems="center">
                  <Grid xs={12} padding={0}>
                    <h4>Client Geographical Representation</h4>
                  </Grid>
                  <Grid xs={6} className="mb-5">
                    <ToggleButtonGroup
                      className="loginTypeToggle"
                      value={mapType}
                      size="small"
                      exclusive
                      onChange={(e, val: "India" | "World" | null) => {
                        if (val != null && val === "India") {
                          setMapType(val);
                          setOrigin({
                            label: "India",
                            value: "IN",
                            origin: "IN",
                          });
                        }
                        if (val != null) {
                          setMapType(val);
                        }
                      }}
                    >
                      <ToggleButton value="India">India</ToggleButton>
                      <ToggleButton value="World">
                        Different Region
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Grid>
                  {mapType === "World" && (
                    <Grid xs={12} lg={4} xl={3} className="pl-0">
                      <SearchDropdown
                        class_name="inputField"
                        title="Choose Country"
                        value={origin.label}
                        attrName={setOrigin}
                        value_update={(atr: any, val: any) => {
                          setOrigin(val);
                        }}
                        data={countryData}
                        warn_status={false}
                      />
                    </Grid>
                  )}
                </Grid>
                {geoChart?.length > 1 ? (
                  <span className="policytypea">
                    <Chart
                      chartType="GeoChart"
                      width="100%"
                      data={geoChart}
                      options={optionsGeoChart}
                    />
                  </span>
                ) : (
                  <div className="no_records">
                    <span>No Records Available</span>
                  </div>
                )}
              </Box>
            </Grid>
          </Grid>
        </>
        <>
          <Grid container spacing={3}>
            <Grid xs={12}>
              <h4 className="mb-0">Requests</h4>
            </Grid>
            <Grid xs={12}>
              <ToggleButtonGroup
                className="loginTypeToggle"
                value={requestType}
                size="small"
                exclusive
                onChange={(e, val: "endoresement" | "claim" | null) => {
                  if (val != null) {
                    setRequestType(val);
                  }
                }}
              >
                <ToggleButton value="claim">Claim</ToggleButton>
                <ToggleButton value="endoresement">Endorsement</ToggleButton>
              </ToggleButtonGroup>
            </Grid>
            <Grid xs={12} className="claim_endorsment_graph">
              {requestType === "claim" && (
                <>
                  {areAllValuesZeroExceptDate(claimChartBarData) ? (
                    <div className="no_records">
                      <span>No Records Available</span>
                    </div>
                  ) : (
                    <ResponsiveContainer width="100%" height={400}>
                      <BarChart barSize={15} data={claimChartBarData}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="Date" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        {Object.keys(claimChartBarData[0])
                          ?.filter((key) => key !== "Date" && key !== "Total")
                          ?.map((data: any, i) => (
                            <Bar
                              dataKey={data}
                              stackId="a"
                              fill={barColorCode[i + 6]}
                            />
                          ))}
                      </BarChart>
                    </ResponsiveContainer>
                  )}
                </>
              )}
              {requestType === "endoresement" && (
                <>
                  {areAllValuesZeroExceptDate(endorsementChartBarData) ? (
                    <div className="no_records">
                      <span>No Records Available</span>
                    </div>
                  ) : (
                    <ResponsiveContainer width="100%" height={400}>
                      <BarChart barSize={15} data={endorsementChartBarData}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="Date" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        {Object.keys(endorsementChartBarData[0])
                          ?.filter((key) => key !== "Date" && key !== "Total")
                          ?.map((data: any, i) => (
                            <Bar
                              dataKey={data}
                              stackId="a"
                              fill={barColorCode[i + 6]}
                            />
                          ))}
                      </BarChart>
                    </ResponsiveContainer>
                  )}
                </>
              )}
            </Grid>
          </Grid>
        </>
      </Grid>
      <Grid xs={3}>
        <>
          <Grid container spacing={3}>
            <Grid xs={12}>
              <div className="alertMessage_broker">
                <h5>
                  {" "}
                  <InfoIcon /> Critical CD Balance Alerts
                </h5>
                {count_data?.criticalBalance?.length === 0 ? (
                  <span>No Alerts</span>
                ) : (
                  <ul>
                    {count_data?.criticalBalance?.length > 0 &&
                      count_data?.criticalBalance?.map((item: any) => {
                        return (
                          <li key={item.title}>
                            <span
                              style={{
                                textTransform: "capitalize",
                                fontWeight: "700",
                              }}
                            >
                              {item?.title.split("-")[0]} -{" "}
                            </span>
                            <span style={{ fontWeight: 600 }}>
                              {item?.title.split("-").slice(1).join("-")}
                            </span>{" "}
                            <span style={{ display: "inline-block" }}>
                              {formatCurrency(Number(item?.cdBalance))}
                            </span>
                          </li>
                        );
                      })}
                  </ul>
                )}
              </div>
            </Grid>
            <Grid xs={12} className="todolist mb-0">
              <h4>To Do</h4>
              <Link
                underline="none"
                className="addpolicy"
                onClick={() => navigate(ALL_ROUTES.CLIENTS)}
              >
                Upload your Clients
              </Link>
              <Link
                underline="none"
                className="addpolicy"
                onClick={() => navigate(ALL_ROUTES.EMPLOYEE)}
              >
                Upload Members
              </Link>
              {/* <Link underline="none" className="riskscore">
                Set Report Preferences
              </Link> */}
              <Link
                underline="none"
                className="familyprofile"
                onClick={() =>
                  navigate(
                    `${ALL_ROUTES.SETTING_MODULE_FORM}?page=accessControl&section=userControl`
                  )
                }
              >
                Review Access Controls
              </Link>
            </Grid>
            <Grid xs={12}>
              <Box className="alertSummary">
                <h3>Alert Summary</h3>
                <p>Total pendings of quote, claim & endorsement.</p>
                <ul>
                  {alertSummary?.map((item) => (
                    <li>
                      <div className={item.class_name}></div>
                      <p>
                        {item.title} <span>{item.value}</span>
                      </p>
                    </li>
                  ))}
                </ul>
              </Box>
            </Grid>
          </Grid>
        </>
      </Grid>
    </Grid>
  );
};

export default Dashboard;
