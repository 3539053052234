import React, { useState, useEffect } from "react";
import { Box, Link, Tooltip } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { Button, Modal } from "@mui/material";
import RKTextField from "../../../../../Supporting files/RKTextField/RKTextField";
import { CUSTOME_MODULE_SERVICES } from "../../../../../Services/customModule/customeModuleServices";
import deleteimage from "../../../../../../src/images/deletepopup_deleteicon.svg";
import tickicon from "../../../../../../src/images/deletePopup_tickicon.svg";
import GlobalDeletePopup from "../../../../../Components/Broker/GlobalDeletePopup/GlobalDeletePopup";
import { getUrlParam } from "../CustomModuleV2/helpers";

const StageStatus = (modulepicklist) => {
  const [layoutid, setlayoutid] = React.useState("");
  const [stagesStatus, setstagesStatus] = React.useState(false);
  const [editstagesStatus, seteditstagesStatus] = React.useState(false);
  const [openStatus, setopenStatus] = React.useState(false);
  const [editopenStatus, seteditopenStatus] = React.useState(false);
  const [deleteStatusStages, setdeleteStatusStages] = React.useState(false);
  const [stagesAndStatusData, setStagesAndStatusData] = React.useState([]);
  const [poststagesdata, setpoststagesdata] = React.useState([]);
  const [postStatusdata, setpostStatusdata] = React.useState([]);
  const [stageOrStatusid, setStageOrStatusid] = React.useState("");
  const [StatusLength, setStatusLength] = React.useState("");
  const [deleteStatusStep, setDeleteStatusStep] = React.useState(1);
  const [deleteStageItemId, setdeleteStageItemId] = React.useState("");
  const [statusupdateIndex, setstatusupdateIndex] = React.useState("");
  const [deleteStatusItemId, setdeleteStatusItemId] = React.useState("");
  const [deleteStatusParentId, setdeleteStatusParentId] = React.useState("");
  const [deleteOpenStatus, setdeleteOpenStatus] = React.useState(false);
  const [stageWarn, setstageWarn] = React.useState(false);
  const [statusWarnName, setstatusWarnName] = React.useState(false);
  const [statusWarnColour, setstatusWarnColour] = React.useState(false);

  //get stages and status
  const get_stages_status = (layoutid) => {
    const onSuccess = (res) => {
      setStagesAndStatusData(res.data.data);
    };
    const onError = (err) => {
      console.log("err", err);
    };
    CUSTOME_MODULE_SERVICES.get_status_stages(onSuccess, onError, layoutid);
  };

  //post stages data
  const post_stages_data = () => {
    if (poststagesdata.name) {
      const onSuccess = (res) => {
        setstagesStatus(false);
        setpoststagesdata("");
      };
      const onError = (err) => {
        console.log("err", err);
      };
      CUSTOME_MODULE_SERVICES.post_stages_data(
        poststagesdata,
        onSuccess,
        onError
      );
    } else {
      setstageWarn(true);
    }
  };

  //post status data
  const post_status_data = () => {
    let params = {
      key: "status." + StatusLength,
      value: {
        name: postStatusdata.name,
        colour: postStatusdata.colour,
      },
    };
    if (postStatusdata.name && postStatusdata.colour) {
      const onSuccess = (res) => {
        setopenStatus(false);
        setpostStatusdata("");
      };
      const onError = (err) => {
        console.log("err", err);
      };
      CUSTOME_MODULE_SERVICES.post_status_data(
        params,
        onSuccess,
        onError,
        stageOrStatusid
      );
    } else {
      if (!postStatusdata.name) {
        setstatusWarnName(true);
      } else {
        setstatusWarnColour(true);
      }
    }
  };

  //get single stages for edit
  const get_single_stages = (deleteStageItemId) => {
    let itemtype = "stages";
    let parentid = deleteStageItemId;

    const onSuccess = (res) => {
      setpoststagesdata(res.data.data);
    };
    const onError = (err) => {
      console.log("err", err);
    };

    CUSTOME_MODULE_SERVICES.get_single_stage_status(
      onSuccess,
      onError,
      parentid,
      itemtype,
      deleteStageItemId
    );
  };

  //get single stages for edit
  const get_single_status = (ItemId, parentid) => {
    let itemtype = "status";
    const onSuccess = (res) => {
      setpostStatusdata(res.data.data[0].status);
    };
    const onError = (err) => {
      console.log("err", err);
    };

    CUSTOME_MODULE_SERVICES.get_single_stage_status(
      onSuccess,
      onError,
      parentid,
      itemtype,
      ItemId
    );
  };
  //delete stages
  const delete_stages = () => {
    let itemtype = "stages";
    setDeleteStatusStep(2);
    let parentid = deleteStageItemId;

    const onSuccess = (res) => {
      setDeleteStatusStep(2);
    };
    const onError = (err) => {
      console.log("err", err);
    };

    CUSTOME_MODULE_SERVICES.delete_status_or_stages(
      onSuccess,
      onError,
      parentid,
      itemtype,
      deleteStageItemId
    );
  };

  //delete status
  const delete_status = () => {
    let itemtype = "status";
    setDeleteStatusStep(2);

    const onSuccess = (res) => {
      setDeleteStatusStep(2);
    };
    const onError = (err) => {
      console.log("err", err);
    };
    CUSTOME_MODULE_SERVICES.delete_status_or_stages(
      onSuccess,
      onError,
      deleteStatusParentId,
      itemtype,
      deleteStatusItemId
    );
  };

  //update stages
  const update_stages = () => {
    let params = {
      key: "name",
      value: poststagesdata.name,
    };
    if (poststagesdata.name.length != 0) {
      const onSuccess = (res) => {
        seteditstagesStatus(false);
        setpoststagesdata("");
      };
      const onError = (err) => {
        console.log("err", err);
      };
      CUSTOME_MODULE_SERVICES.update_stage_status(
        params,
        onSuccess,
        onError,
        deleteStageItemId
      );
    } else {
      setstageWarn(true);
    }
  };

  //update status
  const update_status = () => {
    if (postStatusdata.name) {
      let params = {
        key: "status." + statusupdateIndex,
        value: {
          name: postStatusdata.name,
          colour: postStatusdata.colour,
        },
      };
      const onSuccess = (res) => {
        seteditopenStatus(false);
        setpostStatusdata("");
      };
      const onError = (err) => {
        console.log("err", err);
      };
      CUSTOME_MODULE_SERVICES.update_stage_status(
        params,
        onSuccess,
        onError,
        deleteStageItemId
      );
    } else {
      setstatusWarnName(true);
    }
  };

  const updateMasterState = (attrName, value) => {
    let mainstate = attrName[0];
    let keys = attrName[1];
    if (attrName === "stageName") {
      setstageWarn(false);
      setpoststagesdata({
        layoutId: layoutid,
        name: value,
        status: [],
      });
    } else if (mainstate === "statusName") {
      if (keys === "name") {
        setstatusWarnName(false);
      } else if (keys === "colour") {
        setstatusWarnColour(false);
      }
      setpostStatusdata({
        // key:"status.0"
        ...postStatusdata,
        [keys]: value,
      });
    } else if (attrName === setdeleteStatusStages) {
      setDeleteStatusStep(value);
    } else {
      attrName(value);
    }
  };
  const param = getUrlParam("layout");
  useEffect(() => {
    const layoutid = localStorage.getItem("layoutid");
    setlayoutid(layoutid);

    get_stages_status(layoutid);
  }, [
    stagesStatus,
    openStatus,
    deleteStatusStages,
    deleteOpenStatus,
    editstagesStatus,
    editopenStatus,
    modulepicklist,
  ]);

  return (
    <>
      <Grid xs={12} className="text-right right_tab">
        <Link
          underline="none"
          className="greenBtn addfile"
          onClick={() => {
            setstagesStatus(true);
          }}>
          Add Stages
        </Link>
      </Grid>
      <Grid xs={12} className="stagessection">
        <ul className="tableHeading">
          <li></li>
          <li>
            <button className="sorting-btn mr-10">
              <img src="./images/sorting_btn.svg" alt="" />
            </button>
            Action
          </li>
        </ul>
        {stagesAndStatusData?.map((data, index) => (
          <div className="contentarea">
            <div className="tableSubHeading">
              <p>{data.name}</p>
              <p>
                <>
                  <Tooltip title="Add status" arrow>
                    <Link
                      underline="none"
                      className="addtaskaction mr-4"
                      onClick={() => {
                        setopenStatus(true);
                        setStageOrStatusid(data._id);
                        setStatusLength(data.status.length);
                      }}></Link>
                  </Tooltip>

                  <Tooltip title="Delete stages" arrow>
                    <Link
                      underline="none"
                      className="actiondelete mr-4"
                      onClick={() => {
                        setdeleteStageItemId(data._id);
                        setdeleteStatusStages(true);
                      }}></Link>
                  </Tooltip>
                  <Tooltip title="Edit stages" arrow>
                    <Link
                      underline="none"
                      className="actionedit"
                      onClick={() => {
                        seteditstagesStatus(true);
                        setdeleteStageItemId(data._id);
                        get_single_stages(data._id);
                      }}></Link>
                  </Tooltip>
                </>
              </p>
            </div>

            {
              // data.open_status === true ?
              data.status?.map((value, index) => (
                <ul className="innertable">
                  <li>
                    <Link
                      style={{
                        background: value.colour,
                        paddingLeft: "16px",
                        borderRadius: "4px",
                      }}
                      underline="none"></Link>
                    <span style={{ marginLeft: "10px" }}>{value.name}</span>
                  </li>
                  <li>
                    <Tooltip title="Edit status" arrow>
                      <Link
                        underline="none"
                        className="actionedit mr-4"
                        onClick={() => {
                          setstatusupdateIndex(index);
                          get_single_status(value._id, data._id);
                          setdeleteStageItemId(data._id);
                          seteditopenStatus(true);
                        }}></Link>
                    </Tooltip>

                    <Tooltip title="Delete status" arrow>
                      <Link
                        underline="none"
                        className="actionremove"
                        onClick={() => {
                          setdeleteStatusItemId(value._id);
                          setdeleteStatusParentId(data._id);
                          setdeleteOpenStatus(true);
                        }}></Link>
                    </Tooltip>
                  </li>
                </ul>
              ))
            }
          </div>
        ))}
      </Grid>

      <Modal open={stagesStatus} className="modalWrapper">
        <Box className="modalInner">
          <Grid container spacing={3} sx={{ flexDirection: "column" }}>
            <Grid xs={12}>
              <Link
                onClick={() => {
                  setstagesStatus(false);
                }}
                className="close-button"
              />
              <h5 className="popup-heading">Add Stages</h5>
            </Grid>
            <p></p>
            <Grid xs={12}>
              <RKTextField
                class_name="inputField"
                title="Stage Name"
                value={poststagesdata.name}
                attrName={"stageName"}
                value_update={updateMasterState}
                warn_status={stageWarn}
              />
            </Grid>
            <Grid xs={12} className="ctaBtn" textAlign={"center"}>
              <Button
                onClick={post_stages_data}
                variant="contained"
                className="submitBtn">
                Save
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>

      <Modal open={editstagesStatus} className="modalWrapper">
        <Box className="modalInner">
          <Grid container spacing={3} sx={{ flexDirection: "column" }}>
            <Grid xs={12}>
              <Link
                onClick={() => {
                  seteditstagesStatus(false);
                }}
                className="close-button"
              />
              <h5 className="popup-heading">Edit Stages</h5>
            </Grid>
            <p></p>
            <Grid xs={12}>
              <RKTextField
                class_name="inputField"
                title="Stage Name"
                value={poststagesdata.name}
                attrName={"stageName"}
                value_update={updateMasterState}
                warn_status={stageWarn}
              />
            </Grid>
            <Grid xs={12} className="ctaBtn" textAlign={"center"}>
              <Button
                onClick={update_stages}
                variant="contained"
                className="submitBtn">
                Update
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>

      <Modal open={openStatus} className="modalWrapper">
        <Box className="modalInner">
          <Grid container spacing={3} sx={{ flexDirection: "column" }}>
            <Grid xs={12}>
              <Link
                className="close-button"
                onClick={() => {
                  setopenStatus(false);
                  setpostStatusdata("");
                }}
              />
              <h5 className="popup-heading">Add Status</h5>
            </Grid>
            <Grid xs={12}>
              <RKTextField
                class_name="inputField"
                title="Status Name"
                value={postStatusdata.name}
                attrName={["statusName", "name"]}
                value_update={updateMasterState}
                warn_status={statusWarnName}
              />
            </Grid>
            <Grid xs={12}>
              <RKTextField
                class_name="inputField color-input"
                title="Choose colour"
                attrName={["statusName", "colour"]}
                value_update={updateMasterState}
                warn_status={statusWarnColour}
                type="color"
                value={postStatusdata.colour}
              />
            </Grid>
            <Grid xs={12} className="ctaBtn" textAlign={"center"}>
              <Button
                onClick={post_status_data}
                variant="contained"
                className="submitBtn">
                Save
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>

      <Modal open={editopenStatus} className="modalWrapper">
        <Box className="modalInner">
          <Grid container spacing={3} sx={{ flexDirection: "column" }}>
            <Grid xs={12}>
              <Link
                className="close-button"
                onClick={() => {
                  seteditopenStatus(false);
                  setpostStatusdata("");
                }}
              />
              <h5 className="popup-heading">Edit Status</h5>
            </Grid>
            <Grid xs={12}>
              <RKTextField
                class_name="inputField"
                title="Status Name"
                value={postStatusdata.name}
                attrName={["statusName", "name"]}
                value_update={updateMasterState}
                warn_status={statusWarnName}
              />
            </Grid>
            <Grid xs={12}>
              <RKTextField
                class_name="inputField color-input"
                title="Choose colour"
                attrName={["statusName", "colour"]}
                value_update={updateMasterState}
                warn_status={statusWarnColour}
                type="color"
                value={postStatusdata.colour}
              />
            </Grid>
            <Grid xs={12} className="ctaBtn" textAlign={"center"}>
              <Button
                onClick={update_status}
                variant="contained"
                className="submitBtn">
                Update
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>

      <Modal open={deleteStatusStages} className="modalWrapper">
        <>
          {deleteStatusStep === 1 ? (
            <Box className="modalInner deletepopup">
              <Grid container spacing={1}>
                <Grid xs={12}>
                  <Link
                    className="close-button"
                    onClick={() => setdeleteStatusStages(false)}
                  />
                  <div className="img-prt">
                    <img src={deleteimage} alt="" className="img" />
                  </div>

                  <h5 className="popup-heading">
                    Are you sure you want to delete this Stage?
                  </h5>
                </Grid>

                <Grid
                  xs={12}
                  className="ctaBtn"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}>
                  <Button
                    variant="contained"
                    className="submitBtn"
                    onClick={delete_stages}>
                    Yes, Delete
                  </Button>
                  <Button
                    onClick={() => setdeleteStatusStages(false)}
                    variant="contained"
                    className="cancelBtn">
                    cancel
                  </Button>
                </Grid>
              </Grid>
            </Box>
          ) : deleteStatusStep === 2 ? (
            <Box className="modalInner deletepopup">
              <Grid container spacing={1}>
                <Grid xs={12}>
                  <Link
                    className="close-button"
                    //onClick={() => setDeleteStatus(false)}
                  />
                  <div className="img-prt">
                    <img src={tickicon} alt="" className="img" />
                  </div>
                  <h5 className="popup-heading">Deleted Successfully!</h5>
                </Grid>
                <Grid xs={12}></Grid>
                <Grid
                  xs={12}
                  className="ctaBtn"
                  style={{ textAlign: "center" }}>
                  <Button
                    onClick={() => {
                      setdeleteStatusStages(false);
                      setDeleteStatusStep(1);
                      // setrefresh(true);
                    }}
                    variant="contained"
                    className="submitBtn">
                    Okay
                  </Button>
                </Grid>
              </Grid>
            </Box>
          ) : null}
        </>
      </Modal>

      <Modal open={deleteOpenStatus} className="modalWrapper">
        <>
          {deleteStatusStep === 1 ? (
            <Box className="modalInner deletepopup">
              <Grid container spacing={1}>
                <Grid xs={12}>
                  <Link
                    className="close-button"
                    onClick={() => setdeleteOpenStatus(false)}
                  />
                  <div className="img-prt">
                    <img src={deleteimage} alt="" className="img" />
                  </div>

                  <h5 className="popup-heading">
                    Are you sure you want to delete this status?
                  </h5>
                </Grid>

                <Grid
                  xs={12}
                  className="ctaBtn"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}>
                  <Button
                    variant="contained"
                    className="submitBtn"
                    onClick={delete_status}>
                    Yes, Delete
                  </Button>
                  <Button
                    onClick={() => setdeleteOpenStatus(false)}
                    variant="contained"
                    className="cancelBtn">
                    cancel
                  </Button>
                </Grid>
              </Grid>
            </Box>
          ) : deleteStatusStep === 2 ? (
            <Box className="modalInner deletepopup">
              <Grid container spacing={1}>
                <Grid xs={12}>
                  <Link
                    className="close-button"
                    //onClick={() => setDeleteStatus(false)}
                  />
                  <div className="img-prt">
                    <img src={tickicon} alt="" className="img" />
                  </div>
                  <h5 className="popup-heading">Deleted Successfully!</h5>
                </Grid>
                <Grid xs={12}></Grid>
                <Grid
                  xs={12}
                  className="ctaBtn"
                  style={{ textAlign: "center" }}>
                  <Button
                    onClick={() => {
                      setdeleteOpenStatus(false);
                      setDeleteStatusStep(1);
                      // setrefresh(true);
                    }}
                    variant="contained"
                    className="submitBtn">
                    Okay
                  </Button>
                </Grid>
              </Grid>
            </Box>
          ) : null}
        </>
      </Modal>
    </>
  );
};

export default StageStatus;
