//used
import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import { CLIENT_SERVICES } from "../../../../../../Services/Clients/ClientService";
import SearchDropdown from "../../../../../../Supporting files/SearchDropdown/SearchDropdown";
import UGTable from "../../../../../../Supporting files/UGTable/UGTable";
import { useAppSelector } from "../../../../../../Store/hooks";

const Subsidiary = ({
  clientName,
  disabled,
}: {
  clientName: string;
  disabled?: boolean;
}) => {
  const windowURL = window.location.search;
  const params = new URLSearchParams(windowURL);
  const id = params.get("id");
  const [toggle, setToggle] = useState(false);
  const [view, setview] = useState(false);
  const [subsidairyList, setSubsidairyList] = useState<any[]>([]);
  const userDetails = useAppSelector((state: any) => state.userDetailsSlice);
  //for subsidiary list data
  const [subsidiaryListColumns, setSubsidiaryListColumns] = useState([
    {
      field: "Organisation",
      headerName: "Organisation",
      width: 200,
      check_status: true,
    },
    {
      field: "Ownership",
      headerName: "Ownership",
      width: 200,
      check_status: true,
    },
    {
      field: "Sub-Industry",
      headerName: "Sub-Industry",
      width: 200,
      check_status: true,
    },
    {
      field: "Organization Email ",
      headerName: "Organization Email ",
      width: 200,
      check_status: true,
    },
    {
      field: "Organization Phone Number",
      headerName: "Organization Phone Number",
      width: 100,
      check_status: true,
    },
    {
      field: "Tax ID",
      headerName: "Tax ID",
      width: 150,
      check_status: true,
    },
    {
      field: "Select SPOC",
      headerName: "Select SPOC",
      width: 250,
      check_status: true,
    },
    {
      field: "Website URL",
      headerName: "Website URL",
      width: 200,
      check_status: true,
    },
    {
      field: "Domain",
      headerName: "Domain",
      width: 200,
      check_status: true,
    },
    {
      field: "Location Name",
      headerName: "Location Name",
      width: 200,
      check_status: true,
    },
    {
      field: "Location Type",
      headerName: "Location Type",
      width: 200,
      check_status: true,
    },
    {
      field: "Building/Street Name",
      headerName: "Building/Street Name",
      width: 200,
      check_status: true,
    },
    {
      field: "Country",
      headerName: "Country",
      width: 200,
      check_status: true,
    },
    {
      field: "State",
      headerName: "State",
      width: 200,
      check_status: true,
    },
    {
      field: "City",
      headerName: "City",
      width: 200,
      check_status: true,
    },
    {
      field: "Pincode",
      headerName: "Pincode",
      width: 200,
      check_status: true,
    },
  ]);
  const [parentOrgInfo, setparentOrgInfo] = useState<
    Array<{
      id: string;
      field: string;
      value: string;
      headerName: string;
      order: string;
    }>
  >([]);

  const [ClientList, setClientList] = useState<
    Array<{ label: string; id: string }>
  >([]);
  const [stateDataSubsidairy, setStateDataSubsidairy] = useState(false);
  const [parentOrg, setParentOrg] = useState("");
  const [parentOrganisationdata, setParentOrganisationdata] = useState("");

  const updateMasterState = (attrName: any, value: any) => {
    if (attrName === "setParentOrg") {
      setStateDataSubsidairy(false);
      get_parent_org_details(value.id);
      get_subsidary({ subsidryId: id, clientId: value.id });
    }
  };

  //get subsidiary list
  const client_subsidiary_list = () => {
    const onSuccess = (res: any) => {
      setSubsidairyList(res.data.data);
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    CLIENT_SERVICES.client_subsidiary_list(onSuccess, onError, id);
  };

  //get subsidiary data
  const get_subsidary = (body: any) => {
    const onSuccess = (res: any) => {
      setStateDataSubsidairy(true);
    };
    const onError = (err: any) => {
      console.log(".....err", err);
    };

    CLIENT_SERVICES.client_details_subsidiary(body, onSuccess, onError);
  };

  //post is subsidary true/false
  const Is_subsidary = (body: any) => {
    const onSuccess = (res: any) => {
      setStateDataSubsidairy(true);
    };
    const onError = (err: any) => {
      console.log(".....err", err);
    };

    CLIENT_SERVICES.Is_subsidiary(
      {
        isSubsidary: body,
      },
      id,
      onSuccess,
      onError
    );
  };

  const handleClick = (e: any) => {
    setToggle(!toggle);
    Is_subsidary(!toggle);
    setview(!view);
  };

  //parent org details
  const get_parent_org_details = (id: any) => {
    const onSuccess = (res: any) => {
      let arr: any = [];
      res.data.data.map((e: any) => {
        if (
          e.field === "Location Name" ||
          e.field === "Sub-Industry" ||
          e.field === "Tax ID" ||
          e.field === "Company Id" ||
          e.field === "Organization Phone Number" ||
          e.field === "Organization Email" ||
          e.field === "Building/Street Name" ||
          e.field === "Area Name" ||
          e.field === "Organisation" ||
          e.field === "Pincode"
        ) {
          arr.push(e);
        }
      });

      setparentOrgInfo(arr);
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    CLIENT_SERVICES.get_parent_org_details(onSuccess, onError, id);
  };

  //get one client data
  const get_client_layout_data = () => {
    const onSuccess = (res: any) => {
      setParentOrganisationdata(res.data.data[0].subsidiary);
      get_parent_org_details(res.data.data[0].subsidiary);
      setToggle(res.data.data[0].isSubsidiary);
      setview(res.data.data[0].isSubsidiary);
    };

    const onError = (err: any) => {
      console.log("err", err);
    };
    CLIENT_SERVICES.get_edit_one_data(onSuccess, onError, id);
  };

  // get client list
  const get_clients_list = () => {
    const onSuccess = (res: any) => {
      let filterData = res.data.data.filter((data: any) => {
        return clientName !== data.label;
      });
      setClientList(filterData);
    };
    const onError = (err: any) => {
      console.log("err", err);
    };

    CLIENT_SERVICES.get_clients_list(
      userDetails?.userType?.toLowerCase(),
      onSuccess,
      onError
    );
  };
  useEffect(() => {
    get_client_layout_data();
    get_clients_list();
    client_subsidiary_list();
  }, [stateDataSubsidairy]);

  return (
    <>
      <Box>
        <Grid xs={12}>
          <Grid container>
            <Grid
              xs={12}
              className="switchFields detailSection"
              display={"flex"}
              alignItems="center"
            >
              <span>Is this a Subsidiary Organization ?</span>
              <input
                type="checkbox"
                checked={view}
                className="switch"
                disabled={disabled}
                onClick={handleClick}
              />
            </Grid>
          </Grid>
        </Grid>
        {toggle === false ? (
          <Grid xs={12} className="detailBox2 mb-5">
            <Box className="detailSection">
              <div className="sectionTitle">
                <h4>Subsidiary Organization List </h4>
                <div className="actionBtns"></div>
              </div>

              <Grid container>
                <Grid xs={12} className="innerDetailBox">
                  <>
                    <Grid container>
                      <Grid xs={12}>
                        <div
                          style={{
                            height: "400px",
                            width: "100%",
                          }}
                        >
                          <UGTable
                            header_data={subsidiaryListColumns}
                            data={subsidairyList}
                            value_update={updateMasterState}
                            attrName={"UGTable"}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        ) : (
          <>
            <Grid xs={4} className="mb-3">
              <SearchDropdown
                class_name="inputField"
                title="Parent Organization Name"
                value={
                  ClientList.filter(
                    (data) => parentOrganisationdata === data.id
                  )[0]?.label
                }
                attrName={"setParentOrg"}
                value_update={updateMasterState}
                data={ClientList}
                warn_status={false}
                error_message="Please select client"
              />
            </Grid>
            <Grid xs={12} className="detailBox2">
              <Box className="detailSection">
                <div className="sectionTitle mb-0">
                  <h4>Parent Organization Details</h4>
                  <div className="actionBtns"></div>
                </div>
                <Grid container className="ParentOrgSection">
                  {parentOrgInfo?.map((data) => (
                    <Grid xs={6} xl={3}>
                      <p className="sm-font">
                        {data.field}
                        <span>{data.value}</span>
                      </p>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Grid>
          </>
        )}
      </Box>
    </>
  );
};

export default Subsidiary;
