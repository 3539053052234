import { EMPLOYEE_URL_CONSTANTS } from "../../APIVar/EmployeeUrlConstant";
import { EMPLOYER_URL_CONSTANTS } from "../../APIVar/EmployerUrlConstant";
import { URL_CONSTANTS } from "../../APIVar/URLConstants";
import { PatchAPI, PostAPI } from "../API";

const create_support_ticket = (
  dto: any,
  onSuccess: Function,
  onError: Function
) => {
  PostAPI.call(
    URL_CONSTANTS.create_support_ticket,
    dto,
    onSuccess,
    {},
    onError
  );
};
const create_emp_support_ticket = (
  dto: any,
  onSuccess: Function,
  onError: Function
) => {
  PostAPI.call(
    EMPLOYER_URL_CONSTANTS.create_support_ticket,
    dto,
    onSuccess,
    {},
    onError
  );
};

const create_employee_support_ticket = (
  dto: any,
  onSuccess: Function,
  onError: Function
) => {
  PostAPI.call(
    EMPLOYEE_URL_CONSTANTS.support,
    dto,
    onSuccess,
    {},
    onError
  );
};
const update_employee_pic = (
  dto: any,
  onSuccess: Function,
  onError: Function
) => {
  PatchAPI.call(
    EMPLOYEE_URL_CONSTANTS.update_pic,
    dto,
    onSuccess,
    {},
    onError
  );
};

export const SUPPORT_SERVICES = {
  create_support_ticket,
  create_emp_support_ticket,
  create_employee_support_ticket,
  update_employee_pic
};
