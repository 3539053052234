import { Box, Button, Link, Modal, TextField, Tooltip } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { GridCloseIcon } from "@mui/x-data-grid";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import SlidingPanel from "react-sliding-side-panel";

// import EmployeeDetailProfile from "../../../../../Components/Broker/EmployeeDetailProfile/EmployeeDetailProfile";

import "../../../../formLayout.scss";
import { nomineeDTO } from "../../../../Services/DTO/Employee/EmployeeDTO";
import { EMPLOYEE_NOMINEE_SERVICES } from "../../../../Services/Employee/NomineeService";
import DatePicker from "../../../../Supporting files/DatePicker/DatePicker";
import RKTextField from "../../../../Supporting files/RKTextField/RKTextField";
import SelectDropdown from "../../../../Supporting files/SelectDropdown/SelectDropdown";
import { calculateAgeFromDob } from "../../../../Supporting files/HelpingFunction";

interface Props {
  open_status: boolean;
  attrName?: any;
  value_update?: any;
  id: string;
  data?: any;
  refetchData: any;
}

const EditNominee: React.FC<Props> = ({
  open_status,
  attrName,
  value_update,
  id,
  data,
  refetchData,
}) => {
  const [nomineeDetails, setNomineeDetails] = React.useState<nomineeDTO>(data);

  const relationEmployee_data = [
    { key: "Brother", value: "Brother" },
    { key: "Sister", value: "Sister" },
    { key: "Husband", value: "Husband" },
    { key: "Wife", value: "Wife" },
    { key: "Father", value: "Father" },
    { key: "Mother", value: "Mother" },
    { key: "Son", value: "Son" },
    { key: "Daughter", value: "Daughter" },
  ];

  useEffect(() => {
    setNomineeDetails({
      _id: data._id,
      employeeId: id,
      fullName: data.fullName,
      relation: data.relation,
      dob: data.dob,
      age: data.age,
    });
  }, [data]);

  const updateMasterState = (attrName: any, value: any) => {
    let mainState = attrName[0];
    let key = attrName[1];
    let dateOfBirth: any = nomineeDetails.dob === null ? null : new Date(value);

    if (mainState === "nomineeDetail") {
      setNomineeDetails({
        ...nomineeDetails,
        [key]: value,
        [`warn${key.charAt(0).toUpperCase()}${key.slice(1)}`]: false,
        age:
          key === "dob" ? calculateAgeFromDob(dateOfBirth) : nomineeDetails.age,
      });
    } else {
      attrName(value);
    }
  };

  const onSubmit = () => {
    let data: any = { ...nomineeDetails };
    data.warnFullName = nomineeDetails.fullName.length === 0 ? true : false;
    data.warnRelation = nomineeDetails.relation.length === 0 ? true : false;
    data.warnDob = nomineeDetails.dob === null ? true : false;
    if (
      data.warnFullName === false &&
      data.warnRelation === false &&
      data.warnDob === false
    ) {
      const onSuccess = (res: any) => {
        value_update(attrName, false);
        setNomineeDetails({
          employeeId: id,
          fullName: "",
          relation: "",
          dob: null,
          age: "",
          warnDob: false,
        });
        refetchData();
      };
      const onError = (err: any) => {};
      EMPLOYEE_NOMINEE_SERVICES.update_nominee(
        nomineeDetails,
        onSuccess,
        {},
        onError,
        nomineeDetails?._id === undefined ? "" : nomineeDetails?._id
      );
    }
  };

  return (
    <>
      {/* Nominee add policy popup */}
      <SlidingPanel
        type={"right"}
        isOpen={open_status}
        size={75}
        panelClassName="sliding-panel"
        // backdropClicked={() => setAddNominee(false)}
      >
        <div>
          <Grid container spacing={3} alignItems="center">
            <Grid xs={10}>
              <h4 className="mb-0 mt-3 text-left">Edit Nominee Details</h4>
            </Grid>
            <Grid xs={2} textAlign="end" className="mt-2">
              <GridCloseIcon
                onClick={() => {
                  value_update(attrName, false);
                }}
              />
            </Grid>
            <Grid xs={12}>
              <hr />
            </Grid>
          </Grid>
          <div className="scrollable_area">
            <Grid container spacing={3}>
              <Grid xs={6}>
                <RKTextField
                  class_name="inputField"
                  title="Full Name"
                  value={nomineeDetails?.fullName}
                  attrName={["nomineeDetail", "fullName"]}
                  value_update={updateMasterState}
                  warn_status={nomineeDetails?.warnFullName}
                />
              </Grid>
              <Grid xs={6}>
                <SelectDropdown
                  class_name="inputField"
                  title="Relationship with employee"
                  value={nomineeDetails?.relation}
                  attrName={["nomineeDetail", "relation"]}
                  value_update={updateMasterState}
                  dropdown_data={relationEmployee_data}
                  warn_status={nomineeDetails?.warnRelation}
                />
              </Grid>
              <Grid xs={6}>
                <DatePicker
                  class_name="inputField"
                  title="Date of Birth"
                  value={nomineeDetails?.dob}
                  attrName={["nomineeDetail", "dob"]}
                  value_update={updateMasterState}
                  max_date={new Date()}
                  error_message="enter Date of Birth"
                  warn_status={
                    nomineeDetails?.warnDob === undefined
                      ? false
                      : nomineeDetails?.warnDob
                  }
                />
              </Grid>
              <Grid xs={6}>
                <p className="health_ecard">
                  Age
                  <span>{calculateAgeFromDob(nomineeDetails.dob)}</span>
                </p>
              </Grid>
            </Grid>
          </div>
          <Grid
            container
            spacing={3}
            alignItems="center"
            sx={{ paddingTop: "0" }}
          >
            <Grid xs={12} className="ctaBtn" style={{ textAlign: "center" }}>
              <Link className="submitBtn" onClick={onSubmit}>
                Submit
              </Link>
            </Grid>
          </Grid>
        </div>
      </SlidingPanel>
    </>
  );
};
export default EditNominee;
