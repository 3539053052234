import { URL_CONSTANTS } from "../../APIVar/URLConstants";
import { PostAPI } from "../API";

const getSignedUrl = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
  dto: any,
  user: string,
) => {
  PostAPI.call(
    URL_CONSTANTS.getSignedUrl(user),
    dto,
    onSuccess,
    {},
    onError
  );
};
export const GLOBAL_SERVICES = {
  getSignedUrl
};
