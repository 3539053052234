import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Box, Button, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import RemovePopup from "../../../../../../Components/Common/CarForm/RemovePopup/RemovePopup";
import { ALL_ROUTES } from "../../../../../../Routes/all_routes";
import {
  emailTemplateDTO,
  locationDTO,
  physicalFormDTO,
} from "../../../../../../Services/DTO/ProductConfigurator/AddInsurerDTO";
import { editInsurerDTO } from "../../../../../../Services/DTO/ProductConfigurator/EditInsurerDTO";
import { ADD_NEW_INSURER } from "../../../../../../Services/ProductConfigurator/AddNewInsurer";
import {
  get_city_list_via_name,
  get_country_list,
  get_state_list,
  uploadImage,
  validateEmail,
  validateMobileNumber,
  validateUrl,
} from "../../../../../../Supporting files/HelpingFunction";
import RKTextField from "../../../../../../Supporting files/RKTextField/RKTextField";
import SearchDropdown from "../../../../../../Supporting files/SearchDropdown/SearchDropdown";
import "../../../../../../formLayout.scss";
import { useAppSelector } from "../../../../../../Store/hooks";
import { RootState } from "../../../../../../Store/Store";
import NewLoader from "../../../../../../Components/NewLoader/NewLoader";

function EditTpa() {
  const navigate = useNavigate();
  const [disableSave, setDisableSave] = useState<boolean>(false);
  const usertypeinfo = useAppSelector(    (state: RootState) => state.userDetailsSlice.userType  );
  const [removeCategory, setRemoveCategory] = React.useState(false);
  const [show, setShow] = useState(false);
  const [id, setId] = useState("");

  // add isnurer state
  const [addInsurer, setAddInsurer] = useState<editInsurerDTO>({
    type: "GENERAL",
    insurerBasicInfo: {
      logo: "",
      name: "",
      email: "",
      // networkCount: "",
      website: "",
      contactNumber: "",
      warnName: false,
      warnEmail: false,
      // warnNetworkCount: false,
      warnContactNumber: false,
      warnWebsite: false,
    },
    headOfficeAddressDetails: {
      locationName: "",
      type: "",
      street: "",
      area: "",
      city: "",
      state: "",
      zip: "",
      country: "",
      warnLocationName: false,
      warnType: false,
      warnStreet: false,
      warnArea: false,
      warnCity: false,
      warnState: false,
      warnZip: false,
      warnCountry: false,
    },
    ageBand: {
      min: "",
      max: "",
      warnMin: false,
      warnMax: false,
    },
    location: [
      {
        name: "",
        type: "",
        address: "",
        areaname: "",
        city: "",
        state: "",
        zip: null,
        country: "",
        contactId: "",
        warnName: false,
        warnType: false,
        warnAddress: false,
        warnArea: false,
        warnCity: false,
        warnState: false,
        warnZip: false,
        warnCountry: false,
        warnContactId: false,
      },
    ],
    emailTemplate: [
      {
        title: "",
        module: "",
        subject: "",
        respondentEmail: "",
        sendersEmail: "",
        body: "",
        describe: "",
        attachmentEnable: show,
        warnTitle: false,
        warnModule: false,
        warnSubject: false,
        warnRespondentEmail: false,
        warnSendersEmail: false,
      },
    ],
    physicalForm: [
      {
        title: "",
        product: "",
        description: "",
        type: "",
        subtype: "",
        attachment: "",
        warnTitle: false,
        warnProduct: false,
        warnDescription: false,
        warnType: false,
        warnSubtype: false,
        warnAttachment: false,
      },
    ],
  });

  const [physicalForm, setPhysicalForm] = useState<physicalFormDTO>({
    title: "",
    product: "",
    description: "",
    type: "",
    subType: "",
    attachment: "",
    warnTitle: false,
    warnProduct: false,
    warnDescription: false,
    warnType: false,
    warnSubType: false,
    warnAttachment: false,
  });

  const [locationPopupDetail, setLocationPopupDetail] = useState<locationDTO>({
    id: Math.random() + 1,
    name: "",
    type: "",
    address: "",
    areaname: "",
    city: "",
    state: "",
    zip: null,
    country: "",
    contactId: "",
    warnName: false,
    warnType: false,
    warnAddress: false,
    warnArea: false,
    warnCity: false,
    warnState: false,
    warnZip: false,
    warnCountry: false,
    warnContactId: false,
  });
  const [addTemplateData, setAddTemplateData] = useState<emailTemplateDTO>({
    title: "",
    module: "",
    subject: "",
    respondentEmail: "",
    sendersEmail: "",
    body: "",
    describe: "",
    attachmentEnable: show,
    warnTitle: false,
    warnModule: false,
    warnSubject: false,
    warnRespondentEmail: false,
    warnSendersEmail: false,
  });

  const [displayLogo, setDisplayLogo] = useState<{
    logo: string;
    warning?: boolean;
  }>({ logo: "", warning: false });

  const [countryData, setCountryData] = React.useState<
    Array<{ label: string; id: string }>
  >([]);

  const [stateData, setStateData] = React.useState<
    Array<{ label: string; id: string }>
  >([]);

  const [cityData, setCityData] = React.useState<
    Array<{ label: string; id: string }>
  >([]);

  let selected_data: Array<{ id: any }> = [];
  const [array_delete, setArray_Delete] = useState<Array<{ id: number }>>([]);

  const [physicalData, setPhysicalData] = React.useState([]);

  // *************old data**************
  const [sectionStatus, SetSectionStatus] =
    React.useState<string>("personalDetails");
  const [subSection, setSubSection] = React.useState("basicinfo");
  let [emailData, setEmailData] = useState<Array<any>>([]);
  let [locationData, setLocationData] = useState<Array<any>>([]);
  // for scrolling
  const personalDetails = useRef(null);
  const address = useRef(null);
  const basicinfo = useRef(null);

  const [getContactListData, setGetContactListData] = useState<Array<any>>([]);

  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    setShowLoader(true);
  }, []);

  useEffect(() => {
    const windowURL = window.location.search;
    const params = new URLSearchParams(windowURL);

    const id = params.get("id");
    setId(id ? id : "");
    insurerDetail(id ?? "");
  }, []);

  // get country list
  useEffect(() => {
    get_country_list((cb: any) => {
      setCountryData(cb);
    });
    getContactList();
  }, []);

  const getContactList = () => {
    const onSuccess = (res: any) => {
      setGetContactListData(res.data.data);
    };
    const onError = (err: any) => {
      console.log("false", err);
    };
    ADD_NEW_INSURER.getSpocList(onSuccess, onError);
  };

  const scrollToSection = (elementRef: any) => {
    elementRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleClicks = (ref: any) => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  const updateMasterState = (attrName: any, value: any) => {
    if (attrName === "selected_data") {
      selected_data = value;
    }
    setArray_Delete(value);
    let mainstate = attrName[0];
    let key = attrName[1];
    if (mainstate === "physicalForm") {
      setPhysicalForm({
        ...physicalForm,
        [key]: value,
        [`warn${key.charAt(0).toUpperCase()}${key.slice(1)}`]: false,
      });
    } else {
      if (key === "contactNumber") {
        setAddInsurer({
          ...addInsurer,
          insurerBasicInfo: {
            ...addInsurer.insurerBasicInfo,
            contactNumber: value,
            warnContactNumber: !validateMobileNumber(value),
          },
        });
      } else if (addInsurer.insurerBasicInfo.hasOwnProperty(key)) {
        setAddInsurer({
          ...addInsurer,
          insurerBasicInfo: {
            ...addInsurer.insurerBasicInfo,
            [key]: value,
            [`warn${key.charAt(0).toUpperCase()}${key.slice(1)}`]:
              value.length === 0 ? true : false,
          },
        });
      } else if (addInsurer.headOfficeAddressDetails.hasOwnProperty(key)) {
        setAddInsurer({
          ...addInsurer,
          headOfficeAddressDetails: {
            ...addInsurer.headOfficeAddressDetails,
            [key]: value,
            [`warn${key.charAt(0).toUpperCase()}${key.slice(1)}`]:
              value.length === 0 ? true : false,
          },
        });
        if (key === "country") {
          get_state_list(value, (cb: any) => {
            setStateData(cb);
          });
          setAddInsurer({
            ...addInsurer,
            headOfficeAddressDetails: {
              ...addInsurer.headOfficeAddressDetails,
              [key]: value,
              warnCountry: value.length === 0 ? true : false,
              state: "",
              city: "",
              warnState: false,
              warnCity: true,
            },
          });
        }
        if (key === "state") {
          get_city_list_via_name(
            attrName[0].country,
            value.label,
            (cb: any) => {
              setCityData(cb);
            }
          );
          setAddInsurer({
            ...addInsurer,
            headOfficeAddressDetails: {
              ...addInsurer.headOfficeAddressDetails,
              [key]: value,
              warnState: value.length === 0 ? true : false,
              city: "",
              warnCity: true,
            },
          });
        }
      } else if (addInsurer.ageBand.hasOwnProperty(key)) {
        setAddInsurer({
          ...addInsurer,
          ageBand: {
            ...addInsurer.ageBand,
            [key]: value,
            [`warn${key.charAt(0).toUpperCase()}${key.slice(1)}`]:
              value.length === 0 ? true : false,
          },
        });
      }
    }
  };

  // VALIDATE FORM
  const validate_form = () => {
    if (disableSave) return;
    displayLogo.warning = addInsurer.insurerBasicInfo.logo === "" ? true : false;
    let data: any = addInsurer;
    data.insurerBasicInfo.warnName =
      addInsurer.insurerBasicInfo.name.length < 6 ? true : false;
    // data.insurerBasicInfo.warnNetworkCount = addInsurer.insurerBasicInfo.networkCount.length === 0 ? true : false;
    data.insurerBasicInfo.warnEmail = !validateEmail(
      addInsurer.insurerBasicInfo.email
    );
    data.insurerBasicInfo.warnWebsite = !validateUrl(
      addInsurer.insurerBasicInfo.website
    );
    data.insurerBasicInfo.warnContactNumber =
      addInsurer.insurerBasicInfo.contactNumber.length === 0 ? true : false;
    data.headOfficeAddressDetails.warnLocationName =
      addInsurer.headOfficeAddressDetails.locationName.length === 0
        ? true
        : false;
    data.headOfficeAddressDetails.warnType =
      addInsurer.headOfficeAddressDetails.type.length === 0 ? true : false;
    data.headOfficeAddressDetails.warnStreet =
      addInsurer.headOfficeAddressDetails.street.length === 0 ? true : false;
    data.headOfficeAddressDetails.warnCity =
      addInsurer.headOfficeAddressDetails.city.length === 0 ? true : false;
    data.headOfficeAddressDetails.warnState =
      addInsurer.headOfficeAddressDetails.state.length === 0 ? true : false;
    data.headOfficeAddressDetails.warnCountry =
      addInsurer.headOfficeAddressDetails.country.length === 0 ? true : false;
    data.headOfficeAddressDetails.warnZip =
      addInsurer.headOfficeAddressDetails.zip.length === 0 ? true : false;
    data.headOfficeAddressDetails.warnArea =
      addInsurer.headOfficeAddressDetails.area.length === 0 ? true : false;
    setAddInsurer({ ...data });
    const onSuccess = (res: any) => {
      navigate(ALL_ROUTES.PRODUCT_CONFIG_TPA);
    };
    const onError = (res: any) => {
      setDisableSave(false);
      console.log(res, "false");
    };

    if (
      displayLogo.warning === false &&
      addInsurer.insurerBasicInfo.warnName === false &&
      // addInsurer.insurerBasicInfo.warnNetworkCount === false &&
      addInsurer.insurerBasicInfo.warnEmail === false &&
      addInsurer.insurerBasicInfo.warnWebsite === false &&
      addInsurer.insurerBasicInfo.warnContactNumber === false &&
      addInsurer.headOfficeAddressDetails.warnLocationName === false &&
      addInsurer.headOfficeAddressDetails.warnType === false &&
      addInsurer.headOfficeAddressDetails.warnStreet === false &&
      addInsurer.headOfficeAddressDetails.warnCity === false &&
      addInsurer.headOfficeAddressDetails.warnState === false &&
      addInsurer.headOfficeAddressDetails.warnArea === false &&
      addInsurer.headOfficeAddressDetails.warnCountry === false &&
      addInsurer.headOfficeAddressDetails.warnZip === false
    ) {
      setDisableSave(true);
      ADD_NEW_INSURER.updateTpa(
        {
          name: addInsurer.insurerBasicInfo.name,
          logo: addInsurer.insurerBasicInfo.logo,
          website: addInsurer.insurerBasicInfo.website.includes("http")
            ? addInsurer.insurerBasicInfo.website
            : `http://${addInsurer.insurerBasicInfo.website}`,
          email: addInsurer.insurerBasicInfo.email,
          contactNumber: addInsurer.insurerBasicInfo.contactNumber,
          headOfficeAddressDetails: {
            name: addInsurer.headOfficeAddressDetails.locationName,
            type: addInsurer.headOfficeAddressDetails.type,
            street: addInsurer.headOfficeAddressDetails.street,
            area: addInsurer.headOfficeAddressDetails.area,
            city: addInsurer.headOfficeAddressDetails.city?.label
              ? addInsurer.headOfficeAddressDetails.city?.label
              : addInsurer.headOfficeAddressDetails.city,
            state: addInsurer.headOfficeAddressDetails.state?.label
              ? addInsurer.headOfficeAddressDetails.state?.label
              : addInsurer.headOfficeAddressDetails.state,
            country: addInsurer.headOfficeAddressDetails.country?.label
              ? addInsurer.headOfficeAddressDetails.country?.label
              : addInsurer.headOfficeAddressDetails.country,
            zip: addInsurer.headOfficeAddressDetails.zip,
          },
        },
        onSuccess,
        {},
        onError,
        id
      );
    }
  };

  // get insurer detail
  const insurerDetail = (id: any) => {
    const onSuccess = (res: any) => {
      if (res.status === 200) {
        setTimeout(() => {
          setShowLoader(false);
        }, 1500);
      }
      setAddInsurer(res.data.data[0]);
      setAddInsurer({
        ...addInsurer,
        headOfficeAddressDetails: {
          ...res.data.data[0]?.headOfficeAddressDetails,
          locationName: res.data.data[0]?.headOfficeAddressDetails.name,
        },
        insurerBasicInfo: {
          ...addInsurer?.insurerBasicInfo,
          logo: res.data.data[0]?.logo,
          name: res.data.data[0]?.name,
          email: res.data.data[0]?.email,
          website: res.data.data[0]?.website.split("http://")[1],
          contactNumber: res.data.data[0]?.contactNumber,
        },
      });
      setLocationData(res.data.data[0].location);
      let data: any = [];
      res.data.data[0].emailTemplate.forEach((e: any) => {
        data.push({ ...e, id: e._id });
      });
      setEmailData(data);
      let dataPhysical: any = [];
      res.data.data[0].emailTemplate.forEach((e: any) => {
        dataPhysical.push({ ...e, id: e._id });
      });
      setPhysicalData(dataPhysical);

      setDisplayLogo({ logo: res.data.data[0].logo });
      get_state_list(
        res.data.data[0].headOfficeAddressDetails?.country,
        (cb: any) => {
          setStateData(cb);
        }
      );

      get_city_list_via_name(
        res.data.data[0].headOfficeAddressDetails?.country,
        res.data.data[0].headOfficeAddressDetails?.state,
        (cb: any) => {
          setCityData(cb);
        }
      );
    };
    const onError = (err: any) => {
      console.log("false", err);
    };
    ADD_NEW_INSURER.getTpaDetail(
      onSuccess,
      onError,
      id,
      usertypeinfo.toLocaleLowerCase()
    );
  };

  return (
    <>
      {showLoader ? (
        <NewLoader />
      ) : (
        <Box className="layoutWrapper">
          {/* Page header */}
          <Grid container spacing={3} className="pageHeader">
            <Grid xs={6} className="pageTitle">
              <Link
                className="backStep"
                onClick={() => {
                  navigate(-1);
                }}
              >
                <ChevronLeftIcon />
              </Link>
              <div>
                <h3>Edit TPA</h3>
              </div>
            </Grid>
            <Grid
              xs={6}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            ></Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid xs={12}>
              <>
                <Grid container spacing={3}>
                  <Grid xs className="leftSection h-100">
                    <Link
                      className={
                        sectionStatus === "personalDetails"
                          ? "active sectionLink personalDetails"
                          : "sectionLink personalDetails"
                      }
                      onClick={() => {
                        scrollToSection(personalDetails);
                        SetSectionStatus("personalDetails");
                      }}
                    >
                      <span> Basic Info</span>
                    </Link>
                    <ul className="accordion_list">
                      <li>
                        <Link
                          className={
                            subSection === "basicinfo"
                              ? "inner_link active basicinfo"
                              : "inner_link basicinfo"
                          }
                          onClick={() => {
                            setSubSection("basicinfo");
                            handleClicks(subSection);
                            scrollToSection(basicinfo);
                          }}
                        >
                          Basic info
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={
                            subSection === "address"
                              ? "inner_link active address"
                              : "inner_link address"
                          }
                          onClick={() => {
                            setSubSection("address");
                            handleClicks(subSection);
                            scrollToSection(address);
                          }}
                        >
                          Address Details
                        </Link>
                      </li>
                    </ul>
                  </Grid>
                  <Grid xs className="rightSection h-100">
                    <>
                      <Box
                        onMouseEnter={() => {
                          setSubSection("basicinfo");
                        }}
                        className="detailSection"
                        ref={personalDetails}
                      >
                        <div className="sectionTitle mb-0">
                          <h4>Basic Info</h4>
                        </div>
                        <div ref={basicinfo}>
                          <Grid container spacing={3}>
                            <Grid xs={12}>
                              <h5 className="subHeading">Basic info</h5>
                            </Grid>
                          </Grid>
                          <Grid
                            onMouseEnter={() => {
                              setSubSection("basicinfo");
                            }}
                            container
                            spacing={3}
                          >
                            <Grid xs={12} sx={{ paddingBottom: "16px" }}>
                              <Button
                                className="add_logo greenBtn addfile"
                                component="label"
                              >
                                <img
                                  src={displayLogo.logo}
                                  style={{
                                    width: "140px",
                                    height: "50px",
                                    zIndex: "99",
                                  }}
                                />
                                <input
                                  hidden
                                  accept="image/*"
                                  multiple
                                  type="file"
                                  style={{ zIndex: "99" }}
                                  onChange={(e: any) => {
                                    let displaydata: any = displayLogo;
                                    displaydata.warning =
                                      displayLogo.logo !== "" ? true : false;
                                    setDisplayLogo({ ...displaydata });
                                    const file = e.target.files[0];

                                    uploadImage(
                                      file,
                                      (base64Image: any, base64String: any) => {
                                        setAddInsurer((prevState: any) => ({
                                          ...prevState,
                                          insurerBasicInfo: {
                                            ...prevState.insurerBasicInfo,
                                            logo: base64Image,
                                          },
                                        }));
                                        setDisplayLogo({
                                          logo: base64String,
                                          warning: false,
                                        });
                                      }
                                    );
                                  }}
                                />
                                <span className="img-error">
                                  {displayLogo?.warning === true
                                    ? " Upload a logo"
                                    : ""}
                                </span>
                              </Button>
                            </Grid>
                          </Grid>
                          <Grid
                            onMouseEnter={() => {
                              setSubSection("basicinfo");
                            }}
                            container
                            spacing={3}
                          >
                            <Grid sm={6} md={4} lg={6} xl={4}>
                              <RKTextField
                                validation_type="name"
                                class_name="inputField"
                                title="TPA Name"
                                value={addInsurer.insurerBasicInfo?.name}
                                attrName={[addInsurer.insurerBasicInfo, "name"]}
                                value_update={updateMasterState}
                                warn_status={
                                  addInsurer.insurerBasicInfo?.warnName
                                }
                                error_messg={
                                  addInsurer.insurerBasicInfo?.name === ""
                                    ? "Please enter name"
                                    : addInsurer.insurerBasicInfo?.name.length <
                                      6
                                    ? "should have at least 6 characters!"
                                    : ""
                                }
                                required={true}
                              />
                            </Grid>
                            {/* <Grid sm={4} md={4} lg={3} xl={2}>
                          <RKTextField
                            validation_type="numeric"
                            class_name="inputField"
                            title="No. of Network Hospital"
                            value={addInsurer.insurerBasicInfo?.networkCount}
                            attrName={[
                              addInsurer.insurerBasicInfo,
                              "networkCount",
                            ]}
                            value_update={updateMasterState}
                            warn_status={
                              addInsurer.insurerBasicInfo?.warnNetworkCount
                            }
                            required={true}
                          />
                        </Grid> */}
                          </Grid>

                          <Grid
                            onMouseEnter={() => {
                              setSubSection("basicinfo");
                            }}
                            container
                            spacing={3}
                          >
                            <Grid sm={4} md={4} lg={3} xl={2}>
                              <RKTextField
                                validation_type="url"
                                class_name="inputField"
                                title="Website"
                                value={addInsurer.insurerBasicInfo?.website}
                                attrName={[
                                  addInsurer.insurerBasicInfo,
                                  "website",
                                ]}
                                value_update={updateMasterState}
                                warn_status={
                                  addInsurer.insurerBasicInfo?.warnWebsite
                                }
                                error_messg={
                                  addInsurer.insurerBasicInfo?.website === ""
                                    ? "Enter  Url."
                                    : validateUrl(
                                        addInsurer.insurerBasicInfo?.website
                                      ) === false
                                    ? "Enter valid Url"
                                    : ""
                                }
                                required={true}
                              />
                            </Grid>
                            <Grid sm={4} md={4} lg={3} xl={2}>
                              <RKTextField
                                validation_type="email"
                                class_name="inputField"
                                title="Email"
                                value={addInsurer.insurerBasicInfo?.email}
                                attrName={[
                                  addInsurer.insurerBasicInfo,
                                  "email",
                                ]}
                                value_update={updateMasterState}
                                warn_status={
                                  addInsurer.insurerBasicInfo?.warnEmail
                                }
                                error_messg={
                                  addInsurer.insurerBasicInfo?.email === ""
                                    ? "Enter  Email-ID."
                                    : validateEmail(
                                        addInsurer.insurerBasicInfo?.email
                                      ) === false
                                    ? "Enter valid Email-ID"
                                    : ""
                                }
                                required={true}
                              />
                            </Grid>
                            <Grid sm={4} md={4} lg={3} xl={2}>
                              <RKTextField
                                validation_type="numeric"
                                class_name="inputField"
                                title="Contact Number"
                                charcterlimit={10}
                                value={
                                  addInsurer.insurerBasicInfo?.contactNumber
                                }
                                attrName={[
                                  addInsurer.insurerBasicInfo,
                                  "contactNumber",
                                ]}
                                value_update={updateMasterState}
                                warn_status={
                                  addInsurer.insurerBasicInfo?.warnContactNumber
                                }
                                error_messg={
                                  "Please enter valid Contact Number"
                                }
                                required={true}
                              />
                            </Grid>
                          </Grid>

                          <div
                            onMouseEnter={() => {
                              setSubSection("address");
                            }}
                            ref={address}
                          >
                            <Grid container spacing={3}>
                              <Grid xs={12}>
                                <h5 className="subHeading">
                                  Head Office Address Details
                                </h5>
                              </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                              <Grid xs={6} md={6} lg={6} xl={6}>
                                <RKTextField
                                  class_name="inputField"
                                  title="Location Name"
                                  value={
                                    addInsurer?.headOfficeAddressDetails
                                      ?.locationName
                                  }
                                  attrName={[
                                    addInsurer.headOfficeAddressDetails,
                                    "locationName",
                                  ]}
                                  value_update={updateMasterState}
                                  warn_status={
                                    addInsurer.headOfficeAddressDetails
                                      .warnLocationName
                                  }
                                  required={true}
                                />
                              </Grid>
                              <Grid xs={6} md={6} lg={6} xl={6}>
                                <RKTextField
                                  class_name="inputField"
                                  title="Location Type"
                                  value={
                                    addInsurer?.headOfficeAddressDetails?.type
                                  }
                                  attrName={[
                                    addInsurer.headOfficeAddressDetails,
                                    "type",
                                  ]}
                                  value_update={updateMasterState}
                                  warn_status={
                                    addInsurer?.headOfficeAddressDetails
                                      ?.warnType
                                  }
                                  error_messg="Please select Location"
                                  required={true}
                                />
                              </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                              <Grid xs={6} md={6} lg={6} xl={6}>
                                <RKTextField
                                  class_name="inputField"
                                  title="Building/Street Name"
                                  value={
                                    addInsurer?.headOfficeAddressDetails?.street
                                  }
                                  attrName={[
                                    addInsurer.headOfficeAddressDetails,
                                    "street",
                                  ]}
                                  value_update={updateMasterState}
                                  warn_status={
                                    addInsurer?.headOfficeAddressDetails
                                      ?.warnStreet
                                  }
                                  required={true}
                                />
                              </Grid>
                              <Grid xs={6} md={6} lg={6} xl={6}>
                                <RKTextField
                                  class_name="inputField"
                                  title="Area Name"
                                  value={
                                    addInsurer?.headOfficeAddressDetails?.area
                                  }
                                  attrName={[
                                    addInsurer.headOfficeAddressDetails,
                                    "area",
                                  ]}
                                  value_update={updateMasterState}
                                  warn_status={
                                    addInsurer?.headOfficeAddressDetails
                                      ?.warnArea
                                  }
                                  required={true}
                                />
                              </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                              <Grid sm={6} md={4} lg={3} xl={3}>
                                <SearchDropdown
                                  class_name="inputField"
                                  title="Country"
                                  value={
                                    addInsurer?.headOfficeAddressDetails
                                      ?.country
                                  }
                                  attrName={[
                                    addInsurer.headOfficeAddressDetails,
                                    "country",
                                  ]}
                                  value_update={updateMasterState}
                                  data={countryData}
                                  warn_status={
                                    addInsurer?.headOfficeAddressDetails
                                      ?.warnCountry
                                  }
                                  error_message="Please select"
                                  required={true}
                                />
                              </Grid>
                              <Grid sm={6} md={4} lg={3} xl={3}>
                                <SearchDropdown
                                  class_name="inputField"
                                  title="State"
                                  value={
                                    addInsurer?.headOfficeAddressDetails?.state
                                  }
                                  attrName={[
                                    addInsurer.headOfficeAddressDetails,
                                    "state",
                                  ]}
                                  value_update={updateMasterState}
                                  data={stateData}
                                  warn_status={
                                    addInsurer?.headOfficeAddressDetails
                                      ?.warnState
                                  }
                                  error_message="Please select"
                                  required={true}
                                />
                              </Grid>
                              <Grid sm={6} md={4} lg={3} xl={3}>
                                <SearchDropdown
                                  class_name="inputField"
                                  title="City"
                                  value={
                                    addInsurer?.headOfficeAddressDetails?.city
                                  }
                                  attrName={[
                                    addInsurer.headOfficeAddressDetails,
                                    "city",
                                  ]}
                                  value_update={updateMasterState}
                                  data={cityData}
                                  warn_status={
                                    addInsurer?.headOfficeAddressDetails
                                      ?.warnCity
                                  }
                                  error_message="Please select"
                                  required={true}
                                />
                              </Grid>
                              <Grid sm={6} md={4} lg={3} xl={3}>
                                <RKTextField
                                  title="Pincode"
                                  validation_type="numeric"
                                  class_name="inputField"
                                  charcterlimit={6}
                                  value={
                                    addInsurer?.headOfficeAddressDetails?.zip
                                  }
                                  attrName={[
                                    addInsurer.headOfficeAddressDetails,
                                    "zip",
                                  ]}
                                  value_update={updateMasterState}
                                  warn_status={
                                    addInsurer?.headOfficeAddressDetails
                                      ?.warnZip
                                  }
                                  required={true}
                                />
                              </Grid>
                            </Grid>
                          </div>
                        </div>
                      </Box>
                    </>
                  </Grid>
                </Grid>
              </>
            </Grid>
          </Grid>
          {/* footer section */}
          <Grid container spacing={3} className="footerSection">
            <Grid xs={12} className="ctaBtn">
              <Button
                variant="outlined"
                className="borderBtn"
                onClick={() => {
                  navigate(-1);
                }}
              >
                Cancel
              </Button>
              {/* <Button variant="outlined" className="borderBtn">
            Save Draft
          </Button> */}
              <Button
                variant="contained"
                className="submitBtn"
                onClick={validate_form}
                disabled={disableSave}
              >
                Update
              </Button>
            </Grid>
          </Grid>

          <RemovePopup
            open_status={removeCategory}
            attrName={setRemoveCategory}
            value_update={updateMasterState}
          />
        </Box>
      )}
    </>
  );
}
export default EditTpa;
