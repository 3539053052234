import { Close as CloseIcon } from '@mui/icons-material';
import { Box,IconButton,Modal,Typography } from '@mui/material';
import { useEffect,useState } from 'react';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  overflow: 'auto',
  maxHeight: '90vh',
};

const iframeStyle = {
  width: '100%',
  height: '80vh', // Default height for general cases
  border: 'none',
};

const officeIframeStyle = {
  width: '100%',
  height: '90vh', // Increased height for Office documents
  border: 'none',
};

const DocumentViewer = ({ url, open, onClose }) => {
  const [fileType, setFileType] = useState('');
  const [actualUrl, setActualUrl] = useState('');

  useEffect(() => {
    if (url) {
      try {
        const path = url.split('?')[0]; // Remove query string
        const extension = path.split('.').pop().toLowerCase(); // Get extension
        setActualUrl(url);
        setFileType(extension);
      } catch (error) {
        console.error("Invalid URL", error);
        setActualUrl('');
        setFileType('');
      }
    }
  }, [url]);

  const renderContent = () => {
    if (!actualUrl) {
      return (
        <Typography>
          Unable to display this document type or the URL does not contain a direct file link.
        </Typography>
      );
    }

    switch (fileType) {
      case 'pdf':
        return (
          <iframe
            src={`https://docs.google.com/viewer?url=${encodeURIComponent(actualUrl)}&embedded=true`}
            style={iframeStyle}
            title="PDF Document"
            onError={() => {
              // Fallback if Google Docs Viewer fails
              document.getElementById('pdf-viewer').style.display = 'none';
              document.getElementById('fallback-message').style.display = 'block';
            }}
          />
        );
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif':
        case 'jfif':
        return <img src={actualUrl} alt="Document" style={{ width: '100%' }} />;
      case 'doc':
      case 'docx':
      case 'xls':
      case 'xlsx':
        return (
          <iframe
            src={`https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(actualUrl)}`}
            style={officeIframeStyle}
            title="Office Document"
          />
        );
      default:
        return (
          <Typography>
            Unable to display this document type or the URL does not contain a direct file link.
          </Typography>
        );
    }
  };

  return (
    <Modal open={open} onClose={onClose} aria-labelledby="modal-title">
      <Box sx={modalStyle}>
        <IconButton
          sx={{ position: 'absolute', top: 8, right: 8 }}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
        <Typography id="modal-title" variant="h6" component="h2" mb={2}>
          Document Viewer
        </Typography>
        <div id="pdf-viewer">
          {renderContent()}
        </div>
      </Box>
    </Modal>
  );
};

export default DocumentViewer;
