import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useEffect, useState } from "react";
import { EMPLOYER_URL_CONSTANTS } from "../../../../../../APIVar/EmployerUrlConstant";
import { EMPLOYER_SETTING_SERVICES } from "../../../../../../Services/EmployerServices/Settings/SetingServices";
import { E_BE_FILTER_CODE } from "../../../../../../Services/Enum/E_UGTable";
import { useAppSelector } from "../../../../../../Store/hooks";
import {
  generateModuleId,
  updateTimeFormat,
} from "../../../../../../Supporting files/HelpingFunction";
import UGTable from "../../../../../../Supporting files/UGTable/UGTable";
import NewLoader from "../../../../../../Components/NewLoader/NewLoader";

const Designation = () => {
  const [departmentList, setdepartmentList] = useState([]);
  const userDetails = useAppSelector((state) => state.userDetailsSlice);
  const [tableLimit, setTableLimit] = useState();
  const [tableSkip, setTableSkip] = useState();
  const [columns, setColumns] = useState([
    {
      field: "departmentId",
      headerName: "Designation ID",
      width: 200,
      check_status: true,
      renderCell: (ValueFormatterParams: any) => {
        const { row } = ValueFormatterParams;

        return (
          <p
            style={{
              cursor: "pointer",
              fontWeight: "400",
              color: "#6b62dc",
              textDecoration: "none",
            }}
            // onClick={() => {
            //   navigate(`${ALL_ROUTES.SETTING_MODULE_FORM}?id=${row._id}`);
            // }}
          >
            {generateModuleId("DES", row.id.toString())}
          </p>
        );
      },
    },
    {
      field: "value",
      headerName: "Designation Name",
      width: 300,
      check_status: true,
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 200,
      check_status: true,
      renderCell: (ValueFormatterParams: any) => {
        const { row, index } = ValueFormatterParams;
        row.status = 7;
        return (
          <div id={`_${row.status}`}>
            <div className="status_dropdown" id={`_${row.status}`}>
              {row.status === 7 ? (
                <CheckCircleIcon id={`_${row.status.toString()}`} />
              ) : row.status === 8 ? (
                <HighlightOffIcon id={`_${row.status.toString()}`} />
              ) : null}
              <span>Active</span>
            </div>
          </div>
        );
      },
    },
    {
      field: "createdAt",
      headerName: "Created on",
      width: 200,
      check_status: true,
      renderCell: (ValueFormatterParams: any) => {
        const { row } = ValueFormatterParams;
        return <span>{updateTimeFormat(row.createdAt)}</span>;
      },
    },
    {
      field: "updatedAt",
      headerName: "Updated on",
      width: 200,
      check_status: true,
      renderCell: (ValueFormatterParams: any) => {
        const { row } = ValueFormatterParams;
        return <span>{updateTimeFormat(row.updatedAt)}</span>;
      },
    },
  ]);
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    setShowLoader(true);
  }, []);

  const updateMasterState = (attrName: any, value: any) => {
    if (attrName === "selected_data") {
    }
  };

  const get_designation_list = () => {
    const onSuccess = (res: any) => {
      if (res.status === 200) {
        setTimeout(() => {
          setShowLoader(false);
        }, 1500);
      }
      const updatedArray = res.data.data.flatMap((obj: any) => {
        return obj.value.map((val: any, index: any) => {
          const newObj = { ...obj };
          newObj.value = val?.label;
          newObj.id = 123 + index;
          return newObj;
        });
      });

      setdepartmentList(updatedArray);
    };

    const onError = (err: any) => {
      console.log("designation", err);
    };
    const url = userDetails.parentsId
      ? `${EMPLOYER_URL_CONSTANTS.get_employer_designation_list}&createdBy=${userDetails.createdBy}`
      : EMPLOYER_URL_CONSTANTS.get_employer_designation_list;
    // Call the EMPLOYER_SETTING_SERVICES function to get the designation list
    EMPLOYER_SETTING_SERVICES.fetch_data(url, onSuccess, onError);
  };

  useEffect(() => {
    get_designation_list();
  }, []);

  return (
    <>
      {showLoader ? (
        <NewLoader />
      ) : (
        <Grid container spacing={2} className="currunciesWrapper">
          <Grid xs={4}>
            <h3 className="mb-0">Designation</h3>
          </Grid>

          <Grid xs={8} className="text-right"></Grid>
          <Grid xs={12} sx={{ width: "100%" }}>
            <div style={{ height: 400 }}>
              <UGTable
                header_data={columns}
                data={departmentList}
                value_update={updateMasterState}
                attrName={"UGTable"}
                BE_filter_code={E_BE_FILTER_CODE.DEPARTMENT}
                refetch_data={get_designation_list}
                setTableLimit={setTableLimit}
                setTableSkip={setTableSkip}
                paginationMode="client"
                class_Name={"th-setting"}
              />
            </div>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default Designation;
