import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useEffect, useState } from "react";
import SlidingPanel from "react-sliding-side-panel";
import { toast } from "react-toastify";
import { PROFILE_SERVICE } from "../../../../../Services/Employee/ProfileService";
import { useAppSelector } from "../../../../../Store/hooks";
import DatePicker from "../../../../../Supporting files/DatePicker/DatePicker";
import RKTextField from "../../../../../Supporting files/RKTextField/RKTextField";
import SelectDropdown from "../../../../../Supporting files/SelectDropdown/SelectDropdown";
import { calculateAgeFromDob } from "../../../../../Supporting files/HelpingFunction";
interface Props {
  open_status: boolean;
  attrName: any;
  value_update: Function;
  api_url: string;
  memberList:any[];
}

const AddFamilyMembers: React.FC<Props> = ({
  open_status,
  attrName,
  value_update,
  api_url,
  memberList,
}) => {
  const [relationEmployees_data, SetrelationEmployees_data] = useState<any[]>([]);
  const [disableSave,setDisableSave] = useState(false)

  const relationEmployees_data1 = [
    { key: "Husband", value: "Husband" },
    { key: "Wife", value: "Wife" },
    { key: "Daughter", value: "Daughter" },
    { key: "Son", value: "Son" },
    { key: "Father", value: "Father" },
    { key: "Mother", value: "Mother" },
    { key: "Father-In-Law", value: "Father-In-Law" },
    { key: "Mother-In-Law", value: "Mother-In-Law" },
  ];

  useEffect(() => {
    if (relationEmployees_data1) {
      const filteredData = relationEmployees_data1.filter((listData) => {
        if (listData.key === "Son" || listData.key === "Daughter") {
          return true;
        } else {
          return !memberList.some(
            (memberData:any) =>
              memberData.relationWithEmployee === listData.key ||
              memberData.relationShip === listData.key
          );
        }
      });
      SetrelationEmployees_data(filteredData);
    }
  }, [open_status]);
  const { id } = useAppSelector((state) => state.userDetailsSlice);
  const [formValue, setFormValue] = useState<{
    fullName: { value: string; warning: boolean };
    relationShip: { value: string; warning: boolean };
    dob: { value: string | null; warning: boolean };
  }>({
    fullName: { value: "", warning: false },
    relationShip: { value: "", warning: false },
    dob: { value: null, warning: false },
  });
  const updateMasterState = (attrName: any, value: any) => {
    if (attrName[0] === "formValue") {
      setFormValue((prev) => ({
        ...prev,
        [attrName[1]]: { value: value, warning: false },
      }));
    } else {
      attrName(value);
    }
  };

  // onSubmit function of form to validate the value of form
  const onSubmit = () => {
    if(disableSave)  return;
    let data = { ...formValue };
    data = {
      ...data,
      fullName: { ...data.fullName, warning: data.fullName.value === "" },
      relationShip: {
        ...data.relationShip,
        warning: data.relationShip.value === "",
      },
      dob: { ...data.dob, warning: data.dob.value === null },
    };
    setFormValue({ ...data });
    if (
      !data.fullName.warning &&
      !data.relationShip.warning &&
      !data.dob.warning
    ) {
      api_call();
    }
  };

  //api intigration
  const api_call = () => {
    setDisableSave(true);
    const onSuccess = (res: any) => {
      if (res.status === 200) {
        value_update(attrName, false);
        toast.success(res.data.message);
        setDisableSave(false)
      }
      setFormValue({
        fullName: { value: "", warning: false },
        relationShip: { value: "", warning: false },
        dob: { value: null, warning: false },
      })
    };
    const onError = (err: any) => {
      console.log("err", err);
      toast.error(
        err.response.data.errors[0].message +
        err.response.data.errors[0].message
       
      );
      setFormValue({
        fullName: { value: "", warning: false },
        relationShip: { value: "", warning: false },
        dob: { value: null, warning: false },
      })
      setDisableSave(false)
    };
    let param = {
      employeeId: id,
      member: {
        fullName: formValue.fullName.value,
        relationWithEmployee: formValue.relationShip.value,
        dob: formValue.dob.value,
      },
    };
    PROFILE_SERVICE.add_member_to_employee_portal(
      param,
      onSuccess,
      onError,
      api_url
    );
  };

  return (
    <>
      <SlidingPanel
        type={"right"}
        isOpen={open_status}
        size={75}
        panelClassName="slidingPanel"
      // backdropClicked={() => value_update(attrName, false)}
      >
        <>
          <Grid container spacing={2} alignItems="center">
            <Grid xs={10} className="headingTitle">
              <h3>
                <span>Add Family Member</span>
              </h3>
            </Grid>
            <Grid xs={2} textAlign="right">
              <Link
                className="closeIcon"
                onClick={() => {
                  value_update(attrName, false);
                  setFormValue({
                    fullName: { value: "", warning: false },
                    relationShip: { value: "", warning: false },
                    dob: { value: null, warning: false },
                  })
                }}
              >
                <CloseIcon />
              </Link>
            </Grid>
            <Grid xs={12}>
              <hr />
            </Grid>
          </Grid>
          <Box className="scrollable_area">
            <Grid container spacing={3}>
              <Grid xs={6}>
                <RKTextField
                  validation_type="name"
                  class_name="inputField"
                  title={"Full Name"}
                  value={formValue.fullName.value}
                  attrName={["formValue", "fullName"]}
                  value_update={updateMasterState}
                  warn_status={formValue.fullName.warning}
                />
              </Grid>
              <Grid xs={6}>
                <SelectDropdown
                  class_name="inputField"
                  title="Relationship"
                  value={formValue.relationShip.value}
                  attrName={["formValue", "relationShip"]}
                  value_update={updateMasterState}
                  dropdown_data={relationEmployees_data}
                  warn_status={formValue.relationShip.warning}
                  error_messg={"Select relationship"}
                />
              </Grid>
              <Grid xs={6}>
                <DatePicker
                  class_name="inputField"
                  title="DOB"
                  value={formValue.dob.value}
                  attrName={["formValue", "dob"]}
                  value_update={updateMasterState}
                  error_message="Please enter DOB"
                  warn_status={formValue.dob.warning}
                />
              </Grid>
              <Grid xs={6}>
                <p className="health_ecard">
                  Age
                  <span>{calculateAgeFromDob(formValue.dob.value)}</span>
                </p>
              </Grid>
            </Grid>
          </Box>
          <Grid container spacing={3}>
            <Grid xs={12} className="ctaBtn mt-4">
              <Button
                variant="contained"
                className="submitBtn fixedbtn"
                onClick={onSubmit}
                disabled={disableSave}
              >Submit</Button>
            </Grid>
          </Grid>
        </>
      </SlidingPanel>
    </>
  );
};

export default AddFamilyMembers;
