import { addDays, endOfDay, startOfDay, subDays } from "date-fns";
import React from "react";
import { DateRangePicker } from "rsuite";
import "./UGDateRangePicker.scss";

interface Props {
  minDate?: Date;
  maxDate?: Date;
  placement?: any;
  value_update: Function;
  defaultValue?: any;
}

const UGDateRangePicker: React.FC<Props> = ({
  minDate,
  maxDate,
  placement,
  value_update,
  defaultValue,
}) => {
  const updatemaster = (e: any) => {
    if (e === null) return
    value_update("rangedatepicker", e)
  };

  return (<>
    <div className="dateRangePicker" style={{ display: 'inline-grid' }}>
      <DateRangePicker
        defaultValue={defaultValue ? defaultValue : null}
        placement={"bottomEnd"}
        placeholder={"Start Date - End Date (DD-MM-YY)"}
        cleanable={false}
        format={"dd-MM-yyyy"}
        // editable={false}
        onChange={updatemaster}
        ranges={[
          {
            label: "today",
            value: [startOfDay(new Date()), endOfDay(new Date())],
          },
          {
            label: "yesterday",
            value: [
              startOfDay(addDays(new Date(), -1)),
              endOfDay(addDays(new Date(), -1)),
            ],
          },
          {
            label: "last7Days",
            value: [startOfDay(subDays(new Date(), 6)), endOfDay(new Date())],
          },
          {
            label: "Last 30 Days",
            value: [startOfDay(subDays(new Date(), 30)), endOfDay(new Date())],
          },
          {
            label: "Last Month",
            value: [
              startOfDay(
                startOfDay(
                  new Date(
                    new Date().getFullYear(),
                    new Date().getMonth() - 1,
                    1
                  )
                )
              ),
              endOfDay(
                new Date(
                  new Date().getFullYear(),
                  new Date().getMonth() - 1,
                  30
                )
              ),
            ],
          },
          {
            label: "This Month",
            value: [
              startOfDay(
                new Date(new Date().getFullYear(), new Date().getMonth(), 1)
              ),
              endOfDay(
                new Date(new Date().getFullYear(), new Date().getMonth(), 30)
              ),
            ],
          },
          {
            label: "This Year",
            value: [
              startOfDay(new Date(new Date().getFullYear(), 0, 1)),
              endOfDay(new Date(new Date().getFullYear(), 11, 30)),
            ],
          },
        ]}
      />
    </div>
  </>

  );
};

export default UGDateRangePicker;
