import CheckIcon from "@mui/icons-material/Check";
import EditIcon from "@mui/icons-material/Edit";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box, Link, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import SearchDropdown from "../SearchDropdown/SearchDropdown";

const SearchDropdownDisable = ({
  fieldDisable,
  params,
  id,
  title,
  value,
  value_update,
  attrName,
  data,
  warn_status,
  class_name,
  error_messg,
  info_status,
  info_message,
  required,
  value_type_id_value = false,
  disable_status = false,
  set_disable_status,
  disabled,
}: {
  fieldDisable?: boolean;
  title: string;
  value: { label: string; id: string } | string;
  attrName: any;
  value_update: Function;
  data: Array<any>;
  warn_status?: boolean;
  class_name?: string;
  error_messg?: string;
  info_status?: boolean;
  info_message?: string;
  required?: boolean;
  params?: any;
  id?: string;
  value_type_id_value?: boolean;
  disable_status?: boolean;
  set_disable_status?: Function;
  disabled?: boolean;
}) => {
  const [parsedValue, setParsedValue] = useState("");
  const [disableToggle, setDisableToggle] = useState<boolean>(disable_status);
  const [selectedValue, setselectedValue] = React.useState<string>("");

  const updateMasterState = (attrName: any, value: any) => {
    value_update(attrName, value);
    setselectedValue(attrName);
  };
  const edit = () => {
    setDisableToggle(!disableToggle);
    if (disable_status === false || disableToggle === false) {
      if (set_disable_status) {
        set_disable_status(!disable_status);
      }
    }
    if (disable_status === true || disableToggle === true) {
      if (params) {
        params(attrName);
      }
    }
  };

  useEffect(() => {
    try {
      let parsedValue = value;
      if (typeof value === "string") {
        parsedValue = JSON.parse(value);
      }
      if (
        typeof parsedValue === "object" &&
        parsedValue !== null &&
        "label" in parsedValue
      ) {
        setParsedValue(parsedValue.label);
      } else {
        setParsedValue(`${parsedValue}`);
      }
    } catch (error) {
      // Parsing failed, use the original value
      setParsedValue(`${value}`);
    }
  }, [value]);

  return (
    <Box className="individualEdit">
      {disable_status === false &&
        disableToggle === false &&
        fieldDisable === false ? (
        <div className={parsedValue ? "staticField" : "staticField nullValue"}>
          <label>{title}</label>
          <p>{parsedValue}</p>
        </div>
      ) : (
        <SearchDropdown
          disabled={fieldDisable || disabled}
          class_name={class_name}
          title={title}
          value_update={updateMasterState}
          value={parsedValue}
          attrName={attrName}
          data={data}
          warn_status={warn_status}
          required={required}
          error_message={error_messg}
          info_status={info_status}
          info_message={info_message}
          value_type_id_value={value_type_id_value}
        />
      )}
      {info_status === true ? (
        <Tooltip title={info_message === undefined ? "" : info_message}>
          <span className="infoMessage">
            <InfoOutlinedIcon />
          </span>
        </Tooltip>
      ) : null}

      {!fieldDisable && (
        <>
          {disabled === false && (
            <Link
              className={
                disable_status === true || disableToggle === true
                  ? "editUpdate visible"
                  : "editUpdate"
              }
              onClick={edit}
            >
              {disable_status === false && disableToggle === false ? (
                <EditIcon />
              ) : (
                <CheckIcon />
              )}
            </Link>
          )}
        </>

      )}
    </Box>
  );
};

export default SearchDropdownDisable;
