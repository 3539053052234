import classNames from "classnames";
import { useDrop } from "react-dnd";
import { COMPONENT, SIDEBAR_ITEM, ROW, COLUMN } from "./constants";

const ACCEPTS = [SIDEBAR_ITEM, COMPONENT, ROW, COLUMN];

const DropZone = ({ data, onDrop, isLast, className }) => {
  //console.log(data)
  const [{ isOver, canDrop }, drop] = useDrop({
    accept: ACCEPTS,
    drop: (item, monitor) => {
      onDrop(data, item);
    },
    canDrop: (item, monitor) => {
      const dropZonePath = data.path;
      const splitDropZonePath = dropZonePath.split("-");
      const itemPath = item.path;
      let i

      //console.log("dropZonePath",dropZonePath, "splitDropZonePath",splitDropZonePath, "itemPath", itemPath, data, dt)

      //console.log(data.type)
      if(data.type === "address" || data.type === "contactId" ){
        return false
      }

      if(data.type !== undefined && typeof(data.type) !== String ){
        //console.log("it work")
        const isArrayExist = data.type.children? true: false

        //console.log("data.type.children",data.type.children[0].children)
        
        //up and down or left or right is not allowed for address fields
        if(isArrayExist){
          i = data.type.children[0].children.findIndex((e)=>{ return e.field_type==="address" || e.field_type==="contactId"})
          //console.log("iiiiiiiiii",i)
            if(i !== -1){
              return false
            }
        }
      }

      // sidebar items can always be dropped anywhere
      if (!itemPath) {
        if (data.childrenCount >= 3 && splitDropZonePath.length===4) {
         return false;
        }
        else if(!itemPath && item.component.type === "Address" && data.childrenCount!==0 && splitDropZonePath.length===5 || !itemPath && item.component.type === "Address" && data.childrenCount!==0 && splitDropZonePath.length===4){
          return false
        }
        return true;
      }

      const splitItemPath = itemPath.split("-");
      //console.log(splitItemPath,  item.component.type)

      if(splitItemPath.length===5 && item.component.type==="address"){
        return false
      }

      // into difrent main section 
      const dropZonePathMainSectionIndex = splitDropZonePath[0];
      const itemPathMainSectionIndex = splitItemPath[0];
      const diffMainSection = dropZonePathMainSectionIndex !== itemPathMainSectionIndex;

      const dropZonePathSubSectionIndex = splitDropZonePath[1];
      const itemPathSubSectionIndex = splitItemPath[1];
      const diffSubSection = dropZonePathSubSectionIndex !== itemPathSubSectionIndex;

      if (
        (diffMainSection || diffSubSection) && splitDropZonePath.length === 3
      ) {
        return false;
      }

      // limit columns when dragging from one row to another row
      const dropZonePathRowIndex = splitDropZonePath[1];
      const itemPathRowIndex = splitItemPath[1];
      const diffRow = dropZonePathRowIndex !== itemPathRowIndex;
      //console.log(splitDropZonePath.length, diffRow, splitDropZonePath)
      if (
        diffRow &&
        splitDropZonePath.length === 3 &&
        data.childrenCount >= 3  || splitDropZonePath.length===4 && data.childrenCount >= 3
      ) {
        return false;
      }

      //limit coulumns when dragging from one main section to another main sections row
      const dropZonePathMainIndex = splitDropZonePath[0];
      const itemPathMainIndex = splitItemPath[0];
      const diffMain = dropZonePathMainIndex !== itemPathMainIndex;
      if (
        diffMain &&
        splitDropZonePath.length === 3 &&
        data.childrenCount >= 3
      ) {
        return false;
      }

      // Invalid (Can't drop a parent element (row) into a child (column))
      const parentDropInChild = splitItemPath.length < splitDropZonePath.length;
      if (parentDropInChild) return false;

      // Current item can't possible move to it's own location
      if (itemPath === dropZonePath) return false;

      // Current area
      if (splitItemPath.length === splitDropZonePath.length) {
        const pathToItem = splitItemPath.slice(0, -1).join("-");
        const currentItemIndex = Number(splitItemPath.slice(-1)[0]);

        const pathToDropZone = splitDropZonePath.slice(0, -1).join("-");
        const currentDropZoneIndex = Number(splitDropZonePath.slice(-1)[0]);

        if (pathToItem === pathToDropZone) {
          const nextDropZoneIndex = currentItemIndex + 1;
          if (nextDropZoneIndex === currentDropZoneIndex) return false;
        }
      }

      return true;
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  });

  const isActive = isOver && canDrop;
  // console.log(isActive);
  return (
    <div
      className={classNames(
        "dropZone",
        { active: isActive, isLast },
        className
      )}
      ref={drop}
    />
  );
};
export default DropZone;
