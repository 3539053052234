import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import SelectDropdown from "../../../../../../Supporting files/SelectDropdown/SelectDropdown";
import SearchDropdown from "../../../../../../Supporting files/SearchDropdown/SearchDropdown";
import RKTextField from "../../../../../../Supporting files/RKTextField/RKTextField";

function EmployeeHealthForm() {
  const [gender, setGender] = React.useState("");
  const [age, setAge] = React.useState("");
  const [pincode, setPincode] = React.useState("");
  const [mobile, setMobile] = React.useState("");
  const [email, setEmail] = React.useState("");

  const updateMasterState = (attrName: any, value: any) => {
    attrName(value);
  };

  const gender_data = [
    { Key: "Yes", value: "Yes" },
    { Key: "No", value: "No" },
  ];
  const age_data = [
    { label: "18 Years", value: "18 Years" },
    { label: "19 Years", value: "19 Years" },
    { label: "20 Years", value: "20 Years" },
    { label: "21 Years", value: "21 Years" },
  ];

  const adults = ["1 Adult", "2 Adults"];
  const child = ["0", "1", "2", "3", "4"];
  return (
    <Grid container spacing={3}>
      <Grid xs={6}>
        {/* <RadioButton options={adults} defaultSelected="1 Adult" /> */}
      </Grid>
      <Grid xs={6}>
        {/* <RadioButton options={child} defaultSelected="0" /> */}
      </Grid>

      <Grid xs={6}>
        <SearchDropdown
          class_name="inputField"
          title="Age"
          value={age}
          attrName={setAge}
          value_update={updateMasterState}
          data={age_data}
          warn_status={false}
          error_message={"please select age"}
        />
      </Grid>
      <Grid xs={6}>
        <SelectDropdown
          class_name="inputField"
          title="Gender"
          value={gender}
          attrName={setGender}
          value_update={updateMasterState}
          dropdown_data={gender_data}
          warn_status={false}
        />
      </Grid>

      <Grid xs={6}>
        <RKTextField
          class_name="inputField"
          title="Pin Code"
          value={pincode}
          attrName={setPincode}
          value_update={updateMasterState}
          warn_status={false}
        />
      </Grid>

      <Grid xs={6}>
        <RKTextField
          class_name="inputField"
          title="Mobile"
          value={mobile}
          attrName={setMobile}
          value_update={updateMasterState}
          warn_status={false}
        />
      </Grid>
      <Grid xs={6}>
        <RKTextField
          class_name="inputField"
          title="Email"
          value={email}
          attrName={setEmail}
          value_update={updateMasterState}
          warn_status={false}
        />
      </Grid>
    </Grid>
  );
}

export default EmployeeHealthForm;
