import { PayloadAction } from "@reduxjs/toolkit";

import {
  BrokerPermission,
  EmployerPermission,
  Permission,
} from "../../../Services/Types/TUserData";

function updateBrokerPermission(
  state: Permission,
  action: PayloadAction<BrokerPermission>
) {
  return { ...state, BROKER: action.payload };
}
function updateEmployerPermission(
  state: Permission,
  action: PayloadAction<EmployerPermission>
) {
  return { ...state, EMPLOYER: action.payload };
}
function updateMemberStatus(
  state: Permission,
  action: PayloadAction<number>
) {
  return { ...state, MemberStatus: action.payload };
}

export const PERMISSION_REDUCERS = {
  updateBrokerPermission,
  updateEmployerPermission,
  updateMemberStatus,
};

export type PermissionReducer = typeof PERMISSION_REDUCERS;
