import { URL_CONSTANTS } from "../../APIVar/URLConstants";
import { GetAPI, PostAPI, DeleteAPI, PatchAPI } from "../API";
import {
  groupHealthAddDTO,
  groupHealthDeleteDTO,
  groupHealthExculsionAddDTO,
  groupHealthFeatureAddDTO,
} from "../DTO/ProductConfigurator/GroupHealthDTO";

const createCatageory = (
  dto: groupHealthAddDTO[],
  onSuccess: any,
  config: any,
  onError: any
) => {
  PostAPI.call(
    URL_CONSTANTS.create_group_health_catageory,
    dto,
    onSuccess,
    {},
    onError
  );
};
const getCatageory = (
  searchBarFilter:any,
  onSuccess: (data: any) => void,
  onError: (err: any) => void
) => {
  GetAPI.call(`${URL_CONSTANTS.get_group_health_catageory}${searchBarFilter}`, onSuccess, onError);
};

const isfeatureAssigned = (
  id:any,
  onSuccess: (data: any) => void,
  onError: (err: any) => void
) => {
  GetAPI.call(`${URL_CONSTANTS.check_is_feature_assigned}${id}`, onSuccess, onError);
};

const isexclusionAssigned = (
  id:any,
  onSuccess: (data: any) => void,
  onError: (err: any) => void
) => {
  GetAPI.call(`${URL_CONSTANTS.check_is_exclusion_assigned}${id}`, onSuccess, onError);
};

const deleteCatageory = (
  dto: groupHealthDeleteDTO,
  onSuccess: (data: any) => void,
  config: any,
  onError: (err: any) => void
) => {
  DeleteAPI.call(
    URL_CONSTANTS.delete_group_health_catageory,
    dto,
    onSuccess,
    {},
    onError
  );
};
const updateCatageory = (
  dto: groupHealthAddDTO,
  onSuccess: any,
  config: any,
  onError: any,
  id: string | undefined
) => {
  PatchAPI.call(
    `${URL_CONSTANTS.update_group_health_catageory}${id}`,
    dto,
    onSuccess,
    {},
    onError
  );
};
// exculsion api method
const getExculsion = (
  searchBarFilter:any,
  onSuccess: (data: any) => void,
  onError: (err: any) => void
) => {
  GetAPI.call(`${URL_CONSTANTS.get_group_health_exclusion}${searchBarFilter}`, onSuccess, onError);
};
const createExcuslion = (
  dto: groupHealthExculsionAddDTO[],
  onSuccess: any,
  config: any,
  onError: any
) => {
  PostAPI.call(
    URL_CONSTANTS.create_group_health_exclusion,
    dto,
    onSuccess,
    {},
    onError
  );
};
const updateExclusion = (
  api_url: string,
  dto: groupHealthExculsionAddDTO,
  onSuccess: any,
  config: any,
  onError: any,
  id: string | undefined
) => {
  PatchAPI.call(`${api_url}${id}`, dto, onSuccess, {}, onError);
};
const deleteExclusion = (
  dto: groupHealthDeleteDTO,
  onSuccess: (data: any) => void,
  config: any,
  onError: (err: any) => void
) => {
  DeleteAPI.call(
    URL_CONSTANTS.delete_group_health_exclusions,
    dto,
    onSuccess,
    {},
    onError
  );
};
// feature api method
const getFeature = (
  searchBarFilter:any,
  onSuccess: (data: any) => void,
  onError: (err: any) => void
) => {
  GetAPI.call(`${URL_CONSTANTS.get_group_health_feature}${searchBarFilter}`, onSuccess, onError);
};
const createFeature = (
  dto: groupHealthFeatureAddDTO[],
  onSuccess: any,
  config: any,
  onError: any
) => {
  PostAPI.call(
    URL_CONSTANTS.create_group_health_feature,
    dto,
    onSuccess,
    {},
    onError
  );
};
const deleteFeature = (
  dto: groupHealthDeleteDTO,
  onSuccess: (data: any) => void,
  config: any,
  onError: (err: any) => void
) => {
  DeleteAPI.call(
    URL_CONSTANTS.delete_group_health_feature,
    dto,
    onSuccess,
    {},
    onError
  );
};
const updateFeature = (
  dto: groupHealthFeatureAddDTO,
  onSuccess: any,
  config: any,
  onError: any,
  id: string | undefined
) => {
  PatchAPI.call(
    `${URL_CONSTANTS.update_group_health_feature}${id}`,
    dto,
    onSuccess,
    {},
    onError
  );
};
export const GROUP_HEALTH_SERVICES = {
  createCatageory,
  getCatageory,
  deleteCatageory,
  updateCatageory,
  getExculsion,
  createExcuslion,
  updateExclusion,
  deleteExclusion,
  getFeature,
  createFeature,
  deleteFeature,
  updateFeature,
  isfeatureAssigned,
  isexclusionAssigned,
};
