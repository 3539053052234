import V1 from "./CustomModuleV1/v1";
import V2 from "./CustomModuleV2/v2";
import { DndProvider } from "react-dnd";
import Backend from "react-dnd-html5-backend";
import './formBuilder.css';
import "./CustomModules.scss";


function CustomModule() {
  return (
    <div className="App">
      <DndProvider backend={Backend}>
        <V2 />
      </DndProvider>
    </div>
  );
}

export default CustomModule
