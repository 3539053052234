export enum PolicyCoverageType {
  FLAT = "FLAT",
  GRADED = "GRADED",
}


export enum GRADE_CRITERIA {
  DEPARTMENT = "Department",
  DESIGNATION = "Designation",
  SALARY_BRACKET = "Salary Bracket"

}
