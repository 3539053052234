import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ALL_ROUTES } from "../../../../Routes/all_routes";
import { useAppSelector } from "../../../../Store/hooks";
import "./NewSettings.scss";
// import OrganisationDetails from "./OrganisationSettings/OrganisationDetails/OrganisationDetails";

function Settings() {
  const navigate = useNavigate();
  const userDetails = useAppSelector((state) => {
    return state.userDetailsSlice;
  });

  return (
    <Box className="newSettingWrapper">
      <Grid container className="p-0">
        <Grid xs={12} spacing={3}>
          <h3 className="pageTitle mb-0 ">
            <Link
              onClick={() => {
                navigate(ALL_ROUTES.DASHBOARD);
              }}
            >
              <ChevronLeftIcon />
            </Link>
            Settings
          </h3>
        </Grid>
        <Grid
          xs={12}
          sx={{ maxWidth: "1600px", margin: "auto" }}
          className="innerSection"
        >
          <>
            <Grid container spacing={3} justifyContent={"center"}>
              <Grid lg={3} xs={6}>
                <div className="MenuBox">
                  <h4>General</h4>
                  <div className="menulistBox">
                    <span className="general"></span>
                    <ul className="menulist">
                      {/* <li>
                        <Link
                          underline="none"
                          className="listItem "
                          onClick={() => {
                            navigate(
                              `${ALL_ROUTES.SETTING_MODULE_FORM}?page=personalSettings&section=general`
                            );
                          }}>
                          Personal Settings
                        </Link>
                      </li> */}
                      {/* <li>
                        <Link
                          underline="none"
                          className="listItem "
                          onClick={() => {
                            navigate(
                              `${ALL_ROUTES.SETTING_MODULE_FORM}?page=emailSettings&section=general`
                            );
                          }}>
                          Email Settings
                        </Link>
                      </li> */}
                      <li>
                        <Link
                          underline="none"
                          className="listItem "
                          onClick={() => {
                            navigate(
                              `${ALL_ROUTES.SETTING_MODULE_FORM}?page=appearance&section=general`
                            );
                          }}
                        >
                          Appearance
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </Grid>
              <Grid lg={3} xs={6}>
                <div className="MenuBox">
                  <h4>Organisation</h4>
                  <div className="menulistBox">
                    <span className="organisation"></span>
                    <ul className="menulist">
                      <li>
                        <Link
                          underline="none"
                          className="listItem "
                          onClick={() => {
                            navigate(
                              `${ALL_ROUTES.SETTING_MODULE_FORM}?page=companyDetails&section=org`
                            );
                          }}
                        >
                          Company Details
                        </Link>
                      </li>
                      <li>
                        <Link
                          underline="none"
                          className="listItem "
                          onClick={() => {
                            navigate(
                              `${ALL_ROUTES.SETTING_MODULE_FORM}?page=branches&section=org`
                            );
                          }}
                        >
                          Branches/Locations
                        </Link>
                      </li>
                      <li>
                        <Link
                          underline="none"
                          className="listItem "
                          onClick={() => {
                            navigate(
                              `${ALL_ROUTES.SETTING_MODULE_FORM}?page=departments&section=org`
                            );
                          }}
                        >
                          Departments
                        </Link>
                      </li>
                      <li>
                        <Link
                          underline="none"
                          className="listItem "
                          onClick={() => {
                            navigate(
                              `${ALL_ROUTES.SETTING_MODULE_FORM}?page=designations&section=org`
                            );
                          }}
                        >
                          Designations
                        </Link>
                      </li>
                      <li>
                        <Link
                          underline="none"
                          className="listItem "
                          onClick={() => {
                            navigate(
                              `${ALL_ROUTES.SETTING_MODULE_FORM}?page=salary&section=org`
                            );
                          }}
                        >
                          Salary Brackets
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </Grid>
              {/* <Grid lg={3} xs={6}>
                <div className="MenuBox">
                  <h4>User And Controls</h4>
                  <div className="menulistBox">
                    <span className="userControl"></span>
                    <ul className="menulist">
                      <li>

                        <Link
                          underline="none"
                          className="listItem users"
                          onClick={() => {
                            navigate(
                              `${ALL_ROUTES.SETTING_MODULE_FORM}?page=users&section=userControl`
                            );
                          }}>
                          Users
                        </Link>

                      </li>
                      <li>

                        <Link
                          underline="none"
                          className="listItem access_control"
                          onClick={() => {
                            navigate(
                              `${ALL_ROUTES.SETTING_MODULE_FORM}?page=accessControl&section=userControl`
                            );
                          }}>
                          Access Control
                        </Link>

                      </li>
                    </ul>
                  </div>
                </div>
              </Grid> */}
              {/* <Grid lg={3} xs={6}>
                <div className="MenuBox">
                  <h4>Data Admin</h4>
                  <div className="menulistBox">
                    <span className="dataAdmin"></span>
                    <ul className="menulist">

                      <li>
                        <Link
                          underline="none"
                          className="listItem "
                          onClick={() => { }}>
                          Activity Log
                        </Link>
                      </li>
                      <li>
                        <Link
                          underline="none"
                          className="listItem "
                          onClick={() => { }}>
                          Audit Log
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </Grid>
              <Grid lg={3} xs={6}>
                <div className="MenuBox">
                  <div className="menuBoxHeading">
                    <h4>Developer Space</h4>
                  </div>
                  <div className="menulistBox">
                    <span className="developerSpace"></span>
                    <ul className="menulist">
                      <li>
                        <Link
                          underline="none"
                          className="listItem integartions"
                          onClick={() => { }}>
                          Integrations
                        </Link>
                      </li>
                      <li>
                        <Link
                          underline="none"
                          className="listItem webhook"
                          onClick={() => { }}>
                          API & Webhooks
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </Grid> */}
            </Grid>
          </>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Settings;
