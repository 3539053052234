import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Box, Button, Link, Modal } from "@mui/material";
import SelectDropdown from "../../../../Supporting files/SelectDropdown/SelectDropdown";
import RKTextField from "../../../../Supporting files/RKTextField/RKTextField";
import SlidingPanel from "react-sliding-side-panel";

interface Props {
  open_status: boolean;
  attrName: any;
  value_update: Function;
}

const ModifyFeature: React.FC<Props> = ({
  open_status,
  attrName,
  value_update,
}) => {
  const [selectCategory, setSelectCategory] = React.useState("");
  const [addFeatureName, setAddFeatureName] = React.useState("");
  const [addFieldType, setAddFieldType] = React.useState("");
  const [addValueType, setAddValueType] = React.useState("");
  const [featureDescription, setFeatureDescription] = React.useState("");

  const selectCategory_data = [
    { key: "Medical Cover", value: "Medical Cover" },
    { key: "Maternity Cover", value: "Maternity Cover" },
    { key: "Hospitalization Cover", value: "Hospitalization Cover" },
  ];
  const addFieldType_data = [
    { key: "Text Field", value: "Text Field" },
    { key: "Dropdown List", value: "Dropdown List" },
  ];
  const addValueType_data = [
    { key: "Numeric Only", value: "Numeric Only" },
    { key: "Alphanumeric", value: "Alphanumeric" },
    { key: "Text Only", value: "Text Only" },
  ];
  const updateMasterState = (attrName: any, value: any) => {
    attrName(value);
  };

  return (
    <SlidingPanel
      type={"right"}
      isOpen={open_status}
      size={75}
      backdropClicked={() => value_update(attrName, false)}>
      <div className={open_status === true ? "modalInner show" : "modalInner"}>
        <Grid container spacing={3} alignItems="center">
          <Grid xs={6}>
            <h4 className="mb-0">Modify Feature</h4>
          </Grid>
          <Grid xs={6} className="text-right">
            <Link className="redBtn delete" underline="none" onClick={() => {}}>
              Remove
            </Link>
          </Grid>
          <Grid xs={12}>
            <hr />
          </Grid>
        </Grid>
        <Grid container spacing={3} className="mt-0">
          <Grid xs={6}>
            <SelectDropdown
              class_name="inputField"
              title="Select Category"
              value={selectCategory}
              attrName={setSelectCategory}
              value_update={updateMasterState}
              dropdown_data={selectCategory_data}
            />
          </Grid>
          <Grid xs={6}>
            <RKTextField
              class_name="inputField"
              title={"Feature Name"}
              value={addFeatureName}
              attrName={setAddFeatureName}
              value_update={updateMasterState}
              warn_status={false}
            />
          </Grid>
          <Grid xs={6}>
            <SelectDropdown
              class_name="inputField"
              title="Field Type"
              value={addFieldType}
              attrName={setAddFieldType}
              value_update={updateMasterState}
              dropdown_data={addFieldType_data}
            />
          </Grid>
          <Grid xs={6}>
            <SelectDropdown
              class_name="inputField"
              title="Value Type"
              value={addValueType}
              attrName={setAddValueType}
              value_update={updateMasterState}
              dropdown_data={addValueType_data}
            />
          </Grid>
          <Grid xs={12}>
            <RKTextField
              class_name="inputField"
              title={"Description"}
              value={featureDescription}
              attrName={setFeatureDescription}
              value_update={updateMasterState}
              warn_status={false}
            />
          </Grid>
          <Grid xs={12} className="ctaBtn" sx={{ textAlign: "center" }}>
            <Button
              variant="contained"
              className="submitBtn"
              onClick={() => {}}>
              Update
            </Button>
          </Grid>
        </Grid>
      </div>
    </SlidingPanel>
  );
};

export default ModifyFeature;
