import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Box, Button, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import GlobalDeletePopup from "../../../../../../Components/Broker/GlobalDeletePopup/GlobalDeletePopup";
import {
  AddBasicInfoDTO,
  UpdateBasicInfoDTO,
} from "../../../../../../Services/DTO/Settings/Organisation/TaxationDTO";
import { ApiType } from "../../../../../../Services/Enum/ETaxation";
import { TAXATION_SERVICES } from "../../../../../../Services/Settings/OrganisationSettings/TaxationServices";
import { TaxRate } from "../../../../../../Services/Types/TaxRate";
import { useAppSelector } from "../../../../../../Store/hooks";
import DatePicker from "../../../../../../Supporting files/DatePicker/DatePicker";
import { add_date_format } from "../../../../../../Supporting files/HelpingFunction";
import RKTextField from "../../../../../../Supporting files/RKTextField/RKTextField";
import SelectDropdown from "../../../../../../Supporting files/SelectDropdown/SelectDropdown";
import AddNewTaxGroup from "./AddNewTaxGroup/AddNewTaxGroup";
import EditTaxPopup from "./EditTaxPopup/EditTaxPopup";
import NewTaxPopup from "./NewTaxPopup/NewTaxPopup";
import "./Taxation.scss";
import NewLoader from "../../../../../../Components/NewLoader/NewLoader";
const Taxation = () => {
  const [legalname, setLegalName] = useState("");
  const [tradename, setTradeName] = useState("");
  const [regDate, setRegDate] = useState<Date | null>(null);
  const [legalnameWarn, setLegalNameWarn] = useState<boolean>(false);
  const [tradenameWarn, setTradeNameWarn] = useState<boolean>(false);
  const [regDateWarn, setRegDateWarn] = useState<boolean>(false);
  const [taxPopup, setTaxPopup] = useState(false);
  const [edittaxPopup, setEditTaxPopup] = useState(false);
  const [newtaxgroup, setNewTaxGroup] = useState(false);
  const [deleteStatus, setDeleteStatus] = useState<boolean>(false);
  const [deleteStatusStep, setDeleteStatusStep] = useState<number>(1);
  const [taxRatesList, setTaxRatesList] = useState<TaxRate[]>([]);
  const [taxation_parent_id, setTaxationParentId] = useState<string>("");
  const [taxRateToEdit, setTaxRateToEdit] = useState({ _id: "" });
  const [basicInfoDisabled, setBasicInfoDisabled] = useState<boolean>(true);
  const [taxTypeOptions, setTaxTypeOptions] = useState<any>([
    { id: "Standard Tax", label: "Standard Tax" },
    { id: "Surcharge", label: "Surcharge" },
  ]);
  const [prioritystatusData, setprioritystatusData] = useState([]);
  const user_details = useAppSelector((state) => state.userDetailsSlice);
  const { BROKER } = useAppSelector((state) => state.permissionSlice);
  const [addPermission, setaddPermission] = useState<any>(null);
  const [editPermission, seteditPermission] = useState<any>(null);
  const [removePermission, setremovePermission] = useState<any>(null);
  const [statusPermission, setstatusPermission] = useState<any>(null);
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    getBasicInfo();
    getTaxRatesList();
    getTaxTypeOptions();
    get_status();
    setShowLoader(true);
    setaddPermission(BROKER?.Taxation?.add === undefined ? false : BROKER?.Taxation?.add);
    setremovePermission(BROKER?.Taxation?.remove === undefined ? false : BROKER?.Taxation?.remove);
    seteditPermission(BROKER?.Taxation?.edit === undefined ? false : BROKER?.Taxation?.edit);
    setstatusPermission(BROKER?.Taxation?.status === undefined ? false : BROKER?.Taxation?.status);
  }, []);

  useEffect(() => {
    if (!taxation_parent_id) {
      setBasicInfoDisabled(false);
    } else {
      setBasicInfoDisabled(true);
    }
  }, [taxation_parent_id]);

  const getTaxTypeOptions = (): void => {
    const onSuccess = (res: any) => {
      // setTaxTypeOptions(
      //   res.data.data.map((item: any) => {
      //     return {
      //       key: item.label,
      //       value: item.label,
      //     };
      //   })
      // );
    };

    const onError = (err: any) => {
      console.log(err);
    };

    TAXATION_SERVICES.get_tax_type_options(onSuccess, onError);
  };

  const getBasicInfo = (): void => {
    const onSuccess = (res: any) => {
      // setLegalName(res.data.data.businessName ?? "");
      // setTradeName(res.data.data.businessTradeName ?? "");
      // setRegDate(res.data.data.taxRegistrationDate ?? null);
    };

    const onError = (err: any) => {
      console.log(err);
    };

    TAXATION_SERVICES.get_basic_info(
      onSuccess,
      onError,
      ApiType.BasicInfo,
      user_details.organizationDetails.taxId
    );
  };

  const createBasicInfo = (): void => {
    const onSuccess = (res: any) => {
      getTaxRatesList();
      setBasicInfoDisabled(true);
    };

    const onError = (err: any) => {
      console.log(err);
    };
    const data: AddBasicInfoDTO = {
      APITYPE: ApiType.BasicInfo,
      taxId: user_details.organizationDetails.taxId,
      businessName: legalname,
      businessTradeName: tradename,
      taxRegistrationDate: regDate,
    };

    TAXATION_SERVICES.create_basic_info(onSuccess, onError, data);
  };

  const updateBasicInfo = (): void => {
    const onSuccess = (res: any) => {
      // getBasicInfo();
      getTaxRatesList();
      setBasicInfoDisabled(true);
    };
    const onError = (err: any) => {
      console.log("---err---", err);
    };
    const data: UpdateBasicInfoDTO = {
      APITYPE: ApiType.BasicInfo,
      businessName: legalname,
      businessTradeName: tradename,
      taxRegistrationDate: regDate,
    };

    let namewarn = !legalname || legalname === "" ? true : false;
    let tradewarn = !tradename || tradename === "" ? true : false;
    let regwarn = !regDate || regDate === null ? true : false;
    setLegalNameWarn(namewarn);
    setTradeNameWarn(tradewarn);
    setRegDateWarn(regwarn);

    if (namewarn === false && tradewarn === false && regwarn === false) {
      TAXATION_SERVICES.update_basic_info(
        onSuccess,
        onError,
        taxation_parent_id,
        data
      );
    }
  };

  const getTaxRatesList = (): void => {
    const onSuccess = (res: any) => {
      if (res) {
        setTimeout(() => {
          setShowLoader(false);
        }, 1500);
      }
      setTaxRatesList(res.data.data.taxList);
      setTaxationParentId(res.data.data._id);
      setLegalName(res.data.data.businessName ?? "");
      setTradeName(res.data.data.businessTradeName ?? "");
      setRegDate(res.data.data.taxRegistrationDate ?? null);
    };

    const onError = (err: any) => {
      console.log(err);
      setShowLoader(false);
    };

    TAXATION_SERVICES.get_tax_rates_list(onSuccess, onError);
  };

  const updateMasterState = (attrName: any, value: any) => {
    if (attrName === "deleteStatus") {
      setDeleteStatus(value);
    } else if (attrName === "delete_step") {
      setDeleteStatusStep(value);
    } else if (attrName[0] === "updatestatus") {
      // update_status(value, attrName[1]._id)
      isBeingUsed(attrName[1]._id, value);
    } else {
      attrName(value);
    }
  };

  //isBeingUsed somewhere
  const isBeingUsed = (id: string, value: any) => {
    const onSuccess = (res: any) => {
      if (res.data.data.is_exist) {
        toast.warning("This Tax is being used,Can't Inactive/Edit/Delete it. ");
      } else {
        if (value === "checkdelete") {
          setDeleteStatus(true);
        } else if (value === "checkedit") {
          setEditTaxPopup(true);
        } else {
          update_status(value, id);
        }
      }
    };
    const onError = (err: any) => {
      console.log("isBeingUsed", err);
    };
    TAXATION_SERVICES.isBeingUsed(onSuccess, onError, id);
  };

  const deleteTaxRate = (attrName?: any, step?: any) => {
    if (attrName === "data") {
      setDeleteStatusStep(step);
    }
    const onSuccess = (res: any) => {
      setDeleteStatusStep(2);
      getTaxRatesList();
    };

    const onError = (err: any) => {
      console.log("error", err);
    };

    TAXATION_SERVICES.delete_tax_rate(
      onSuccess,
      onError,
      taxation_parent_id,
      taxRateToEdit._id
    );
  };

  const get_status = () => {
    const onSuccess = (res: any) => {
      let statusData = res.data.data.map((data: any) => ({
        label: data.value,
        value: data.label,
      }));
      setprioritystatusData(statusData);
    };

    const onError = (err: any) => {
      console.log("err", err);
    };

    TAXATION_SERVICES.get_status(onSuccess, onError);
  };

  const update_status = (value: number, tax_id: string) => {
    const onSuccess = (res: any) => {
      getTaxRatesList();
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    TAXATION_SERVICES.update_status(
      {
        value: value,
      },
      onSuccess,
      onError,
      taxation_parent_id,
      tax_id
    );
  };
  return (
    <>
      {showLoader ? (
        <NewLoader />
      ) : (
        <Box className="taxation_wrapper">
          <NewTaxPopup
            open_status={taxPopup}
            attrName={setTaxPopup}
            value_update={updateMasterState}
            refetch_data={getTaxRatesList}
            parentId={taxation_parent_id}
            taxTypeOptions={taxTypeOptions}
          />

          <EditTaxPopup
            open_status={edittaxPopup}
            attrName={setEditTaxPopup}
            value_update={updateMasterState}
            refetch_data={getTaxRatesList}
            edit_obj={taxRateToEdit}
            parentId={taxation_parent_id}
            taxTypeOptions={taxTypeOptions}
          />

          <AddNewTaxGroup
            open_status={newtaxgroup}
            attrName={setNewTaxGroup}
            value_update={updateMasterState}
          />

          <Grid container spacing={2}>
            <Grid xs={8}>
              <h3>Tax Rates</h3>
            </Grid>

            <Grid xs={4} className="text-right">
              <div className="buttons">
                {addPermission && <Link
                  underline="none"
                  className="blueBtn addfile"
                  onClick={() => {
                    if (!taxation_parent_id) {
                      return toast.warn("Please update basic information before adding tax.");
                    }
                    setTaxPopup(true);
                  }}
                >
                  New Tax
                </Link>}

                {/* <Link
              underline="none"
              className="greenBtn addfile"
              onClick={() => {
                if (!taxation_parent_id) {
                  toast.warn(
                    "Please update basic information before adding tax group."
                  );
                  return;
                }
                setNewTaxGroup(true);
              }}>
              New Tax Group
            </Link> */}
              </div>
            </Grid>
          </Grid>

          <Box className="detailSection">
            <div className="sectionTitle">
              <h4>Basic Information</h4>
            </div>
            <div className="pl-4">
              <Grid container spacing={3}>
                <Grid xs={12}>
                  <p className="tax_id">
                    Tax ID:{" "}
                    <span>{user_details.organizationDetails.taxId}</span>
                  </p>
                </Grid>
              </Grid>
            </div>
            <div className="pl-4 pr-3">
              <Grid container spacing={3}>
                <Grid xs={6} md={4} lg={3}>
                  {basicInfoDisabled ? (
                    <div className="BasicInfoLabel">
                      <p>Business Legal Name</p>
                      <h6>{legalname}</h6>
                    </div>
                  ) : (
                    <RKTextField
                      class_name="inputField"
                      title={"Business Legal Name"}
                      value={legalname}
                      attrName={setLegalName}
                      value_update={updateMasterState}
                      warn_status={legalnameWarn}
                      error_messg="Enter Business Legal Name"
                    />
                  )}
                </Grid>
                <Grid xs={6} md={4} lg={3}>
                  {basicInfoDisabled ? (
                    <div className="BasicInfoLabel">
                      <p>Business Trade Name</p>
                      <h6>{tradename}</h6>
                    </div>
                  ) : (
                    <RKTextField
                      class_name="inputField"
                      title={"Business Trade Name"}
                      value={tradename}
                      attrName={setTradeName}
                      value_update={updateMasterState}
                      warn_status={tradenameWarn}
                      error_messg="Enter Business Trade Name"
                    />
                  )}
                </Grid>
                <Grid xs={6} md={4} lg={4}>
                  {basicInfoDisabled ? (
                    <div className="BasicInfoLabel">
                      <p>Tax ID Registration Date</p>
                      <h6>{add_date_format(regDate)}</h6>
                    </div>
                  ) : (
                    <DatePicker
                      class_name="inputField"
                      title="Tax ID Registration Date"
                      value={regDate}
                      attrName={setRegDate}
                      value_update={updateMasterState}
                      error_message="Enter Tax ID Registration Date"
                      warn_status={regDateWarn}
                      max_date={new Date()}
                    />
                  )}
                </Grid>
                <Grid xs={6} md={4} lg={2} className="ctaBtn">
                  {taxation_parent_id ? (
                    <>
                      {editPermission && <Button
                        onClick={
                          basicInfoDisabled
                            ? () => {
                              setBasicInfoDisabled(false);
                            }
                            : updateBasicInfo
                        }
                        variant="contained"
                        className="submitBtn"
                      >
                        {basicInfoDisabled ? "Edit" : "Update"}
                      </Button>}
                    </>
                  ) : (
                    <Button
                      onClick={createBasicInfo}
                      variant="contained"
                      className="submitBtn"
                    >
                      Save
                    </Button>
                  )}
                </Grid>
              </Grid>
            </div>
          </Box>

          <Grid container spacing={2}>
            <Grid xs={12} className="fieldsBox">
              <ul className="dataHeading">
                <li>Tax Name</li>
                <li>Rate (%)</li>
                <li>Tax Type</li>
                <li>Status</li>
                <li>Actions</li>
              </ul>
              {taxRatesList.map((data, index) => (
                <ul key={data._id} className="dataLists">
                  <li>{data.taxName}</li>
                  <li>{data.taxRate}</li>
                  <li>{data.taxType}</li>
                  <li>
                    <div id={`_${data.status}`}>
                      <div className="status_dropdown" id={`_${data.status}`}>
                        {data.status === 11 ? (
                          <CheckCircleIcon id={`_${data.status.toString()}`} />
                        ) : data.status === 12 ? (
                          <HighlightOffIcon id={`_${data.status.toString()}`} />
                        ) : null}
                        <SelectDropdown
                          class_name="inputFieldd"
                          title=""
                          value={data.status}
                          attrName={["updatestatus", data, index]}
                          value_update={updateMasterState}
                          dropdown_data={prioritystatusData}
                          warn_status={false}
                          disabled={!statusPermission}
                        />
                      </div>
                    </div>
                  </li>
                  <li>
                    {removePermission && <Link
                      sx={{
                        textDecoration: "none",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setTaxRateToEdit(data);
                        isBeingUsed(data._id, "checkdelete");
                      }}
                    >
                      <img src="images/delete_icon.svg" alt="" />
                    </Link>}
                    {editPermission && <Link
                      className="ml-3"
                      sx={{
                        textDecoration: "none",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setTaxRateToEdit(data);
                        isBeingUsed(data._id, "checkedit");
                      }}
                    >
                      <img src="images/edit_icon.svg" alt="" />
                    </Link>}
                  </li>
                </ul>
              ))}
            </Grid>
          </Grid>

          <GlobalDeletePopup
            open_status={deleteStatus}
            value_update={updateMasterState}
            attrName={setDeleteStatus}
            delete_step={deleteStatusStep}
            ids={[""]}
            delete_item={() => {
              deleteTaxRate("data", 2);
            }}
            title="tax rate"
          ></GlobalDeletePopup>
        </Box>
      )}
    </>
  );
};

export default Taxation;
