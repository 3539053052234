//used
import Grid from "@mui/material/Unstable_Grid2";
import "./RepairVehicle.scss";

function RepairVehicle() {
  return (
    <Grid xs className="repairVehicle_wrapper">
      <Grid container spacing={3}>
        <Grid xs={8}>
          <div className="main_heading mb-10">
            <h5>
              Repair <span>Vehicle</span>
            </h5>
            <p>Mechanical services for your vehicle</p>
          </div>

          <div className="repair_list">
            <ul className="inner_repairlist">
              <li>
                <div className="inner_content"></div>
              </li>
            </ul>
          </div>
        </Grid>
        <Grid xs={4}>kghsfhjfn</Grid>
      </Grid>
    </Grid>
  );
}

export default RepairVehicle;
