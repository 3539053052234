import { useCallback, useMemo, useRef } from "react";
import { TUserData } from "../Services/Types/TUserData";
import { RootState } from "../Store/Store";
import { useAppSelector } from "../Store/hooks";
import { formatCurrencyAccToUser, getCurrencyCodeRegex } from "./HelpingFunction";

export function useFormatCurrency() {
  const userState: TUserData = useAppSelector((state: RootState) => state.userDetailsSlice);

  return {
    formatCurrency: (value: any, decimal?: any) => {
      if (value === "") {
        return ""
      }
      return formatCurrencyAccToUser(
        value,
        getCurrencyCodeRegex(userState.localInformation.currency),
        userState.localInformation.countryCurrencyCode,
        decimal ?? 0
      );
    },
  };
}



export function useOrigin() {
  return useMemo(() => window.location.origin, []);
}

