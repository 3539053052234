import { Modal } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { Box } from "@mui/system";
import React, { useCallback, useEffect } from "react";
import "./KeyboardShotcuts.scss";
import Session from "../../Session/Session";
import { ALL_ROUTES } from "../../Routes/all_routes";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../Store/hooks";

interface Props {
  openStatus: boolean;
  attrName: string;
  valueUpdate: Function;
}

const KeyboardShotcuts = ({
  openStatus,
  attrName,
  valueUpdate,
}: {
  openStatus: boolean;
  attrName: string;
  valueUpdate: Function;
}) => {
  const userType = useAppSelector((state) => state.userDetailsSlice.userType);
  var shortcut_data = [
    {
      title: "Go to Home",
      value: "D",
    },
    {
      title: "Policies",
      value: "P",
    },
    {
      title: userType === "EMPLOYER" ? "" : "Quotes",
      value: userType === "EMPLOYER" ? "" : "Q",
    },
    {
      title: "Policy Renewal",
      value: "R",
    },
    {
      title: userType === "EMPLOYER" ? "" : "Clients",
      value: userType === "EMPLOYER" ? "" : "C",
    },
    {
      title: userType === "EMPLOYER" ? "" : "Contacts",
      value: userType === "EMPLOYER" ? "" : "N",
    },
    {
      title: "Employees",
      value: "E",
    },
    {
      title: userType === "EMPLOYER" ? "" : "Product Configurator",
      value: userType === "EMPLOYER" ? "" : "F",
    },
    {
      title: "Endorsement",
      value: "T",
    },
    {
      title: "Claim",
      value: "L",
    },
    {
      title: "Trending Questions",
      value: "G",
    },
    {
      title: "Community",
      value: "M",
    },
    {
      title: "Report",
      value: "E",
    },
    {
      title: "Support",
      value: "O",
    },
    {
      title: "Setting",
      value: "I",
    },
    {
      title: userType === "EMPLOYER" ? "" : "Add new Quote",
      value: userType === "EMPLOYER" ? "" : "Shift + Q",
    },
    {
      title: userType === "EMPLOYER" ? "" : "Add new Policy",
      value: userType === "EMPLOYER" ? "" : "Shift + P",
    },
    {
      title: userType === "EMPLOYER" ? "" : "Add new Client",
      value: userType === "EMPLOYER" ? "" : "Shift + C",
    },
    {
      title: userType === "EMPLOYER" ? "" : "Add new Contact",
      value: userType === "EMPLOYER" ? "" : "Shift + N",
    },
    {
      title: "Add new Employee",
      value: "Shift + E",
    },
    {
      title: "Post a Query",
      value: "Shift + G",
    },
    {
      title: "Post a Question",
      value: "Shift + M",
    },
    {
      title: userType === "EMPLOYER" ? "" : "Add Quote",
      value: userType === "EMPLOYER" ? "" : "Shift + Q",
    },
    {
      title: "Endorsement Request",
      value: "Shift + T",
    },
    {
      title: "New Claim Request",
      value: "Shift + L",
    },
  ];
  const navigate = useNavigate();

  useEffect(() => {
    // attach the event listener
    window.addEventListener("keydown", handleKeyPress);
    if (
      Session.getTokken().toString().length === 0 &&
      Session.getUserType() != "broker"
    ) {
      //navigate("/");
      const windowURL = window.location.search;
      const params = new URLSearchParams(windowURL);
      let page = params.get("page");
      if (page !== null) {
        // setActivePage(page ?? "");
      }
    }

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  const handleKeyPress = useCallback((e: any) => {
    var event = e;
    var key = event.keyCode;
    var shift = event.shiftKey;
    if (userType === "EMPLOYER") {
      if (key === 67 || key === 70 || key === 81 || key === 78 || (shift && key === 80)) {
        return
      }
    }

    if (
      event.target.tagName !== "INPUT" &&
      event.target.tagName !== "TEXTAREA"
    ) {
      if (shift) {
        switch (key) {
          case 83:
            //s
            valueUpdate(attrName, true);
            break;
          case 81:
            //Q
            navigate(ALL_ROUTES.ADD_NEW_QUOTES);
            break;
          case 80:
            //P
            navigate(ALL_ROUTES.ADD_NEW_POLICY);
            break;
          case 67:
            //C
            navigate(ALL_ROUTES.ADD_NEW_CLIENT);
            break;
          case 78:
            //N
            navigate(ALL_ROUTES.ADD_NEW_CONTACT);
            break;
          case 69:
            //E
            navigate(ALL_ROUTES.ADD_EMPLOYEE);
            break;
          case 84:
            //T
            // navigate(ALL_ROUTES.que);
            break;
          case 22:
            //L
            navigate(ALL_ROUTES.CLIAM_ASSISTANT);
            break;
          case 76:
            //G
            // setActivePage("taskmanagement");
            // setTaskManagement(true);
            break;
          // case 71:
          //   //G
          //   navigate(ALL_ROUTES.TRENDING_QUESTION);
          //   break;
          // case 77:
          //   //M
          //   navigate(ALL_ROUTES.COMMUNITY);
          //   break;
          default:
            break;
        }
      } else {
        switch (key) {
          case 68:
            //D
            navigate(ALL_ROUTES.DASHBOARD);
            break;
          case 81:
            //Q
            navigate(ALL_ROUTES.QUOTES);
            break;
          case 80:
            //P
            navigate(ALL_ROUTES.POLICIES);
            break;
          // case 82:
          //   //R
          //   navigate(ALL_ROUTES.POLICY_RENEWAL);
          //   break;
          case 67:
            //C
            navigate(ALL_ROUTES.CLIENTS);
            break;
          case 78:
            //N
            navigate(ALL_ROUTES.CONTACTS);
            break;
          // case 69:
          //   //E
          //   navigate(ALL_ROUTES.REPORT);
          //   break;
          case 70:
            //F
            navigate(ALL_ROUTES.PRODUCT_CONFIG_GROUP_HEALTH);
            break;
          case 84:
            //T
            navigate(ALL_ROUTES.ENDORSEMENT);
            // setRaiseNewRequest(false);
            break;
          case 76:
            //L
            navigate(ALL_ROUTES.CLIAM_ASSISTANT);
            // setIntiateClaim(false);
            break;
          // case 77:
          //   //M
          //   navigate(ALL_ROUTES.COMMUNITY);
          //   break;
          case 79:
            //O
            navigate(ALL_ROUTES.SUPPORT);
            break;
          case 83:
            //S
            // setSearchopen(true);
            break;
          case 85:
            //U
            navigate(ALL_ROUTES.SUPPORT);
            // setActivePage("support");
            break;
          // case 71:
          //   //G
          //   navigate(ALL_ROUTES.TRENDING_QUESTION);
          //   // setPostQuery(false);
          //   break;
          case 73:
            //I
            navigate(ALL_ROUTES.SETTING);
            break;
          default:
            break;
        }
      }
    }
  }, []);

  return (
    <Modal
      open={openStatus}
      onClose={() => {
        valueUpdate(attrName, false);
      }}
      className="keyboardWrapper">
      <Box className="keyboardShortcuts">
        <Grid container spacing={3}>
          <Grid xs={6}>
            <h4>Keyboard Shortcuts</h4>
          </Grid>
          <Grid xs={6}></Grid>
        </Grid>
        <Grid container spacing={0} className="shortcutKeys">
          {shortcut_data.map((data, index) => {
            if (data.title === "" || data.value === "") return
            return (
              <>
                <Grid xs={4}>
                  <p>
                    {data.title} <kbd>{data.value}</kbd>
                  </p>
                </Grid>
              </>
            )
          }
          )}
          {/* <Grid xs={6}>
                <p>Policies <kbd>P</kbd></p>
                <p>Transactions <kbd>T</kbd></p>
                <p>Renewals <kbd>R</kbd></p>
                <p>Customers <kbd>C</kbd></p>
            </Grid>
            <Grid xs={6}>
                <p>Search <kbd>ctrl Space</kbd></p>
                <p>Referesh <kbd>alt Space</kbd></p>
                <p>Add New Policy <kbd>alt N</kbd></p>
                <p>Car Dahboard <kbd>C</kbd></p>
                <p>Bike Dahboard <kbd>B</kbd></p>
                <p>Health Dahboard <kbd>H</kbd></p>
            </Grid> */}
        </Grid>
      </Box>
    </Modal>
  );
};

export default KeyboardShotcuts;
