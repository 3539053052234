import { Box, Grid } from "@mui/material";
import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { EMPLOYER_ALL_ROUTES } from "../../../../src/Routes/employer_all_routes";
import TabList from "../../Broker/Home/TabList/Tablist";
import "./SideNavBar.scss";

function SideNavBar() {
  const location = useLocation();

  return (
    <Box className="menus">
      {/* Dashboard Tab */}
      <TabList
        className={
          location.pathname === EMPLOYER_ALL_ROUTES.DASHBOARD
            ? "dashboard active"
            : "dashboard"
        }
        buttonText={
          <p>
            <span>D</span>ashboard
          </p>
        }
        path={EMPLOYER_ALL_ROUTES.DASHBOARD}
      />

      {/* Policies Tab */}
      <TabList
        className={
          location.pathname === EMPLOYER_ALL_ROUTES.POLICIES
            ? "policies active"
            : "policies"
        }
        buttonText={
          <p>
            <span>P</span>olicies
          </p>
        }
        path={EMPLOYER_ALL_ROUTES.POLICIES}
      />
      {/* Employee Tab */}
      <TabList
        className={
          location.pathname === EMPLOYER_ALL_ROUTES.EMPLOYEE
            ? "employe active"
            : "employe"
        }
        buttonText={
          <p>
            <span>M</span>ember
          </p>
        }
        path={EMPLOYER_ALL_ROUTES.EMPLOYEE}
      />


      {/* Report Tab */}
      {/* <TabList
          className={
            location.pathname === EMPLOYER_ALL_ROUTES.REPORT
              ? "report active"
              : "report"
          }
          buttonText={
            <p>
              <span>R</span>eport
            </p>
          }
          path={EMPLOYER_ALL_ROUTES.REPORT}
        /> */}

      {/* Claim Assistant Tab */}
      <TabList
        className={
          location.pathname === EMPLOYER_ALL_ROUTES.CLIAM_ASSISTANT
            ? "claimassistant active"
            : "claimassistant"
        }
        buttonText={
          <p>
            C<span>l</span>aim
          </p>
        }
        path={EMPLOYER_ALL_ROUTES.CLIAM_ASSISTANT}
      />

      {/* Endorsement Tab */}
      <TabList
        className={
          location.pathname === EMPLOYER_ALL_ROUTES.ENDORSEMENT
            ? "endorsement active"
            : "endorsement"
        }
        buttonText={
          <p>
            Endorsemen<span>t</span>
          </p>
        }
        path={EMPLOYER_ALL_ROUTES.ENDORSEMENT}
      />

      {/* Trending Questions Tab */}
      {/* <TabList
          className={
            location.pathname === EMPLOYER_ALL_ROUTES.TRENDING_QUESTION
              ? "trendingquestions active"
              : "trendingquestions"
          }
          buttonText={
            <p>
              Trendin<span>g</span> Questions
            </p>
          }
          path={EMPLOYER_ALL_ROUTES.TRENDING_QUESTION}
        /> */}

      {/* Community Tab */}
      {/* <TabList
          className={
            location.pathname === EMPLOYER_ALL_ROUTES.COMMUNITY
              ? "community active"
              : "community"
          }
          buttonText={
            <p>
              Co<span>m</span>munity
            </p>
          }
          path={EMPLOYER_ALL_ROUTES.COMMUNITY}
        /> */}

      {/* Support Tab */}
      <TabList
        className={
          location.pathname === EMPLOYER_ALL_ROUTES.SUPPORT
            ? "support active"
            : "support"
        }
        buttonText={
          <p>
            S<span>u</span>pport
          </p>
        }
        path={EMPLOYER_ALL_ROUTES.SUPPORT}
      />

      {/* Settings Tab */}
      {/* <TabList
          className={
            location.pathname === EMPLOYER_ALL_ROUTES.SETTING
              ? "settings active"
              : "settings"
          }
          buttonText={
            <p>
              <span>S</span>etting
            </p>
          }
          path={EMPLOYER_ALL_ROUTES.SETTING}
        /> */}
    </Box>
  );
}

export default SideNavBar;
