//used
import { Box, Grid } from "@mui/material";

function CommingSoon() {
  return (
    <Box className="settingMenuWrapper">
      <Grid container spacing={2}>
        <Grid xs={12} className="text-center pt-19">
          <img
            src="./images/coming_soon.svg"
            className="mb-5"
            height="260px"
            alt="coming soon"
          />
          <div className="comingsoon">
            <h1>
              <span></span>
            </h1>
            {/* <div className="load"></div> */}
          </div>
          <h5>We are working on it.</h5>
        </Grid>
      </Grid>
    </Box>
  );
}

export default CommingSoon;
