//not used
import React, { useState } from "react";
import "../ProductConfig.scss";
import Grid from "@mui/material/Unstable_Grid2";
import { Box, Link } from "@mui/material";
import { useNavigate } from "react-router-dom";
import RemovePopup from "../../../../../Components/Common/CarForm/RemovePopup/RemovePopup";
import { ALL_ROUTES } from "../../../../../Routes/all_routes";

function VoulntaryPlans() {
  const [productTabsStatus, setProductTabsStatus] =
    React.useState("Categories");
  const [hideDataStatus, setHideDataStatus] = React.useState(false);
  const [removeCategory, setRemoveCategory] = React.useState(false);
  const [addVoluntaryPlan, setAddVoluntaryPlan] = React.useState(false);
  //Features Variables
  const [addNewFeatures, setAddNewFeatures] = React.useState(false);
  const [addNewCategory, setAddNewCategory] = React.useState(false);
  const navigate = useNavigate();
  //Category Data
  const [columns, setColumns] = React.useState([
    {
      field: "sr_no",
      headerName: "Sr. No.",
      width: 80,
      check_status: true,
      renderCell: (index: any) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: "category_name",
      headerName: "Category Name",
      minWidth: 260,
      check_status: true,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 3,
      minWidth: 150,
      check_status: true,
    },
    {
      field: "action_btns",
      headerName: "Action",
      width: 150,
      check_status: true,
      renderCell: (ValueFormatterParams: any) => {
        const { row } = ValueFormatterParams;
        return (
          <Link
            className="blueBtn edit ml-0"
            underline="none"
            onClick={() => {
              setAddNewCategory(true);
            }}
          >
            Edit
          </Link>
        );
      },
    },
  ]);
  const voluntry_table_data = [
    {
      productname: "Group Personal Accident",
      description:
        "This policy provides comprehensive coverage upto 100% of Sum Insured against Accidental Death, Permanent Total Disability (PTD), Permanent Partial Disability (PPD) and upto 1% of Actual Salary incase of Temporary Total Disability (TTD)....more",
    },
    {
      productname: "Group Personal Accident",
      description:
        "This policy provides comprehensive coverage upto 100% of Sum Insured against Accidental Death, Permanent Total Disability (PTD), Permanent Partial Disability (PPD) and upto 1% of Actual Salary incase of Temporary Total Disability (TTD)....more",
    },
    {
      productname: "Group Personal Accident",
      description:
        "This policy provides comprehensive coverage upto 100% of Sum Insured against Accidental Death, Permanent Total Disability (PTD), Permanent Partial Disability (PPD) and upto 1% of Actual Salary incase of Temporary Total Disability (TTD)....more",
    },
  ];
  const updateMasterState = (attrName: any, value: any) => {
    attrName(value);

    if (attrName === setAddNewFeatures) {
      setHideDataStatus(value);
    }
  };

  const [checked, setChecked] = useState(false);
  const handleClick = () => {
    setChecked(!checked);
  };

  return (
    <Grid container spacing={3}>
      <Grid xs={8}>
        <h3 className="mb-0">Voulntary Plans</h3>
      </Grid>
      <Grid xs={4} className="text-right">
        <Link
          className="redBtn delete"
          underline="none"
          onClick={() => {
            setRemoveCategory(true);
          }}
        >
          Delete
        </Link>
        <Link
          className="greenBtn addfile"
          underline="none"
          onClick={() => {
            navigate(ALL_ROUTES.ADD_VOLUNTRY_PLAN);
          }}
        >
          Add New
        </Link>
      </Grid>
      <Grid xs={12}>
        <Box className="table">
          <div className="fieldsBox">
            <div className="voluntry_table">
              <ul className="tableHeading">
                <li>
                  <div className="Checkbox" onClick={handleClick}>
                    <input type="checkbox" name="check" />
                    <label className="employee"></label>
                  </div>
                  Product Name
                  <button className="sorting-btn">
                    {/* <img src="./images/sorting_btn.svg" alt="" /> */}
                  </button>
                </li>
                <li>
                  Description
                  <button className="sorting-btn">
                    {/* <img src="./images/sorting_btn.svg" alt="" /> */}
                  </button>
                </li>
                <li>
                  Actions
                  <button className="sorting-btn last">
                    {/* <img src="./images/sorting_btn.svg" alt="" /> */}
                  </button>
                </li>
              </ul>
              {voluntry_table_data.map((data, index) => (
                <ul className="tablecontent">
                  <li>
                    <div className="Checkbox">
                      {checked === true ? (
                        <input type="checkbox" name="check" checked={checked} />
                      ) : (
                        <input type="checkbox" name="check" />
                      )}
                      <label className="employee"></label>
                    </div>
                    <Link
                      className="productname"
                      onClick={() => {
                        navigate(ALL_ROUTES.VOLUNTRY_DETAILS);
                      }}
                    >
                      {data.productname}
                    </Link>
                  </li>
                  <li>{data.description}</li>
                  <li>
                    <div className="actionBtns">
                      <Link
                        underline="none"
                        className="blueBtn edit "
                        onClick={() => {}}
                      >
                        Edit
                      </Link>
                    </div>
                  </li>
                </ul>
              ))}
            </div>
          </div>
        </Box>
      </Grid>

      <RemovePopup
        open_status={removeCategory}
        attrName={setRemoveCategory}
        value_update={updateMasterState}
      />
    </Grid>
  );
}

export default VoulntaryPlans;
