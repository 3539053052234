import Container from "@mui/material/Container";
import LinearProgress from "@mui/material/LinearProgress";
import { useNProgress } from "@tanem/react-nprogress";
import React from "react";

const Progress: React.FC<{ isAnimating: boolean }> = ({ isAnimating }) => {
  const { animationDuration, isFinished, progress } = useNProgress({
    isAnimating,
  });

  return (
    <Container
      maxWidth={false}
      sx={{
        top: 0,
        position: "absolute",
        pointerEvents: "none",
        transition: `opacity 200ms linear`,
        width: "200%",
        zIndex: 9999,
        ...(isFinished ? { opacity: 0 } : { opacity: 1 }),
      }}
      disableGutters={true}
    >
      <LinearProgress
        sx={{
          transitionDuration: `200ms`,
        }}
        value={progress * 100}
        variant="determinate"
      />
    </Container>
  );
};

export default Progress;
