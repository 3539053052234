import { Box, Button, Grid, Link, Modal, Menu, MenuItem } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import UGTable from "../../../../Supporting files/UGTable/UGTable";
// import AddNewPolicy from "../../../../Components/Broker/Policies/AddNewPolicy/AddNewPolicy";
import BookmarkAddIcon from "@mui/icons-material/BookmarkAdd";
import CancelIcon from "@mui/icons-material/Cancel";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import VerifiedIcon from "@mui/icons-material/Verified";
import BulkUploadForm from "../../../../Components/Common/BulkUploadFormSP/BulkUploadForm";
import { ALL_ROUTES } from "../../../../Routes/all_routes";
import { E_BE_FILTER_CODE } from "../../../../Services/Enum/E_UGTable";
import { QUOTES_SERVICES } from "../../../../Services/Quotes/QuotesServices";
import { TUserData } from "../../../../Services/Types/TUserData";
import { RootState } from "../../../../Store/Store";
import { useAppSelector } from "../../../../Store/hooks";
import { useFormatCurrency } from "../../../../Supporting files/CustomHooks";
import RKTextField from "../../../../Supporting files/RKTextField/RKTextField";
import SearchBox from "../../../../Supporting files/SearchBarAnimation/SearchBox";
import SelectDropdown from "../../../../Supporting files/SelectDropdown/SelectDropdown";
import UGDateRangePicker from "../../../../Supporting files/UGDateRangePicker/UGDateRangePicker";
import "./../../../../Statuses.scss";
import NewLoader from "../../../../Components/NewLoader/NewLoader";
import {
  date_time_format,
  formatSumInsured,
} from "../../../../Supporting files/HelpingFunction";
import { CLIENT_SERVICES } from "../../../../Services/Clients/ClientService";

const Quotes = () => {
  const userState: TUserData = useAppSelector(
    (state: RootState) => state.userDetailsSlice
  );
  const user_type_dateFormat = useAppSelector(
    (state) => state.userDetailsSlice.localInformation.dateFormat
  );
  const tz = useAppSelector(
    (state: RootState) => state.userDetailsSlice.localInformation.timeZone
  );
  const { formatCurrency } = useFormatCurrency();
  const [columns, setColumns] = useState<any>([]);
  const [addCdBalance, setAddCdBalance] = useState(false);
  const [uploadFile, setUploadFile] = useState(false);
  const [addNewEmployeeStep, setAddNewEmployeeStep] = useState(1);
  const [cdAmount, setCdAmount] = useState("");
  const [policyData, setPolicyData] = useState([]);
  const [editStatus, setEditStatus] = useState(false);
  const [editStatusStep, setEditStatusStep] = useState(1);
  const [array_delete, setArray_Delete] = useState<Array<{ id: number }>>([]);
  const [startEndDate, setStartEndDate] = useState<any>({
    startDate: "",
    endDate: "",
  });
  const [searchBarFilter, setsearchBarFilter] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [tableLimit, setTableLimit] = useState();
  const [tableSkip, setTableSkip] = useState();
  const [prioritystatusData, setprioritystatusData] = useState([]);
  const navigate = useNavigate();
  const { dateFormat, timeZone } = useAppSelector(
    (state: any) => state.userDetailsSlice.localInformation
  );
  const [showLoader, setShowLoader] = useState(true);
  const Quotes_permission = useAppSelector(
    (state) => state.permissionSlice.BROKER?.Quotes
  );
  const [addQuotesPermission, setAddQuotesPermission] = useState<any>(null);
  const [editQuotesPermission, setEditQuotesPermission] = useState<any>(null);
  const [statusQuotesPermission, setStatusQuotesPermission] = useState<any>(null);
  const [csvQuotesPermission, setCsvQuotesPermission] = useState<any>(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDownloadAll = () => {
    get_quotes(totalRecords, 0, "csv");
    handleClose();
  };

  const handleDownloadSelected = () => {
    get_quotes(tableLimit ?? 25, 0, "csv");

    handleClose();
  };
  let timeout: any;

  useEffect(() => {
    setAddQuotesPermission(
      Quotes_permission?.add === undefined ? false : Quotes_permission?.add
    );
    setEditQuotesPermission(
      Quotes_permission?.edit === undefined ? false : Quotes_permission?.edit
    );
    setCsvQuotesPermission(
      Quotes_permission?.csv === undefined ? false : Quotes_permission?.csv
    );
    setStatusQuotesPermission(
      Quotes_permission?.status === undefined ? false : Quotes_permission?.status
    );
    setShowLoader(true);

    if (timeout) {
      clearTimeout(timeout);
    }
    const delayedApiCall = () => {
    get_quotes();
    get_status(Quotes_permission?.status === undefined ? false : Quotes_permission?.status);
      
    };
    timeout = setTimeout(delayedApiCall, 900);

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, []);

  let timeoutId: any;
  useEffect(() => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    const delayedApiCall = () => {
      if(searchBarFilter ||startEndDate.endDate){
        get_quotes();
      }
    };
    timeoutId = setTimeout(delayedApiCall, 500);
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [searchBarFilter,startEndDate]);

  // useEffect(() => {
  //   get_quotes();
  // }, [startEndDate]);

  const edit_navigate = () => {
    if (array_delete.length === 0) {
      setEditStatus(true);
    } else if (array_delete.length > 1) {
      setEditStatus(true);
    } else {
      navigate(`${ALL_ROUTES.EDIT_QUOTES}?id=${array_delete[0]}`);
    }
  };

  const get_quotes = (limit?: number, skip?: number, csv?: any) => {
    const onSuccess = (res: any) => {
      if (res) {
        setTimeout(() => {
          setShowLoader(false);
        }, 1500);
      }
      const data = res?.data?.data?.data?.map((e: any) => ({
        ...e,
        id: e._id,
      }));
      setPolicyData(data);
      setTotalRecords(res?.data?.data?.countData);
      if (csv) {
        downloadCsv(data);
      }
    };
    const onError = (err: any) => {
      console.log("error", err);
    };
    QUOTES_SERVICES.get_quotes_list_view(
      onSuccess,
      onError,
      limit ? limit : 25,
      skip ? skip : 0,
      startEndDate.startDate ? startEndDate.startDate : "",
      startEndDate.endDate ? startEndDate.endDate : "",
      searchBarFilter ? searchBarFilter : ""
    );
  };

  const get_status = (permissionStatus:any) => {

    const onSuccess = (res: any) => {
      let statusData = res.data.data.map((data: any) => ({
        label: data.value,
        value: data.label,
      }));

      setprioritystatusData(statusData);
      setColumns([
        {
          field: "quoteId",
          headerName: "Quotes ID",
          width: 170,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return (
              <Link
                underline="none"
                style={{
                  cursor: "pointer",
                  fontWeight: "400",
                  color: "#6b62dc",
                  textDecoration: "none",
                }}
                onClick={() => {
                  navigate(`${ALL_ROUTES.QUOTE_DETAIL}?id=${row._id}`);
                }}
              >
                {row["quoteId"]}
              </Link>
            );
          },
        },
        {
          field: "companyName",
          headerName: "Company Name",
          width: 220,
          check_status: true,
        },
        {
          field: "contactNumber",
          headerName: "Contact Number",
          width: 150,
          check_status: true,
        },
        {
          field: "email",
          headerName: "Email",
          width: 200,
          check_status: true,
        },
        {
          field: "productName",
          headerName: "Product Name",
          width: 180,
          check_status: true,
        },
        {
          field: "website",
          headerName: "Website",
          width: 180,
          check_status: true,
        },
        // {
        //   field: "policyStartDate",
        //   headerName: "Policy Start Date",
        //   width: 130,
        //   check_status: true,
        //   renderCell: (ValueFormatterParams: any) => {
        //     const { row } = ValueFormatterParams;
        //     return (
        //       <span>
        //         {date_time_format(
        //           row.policyStartDate,
        //           userState.localInformation.dateFormat,
        //           userState.localInformation.timeZone
        //         )}
        //       </span>
        //     );
        //   },
        // },
        // {
        //   field: "policyEndDate",
        //   headerName: "Policy End Date",
        //   width: 130,
        //   check_status: true,
        //   renderCell: (ValueFormatterParams: any) => {
        //     const { row } = ValueFormatterParams;
        //     return (
        //       <span>
        //         {date_time_format(
        //           row.policyEndDate,
        //           userState.localInformation.dateFormat,
        //            userState.localInformation.timeZone
        //         )}
        //       </span>
        //     );
        //   },
        // },
        {
          field: "status",
          headerName: "Status",
          minWidth: 200,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row, index } = ValueFormatterParams;
            return (
              <div id={`_${row.status}`}>
                <div className="status_dropdown" id={`_${row.status}`}>
                  {row.status === 1 ? (
                    <BookmarkAddIcon id={`_${row.status.toString()}`} />
                  ) : row.status === 2 ? (
                    <MarkEmailReadIcon id={`_${row.status.toString()}`} />
                  ) : row.status === 3 ? (
                    <MarkEmailReadIcon id={`_${row.status.toString()}`} />
                  ) : row.status === 4 ? (
                    <VerifiedIcon id={`_${row.status.toString()}`} />
                  ) : row.status === 5 ? (
                    <CancelIcon id={`_${row.status.toString()}`} />
                  ) : row.status === 6 ? (
                    <PublishedWithChangesIcon
                      id={`_${row.status.toString()}`}
                    />
                  ) : null}
                  <SelectDropdown
                    class_name="inputFieldd"
                    title=""
                    value={row.status}
                    attrName={["updatestatus", row, index]}
                    value_update={updateMasterState}
                    dropdown_data={statusData}
                    warn_status={false}
                    disabled={!permissionStatus}
                  />
                </div>
              </div>
            );
          },
        },

        {
          field: "liveCounts",
          headerName: "No. of Dependants",
          width: 100,
          check_status: true,
        },
        {
          field: "employeeCount",
          headerName: "No. of Employees",
          width: 150,
          check_status: true,
        },
        {
          field: "sumInsured",
          headerName: "Sum Insured/Assured",
          width: 200,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return typeof row.sumInsured === "object"
              ? row.sumInsured
                  .map((item: any) => {
                    return formatCurrency(item);
                  })
                  .join("/")
              : formatCurrency(row.sumInsured);
          },
        },
        {
          field: "planType",
          headerName: "Plan Type",
          width: 150,
          check_status: true,
        },
        {
          field: "createdAt",
          minWidth: 200,
          check_status: true,
          headerName: "Created At",
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return (
              <span>
                {date_time_format(row.createdAt, user_type_dateFormat, tz)}
              </span>
            );
          },
        },
        {
          field: "updatedAt",
          minWidth: 200,
          check_status: true,
          headerName: "Updated At",
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return (
              <span>
                {date_time_format(row.updatedAt, user_type_dateFormat, tz)}
              </span>
            );
          },
        },
      ]);
    };

    const onError = (err: any) => {
      console.log("err", err);
    };

    QUOTES_SERVICES.get_status(onSuccess, onError);
  };
  const update_status = (value: number, id: string) => {
    const onSuccess = (res: any) => {
      get_quotes();
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    QUOTES_SERVICES.update_status(
      {
        value: value,
      },
      onSuccess,
      onError,
      id
    );
  };
  const updateMasterState = (attrName: any, value: any) => {
    if (attrName === "searchBarfilter") {
      setsearchBarFilter(value);
    } else if (attrName === "rangedatepicker") {
      setStartEndDate({ startDate: value[0], endDate: value[1] });
    } else if (attrName[0] === "updatestatus") {
      update_status(value, attrName[1]._id);
    } else {
      setArray_Delete(value);
    }
  };

  const downloadCsv = (csvData: any) => {
    const onSuccess = async (res: any) => {
      const data = await res;
      const blob = new Blob([data.data], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "quotes.csv";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    const updatedArr = csvData?.map((item: any) => {
      const statusObj: any =
        prioritystatusData &&
        prioritystatusData?.find((status: any) => status.label === item.status);
      if (statusObj) {
        return {
          ...item,
          "Quotes ID": item?.quoteId,
          "Company Name": item?.companyName,
          "Contact Number": item?.contactNumber,
          Email: item?.email,
          "Product Name": item?.productName,
          Website: item?.website,
          Status: statusObj?.value,
          "No.of Lives": item?.liveCounts,
          "No. of Employees": item?.employeeCount,
          "Sum Insured/Assured": formatSumInsured(
            item?.sumInsured,
            formatCurrency
          ),
          "Plan Type": item?.planType,
          "Created At": date_time_format(item?.createdAt, dateFormat, timeZone),
          "Updated At": date_time_format(item?.updatedAt, dateFormat, timeZone),
          branchLocations: item?.branchLocations
            ?.map((label: any) => label?.name)
            ?.join(", "),
        };
      }
      return item;
    });

    columns.forEach((item: any) => {
      item.field = item.headerName;
    });
    let dto = {
      headers: columns,
      data: updatedArr,
    };

    CLIENT_SERVICES.csvDownload(
      onSuccess,
      onError,
      dto,
      userState.userType.toLowerCase(),
      "quotes"
    );
  };

  return (
    <>
      {showLoader && <NewLoader />}
      <Grid container spacing={2}>
        {/* Bulk upload */}
        <BulkUploadForm
          open_status={uploadFile}
          attrName={setUploadFile}
          value_update={updateMasterState}
        />

        {/* add cd balance popup */}
        <Modal open={addCdBalance} className="modalWrapper">
          <Box className="modalInner">
            <Grid container spacing={2}>
              <Grid item xs={12} className="mb-4">
                <Link
                  className="close-button"
                  onClick={() => {
                    setAddCdBalance(false);
                  }}
                />
                <h5 className="popup-heading">Add CD Balance</h5>
              </Grid>
            </Grid>
            {addNewEmployeeStep === 1 ? (
              <div>
                <Grid
                  container
                  spacing={2}
                  justifyContent="center"
                  className="mb-4"
                >
                  <Grid item xs={6}>
                    <RKTextField
                      class_name="inputField"
                      title={"Enter CD Amount"}
                      value={cdAmount}
                      attrName={setCdAmount}
                      value_update={updateMasterState}
                      warn_status={false}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    style={{ textAlign: "center" }}
                    className="ctaBtn"
                  >
                    <Button
                      variant="contained"
                      className="submitBtn"
                      onClick={() => setAddCdBalance(false)}
                    >
                      Add Amount
                    </Button>
                  </Grid>
                </Grid>
              </div>
            ) : null}
            {addNewEmployeeStep === 2 ? (
              <Grid item xs={12}>
                <Link className="md-link" underline="none">
                  Great ! New Employee added.
                </Link>
              </Grid>
            ) : null}
          </Box>
        </Modal>

        <Modal open={editStatus} className="modalWrapper">
          <>
            {editStatusStep === 1 ? (
              <Box className="modalInner deletepopup">
                <Grid container spacing={1}>
                  <Grid xs={12}>
                    <Link
                      className="close-button"
                      onClick={() => setEditStatus(false)}
                    />
                    <div className="img-prt">
                      <img
                        src="./images/deletepopup_edit_icon.svg"
                        alt=""
                        className="img"
                      />
                    </div>
                    {array_delete?.length === 0 ? (
                      ""
                    ) : (
                      <h5 className="popup-heading">
                        You can't select multiple ids
                      </h5>
                    )}
                  </Grid>
                  <Grid xs={12}>
                    <Link className="md-link mb-10" underline="none">
                      {array_delete?.length === 0
                        ? "Please Select the Client for edit"
                        : `You have selected ${array_delete?.length} ids`}
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            ) : null}
          </>
        </Modal>

        <Grid item xs={6} style={{ display: "flex", alignItems: "center" }}>
          <h3 className="mb-0 mr-5">Quotes</h3>
          <SearchBox value_update={updateMasterState} />
        </Grid>
        <Grid item xs={6} className="text-right">
          <UGDateRangePicker
            maxDate={new Date()}
            minDate={new Date()}
            placement="bottom"
            value_update={updateMasterState}
          />
          {csvQuotesPermission && policyData?.length > 0 && (
            <>
              <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}
                className="blueBtn download"
                style={{ paddingLeft: 30, textTransform: "unset" }}
              >
                Download CSV
              </Button>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={handleDownloadAll}>All Items</MenuItem>
                <MenuItem onClick={handleDownloadSelected}>
                  Selected Items
                </MenuItem>
              </Menu>
            </>
          )}
          {editQuotesPermission && (
            <Link
              underline="none"
              className="blueBtn edit"
              onClick={edit_navigate}
            >
              Edit
            </Link>
          )}
          {addQuotesPermission && (
            <Link
              underline="none"
              className="greenBtn addfile"
              onClick={() => {
                navigate(ALL_ROUTES.ADD_NEW_QUOTES);
              }}
            >
              Add Quotes
            </Link>
          )}
        </Grid>
        <Grid item xs={12}>
          {policyData && (
            <UGTable
              header_data={columns}
              data={policyData}
              value_update={updateMasterState}
              attrName={"UGTable"}
              BE_filter_code={E_BE_FILTER_CODE.QUOTES}
              totalRecords={totalRecords}
              refetch_data={get_quotes}
              setTableLimit={setTableLimit}
              setTableSkip={setTableSkip}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default Quotes;
