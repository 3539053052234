import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useEffect, useState } from "react";
import "./Dashboard.scss";
import { EMPLOYEE_URL_CONSTANTS } from "../../../../APIVar/EmployeeUrlConstant";
import { EMPLOYER_SETTING_SERVICES } from "../../../../Services/EmployerServices/Settings/SetingServices";
import FourWheelerPopup from "./FourWheelerPopup/FourWheelerPopup";
import HealthPopup from "./HealthPopup/HealthPopup";
import InvestmentPopup from "./InvestmentPopup/InvestmentPopup";
import TermPopup from "./TermPopup/TermPopup";
import TravelPopup from "./TravelPopup/TravelPopup";
import TwoWheelerPopup from "./TwoWheelerPopup/TwoWheelerPopup";
import { useAppSelector } from "../../../../Store/hooks";
import RiskScore from "../../../../Components/Desktop/Employee/RiskScore/RiskScore";
import {
  countOccurrencesInCoverageConfigString,
  formatCurrencyAccToUser,
  getCurrencyCodeRegex,
  parseConfigurations,
  titleCase,
} from "../../../../Supporting files/HelpingFunction"; 
import { Height } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import Employee_Routes from "../../../../Routes/employee_routes";
import { EMPLOYEE_ALL_ROUTES } from "../../../../Routes/employee_all_routes";
import { Claim_ASSISTANT } from "../../../../Services/Employee/ClaimAssistantService";
import NewLoader from "../../../../Components/NewLoader/NewLoader";
import MobileLoader from "../../../../Components/NewLoader/MobileLoader";
import useIsMobile from "../../../../Supporting files/MobileProvider";

const Dashboard = () => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const usertypeinfo = useAppSelector((state) => state.userDetailsSlice);
  const { ["First Name"]: fname } = useAppSelector((state: any) => state.userDetailsSlice);
  const [showHealthPolicyId, setShowHealthPolicyId] = useState("");
  const [showTermPolicyId, setShowTermPolicyId] = useState("");
  const [endorsement_claim_data, setEndorsement_claim_data] = useState<any>({
    endorsements: [],
    claims: [],
  });
  const [twoWheeler, setTwoWheeler] = React.useState<boolean>(false);
  const [fourWheeler, setFourWheeler] = React.useState<boolean>(false);
  const [travel, setTravel] = React.useState<boolean>(false);
  const [term, setTerm] = React.useState<boolean>(false);
  const [investment, setInvestment] = React.useState<boolean>(false);
  const [health, setHealth] = React.useState<boolean>(false);
  const [showLoader, setShowLoader] = useState(true);
  const [dashboardData, setDashboardData] = useState<any>([]);
  const quote = [
    {
      quote:
        "Hope is the thing with feathers that perches in the soul and sings the tune without the words and never stops at all. ",
      author: "Emily Dickinson",
    },
    {
      quote:
        "Success is not the key to happiness. Happiness is the key to success. If you love what you are doing, you will be successful.",
      author: "Albert Schweitzer",
    },
    {
      quote:
        "The future belongs to those who believe in the beauty of their dreams.",
      author: "Eleanor Roosevelt",
    },
    {
      quote: "The only way to do great work is to love what you do.",
      author: "Steve Jobs",
    },
  ];
  const selectedQuote = Math.floor(Math.random() * quote.length);

  const updateMasterState = (attrName: any, value: any) => {
    attrName(value);
  };

  const getDashboard = () => {
    const onSuccess = (res: any) => {
      if (res) {
        setTimeout(() => {
          setShowLoader(false);
        }, 1500);
      }
      let HealthPolicyId =
        res.data.data.policyDetails.find(
          (data: any) => data.productType === "Group Health"
        )?._id ?? "";
      let TermPolicyId =
        res.data.data.policyDetails.find(
          (data: any) => data.productType === "Group Term"
        )?._id ?? "";
      setShowHealthPolicyId(HealthPolicyId);
      setShowTermPolicyId(TermPolicyId);
      setDashboardData(res.data.data.policyDetails);
    };
    const onError = (err: any) => {
      console.log("true", err);
      setTimeout(() => {
        setShowLoader(false);
      }, 1500);
    };
    const url = EMPLOYEE_URL_CONSTANTS.get_dashboard_data;
    EMPLOYER_SETTING_SERVICES.fetch_data(url, onSuccess, onError);
  };

  useEffect(() => {
    getDashboard();
    get_claim_endorsement_data();
    setShowLoader(true);
  }, []);

  // get endorsement claim data
  const get_claim_endorsement_data = () => {
    const onSuccess = (res: any) => {
      // Assuming res.data.data is your response object
      const endorsements = res.data.data.endorsements;
      const claims = res.data.data.claims;

      // Find the latest endorsement based on the createdAt timestamp
      const latestEndorsement = endorsements.reduce(
        (latest: any, current: any) => {
          return latest.createdAt > current.createdAt ? latest : current;
        },
        endorsements[0]
      ); // Initialize with the first endorsement

      // Find the latest claim based on the createdAt timestamp
      const latestClaim = claims.reduce((latest: any, current: any) => {
        return latest.createdAt > current.createdAt ? latest : current;
      }, claims[0]); // Initialize with the first claim

      // Set the data
      setEndorsement_claim_data({
        endorsements: [latestEndorsement], // Wrap the latest endorsement in an array
        claims: [latestClaim], // Wrap the latest claim in an array
      });
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    Claim_ASSISTANT.get_emp_enrolment_policies(onSuccess, onError);
  };

  const parseCoverageString = (basicConfig: string, parentConfig: string) => {
    let EmployeeStr = "";
    let ParentStr = "";
    if (!basicConfig) {
      EmployeeStr = "Employee";
    } else {
      if (parentConfig === "Nill") {
        if (basicConfig?.includes("+")) {
          const spouseOccurences = countOccurrencesInCoverageConfigString(basicConfig, "S");
          const childrenOccurences = countOccurrencesInCoverageConfigString(basicConfig, "C");
          if (spouseOccurences && childrenOccurences) {
            EmployeeStr = `1 Employee, ${spouseOccurences} Spouse & ${childrenOccurences} ${childrenOccurences === 1 ? "Child" : "Children"}`;
          } else if (spouseOccurences) {
            EmployeeStr = `1 Employee & ${spouseOccurences} Spouse`;
          } else if (childrenOccurences) {
            EmployeeStr = `1 Employee & ${childrenOccurences} ${childrenOccurences === 1 ? "Child" : "Children"}`;
          }
        } else {
          EmployeeStr = "Employee";
        }
        return { EmployeeStr }
      } else {
        if (basicConfig?.includes("+")) {
          const spouseOccurences = countOccurrencesInCoverageConfigString(basicConfig, "S");
          const childrenOccurences = countOccurrencesInCoverageConfigString(basicConfig, "C");
          if (spouseOccurences && childrenOccurences) {
            EmployeeStr = `1 Employee, ${spouseOccurences} Spouse, ${childrenOccurences} ${childrenOccurences === 1 ? "Child" : "Children"}`;
          } else if (spouseOccurences) {
            EmployeeStr = `1 Employee, ${spouseOccurences} Spouse`;
          } else if (childrenOccurences) {
            EmployeeStr = `1 Employee, ${childrenOccurences} ${childrenOccurences === 1 ? "Child" : "Children"}`;
          }
        } else {
          EmployeeStr = "Employee";
        }
        if (parentConfig) {
          if (parentConfig?.includes("+")) {
            const inLawsOccurences = countOccurrencesInCoverageConfigString(parentConfig, "PIL");
            const parentsOccurences = countOccurrencesInCoverageConfigString(parentConfig, "P");
            if (parentsOccurences && inLawsOccurences) {
              ParentStr = `${parentsOccurences} Parents & ${inLawsOccurences} Parents-In-Law`;
            } else if (parentsOccurences) {
              ParentStr = `${parentsOccurences} Parents`;
            } else if (inLawsOccurences) {
              ParentStr = `${inLawsOccurences} Parents-In-Law`;
            }
          }
        }
      }
    }
    return { EmployeeStr, ParentStr };
  };

  useEffect(() => {
    if (showLoader) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
    return () => {
      document.body.style.overflow = "";
    }
  }, [])

  return (
    <>
      {/* {isMobile ? <MobileLoader /> : ( */}
        <>
          {showLoader && <NewLoader />}
          <HealthPopup
            open_status={health}
            attrName={setHealth}
            value_update={updateMasterState}
          />
          <TwoWheelerPopup
            open_status={twoWheeler}
            attrName={setTwoWheeler}
            value_update={updateMasterState}
          />
          <FourWheelerPopup
            open_status={fourWheeler}
            attrName={setFourWheeler}
            value_update={updateMasterState}
          />
          <TravelPopup
            open_status={travel}
            attrName={setTravel}
            value_update={updateMasterState}
          />
          <TermPopup
            open_status={term}
            attrName={setTerm}
            value_update={updateMasterState}
          />
          <InvestmentPopup
            open_status={investment}
            attrName={setInvestment}
            value_update={updateMasterState}
          />

          <Grid xs className="dashbaordWrapper px-0">
            <>
              <Grid container spacing={3}>
                <Grid xs={8}>
                  <Box className="pageTitle mb-6">
                    <h2>
                      <span>{fname}</span> - Discover for yourself react
                    </h2>
                    <p>
                      High-level information in one view that makes the related
                      information easier to understand.
                    </p>
                  </Box>
                  <Grid container spacing={0} className="insurancePolicies">
                    {dashboardData &&
                      dashboardData?.find(
                        (data: any) => data._id === showHealthPolicyId
                      ) ? (
                      <Grid
                        xs
                        className="healthPolicy"
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          src="./images/healthPolicyImg.svg"
                          alt="Health Policy"
                        />
                        <div className="policyDetail" style={{ height: "unset" }}>
                          <p>
                            {
                              dashboardData?.find(
                                (data: any) => data._id === showHealthPolicyId
                              )?.productType
                            }
                          </p>
                          {/* Accessing the productType from the found data */}
                          <h4>
                            SI-{" "}
                            {formatCurrencyAccToUser(
                              dashboardData?.find(
                                (data: any) => data._id === showHealthPolicyId
                              )?.sumInsured,
                              getCurrencyCodeRegex(
                                usertypeinfo.localInformation.currency
                              ),
                              usertypeinfo.localInformation.countryCurrencyCode,
                              0
                            )}{" "}
                          </h4>
                          <p className="covered">
                            {parseCoverageString(
                              dashboardData?.find(
                                (data: any) => data._id === showHealthPolicyId
                              )?.planType.basicConfig,
                              dashboardData?.find(
                                (data: any) => data._id === showHealthPolicyId
                              )?.planType.parentConfig
                            )?.ParentStr
                              ? parseCoverageString(
                                dashboardData?.find(
                                  (data: any) => data._id === showHealthPolicyId
                                )?.planType.basicConfig,
                                dashboardData?.find(
                                  (data: any) => data._id === showHealthPolicyId
                                )?.planType.parentConfig
                              )?.EmployeeStr +
                              ", " +
                              parseCoverageString(
                                dashboardData?.find(
                                  (data: any) => data._id === showHealthPolicyId
                                )?.planType.basicConfig,
                                dashboardData?.find(
                                  (data: any) => data._id === showHealthPolicyId
                                )?.planType.parentConfig
                              )?.ParentStr
                              : parseCoverageString(
                                dashboardData?.find(
                                  (data: any) => data._id === showHealthPolicyId
                                )?.planType.basicConfig,
                                dashboardData?.find(
                                  (data: any) => data._id === showHealthPolicyId
                                )?.planType.parentConfig
                              )?.EmployeeStr}
                          </p>
                        </div>
                      </Grid>
                    ) : (
                      <Grid
                        xs
                        className="healthPolicy"
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          src="./images/healthPolicyNone.svg"
                          alt="No Health Policy"
                        />
                        <div className="policyDetail">
                          <p className="highlight mb-1">You're Missing</p>
                          <p>Health Insurance</p>
                          <p className="smText mt-1">
                            It covers medical expenses for illness or injuries.
                          </p>
                        </div>
                      </Grid>
                    )}

                    {/* Term Policy */}
                    <Grid xs>
                      {dashboardData &&
                        dashboardData?.find(
                          (data: any) => data._id === showTermPolicyId
                        ) ? (
                        <div
                          className="termInsurance"
                          style={{ cursor: "pointer" }}
                        >
                          <img src="./images/BuyInsurance.svg" />
                          <div>
                            <p>
                              <span>Term</span> Insurance
                            </p>

                            <h4 className="mt-2 mb-0">
                              SI-
                              {formatCurrencyAccToUser(
                                dashboardData?.find(
                                  (data: any) => data._id === showTermPolicyId
                                )?.sumInsured,
                                getCurrencyCodeRegex(
                                  usertypeinfo.localInformation.currency
                                ),
                                usertypeinfo.localInformation.countryCurrencyCode,
                                0
                              )}
                            </h4>
                            <p className="covered">
                              {parseCoverageString(
                                dashboardData?.find(
                                  (data: any) => data._id === showTermPolicyId
                                )?.planType.basicConfig,
                                dashboardData?.find(
                                  (data: any) => data._id === showTermPolicyId
                                )?.planType.parentConfig
                              )?.ParentStr
                                ? parseCoverageString(
                                  dashboardData?.find(
                                    (data: any) => data._id === showTermPolicyId
                                  )?.planType.basicConfig,
                                  dashboardData?.find(
                                    (data: any) => data._id === showTermPolicyId
                                  )?.planType.parentConfig
                                )?.EmployeeStr +
                                ", " +
                                parseCoverageString(
                                  dashboardData?.find(
                                    (data: any) => data._id === showTermPolicyId
                                  )?.planType.basicConfig,
                                  dashboardData?.find(
                                    (data: any) => data._id === showTermPolicyId
                                  )?.planType.parentConfig
                                )?.ParentStr
                                : parseCoverageString(
                                  dashboardData?.find(
                                    (data: any) => data._id === showTermPolicyId
                                  )?.planType.basicConfig,
                                  dashboardData?.find(
                                    (data: any) => data._id === showTermPolicyId
                                  )?.planType.parentConfig
                                )?.EmployeeStr}
                            </p>
                          </div>
                        </div>
                      ) : (
                        <div
                          className="termInsurance"
                          style={{ cursor: "pointer" }}
                        >
                          <img src="./images/noTermPolicy.svg" />
                          <div>
                            <p className="highLight mb-1">You're Missing</p>
                            <p>
                              <span>Term</span> Insurance
                            </p>
                            <p className="smText mt-2">
                              It offers your family a sum of money in <br />
                              case of an unfortunate incident.
                            </p>
                          </div>
                        </div>
                      )}

                      {/* Risk Score */}
                      {/* <div
             className="riskCover"
             style={{ cursor: "pointer" }}
           >
             <div>
               <RiskScore
                 class_name="svg-pi-wrapper riskmap"
                 progress_one={50}
               ></RiskScore>
             </div>
             <div className="percentage">
               56<span>%</span>
             </div>
             <div>
               <h4>Risk Cover</h4>
               <p>Know your ideal insurance requirement</p>
             </div>
           </div> */}

                      {/* <div
             className="riskCover"
             onClick={toggleRiskDivs}
             style={{ cursor: "pointer" }}
           >
             <img src="./images/riskMap.svg" className="mr-4" />
             <div>
               <h4>Risk Cover</h4>
               <p>
                 To know your ideal insurance requirement you need to ask
                 to unlock this feature.
               </p>
             </div>
           </div> */}
                    </Grid>
                  </Grid>
                  <Box>
                    <Grid container padding={0} >
                      {dashboardData &&
                        dashboardData?.map((data: any) => {
                          if (
                            data._id !== showHealthPolicyId &&
                            data._id !== showTermPolicyId
                          ) {
                            return (
                              <Grid xs={12} lg={6} padding={1} className="otherPlans">
                                <Box className="supertopup">
                                  {data.productType}
                                  <h4>
                                    SI-{" "}
                                    {formatCurrencyAccToUser(
                                      data?.sumInsured,
                                      getCurrencyCodeRegex(
                                        usertypeinfo.localInformation.currency
                                      ),
                                      usertypeinfo.localInformation
                                        .countryCurrencyCode,
                                      0
                                    )}
                                  </h4>
                                  <p>
                                    {parseCoverageString(
                                      data?.planType?.basicConfig,
                                      data?.planType?.parentConfig
                                    )?.ParentStr
                                      ? parseCoverageString(
                                        data?.planType?.basicConfig,
                                        data?.planType?.parentConfig
                                      )?.EmployeeStr +
                                      ", " +
                                      parseCoverageString(
                                        data?.planType?.basicConfig,
                                        data?.planType?.parentConfig
                                      )?.ParentStr
                                      : parseCoverageString(
                                        data?.planType?.basicConfig,
                                        data?.planType?.parentConfig
                                      )?.EmployeeStr}
                                  </p>
                                </Box>
                              </Grid>
                            );
                          }
                        })}
                    </Grid>
                  </Box>
                  <Box className="thoughtofTheDay">
                    <div className="icon">
                      <img src="./images/thought-icon.svg" /> Thought of the day
                    </div>
                    <div className="thoughtText">
                      <span>"</span> {quote[selectedQuote]?.quote} <span>"</span>{" "}
                      <div className="author">
                        - {quote[selectedQuote]?.author}
                      </div>
                    </div>
                  </Box>
                  {/* <Grid container spacing={1} className="buyInsurance">
       <Grid xs={8} className="subHeading">
         <h3>
           <span>Buy</span> Insurance
         </h3>
         <p>
           Retail insurance policies to cover you from different types of
           risks.
         </p>
       </Grid>
       <Grid xs={4} textAlign={"right"}>
         <Link className="viewPolicy">
           View Policies{" "}
           <RemoveRedEyeOutlinedIcon className="outlineIcon" />
           <VisibilityIcon className="solidIcon" />
         </Link>
       </Grid>
       <Grid xs={12}>
         <ul className="productList">
           <li
             className="healthInsurance"
             onClick={() => setHealth(true)}
           >
             <span>Health</span> Insurance
           </li>
           <li
             className="carInsurance"
             onClick={() => setFourWheeler(true)}
           >
             <span>Car</span> Insurance
           </li>
           <li
             className="bikeInsurance"
             onClick={() => setTwoWheeler(true)}
           >
             <span>Bike</span> Insurance
           </li>
           <li className="termInsurance" onClick={() => setTerm(true)}>
             <span>Term</span> Insurance
           </li>
           <li
             className="investmentPlan"
             onClick={() => setInvestment(true)}
           >
             <span>Investment</span> Plan
           </li>
           <li
             className="travelInsurance"
             onClick={() => setTravel(true)}
           >
             <span>Travel</span> Insurance
           </li>
         </ul>
       </Grid>
     </Grid> */}
                </Grid>
                <Grid xs={4}>
                  <div className="toDoList">
                    <h4>
                      📌 <span>To</span> Do
                    </h4>
                    <ul>
                      <li>
                        <img src="./images/toDo-Icon.svg" className="my-2" />
                        <p className="laddr_p">
                          You should complete the following steps.
                        </p>
                      </li>
                      <li
                        onClick={() => {
                          navigate(EMPLOYEE_ALL_ROUTES.POLICY_WALLET);
                        }}
                      >
                        <Link className="addpolicy">
                          <p>Add Nominee</p>
                        </Link>
                      </li>
                      <li
                        onClick={() => {
                          navigate(EMPLOYEE_ALL_ROUTES.POLICY_WALLET);
                        }}
                      >
                        <Link className="addMember">
                          <p>Add Dependant</p>
                        </Link>
                      </li>
                      {/* <li onClick={() => { navigate(EMPLOYEE_ALL_ROUTES.POLICY_WALLET) }}>
             <Link className="riskScore">
               <p>Risk Score</p>
             </Link>
           </li> */}
                    </ul>
                  </div>
                  {/* Requests */}
                  <div className="yourRequests">
                    <h3>
                      🔖 <span>Your</span> Request
                    </h3>
                    <ul>
                      <li
                        onClick={() => {
                          navigate(EMPLOYEE_ALL_ROUTES.CLAIM_ASSISTANT);
                        }}
                      >
                        <Link className="addNewRequest">
                          <span>
                            <PlaylistAddIcon />
                          </span>
                          <p>
                            New <br />
                            Request
                          </p>
                        </Link>
                      </li>
                      <li>
                        <Link className="endorsement">
                          <div className="icon">
                            {endorsement_claim_data.endorsements[0]?.status ===
                              57 ? (
                              <CheckCircleOutlineIcon sx={{ color: "#2ddd2d" }} />
                            ) : (
                              <HourglassBottomIcon sx={{ color: "#FBA31D" }} />
                            )}
                          </div>
                          <span>Endorsement</span>
                          <p>
                            {endorsement_claim_data.endorsements[0]?.policyType}
                          </p>
                          <p>
                            {endorsement_claim_data.endorsements[0]?.memberId}
                          </p>
                          <p>
                            {
                              endorsement_claim_data.endorsements[0]
                                ?.endorsementFor
                            }
                          </p>
                        </Link>
                      </li>
                      {endorsement_claim_data.claims[0]?.status &&
                        <li>
                          <Link className="claim">
                            <div className="icon">
                              {endorsement_claim_data.claims[0]?.status === 50 ? (
                                <CheckCircleOutlineIcon sx={{ color: "#2ddd2d" }} />
                              ) : (
                                <HourglassBottomIcon sx={{ color: "#FBA31D" }} />
                              )}
                            </div>
                            <span>{endorsement_claim_data.claims[0]?.requestType.toLowerCase() === "intimation" ? "Reimbursement" : endorsement_claim_data.claims[0]?.requestType} Claim</span>
                            <p>{endorsement_claim_data.claims[0]?.policyType}</p>
                            <p>
                              {endorsement_claim_data?.claims[0]?.memberName &&
                                titleCase(
                                  endorsement_claim_data?.claims[0]?.memberName
                                )}
                            </p>
                            <p>
                              {endorsement_claim_data.claims[0]?.requestType}{" - "}
                              {formatCurrencyAccToUser(
                                endorsement_claim_data.claims[0]?.claimAmt,
                                getCurrencyCodeRegex(usertypeinfo.localInformation.currency),
                                usertypeinfo.localInformation.countryCurrencyCode, 0
                              )}
                            </p>
                          </Link>
                        </li>
                      }
                    </ul>
                  </div>
                  {/* Advertisement */}
                  {/* <Box
           className="advertisementBox pl-3 py-4"
           style={{
             backgroundImage: `url('./images/investment_advetisement.svg')`,
             backgroundRepeat: "no-repeat",
             backgroundSize: "200px",
             backgroundPosition: "top right",
           }}
         >
           <h3>
             <span>Low Risk</span>
             <br /> Investment Plans
           </h3>
           <p>
             Achive your financial goals with
             <br /> huge returns
           </p>
           <Link sx={{ background: "#FBA31D" }}>Get Free Quotes</Link>
         </Box>
         <Box
           className="advertisementBox pr-3 py-4 rv_box"
           style={{
             backgroundColor: "#F0F7FE",
             backgroundImage: `url('./images/repair_vehicle.svg')`,
             backgroundRepeat: "no-repeat",
             backgroundSize: "250px",
             backgroundPosition: "bottom left",
             textAlign: "right",
           }}
         >
           <h3>
             <span>Repair</span> Vehicle
           </h3>
           <p>
             It is now easy to search for <br />
             car repair & services online
           </p>
           <Link sx={{ background: "#D8433D" }}>Read More</Link>
         </Box> */}
                </Grid>
              </Grid>
            </>
          </Grid>
        </>
      {/* )} */}
    </>
  );
};

export default Dashboard;
