import React, { useEffect } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SelectDropdown from "../../../../../Supporting files/SelectDropdown/SelectDropdown";
import SearchDropdown from "../../../../../Supporting files/SearchDropdown/SearchDropdown";
import RKTextField from "../../../../../Supporting files/RKTextField/RKTextField";
import CheckBox from "../../../../../Supporting files/CheckBox/CheckBox";
import SlidingPanel from "react-sliding-side-panel";
import "./../CustomModules.scss";
import { useSelector, useDispatch } from "react-redux";
import { ToggelModalAction } from "../../../../../Store/actions/modalAction";
import { UpdateLayoutPropertiesAction } from "../../../../../Store/actions/layoutAction";
import { checkErrors } from "../CustomModuleV1/helpers";
import { ROW, COLUMN } from "../CustomModuleV1/constants";
import shortid from "shortid";

const CurrencyProperties = ({ open_status }) => {
  //const [pageStatus, setPageStatus] = React.useState<number>(1);

  const modal = useSelector((state) => {
    return state.modalReducer;
  });
  const layoutData = useSelector(({ layoutReducer }) => {
    return layoutReducer;
  });

  const [title, setTitle] = React.useState(modal.data.field_lable);
  const [maxDigits, setMaxDigits] = React.useState(
    modal.data.maxDigits ? modal.data.maxDigits : "15"
  );
  const [maxDigitsData, setMaxDigitsData] = React.useState([
    { label: "", value: "" },
  ]);
  const [decimalPlaces, setDecimalPlaces] = React.useState(
    modal.data.decimalPlaces ? modal.data.decimalPlaces : "4"
  );
  const [decimalPlacesData, setDecimalPlacesData] = React.useState([
    { label: "", value: "" },
  ]);
  const [roundingOption, setRoundingOption] = React.useState(
    modal.data.roundingOption ? modal.data.roundingOption : "Rounded Off"
  );
  const [precision, setPrecision] = React.useState(
    modal.data.precision ? modal.data.precision : "0"
  );
  const [tooltip, setTooltip] = React.useState(
    modal.data.toolTip ? modal.data.toolTip : false
  );
  const [tooltiptext, setTooltipText] = React.useState(
    modal.data.tipText ? modal.data.tipText : ""
  );

  const [isError, setError] = React.useState(false);
  const [isSuccess, setSuccess] = React.useState(false);
  const [required, setRequired] = React.useState(
    modal.data.required ? modal.data.required : false
  );

  const dispatch = useDispatch();

  const roundingOption_data = [
    { key: "Round Off", value: "Round Off" },
    { key: "Round Off", value: "Round Off" },
  ];
  const precision_data = [
    { key: "0", value: "0" },
    { key: "1", value: "1" },
  ];

  useEffect(() => {
    let maxDigitsData = [];
    let decimalPlacesData = [];
    for (var i = 1; i <= 20; i++) {
      maxDigitsData.push({
        label: i.toString(),
        value: i.toString(),
      });
      decimalPlacesData.push({
        label: i.toString(),
        value: i.toString(),
      });
    }
    setMaxDigitsData(maxDigitsData);
    setDecimalPlacesData(decimalPlacesData);
    if (isError || isSuccess) {
      setTimeout(() => {
        setError(false);
        setSuccess(false);
      }, 2000);
    }
  }, [isError, isSuccess]);

  const updateMasterState = (attrName, value) => {
    attrName(value);
  };

  const submitHandler = () => {
    let objectToUpdate = {
      ...modal.data,
      field_lable: title,
      precision: precision,
      maxDigits: maxDigits,
      decimalPlaces: decimalPlaces.value,
      required,
      roundingOption,
      tipText: tooltiptext,
      toolTip: tooltip,
    };

    let path = modal.path.split("-");

    let columnObj = {
      id: shortid.generate(),
      type: COLUMN,
      children: [objectToUpdate],
    };


    let isError = checkErrors(title);

    if(!isError) {
      if (!objectToUpdate.from) {
        if (path.length === 5) {
          layoutData.layout[path[0]].children[path[1]].children[path[2]].children[
            path[3]
          ].children.splice(path[4], 0, objectToUpdate);
        } else if (path.length === 4) {
          layoutData.layout[path[0]].children[path[1]].children[
            path[2]
          ].children.splice(path[3], 0, columnObj);
        } else if (path.length === 3) {
          layoutData.layout[path[0]].children[path[1]].children.splice(
            path[2],
            0,
            { id: shortid.generate(), type: ROW, children: [columnObj] }
          );
        } else if (path.length === 2) {
          layoutData.layout[path[0]].children.splice(path[1], 0, {
            id: shortid.generate(),
            type: ROW,
            name: "New Sub Section",
            children: [
              {
                id: shortid.generate(),
                type: COLUMN,
                children: [objectToUpdate],
              },
            ],
          });
        }
      } else {
        layoutData.layout[path[0]].children[path[1]].children[path[2]].children[
          path[3]
        ].children[path[4]] = objectToUpdate; //[path[3]]
      }
      dispatch(UpdateLayoutPropertiesAction(layoutData.layout, objectToUpdate));
      setError(false);
      setSuccess(true);
      dispatch(ToggelModalAction(modal.isOpen, "", null));
    } else {
      setError(true);
      setSuccess(false);
    }
  };

  const checkErrorOnBackdrop = () => {
    let isError = checkErrors(title);
    if (!isError) {
      setError(false);
    } else {
      setError(true);
    }
  };

  return (
    <SlidingPanel
      type={"right"}
      isOpen={open_status}
      size={75}
      backdropClicked={checkErrorOnBackdrop}
    >
      <div>
        <Grid container spacing={3} alignItems="center">
          <Grid xs={11} className="pt-3">
            <h4 className="mb-0">Field Properties - Currency</h4>
          </Grid>

          {/* {modal.data.from ? (
            <Grid
              xs={1}
              className="pt-3"
              onClick={() => {
                dispatch(ToggelModalAction(modal.isOpen, "", null));
              }}
            >
              <CloseIcon />
            </Grid>
          ) : (
            ""
          )} */}

          <Grid
              xs={1}
              className="pt-3"
              onClick={() => {
                dispatch(ToggelModalAction(modal.isOpen, "", null));
              }}
            >
              <CloseIcon />
          </Grid>

          <Grid xs={12} className="mb-4">
            <hr />
          </Grid>
        </Grid>
        <div className="scrollable_area pt-3">
          <Grid container spacing={3}>
            <Grid xs={12}>
              <RKTextField
                class_name="inputField"
                title="Field Label"
                value={title}
                attrName={setTitle}
                value_update={updateMasterState}
                error_messg="Field Label is Required!"
                warn_status={isError}
              />
            </Grid>

            <Grid xs={12}>
              <RKTextField
                validation_type="numeric"
                class_name="inputField"
                title="Maximum Digits Allowed"
                value={maxDigits}
                attrName={setMaxDigits}
                value_update={updateMasterState}
                error_message="Please Enter max Digits"
                warn_status={false}
              />
            </Grid>
            <Grid xs={12}>
              <SearchDropdown
                class_name="inputField"
                title="No. of Decimal Places"
                value={decimalPlaces}
                attrName={setDecimalPlaces}
                value_update={updateMasterState}
                data={decimalPlacesData}
                error_message="Please select max decimal Places"
                warn_status={false}
              />
            </Grid>
            <Grid xs={6}>
              <SelectDropdown
                class_name="inputField"
                title="Rounding Option"
                value={roundingOption}
                attrName={setRoundingOption}
                value_update={updateMasterState}
                dropdown_data={roundingOption_data}
                warn_status={false}
              />
            </Grid>
            <Grid xs={6}>
              <SelectDropdown
                class_name="inputField"
                title="Precision"
                value={precision}
                attrName={setPrecision}
                value_update={updateMasterState}
                dropdown_data={precision_data}
                warn_status={false}
              />
            </Grid>
            <Grid xs={12}>
              <CheckBox
                title="Required"
                name="required"
                id="required"
                value={required}
                attrName={setRequired}
                value_update={updateMasterState}
                default_check={true}
              />
            </Grid>
            <Grid xs={12} className="checkbox_feature ">
              <CheckBox
                title="Show Tooltip"
                name="tooltip"
                id="tooltip"
                value={tooltip}
                attrName={setTooltip}
                value_update={updateMasterState}
                default_check={false}
              />
            </Grid>
            {tooltip === true ? (
              <Grid xs={12}>
                <RKTextField
                  class_name="inputField"
                  title="Enter Tooltip Text"
                  value={tooltiptext}
                  attrName={setTooltipText}
                  value_update={updateMasterState}
                  warn_status={false}
                />
              </Grid>
            ) : null}
          </Grid>
        </div>
        <Grid container spacing={3}>
          <Grid xs={12} className="ctaBtn mt-4 text-center">
            <Button
              variant="contained"
              className="submitBtn "
              onClick={submitHandler}
            >
              Done
            </Button>
          </Grid>
        </Grid>
        {isSuccess ? (
          <div className="successMessage">
            <h4>Field Property Updated!</h4>
          </div>
        ) : (
          ""
        )}
      </div>
    </SlidingPanel>
  );
};

export default CurrencyProperties;
