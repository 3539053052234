import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useEffect, useRef, useState } from "react";
import EmployerFeaturesAndExclusion from "../../../../../Components/Broker/FeaturesAndExclusion/EmployerFeaturesAndExclusion";

interface Props {
  type: any;
}

const FeatureAndExclusions: React.FC<Props> = ({ type }) => {
  const [sectionStatus, SetSectionStatus] = React.useState<string>("FeaturesAndExclusionss");
  const FeaturesAndExclusionss = useRef(null);

  const scrollToSection = (elementRef: any) => {
    elementRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <Grid container spacing={3}>
      <Grid
        xs={2}
        style={{
          maxWidth: "260px",
          position: "relative",
          top: "-75px",
          paddingLeft: "0",
        }}
      >
        <Grid xs className="leftSection h-100" style={{ position: "fixed", paddingLeft: "8px" }}>
          <Link
            className={
              sectionStatus === "FeaturesAndExclusionss"
                ? "active sectionLink FeaturesAndExclusionss"
                : "sectionLink FeaturesAndExclusionss"
            }
            onClick={() => {
              scrollToSection(FeaturesAndExclusionss);
              SetSectionStatus("FeaturesAndExclusionss");
            }}
          >
            <span> Features And Exclusions</span>
          </Link>
        </Grid>
      </Grid>
      <Grid xs={10}>
        <Box className="voluntry_plans_outer" ref={FeaturesAndExclusionss}>
          <EmployerFeaturesAndExclusion type={type} />
        </Box>
      </Grid>
    </Grid>
  );
}
export default FeatureAndExclusions;
