export const one_to_thirty_days_data = [
  { key: "0 Day", value: "0 Day" },
  { key: "1 Day", value: "1 Day" },
  { key: "2 Days", value: "2 Days" },
  { key: "3 Days", value: "3 Days" },
  { key: "4 Days", value: "4 Days" },
  { key: "5 Days", value: "5 Days" },
  { key: "6 Days", value: "6 Days" },
  { key: "7 Days", value: "7 Days" },
  { key: "8 Days", value: "8 Days" },
  { key: "9 Days", value: "9 Days" },
  { key: "10 Days", value: "10 Days" },
  { key: "11 Days", value: "11 Days" },
  { key: "12 Days", value: "12 Days" },
  { key: "13 Days", value: "13 Days" },
  { key: "14 Days", value: "14 Days" },
  { key: "15 Days", value: "15 Days" },
  { key: "16 Days", value: "16 Days" },
  { key: "17 Days", value: "17 Days" },
  { key: "18 Days", value: "18 Days" },
  { key: "19 Days", value: "19 Days" },
  { key: "20 Days", value: "20 Days" },
  { key: "21 Days", value: "21 Days" },
  { key: "22 Days", value: "22 Days" },
  { key: "23 Days", value: "23 Days" },
  { key: "24 Days", value: "24 Days" },
  { key: "25 Days", value: "25 Days" },
  { key: "26 Days", value: "26 Days" },
  { key: "27 Days", value: "27 Days" },
  { key: "28 Days", value: "28 Days" },
  { key: "29 Days", value: "29 Days" },
  { key: "30 Days", value: "30 Days" },
];

export const one_to_ten_data = [
  { key: 1, value: 1 },
  { key: 2, value: 2 },
  { key: 3, value: 3 },
  { key: 4, value: 4 },
  { key: 5, value: 5 },
  { key: 6, value: 6 },
  { key: 7, value: 7 },
  { key: 8, value: 8 },
  { key: 9, value: 9 },
  { key: 10, value: 10 },
];
