import { MODAL_TOGGEL_CLOSE, MODAL_TOGGEL_OPEN } from './../constants/modalConstants';

export const ToggelModalAction = (status, data, path) => async (dispatch) =>
{
    console.log(status, data, path);
    return await status ?
        dispatch({
            type: MODAL_TOGGEL_CLOSE,
            data,
            path
        })
        :
        dispatch({
            type: MODAL_TOGGEL_OPEN,
            data,
            path
        })
}