import { createSlice } from "@reduxjs/toolkit";
import { TBulkUpload } from "../../../Services/Types/TBulkUpload";
import { BULK_UPLOAD_REDUCERS, BulkUploadReducer } from "./BulkUploadReducers";

export const bulkUploadSlice = createSlice<TBulkUpload, BulkUploadReducer>({
  name: "bulk_upload",
  initialState: {
    action_perform_to_duplicate_record: "0",
    csv_file: {
      name: "",
      size: 0,
      type: "0",
      lastModified: 0,
      webkitRelativePath: "",
      arrayBuffer: () => Promise.resolve(new ArrayBuffer(0)),
      slice: () => new Blob(),
      stream: () => new ReadableStream(),
      text: () => Promise.resolve("CSV file content"),
    },
    data_time_format_of_selected_file: "",
    find_duplicate_using: "",
    csv_file_header: [],
    clientId_detail: { id: "", label: "" },
    map_field_data: [],
    map_field_api_response: {
      errorData: [],
      overWriteCount: 0,
      skipCount: 0,
      successData: 0,
    },
    bulk_upload_page_status: "BULK_UPLOAD_PAGE",
  },
  reducers: BULK_UPLOAD_REDUCERS,
});
