import Employed from "@mui/icons-material/Badge";
import Active from "@mui/icons-material/CheckCircle";
import Terminated from "@mui/icons-material/DirectionsRun";
import Void from "@mui/icons-material/DoDisturbOff";
import Inactive from "@mui/icons-material/HighlightOff";
import Probation from "@mui/icons-material/ModelTraining";
import NA from "@mui/icons-material/NotAccessible";
import Resigned from "@mui/icons-material/SubtitlesOff";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  Link,
  Menu,
  MenuItem,
  Modal,
  TextField,
} from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Grid from "@mui/material/Unstable_Grid2";
import { differenceInCalendarDays } from "date-fns";
import React, { useCallback, useEffect, useState } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import GlobalDeletePopup from "../../../../Components/Broker/GlobalDeletePopup/GlobalDeletePopup";
import GlobelEditPopup from "../../../../Components/Broker/GlobalEditPopup/GlobalEditPopup";
import MassUpdate from "../../../../Components/Common/MassUpdate/MassUpdate";
import NewLoader from "../../../../Components/NewLoader/NewLoader";
import { ALL_ROUTES } from "../../../../Routes/all_routes";
import { CLIENT_SERVICES } from "../../../../Services/Clients/ClientService";
import { EMPLOYEE_SERVICES } from "../../../../Services/Employee/EmployeeService";
import { E_BE_FILTER_CODE } from "../../../../Services/Enum/E_UGTable";
import { TUserData } from "../../../../Services/Types/TUserData";
import { RootState } from "../../../../Store/Store";
import { useAppSelector } from "../../../../Store/hooks";
import { useFormatCurrency } from "../../../../Supporting files/CustomHooks";
import { capitalize, convertSingleBracketToCurrencyFormat, date_time_format, getCurrencyCodeRegex } from "../../../../Supporting files/HelpingFunction";
import RKTextField from "../../../../Supporting files/RKTextField/RKTextField";
import SearchBox from "../../../../Supporting files/SearchBarAnimation/SearchBox";
import SelectDropdown from "../../../../Supporting files/SelectDropdown/SelectDropdown";
import UGDateRangePicker from "../../../../Supporting files/UGDateRangePicker/UGDateRangePicker";
import UGTable from "../../../../Supporting files/UGTable/UGTable";

interface Props {
  addEmployee?: boolean;
}

const Employees: React.FC<Props> = ({ addEmployee }) => {
  const usertypeinfo = useAppSelector((state) => state.userDetailsSlice.userType);
  const userState: TUserData = useAppSelector((state: RootState) => state.userDetailsSlice);
  const [addFilterStatus, setAddFilterStatus] = useState(false);
  const [removeEmployee, setRemoveEmployee] = useState(false);
  const [InviteEmployee, setInviteEmployee] = useState(false);
  const [addNewEmployee, setAddNewEmployee] = useState(addEmployee);
  const [addPolicyStatus, setAddPolicyStatus] = useState(false);
  const [policyType, setPolicyType] = useState("");
  const [policyname, setPolicyName] = useState("");
  const [expiry, setExpiryStatus] = useState("");
  const [age, setAge] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [array_delete, setArray_Delete] = useState<Array<{ id: number }>>([]);
  const [startEndDate, setStartEndDate] = useState<any>({
    startDate: "",
    endDate: "",
  });
  const { formatCurrency } = useFormatCurrency();
  const [searchBarFilter, setsearchBarFilter] = useState("");
  const [edit_popup, setEdit_popup] = useState<boolean>(false);
  const [deleteStatusStep, setDeleteStatusStep] = useState(1);
  const [massupdateopen, setmassupdateopen] = useState(false);
  const { BROKER } = useAppSelector((state) => state.permissionSlice);
  const [empolyeesBulkUplodPermission, setEmpolyeesBulkUplodPermission] = useState(true);
  const [empolyeesEiditPermission, setEmpolyeesEiditPermission] = useState(true);
  const [empolyeesAddPermission, setEmpolyeesAddPermission] = useState(true);
  const [empolyeesRemovePermission, setEmpolyeesRemovePermission] = useState(true);
  const [empolyeesCsvPermission, setEmpolyeesCsvPermission] = useState(true);
  const [statusPermission, setStatusPermission] = useState(true);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [rowsClone, setRowsClone] = useState([]);
  const [csvBody, setCsvBody] = useState<any[]>([]);
  const [employementStatusData, setEmployementStatusData] = useState<any[]>([]);
  const user_type_dateFormat = useAppSelector((state: RootState) => state.userDetailsSlice.localInformation.dateFormat);
  const tz = useAppSelector((state: RootState) => state.userDetailsSlice.localInformation.timeZone);
  let selected_data: Array<{ id: number }> = [];
  const [gender, setGender] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [tableLimit, setTableLimit] = useState();
  const [tableSkip, setTableSkip] = useState();
  const navigate = useNavigate();
  const [showLoader, setShowLoader] = useState(true);
  const [prioritystatusData, setprioritystatusData] = useState([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorElCSV, setAnchorElCSV] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClickCsv = (event: any) => {
    setAnchorElCSV(event.currentTarget);
  };

  const handleCloseCsv = () => {
    setAnchorElCSV(null);
  };

  const handleDownloadAll = () => {
    table_data(totalRecords, 0, "csv")
    handleCloseCsv();
  };

  const handleDownloadSelected = () => {
    table_data(tableLimit ?? 25, 0, "csv")
    handleCloseCsv();
  };
  let timeout: any;

  useEffect(() => {
    setEmpolyeesBulkUplodPermission(BROKER?.Employees.upload === undefined ? false : BROKER.Employees.upload);
    setEmpolyeesEiditPermission(BROKER?.Employees.edit === undefined ? false : BROKER?.Employees.edit);
    setEmpolyeesAddPermission(BROKER?.Employees.add === undefined ? false : BROKER?.Employees.add);
    setEmpolyeesRemovePermission(BROKER?.Employees.remove === undefined ? false : BROKER?.Employees.remove);
    setEmpolyeesCsvPermission(BROKER?.Employees.csv === undefined ? false : BROKER?.Employees.csv);
    setStatusPermission(BROKER?.Employees.status === undefined ? false : BROKER?.Employees.status);

    setShowLoader(true);

    if (timeout) {
      clearTimeout(timeout);
    }

    const delayedApiCall = () => {
      console.log("entered1")

      get_status(BROKER?.Employees.status === undefined ? false : BROKER?.Employees.status);
      table_data();
    };

    timeout = setTimeout(delayedApiCall, 900);

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  
  }, []);

  const handleClose = (value: string) => {
    navigate({
      pathname: ALL_ROUTES.BULK_UPLOAD_PAGE,
      search: createSearchParams({
        calledFrom: ALL_ROUTES.EMPLOYEE,
        subpage: value,
      }).toString(),
    });
    // setSelectedValue(value);
    setAnchorEl(null);
  };

  useEffect(() => {
    if(removeEmployee){
      window.addEventListener("keydown", handleKeyPress);
      // navigate("/homebroker?page=employe");
      header_tag();
    }
    
  }, [removeEmployee]);

  const handleKeyPress = useCallback((e: any) => {
    var event = e;
    if (event.target.tagName !== "INPUT") {
      if (event.shiftKey && event.keyCode === 69) {
        setAddNewEmployee(true);
      }
    }
  }, []);

  //delete employee
  const delete_employee = (attrName?: any, step?: any) => {
    // success delete
    let ids = {
      ids: array_delete,
    };

    const onSuccess = (res: any) => {
      setDeleteStatusStep(2);
      table_data(tableLimit, tableSkip);
    };

    const onError = (err: any) => {
      console.log("error", err);
    };
    EMPLOYEE_SERVICES.delete_employee(ids, onSuccess, {}, onError);
    if (attrName === "data") {
      setDeleteStatusStep(step);
    }
  };

  const forstatusPermission = () => {
    return statusPermission
  }

  const handleChange = (event: SelectChangeEvent, attrName: string) => {
    if (attrName === "gender") {
      setGender(event.target.value as string);
    } else if (attrName === "policyType") {
      setPolicyType(event.target.value as string);
    } else if (attrName === "expiry") {
      setExpiryStatus(event.target.value as string);
    } else if (attrName === "policyname") {
      setPolicyName(event.target.value as string);
    } else if (attrName === "age") {
      setAge(event.target.value as string);
    }
  };

  const get_status = (permissionStatus: any) => {
    const onSuccess = (res: any) => {
      let statusData = res.data.data.map((data: any) => ({
        label: data.value,
        value: data.label,
      }));
      setprioritystatusData(statusData);
      //header_tag(statusData);
      get_status_employement(statusData, permissionStatus);
    };

    const onError = (err: any) => {
      console.log("err", err);
    };

    EMPLOYEE_SERVICES.get_status(onSuccess, onError);
  };

  const get_status_employement = (memberstatus: any, permissionStatus: any) => {
    const onSuccess = (res: any) => {
      let statusData = res.data.data.map((data: any) => ({
        label: data.value,
        value: data.label,
      }));
      setEmployementStatusData(statusData);
      header_tag(memberstatus, statusData, permissionStatus);
    };

    const onError = (err: any) => {
      console.log("err", err);
    };

    EMPLOYEE_SERVICES.get_status_employement(onSuccess, onError);
  };

  const update_status = (value: number, id: string, type: string) => {
    const onSuccess = (res: any) => {
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    EMPLOYEE_SERVICES.update_status(
      {
        value: value,
      },
      onSuccess,
      onError,
      id,
      type,
      usertypeinfo.toLowerCase()
    );
  };

  const header_tag = (statusData?: any, employementStatus?: any, permissionStatus?: any) => {
    const onSuccess = (res: any) => {
      res.data.data.push(
        {
          field: "createdAt",
          minWidth: 200,
          check_status: true,
          headerName: "Created At",
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return (
              <span>
                {date_time_format(
                  row.createdAt,
                  `${user_type_dateFormat} hh:mm A`,
                  tz
                )}
              </span>
            );
          },
        },
        {
          field: "updatedAt",
          minWidth: 200,
          check_status: true,
          headerName: "Updated At",
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return (
              <span>
                {date_time_format(
                  row.updatedAt,
                  `${user_type_dateFormat} hh:mm A`,
                  tz
                )}
              </span>
            );
          },
        }
      );
      let data_change_field: any = [];
      res.data.data.forEach((e: any, index: number) => {
        if (index === 5) {
          data_change_field.push(
            {
              field: "status",
              headerName: "Member Status",
              minWidth: 200,
              check_status: true,
              renderCell: (ValueFormatterParams: any) => {
                const { row, index } = ValueFormatterParams;
                return (
                  <div id={`_${row.status}`}>
                    <div className="status_dropdown" id={`_${row.status}`}>
                      {row.status === 32 ? (
                        <Active id={`_${row.status.toString()}`} />
                      ) : row.status === 33 ? (
                        <Inactive id={`_${row.status.toString()}`} />
                      ) : row.status === 34 ? (
                        <Void id={`_${row.status.toString()}`} />
                      ) : null}
                      <SelectDropdown
                        class_name="inputFieldd"
                        title=""
                        value={row.status}
                        attrName={["updatestatus", row, index]}
                        value_update={updateMasterState}
                        dropdown_data={statusData}
                        warn_status={false}
                        disabled={!permissionStatus}
                      />
                    </div>
                  </div>
                );
              },
            },
            {
              field: "employement_status",
              headerName: "Employment Status",
              minWidth: 200,
              check_status: true,
              renderCell: (ValueFormatterParams: any) => {
                const { row, index } = ValueFormatterParams;
                row.employement_status =
                  row["Member Type"] === "Employee"
                    ? row.employement_status
                    : 39;
                return (
                  <div
                    id={`_${row.employement_status}`}
                    style={{ borderRadius: "20px" }}
                  >
                    <div className="status_dropdown"
                      id={`_${row.employement_status?.toString()}`}
                    >
                      {row["Member Type"] !== "Employee" ? (
                        <NA id={`_${row.employement_status?.toString()}`} />
                      ) : row.employement_status === 35 ? (
                        <Resigned id={`_${row.employement_status?.toString()}`} />
                      ) : row.employement_status === 36 ? (
                        <Terminated id={`_${row.employement_status?.toString()}`} />
                      ) : row.employement_status === 37 ? (
                        <Employed id={`_${row.employement_status?.toString()}`} />
                      ) : row.employement_status === 38 ? (
                        <Probation id={`_${row.employement_status?.toString()}`} />
                      ) : row.employement_status === 39 ? (
                        <NA id={`_${row.employement_status?.toString()}`} />
                      ) : null}
                      {row["Member Type"] === "Employee" ? (
                        <SelectDropdown
                          class_name="inputFieldd"
                          title=""
                          value={row.employement_status}
                          attrName={["updatestatusEmployment", row, index]}
                          value_update={updateMasterState}
                          dropdown_data={employementStatus}
                          warn_status={false}
                          disabled={!permissionStatus}
                        />
                      ) : (
                        <span>NA</span>
                      )}
                    </div>
                  </div>
                );
              },
            }
          );
        }
        if (e.field === "Email") {
          data_change_field.push({
            ...e,
            renderCell: (ValueFormatterParams: any) => {
              const { row } = ValueFormatterParams;
              return (
                <Link
                  underline="none"
                  style={{ cursor: "pointer" }}
                  href={`mailto:${row.email}`}
                >
                  {row["Email"]}
                </Link>
              );
            },
          });
        }else if(e.field === "Gender"){
          data_change_field.push({
            ...e,
            renderCell: (ValueFormatterParams: any) => {
              const { row } = ValueFormatterParams;
              return capitalize(row.Gender);
            },
          });
        }else if (e.field === "First Name") {
          data_change_field.push({
            ...e,

            renderCell: (ValueFormatterParams: any) => {
              const { row } = ValueFormatterParams;

              return (
                <Link
                  underline="none"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate(
                      `${ALL_ROUTES.EMPLOYEE_DETAILS_VIEW}?id=${row.id}`
                    );
                  }}
                >
                  {row["First Name"]}
                </Link>
              );
            },
          });
        } else if (e.field === "Official Email") {
          data_change_field.push({
            ...e,
            renderCell: (ValueFormatterParams: any) => {
              const { row } = ValueFormatterParams;
              return (
                <Link
                  underline="none"
                  style={{ cursor: "pointer" }}
                  href={`mailto:${row["Official Email"]}`}
                >
                  {row["Official Email"]}
                </Link>
              );
            },
          });
        } else if (e.field === "Date Of Joining") {
          data_change_field.push({
            ...e,
            renderCell: (ValueFormatterParams: any) => {
              const { row } = ValueFormatterParams;
              return (
                <span>
                  {row["Date Of Joining"] === "--" ||
                    row["Date Of Joining"] === null ||
                    row["Date Of Joining"] === ""
                    ? "--"
                    : date_time_format(
                      row["Date Of Joining"],
                      user_type_dateFormat,
                      tz
                    )}
                </span>
              );
            },
          });
        } else if (e.field === "Select Client") {
          data_change_field.push({
            ...e,
            renderCell: (ValueFormatterParams: any) => {
              const { row } = ValueFormatterParams;
              return <span>{row["Select Client"]}</span>;
            },
          });
        } else if (e.field === "Date Of Birth") {
          data_change_field.push({
            ...e,
            renderCell: (ValueFormatterParams: any) => {
              let { row } = ValueFormatterParams;
              return (
                <span>
                  {row["Date Of Birth"] === "--" ||
                    row["Date Of Birth"] === null ||
                    row["Date Of Birth"] === "" ||
                    row["Date Of Birth"] === undefined
                    ? "--"
                    : date_time_format(
                      row["Date Of Birth"],
                      user_type_dateFormat,
                      tz
                    )}
                </span>
              );
            },
          });
        } else if (e.field === "Select Client") {
          data_change_field.push({
            ...e,
            headerName: "Organisation ID/Name",
          });
        } else if (e.field === "Location") {
          data_change_field.push({
            ...e,
            headerName: "Organisation Location",
          });
        } else if (e.field === "Salary") {
          data_change_field.push({
            ...e,
            renderCell: (ValueFormatterParams: any) => {
              const { row } = ValueFormatterParams;
              return (
                <span>
                  {row["Salary"] === "--" ||
                    row["Salary"] === null ||
                    row["Salary"] === ""
                    ? "--"
                    : formatCurrency(
                      row["Salary"]
                    )}
                </span>)
            },
          });
        }
        else if (e.field === "Last Day of Working") {
          data_change_field.push({
            ...e,
            renderCell: (ValueFormatterParams: any) => {
              const { row } = ValueFormatterParams;
              return (
                <span>
                  {row["Last Day of Working"] ? (row["Last Day of Working"] === "--" ||
                    row["Last Day of Working"] === null ||
                    row["Last Day of Working"] === ""
                    ? "--"
                    : date_time_format(
                      row["Last Day of Working"],
                      `${user_type_dateFormat} hh:mm A`,
                      tz)) : "--"}
                </span>
              );
            },
          });
        }
        else {
          data_change_field.push(e);
        }
      });
      setColumns(data_change_field);
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    EMPLOYEE_SERVICES.get_header_tag(onSuccess, onError);
  };
  const table_data = (limit?: number, skip?: number, csv?: any) => {
    const onSuccess = (res: any) => {
      if (res) {
        setTimeout(() => {
          setShowLoader(false);
        }, 1500);
      }
      setCsvBody(res.data.data.data);
      let data = res.data.data.data;
      data = data?.map((item: any) => {
        for (let key in item) {
          if (!item[key]) {
            item[key] = "--";
          }
        }
        return item;
      });
      setRows(data);
      setRowsClone(pv => data)
      setTotalRecords(res.data.data.countData);
      if (csv) {
        downloadCsv(data)
      }
    };
    const onError = (err: any) => { };
    EMPLOYEE_SERVICES.get_table_data(
      onSuccess,
      onError,
      limit ?? 25,
      skip ?? 0,
      startEndDate.startDate ?? "",
      startEndDate.endDate ?? "",
      searchBarFilter ?? ""
    );
  };

  const edit_navigate = () => {
    if (array_delete.length === 0) {
      return setEdit_popup(true);
    } else if (array_delete.length > 1) {
      return setEdit_popup(true);
    } else {
      navigate(`${ALL_ROUTES.EDIT_EMPLOYEE}?id=${array_delete[0]}`);
    }
  };

  let updateLocalStatus = (id: any, type: any, value: any) => {
    let anotherData: any;
    setRowsClone(pv => {
      anotherData = pv;
      return pv;
    })
    let newData: any = [...anotherData];
    let updatedRows = newData.map((dataRow: any) => {
      if (dataRow.id === id) {
        dataRow[type] = value;
      }
      return dataRow;
    });
    setRows(updatedRows);
  }

  const updateMasterState = (attrName: any, value: any) => {
    if (attrName === "selected_data") {
      selected_data = value;
    } else if (attrName === "delete_step") {
      setDeleteStatusStep(value);
    } else if (attrName[0] === "updatestatus") {
      if (attrName[1].employement_status === 36 ||
        attrName[1].employement_status === 35) {
        return toast.warn("Can't change member status when employement status is Resigned/Terminated");
      }
      if (attrName[1].status === 34) {
        return toast.warn("Void status is not Reversible");
      }
      updateLocalStatus(attrName[1].id, "status", value)
      update_status(value, attrName[1].id, "MEMBER");
    } else if (attrName[0] === "updatestatusEmployment") {
      let diff: number = differenceInCalendarDays(new Date(attrName[1]["Last Day of Working"]), new Date())

      if (value === 36 || value === 35) {
        if (!attrName[1]["Last Day of Working"] || attrName[1]["Last Day of Working"] === "--") {
          return toast.warn(
            "Please Add Last Date of Working before to change status to Resigned/Terminated"
          );
        } else if (diff > 0 && (attrName[1]["Last Day of Working"] || attrName[1]["Last Day of Working"] === "--")) {
          return toast.warn(
            "Before Last Date of Working status can't be changed to Resigned/Terminated"
          );
        } else {
          updateLocalStatus(attrName[1].id, "employement_status", value)

          return update_status(value, attrName[1].id, "EMPLOYMENT");
        }
      } else if (
        attrName[1].employement_status === 36 ||
        attrName[1].employement_status === 35
      ) {
        if (
          (attrName[1].employement_status === 36 && value === 35) ||
          (attrName[1].employement_status === 35 && value === 36)
        ) {
          updateLocalStatus(attrName[1].id, "employement_status", value)

          return update_status(value, attrName[1].id, "EMPLOYMENT");
        } else {
          return toast.warning(
            "You can't Change status after Resigned/Terminated"
          );
        }
      } else if (attrName[1].employement_status === 38 && value === 37) {
        updateLocalStatus(attrName[1].id, "employement_status", value)

        return update_status(value, attrName[1].id, "EMPLOYMENT");
      } else if (attrName[1].employement_status === 37 && value === 38) {
        return toast.warning("You can't change status Employed to Probation");
      } else if (value === 39) {
        return toast.warning("This Staus is not Applicable for Employee");
      } else {
        updateLocalStatus(attrName[1].id, "employement_status", value)

        return update_status(value, attrName[1].id, "EMPLOYMENT");
      }

    } else if (attrName === "searchBarfilter") {
      setsearchBarFilter(value);
    } else if (attrName === "rangedatepicker") {
      setStartEndDate({ startDate: value[0], endDate: value[1] });
    } else if (attrName === "massupdateopen") {
      setmassupdateopen(false);
      table_data(25, 0);
    } else {
      attrName(value);
    }
    if (attrName === "selected_data") {
      setArray_Delete(value);
    }
  };

  let timeoutId: any;
  useEffect(() => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    const delayedApiCall = () => {
      if(searchBarFilter ||startEndDate.endDate){
        table_data();
      }
     
    };

    timeoutId = setTimeout(delayedApiCall, 1000);

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [searchBarFilter,startEndDate]);

  // useEffect(() => {
  //   table_data();
  // }, [startEndDate]);

  const downloadCsv = (data: any) => {
    const onSuccess = async (res: any) => {
      const data = await res;
      const blob = new Blob([data.data], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "members.csv";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    };
    const onError = (err: any) => {
      console.log("sucess", err);
    };

    const updatedArr = data?.map((item: any) => {
      const statusObj: any = prioritystatusData && prioritystatusData?.find((status: any) => status.label === item.status);
      const employeeStatusObj: any = employementStatusData && employementStatusData?.find((status: any) => status.label === item.employement_status);
      if (statusObj) {
        return {
          Title: item?.Title,
          Id: item?.Id,
          "First Name": item["First Name"],
          "Last Name": item["Last Name"],
          Gender: item?.Gender,
          "Date Of Birth": item["Date Of Birth"] !== "--" ? date_time_format(item["Date Of Birth"], user_type_dateFormat, tz) : item["Date Of Birth"],
          "Member Status": statusObj?.value,
          // status: statusObj?.value,
          "Employment Status": employeeStatusObj?.value,
          "Contact Number": item["Contact Number"],
          Email: item.Email,
          Department: item.Department,
          Designation: item.Designation,
          "Date Of Joining": item["Date Of Joining"] !== "--" ? date_time_format(item["Date Of Joining"], user_type_dateFormat, tz) : item["Date Of Joining"],
          "Last Day of Working": item["Last Day of Working"] && item["Last Day of Working"] !== "--" ? date_time_format(item["Last Day of Working"], user_type_dateFormat, tz) : "--",
          "Official Email": item["Official Email"],
          "Office Contact Number": item["Office Contact Number"],
          "Salary Bracket": item["Salary Bracket"] !== "--" && item["Salary Bracket"] !== undefined ? convertSingleBracketToCurrencyFormat(item["Salary Bracket"], getCurrencyCodeRegex(userState.localInformation.currency), userState?.localInformation?.countryCurrencyCode, true).replace(/₹/g, 'Rs') : item["Salary Bracket"],
          Salary: item?.Salary !== "--" && item?.Salary !== undefined ? formatCurrency(item["Salary"])?.replace(/₹/g, 'Rs') : item?.Salary,
          "Member Type": item["Member Type"],
          "Organization Employee ID": item["Organization Employee ID"],
          "Organisation Location": item.Location,
          "Relationship With Employee": item["Relationship With Employee"],
          "Primary Employee": item["Primary Employee"],
          Client: item.Client,
          "Created At": date_time_format(item?.createdAt, user_type_dateFormat, tz),
          "Updated At": date_time_format(item?.updatedAt, user_type_dateFormat, tz),
        };
      }
      // const empStatusObj: any = employementStatusData && employementStatusData?.find((status: any) => status.label === item.status);
      // if (empStatusObj) {
      //   return {
      //     ...item, employement_status: empStatusObj?.value,
      //   };
      // }

      return item;
    });

    columns.forEach((item: any) => {
      item.field = item.headerName;
    })

    let dto = {
      headers: columns,
      data: updatedArr,
    };

    CLIENT_SERVICES.csvDownload(
      onSuccess,
      onError,
      dto,
      usertypeinfo.toLowerCase(),
      "member"
    );
  };

  return (
    <>
      {showLoader && (<NewLoader />)}
      <Grid container spacing={2}>
        <MassUpdate
          open_status={massupdateopen}
          value_update={updateMasterState}
          selected_id={array_delete}
          get_Api_url={EMPLOYEE_SERVICES.massModification}
          update_Api_url={EMPLOYEE_SERVICES.updateMassmodification}
          status={true}
        />
        {/* filter popup */}
        <Modal open={addFilterStatus} className="modalWrapper">
          <Box className="modalInner">
            <Grid container spacing={1}>
              <Grid xs={12}>
                <Link
                  className="close-button"
                  onClick={() => {
                    setAddFilterStatus(false);
                  }}
                />
                <h5 className="popup-heading">Add Filters</h5>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid xs={6}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Client ID
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={age}
                    label="Client ID"
                    onChange={(e) => handleChange(e, "age")}
                  >
                    <MenuItem value={"20"}>20 years</MenuItem>
                    <MenuItem value={"22"}>22 years</MenuItem>
                    <MenuItem value={"24"}>24 years</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid xs={6}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Policy Status
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={age}
                    label="Policy Status"
                    onChange={(e) => handleChange(e, "age")}
                  >
                    <MenuItem value={"20"}>20 years</MenuItem>
                    <MenuItem value={"22"}>22 years</MenuItem>
                    <MenuItem value={"24"}>24 years</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid xs={12}>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Client Name"
                  variant="outlined"
                  className="textarea"
                />
              </Grid>
              <Grid xs={6}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Status
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={age}
                    label="Designation"
                    onChange={(e) => handleChange(e, "age")}
                  >
                    <MenuItem value={"20"}>20 years</MenuItem>
                    <MenuItem value={"22"}>22 years</MenuItem>
                    <MenuItem value={"24"}>24 years</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid xs={6}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Status
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={age}
                    label="Status"
                    onChange={(e) => handleChange(e, "age")}
                  >
                    <MenuItem value={"20"}>20 years</MenuItem>
                    <MenuItem value={"22"}>22 years</MenuItem>
                    <MenuItem value={"24"}>24 years</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid
                xs={12}
                style={{ textAlign: "center" }}
                className="ctaBtn"
              >
                <Button
                  variant="contained"
                  className="submitBtn"
                  onClick={() => {
                    setAddFilterStatus(false);
                  }}
                >
                  Apply
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Modal>
        {/* Invite Employees Popup */}
        <Modal open={InviteEmployee} className="modalWrapper">
          <Box className="modalInner">
            <Grid container spacing={2}>
              <Grid xs={12}>
                <Link
                  className="close-button"
                  onClick={() => {
                    setInviteEmployee(false);
                  }}
                />
                <h5 className="popup-heading">Invite Employees</h5>
              </Grid>
            </Grid>
            <Grid container spacing={2} textAlign="center" className="mb-4">
              <Grid xs={12}>
                <RKTextField
                  class_name="inputField"
                  title="Email Address"
                  value={emailAddress}
                  value_update={updateMasterState}
                  attrName={setEmailAddress}
                  warn_status={false}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid
                xs={12}
                style={{ textAlign: "center" }}
                className="ctaBtn"
              >
                <Button
                  variant="contained"
                  className="submitBtn"
                  onClick={() => {
                    setInviteEmployee(false);
                  }}
                >
                  Invite
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Modal>
        <Modal open={addPolicyStatus} className="modalWrapper">
          <Box className="modalInner">
            <Grid container spacing={1}>
              <Grid xs={12}>
                <Link
                  className="close-button"
                  onClick={() => {
                    setAddPolicyStatus(false);
                  }}
                />
                <h5 className="popup-heading">Kudos !!</h5>
              </Grid>
            </Grid>
            <Grid container spacing={0} textAlign="center">
              <Grid xs={12} className="mb-5">
                <h1>Img</h1>
              </Grid>
              <Grid xs={12} className="mb-5">
                <h5 className="popup-heading">
                  Invitation has been sent successfully
                </h5>
              </Grid>
            </Grid>
          </Box>
        </Modal>

        <Grid xs={4} style={{ display: "flex", alignItems: "center" }}>
          <h3 className="mb-0 mr-5">Members</h3>
          <SearchBox value_update={updateMasterState} />
        </Grid>
        <Grid xs={8} className="text-right">
          <UGDateRangePicker
            maxDate={new Date()}
            minDate={new Date()}
            placement="bottom"
            value_update={updateMasterState}
          />
          {empolyeesCsvPermission && csvBody?.length > 0 && (
            <>
              <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClickCsv}
                className="blueBtn download"
                style={{ paddingLeft: 30, textTransform: "unset" }}
              >
                Download CSV
              </Button>
              <Menu
                id="simple-menu"
                anchorEl={anchorElCSV}
                keepMounted
                open={Boolean(anchorElCSV)}
                onClose={handleCloseCsv}
              >
                <MenuItem onClick={handleDownloadAll}>All Items</MenuItem>
                <MenuItem onClick={handleDownloadSelected}>Selected Items</MenuItem>
              </Menu>
            </>
          )}
          {empolyeesAddPermission && array_delete.length > 1 && (
            <Link
              underline="none"
              className="greenBtn edit"
              onClick={() => {
                setmassupdateopen(true);
              }}
            >
              Mass Update
            </Link>
          )}
          {/* <Link underline="none" className="blueBtn edit" onClick={edit_navigate}>
          Edit
        </Link> */}
          {/* <Link
          underline="none"
          className="blueBtn mail"
          onClick={() => {
            setInviteEmployee(true);
          }}
        >
          Invite Employees
        </Link> */}

          {empolyeesBulkUplodPermission && (
            <>
              <Button
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                className="blueBtn upload"
                sx={{
                  textTransform: "Capitalize",
                  padding: "4px 0px 4px 28px",
                  "&:hover": {
                    textDecoration: "none !important",
                    backgroundColor: "transparent !important",
                  },
                }}
              >
                Bulk Upload
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={() => { setAnchorEl(null) }}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem onClick={() => handleClose("Employee")}>
                  Employee
                </MenuItem>
                <MenuItem onClick={() => handleClose("Dependent")}>
                  Dependent
                </MenuItem>
              </Menu>
            </>
          )}
          {empolyeesAddPermission && (
            <Link
              underline="none"
              className="greenBtn addfile"
              onClick={() => {
                navigate(ALL_ROUTES.ADD_EMPLOYEE);
              }}
            >
              Add Member
            </Link>
          )}

          {/* {empolyeesRemovePermission && (
          <Link
            underline="none"
            className="redBtn delete"
            onClick={() => {
              setRemoveEmployee(true);
            }}
          >
            Remove
          </Link>
        )} */}
        </Grid>
        <Grid xs={12}>
          <UGTable
            header_data={columns}
            data={rows?.length > 0 ? rows : []}
            value_update={updateMasterState}
            attrName={"UGTable"}
            BE_filter_code={E_BE_FILTER_CODE.EMPLOYEE}
            refetch_data={table_data}
            totalRecords={totalRecords}
            setTableLimit={setTableLimit}
            setTableSkip={setTableSkip}
          />
        </Grid>

        <GlobalDeletePopup
          open_status={removeEmployee}
          value_update={updateMasterState}
          attrName={setRemoveEmployee}
          delete_step={deleteStatusStep}
          delete_item={() => {
            delete_employee("data", deleteStatusStep);
          }}
          ids={array_delete}
          title="Employee"
        />
        <GlobelEditPopup
          popupStatus={edit_popup}
          setPopupStatus={setEdit_popup}
          title={
            array_delete.length === 0
              ? "Select Employee Id"
              : array_delete.length > 1
                ? `Select Only One Id`
                : ""
          }
        />
      </Grid>
    </>
  );
};

export default Employees;
