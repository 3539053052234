import { Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { GridCloseIcon } from "@mui/x-data-grid";
import { useState } from "react";
import SlidingPanel from "react-sliding-side-panel";
import { toast } from "react-toastify";
import { AddTaxDTO } from "../../../../../../../Services/DTO/Settings/Organisation/TaxationDTO";
import { ApiType } from "../../../../../../../Services/Enum/ETaxation";
import { TAXATION_SERVICES } from "../../../../../../../Services/Settings/OrganisationSettings/TaxationServices";
import RKTextField from "../../../../../../../Supporting files/RKTextField/RKTextField";
import SelectDropdown from "../../../../../../../Supporting files/SelectDropdown/SelectDropdown";

interface Props {
  open_status: boolean;
  attrName: any;
  value_update: Function;
  refetch_data: Function;
  parentId: string;
  taxTypeOptions: Array<{}>;
}

const NewTaxPopup: React.FC<Props> = ({
  open_status,
  attrName,
  value_update,
  refetch_data,
  parentId,
  taxTypeOptions,
}) => {
  const [disableSave, setDisableSave] = useState<boolean>(false);
  const [taxname, setTaxName] = useState<string>("");
  const [rate, setRate] = useState<string>("");
  const [taxtype, setTaxType] = useState<string>("");
  const [taxnameWarn, setTaxNameWarn] = useState<boolean>(false);
  const [rateWarn, setRateWarn] = useState<boolean>(false);
  const [taxtypeWarn, setTaxTypeWarn] = useState<boolean>(false);
  const [taxtype1, setTaxType1] = useState<Array<{ label: string; id: string }>
  >([
    { id: "Standard Tax", label: "Standard Tax" },
    { id: "Surcharge", label: "Surcharge" },
  ]);

  const updateMasterState = (attrName: any, value: any) => {
    if (attrName === "setRate") {
      const val = value.replace(/[^0-9.]/g, '');
      if (val === "0") return setRate("");
      if (Number(val) >= 0 && Number(val) < 100) {
        setRate(val)
      }
    } else {
      attrName(value);
    }
  };

  const createNewTaxRate = () => {
    if (disableSave) return;
    const onSuccess = (res: any) => {
      toast.success("Added new tax rate");
      setTaxName("");
      setRate("");
      setTaxType("");
      setTaxNameWarn(false);
      setRateWarn(false);
      setTaxTypeWarn(false);
      refetch_data();
      value_update(attrName, false);
      setDisableSave(false);
    };

    const onError = (err: any) => {
      console.log(err);
      setDisableSave(false);
    };
    const data: AddTaxDTO = {
      APITYPE: ApiType.TaxList,
      id: parentId,
      taxList: {
        taxName: taxname,
        taxRate: rate,
        taxType: taxtype,
      },
    };
    let taxwarn = !taxname || taxname === "" ? true : false;
    let ratewarn = !rate || rate === "" ? true : false;
    let typewarn = !taxtype || taxtype === "" ? true : false;
    setTaxNameWarn(taxwarn);
    setRateWarn(ratewarn);
    setTaxTypeWarn(typewarn);

    if (taxwarn === false && ratewarn === false && typewarn === false) {
      setDisableSave(true);
      TAXATION_SERVICES.create_tax_rate(onSuccess, onError, data);
    }
  };

  return (
    <SlidingPanel
      type={"right"}
      isOpen={open_status}
      size={75}
    // backdropClicked={() => value_update(attrName, false)}
    >
      <div>
        <Grid container spacing={3} alignItems="center">
          <Grid xs={10}>
            <h4 className="mb-0 mt-4 ml-3">New Tax</h4>
          </Grid>
          <Grid xs={2} textAlign="end" className="mt-3">
            <GridCloseIcon
              onClick={() => {
                value_update(attrName, false);
                setTaxName("");
                setRate("");
                setTaxType("");
                setTaxNameWarn(false);
                setRateWarn(false);
                setTaxTypeWarn(false);
              }}
            />
          </Grid>
          <Grid xs={12} className="mb-5">
            <hr />
          </Grid>
        </Grid>
        <div className="scrollable_area">
          <Grid container spacing={3}>
            <Grid xs={12}>
              <RKTextField
                class_name="inputField"
                title={"Tax Name"}
                value={taxname}
                attrName={setTaxName}
                value_update={updateMasterState}
                warn_status={taxnameWarn}
                error_messg="Enter Tax Name"
              />
            </Grid>
            <Grid xs={12}>
              <RKTextField
                class_name="inputField"
                title={"Rate (%)"}
                value={rate}
                attrName={"setRate"}
                value_update={updateMasterState}
                warn_status={rateWarn}
                validation_type="numericfloatvalue"
                error_messg="Enter Rate %"
              />
            </Grid>
            <Grid xs={12}>
              <SelectDropdown
                class_name="inputField"
                title="Tax Type"
                value={taxtype}
                attrName={setTaxType}
                value_update={updateMasterState}
                dropdown_data={taxtype1}
                warn_status={taxtypeWarn}
                error_messg="Enter Tax Type"
              />
            </Grid>
          </Grid>
        </div>
        <Grid container spacing={3}>
          <Grid xs={12} className="ctaBtn mt-4">
            <Button
              variant="contained"
              className="submitBtn fixedbtn"
              disabled={disableSave}
              onClick={createNewTaxRate}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </div>
    </SlidingPanel>
  );
};

export default NewTaxPopup;
