// ########### REGISTER LOGIN LOGOUT #####################

export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const USERGET_SUCCESS = "USERGET_SUCCESS";

export const USER_LOGOUT = "USER_LOGOUT";

// ########### REGISTER LOGIN LOGOUT #####################

export const MODAL_TOGGEL_OPEN = "MODAL_TOGGEL_OPEN";
export const MODAL_TOGGEL_CLOSE = "MODAL_TOGGEL_CLOSE";
