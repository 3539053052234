import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import RKTextField from "../../../../../Supporting files/RKTextField/RKTextField";
import Grid from "@mui/material/Unstable_Grid2";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Link, Box, Tooltip } from "@mui/material";
import SelectDropdown from "../../../../../Supporting files/SelectDropdown/SelectDropdown";
import "../TaskDetail/TaskDetail.scss";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import CircleIcon from "@mui/icons-material/Circle";
import { Input } from "@material-ui/core";
import { ALL_ROUTES } from "../../../../../Routes/all_routes";

function TaskDetail() {
  const ariaLabel = { "aria-label": "description" };
  const navigate = useNavigate();
  const [taskName, setTaskName] = React.useState("");
  const [organizationName, setOrganizationName] = React.useState("");
  const [status, setStatus] = React.useState("started");
  const [priorityStatus, setPriorityStatus] = React.useState("Highest");
  const updateMasterState = (attrName: any, value: any) => {
    attrName(value);
  };
  const statusData = [
    { key: "started", value: "Started" },
    { key: "inqueue", value: "In Queue" },
    { key: "senttoinsurer", value: "Sent to Insurer" },
    { key: "upcomingrenewal", value: "Upcoming Renewal" },
    { key: "pendingwithinsurer", value: "Pending with Insurer" },
    { key: "endorsementrequestsent", value: "Endorsement Request Sent" },
    { key: "claimrequestsent", value: "Claim Request Sent" },
    { key: "completed", value: "Completed" },
  ];
  const prioritystatusData = [
    { key: "Highest", value: "Highest" },
    { key: "High", value: "High" },
    { key: "Lowest", value: "Lowest" },
    { key: "Low", value: "Low" },
    { key: "Normal", value: "Normal" },
  ];

  return (
    <Box>
      <Grid container spacing={3} className="headerSection">
        <Grid xs={6}>
          <h3 className="pageTitle mb-0 ">
            <Link
              onClick={() => {
                navigate(ALL_ROUTES.DASHBOARD);
              }}>
              <ChevronLeftIcon />
            </Link>
            Follow Up with client <span>Quotes-QT23</span>
          </h3>
        </Grid>
        <Grid xs={6} className="pr-4">
          <h3 className="right_pageTitle mb-0 ">#TSK123</h3>
        </Grid>
      </Grid>
      <div className="main_section">
        <Grid container spacing={3}>
          <Grid xs={2}>
            <div id={status}>
              <div className="status_dropdown">
                <SelectDropdown
                  class_name="inputFieldd"
                  title=""
                  value={status}
                  attrName={setStatus}
                  value_update={updateMasterState}
                  dropdown_data={statusData}
                  warn_status={false}
                />
              </div>
            </div>
          </Grid>
          <Grid xs={4}>
            <div id={priorityStatus}>
              <div
                className="status_dropdown"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "right",
                }}>
                <SelectDropdown
                  class_name="inputFieldd high"
                  title=""
                  value={priorityStatus}
                  attrName={setPriorityStatus}
                  value_update={updateMasterState}
                  dropdown_data={prioritystatusData}
                  warn_status={false}
                />
                {priorityStatus === "Highest" || priorityStatus === "High" ? (
                  <ArrowUpwardIcon id={priorityStatus} style={{ height: 16 }} />
                ) : priorityStatus === "Low" || priorityStatus === "Lowest" ? (
                  <ArrowDownwardIcon
                    id={priorityStatus}
                    style={{ height: 16 }}
                  />
                ) : priorityStatus === "Normal" ? (
                  <CircleIcon id={priorityStatus} style={{ height: 16 }} />
                ) : null}
              </div>
            </div>
          </Grid>
          <Grid xs={6} style={{ textAlign: "right" }}>
            <div className="assinee_section">
              <Tooltip title="Udit">
                <span className="asignee_tag orgBg">U</span>
              </Tooltip>

              <Tooltip title="Rahul">
                <span className="asignee_tag violetBg">R</span>
              </Tooltip>
              <Tooltip title="Add">
                <span className="asignee_tag grayBg">+</span>
              </Tooltip>
            </div>
          </Grid>
          <Grid xs={6}>
            <div className="left_section">
              <Grid container spacing={2} style={{ padding: "0" }}>
                <Grid xs={12}>
                  <h3>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book. It has survived not only five centuries.
                  </h3>
                </Grid>
                <Grid xs={12} className="subtask">
                  <h6>Subtasks</h6>
                  <ul>
                    <li>
                      Connect on call <Link className="delete_icon"></Link>
                    </li>
                    <li>
                      Send an email<Link className="delete_icon"></Link>
                    </li>
                    <li>
                      sub-task name<Link className="delete_icon"></Link>
                    </li>
                  </ul>
                </Grid>
                <Grid xs={12}>
                  <RKTextField
                    class_name="inputField"
                    title="Task 3"
                    value={taskName}
                    attrName={setTaskName}
                    value_update={updateMasterState}
                    warn_status={false}
                  />
                </Grid>
                <Grid xs={9} className="attachments">
                  <h6>Attachments</h6>
                </Grid>
                <Grid xs={3} style={{ textAlign: "right" }}>
                  <Link underline="none" className="greenBtn addfile">
                    Add
                  </Link>
                </Grid>
                <Grid xs={12}>
                  <div className="img_section">
                    <div className="inner_section">
                      <img
                        src="./images/task_detail_img.svg"
                        alt=""
                        className="task_img"
                        height={"71px"}
                      />
                      <span>image.png</span>
                      <div className="bottom_img">
                        <img
                          src="./images/task_download_icon.svg"
                          alt=""
                          height={"20px"}
                        />
                        <img
                          src="./images/delete_icons.svg"
                          alt=""
                          className="delete_icon"
                          height={"20px"}
                        />
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid xs={6}>
            <div className="right_section">
              <Grid container spacing={2} style={{ padding: "0" }}>
                <Grid xs={12}>
                  <div className="scrolll_section">
                    <Grid container spacing={2} style={{ padding: "0" }}>
                      <Grid xs={6} className="date_section">
                        <h6>Created:</h6>
                        <p>Jun 17 2021, 3:44 pm</p>
                      </Grid>
                      <Grid
                        xs={6}
                        className="date_section"
                        style={{ textAlign: "right" }}>
                        <h6>Last Updated</h6>
                        <p>Jun 20 2021, 06:44 am</p>
                      </Grid>
                      <Grid xs={12}>
                        <hr />
                      </Grid>
                      <Grid xs={8} className="task_title">
                        <h6>Rahul created this task</h6>
                      </Grid>
                      <Grid xs={4} className="task_date">
                        <h6>Jun 20 2021, 06:44 am</h6>
                      </Grid>
                      <Grid xs={8} className="task_title">
                        <h6>
                          Rahul created subtask: Previous Policy Expiry Date
                          should only be visible if the two-wheeler is old so an
                          additional checkbox to be added for new two-wheeler
                          policy creation which will hide previous policy date
                          if selected
                        </h6>
                      </Grid>
                      <Grid xs={4} className="task_date">
                        <h6>Jun 21 2021, 06:44 am</h6>
                      </Grid>
                      <Grid xs={8} className="task_title">
                        <h6>Rahul assigned to udit jaswal</h6>
                      </Grid>
                      <Grid xs={4} className="task_date">
                        <h6>Jun 22 2021, 06:44 am</h6>
                      </Grid>
                    </Grid>
                  </div>
                  <div className="comment_section">
                    <Grid container spacing={2} style={{ padding: "0" }}>
                      <Grid xs={12}>
                        <hr />
                      </Grid>
                      <Grid xs={12}>
                        <Input
                          placeholder="add your comment here"
                          inputProps={ariaLabel}
                        />
                      </Grid>
                    </Grid>
                  </div>
                  {/* <Grid xs={6} className="date_section">
                  <h6>Created:</h6>
                  <p>Jun 17 2021, 3:44 pm</p>
                </Grid>
                <Grid
                  xs={6}
                  className="date_section"
                  style={{ textAlign: "right" }}
                >
                  <h6>Last Updated</h6>
                  <p>Jun 20 2021, 06:44 am</p>
                </Grid>
                <Grid xs={12}>
                  <hr />
                </Grid>
                <Grid xs={8} className="task_title">
                  <h6>Simran Deep created this task</h6>
                </Grid>
                <Grid xs={4} className="task_date">
                  <h6>Jun 20 2021, 06:44 am</h6>
                </Grid>
                <Grid xs={8} className="task_title">
                  <h6>
                    Simran Deep created subtask: Previous Policy Expiry Date
                    should only be visible if the two-wheeler is old so an
                    additional checkbox to be added for new two-wheeler policy
                    creation which will hide previous policy date if selected
                  </h6>
                </Grid>
                <Grid xs={4} className="task_date">
                  <h6>Jun 21 2021, 06:44 am</h6>
                </Grid>
                <Grid xs={8} className="task_title">
                  <h6>Simran Deep assigned to udit jaswal</h6>
                </Grid>
                <Grid xs={4} className="task_date">
                  <h6>Jun 22 2021, 06:44 am</h6>
                </Grid>*/}
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </div>
    </Box>
  );
}

export default TaskDetail;
