import Grid from "@mui/material/Unstable_Grid2";
import { useEffect,useState } from "react";
import { CUSTOME_MODULE_SERVICES } from "../../../../../Services/customModule/customeModuleServices";
import { titleCase } from "../../../../../Supporting files/HelpingFunction";
import { getUrlParam } from "../CustomModuleV2/helpers";

const Summary = (modulepicklist,currentTab) => {
  const [summaryData, setSummaryData] = useState([{
    system: "",
    custom: "",
    totalFields: ""
  }]);
  const [tabType, settabType] = useState('');
  const param = getUrlParam("layout");
  useEffect(() => {
    const layoutid = localStorage.getItem("layoutid");
    const param = getUrlParam("layout");
    settabType(param)
    get_summary_details(layoutid,param);
  }, [modulepicklist,currentTab])
   
  //get stages and status
  const get_summary_details =  (id,type) => {
    const onSuccess = (res) => {
        setSummaryData(res.data.data[0])
    };
    const onError = (err) => {
      console.log("err", err);
    };
    CUSTOME_MODULE_SERVICES.get_summary_deatils(onSuccess, onError,id,type);
  };

  


  return (
    <>
      <Grid xs={11}>
        <h5>Summary of the necessary information about this module.</h5>
      </Grid>

      <Grid xs={10} lg={8} className="summaryBox">
        <label>Primary</label>

        <ul>
          <li>
            <div>Module</div> : <div> {titleCase(tabType)} </div>
          </li>

          <li>
            <div>Displayed as</div> :<div> To show Genereated {titleCase(tabType)}</div>
          </li>
        </ul>
      </Grid>

      <Grid xs={11} lg={8} className="summaryBox">
        <label>Customization</label>

       {summaryData ?  <ul>
          <li>
            <div>Module Permission</div> :<div> Administrator</div>
          </li>

          <li>
            <div>Fields</div> :
            <div>
              <ul>
                <li>
                  <span>System:</span><span> {summaryData.system} </span>
                </li>

                <li>
                  <span>Custom:</span><span> {summaryData.custom  === undefined ? '' :summaryData.custom } </span>
                </li>
                <li>
                  <span>Total:</span> <span> {summaryData.totalFields} </span>
                </li>
              </ul>
            </div>
          </li>

          <li>
            <div>Layout</div> : <div>{summaryData.custom === 0 ? "Standard": "Custom"}  </div>
          </li>
        </ul> : null}
      </Grid>
    </>
  );
};

export default Summary;
