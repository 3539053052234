import { EMPLOYER_URL_CONSTANTS } from "../../../APIVar/EmployerUrlConstant";

import { DeleteAPI, GetAPI, PatchAPI, PostAPI } from "../../API";

const get_policies_list = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
  limit: number,
  skip: number,
  startDate: any,
  endDate: any,
  searchBarFilter: any
) => {
  GetAPI.call(
    `${EMPLOYER_URL_CONSTANTS.GET_POLICIES_LIST}limit=${limit}&skip=${skip}&startDate=${startDate}&endDate=${endDate}&searchValue=${searchBarFilter}`,
    onSuccess,
    {},
    onError
  );
};

// detail view page api
const get_policy_detail_view = (
  url: string,
  onSuccess: (data: any) => void,
  onError: (err: any) => void
) => {
  GetAPI.call(url, onSuccess, {}, onError);
};



export const EMPLOYER_POLICIES_SERVICES = {
  get_policies_list,
  get_policy_detail_view,
};
