import CheckIcon from "@mui/icons-material/Check";
import EditIcon from "@mui/icons-material/Edit";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box, Link, Tooltip } from "@mui/material";
import React, { useRef, useState } from "react";
import SelectDropdown from "../SelectDropdown/SelectDropdown";
interface Props {
  title: string;
  value: string;
  attrName: any;
  value_update: Function;
  dropdown_data: Array<any>;
  warn_status?: boolean;
  class_name?: string;
  error_messg?: string;
  info_status?: boolean;
  required?: boolean;
  info_message?: string;
  params?: any;
  id?: string;
  disabled?: boolean;

}

const SelectDropdownDisable: React.FC<Props> = ({
  title,
  value,
  value_update,
  attrName,
  dropdown_data,
  warn_status,
  class_name,
  error_messg,
  info_status,
  required,
  disabled,
  params,
  id,
  info_message,
}) => {
  const [disbaleToggle, setDisableToggle] = useState<boolean>(false);

  const updateMasterState = (attrName: any, value: any) => {
    value_update(attrName, value);
  };
  const [focus, setFocus] = useState<boolean>(false);
  const refinput = useRef(null);
  const edit = () => {
    setDisableToggle(!disbaleToggle);
    setFocus(true);
    if (disbaleToggle === false) {
      // const input = document.getElementById(value);
      // console.log(input);
      // input?.focus();
    }
    if (disbaleToggle === true) {
      params(attrName);
    }
  };

  return (
    <Box className="individualEdit">
      {disbaleToggle === false ? (
        <div
          className={
            value?.length === 0 ? "staticField nullValue" : "staticField"
          }
        >
          <label>{title}</label>
          <p>{value}</p>
        </div>
      ) : (
        <SelectDropdown
          class_name={class_name}
          title={title}
          value_update={updateMasterState}
          value={value}
          attrName={attrName}
          dropdown_data={dropdown_data}
          warn_status={warn_status}
          required={required}
          error_messg={error_messg}
          info_status={info_status}
          info_message={info_message}
            disabled={disabled ? true : false}
        />
      )}
      {info_status === true ? (
        <Tooltip title={info_message === undefined ? "" : info_message}>
          <span className="infoMessage">
            <InfoOutlinedIcon />
          </span>
        </Tooltip>
      ) : null}
      {!disabled && (
      <Link
        className={disbaleToggle === true ? "editUpdate visible" : "editUpdate"}
        onClick={edit}
      >
        {disbaleToggle === false ? <EditIcon /> : <CheckIcon />}
      </Link>
      )}
    </Box>
  );
};

export default SelectDropdownDisable;
