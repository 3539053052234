import React, { useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import "./DoctorOnline.scss";
import FAQ from "../Support/FAQ/FAQ";
import { Button } from "@mui/material";
import TextArea from "../../../../Supporting files/TextArea/TextArea";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const DoctorOnline = () => {
  const benefits_consult = [
    {
      heading: "Consult Top Doctors 24x7",
    },
    {
      heading: "Convenient and Easy",
    },
    {
      heading: "100% Safe Consultations",
    },
    {
      heading: "Similar Clinic Experience",
    },
    {
      heading: "Free Follow-up",
    },
  ];

  const faqs = [
    {
      expanded: "panel1",
      title: "What is online doctor consultation?",
      description:
        "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Laudantium reiciendis cum aliquam fugit vero voluptas, rerum iure natus illum odio.",
    },
    {
      expanded: "panel2",
      title: "Are your online doctors qualified?",
      description:
        "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Laudantium reiciendis cum aliquam fugit vero voluptas, rerum iure natus illum odio.",
    },
    {
      expanded: "panel3",
      title: "Is online doctor consultation safe and secured on BEE?",
      description:
        "The privacy of our patients is critical to us, and thus, we are compliant with industry standards like ISO 27001. Rest assured that your online consultation with a doctor is completely safe and secured with 256-bit encryption.",
    },
  ];
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1150 },
      items: 5,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1149, min: 464 },
      items: 3,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  const doctor_responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1150 },
      items: 1,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1149, min: 464 },
      items: 3,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  const consult_specialities = [
    {
      img_url: "./images/consult_specialities_img1.svg",
      heading: "Gynaecology",
      desc: "Consult Now",
    },
    {
      img_url: "./images/consult_specialities_img2.svg",
      heading: "Pediatrics",
      desc: "Consult Now",
    },
    {
      img_url: "./images/consult_specialities_img3.svg",
      heading: "General physician",
      desc: "Consult Now",
    },
    {
      img_url: "./images/consult_specialities_img4.svg",
      heading: "Dermatology",
      desc: "Consult Now",
    },
    {
      img_url: "./images/consult_specialities_img5.svg",
      heading: "Psychiatry",
      desc: "Consult Now",
    },
    {
      img_url: "./images/consult_specialities_img1.svg",
      heading: "Gynaecology",
      desc: "Consult Now",
    },
    {
      img_url: "./images/consult_specialities_img2.svg",
      heading: "Pediatrics",
      desc: "Consult Now",
    },
    {
      img_url: "./images/consult_specialities_img3.svg",
      heading: "General physician",
      desc: "Consult Now",
    },
  ];

  const our_doctors = [
    {
      img_url: "./images/our_doctor_img.svg",
      years: "15 Years Exp.",
      name: "Dr. Santosh Bhagwat Raut",
      title: "Dermatologist, Cosmetologist",
      desc: "1,856 consults done",
    },
    {
      img_url: "./images/our_doctor_img.svg",
      years: "15 Years Exp.",
      name: "Dr. Abhilash Kumar",
      title: "Dermatologist, Cosmetologist",
      desc: "1,556 consults done",
    },
    {
      img_url: "./images/our_doctor_img.svg",
      years: "15 Years Exp.",
      name: "Dr. Sumit Aggarwal",
      title: "Dermatologist, Cosmetologist",
      desc: "1,056 consults done",
    },
    {
      img_url: "./images/our_doctor_img.svg",
      years: "15 Years Exp.",
      name: "Dr. Sajal Chauhan",
      title: "Dermatologist, Cosmetologist",
      desc: "1,126 consults done",
    },
  ];
  const [reasonForSupport, setReasonForSupport] = React.useState("");

  const updateMasterState = (attrName: any, value: any) => {
    attrName(value);
  };

  return (
    <Grid xs className="doctorOnline_wrapper">
      <Grid container spacing={3}>
        <Grid xs={8}>
          <Grid container spacing={3} sx={{ padding: "0" }}>
            <Grid xs={12} className="main_heading mb-10">
              <h5>
                Embrace the
                <span> Digital Age of Healthcare</span>
              </h5>
              <p>
                We provide virtual doctor consultations with experienced
                physicians.
              </p>
            </Grid>
            <Grid xs={12} className="mb-10">
              <div className="employee_list">
                <ul className="employee_list_inner">
                  <li>
                    <img
                      src="./images/employermother_img.svg"
                      alt=""
                      height={50}
                    />
                  </li>
                  <li>
                    <img
                      src="./images/employerson_img.svg"
                      alt=""
                      height={50}
                    />
                  </li>
                  <li>
                    <img
                      src="./images/employerfather_img.svg"
                      alt=""
                      height={50}
                    />
                  </li>
                </ul>
                <h5>86+ Doctors are online</h5>
                <span className="online"></span>
              </div>
              <div className="doctor_list">
                <ul className="doctor_list_inner">
                  <li className="verified">Verified Doctors</li>
                  <li className="digital">Digital Prescription</li>
                  <li className="followup">Free Followup</li>
                </ul>
              </div>
            </Grid>
            <Grid xs={12}>
              <div className="consult_specialities">
                <h5>
                  26+ Specialities{" "}
                  <span>Consult with top doctors across specialities</span>
                </h5>
                <Carousel
                  swipeable={true}
                  draggable={true}
                  showDots={true}
                  responsive={responsive}
                  infinite={true}
                  autoPlay={true}
                  autoPlaySpeed={3000} // Adjust the speed as needed (in milliseconds)
                >
                  {consult_specialities.map((data, index) => (
                    <div>
                      <Grid container spacing={3}>
                        <Grid xs={12} sx={{ paddingLeft: "6px" }}>
                          <div className="choose-sec">
                            <img src={data.img_url} />
                            <h5>{data.heading}</h5>
                            <p>{data.desc}</p>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  ))}
                </Carousel>
              </div>
            </Grid>
            <Grid xs={12}>
              <div className="benefits_consult">
                <h5>
                  Benefits of <span>Online Consultation</span>
                </h5>
                <ul className="benefits_list">
                  {benefits_consult.map((data, index) => (
                    <li>{data.heading}</li>
                  ))}
                </ul>
              </div>
            </Grid>
            <Grid xs={12}>
              <div className="faq">
                <h5>FAQs</h5>

                {faqs.map((data, index) => {
                  return (
                    <FAQ
                      data={{
                        description: data.description,
                        expanded: data.expanded,
                        title: data.title,
                      }}
                    />
                  );
                })}
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={4}>
          <Grid container spacing={3} sx={{ padding: "0" }}>
            <Grid xs={12} className="our_doctors">
              <h5>
                Our Doctors <span>Private consultation + Audio call</span>
              </h5>
              <Carousel
                swipeable={true}
                draggable={true}
                showDots={true}
                responsive={doctor_responsive}
                infinite={true}
                autoPlay={true}
                autoPlaySpeed={3000} // Adjust the speed as needed (in milliseconds)
              >
                {our_doctors.map((data, index) => (
                  <div>
                    <Grid container spacing={3}>
                      <Grid xs={12}>
                        <div className="doctor_carousel">
                          <img src={data.img_url} />
                          <h6>{data.years}</h6>
                          <h5>
                            {data.name} <span>{data.title}</span>
                            <span>{data.desc}</span>
                          </h5>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                ))}
              </Carousel>
            </Grid>
            <Grid xs={12}>
              <div className="health_concern">
                <h5>
                  Still delaying your health concerns?{" "}
                  <span>Consult with a Doctor</span>
                </h5>
                <ul className="health_concernList">
                  <li>Are you addicted to something?</li>
                  <li>Do you actively work-out?</li>
                </ul>
              </div>
            </Grid>
            <Grid xs={12}>
              <TextArea
                class_name="inputField multiline"
                title={"Tell us your symptom or health problem"}
                value={reasonForSupport}
                attrName={setReasonForSupport}
                value_update={updateMasterState}
                warn_status={false}
              />
            </Grid>
            <Grid xs={12} className="ctaBtn">
              <Button
                variant="contained"
                className="submitBtn"
                onClick={() => {}}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DoctorOnline;
