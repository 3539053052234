import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Box, Button, Link, Modal } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import PoliciesWarningpopup from "../../../../../../Components/Broker/WarningForPlicies/PoliciesWarningpopup";
import NewLoader from "../../../../../../Components/NewLoader/NewLoader";
import { ALL_ROUTES } from "../../../../../../Routes/all_routes";
import { E_BE_FILTER_CODE } from "../../../../../../Services/Enum/E_UGTable";
import { BRANCHES_SERVICES } from "../../../../../../Services/customModule/branchesServices";
import { RootState } from "../../../../../../Store/Store";
import { useAppSelector } from "../../../../../../Store/hooks";
import { date_time_format } from "../../../../../../Supporting files/HelpingFunction";
import SelectDropdown from "../../../../../../Supporting files/SelectDropdown/SelectDropdown";
import UGTable from "../../../../../../Supporting files/UGTable/UGTable";
import "../../OrganisationSettings/Currencies/Currencies.scss";

const Branches = () => {
  const user_type_dateFormat = useAppSelector(
    (state) => state.userDetailsSlice.localInformation.dateFormat
  );
  const [warningpopup, setwarningpopup] = useState<boolean>(false);
  const tz = useAppSelector(
    (state: RootState) => state.userDetailsSlice.localInformation.timeZone
  );
  const [deleteStatus, setDeleteStatus] = useState(false);
  const [branchList, setBranchList] = useState([]);
  const [array_delete, setArray_Delete] = useState<Array<{ id: number }>>([]);
  const [deleteStatusStep, setDeleteStatusStep] = useState(1);
  const [editStatus, setEditStatus] = useState(false);
  const [isBranchAssigned, setIsBranchAssigned] = useState(false);
  const [tableLimit, setTableLimit] = useState();
  const [tableSkip, setTableSkip] = useState();
  const navigate = useNavigate();
  const { BROKER } = useAppSelector((state) => state.permissionSlice);
  const [branchAddPermission, setbranchAddPermission] = useState<any>(null);
  const [branchEditPermission, setbranchEditPermission] = useState<any>(null);
  const [branchRemovePermission, setbranchRemovePermission] = useState<any>(null);
  const [branchStatusPermission, setbranchStatusPermission] = useState<any>(null);

  //Table Data
  const [columns, setColumns] = useState<any>([]);
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    setShowLoader(true);
    get_status(BROKER?.Branches?.status === undefined
      ? false : BROKER?.Branches?.status);
    setbranchAddPermission(
      BROKER?.Branches?.add === undefined
        ? false : BROKER?.Branches?.add
    );
    setbranchEditPermission(
      BROKER?.Branches?.edit === undefined
        ? false : BROKER?.Branches?.edit
    );
    setbranchRemovePermission(
      BROKER?.Branches?.remove === undefined
        ? false : BROKER?.Branches?.remove
    );
    setbranchStatusPermission(
      BROKER?.Branches?.status === undefined
        ? false : BROKER?.Branches?.status
    );
  }, []);

  const updateMasterState = (attrName: any, value: any) => {
    if (attrName === "selected_data") {
      setArray_Delete(value);
    } else if (attrName[0] === "updatestatus") {
      isBeingUsed(attrName[1]._id, value);
    }
  };


  //get Branches list data
  const get_branch_list_data = () => {
    const onSuccess = (res: any) => {
      if (res) {
        setTimeout(() => {
          setShowLoader(false);
        }, 1500);
      }

      setBranchList(res.data.data);
    };
    const onError = (err: any) => {
      console.log("Branches", err);
    };
    BRANCHES_SERVICES.get_branch_list_data(onSuccess, onError);
  };

  const edit_navigate = () => {
    if (array_delete.length === 0) {
      setEditStatus(true);
    } else if (array_delete.length > 1) {
      setEditStatus(true);
    } else {
      navigate(`${ALL_ROUTES.EDIT_BRANCH}?id=${array_delete[0]}`);
    }
  };

  const deleteditem = () => {
    setDeleteStatusStep(1);
    setDeleteStatus(false);
  };

  //delete Branches list
  const delete_branch_list_data = () => {
    let body: any = { ids: array_delete };
    const onSuccess = (res: any) => {
      setDeleteStatusStep(2);
    };
    const onError = (err: any) => {
      console.log("Branches", err);
    };
    BRANCHES_SERVICES.delete_branches(body, onSuccess, onError);
  };

  //isBeingUsed somewhere
  const isBeingUsed = (id: string, value: any) => {
    const onSuccess = (res: any) => {
      if (res.data.data.is_exist) {
        toast.warning("This Branch is being used,Can't Inactive it. ");
      } else {
        update_status(value, id);
      }
    };
    const onError = (err: any) => {
      console.log("isBeingUsed", err);
    };
    BRANCHES_SERVICES.isBeingUsed(onSuccess, onError, id);
  };

  useEffect(() => {
    get_branch_list_data();
  }, [deleteStatus]);

  const get_status = (statusPermission:any) => {
    const onSuccess = (res: any) => {
      let statusData = res.data.data.map((data: any) => ({
        label: data.value,
        value: data.label,
      }));
      setColumns([
        {
          field: "branchId",
          headerName: "Branch ID",
          width: 200,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return (
              <p
                style={{
                  cursor: "pointer",
                  fontWeight: "400",
                  color: "#6b62dc",
                  textDecoration: "none",
                }}
                onClick={() => {
                  navigate(`${ALL_ROUTES.EDIT_BRANCH}?id=${row._id}`);
                }}
              >
                {row["branchId"]}
              </p>
            );
          },
        },
        {
          field: "locationName",
          headerName: "Location Name",
          width: 300,
          check_status: true,
        },
        {
          field: "locationType",
          headerName: "Location Type",
          width: 300,
          check_status: true,
        },
        {
          field: "status",
          headerName: "Status",
          minWidth: 200,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row, index } = ValueFormatterParams;
            return (
              <div id={`_${row.status}`}>
                <div className="status_dropdown" id={`_${row.status}`}>
                  {row.status === 7 ? (
                    <CheckCircleIcon id={`_${row.status.toString()}`} />
                  ) : row.status === 8 ? (
                    <HighlightOffIcon id={`_${row.status.toString()}`} />
                  ) : null}
                  <SelectDropdown
                    class_name="inputFieldd"
                    title=""
                    value={row.status}
                    attrName={["updatestatus", row, index]}
                    value_update={updateMasterState}
                    dropdown_data={statusData}
                    warn_status={false}
                    disabled={!statusPermission}
                  />
                </div>
              </div>
            );
          },
        },
        {
          field: "building",
          headerName: "Address",
          width: 600,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return (
              <p>
                {row.building} {row.area} {row.state} {row.city} {row.pinCode}
                {" - "}
                {row.country}
              </p>
            );
          },
        },
        {
          field: "createdAt",
          headerName: "Created on",
          width: 200,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return (
              <span>
                {date_time_format(row.createdAt, user_type_dateFormat, tz)}
              </span>
            );
          },
        },
        {
          field: "updatedAt",
          headerName: "updated on",
          width: 200,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return (
              <span>
                {date_time_format(row.updatedAt, user_type_dateFormat, tz)}
              </span>
            );
          },
        },
      ]);
    };

    const onError = (err: any) => {
      console.log("err", err);
    };

    BRANCHES_SERVICES.get_status(onSuccess, onError);
  };

  const update_status = (value: number, id: string) => {
    const onSuccess = (res: any) => {
      get_branch_list_data();
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    BRANCHES_SERVICES.update_status(
      {
        value: value,
      },
      onSuccess,
      onError,
      id
    );
  };

  useEffect(() => {
    let branchAssigned = false;

    array_delete?.forEach((item: any, index: number) => {
      const onSuccess = (res: any) => {
        if (!branchAssigned) {
          branchAssigned = res.data.data.is_exist;
          setIsBranchAssigned(branchAssigned);
        }
      };

      const onError = (err: any) => {
        console.log("false", err);
      };

      if (!branchAssigned) {
        BRANCHES_SERVICES.isBeingUsed(onSuccess, onError, item);
      }
    });
  }, [array_delete]);

  return (
    <>
      {showLoader ? (
        <NewLoader />
      ) : (
        <Grid container spacing={2} className="currunciesWrapper">
          <Grid xs={4}>
            <h3 className="mb-0">Branches</h3>
          </Grid>

          <Grid xs={8} className="text-right">
            <div className="buttons">
              {branchAddPermission &&
                <Link
                  underline="none"
                  className="greenBtn addfile"
                  onClick={() => {
                    navigate(ALL_ROUTES.ADD_BRANCH);
                  }}
                >
                  Add
                </Link>}
              {branchEditPermission && <Link
                underline="none"
                className="blueBtn edit"
                onClick={edit_navigate}
              >
                Edit
              </Link>}

              {branchRemovePermission && <Link
                underline="none"
                className={
                  isBranchAssigned ? `redBtnlight delete` : `redBtn delete`
                }
                onClick={() => {
                  isBranchAssigned
                    ? setwarningpopup(true)
                    : setDeleteStatus(true);
                }}
              >
                Remove
              </Link>}
            </div>
          </Grid>
          <Grid xs={12} sx={{ width: "100%" }}>
            <div style={{ height: 400 }}>
              <UGTable
                header_data={columns}
                data={branchList}
                value_update={updateMasterState}
                attrName={"UGTable"}
                BE_filter_code={E_BE_FILTER_CODE.BRANCHES}
                refetch_data={get_branch_list_data}
                setTableLimit={setTableLimit}
                setTableSkip={setTableSkip}
                paginationMode="client"
                class_Name={"th-setting"}
              />
            </div>
          </Grid>
          <PoliciesWarningpopup
            popupStatus={warningpopup}
            setPopupStatus={setwarningpopup}
            title={`One of the Brach is assigned to the user, so you will not be able to delete that Branch`}
          />
          {/* Remove pop up */}
          <Modal open={deleteStatus} className="modalWrapper">
            <>
              {deleteStatusStep === 1 ? (
                <Box className="modalInner deletepopup">
                  <Grid container spacing={1}>
                    <Grid xs={12} sx={{ width: "100%" }}>
                      <Link
                        className="close-button"
                        onClick={() => setDeleteStatus(false)}
                      />
                      <div className="img-prt">
                        <img
                          src="./images/deletepopup_deleteicon.svg"
                          alt=""
                          className="img"
                        />
                      </div>
                      {array_delete?.length === 0 ? (
                        ""
                      ) : (
                        <h5 className="popup-heading">
                          Are you sure you want
                          <br /> to delete this?
                        </h5>
                      )}
                    </Grid>
                    <Grid xs={12} sx={{ width: "100%" }}>
                      {/* <Link className="md-link mb-10" underline="none">
                    {array_delete?.length === 0
                      ? "Please Select the branch"
                      : `You are selected ${array_delete?.length} to delete`}
                  </Link> */}
                      {array_delete?.length === 0 ? (
                        <Link className="md-link mb-10" underline="none">
                          Please Select the branch
                        </Link>
                      ) : null}
                    </Grid>
                    {array_delete?.length === 0 ? (
                      ""
                    ) : (
                      <Grid
                        xs={12}
                        sx={{ width: "100%" }}
                        className="ctaBtn"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Button
                          variant="contained"
                          className="submitBtn"
                          onClick={delete_branch_list_data}
                        >
                          Yes, Delete
                        </Button>
                        <Button
                          onClick={() => setDeleteStatus(false)}
                          variant="contained"
                          className="cancelBtn"
                        >
                          cancel
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                </Box>
              ) : deleteStatusStep === 2 ? (
                <Box className="modalInner deletepopup">
                  <Grid container spacing={1}>
                    <Grid xs={12} sx={{ width: "100%" }}>
                      <Link
                        className="close-button"
                        onClick={() => setDeleteStatus(false)}
                      />
                      <div className="img-prt">
                        <img
                          src="./images/deletePopup_tickicon.svg"
                          alt=""
                          className="img"
                        />
                      </div>
                      <h5 className="popup-heading">Deleted Successfully!</h5>
                    </Grid>
                    <Grid xs={12} sx={{ width: "100%" }}>
                      <Link className="md-link mb-10" underline="none">
                        Your Branch has been Deleted
                      </Link>
                    </Grid>
                    <Grid
                      xs={12}
                      sx={{ width: "100%" }}
                      className="ctaBtn"
                      style={{ textAlign: "center" }}
                    >
                      <Button
                        //onClick={deleteditem}
                        variant="contained"
                        className="submitBtn"
                        onClick={deleteditem}
                      >
                        Okay
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              ) : null}
            </>
          </Modal>

          {/* Remove pop up */}
          <Modal open={editStatus} className="modalWrapper">
            <>
              <Box className="modalInner deletepopup">
                <Grid container spacing={1}>
                  <Grid xs={12} sx={{ width: "100%" }}>
                    <Link
                      className="close-button"
                      onClick={() => setEditStatus(false)}
                    />
                    <Link className="md-link mb-10" underline="none">
                      <div className="img-prt">
                        <img
                          src="./images/deletepopup_edit_icon.svg"
                          alt=""
                          className="img"
                        />
                      </div>
                      {array_delete?.length === 0
                        ? "Please Select the branch"
                        : `You can't select ${array_delete?.length} branches to edit`}
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            </>
          </Modal>
        </Grid>
      )}
    </>
  );
};

export default Branches;
