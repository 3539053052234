import { EMPLOYEE_URL_CONSTANTS } from "../../APIVar/EmployeeUrlConstant";
import { GetAPI, PatchAPI } from "../API";

const get_emp_enrolment_policies = (onSuccess: any, onError: any) => {
  GetAPI.call(
    EMPLOYEE_URL_CONSTANTS.get_claim_assistant,
    onSuccess,
    {},
    onError
  );
};


const submit_doc_endorsment_claim = (
  dto: any,
  onSuccess: (data: any) => void,
  { },
  onError: (err: any) => void,
  id: string,
  type: string,
) => {
  PatchAPI.call(
    EMPLOYEE_URL_CONSTANTS.submit_doc_endorsment_claim(id, type),
    dto,
    onSuccess,
    onError
  );
};


export const Claim_ASSISTANT = {
  get_emp_enrolment_policies,
  submit_doc_endorsment_claim,
};
