import { URL_CONSTANTS } from "../../../APIVar/URLConstants";
import { GetAPI, PatchAPI, PostAPI } from "../../API";

const getEmailData = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
  userId: string,
  settingId: string
) => {
  PatchAPI.call(
    `${URL_CONSTANTS.get_email_data(userId, settingId)}`,
    {},
    onSuccess,
    {},
    onError
  );
};

const getEmailDropDown = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void
) => {
  GetAPI.call(URL_CONSTANTS.get_email_dropdown_data(), onSuccess, {}, onError);
};
const getConfigurationDetails = (
  id: any,
  onSuccess: (data: any) => void,
  onError: (err: any) => void
) => {
  GetAPI.call(
    URL_CONSTANTS.get_email_dropdown_data_details(id),
    onSuccess,
    {},
    onError
  );
};
const updateMailConfig = (
  
  data: any,
  onSuccess: (data: any) => void,
  onError: (err: any) => void
) => {
  PostAPI.call(
    URL_CONSTANTS.update_mail_config_settings,
    data,
    onSuccess,
    onError
  );
};

export const GENERAL_SERVICES = {
  getEmailData,
  getEmailDropDown,
  getConfigurationDetails,
  updateMailConfig,
};
