import { EMPLOYEE_URL_CONSTANTS } from "../../APIVar/EmployeeUrlConstant";
import { URL_CONSTANTS } from "../../APIVar/URLConstants";
import { DeleteSingleAPI, GetAPI, PatchAPI, PostAPI } from "../API";

const create_family_member = (dto: any, onSuccess: any, onError: any, user: string) => {
  PostAPI.call(URL_CONSTANTS.create_nominee(user), dto, onSuccess, {}, onError);
};

// _family_detail_list

const get_family_detail_list = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
  id: string,
  userType: string
) => {
  GetAPI.call(
    `${URL_CONSTANTS.get_family_detail(id, userType)}`,
    onSuccess,
    onError
  );
};
// common service for the employee and broker portal
const create_family_detail = (
  dto: any,
  onSuccess: any,
  onError: any,
  usertypeinfo: any
) => {
  PostAPI.call(
    URL_CONSTANTS.create_family_detail(usertypeinfo),
    dto,
    onSuccess,
    {},
    onError
  );
};

const update_family_detail = (
  url: string,
  dto: any,
  onSuccess: any,
  onError: any
) => {
  PatchAPI.call(url, dto, onSuccess, {}, onError);
};
// family kyc detail
const get_family_kyc_detail_list = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
  url: string
) => {
  GetAPI.call(url, onSuccess, onError);
};
const create_family_kyc_detail = (
  dto: any,
  onSuccess: any,
  onError: any,
  usertypeinfo: any
) => {
  PostAPI.call(
    URL_CONSTANTS.create_family_kyc_detail(usertypeinfo),
    dto,
    onSuccess,
    {},
    onError
  );
};

// veicle detail
const create_vehicle_detail = (
  dto: any,
  onSuccess: any,
  onError: any,
  user: string
) => {
  PostAPI.call(
    URL_CONSTANTS.create_vehicle_detail(user),
    dto,
    onSuccess,
    {},
    onError
  );
};

const update_vehicle_detail = (
  dto: any,
  onSuccess: any,
  onError: any,
  id: string,
  user: string
) => {
  PatchAPI.call(
    `${URL_CONSTANTS.update_vehicle_detail(user)}${id}`,
    dto,
    onSuccess,
    {},
    onError
  );
};

const get_vehicle_detail_list = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
  id: string
) => {
  GetAPI.call(`${URL_CONSTANTS.get_vehicle_detail}${id}`, onSuccess, onError);
};

const delete_detail = (
  url: string,
  onSuccess: (data: any) => void,
  onError: (err: any) => void
) => {
  DeleteSingleAPI.call(url, onSuccess, {}, onError);
};

// property add api
const create_property_detail = (
  dto: any,
  onSuccess: any,
  onError: any,
  userType: string
) => {
  PostAPI.call(
    URL_CONSTANTS.create_property_detail(userType),
    dto,
    onSuccess,
    {},
    onError
  );
};

const update_property_detail = (
  dto: any,
  onSuccess: any,
  onError: any,
  id: string,
  user: string
) => {
  PatchAPI.call(
    `${URL_CONSTANTS.update_property_detail(user)}${id}`,
    dto,
    onSuccess,
    {},
    onError
  );
};

const get_property_detail_list = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
  id: string,
  user: string
) => {
  GetAPI.call(
    `${URL_CONSTANTS.get_property_detail(user)}${id}`,
    onSuccess,
    onError
  );
};

const get_employee_kyc_detail_list = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
  id: string,
  usertypeinfo: string
) => {
  GetAPI.call(
    `${URL_CONSTANTS.get_kyc_detail(id, usertypeinfo)}`,
    onSuccess,
    onError
  );
};

const get_dropdown_list_property_data = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
  url: string
) => {
  GetAPI.call(url, onSuccess, onError);
};

const add_member_to_employee_portal = (
  dto: any,
  onSuccess: any,
  onError: any,
  apiUrl: string
) => {
  PostAPI.call(apiUrl, dto, onSuccess, {}, onError);
};
const get_assigned_policies = (
  onSuccess: (res: any) => void,
  onError: (err: any) => void,
  empId: string
) => {
  GetAPI.call(
    `${EMPLOYEE_URL_CONSTANTS.get_assigned_policies}${empId}`,
    onSuccess,
    {},
    onError
  );
};
const getPreviousInsurer = (
  onSuccess: (res: any) => void,
  onError: (err: any) => void,
) => {
  GetAPI.call(
    `${EMPLOYEE_URL_CONSTANTS.getPreviousInsurer}`,
    onSuccess,
    {},
    onError
  );
};
export const PROFILE_SERVICE = {
  create_family_member,
  get_family_detail_list,
  create_vehicle_detail,
  get_vehicle_detail_list,
  delete_detail,
  create_property_detail,
  get_property_detail_list,
  update_vehicle_detail,
  update_property_detail,
  create_family_detail,
  update_family_detail,
  get_family_kyc_detail_list,
  create_family_kyc_detail,
  get_employee_kyc_detail_list,
  get_dropdown_list_property_data,
  add_member_to_employee_portal,
  get_assigned_policies,
  getPreviousInsurer,
};
