import { Button, Link, Modal } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Box } from "@mui/system";
const ConfirmationPopup = ({
  statusWarningPopoup,
  setstatusWarningPopoup,
  update_status,
  title,
}: {
  statusWarningPopoup: boolean;
  setstatusWarningPopoup: Function;
  update_status: Function;
  title:string;
}) => {
  return (
    <Modal open={statusWarningPopoup} className="modalWrapper">
      <>
        <Box className="modalInner deletepopup">
          <Grid container spacing={1}>
            <Grid xs={12}>
              <Link
                className="close-button"
                onClick={() => setstatusWarningPopoup(false)}
              />
              <div className="img-prt">
                <img src="./images/warninglogo.svg" alt="" className="img" />
              </div>
              <h5 className="popup-heading">
                {title}                
              </h5>
            </Grid>
            <Grid xs={12}>
              <Link className="md-link mb-10" underline="none">
                Are you sure you want to Proceed ?
              </Link>
            </Grid>
            <Grid
              xs={12}
              className="ctaBtn"
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button
                variant="contained"
                className="submitBtn"
                onClick={() => {
                  update_status();
                  setstatusWarningPopoup(false);
                }}
              >
                Yes, Change
              </Button>
              <Button
                variant="contained"
                className="cancelBtn"
                onClick={() => setstatusWarningPopoup(false)}
              >
                cancel
              </Button>
            </Grid>
          </Grid>
        </Box>
      </>
    </Modal>
  );
};

export default ConfirmationPopup;
