var Session = (function () {
  var tokken = "";
  var userType = "";

  var getTokken = function () {
    return tokken;
  };
  var setTokken = function (new_tokken: string) {
    tokken = new_tokken;
  };

  var getUserType = function () {
    return userType;
  };
  var setUserType = function (value: string) {
    userType = value;
  };

  return {
    getTokken: getTokken,
    setTokken: setTokken,
    getUserType: getUserType,
    setUserType: setUserType,
  };
})();

export default Session;
