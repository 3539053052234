import { Button, Link, TextField } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import { Box } from "@mui/system";
import React, { useCallback, useEffect } from "react";
import PostQueryForm from "../../../../Components/Common/PostQuerySP/PostQueryForm";
import "./TrendingQuestions.scss";

interface Props {
  postQuery?: boolean;
}

const TrendingQuestions: React.FC<Props> = ({ postQuery }) => {
  const [addtrendingQuestions, setTrendingQuestions] =
    React.useState(postQuery);
  const [companyphonenumber, setCompanyPhoneNumber] = React.useState("");
  const [postQueryForm, setPostQueryForm] = React.useState(false);

  useEffect(() => {
    // attach the event listener
    window.addEventListener("keydown", handleKeyPress);
  }, []);

  const handleKeyPress = useCallback((e: any) => {
    var event = e;
    if (event.target.tagName !== "INPUT") {
      if (event.shiftKey && event.keyCode === 71) {
        setTrendingQuestions(true);
      }
    }
  }, []);

  const updateMasterState = (attrName: any, value: any) => {
    attrName(value);
  };

  var questions_data_origional = [
    {
      logo: "https://devpos.masuraksha.com/agent_documents/8708430006_Profile_Image_2022_04_14_16_51_17.png",
      username: "Umesh Gemini",
      question:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
      date: "22, August, 2022",
      upvotes: "2",
      answer_open_status: "false",
      answers: [
        {
          logo: "/agent_documents/8708430006_Profile_Image_2022_04_14_16_51_17.png",
          username: "Rahul Kumar",
          date: "02, August, 2022",
          answer: "No, Baleno is better for city.",
          upvotes: "10000",
        },
        {
          logo: "/agent_documents/8708430006_Profile_Image_2022_04_14_16_51_17.png",
          username: "Rahul Kumar",
          date: "02, August, 2022",
          answer: "No, Baleno is better for city.",
          upvotes: "10000",
        },
        {
          logo: "/agent_documents/8708430006_Profile_Image_2022_04_14_16_51_17.png",
          username: "Rahul Kumar",
          date: "02, August, 2022",
          answer: "No, Baleno is better for city.",
          upvotes: "10000",
        },
      ],
    },
    {
      logo: "https://devpos.masuraksha.com/agent_documents/8708430006_Profile_Image_2022_04_14_16_51_17.png",
      username: "Umesh Gemini",
      question:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
      date: "22, August, 2022",
      upvotes: "2",
      answer_open_status: "false",
      answers: [
        {
          logo: "/agent_documents/8708430006_Profile_Image_2022_04_14_16_51_17.png",
          username: "Rahul Kumar",
          date: "02, August, 2022",
          answer: "No, Baleno is better for city.",
          upvotes: "10000",
        },
      ],
    },
    {
      logo: "https://devpos.masuraksha.com/agent_documents/8708430006_Profile_Image_2022_04_14_16_51_17.png",
      username: "Umesh Gemini",
      question:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
      date: "22, August, 2022",
      upvotes: "2",
      answer_open_status: "false",
      answers: [
        {
          logo: "/agent_documents/8708430006_Profile_Image_2022_04_14_16_51_17.png",
          username: "Rahul Kumar",
          date: "02, August, 2022",
          answer: "No, Baleno is better for city.",
          upvotes: "10000",
        },
      ],
    },
  ];
  const [questions, setQuestions] = React.useState(questions_data_origional);

  const answer_open_status_changes = (index: number) => {
    var questions_data = questions;

    questions_data[index].answer_open_status =
      questions_data[index].answer_open_status === "true" ? "false" : "true";

    setQuestions(questions_data);
  };

  return (
    <Grid container spacing={3} className="trendingQuestions">
      <PostQueryForm
        attrName={setPostQueryForm}
        open_status={postQueryForm}
        value_update={updateMasterState}
      />
      <Grid xs={6}>
        <h3 className="mb-0">Trending Questions </h3>
      </Grid>
      <Grid xs={6} className="text-right">
        <Link
          className="greenBtn addfile"
          underline="none"
          onClick={() => {
            setPostQueryForm(true);
          }}>
          Post a query
        </Link>
      </Grid>

      <Grid xs={12}>
        {questions.map((data, index) => {
          return (
            <Box className="questionPost">
              <Grid container spacing={2}>
                <Grid xs={12} className="postTitle">
                  <div className="userIcon">
                    <img src={data.logo} />
                  </div>
                  <div className="userName">
                    <h6>{data.username}</h6>
                    <p>{data.date}</p>
                  </div>
                </Grid>
                <Grid xs={12}>
                  <p className="question">{data.question}</p>
                </Grid>
                <Grid xs={12} className="greySection">
                  <Grid container spacing={2}>
                    <Grid xs={12}>
                      <Link className="upvote active" underline="none">
                        {data.upvotes} upvotes
                      </Link>
                      <Link
                        className="answer"
                        underline="none"
                        onClick={() => {
                          answer_open_status_changes(index);
                        }}>
                        {data.answers.length} answers
                      </Link>
                    </Grid>
                  </Grid>
                  {data.answer_open_status === "false" ? null : (
                    <Box className="postGroup">
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        placeholder="Add answer"
                      />
                      <Button>Post</Button>
                    </Box>
                  )}
                  {data.answer_open_status === "false"
                    ? null
                    : data.answers.map((dataAns, indexAns) => {
                        return (
                          <div>
                            <Grid container spacing={2} className="innerPost">
                              <Grid xs={12} className="postTitle">
                                <div className="userIcon">
                                  <img src={dataAns.logo} />
                                </div>
                                <div className="userName">
                                  <h6>{dataAns.username}</h6>
                                  <p>{dataAns.date}</p>
                                </div>
                              </Grid>
                              <Grid xs={12}>
                                <p className="question">{dataAns.answer}</p>
                              </Grid>
                              <Grid xs={12}>
                                <Link
                                  className="upvote active"
                                  underline="none">
                                  {dataAns.upvotes} upvotes
                                </Link>
                              </Grid>
                            </Grid>
                          </div>
                        );
                      })}
                </Grid>
              </Grid>
            </Box>
          );
        })}
      </Grid>
    </Grid>
  );
};

export default TrendingQuestions;
