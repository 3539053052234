import { InputAdornment } from "@material-ui/core";
import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useRef, useState } from "react";
import { URL_CONSTANTS } from "../../../../../APIVar/URLConstants";
import NewLoader from "../../../../../Components/NewLoader/NewLoader";
import { POLICIES_SERVICES } from "../../../../../Services/Policies/PoliciesServices";
import { useFormatCurrency } from "../../../../../Supporting files/CustomHooks";
import RKTextField from "../../../../../Supporting files/RKTextField/RKTextField";
import TextAreaField from "../../../../../Supporting files/TextAreaField/TextAreaField";
import { QUOTES_SERVICES } from "../../../../../Services/Quotes/QuotesServices";

const Riders = () => {
  const { formatCurrency } = useFormatCurrency();
  const [sectionStatus, SetSectionStatus] = useState<string>("Riders");
  const [RiderData, SetRiderData] = useState<any>({});
  const [livesSelectedGrade, setlivesSelectedGrade] = useState<Array<{ selectedGrade: number }>>([{ selectedGrade: 0 }]);
  const [IsPolicyType, SetIsPolicyType] = useState<any>("FLAT");
  const Riders = useRef(null);
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    setShowLoader(true);
  }, []);

  useEffect(() => {
    const windowURL = window.location.search;
    const params = new URLSearchParams(windowURL);
    const id = params.get("id");
    get_policy_rider_tab(id ?? "");
  }, []);

  useEffect(() => {
    SetIsPolicyType(RiderData?.benefits_detail_flat?.length > 0 ? "FLAT" : "GRADED");
  }, [RiderData]);

  const scrollToSection = (elementRef: any) => {
    elementRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const get_policy_rider_tab = (id: string) => {
    const onSuccess = (res: any) => {
      SetRiderData({ ...res.data.data[0], is_rider: true });
      if (res.data.data[0]?.benefits_detail_flat?.length < 1) {
        let newLivesSelectedGrade = res.data.data[0]?.rider_premium_graded.map(
          (element: any) => ({ selectedGrade: 0 })
        );
        setlivesSelectedGrade(newLivesSelectedGrade);
      }
      if (res.status === 200) {
        setTimeout(() => {
          setShowLoader(false);
        }, 1500);
      }
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    QUOTES_SERVICES.get_quote_tab_data(onSuccess, onError, id, "RIDERS");

  };


  return (
    <>
      {showLoader ? (
        <NewLoader />
      ) : (
        <Grid container spacing={3}>
          <Grid
            xs={2}
            style={{
              maxWidth: "260px",
              position: "relative",
              top: "-75px",
              paddingLeft: "0",
            }}
          >
            <div
              className="leftSection h-100"
              style={{ position: "fixed", paddingLeft: "8px" }}
            >
              <Link
                className={
                  sectionStatus === "Riders"
                    ? "active sectionLink Riders"
                    : "sectionLink Riders"
                }
                onClick={() => {
                  scrollToSection(Riders);
                  SetSectionStatus("Riders");
                }}
              >
                <span> Rider Details</span>
              </Link>
            </div>
          </Grid>
          <Grid xs>
            <Box className="voluntry_plans_outer" ref={Riders}>
              {/* prorata rates for riders in group Term */}
              {RiderData.is_rider && (
                <Grid container spacing={3} className="px-0">
                  <Grid xs={12}>
                    <Box className="detailSection">
                      <div className="sectionTitle">
                        <h4>Rider Details</h4>
                      </div>
                      {IsPolicyType === "FLAT"
                        ? RiderData?.rider_premium_flat?.map(
                          (rider_premium_flat: any, riderIndex: number) => (
                            <>
                              <Grid xs={12}>
                                <h5 className="subHeading">
                                  {rider_premium_flat.rider_name}
                                </h5>
                              </Grid>
                              <Grid xs lg={12}>
                                <TextAreaField
                                  class_name="inputField multiline"
                                  title={"Description"}
                                  value={
                                    RiderData?.benefits_detail_flat[
                                      riderIndex
                                    ]?.description
                                  }
                                  attrName={[
                                    "addPoliciesdata",
                                    "rider",
                                    "description",
                                    "input_fields",
                                    "index",
                                  ]}
                                  value_update={() => { }}
                                  warn_status={false}
                                  required
                                />
                              </Grid>
                              <Grid
                                container
                                spacing={3}
                                className="ml-1 mr-1"
                              >
                                <Grid xs={12}>
                                  <Box className="detailSection">
                                    <div className="sectionTitle">
                                      <h4>
                                        Rider Premium Split Configuration &
                                        Rider Premium Pricing
                                      </h4>
                                    </div>

                                    <div className="policy_lives">
                                      <Grid container spacing={3}>
                                        <>
                                          <Grid xs={12}>
                                            <Grid xs={4}>
                                              <Grid xs={12}>
                                                <h5 className="subHeadingh mb-3" style={{ fontSize: '14px' }}>
                                                  Premium value
                                                </h5>
                                              </Grid>
                                              <RKTextField
                                                class_name="inputField"
                                                title="Pro- Rata Premium Pricing By"
                                                value={
                                                  rider_premium_flat.proreta_by
                                                }
                                                attrName={[
                                                  "RiderData",
                                                  "rider_premium_flat",
                                                  "proreta_by",
                                                  riderIndex,
                                                ]}
                                                value_update={() => { }}
                                                warn_status={false}
                                                required
                                              />
                                            </Grid>
                                            <Grid container spacing={3}>
                                              <Grid
                                                sm={6}
                                                md={6}
                                                lg={3}
                                                xl={3}
                                              >
                                                <Grid xs={12}>
                                                  <h5 className="subHeadingh mb-3" style={{ fontSize: '14px' }}>
                                                    Premium Split
                                                    Configuration
                                                  </h5>
                                                </Grid>
                                                <div className="payment_inner_section">
                                                  <RKTextField
                                                    class_name="inputField"
                                                    // type="number"
                                                    title={"Employee"}
                                                    value={
                                                      rider_premium_flat.premium_split
                                                    }
                                                    attrName={[
                                                      "RiderData",
                                                      "rider_premium_flat",
                                                      "premium_split",
                                                      riderIndex,
                                                    ]}
                                                    value_update={() => { }}
                                                    warn_status={false}
                                                    inputProps={{
                                                      endAdornment: (
                                                        <InputAdornment position="end">
                                                          %
                                                        </InputAdornment>
                                                      ),
                                                    }}
                                                    error_messg="Enter Split amount"
                                                  />
                                                </div>
                                              </Grid>
                                            </Grid>
                                            <Grid xs lg={4}>
                                              <RKTextField
                                                class_name="inputField"
                                                title={"Rider SA"}
                                                value={
                                                  formatCurrency(
                                                    Number(
                                                      RiderData
                                                        ?.benefits_detail_flat[
                                                        riderIndex
                                                      ]?.amount
                                                    )
                                                  )
                                                }
                                                attrName={[
                                                  "addPoliciesdata",
                                                  "benefits_detail_flat",
                                                  "amount",
                                                  "input_fields",
                                                  "index",
                                                ]}
                                                value_update={() => { }}
                                                warn_status={false}
                                              />
                                            </Grid>
                                            <div className="table mt-2">
                                              <div className="livesinformation-table">
                                                <Grid xs={12}>
                                                  <h5 className="subHeadingh mb-3" style={{fontSize:'14px'}}>
                                                    Premium Pricing
                                                  </h5>
                                                </Grid>
                                                <ul className="heading">
                                                  {rider_premium_flat?.livesInfoFlat?.map(
                                                    (
                                                      data: any,
                                                      ageindex: any
                                                    ) => (
                                                      <li>
                                                        {data.min}-{data.max}
                                                      </li>
                                                    )
                                                  )}
                                                </ul>

                                                <ul className="subheading">
                                                  {rider_premium_flat?.livesInfoFlat?.map(
                                                    (
                                                      data: any,
                                                      ageindex: any
                                                    ) => (
                                                      <li>
                                                        <RKTextField
                                                          validation_type="numeric"
                                                          class_name="inputField"
                                                          title={"Amount"}
                                                          value={
                                                            formatCurrency(
                                                              Number(
                                                                data
                                                                  .coverageTypeData[0]
                                                                  .value
                                                              )
                                                            )
                                                          }
                                                          attrName={[
                                                            "RiderData",
                                                            "rider_premium_flat",
                                                            riderIndex,
                                                            ageindex,
                                                          ]}
                                                          warn_status={false}
                                                          value_update={() => { }}
                                                        />
                                                      </li>
                                                    )
                                                  )}
                                                </ul>
                                              </div>
                                            </div>
                                          </Grid>
                                        </>
                                      </Grid>
                                    </div>
                                  </Box>
                                </Grid>
                              </Grid>
                            </>
                          )
                        )
                        : RiderData?.rider_premium_graded?.map(
                          (rider_premium_flat: any, riderIndex: number) => (
                            <>
                              <Grid xs={12}>
                                <h5 className="subHeading">
                                  {rider_premium_flat.rider_name}
                                </h5>
                              </Grid>
                              <Grid xs lg={12}>
                                <TextAreaField
                                  class_name="inputField multiline"
                                  title={"Description"}
                                  value={
                                    RiderData?.rider[riderIndex]?.description
                                  }
                                  attrName={[
                                    "addPoliciesdata",
                                    "rider",
                                    "description",
                                    "input_fields",
                                    "index",
                                  ]}
                                  value_update={() => { }}
                                  warn_status={false}
                                  required
                                />
                              </Grid>
                              <Grid
                                container
                                spacing={3}
                                className="ml-1 mr-1"
                              >
                                <Grid xs={12}>
                                  <Box className="detailSection lastsection">
                                    <div className="sectionTitle">
                                      <h4>
                                        Rider Premium Split Configuration &
                                        Rider Premium Pricing
                                      </h4>
                                    </div>

                                    <div className="policy_lives">
                                      <Grid container spacing={3}>
                                        <Grid sm={12} md={12} lg={12}>
                                          <Grid container spacing={3}>
                                            <Grid
                                              sm={6}
                                              md={6}
                                              lg={4}
                                              xl={4}
                                              width={"12%"}
                                            >
                                              <Grid xs={12}>
                                                <h5 className="subHeadingh">
                                                  Premium value
                                                </h5>
                                              </Grid>
                                              <div className="payment_inner_section">
                                                <RKTextField
                                                  class_name="inputField"
                                                  title="Pro- Rata Premium Pricing By"
                                                  value={
                                                    rider_premium_flat.proreta_by
                                                  }
                                                  attrName={[
                                                    "addPoliciesdata",
                                                    "rider_premium_graded",
                                                    "proreta_by",
                                                    riderIndex,
                                                  ]}
                                                  value_update={() => { }}
                                                  warn_status={false}
                                                  required
                                                />
                                              </div>
                                            </Grid>
                                          </Grid>
                                          <Grid container spacing={3}>
                                            <Grid sm={6} md={6} lg={3} xl={3}>
                                              <Grid xs={12}>
                                                <h5 className="subHeadingh">
                                                  Premium Split Configuration
                                                </h5>
                                              </Grid>
                                              <div className="payment_inner_section">
                                                <RKTextField
                                                  class_name="inputField"
                                                  // type="number"
                                                  title={"Employee"}
                                                  value={
                                                    rider_premium_flat.premium_split
                                                  }
                                                  attrName={[
                                                    "addPoliciesdata",
                                                    "rider_premium_graded",
                                                    "premium_split",
                                                    riderIndex,
                                                  ]}
                                                  value_update={() => { }}
                                                  warn_status={false}
                                                  inputProps={{
                                                    endAdornment: (
                                                      <InputAdornment position="end">
                                                        %
                                                      </InputAdornment>
                                                    ),
                                                  }}
                                                  error_messg="Enter Split amount"
                                                />
                                              </div>
                                            </Grid>
                                          </Grid>
                                          <Grid container spacing={3}>
                                            <>
                                              <Grid xs={8}>
                                                <Grid xs={12}>
                                                  <h5 className="subHeadingh">
                                                    Premium Pricing
                                                  </h5>
                                                </Grid>
                                                <ul
                                                  className="coverageAmt mb-4"
                                                  key={riderIndex}
                                                  style={{
                                                    justifyContent: "unset",
                                                  }}
                                                >
                                                  {rider_premium_flat.livesInfoGrade &&
                                                    rider_premium_flat.livesInfoGrade.map(
                                                      (
                                                        data: any,
                                                        mainIndex: any
                                                      ) => (
                                                        <li
                                                          key={`${riderIndex}-${mainIndex}`}
                                                        >
                                                          <div
                                                            className="radioBox"
                                                            key={`${riderIndex}-${mainIndex}`}
                                                          >
                                                            <input
                                                              key={`${riderIndex}-${mainIndex}`}
                                                              type="radio"
                                                              name={`abc${riderIndex}-${mainIndex}`}
                                                              onClick={() => {
                                                                setlivesSelectedGrade(
                                                                  (prev) => {
                                                                    const updatedGrades =
                                                                      [
                                                                        ...prev,
                                                                      ];
                                                                    updatedGrades[
                                                                      riderIndex
                                                                    ] = {
                                                                      selectedGrade:
                                                                        mainIndex,
                                                                    };
                                                                    return updatedGrades;
                                                                  }
                                                                );
                                                              }}
                                                              checked={
                                                                livesSelectedGrade[
                                                                  riderIndex
                                                                ]
                                                                  ?.selectedGrade ==
                                                                mainIndex
                                                              }
                                                            />
                                                            <label>
                                                              Grade
                                                              {mainIndex + 1}
                                                            </label>
                                                          </div>
                                                        </li>
                                                      )
                                                    )}
                                                </ul>
                                              </Grid>

                                              <Grid xs={12}>
                                                <div className="table">
                                                  <div className="livesinformation-table">
                                                    <ul className="heading">
                                                      {rider_premium_flat?.livesInfoGrade[
                                                        livesSelectedGrade[
                                                          riderIndex
                                                        ]?.selectedGrade
                                                      ]?.data?.map(
                                                        (data: any) => (
                                                          <li>
                                                            {data.min}-
                                                            {data.max}
                                                          </li>
                                                        )
                                                      )}
                                                    </ul>

                                                    <ul className="subheading">
                                                      {rider_premium_flat?.livesInfoGrade[
                                                        livesSelectedGrade[
                                                          riderIndex
                                                        ]?.selectedGrade
                                                      ]?.data?.map(
                                                        (
                                                          data: any,
                                                          ageindex: any
                                                        ) => (
                                                          <li>
                                                            <RKTextField
                                                              validation_type="numeric"
                                                              class_name="inputField"
                                                              title={"Amount"}
                                                              value={
                                                                formatCurrency(
                                                                  Number(
                                                                    data
                                                                      .coverageTypeData[0]
                                                                      .value
                                                                  )
                                                                )
                                                              }
                                                              attrName={[
                                                                "addPoliciesdata",
                                                                "rider_premium_graded",
                                                                riderIndex,
                                                                livesSelectedGrade[
                                                                  riderIndex
                                                                ]
                                                                  ?.selectedGrade,
                                                                ageindex,
                                                              ]}
                                                              value_update={() => { }}
                                                              warn_status={
                                                                false
                                                              }
                                                            />
                                                          </li>
                                                        )
                                                      )}
                                                    </ul>
                                                  </div>
                                                </div>
                                              </Grid>
                                            </>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </div>
                                  </Box>
                                </Grid>
                              </Grid>
                            </>
                          )
                        )}
                    </Box>
                  </Grid>
                </Grid>
              )}
            </Box>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default Riders;
