import { Grid, Modal } from "@mui/material";
import { useEffect, useState } from "react";
import { Button, Box, Link } from "@mui/material";
import "./Currencies.scss";
import UGTable from "../../../../../../Supporting files/UGTable/UGTable";
// import SlidingPanel from "react-sliding-side-panel";
// import DatePicker from "../../../../../Supporting files/DatePicker/DatePicker";
// import SelectDropdown from "../../../../../Supporting files/SelectDropdown/SelectDropdown";
// import RKTextField from "../../../../../Supporting files/RKTextField/RKTextField";
import AddCurrency from "./AddCurrency";
import { CURRENCIES_SERVICES } from "../../../../../../Services/Settings/OrganisationSettings/CurrenciesServices";
import { date_time_format } from "../../../../../../Supporting files/HelpingFunction";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../Store/Store";
import { TUserData } from "../../../../../../Services/Types/TUserData";
import { ECurrencyCode } from "../../../../../../Services/Enum/ECurrencyCode";
import { useAppSelector } from "../../../../../../Store/hooks";
import GlobalDeletePopup from "../../../../../../Components/Broker/GlobalDeletePopup/GlobalDeletePopup";
import { E_BE_FILTER_CODE } from "../../../../../../Services/Enum/E_UGTable";
import NewLoader from "../../../../../../Components/NewLoader/NewLoader";
import { toast } from "react-toastify";

const Currencies = () => {
  const user_type_dateFormat = useAppSelector(
    (state) => state.userDetailsSlice.localInformation.dateFormat
  );
  const tz = useAppSelector(
    (state: RootState) => state.userDetailsSlice.localInformation.timeZone
  );
  const [addcurrency, setAddCurrency] = useState(false);
  const [editcurrency, setEditCurrency] = useState(false);
  const [exchangeRate, setExchangeRate] = useState(true);
  const [exchangeRateConfrm, setExchangeRateConfrm] = useState(false);
  const [currencyEntries, setCurrencyEntries] = useState([]);
  const [currencyDropdownData, setCurrencyDropdownData] = useState([]);
  const [deleteStatus, setDeleteStatus] = useState<boolean>(false);
  const [deleteStatusStep, setDeleteStatusStep] = useState<number>(1);
  const [idToBeDeleted, setidToBeDeleted] = useState("");
  const [tableLimit, setTableLimit] = useState();
  const [tableSkip, setTableSkip] = useState();
  const [objToEdit, setObjToEdit] = useState<{
    code: ECurrencyCode | null;
    createdBy: string;
    decimal: number;
    format: string;
    name: string;
    symbol: string;
    _id: string;
  }>({
    code: null,
    createdBy: "",
    decimal: 1,
    format: "",
    name: "",
    symbol: "",
    _id: "",
  });
  const { BROKER } = useAppSelector((state) => state.permissionSlice);
  const [addPermission, setaddPermission] = useState<any>(null);
  const [editPermission, seteditPermission] = useState<any>(null);
  const [removePermission, setremovePermission] = useState<any>(null);
  const [showLoader, setShowLoader] = useState(true);
  const [columns, setColumns] = useState<any>([]);
  useEffect(() => {
    setShowLoader(true);
    setaddPermission(BROKER?.Currencies?.add === undefined ? false : BROKER?.Currencies?.add);
    setremovePermission(BROKER?.Currencies?.remove === undefined ? false : BROKER?.Currencies?.remove);
    seteditPermission(BROKER?.Currencies?.edit === undefined ? false : BROKER?.Currencies?.edit);
    setColumns([
      {
        field: "name",
        headerName: "Currency Name",
        width: 200,
        flex: 1,
        check_status: true,
      },
      {
        field: "code",
        headerName: "Currency Code",
        width: 150,
        flex: 1,
        check_status: true,
      },
      {
        field: "symbol",
        headerName: "Currency Symbol",
        width: 150,
        flex: 1,
        check_status: true,
      },
      {
        field: "decimal",
        headerName: "Decimal Places",
        width: 200,
        flex: 1,
        check_status: true,
      },
      {
        field: "format",
        headerName: "Format",
        width: 150,
        flex: 1,
        check_status: true,
      },
      {
        field: "rate",
        headerName: "Exchange Rate (with Base Currency)",
        width: 200,
        flex: 1,
        check_status: true,
      },
      {
        field: "ondate",
        headerName: "As on Date",
        width: 100,
        flex: 1,
        check_status: true,
        renderCell: (ValueFormatterParams: any) => {
          const { row } = ValueFormatterParams;
          return <span>{row.ondate}</span>;
        },
      },
      {
        field: "actions",
        headerName: "Actions",
        width: 100,
        flex: 1,
        check_status: true,
        renderCell: (obj: any) => {
          return (
            <>
              <Link
                underline="none"
                className="actiondelete"
                onClick={() => {
                  if(!BROKER?.Currencies?.remove) return toast.error("Remove permission not allowed");
                  setDeleteStatus(true);
                  setidToBeDeleted(obj.formattedValue._id);
                }}
              ></Link>
              <Link
                underline="none"
                className="actionedit"
                onClick={() => {
                  if(!BROKER?.Currencies?.edit) return toast.error("Edit permission not allowed");
                  setEditCurrency(true);
                  setObjToEdit(obj.formattedValue);
                }}
              ></Link>
            </>
          );
        },
      },
    ])
  }, [BROKER]);

  const userDetailsState: TUserData = useSelector(
    (state: RootState) => state.userDetailsSlice
  );

  useEffect(() => {
    getCurrencyOptions();
  }, []);

  const getCurrencyOptions = () => {
    const onSuccess = (res: any) => {
      setCurrencyDropdownData(res.data.data);
      getCurrencyEntries(res.data.data);
    };

    const onError = (err: any) => {
      console.log(err);
    };

    CURRENCIES_SERVICES.get_currency_options(onSuccess, onError);
  };

  const getCurrencyEntries = (currencyOptions?: any) => {
    try {
      const onSuccess = (res: any) => {
        if (res) {
          setTimeout(() => {
            setShowLoader(false);
          }, 1500);
        }
        setCurrencyEntries(
          res.data.data
            .map((item: any) => {
              return {
                ...item,
                id: item._id,
                ondate: date_time_format(
                  new Date(),
                  userDetailsState.localInformation.dateFormat,
                  tz
                ),
                actions: item,
              };
            })
            .reverse()
        );
      };

      const onError = (err: any) => {
        console.log(err);
      };

      const arrToUse = currencyOptions ? currencyOptions : currencyDropdownData;

      CURRENCIES_SERVICES.get_currency_entries(
        onSuccess,
        onError,
        arrToUse.filter(
          (item: any) =>
            userDetailsState.localInformation.currency === item.label
        )[0].currency
      );
    } catch (err) {
      console.log("caught error", err);
    }
  };

  //Table Data


  const updateMasterState = (attrName: any, value: any) => {
    if (typeof attrName === "string") {
      return;
    }

    attrName(value);
    if (attrName === setExchangeRate && value === false) {
      setExchangeRateConfrm(true);
    }
  };

  // Function to handle deletion of a currency entry
  const handleCurrencyEntryDelete = (currencyId: string) => {
    // Callback function to execute on successful deletion
    const onSuccess = (res: any) => {
      // Refresh the currency entries after successful deletion
      getCurrencyOptions();
      setDeleteStatus(false);
    };
    // Callback function to execute on error
    const onError = (err: any) => {
      // Handle error (if required)
    };

    // Call the API service to delete the currency entry
    CURRENCIES_SERVICES.delete_currency_entry(onSuccess, onError, currencyId);
  };

  return (
    <>
      {showLoader ? (
        <NewLoader />
      ) : (
        <Grid container spacing={2} className="currunciesWrapper">
          <Grid item xs={4}>
            <h3 className="mb-0">Currencies</h3>
          </Grid>
          <Grid item xs={8} className="text-right">
            <div className="buttons">
              <h6 className="mb-0">
                Base Currency: {userDetailsState.localInformation.currency}
              </h6>
              {addPermission &&
                <Link
                  underline="none"
                  className="greenBtn addfile"
                  onClick={() => {
                    setAddCurrency(true);
                  }}
                >
                  Add Currency
                </Link>
              }
              {/* <Switch
            defaultChecked
            checked={exchangeRate}
            onChange={() => {
              updateMasterState(setExchangeRate, !exchangeRate);
            }}
          />
          <p>Exchange Rate Feeds</p> */}
              {/*<Link
              className="switchDetails"
              onClick={() => {
                setCurrencyFeed(true);
              }}>
              <input type="checkbox" className="switch" />
              <p>Exchange Rate Feeds</p>
            </Link> */}
            </div>
            <Modal open={exchangeRateConfrm} className="modalWrapper">
              <Box className="modalInner currencypopup">
                <Grid container spacing={3}>
                  <Grid xs={12}>
                    <p className="currencyData">
                      Kindly manually enter the exchange rates for each
                      curerency, once you have disabled this feature.
                    </p>
                  </Grid>
                  <Grid
                    xs={6}
                    className="ctaBtn"
                    style={{ textAlign: "right" }}
                  >
                    <Button
                      variant="contained"
                      className="submitBtn mr-2"
                      onClick={() => {
                        setExchangeRateConfrm(false);
                      }}
                    >
                      Disable
                    </Button>
                  </Grid>
                  <Grid xs={6} className="ctaBtn" style={{ textAlign: "left" }}>
                    <Button
                      className="borderBtn ml-2"
                      onClick={() => {
                        setExchangeRateConfrm(false);
                        setExchangeRate(true);
                      }}
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Modal>
          </Grid>
          <Grid item xs={12}>
            <AddCurrency
              add_cuurency_status={exchangeRate}
              attrName={setAddCurrency}
              open_status={addcurrency}
              value_update={updateMasterState}
              getCurrencyEntries={getCurrencyEntries}
              currencyDropdownData={currencyDropdownData}
            />

            <AddCurrency
              add_cuurency_status={exchangeRate}
              attrName={setEditCurrency}
              open_status={editcurrency}
              value_update={updateMasterState}
              getCurrencyEntries={getCurrencyEntries}
              currencyDropdownData={currencyDropdownData}
              existingValues={objToEdit}
            />

            <div style={{ height: 400 }}>
              <UGTable
                header_data={columns}
                data={currencyEntries}
                value_update={updateMasterState}
                attrName={"UGTable"}
                BE_filter_code={E_BE_FILTER_CODE.CURRENCIES}
                setTableLimit={setTableLimit}
                setTableSkip={setTableSkip}
                paginationMode="client"
                refetch_data={getCurrencyEntries}
                class_Name={"th-setting"}
              />
            </div>
          </Grid>
          <GlobalDeletePopup
            open_status={deleteStatus}
            value_update={updateMasterState}
            attrName={setDeleteStatus}
            delete_step={deleteStatusStep}
            delete_item={() => {
              handleCurrencyEntryDelete(idToBeDeleted);
            }}
          ></GlobalDeletePopup>
        </Grid>
      )}
    </>
  );
};

export default Currencies;
