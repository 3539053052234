//used
import React, { useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Box, Button, Link, Modal } from "@mui/material";

import RKTextField from "../../../../../../Supporting files/RKTextField/RKTextField";
import SlidingPanel from "react-sliding-side-panel";
import SearchDropdown from "../../../../../../Supporting files/SearchDropdown/SearchDropdown";

interface Props {
  open_status: boolean;
  attrName: any;
  value_update: Function;
}

const LocationPopup: React.FC<Props> = ({
  open_status,
  attrName,
  value_update,
}) => {
  const [locationName, setLocationName] = React.useState("");
  const [locationType, setLocationType] = React.useState("");
  const [streetName, setStreetName] = React.useState("");
  const [areaName, setAreaName] = React.useState("");
  const [country, setCountry] = React.useState("");
  const [state, setState] = React.useState("");
  const [city, setCity] = React.useState("");
  const [spocContact, setSpocContact] = React.useState("");
  const [zipCode, setZipCode] = React.useState("");
  const spoccontact_data = [
    { label: "CLT012 - Evervent", value: "CLT012 - Evervent" },
    { label: "CLT013 - Evervent", value: "CLT013 - Evervent" },
    { label: "CLT014 - Evervent", value: "CLT014 - Evervent" },
    { label: "CLT015 - Evervent", value: "CLT015 - Evervent" },
  ];
  const updateMasterState = (attrName: any, value: any) => {
    attrName(value);
  };

  return (
    <SlidingPanel
      type={"right"}
      isOpen={open_status}
      size={75}
      backdropClicked={() => value_update(attrName, false)}
    >
      <div>
        <Grid container spacing={3} alignItems="center">
          <Grid xs={12}>
            <h4 className="mb-0 mt-4">Update Location Details</h4>
          </Grid>

          <Grid xs={12} className="mb-5">
            <hr />
          </Grid>
        </Grid>
        <div className="scrollable_area">
          <Grid container spacing={3}>
            <Grid xs={6}>
              <RKTextField
                class_name="inputField"
                title="Location Name"
                value={locationName}
                attrName={setLocationName}
                value_update={updateMasterState}
                warn_status={false}
              />
            </Grid>
            <Grid xs={6}>
              <RKTextField
                class_name="inputField"
                title="Location type"
                value={locationType}
                attrName={setLocationType}
                value_update={updateMasterState}
                warn_status={false}
              />
            </Grid>
            <Grid xs={6}>
              <RKTextField
                class_name="inputField"
                title="Building/Street Name"
                value={streetName}
                attrName={setStreetName}
                value_update={updateMasterState}
                warn_status={false}
              />
            </Grid>
            <Grid xs={6}>
              <RKTextField
                class_name="inputField"
                title="Area Name"
                value={areaName}
                attrName={setAreaName}
                value_update={updateMasterState}
                warn_status={false}
              />
            </Grid>
            <Grid xs={3}>
              <RKTextField
                class_name="inputField"
                title="Country"
                value={country}
                attrName={setCountry}
                value_update={updateMasterState}
                warn_status={false}
              />
            </Grid>
            <Grid xs={3}>
              <RKTextField
                class_name="inputField"
                title="State"
                value={state}
                attrName={setState}
                value_update={updateMasterState}
                warn_status={false}
              />
            </Grid>
            <Grid xs={3}>
              <RKTextField
                class_name="inputField"
                title="City"
                value={city}
                attrName={setCity}
                value_update={updateMasterState}
                warn_status={false}
              />
            </Grid>
            <Grid xs={3}>
              <RKTextField
                class_name="inputField"
                title="Zip Code"
                value={zipCode}
                attrName={setZipCode}
                value_update={updateMasterState}
                warn_status={false}
              />
            </Grid>
            <Grid xs={12}>
              <h6 className="popup-subheading">SPOC Info</h6>
            </Grid>
            <Grid xs={5}>
              <SearchDropdown
                class_name="inputField"
                title="Select Contact"
                value={spocContact}
                attrName={setSpocContact}
                value_update={updateMasterState}
                data={spoccontact_data}
                warn_status={false}
                error_message="Please select client"
              />
            </Grid>
          </Grid>
        </div>
        <Grid container spacing={3}>
          <Grid xs={12} className="ctaBtn mt-4">
            <Button
              variant="contained"
              className="submitBtn fixedbtn"
              onClick={() => {}}
            >
              Update
            </Button>
          </Grid>
        </Grid>
      </div>
    </SlidingPanel>
  );
};

export default LocationPopup;
