import { EMPLOYEE_URL_CONSTANTS } from "../../APIVar/EmployeeUrlConstant";
import { GetAPI, PatchAPI, PostAPI } from "../API";
import {
  AddFamilyMemberDTO,
  SetPasswordDTO,
} from "../DTO/Employee/EmployeeEnrolmentDTO";

const get_emp_enrolment_policies = (
  onSuccess: any,
  onError: any,
  empId: string,
  queryParams: string
) => {
  GetAPI.call(
    EMPLOYEE_URL_CONSTANTS.GET_ENROLMENT_EMPLOYEE_POLICIES(empId, queryParams),
    onSuccess,
    {},
    onError
  );
};

const set_password = (
  onSuccess: any,
  onError: any,
  userId: string,
  dto: SetPasswordDTO
) => {
  PatchAPI.call(
    EMPLOYEE_URL_CONSTANTS.set_password(userId),
    dto,
    onSuccess,
    {},
    onError
  );
};

const add_family_member = (
  empId: string,
  onSuccess: any,
  onError: any,
  dto: any
) => {
  PatchAPI.call(
    EMPLOYEE_URL_CONSTANTS.add_family_member(empId),
    dto,
    onSuccess,
    {},
    onError
  );
};

const get_policy_features_exclusions = (
  onSuccess: any,
  onError: any,
  policyId: string
) => {
  GetAPI.call(
    EMPLOYEE_URL_CONSTANTS.get_policy_features_exclusions(policyId),
    onSuccess,
    {},
    onError
  );
};

const completePolicyAssignment = (
  user: string,
  onSuccess: any,
  onError: any,
  dto: any,
) => {
  PostAPI.call(
    EMPLOYEE_URL_CONSTANTS.complete_Policy_Assignment(user),
    dto,
    onSuccess,
    {},
    onError
  );
};
const submitRider = (
  onSuccess: any,
  onError: any,
  dto: any,
) => {
  PostAPI.call(
    EMPLOYEE_URL_CONSTANTS.submitRider,
    dto,
    onSuccess,
    {},
    onError
  );
};

export const EMPLOYEE_ENROLMENT_SERVICES = {
  get_emp_enrolment_policies,
  set_password,
  add_family_member,
  get_policy_features_exclusions,
  completePolicyAssignment,
  submitRider,
};
