import { Box, Button, Link, Tooltip } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import { useFormatCurrency } from "../../../Supporting files/CustomHooks";
import { extractNumbersFromString } from "../../../Supporting files/HelpingFunction";
import RKTextField from "../../../Supporting files/RKTextField/RKTextField";
import { useAppSelector } from "../../../Store/hooks";
import { toast } from "react-toastify";

const SalaryBracketInputs = ({
  dbValue,
  getSalaryBrackets,
  editMode,
  updateApi,
  disabled,
  submit,
  submit2,
  stopEditOldValues,
}: {
  dbValue: Array<string> | string;
  getSalaryBrackets: any;
  editMode?: boolean;
  disabled?: boolean;
  updateApi?: any;
  submit?: boolean;
  submit2?: Function;
  stopEditOldValues?:boolean;
}) => {
  const userDetails = useAppSelector((state: any) => state.userDetailsSlice);
  const userCurrency = userDetails?.localInformation?.currency?.split(" ");
  const currency = userCurrency[userCurrency?.length - 1];
  const { formatCurrency } = useFormatCurrency();
  const [salaryBrackets, setSalaryBrackets] = useState<
    Array<{
      lowerRange: string | number;
      upperRange: string | number;
      newlyAdded: boolean;
    }>
  >([{ lowerRange: "0", upperRange: "0", newlyAdded: true }]);
  const [lowestRange, setLowestRange] = useState<string | number>(0);
  const [highestRange, setHighestRange] = useState<string | number>(0);
  const [invalidSalaryBrackets, setInvalidSalaryBrackets] =
    useState<boolean>(false);
  const [upperWarn, setUpperWarn] = useState<string>("");
  const [upperWarnStatus, setUpperWarnStatus] = useState<boolean>(false);

  useEffect(() => {
    if (typeof dbValue !== "string") {
      setSalaryBrackets(
        dbValue.map((item) => {
          const splitVals = item.split("-");
          return {
            lowerRange: formatCurrency(Number(splitVals[0])),
            upperRange: formatCurrency(Number(splitVals[1])),
            newlyAdded: true,
          };
        })
      );
    }

    getSalaryBrackets({
      invalidSalaryBrackets: false,
      salaryBracketsString:
        typeof dbValue !== "string" ? dbValue.join() : dbValue,
    });
  }, [dbValue]);

  useEffect(() => {
    setLowestRange(salaryBrackets[0].lowerRange);
    setHighestRange(salaryBrackets[salaryBrackets.length - 1].upperRange);
    returnSalaryBracketValues();
  }, [salaryBrackets]);

  const returnSalaryBracketValues = () => {
    getSalaryBrackets({
      invalidSalaryBrackets,
      salaryBracketsString: salaryBrackets
        .map(
          (item) =>
            `${extractNumbersFromString(
              item.lowerRange.toString()
            )}-${extractNumbersFromString(item.upperRange.toString())}`
        )
        .join(),
    });
  };

  useEffect(() => {
    if (submit) {
      let lastLower = Number(
        `${salaryBrackets[salaryBrackets.length - 1].lowerRange}`
      );
      let lastUpper = Number(
        `${salaryBrackets[salaryBrackets.length - 1].upperRange}`
      );
      if (lastUpper === 0) {
        setUpperWarn("Enter Upper range value");
        if(submit2) submit2(false)
        return setUpperWarnStatus(true);
      } else if (lastLower === lastUpper) {
        setUpperWarn(
          "Upper value should be greater than the range's lower value"
        );
        if(submit2) submit2(false)
        return setUpperWarnStatus(true);
      } else if (lastLower > lastUpper) {
        setUpperWarn(
          "Upper value should be greater than the range's lower value"
        );
        if(submit2) submit2(false)
        return setUpperWarnStatus(true);
      }
    }
  }, [submit]);

  const addNewBracket = () => {
    if (disabled) {
      return toast.error("Edit Permissions Not Allowed");
    }
    let lastLower = Number(
      `${salaryBrackets[salaryBrackets.length - 1].lowerRange}`
    );
    let lastUpper = Number(
      `${salaryBrackets[salaryBrackets.length - 1].upperRange}`
    );
    if (lastUpper === 0) {
      setUpperWarn("Enter Upper range value");
      return setUpperWarnStatus(true);
    } else if (lastLower === lastUpper) {
      setUpperWarn(
        "Upper value should be greater than the range's lower value"
      );
      return setUpperWarnStatus(true);
    } else if (lastLower > lastUpper) {
      setUpperWarn(
        "Upper value should be greater than the range's lower value"
      );
      return setUpperWarnStatus(true);
    } else {
      setUpperWarnStatus(false);
      setUpperWarn("");
      let newSalaryBrackets = [...salaryBrackets];
      let lower;
      if (
        typeof salaryBrackets[salaryBrackets.length - 1].upperRange === "string"
      ) {
        lower = `${
          salaryBrackets[salaryBrackets.length - 1].upperRange
        }`.replaceAll(/,/g, "");
      } else {
        lower = `${salaryBrackets[salaryBrackets.length - 1].upperRange}`;
      }

      let newLower = Number(lower) + 1;
      newSalaryBrackets.push({
        lowerRange: `${newLower}`,
        upperRange: 0,
        newlyAdded: true,
      });
      setSalaryBrackets(newSalaryBrackets);
    }
  };

  useEffect(() => {
    if (editMode) {
      if (editMode && dbValue && typeof dbValue === "string") {
        const result = dbValue.split(",").map((item: any) => {
          const [lowerRange, upperRange] = item.split("-");
          return {
            lowerRange: parseInt(lowerRange),
            upperRange,
            newlyAdded: false,
          };
        });
        setSalaryBrackets(result);
      }
    }
  }, []);

  const updateBracket = () => {
    let last = salaryBrackets[salaryBrackets.length - 1];
    if(Number(last.upperRange) === 0 && Number(last.lowerRange) && salaryBrackets.length === 1){
      setUpperWarnStatus(false);
      return  updateApi();
    }
    if (Number(last.upperRange) === 0) {
      setUpperWarn("Enter Upper range value");
      return setUpperWarnStatus(true);
    }
    if (Number(last.lowerRange) === Number(last.upperRange)) {
      setUpperWarn(
        "Upper value should be greater than the range's lower value"
      );
      return setUpperWarnStatus(true);
    }
    if (Number(last.lowerRange) > Number(last.upperRange)) {
      setUpperWarn(
        "Upper value should be greater than the range's lower value"
      );
      return setUpperWarnStatus(true);
    }
    updateApi();
  };
  return (
    <Box className="detailSection ">
      <Tooltip
        title={
          editMode
            ? `Can only add new brackets from edit and detail view`
            : "Add Salary Brackets"
        }
      >
        <div className="sectionTitle">
          <h4>Salary Brackets</h4>
        </div>
      </Tooltip>
      <Box className="band_lives">
        <Grid
          container
          spacing={3}
          style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
        >
          {salaryBrackets?.map((data, index) => (
            <Grid container spacing={3} marginTop={"12px"}>
              <Grid
                sm={6}
                md={6}
                lg={6}
                xl={6}
                width={"100%"}
                display={"flex"}
                alignItems={"center"}
              >
                <span>{index + 1}.</span>
                <div
                  className="bandlives_inner"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <RKTextField
                    class_name="inputField"
                    title="Lower Range"
                    value={
                      Number(data.lowerRange) !== 0
                        ? formatCurrency(Number(data.lowerRange))
                        : `${currency} 0`
                    } //data.lowerRange
                    attrName={[]}
                    disabled={
                      disabled
                        ? true
                        : editMode
                        ? true
                        : index === 0
                        ? true
                        : index !== 0
                    }
                    value_update={(attrName: any, value: any) => {
                      let newSalaryBrackets = [...salaryBrackets];
                      newSalaryBrackets[index].lowerRange = formatCurrency(
                        Number(extractNumbersFromString(value))
                      );
                      setSalaryBrackets(newSalaryBrackets);
                    }}
                    warn_status={false}
                  />
                  <span style={{ marginRight: "3%" }}> - </span>
                  <RKTextField
                    class_name="inputField"
                    title="Upper Range"
                    // disabled={editMode ? !salaryBrackets[index].newlyAdded : false}
                    disabled={
                      disabled
                        ? true
                        : salaryBrackets?.length - 1 === index
                        ? false
                        : true
                    }
                    value={formatCurrency(Number(data.upperRange))}
                    attrName={[]}
                    value_update={(attrName: any, value: any) => {
                      const currentLowerVal = Number(
                        extractNumbersFromString(
                          salaryBrackets[index].lowerRange.toString()
                        )
                      );
                      if (
                        currentLowerVal >=
                        Number(extractNumbersFromString(value))
                      ) {
                        setUpperWarn(
                          "Upper value should be greater than the range's lower value"
                        );
                        setInvalidSalaryBrackets(true);
                      } else {
                        setUpperWarn("");
                      }
                      if (salaryBrackets[index + 1]) {
                        const nextLowerVal = Number(
                          extractNumbersFromString(
                            salaryBrackets[index + 1].lowerRange.toString()
                          )
                        );
                        if (
                          nextLowerVal < Number(extractNumbersFromString(value))
                        ) {
                          setUpperWarn(
                            "Upper value cannot be greater than the next range's lower value"
                          );
                          setInvalidSalaryBrackets(true);
                        } else {
                          setUpperWarn("");
                          setInvalidSalaryBrackets(false);
                        }
                        if (
                          nextLowerVal !==
                          Number(extractNumbersFromString(value))
                        ) {
                          setUpperWarn(
                            "Upper value should be equal to the next range's lower value"
                          );
                          setInvalidSalaryBrackets(true);
                        } else {
                          setUpperWarn("");
                          setInvalidSalaryBrackets(false);
                        }
                      }
                      let newSalaryBrackets = [...salaryBrackets];
                      newSalaryBrackets[index].upperRange = Number(
                        extractNumbersFromString(value)
                      );
                      if (newSalaryBrackets[index + 1]) {
                        newSalaryBrackets[index + 1].lowerRange = Number(
                          extractNumbersFromString(value)
                        );
                      }
                      setSalaryBrackets(newSalaryBrackets);
                    }}
                    warn_status={
                      index === salaryBrackets?.length - 1
                        ? upperWarnStatus
                        : false
                    }
                    error_messg={upperWarn}
                  />

                  <div className="actionBtns" style={{ display: "flex" }}>
                    {index + 1 === salaryBrackets.length && (
                      <Link
                        className="blueBtn addfile ml-1"
                        sx={{
                          textDecoration: "none",
                          cursor: "pointer",
                          height: "28px",
                          // opacity: disableAdd ? "0.3" : "1"
                        }}
                        onClick={addNewBracket}
                      />
                    )}
                    <Link
                      className="redBtn delete ml-1"
                      sx={{
                        textDecoration: "none",
                        cursor: "pointer",
                        height: "28px",
                        opacity: index === 0 || editMode && stopEditOldValues && !data.newlyAdded ? "0.3" : "1",
                      }}
                      onClick={() => {
                        if (disabled || editMode && stopEditOldValues && !data.newlyAdded ) {
                          return toast.error("Edit Permissions Not Allowed");
                        }
                        if (index === 0) {
                          return;
                        }
                        setUpperWarn("");
                        let newSalaryBrackets = [...salaryBrackets];
                        if (newSalaryBrackets[index + 1]) {
                          newSalaryBrackets[index + 1].lowerRange =
                            newSalaryBrackets[index - 1].upperRange;
                        }
                        newSalaryBrackets.splice(index, 1);
                        setSalaryBrackets(newSalaryBrackets);
                      }}
                    />
                  </div>
                </div>
              </Grid>
            </Grid>
          ))}
        </Grid>
        <Grid
          container
          spacing={3}
          style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
        >
          <Grid container spacing={3} marginTop={"12px"}>
            <Grid
              sm={6}
              md={6}
              lg={6}
              xl={6}
              width={"100%"}
              display={"flex"}
              alignItems={"center"}
            >
              <span>{salaryBrackets?.length + 1}.</span>
              <div
                className="bandlives_inner"
                style={{ display: "flex", alignItems: "center" }}
              >
                <div style={{ maxWidth: "166px" }} className="disabled_inp">
                  <RKTextField
                    class_name="inputField"
                    title=""
                    value={
                      highestRange !== "0"
                        ? formatCurrency(Number(highestRange) + 1)
                        : `${currency} 0`
                    }
                    attrName={[]}
                    disabled={true}
                    value_update={() => {}}
                    warn_status={false}
                    error_messg=""
                  />
                </div>
                <span style={{ marginRight: "3%" }}> - </span>
                <div style={{ maxWidth: "166px" }} className="disabled_inp">
                  <RKTextField
                    class_name="inputField"
                    title=""
                    value={"Above"}
                    attrName={[]}
                    disabled={true}
                    value_update={() => {}}
                    warn_status={false}
                    error_messg=""
                  />
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
        {updateApi && (
          <Grid xs={12} sx={{ textAlign: "center" }}>
            <Button
              style={{
                color: "#fff",
                background: "#8a83e3",
                fontFamily: "Montserrat",
                textTransform: "capitalize",
                borderRadius: "40px",
                paddingLeft: "24px",
                paddingRight: "24px",
              }}
              onClick={updateBracket}
              disabled={disabled}
            >
              Update Salary Brackets
            </Button>
          </Grid>
        )}
      </Box>
    </Box>
  );
};

export default SalaryBracketInputs;
