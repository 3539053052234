import { URL_CONSTANTS } from "../../../APIVar/URLConstants";
import { DeleteAPI, GetAPI, PatchAPI, PostAPI } from "../../API";
import { ApiType } from "../../Enum/ETaxation";

const create_basic_info = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
  dto: any
) => {
  PostAPI.call(
    `${URL_CONSTANTS.create_basic_info}`,
    dto,
    onSuccess,
    {},
    onError
  );
};

const get_basic_info = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
  apiType: ApiType,
  orgTaxId: string
) => {
  GetAPI.call(
    `${URL_CONSTANTS.get_basic_info(apiType, orgTaxId)}`,
    onSuccess,
    {},
    onError
  );
};

const get_tax_rates_list = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void
) => {
  GetAPI.call(`${URL_CONSTANTS.get_tax_rates_list()}`, onSuccess, {}, onError);
};

const create_tax_rate = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
  dto: any
) => {
  PostAPI.call(`${URL_CONSTANTS.add_tax_rate}`, dto, onSuccess, {}, onError);
};

const delete_tax_rate = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
  parent_id: string,
  childId: string
) => {
  DeleteAPI.call(
    `${URL_CONSTANTS.delete_tax_rate(parent_id, childId)}`,
    {},
    onSuccess,
    {},
    onError
  );
};

const update_basic_info = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
  parent_id: string,
  dto: any
) => {
  PatchAPI.call(
    `${URL_CONSTANTS.update_basic_info(parent_id)}`,
    dto,
    onSuccess,
    {},
    onError
  );
};

const update_tax = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
  parent_id: string,
  taxId: string,
  dto: any
) => {
  PatchAPI.call(
    `${URL_CONSTANTS.update_tax(parent_id, taxId)}`,
    dto,
    onSuccess,
    {},
    onError
  );
};

const get_tax_type_options = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void
) => {
  GetAPI.call(`${URL_CONSTANTS.get_tax_type_options}`, onSuccess, {}, onError);
};
const get_status = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
) => {
  GetAPI.call(
    URL_CONSTANTS.get_texation_status,
    onSuccess,
    {},
    onError
  );
};
const update_status = (
  dto: any,
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
  group_id: string,
  tax_id: string,
) => {
  PatchAPI.call(
    `${URL_CONSTANTS.update_texation_status}${group_id}&childId=${tax_id}`,
    dto,
    onSuccess,
    {},
    onError
  );
};
const isBeingUsed = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
  id: string,
) => {
  GetAPI.call(
    `${URL_CONSTANTS.isStatusBeingUsed}${id}&type=TAX`,
    onSuccess,
    {},
    onError
  );
};



export const TAXATION_SERVICES = {
  get_basic_info,
  get_tax_rates_list,
  create_tax_rate,
  delete_tax_rate,
  update_basic_info,
  create_basic_info,
  update_tax,
  get_tax_type_options,
  get_status,
  update_status,
  isBeingUsed,
};
