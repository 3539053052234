import { Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../OrganisationSettings/Currencies/Currencies.scss";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { toast } from "react-toastify";
import GlobalDeletePopup from "../../../../../../Components/Broker/GlobalDeletePopup/GlobalDeletePopup";
import GlobelEditPopup from "../../../../../../Components/Broker/GlobalEditPopup/GlobalEditPopup";
import { ALL_ROUTES } from "../../../../../../Routes/all_routes";
import { E_BE_FILTER_CODE } from "../../../../../../Services/Enum/E_UGTable";
import { ACCESS_LEVEL_SERVICES } from "../../../../../../Services/Settings/AccessLevelServices";
import { RootState } from "../../../../../../Store/Store";
import { useAppSelector } from "../../../../../../Store/hooks";
import { date_time_format } from "../../../../../../Supporting files/HelpingFunction";
import SelectDropdown from "../../../../../../Supporting files/SelectDropdown/SelectDropdown";
import UGTable from "../../../../../../Supporting files/UGTable/UGTable";
import NewLoader from "../../../../../../Components/NewLoader/NewLoader";

const AccessControl = () => {
  const navigate = useNavigate();
  const user_type_dateFormat = useAppSelector((state) => state.userDetailsSlice.localInformation.dateFormat);
  const tz = useAppSelector((state: RootState) => state.userDetailsSlice.localInformation.timeZone);
  const [editStatus, setEditStatus] = useState(false);
  const [editPopupTitle, setEditPopupTitle] = useState("");
  const { companyCode, parentsId } = useAppSelector((state) => state.userDetailsSlice);
  const { BROKER } = useAppSelector((state) => state.permissionSlice);
  const [addPermission, setaddPermission] = useState<any>(null);
  const [editPermission, seteditPermission] = useState<any>(null);
  const [statusPermission, setstatusPermission] = useState<any>(null);
  const [RemovePermission, setRemovePermission] = useState<any>(null);
  const [tableLimit, setTableLimit] = useState();
  const [tableSkip, setTableSkip] = useState();
  const [deleteStatus, setDeleteStatus] = useState(false);
  const [deleteStatusStep, setDeleteStatusStep] = useState(1);
  const [array_delete, setArray_Delete] = useState<Array<{ id: number }>>([]);
  const [priorityStatus, setPriorityStatus] = useState(1);
  const [prioritystatusData, setprioritystatusData] = useState([]);
  const [isroleAssigned, setIsroleAssigned] = useState<Array<string>>([]);
  const [columns, setColumns] = useState<any>([]);
  const [rows, setRows] = useState([]);
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    setShowLoader(true);
    get_status(BROKER?.AccessControl?.status === undefined ? false : BROKER?.AccessControl?.status);
    setaddPermission(BROKER?.AccessControl?.add === undefined ? false : BROKER?.AccessControl?.add);
    seteditPermission(BROKER?.AccessControl?.edit === undefined ? false : BROKER?.AccessControl?.edit);
    setstatusPermission(BROKER?.AccessControl?.status === undefined ? false : BROKER?.AccessControl?.status);
    setRemovePermission(BROKER?.AccessControl?.remove === undefined ? false : BROKER?.AccessControl?.remove);
  }, []);

  useEffect(() => {
    get_data_access_control();
  }, [deleteStatus]);

  const get_data_access_control = () => {
    let params = {
      companyCode: parentsId ? parentsId : companyCode,
    };
    const onSuccess = (res: any) => {
      if (res) {
        setTimeout(() => {
          setShowLoader(false);
        }, 1500);
      }
      const data = res.data.data.map((e: any) => ({
        ...e,
        id: e._id,
      }));
      //.reverse();
      setRows(data);
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    ACCESS_LEVEL_SERVICES.get_accessControl_data(params, onSuccess, onError);
  };

  const delete_employee = (attrName?: any, step?: any) => {
    let ids = {
      ids: array_delete,
    };
    const onSuccess = (res: any) => {
      if (!res) {
        toast.error("Role Already assigned")
        return setDeleteStatusStep(step);
      }
      setDeleteStatusStep(2);
    };

    const onError = (err: any) => {
      console.log("error", err);
    };

    ACCESS_LEVEL_SERVICES.delete_user_accessControl(
      ids,
      onSuccess,
      {},
      onError
    );
    if (attrName === "data") {
      setDeleteStatusStep(step);
    }
  };

  const edit_navigate = () => {
    if (array_delete.length === 0) {
      setEditPopupTitle("Please select the role for edit");
      setEditStatus(true);
      return;
    } else if (array_delete.length > 1) {
      setEditPopupTitle("Only one role allow at a time");
      setEditStatus(true);
      return;
    } else {
      navigate(`${ALL_ROUTES.EDIT_ACCESS_CONTROL}?id=${array_delete[0]}`);
    }
  };

  const updateMasterState = (attrName: any, value: any) => {
    if (attrName === "selected_data") {
      setArray_Delete(value);
      setIsroleAssigned(value)
    } else if (attrName === "delete_step") {
      setDeleteStatusStep(value);
    } else if (attrName[0] === "updatestatus") {
      isBeingUsed(attrName[1]._id, value);
    } else {
      attrName(value);
    }
  };

  const get_status = (statusPermission1:any) => {
    const onSuccess = (res: any) => {
      let statusData = res.data.data.map((data: any) => ({
        label: data.value,
        value: data.label,
      }));
      setprioritystatusData(statusData);
      setColumns([
        {
          field: "roleid",
          headerName: "Role ID",
          width: 200,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return (
              <Link
                underline="none"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate(`${ALL_ROUTES.EDIT_ACCESS_CONTROL}?id=${row.id}`);
                }}
              >
                {row.roleId}
              </Link>
            );
          },
        },
        {
          field: "role",
          headerName: "Role",
          width: 250,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return <p>{row.roleName}</p>;
          },
        },
        {
          field: "status",
          headerName: "Status",
          minWidth: 200,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row, index } = ValueFormatterParams;
            return (
              <div id={`_${row.status}`}>
                <div className="status_dropdown" id={`_${row.status}`}>
                  {row.status === 15 ? (
                    <CheckCircleIcon id={`_${row.status.toString()}`} />
                  ) : row.status === 16 ? (
                    <HighlightOffIcon id={`_${row.status.toString()}`} />
                  ) : null}
                  <SelectDropdown
                    class_name="inputFieldd"
                    title=""
                    value={row.status}
                    attrName={["updatestatus", row, index]}
                    value_update={updateMasterState}
                    dropdown_data={statusData}
                    warn_status={false}
                    disabled={!statusPermission1}
                  />
                </div>
              </div>
            );
          },
        },
        {
          field: "createdAt",
          headerName: "Creation Date",
          width: 200,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return (
              <p>{date_time_format(row.createdAt, user_type_dateFormat, tz)}</p>
            );
          },
        },
        {
          field: "updatedDate",
          headerName: "Updation Date",
          width: 200,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return (
              <p>{date_time_format(row.updatedAt, user_type_dateFormat, tz)}</p>
            );
          },
        },
        {
          field: "assignedusers",
          headerName: "Assigned Users",
          width: 200,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return <p>{row?.totalUsers}</p>;
          },
        },
      ]);
    };

    const onError = (err: any) => {
      console.log("err", err);
    };

    ACCESS_LEVEL_SERVICES.get_status_access_control(onSuccess, onError);
  };

  const isBeingUsed = (id: string, value: any) => {
    const onSuccess = (res: any) => {
      if (res.data.data.is_exist) {
        toast.warning("This Role is being used,Can't Inactive it. ");
      } else {
        update_status(value, id);
      }
    };
    const onError = (err: any) => {
      console.log("isBeingUsed", err);
    };
    ACCESS_LEVEL_SERVICES.isBeingUsed(onSuccess, onError, id);
  };

  const update_status = (value: number, id: string) => {
    const onSuccess = (res: any) => {
      get_data_access_control();
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    ACCESS_LEVEL_SERVICES.update_status_access_control(
      {
        value: value,
      },
      onSuccess,
      onError,
      id
    );
  };

  useEffect(() => {
    array_delete?.map((id: any) => {
      let test = 0;
      const onSuccess = (res: any) => {
        let roleAssigned = res.data.data.assigned;
        if (roleAssigned) {
          if (test === 0) {
            //toast.error("Can't delete this Role Because it's being used");
            test += 1;
          }
          setIsroleAssigned(pv => pv?.filter(val => val != id))
        }
      }
      const onError = (err: any) => {
        console.log("false", err);
      };
      ACCESS_LEVEL_SERVICES.isBeingUsedRole(onSuccess, onError, id);
    })

  }, [array_delete]);

  return (
    <>
      {showLoader && (<NewLoader />)}
      <Grid container spacing={2} className="currunciesWrapper">
        <Grid xs={4}>
          <h3 className="mb-0">Access Control</h3>
        </Grid>

        <Grid xs={8} className="text-right">
          <div className="buttons">
            {addPermission && (
              <Link
                underline="none"
                className="greenBtn addfile"
                onClick={() => {
                  navigate(ALL_ROUTES.CREATE_ACCESS_CONTROL);
                }}
              >
                Create New Role
              </Link>
            )}
            {editPermission && (
              <Link
                underline="none"
                className="blueBtn edit"
                onClick={edit_navigate}
              >
                Edit
              </Link>
            )}
            {RemovePermission && isroleAssigned?.length >= 1 && (
              <Link
                underline="none"
                className="redBtn delete"
                onClick={() => {
                  setDeleteStatus(true);
                }}
              >
                Remove
              </Link>
            )}
          </div>
        </Grid>
        <Grid xs={12}>
          <div style={{ height: 400 }}>
            <UGTable
              header_data={columns}
              data={rows}
              value_update={updateMasterState}
              attrName={"UGTable"}
              BE_filter_code={E_BE_FILTER_CODE.ACCESS_CONTROL}
              refetch_data={get_data_access_control}
              setTableLimit={setTableLimit}
              setTableSkip={setTableSkip}
              paginationMode="client"
            />
          </div>
        </Grid>
        <GlobalDeletePopup
          open_status={deleteStatus}
          value_update={updateMasterState}
          attrName={setDeleteStatus}
          delete_step={deleteStatusStep}
          delete_item={() => {
            delete_employee("data", deleteStatusStep);
          }}
          ids={isroleAssigned}
          title="User Data"
        />
        <GlobelEditPopup
          popupStatus={editStatus}
          setPopupStatus={setEditStatus}
          title={editPopupTitle}
        />
      </Grid>

    </>
  );
};

export default AccessControl;
