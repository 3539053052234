import { PayloadAction } from "@reduxjs/toolkit";

import { TUserData } from "../../../Services/Types/TUserData";

// function setUserState(state: TUserData, action: PayloadAction<TUserData>) {
//   return { ...state, ...action.payload };
// }
function setUserState(state: TUserData, action: PayloadAction<TUserData>) {
  // const { ["Company Id"]: companyCode, ...rest } = action.payload;
  if (typeof action.payload === "string" || !action.payload) {
    return { ...state };
  }
  const { ["Company Id"]: companyCode, userToken, ...rest } = action.payload;
  return companyCode
    ? { ...state, ...rest, companyCode }
    : { ...state, ...action.payload };
}

type ValueTypes = keyof TUserData;

function updateUserDetails(
  state: TUserData,
  action: PayloadAction<{ key: ValueTypes; value: any }[]>
) {
  let updatedState: TUserData = { ...state };

  action.payload.forEach(({ key, value }) => {
    updatedState = { ...updatedState, [key]: value };
  });

  return updatedState;
}

function updateAppearance(state: TUserData, action: PayloadAction<number>) {
  let data: TUserData = { ...state };

  data = { ...data, appearance: { theme: action.payload } };

  return data;
}

function updateEmailConfig(state: TUserData, action: PayloadAction<{data:any,for:string}>) {
  let data: TUserData = { ...state };
  console.log("data", action.payload);
  return (data = { ...data, config_settings: {...data.config_settings, [action.payload.for]:action.payload.data} });
}
function updateStorageConfig(state: TUserData, action: PayloadAction<string>) {
  let data: TUserData = { ...state };
  console.log("dataqqqqqqqq", action.payload);
  data = { ...data, config_settings: {...data.config_settings, storage:action.payload} }
  return data;
}
export const USER_DETAILS_REDUCERS = {
  setUserState,
  updateUserDetails,
  updateAppearance,
  updateEmailConfig,
  updateStorageConfig
};

export type UserDetailsReducer = typeof USER_DETAILS_REDUCERS;
