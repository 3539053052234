import React, { useState } from "react";
import "../ResetPassword/ResetPassword.scss";
import { Box, Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { ALL_ROUTES } from "../../../../../Routes/all_routes";
import useIsMobile from "../../../../../Supporting files/MobileProvider";

interface Props {
  value_update: Function;
}

const ResetPassword: React.FC<Props> = ({ value_update }) => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  return (
    <>
      {isMobile ? (
        <Box className="m_reset_wrapper">
          <Grid container className="content_container">
            <Grid xs={12}>
              <h1>Seamless Access Awaits!</h1>
              <p>Welcome back and log in to your personalized insurance hub.</p>
            </Grid>
            <Grid xs={12} textAlign={"center"}>
              <img src="mobile/reset_man.svg" alt="man" />
            </Grid>
            <Grid xs={12} textAlign={"center"}>
              <div className="ctaBtn mb-5">
                <Button
                  onClick={() => navigate(ALL_ROUTES.LOGIN)}
                  variant="contained"
                  className="submitBtn"
                >
                  Let's Login
                </Button>
              </div>
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Box className="reset_wrapper">
          <Grid container spacing={3} className="row">
            <Grid xs={12} className="mb-5">
              <div className="backBtn">
                <ArrowBackIcon
                  onClick={() => {
                    value_update("setStartSeventhScreenfalse", false);
                  }}
                />
                <span>Back to Reset Password</span>
              </div>
            </Grid>

            <Grid xs={12}>
              <div className="reset_section">
                <div className="reset_innerSection">
                  <h5>Seamless Access Awaits!</h5>
                  <p>
                    Welcome back and log in to your personalized <br />{" "}
                    insurance hub.
                  </p>
                  <div className="ctaBtn mb-5">
                    <Button
                      onClick={() => navigate(ALL_ROUTES.LOGIN)}
                      variant="contained"
                      className="submitBtn"
                    >
                      Let's Login
                    </Button>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};

export default ResetPassword;
