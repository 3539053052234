//not used
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box, Button, IconButton, Tooltip } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import RiskScore from "../../../../Components/Desktop/Employee/RiskScore/RiskScore";
import "./Riskmap.scss";

export default function Riskmap() {
  return (
    <Grid xs className="riskmapWrapper">
      <>
        <Grid container spacing={3}>
          <Grid xs={12}>
            <Box className="pageTitle">
              <h2>
                <span>Risk</span> Score
              </h2>
              <p>
                A risk score indicates that you may not be efficiently covered
                for unforeseen risks.
              </p>
            </Box>
          </Grid>
          <Grid xs={12}>
            <Box className="riskCover">
              <Box className="scoreMeter">
                <RiskScore
                  class_name="svg-pi-wrapper riskmap"
                  progress_one={50}
                ></RiskScore>
                <p>You're in moderate risk</p>
              </Box>

              <Box className="riskScore">
                <h3>
                  Risk Cover: 58%{" "}
                  <Tooltip
                    title="Having a comprehensive health cover will guarantee you peace of mind and will help to keep your savings intact in case of a medical emergency. So, plan for it and stay financially prepared for the worst."
                    placement="right"
                  >
                    <IconButton>
                      <InfoOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                </h3>
                <p>
                  Completing your profile with accurate and up-to-date
                  information will enable you to determine your ideal insurance
                  requirements and better understand your risk score.
                </p>
                <img src="./images/risk_analyzer.svg" className="analyzer" />
              </Box>
            </Box>
          </Grid>
          <Grid md={6} xs={12} className="insuranceAdvisory">
            <div className="recommendation">
              <ul>
                <li>
                  Existing <br />
                  coverage <span>₹ 3 L</span>
                </li>
                <li>
                  Recommended <br />
                  coverage <span>₹ 10 L</span>
                </li>
              </ul>
              <h4>
                You're short of <span className="high">₹ 90 K</span>
              </h4>
              <Button className="buyPolicy">Buy Policy</Button>
            </div>
            <h3>
              You need to increase your health cover{" "}
              <div className="tip">
                Tip
                <div className="tipInfo">
                  Having a comprehensive health cover will guarantee you peace
                  of mind and will help to keep your savings intact in case of a
                  medical emergency. So, plan for it and stay financially
                  prepared for the worst. Give yourself and your family the
                  protection of health insurance and stay stress-free of rising
                  medical costs.
                </div>
              </div>
            </h3>
            <div className="protectionShield">
              <span>Rahul</span>, get a complete healthcar plan & shield your
              family against all odds.
            </div>
            <p>
              We recommend ₹10 lakh coverage for you, your spouse, and your
              children, and a separate ₹3 lakh health insurance plan for your
              parents. This way, you ensure adequate protection and save on the
              costs of a consolidated plan while providing tailored coverage for
              your parents' specific healthcare needs.
            </p>
          </Grid>
          <Grid md={6} xs={12} className="insuranceAdvisory">
            <img
              src="./images/non-need-term-insurance.svg"
              className="productImg"
            />
            <h3>
              You don't need term insurance{" "}
              <div className="tip">
                Tip
                <div className="tipInfo">
                  Term insurance is recommended for building a safety net for
                  your family. So, that even when you're not around, they'll
                  have financial security. Since you don't have dependents to
                  look after you don't need term insurance.
                </div>
              </div>
            </h3>
            <p>
              Term insurance is recommended for building a safety net for your
              family. So, that even when you're not around, they'll have
              financial security. Since you don't have dependents to look after
              you don't need term insurance.
            </p>
          </Grid>
        </Grid>
      </>
    </Grid>
  );
}
