import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import "./NewLoader.scss";

const  NewLoader = ({customMessage}:{customMessage? : string})=> {
  const [currentThought, setCurrentThought] = useState("");
  const [currentAuthor, setCurrentAuthor] = useState("");

  useEffect(() => {
    setRandomThought();
  }, []);

  const randomQuotes = [
    ' "You must be the change you wish to see in the world." - Mahatma Gandhi',
    '"The only way to do great work is to love what you do." - Steve Jobs',
    '"Life is 10% what happens to us and 90% how we react to it." - Charles R. Swindoll',
    '"Success is not the key to happiness. Happiness is the key to success." - Albert Schweitzer',
    '"The only limit to our realization of tomorrow will be our doubts of today." - Franklin D. Roosevelt',
    '"In the middle of difficulty lies opportunity." - Albert Einstein',
    '"The future belongs to those who believe in the beauty of their dreams." - Eleanor Roosevelt',
    `"Believe you can and you're halfway there." - Theodore Roosevelt`,
    `"Your time is limited, don't waste it living someone else's life." - Steve Jobs`,
    '"Happiness is not something ready-made. It comes from your own actions." - Dalai Lama',
    '"Believe in yourself and all that you are. Know that there is something inside you that is greater than any obstacle." - Christian D. Larson',
    `"Opportunities don't happen, you create them." - Chris Grosser`,
    `"The future depends on what you do today." - Mahatma Gandhi`,
    `"Strive not to be a success, but rather to be of value." - Albert Einstein`,
    `"You miss 100% of the shots you don't take." - Wayne Gretzky`,
    `"Life is what happens when you're busy making other plans." - John Lennon`,
    `"The only true wisdom is in knowing you know nothing." - Socrates`,
    `"To be yourself in a world that is constantly trying to make you something else is the greatest accomplishment." - Ralph Waldo Emerson`,
    `"It does not matter how slowly you go as long as you do not stop." - Confucius`,
    `"The only thing necessary for the triumph of evil is for good men to do nothing." - Edmund Burke`,
    `"We can't help everyone, but everyone can help someone." - Ronald Reagan`,
  ];

  const setRandomThought = () => {
    const randomIndex = Math.floor(Math.random() * randomQuotes.length);
    const quote = randomQuotes[randomIndex];
    setCurrentThought(quote.split(" - ")[0]);
    setCurrentAuthor(quote.split(" - ")[1]);
  };
  
  return (
    <Box className="newLoader">
      <div className="innerWrapper">
        {/* <h4>
          “Success tends to find those fully engaged in their pursuits, not
          those actively searching for it.”
          <span>-Henry David Thoreau</span>
        </h4> */}
        <h4>
          {customMessage ?? currentThought}
          {!customMessage &&<span>-{currentAuthor}</span>}
        </h4>

        <div className="loader-box">
          <div className="loading">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
    </Box>
  );
}

export default NewLoader;
