import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Box, Button, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ModulePermissionAccessControl from "../../../../../../Components/Broker/ModulePermissionAccessControl/ModulePermissionAccessControl";
import { AccessLevelDTO } from "../../../../../../Services/DTO/Settings/AccessLevel/AccessLevelDTO";
import { EUserType } from "../../../../../../Services/Enum/EUserType";
import { ACCESS_LEVEL_SERVICES } from "../../../../../../Services/Settings/AccessLevelServices";
import { useAppSelector } from "../../../../../../Store/hooks";
import RKTextField from "../../../../../../Supporting files/RKTextField/RKTextField";
import "../../../../../../formLayout.scss";

function CreateRole() {
  const navigate = useNavigate();
  const [roleName, setRoleName] = useState("");
  const [accessData, setAccessData] = useState<AccessLevelDTO[]>([]);
  const [sectionStatus, SetSectionStatus] = useState<string>("roleInformation");
  const [role_warn, setRole_warn] = useState<boolean>(false);
  const [subSection, setSubSection] = useState<string>("rolename");
  const updateMasterState = (attrName: any, value: any) => {
    attrName(value);
    setRole_warn(false);
  };
  const roleInformation = useRef(null);
  const rolename = useRef(null);
  const modulePermission = useRef(null);
  const scrollToSection = (elementRef: any) => { elementRef.current?.scrollIntoView({ behavior: "smooth" }); };
  const handleClicks = (ref: any) => { ref.current?.scrollIntoView({ behavior: "smooth" }); };
  const { companyCode, userType } = useAppSelector((state: any) => state.userDetailsSlice);
  const getData = (attr: any, data: any) => {
    setAccessData(data);
  };
  const SaveUserRoleData = async () => {
    let data: boolean = false;
    data = roleName.length === 0 ? true : false;
    setRole_warn(data);
    if (data === false) {
      const paylod = {
        roleName: roleName,
        parentsId: companyCode,
        perrsmission: accessData,
      };
      const onSuccess = (res: any) => {
        navigate(-1);
        toast.success("Role added Successfully");
      };
      const onError = (err: any) => {
        console.log("false", err);
      };
      ACCESS_LEVEL_SERVICES.create_new_role_employer(paylod, onSuccess, onError);
    }
  };

  useEffect(() => {
    if (userType === EUserType.SUPER || userType === EUserType.EMPLOYER) {
      setAccessData([
        {
          data: {
            module_name: "Dashboard",
            module_key: "Dashboard",
            id: "AB01",
            view: true,
            edit: false,
            csv: false,
            upload: false,
            status: false,
            add: false,
            remove: false,
            view_disable: true,
            edit_disable: true,
            csv_disable: true,
            upload_disable: true,
            status_disable: true,
            add_disable: true,
            remove_disable: true,
          },
        },
        // {
        //   data: {
        //     module_name: "Quotes",
        //     module_key: "Quotes",
        //     id: "AB02",
        //     view: true,
        //     edit: true,
        //     csv: true,
        //     upload: false,
        //     status: true,
        //     add: true,
        //     remove: true,
        //     view_disable: false,
        //     edit_disable: false,
        //     csv_disable: false,
        //     upload_disable: true,
        //     status_disable: false,
        //     add_disable: false,
        //     remove_disable: false,
        //   },
        // },
        {
          data: {
            module_name: "Policies",
            module_key: "Policies",
            id: "AB03",
            view: true,
            edit: true,
            csv: true,
            upload: false,
            status: true,
            add: true,
            remove: true,
            view_disable: false,
            edit_disable: false,
            csv_disable: false,
            upload_disable: true,
            status_disable: false,
            add_disable: false,
            remove_disable: false,
          },
        },
        // {
        //   data: {
        //     module_name: "Renewals",
        //     module_key: "Renewals",
        //     id: "AB04",
        //     view: true,
        //     edit: true,
        //     csv: true,
        //     upload: false,
        //     status: true,
        //     add: true,
        //     remove: true,
        //     view_disable: false,
        //     edit_disable: false,
        //     csv_disable: false,
        //     upload_disable: true,
        //     status_disable: false,
        //     add_disable: false,
        //     remove_disable: false,
        //   },
        // },
        // {
        //   data: {
        //     module_name: "Clients",
        //     module_key: "Clients",
        //     id: "AB05",
        //     view: true,
        //     edit: true,
        //     csv: true,
        //     upload: true,
        //     status: true,
        //     add: true,
        //     remove: true,
        //     view_disable: false,
        //     edit_disable: false,
        //     csv_disable: false,
        //     upload_disable: false,
        //     status_disable: false,
        //     add_disable: false,
        //     remove_disable: false,
        //   },
        // },
        // {
        //   data: {
        //     module_name: "Contact",
        //     module_key: "Contact",
        //     id: "AB06",
        //     view: true,
        //     edit: true,
        //     csv: true,
        //     upload: true,
        //     status: false,
        //     add: true,
        //     remove: true,
        //     view_disable: false,
        //     edit_disable: false,
        //     csv_disable: false,
        //     upload_disable: false,
        //     status_disable: true,
        //     add_disable: false,
        //     remove_disable: false,
        //   },
        // },
        {
          data: {
            module_name: "Members",
            module_key: "Members",
            id: "AB07",
            view: true,
            edit: true,
            csv: true,
            upload: true,
            status: true,
            add: true,
            remove: true,
            view_disable: false,
            edit_disable: false,
            csv_disable: false,
            upload_disable: false,
            status_disable: false,
            add_disable: false,
            remove_disable: false,
          },
        },
        {
          data: {
            module_name: "Endorsement",
            module_key: "Endorsement",
            id: "AB09",
            view: true,
            edit: true,
            csv: true,
            upload: false,
            status: true,
            add: true,
            remove: true,
            view_disable: false,
            edit_disable: false,
            csv_disable: false,
            upload_disable: true,
            status_disable: false,
            add_disable: false,
            remove_disable: false,
          },
        },
        {
          data: {
            module_name: "Claims",
            module_key: "Claims",
            id: "AB10",
            view: true,
            edit: true,
            csv: true,
            upload: false,
            status: true,
            add: true,
            remove: true,
            view_disable: false,
            edit_disable: false,
            csv_disable: false,
            upload_disable: true,
            status_disable: false,
            add_disable: false,
            remove_disable: false,
          },
        },
        {
          data: {
            module_name: "Reports",
            module_key: "Reports",
            id: "AB11",
            view: true,
            edit: false,
            csv: true,
            upload: false,
            status: false,
            add: false,
            remove: false,
            view_disable: false,
            edit_disable: true,
            csv_disable: false,
            upload_disable: true,
            status_disable: true,
            add_disable: true,
            remove_disable: true,
          },
        },
        {
          data: {
            module_name: "Community",
            module_key: "Community",
            id: "AB08",
            view: true,
            edit: true,
            csv: false,
            upload: false,
            status: false,
            add: true,
            remove: true,
            view_disable: false,
            edit_disable: false,
            csv_disable: true,
            upload_disable: true,
            status_disable: true,
            add_disable: false,
            remove_disable: false,
          },
        },
        {
          data: {
            module_name: "Support",
            module_key: "Support",
            id: "AB12",
            view: true,
            edit: false,
            csv: false,
            upload: false,
            status: false,
            add: true,
            remove: true,
            view_disable: false,
            edit_disable: true,
            csv_disable: true,
            upload_disable: true,
            status_disable: true,
            add_disable: false,
            remove_disable: false,
          },
        },
        {
          data: {
            module_name: "Settings",
            module_key: "Settings",
            id: "AB13",
            view: true,
            edit: true,
            csv: false,
            upload: false,
            status: false,
            add: false,
            remove: false,
            view_disable: true,
            edit_disable: true,
            csv_disable: false,
            upload_disable: false,
            status_disable: false,
            add_disable: false,
            remove_disable: false,
          },
          child_open_status: false,
          child: [
            {
              data: {
                module_name: "General",
                module_key: "General",
                id: "AB0111",
                view: false,
                edit: false,
                csv: false,
                upload: false,
                status: false,
                add: false,
                remove: false,
                view_disable: false,
                edit_disable: false,
                csv_disable: false,
                upload_disable: false,
                status_disable: false,
                add_disable: false,
                remove_disable: false,
              },
              child_open_status: false,
              child: [
                {
                  data: {
                    module_name: "Personal Setting",
                    module_key: "personalSetting",
                    id: "AB0121",
                    view: true,
                    edit: true,
                    csv: false,
                    upload: false,
                    status: false,
                    add: false,
                    remove: false,
                    view_disable: true,
                    edit_disable: true,
                    csv_disable: true,
                    upload_disable: true,
                    status_disable: true,
                    add_disable: true,
                    remove_disable: true,
                  },
                },
                {
                  data: {
                    module_name: "Email Setting",
                    module_key: "EmailSetting",
                    id: "AB0122",
                    view: false,
                    edit: false,
                    csv: false,
                    upload: false,
                    status: false,
                    add: false,
                    remove: false,
                    view_disable: false,
                    edit_disable: false,
                    csv_disable: true,
                    upload_disable: true,
                    status_disable: true,
                    add_disable: false,
                    remove_disable: false,
                  },
                },
                {
                  data: {
                    module_name: "Appearance",
                    module_key: "Appearance",
                    id: "AB0123",
                    view: true,
                    edit: true,
                    csv: false,
                    upload: false,
                    status: false,
                    add: false,
                    remove: false,
                    view_disable: true,
                    edit_disable: true,
                    csv_disable: true,
                    upload_disable: true,
                    status_disable: true,
                    add_disable: true,
                    remove_disable: true,
                  },
                },
              ],
            },
            {
              data: {
                module_name: "Organisation",
                module_key: "Organisation",
                id: "OR01",
                view: false,
                edit: false,
                csv: false,
                upload: false,
                status: false,
                add: false,
                remove: false,
                view_disable: false,
                edit_disable: false,
                csv_disable: false,
                upload_disable: false,
                status_disable: false,
                add_disable: false,
                remove_disable: false,
              },
              child_open_status: false,
              child: [
                {
                  data: {
                    module_name: "Company details",
                    module_key: "CompanyDetails",
                    id: "OR011",
                    view: true,
                    edit: true,
                    csv: false,
                    upload: false,
                    status: false,
                    add: true,
                    remove: false,
                    view_disable: false,
                    edit_disable: false,
                    csv_disable: true,
                    upload_disable: true,
                    status_disable: true,
                    add_disable: false,
                    remove_disable: true,
                  },
                },
                {
                  data: {
                    module_name: "Branches",
                    module_key: "Branches",
                    id: "OR012",
                    view: true,
                    edit: true,
                    csv: false,
                    upload: false,
                    status: false,
                    add: true,
                    remove: true,
                    view_disable: false,
                    edit_disable: false,
                    csv_disable: true,
                    upload_disable: true,
                    status_disable: true,
                    add_disable: false,
                    remove_disable: false,
                  },
                },
                {
                  data: {
                    module_name: "Department",
                    module_key: "Department",
                    id: "OR013",
                    view: true,
                    edit: true,
                    csv: false,
                    upload: false,
                    status: false,
                    add: true,
                    remove: true,
                    view_disable: false,
                    edit_disable: false,
                    csv_disable: true,
                    upload_disable: true,
                    status_disable: true,
                    add_disable: false,
                    remove_disable: false,
                  },
                },
                {
                  data: {
                    module_name: "Designation",
                    module_key: "Designation",
                    id: "OR014",
                    view: true,
                    edit: true,
                    csv: false,
                    upload: false,
                    status: false,
                    add: true,
                    remove: true,
                    view_disable: false,
                    edit_disable: false,
                    csv_disable: true,
                    upload_disable: true,
                    status_disable: true,
                    add_disable: false,
                    remove_disable: false,
                  },
                },
                {
                  data: {
                    module_name: "Salary Brackets",
                    module_key: "Salary Brackets",
                    id: "OR015",
                    view: true,
                    edit: true,
                    csv: false,
                    upload: false,
                    status: false,
                    add: true,
                    remove: true,
                    view_disable: false,
                    edit_disable: false,
                    csv_disable: true,
                    upload_disable: true,
                    status_disable: true,
                    add_disable: false,
                    remove_disable: false,
                  },
                },
              ],
            },
            {
              data: {
                module_name: "User And Controls",
                module_key: "UserAndControls",
                id: "US01",
                view: false,
                edit: false,
                csv: false,
                upload: false,
                status: false,
                add: false,
                remove: false,
                view_disable: false,
                edit_disable: true,
                csv_disable: true,
                upload_disable: true,
                status_disable: true,
                add_disable: true,
                remove_disable: true,
              },
              child_open_status: false,
              child: [
                {
                  data: {
                    module_name: "Users",
                    module_key: "Users",
                    id: "US011",
                    view: true,
                    edit: true,
                    csv: false,
                    upload: false,
                    status: false,
                    add: true,
                    remove: true,
                    view_disable: false,
                    edit_disable: false,
                    csv_disable: true,
                    upload_disable: true,
                    status_disable: true,
                    add_disable: false,
                    remove_disable: false,
                  },
                },
                {
                  data: {
                    module_name: "Access Control",
                    module_key: "AccessControl",
                    id: "US012",
                    view: true,
                    edit: true,
                    csv: false,
                    upload: false,
                    status: true,
                    add: true,
                    remove: true,
                    view_disable: false,
                    edit_disable: false,
                    csv_disable: true,
                    upload_disable: true,
                    status_disable: false,
                    add_disable: false,
                    remove_disable: false,
                  },
                },
              ],
            },
            // {
            //   data: {
            //     module_name: "Customisation",
            //     module_key: "Customisation",
            //     id: "CS01",
            //     view: true,
            //     edit: false,
            //     csv: false,
            //     upload: false,
            //     status: false,
            //     add: false,
            //     remove: false,
            //     view_disable: false,
            //     edit_disable: false,
            //     csv_disable: false,
            //     upload_disable: false,
            //     status_disable: false,
            //     add_disable: false,
            //     remove_disable: false,
            //   },
            //   child_open_status: false,
            //   child: [
            //     {
            //       data: {
            //         module_name: "Module",
            //         module_key: "Module",
            //         id: "CS011",
            //         view: true,
            //         edit: true,
            //         csv: false,
            //         upload: false,
            //         status: false,
            //         add: true,
            //         remove: true,
            //         view_disable: false,
            //         edit_disable: false,
            //         csv_disable: true,
            //         upload_disable: true,
            //         status_disable: true,
            //         add_disable: false,
            //         remove_disable: false,
            //       },
            //     },
            //     {
            //       data: {
            //         module_name: "Template",
            //         module_key: "Template",
            //         id: "CS012",
            //         view: false,
            //         edit: false,
            //         csv: false,
            //         upload: false,
            //         status: false,
            //         add: false,
            //         remove: false,
            //         view_disable: false,
            //         edit_disable: false,
            //         csv_disable: true,
            //         upload_disable: true,
            //         status_disable: true,
            //         add_disable: false,
            //         remove_disable: false,
            //       },
            //     },
            //   ],
            // },
            // {
            //   data: {
            //     module_name: "Portal Setup",
            //     module_key: "PortalSetup",
            //     id: "PS01",
            //     view: false,
            //     edit: false,
            //     csv: false,
            //     upload: false,
            //     status: false,
            //     add: false,
            //     remove: false,
            //     view_disable: false,
            //     edit_disable: false,
            //     csv_disable: false,
            //     upload_disable: false,
            //     status_disable: false,
            //     add_disable: false,
            //     remove_disable: false,
            //   },
            //   child_open_status: false,
            //   child: [
            //     {
            //       data: {
            //         module_name: "Employee Portal",
            //         module_key: "EmployeePortal",
            //         id: "PS011",
            //         view: false,
            //         edit: false,
            //         csv: false,
            //         upload: false,
            //         status: false,
            //         add: false,
            //         remove: false,
            //         view_disable: false,
            //         edit_disable: false,
            //         csv_disable: true,
            //         upload_disable: true,
            //         status_disable: true,
            //         add_disable: true,
            //         remove_disable: true,
            //       },
            //     },
            //     {
            //       data: {
            //         module_name: "Employer Portal",
            //         module_key: "EmployerPortal",
            //         id: "PS012",
            //         view: false,
            //         edit: false,
            //         csv: false,
            //         upload: false,
            //         status: false,
            //         add: false,
            //         remove: false,
            //         view_disable: false,
            //         edit_disable: false,
            //         csv_disable: true,
            //         upload_disable: true,
            //         status_disable: true,
            //         add_disable: true,
            //         remove_disable: true,
            //       },
            //     },
            //   ],
            // },
            // {
            //   data: {
            //     module_name: "Data Admin",
            //     module_key: "DataAdmin",
            //     id: "DA01",
            //     view: false,
            //     edit: false,
            //     csv: false,
            //     upload: false,
            //     status: false,
            //     add: false,
            //     remove: false,
            //     view_disable: false,
            //     edit_disable: false,
            //     csv_disable: false,
            //     upload_disable: false,
            //     status_disable: false,
            //     add_disable: false,
            //     remove_disable: false,
            //   },
            //   child_open_status: false,
            //   child: [
            //     // {
            //     //   data: {
            //     //     module_name: "Storage",
            //     //     module_key: "Storage",
            //     //     id: "DA011",
            //     //     view: false,
            //     //     edit: false,
            //     //     csv: false,
            //     //     upload: false,
            //     //     status: false,
            //     //     add: false,
            //     //     remove: false,
            //     //     view_disable: false,
            //     //     edit_disable: false,
            //     //     csv_disable: true,
            //     //     upload_disable: true,
            //     //     status_disable: true,
            //     //     add_disable: true,
            //     //     remove_disable: true,
            //     //   },
            //     // },
            //     // {
            //     //   data: {
            //     //     module_name: "Export",
            //     //     module_key: "Export",
            //     //     id: "DA012",
            //     //     view: false,
            //     //     edit: false,
            //     //     csv: false,
            //     //     upload: false,
            //     //     status: false,
            //     //     add: false,
            //     //     remove: false,
            //     //     view_disable: false,
            //     //     edit_disable: false,
            //     //     csv_disable: true,
            //     //     upload_disable: true,
            //     //     status_disable: true,
            //     //     add_disable: true,
            //     //     remove_disable: true,
            //     //   },
            //     // },
            //     {
            //       data: {
            //         module_name: "Activity Log",
            //         module_key: "ActivityLog",
            //         id: "DA013",
            //         view: false,
            //         edit: false,
            //         csv: false,
            //         upload: false,
            //         status: false,
            //         add: false,
            //         remove: false,
            //         view_disable: false,
            //         edit_disable: false,
            //         csv_disable: true,
            //         upload_disable: true,
            //         status_disable: true,
            //         add_disable: true,
            //         remove_disable: true,
            //       },
            //     },
            //     {
            //       data: {
            //         module_name: "Audit Log",
            //         module_key: "AuditLog",
            //         id: "DA014",
            //         view: false,
            //         edit: false,
            //         csv: false,
            //         upload: false,
            //         status: false,
            //         add: false,
            //         remove: false,
            //         view_disable: false,
            //         edit_disable: false,
            //         csv_disable: true,
            //         upload_disable: true,
            //         status_disable: true,
            //         add_disable: true,
            //         remove_disable: true,
            //       },
            //     },
            //   ],
            // },
            // {
            //   data: {
            //     module_name: "Developer Space",
            //     module_key: "DeveloperSpace",
            //     id: "DS01",
            //     view: false,
            //     edit: false,
            //     csv: false,
            //     upload: false,
            //     status: false,
            //     add: false,
            //     remove: false,
            //     view_disable: false,
            //     edit_disable: false,
            //     csv_disable: false,
            //     upload_disable: false,
            //     status_disable: false,
            //     add_disable: false,
            //     remove_disable: false,
            //   },
            //   child_open_status: false,
            //   child: [
            //     {
            //       data: {
            //         module_name: "Integration",
            //         module_key: "Integration",
            //         id: "DS011",
            //         view: false,
            //         edit: false,
            //         csv: false,
            //         upload: false,
            //         status: false,
            //         add: false,
            //         remove: false,
            //         view_disable: false,
            //         edit_disable: false,
            //         csv_disable: true,
            //         upload_disable: true,
            //         status_disable: true,
            //         add_disable: false,
            //         remove_disable: false,
            //       },
            //     },
            //     {
            //       data: {
            //         module_name: "API & Webhooks",
            //         module_key: "API&Webhooks",
            //         id: "DS012",
            //         view: false,
            //         edit: false,
            //         csv: false,
            //         upload: false,
            //         status: false,
            //         add: false,
            //         remove: false,
            //         view_disable: false,
            //         edit_disable: false,
            //         csv_disable: true,
            //         upload_disable: true,
            //         status_disable: true,
            //         add_disable: false,
            //         remove_disable: false,
            //       },
            //     },
            //   ],
            // },
          ],
        },
      ]);
    } else {
      setAccessData([
        {
          data: {
            module_name: "Dashboard",
            module_key: "Dashboard",
            id: "AB01",
            view: true,
            edit: false,
            csv: false,
            upload: false,
            status: false,
            add: false,
            remove: false,
            view_disable: true,
            edit_disable: true,
            csv_disable: true,
            upload_disable: true,
            status_disable: true,
            add_disable: true,
            remove_disable: true,
          },
        },
        {
          data: {
            module_name: "Quotes",
            module_key: "Quotes",
            id: "AB02",
            view: false,
            edit: false,
            csv: false,
            upload: false,
            status: false,
            add: false,
            remove: false,
            view_disable: false,
            edit_disable: false,
            csv_disable: false,
            upload_disable: true,
            status_disable: false,
            add_disable: false,
            remove_disable: false,
          },
        },
        {
          data: {
            module_name: "Policies",
            module_key: "Policies",
            id: "AB03",
            view: false,
            edit: false,
            csv: false,
            upload: false,
            status: false,
            add: false,
            remove: false,
            view_disable: false,
            edit_disable: false,
            csv_disable: false,
            upload_disable: true,
            status_disable: false,
            add_disable: false,
            remove_disable: false,
          },
        },
        {
          data: {
            module_name: "Renewals",
            module_key: "Renewals",
            id: "AB04",
            view: false,
            edit: false,
            csv: false,
            upload: false,
            status: false,
            add: false,
            remove: false,
            view_disable: false,
            edit_disable: false,
            csv_disable: false,
            upload_disable: false,
            status_disable: false,
            add_disable: false,
            remove_disable: false,
          },
        },
        {
          data: {
            module_name: "Clients",
            module_key: "Clients",
            id: "AB05",
            view: false,
            edit: false,
            csv: false,
            upload: false,
            status: false,
            add: false,
            remove: false,
            view_disable: false,
            edit_disable: false,
            csv_disable: false,
            upload_disable: false,
            status_disable: false,
            add_disable: false,
            remove_disable: false,
          },
        },
        {
          data: {
            module_name: "Contact",
            module_key: "Contact",
            id: "AB06",
            view: true,
            edit: false,
            csv: false,
            upload: false,
            status: false,
            add: false,
            remove: false,
            view_disable: true,
            edit_disable: false,
            csv_disable: false,
            upload_disable: false,
            status_disable: true,
            add_disable: false,
            remove_disable: false,
          },
        },
        {
          data: {
            module_name: "Employees",
            module_key: "Employees",
            id: "AB07",
            view: false,
            edit: false,
            csv: false,
            upload: false,
            status: false,
            add: false,
            remove: false,
            view_disable: false,
            edit_disable: false,
            csv_disable: false,
            upload_disable: false,
            status_disable: false,
            add_disable: false,
            remove_disable: false,
          },
        },
        {
          data: {
            module_name: "ProductConfigurator",
            module_key: "ProductConfigurator",
            id: "AB08",
            view: false,
            edit: false,
            csv: false,
            upload: false,
            status: false,
            add: false,
            remove: false,
            view_disable: false,
            edit_disable: false,
            csv_disable: true,
            upload_disable: true,
            status_disable: true,
            add_disable: false,
            remove_disable: false,
          },
        },
        {
          data: {
            module_name: "Endorsement",
            module_key: "Endorsement",
            id: "AB09",
            view: false,
            edit: false,
            csv: false,
            upload: false,
            status: false,
            add: false,
            remove: false,
            view_disable: false,
            edit_disable: false,
            csv_disable: false,
            upload_disable: true,
            status_disable: false,
            add_disable: false,
            remove_disable: false,
          },
        },
        {
          data: {
            module_name: "Claims",
            module_key: "Claims",
            id: "AB10",
            view: false,
            edit: false,
            csv: false,
            upload: false,
            status: false,
            add: false,
            remove: false,
            view_disable: false,
            edit_disable: false,
            csv_disable: false,
            upload_disable: true,
            status_disable: false,
            add_disable: false,
            remove_disable: false,
          },
        },
        {
          data: {
            module_name: "Reports",
            module_key: "Reports",
            id: "AB11",
            view: false,
            edit: false,
            csv: false,
            upload: false,
            status: false,
            add: false,
            remove: false,
            view_disable: false,
            edit_disable: true,
            csv_disable: false,
            upload_disable: true,
            status_disable: true,
            add_disable: true,
            remove_disable: true,
          },
        },
        {
          data: {
            module_name: "Support",
            module_key: "Support",
            id: "AB12",
            view: true,
            edit: false,
            csv: false,
            upload: false,
            status: false,
            add: false,
            remove: false,
            view_disable: true,
            edit_disable: true,
            csv_disable: true,
            upload_disable: true,
            status_disable: true,
            add_disable: true,
            remove_disable: true,
          },
        },
        {
          data: {
            module_name: "Settings",
            module_key: "Settings",
            id: "AB13",
            view: true,
            edit: true,
            csv: false,
            upload: false,
            status: false,
            add: false,
            remove: false,
            view_disable: true,
            edit_disable: true,
            csv_disable: false,
            upload_disable: false,
            status_disable: false,
            add_disable: false,
            remove_disable: false,
          },
          child_open_status: false,
          child: [
            {
              data: {
                module_name: "General",
                module_key: "General",
                id: "AB0111",
                view: false,
                edit: false,
                csv: false,
                upload: false,
                status: false,
                add: false,
                remove: false,
                view_disable: false,
                edit_disable: false,
                csv_disable: false,
                upload_disable: false,
                status_disable: false,
                add_disable: false,
                remove_disable: false,
              },
              child_open_status: false,
              child: [
                {
                  data: {
                    module_name: "Personal Setting",
                    module_key: "personalSetting",
                    id: "AB0121",
                    view: true,
                    edit: true,
                    csv: false,
                    upload: false,
                    status: false,
                    add: false,
                    remove: false,
                    view_disable: true,
                    edit_disable: true,
                    csv_disable: true,
                    upload_disable: true,
                    status_disable: true,
                    add_disable: true,
                    remove_disable: true,
                  },
                },
                {
                  data: {
                    module_name: "Email Setting",
                    module_key: "EmailSetting",
                    id: "AB0122",
                    view: false,
                    edit: false,
                    csv: false,
                    upload: false,
                    status: false,
                    add: false,
                    remove: false,
                    view_disable: false,
                    edit_disable: false,
                    csv_disable: true,
                    upload_disable: true,
                    status_disable: true,
                    add_disable: false,
                    remove_disable: false,
                  },
                },
                {
                  data: {
                    module_name: "Appearance",
                    module_key: "Appearance",
                    id: "AB0123",
                    view: true,
                    edit: true,
                    csv: false,
                    upload: false,
                    status: false,
                    add: false,
                    remove: false,
                    view_disable: true,
                    edit_disable: true,
                    csv_disable: true,
                    upload_disable: true,
                    status_disable: true,
                    add_disable: true,
                    remove_disable: true,
                  },
                },
              ],
            },
            {
              data: {
                module_name: "Organisation",
                module_key: "Organisation",
                id: "OR01",
                view: false,
                edit: false,
                csv: false,
                upload: false,
                status: false,
                add: false,
                remove: false,
                view_disable: false,
                edit_disable: false,
                csv_disable: false,
                upload_disable: false,
                status_disable: false,
                add_disable: false,
                remove_disable: false,
              },
              child_open_status: false,
              child: [
                {
                  data: {
                    module_name: "Company details",
                    module_key: "CompanyDetails",
                    id: "OR011",
                    view: true,
                    edit: false,
                    csv: false,
                    upload: false,
                    status: false,
                    add: false,
                    remove: false,
                    view_disable: true,
                    edit_disable: true,
                    csv_disable: true,
                    upload_disable: true,
                    status_disable: true,
                    add_disable: true,
                    remove_disable: true,
                  },
                },
                {
                  data: {
                    module_name: "Branches",
                    module_key: "Branches",
                    id: "OR012",
                    view: true,
                    edit: false,
                    csv: false,
                    upload: false,
                    status: false,
                    add: false,
                    remove: false,
                    view_disable: true,
                    edit_disable: false,
                    csv_disable: true,
                    upload_disable: true,
                    status_disable: true,
                    add_disable: false,
                    remove_disable: false,
                  },
                },
                {
                  data: {
                    module_name: "Department",
                    module_key: "Department",
                    id: "OR013",
                    view: true,
                    edit: false,
                    csv: false,
                    upload: false,
                    status: false,
                    add: false,
                    remove: false,
                    view_disable: true,
                    edit_disable: false,
                    csv_disable: true,
                    upload_disable: true,
                    status_disable: true,
                    add_disable: false,
                    remove_disable: false,
                  },
                },
                {
                  data: {
                    module_name: "Taxation",
                    module_key: "Taxation",
                    id: "OR014",
                    view: true,
                    edit: false,
                    csv: false,
                    upload: false,
                    status: false,
                    add: false,
                    remove: false,
                    view_disable: true,
                    edit_disable: false,
                    csv_disable: true,
                    upload_disable: true,
                    status_disable: true,
                    add_disable: false,
                    remove_disable: false,
                  },
                },
                {
                  data: {
                    module_name: "Currencies",
                    module_key: "Currencies",
                    id: "OR015",
                    view: true,
                    edit: false,
                    csv: false,
                    upload: false,
                    status: false,
                    add: false,
                    remove: false,
                    view_disable: true,
                    edit_disable: false,
                    csv_disable: true,
                    upload_disable: true,
                    status_disable: true,
                    add_disable: false,
                    remove_disable: false,
                  },
                },
              ],
            },
            {
              data: {
                module_name: "User And Controls",
                module_key: "UserAndControls",
                id: "US01",
                view: false,
                edit: false,
                csv: false,
                upload: false,
                status: false,
                add: false,
                remove: false,
                view_disable: false,
                edit_disable: true,
                csv_disable: true,
                upload_disable: true,
                status_disable: true,
                add_disable: true,
                remove_disable: true,
              },
              child_open_status: false,
              child: [
                {
                  data: {
                    module_name: "Users",
                    module_key: "Users",
                    id: "US011",
                    view: false,
                    edit: false,
                    csv: false,
                    upload: false,
                    status: false,
                    add: false,
                    remove: false,
                    view_disable: false,
                    edit_disable: true,
                    csv_disable: true,
                    upload_disable: true,
                    status_disable: true,
                    add_disable: true,
                    remove_disable: true,
                  },
                },
                {
                  data: {
                    module_name: "Access Control",
                    module_key: "AccessControl",
                    id: "US012",
                    view: false,
                    edit: false,
                    csv: false,
                    upload: false,
                    status: false,
                    add: false,
                    remove: false,
                    view_disable: false,
                    edit_disable: true,
                    csv_disable: true,
                    upload_disable: true,
                    status_disable: true,
                    add_disable: true,
                    remove_disable: true,
                  },
                },
              ],
            },
            {
              data: {
                module_name: "Customisation",
                module_key: "Customisation",
                id: "CS01",
                view: false,
                edit: false,
                csv: false,
                upload: false,
                status: false,
                add: false,
                remove: false,
                view_disable: false,
                edit_disable: false,
                csv_disable: false,
                upload_disable: false,
                status_disable: false,
                add_disable: false,
                remove_disable: false,
              },
              child_open_status: false,
              child: [
                {
                  data: {
                    module_name: "Module",
                    module_key: "Module",
                    id: "CS011",
                    view: false,
                    edit: false,
                    csv: false,
                    upload: false,
                    status: false,
                    add: false,
                    remove: false,
                    view_disable: false,
                    edit_disable: false,
                    csv_disable: true,
                    upload_disable: true,
                    status_disable: true,
                    add_disable: false,
                    remove_disable: false,
                  },
                },
                // {
                //   data: {
                //     module_name: "Template",
                //     module_key: "Template",
                //     id: "CS012",
                //     view: false,
                //     edit: false,
                //     csv: false,
                //     upload: false,
                //     status: false,
                //     add: false,
                //     remove: false,
                //     view_disable: false,
                //     edit_disable: false,
                //     csv_disable: true,
                //     upload_disable: true,
                //     status_disable: true,
                //     add_disable: false,
                //     remove_disable: false,
                //   },
                // },
              ],
            },
            // {
            //   data: {
            //     module_name: "Portal Setup",
            //     module_key: "PortalSetup",
            //     id: "PS01",
            //     view: false,
            //     edit: false,
            //     csv: false,
            //     upload: false,
            //     status: false,
            //     add: false,
            //     remove: false,
            //     view_disable: false,
            //     edit_disable: false,
            //     csv_disable: false,
            //     upload_disable: false,
            //     status_disable: false,
            //     add_disable: false,
            //     remove_disable: false,
            //   },
            //   child_open_status: false,
            //   child: [
            //     {
            //       data: {
            //         module_name: "Employee Portal",
            //         module_key: "EmployeePortal",
            //         id: "PS011",
            //         view: false,
            //         edit: false,
            //         csv: false,
            //         upload: false,
            //         status: false,
            //         add: false,
            //         remove: false,
            //         view_disable: false,
            //         edit_disable: false,
            //         csv_disable: true,
            //         upload_disable: true,
            //         status_disable: true,
            //         add_disable: true,
            //         remove_disable: true,
            //       },
            //     },
            //     {
            //       data: {
            //         module_name: "Employer Portal",
            //         module_key: "EmployerPortal",
            //         id: "PS012",
            //         view: false,
            //         edit: false,
            //         csv: false,
            //         upload: false,
            //         status: false,
            //         add: false,
            //         remove: false,
            //         view_disable: false,
            //         edit_disable: false,
            //         csv_disable: true,
            //         upload_disable: true,
            //         status_disable: true,
            //         add_disable: true,
            //         remove_disable: true,
            //       },
            //     },
            //   ],
            // },
            // {
            //   data: {
            //     module_name: "Data Admin",
            //     module_key: "DataAdmin",
            //     id: "DA01",
            //     view: false,
            //     edit: false,
            //     csv: false,
            //     upload: false,
            //     status: false,
            //     add: false,
            //     remove: false,
            //     view_disable: false,
            //     edit_disable: false,
            //     csv_disable: false,
            //     upload_disable: false,
            //     status_disable: false,
            //     add_disable: false,
            //     remove_disable: false,
            //   },
            //   child_open_status: false,
            //   child: [
            //     {
            //       data: {
            //         module_name: "Storage",
            //         module_key: "Storage",
            //         id: "DA011",
            //         view: false,
            //         edit: false,
            //         csv: false,
            //         upload: false,
            //         status: false,
            //         add: false,
            //         remove: false,
            //         view_disable: false,
            //         edit_disable: false,
            //         csv_disable: true,
            //         upload_disable: true,
            //         status_disable: true,
            //         add_disable: true,
            //         remove_disable: true,
            //       },
            //     },
            //     {
            //       data: {
            //         module_name: "Export",
            //         module_key: "Export",
            //         id: "DA012",
            //         view: false,
            //         edit: false,
            //         csv: false,
            //         upload: false,
            //         status: false,
            //         add: false,
            //         remove: false,
            //         view_disable: false,
            //         edit_disable: false,
            //         csv_disable: true,
            //         upload_disable: true,
            //         status_disable: true,
            //         add_disable: true,
            //         remove_disable: true,
            //       },
            //     },
            //     {
            //       data: {
            //         module_name: "Activity Log",
            //         module_key: "ActivityLog",
            //         id: "DA013",
            //         view: false,
            //         edit: false,
            //         csv: false,
            //         upload: false,
            //         status: false,
            //         add: false,
            //         remove: false,
            //         view_disable: false,
            //         edit_disable: false,
            //         csv_disable: true,
            //         upload_disable: true,
            //         status_disable: true,
            //         add_disable: true,
            //         remove_disable: true,
            //       },
            //     },
            //     {
            //       data: {
            //         module_name: "Audit Log",
            //         module_key: "AuditLog",
            //         id: "DA014",
            //         view: false,
            //         edit: false,
            //         csv: false,
            //         upload: false,
            //         status: false,
            //         add: false,
            //         remove: false,
            //         view_disable: false,
            //         edit_disable: false,
            //         csv_disable: true,
            //         upload_disable: true,
            //         status_disable: true,
            //         add_disable: true,
            //         remove_disable: true,
            //       },
            //     },
            //   ],
            // },
            // {
            //   data: {
            //     module_name: "Developer Space",
            //     module_key: "DeveloperSpace",
            //     id: "DS01",
            //     view: false,
            //     edit: false,
            //     csv: false,
            //     upload: false,
            //     status: false,
            //     add: false,
            //     remove: false,
            //     view_disable: false,
            //     edit_disable: false,
            //     csv_disable: false,
            //     upload_disable: false,
            //     status_disable: false,
            //     add_disable: false,
            //     remove_disable: false,
            //   },
            //   child_open_status: false,
            //   child: [
            //     {
            //       data: {
            //         module_name: "Integration",
            //         module_key: "Integration",
            //         id: "DS011",
            //         view: false,
            //         edit: false,
            //         csv: false,
            //         upload: false,
            //         status: false,
            //         add: false,
            //         remove: false,
            //         view_disable: false,
            //         edit_disable: false,
            //         csv_disable: true,
            //         upload_disable: true,
            //         status_disable: true,
            //         add_disable: false,
            //         remove_disable: false,
            //       },
            //     },
            //     {
            //       data: {
            //         module_name: "API & Webhooks",
            //         module_key: "API&Webhooks",
            //         id: "DS012",
            //         view: false,
            //         edit: false,
            //         csv: false,
            //         upload: false,
            //         status: false,
            //         add: false,
            //         remove: false,
            //         view_disable: false,
            //         edit_disable: false,
            //         csv_disable: true,
            //         upload_disable: true,
            //         status_disable: true,
            //         add_disable: false,
            //         remove_disable: false,
            //       },
            //     },
            //   ],
            // },
          ],
        },
      ]);
    }
  }, [userType]);

  return (
    <Box className="layoutWrapper">
      {/* Page header */}
      <Grid container spacing={3} className="pageHeader">
        <Grid xs={6} className="pageTitle">
          <Link
            className="backStep"
            onClick={() => {
              navigate(-1);
            }}>
            <ChevronLeftIcon />
          </Link>

          <div>
            <h3>Create New Role</h3>
          </div>
        </Grid>
        <Grid xs={6}></Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid xs={12}>
          <Grid container spacing={3}>
            <Grid xs className="leftSection">
              <Link
                className={
                  sectionStatus === "roleInformation"
                    ? "active sectionLink roleInformation"
                    : "sectionLink roleInformation"
                }
                onClick={() => {
                  handleClicks(sectionStatus);
                  scrollToSection(roleInformation);
                  SetSectionStatus("roleInformation");
                }}>
                <span> Role Information</span>
              </Link>
              <ul className="accordion_list">
                <li>
                  <Link
                    className={
                      subSection === "rolename"
                        ? "inner_link active rolename"
                        : "inner_link  rolename"
                    }
                    onClick={() => {
                      handleClicks(subSection);
                      scrollToSection(rolename);
                      setSubSection("rolename");
                    }}>
                    Role Name
                  </Link>
                </li>
                <li>
                  <Link
                    className={
                      subSection === "modulePermission"
                        ? "inner_link active modulePermission"
                        : "inner_link modulePermission"
                    }
                    onClick={() => {
                      setSubSection("modulePermission");
                      handleClicks(subSection);
                      scrollToSection(modulePermission);
                    }}>
                    Module Permission
                  </Link>
                </li>
              </ul>
            </Grid>
            <Grid xs className="rightSection">
              <Box className="detailSection" ref={roleInformation}>
                <div ref={rolename}>
                  <div className="sectionTitle">
                    <h4>Role Name</h4>
                  </div>
                  <Grid container spacing={3}>
                    <Grid xs={12}>
                      <h5 className="subHeading">Role Name</h5>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid sm={6} md={4} lg={3} xl={3}>
                      <RKTextField
                        validation_type="name"
                        class_name="inputField"
                        title={"Role Name"}
                        value={roleName}
                        attrName={setRoleName}
                        value_update={updateMasterState}
                        warn_status={role_warn}
                      />
                    </Grid>
                  </Grid>
                </div>
                <div ref={modulePermission}>
                  <ModulePermissionAccessControl
                    value_update={getData}
                    dataArray={accessData}
                  />
                </div>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* Footer Section */}
      <Grid container spacing={3} className="footerSection">
        <Grid xs={12} className="ctaBtn">
          <Button
            variant="outlined"
            className="borderBtn"
            onClick={() => {
              navigate(-1);
            }}>
            Cancel
          </Button>

          <Button
            variant="contained"
            className="submitBtn"
            onClick={SaveUserRoleData}>
            Save
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

export default CreateRole;
