export const applyTheme = (theme: number) => {
  if (theme === 0) {
    document.body.classList.remove('theme-dark');
  } else if (theme === 1) {
    document.body.classList.add('theme-dark');
  } else {
    // Handle autoswitch code here
    const currentDate = new Date();
    const currentHour = currentDate.getHours();

    const lightThemeStartHour = 4;
    const lightThemeEndHour = 17;

    const isLightTheme = currentHour >= lightThemeStartHour && currentHour < lightThemeEndHour;

    const newTheme = isLightTheme ? 0 : 1;


    applyTheme(newTheme);
  }
};

