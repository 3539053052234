import { URL_CONSTANTS } from "../../APIVar/URLConstants";
import {GetAPI, PatchAPI} from "../API"

const get_personal_settings = (onSuccess:(data:any)=>void, onError:(err:any)=>void)=>{
    GetAPI.call(`${URL_CONSTANTS.get_personal_settings}`,onSuccess,{},onError)
}

const department_list = (onSuccess:(data:any)=>void, onError:(err:any)=>void)=>{
    GetAPI.call(`${URL_CONSTANTS.department_list}`,onSuccess,{},onError)
}


const update_personal_settings = (dto:any, onSuccess:(data:any)=>void, onError:(err:any)=>void)=>{
    PatchAPI.call(`${URL_CONSTANTS.update_personal_settings}`,dto,onSuccess,{},onError)
}

const update_password = ( onSuccess:(data:any)=>void, onError:(err:any)=>void,dto:any,userType:string)=>{
    PatchAPI.call(`${URL_CONSTANTS.update_password}`,dto,onSuccess,{},onError)
}



export const PERSONAL_SETTINGS_SERVICES = {
    get_personal_settings,
    update_personal_settings,
    department_list,
    update_password,
}