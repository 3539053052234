import { URL_CONSTANTS } from "../../../APIVar/URLConstants";
import { GetAPI } from "../../API";

const get_Module_Modified_Data = (
    onSuccess: (data: any) => void,
    onError: (err: any) => void
) => {
    GetAPI.call(
        URL_CONSTANTS.get_cutom_module_modified_dates,
        onSuccess,
        {},
        onError
    );
};

export const MODULE_SETTINGS_SERVICES = {
    get_Module_Modified_Data
}