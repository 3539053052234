import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Link,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import "../PolicyHistory/PolicyHistory.scss";
import RKTextField from "../../../Supporting files/RKTextField/RKTextField";
import MultipleSearchDropdown from "../../../Supporting files/MultipleSearchDropdown/MultipleSearchDropdown";
import BulkUploadForm from "../../Common/BulkUploadFormSP/BulkUploadForm";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SearchDropdownDisable from "../../../Supporting files/SearchDropdownDisable/SearchDropdownDisable";
import SelectDropdownDisable from "../../../Supporting files/SelectDropdownDisable/SelectDropdownDisable";
import DatePickerDisable from "../../../Supporting files/DatePickerDisable/DatePickerDisable";
import RKTextFieldDisable from "../../../Supporting files/RKTextFieldDisable/RKTextFieldDisable";

function DetailPolicyHistory() {
  const [selectInsurer, setSelectInsurer] = React.useState("HDFC Ergo");
  const [selectTpa, setSelectTpa] = React.useState("Good Health");
  const [startDate, setStartDate] = React.useState<Date | null>(new Date());
  const [endDate, setEndDate] = React.useState<Date | null>(new Date());
  const [premium, setPremium] = React.useState("₹ 5,000");
  const [totalLives, setTotalLives] = React.useState("200");
  const [totalEmployee, setTotalEmployee] = React.useState("300");
  const [livesPolicy, setLivesPolicy] = React.useState("100");
  const [employeePolicy, setEmployeePolicy] = React.useState("250");
  const [proposedLives, setProposedLives] = React.useState("210");
  const [proposedEmployees, setProposedEmployees] = React.useState("150");
  const [numberGrade, setNumberGrade] = React.useState("5");
  const [gradeCreation, setGradeCreation] = React.useState("Designation");
  const [designations, setDesignations] = React.useState("");
  const [sumInsured, setSumInsured] = React.useState("₹ 1,00,000");
  const [basicConfiguration, setBasicConfiguration] =
    React.useState("1E+1S+1C+1C");
  const [individualConfiguration, setIndividualConfiguration] =
    React.useState("1P+1P+1PIL+1PIL");
  const [showcorporate, setShowcorporate] = React.useState(false);
  const [showcopayPercentage, setshowcopayPercentage] = React.useState(false);
  const [amount, setAmount] = React.useState("₹ 50,000");
  const [copayPercentage, setCopayPercentage] = React.useState("10%");
  const [deletionMember, setDeletionMember] =
    React.useState("On Pro-Rata Basis");
  const [intimationClause, setIntimationClause] = React.useState(
    "Within 7 days of Hospitalization"
  );
  const [submissionClause, setSubmissionClause] = React.useState(
    "Within 30 days of Discharge For Post Hospitalization"
  );
  const [midTerm, setMidTerm] = React.useState(
    "Allowed in case of promotion only"
  );
  const [conditionOne, setConditionOne] = React.useState("");
  const [conditionTwo, setConditionTwo] = React.useState("");
  const [endorsementPremium, setEndorsementPremium] =
    React.useState("₹ 10,000");
  const [misDate, setMisDate] = React.useState<Date | null>(new Date());
  const [claimPaid, setClaimPaid] = React.useState("₹ 10,000");
  const [outstandingClaims, setOutstandingClaims] = React.useState("₹ 10,000");
  const [claimIncurred, setClaimIncurred] = React.useState("₹ 20,000");
  const [claimRatio, setClaimRatio] = React.useState("10%");
  const [projectClaim, setProjectClaim] = React.useState("");
  const [existingDisease, setExistingDisease] = React.useState(
    "Covered from day one"
  );
  const [waitingPeriod, setWaitingPeriod] = React.useState(
    "First 30 Days Waiting Period"
  );
  const [exclusion, setExclusion] = React.useState(
    "1st/2nd/3rd/4th Year Exclusion"
  );
  const [maternity, setMaternity] = React.useState("Yes");
  const [maternityLimits, setMaternityLimits] = React.useState("₹ 1,00,000");
  const [sectionDelivery, setSectionDelivery] = React.useState("₹ 2,00,000");
  const [awaitingPeriod, setAwaitingPeriod] = React.useState("Applicable");
  const [babyCoverage, setBabyCoverage] = React.useState("Not Covered");
  const [natalCoverage, setNatalCoverage] = React.useState("Not Covered");
  const [preHospitalization, setPreHospitalization] = React.useState("30 Days");
  const [postHospitalization, setPostHospitalization] =
    React.useState("40 Days");
  const [domicileHospitalization, setDomicileHospitalization] =
    React.useState("Covered");
  const [terrorism, setTerrorism] = React.useState("Not Covered");
  const [restriction, setRestriction] = React.useState("No Room Rent Capping");
  const [ambulance, setAmbulance] = React.useState("1% of Sum Insured");
  const [treatment, setTreatment] = React.useState("Not Covered");
  const [ailments, setAilments] = React.useState("No Cappings");
  const [internal, setInternal] = React.useState("Covered");
  const [external, setExternal] = React.useState("Not Covered");
  const [uploadFile, setUploadFile] = React.useState(false);
  const [deleteStatus, member_table_data] = React.useState(false);
  const [toggle, setToggle] = React.useState(true);
  const [expanded, setExpanded] = React.useState<string | false>("panel");
  const [coverageType, setCoverageType] = React.useState(0);

  const updateMasterState = (attrName: any, value: any) => {
    attrName(value);
  };
  const handleClick = () => {
    setToggle(true);
  };
  function toggleShowcorporate() {
    setShowcorporate(!showcorporate);
  }
  function toggleshowcopayPercentage() {
    setshowcopayPercentage(!showcopayPercentage);
  }
  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const [empGradeData, setEmpGradeData] = React.useState<
    Array<{
      sum_insured: string;
      emp_count: string;
      deleteable: boolean;
      features: Array<string>;
    }>
  >([{ sum_insured: "", emp_count: "", deleteable: false, features: [] }]);
  const selectInsurer_data = [
    { label: "HDFCErgo", value: "HDFC Ergo" },
    { label: "BajajAllianz", value: "Bajaj Allianz" },
    { label: "Reliance", value: "Reliance" },
    { label: "Star", value: "Star" },
    { label: "Royalsundaram", value: "Royal sundaram" },
  ];
  const tpa_data = [
    { key: "GoodHealth", value: "Good Health" },
    { key: "GoodHealth", value: "Good Health" },
  ];
  const numberGrade_data = [
    { key: "2", value: "2" },
    { key: "3", value: "3" },
    { key: "4", value: "4" },
    { key: "5", value: "5" },
  ];
  const gradeCreation_data = [
    { key: "Designation", value: "Designation" },
    { key: "Salary", value: "Salary" },
    { key: "Salary Grade", value: "Salary Grade" },
  ];
  const designations_data = [
    { key: "Assistant Manager", year: "Assistant Manager" },
    { key: "Trainee", year: "Trainee" },
    { key: "Deputy Manager", year: "Deputy Manager" },
    { key: "Software Engineer", year: "Software Engineer" },
  ];
  const sumInsured_data = [
    { key: "₹ 5 Lakh", year: "₹ 5 Lakh" },
    { key: "₹ 6 Lakh", year: "₹ 6 Lakh" },
    { key: "₹ 7 Lakh", year: "₹ 7 Lakh" },
    { key: "₹ 8 Lakh", year: "₹ 8 Lakh" },
  ];
  const basicConfiguration_data = [
    { key: "1E", value: "1E" },
    { key: "1E+1S", value: "1E + 1S" },
    { key: "1E+1S+1C", value: "1E + 1S + 1C" },
    { key: "1E+1S+1C+1C", value: "1E + 1S + 1C + 1C" },
  ];
  const individualConfiguration_data = [
    { key: "NIL", value: "NIL" },
    { key: "1P", value: "1P + 1P" },
    { key: "1PIL", value: "1PIL + 1PIL" },
    { key: "1P+1P+1PIL+1PIL", value: "1P + 1P + 1PIL + 1PIL" },
  ];
  const existingDisease_data = [
    { key: "Coveredfromdayone", value: "Covered from day one" },
    { key: "Not Covered", value: "Not Covered" },
  ];
  const waitingPeriod_data = [
    {
      key: "First30DaysWaitingPeriod ",
      value: "First 30 Days Waiting Period ",
    },
    {
      key: "First 30 Days Waiting Period ",
      value: "First 30 Days Waiting Period ",
    },
    {
      key: "First 30 Days Waiting Period ",
      value: "First 30 Days Waiting Period ",
    },
  ];
  const exclusion_data = [
    { key: "Waivedoff ", value: "Waived off " },
    { key: "Waivedoff ", value: "Waived off " },
    { key: "Waivedoff ", value: "Waived off " },
  ];
  const maternity_data = [
    { key: "Yes", value: "Yes" },
    { key: "No", value: "No" },
  ];
  const maternityLimits_data = [
    { label: "₹1,00,000", value: "₹ 1,00,000" },
    { label: "₹2,00,000", value: "₹ 2,00,000" },
    { label: "₹3,00,000", value: "₹ 3,00,000" },
    { label: "₹4,00,000", value: "₹ 4,00,000" },
  ];
  const sectionDelivery_data = [
    { label: "₹1,00,000", value: "₹ 1,00,000" },
    { label: "₹2,00,000", value: "₹ 2,00,000" },
    { label: "₹3,00,000", value: "₹ 3,00,000" },
    { label: "₹4,00,000", value: "₹ 4,00,000" },
  ];
  const awaitingPeriod_data = [
    { key: "Applicable", value: "Applicable" },
    { key: "Waived Off", value: "Waived Off" },
  ];
  const babyCoverage_data = [
    { key: "Coveredfromday1", value: "Covered from day 1" },
    { key: "Coveredfrom91stday", value: "Covered from 91st day" },
    { key: "NotCovered", value: "Not Covered" },
  ];
  const natalCoverage_data = [
    { key: "Covered", value: "Covered" },
    { key: "NotCovered", value: "Not Covered" },
  ];
  const preHospitalization_data = [
    { key: "30Days", value: "30 Days" },
    { key: "40Days", value: "40 Days" },
    { key: "50Days", value: "50 Days" },
  ];
  const postHospitalization_data = [
    { key: "30Days", value: "30 Days" },
    { key: "40Days", value: "40 Days" },
    { key: "50Days", value: "50 Days" },
  ];
  const domicileHospitalization_data = [
    { key: "Covered", value: "Covered" },
    { key: "NotCovered", value: "Not Covered" },
  ];
  const terrorism_data = [
    { key: "Covered", value: "Covered" },
    { key: "NotCovered", value: "Not Covered" },
  ];
  const restriction_data = [
    { key: "NoRoomRentCapping", value: "No Room Rent Capping" },
    { key: "1% & 1% (Normal & ICU)", value: "1% & 1% (Normal & ICU)" },
    { key: "1% & 2% (Normal & ICU)", value: "1% & 2% (Normal & ICU)" },
    { key: "Singla Standard AC Room", value: "Singla Standard AC Room" },
    { key: "Other", value: "Other" },
  ];
  const ambulance_data = [
    { key: "₹1,000", value: "₹ 1,000" },
    { key: "₹2,000", value: "₹ 2,000" },
    { key: "1%ofSumInsured", value: "1% of Sum Insured" },
  ];
  const treatment_data = [
    { key: "Covered", value: "Covered" },
    { key: "NotCovered", value: "Not Covered" },
  ];
  const ailments_data = [
    { key: "Applicable", value: "Applicable" },
    { key: "NoCappings", value: "No Cappings" },
  ];
  const internal_data = [
    { key: "Covered", value: "Covered" },
    { key: "NotCovered", value: "Not Covered" },
  ];
  const external_data = [
    { key: "Covered", value: "Covered" },
    { key: "NotCovered", value: "Not Covered" },
    {
      key: "Covered in Life Threatening Cases",
      value: "Covered in Life Threatening Cases",
    },
  ];
  const attatchment_table_data = [
    {
      file_name: "621c94f41153e1835257b41b_lead mockup.webp",
      document_type: "Existing Policy Copy",
      description: "Automation Enhancements and ...",
      Date: "30 Jan, 2023",
      time: "11 :30 A.M.",
    },
    {
      file_name: "621c94f41153e1835257b41b_lead mockup.webp",
      document_type: "Previous Claim Summary",
      description: "Automation Enhancements and ...",
      Date: "25 Nov, 2022",
      time: "11 :30 A.M.",
    },
    {
      file_name: "621c94f41153e1835257b41b_lead mockup.webp",
      document_type: "Proposed Member Data",
      description: "Automation Enhancements and ...",
      Date: "25 Nov, 2022",
      time: "11 :30 A.M.",
    },
    {
      file_name: "621c94f41153e1835257b41b_lead mockup.webp",
      document_type: "Previous Claim Dump",
      description: "Automation Enhancements and ...",
      Date: "25 Nov, 2022",
      time: "11 :30 A.M.",
    },
  ];
  return (
    <Box className="detailSection mt-3">
      <BulkUploadForm
        open_status={uploadFile}
        attrName={setUploadFile}
        value_update={updateMasterState}
      />
      <div className="sectionTitle">
        <h4>Policy History</h4>
        <div className="actionBtns ">
          <Link
            className="rounded_add_icons mr-3"
            sx={{
              textDecoration: "none",
              cursor: "pointer",
            }}
            onClick={() => {
              setEmpGradeData((empGradeData) => [
                ...empGradeData,
                {
                  sum_insured: "5000",
                  emp_count: "5",
                  features: ["sdf", "sdfsd"],
                  deleteable: true,
                },
              ]);
            }}
          >
            Add Policy History
          </Link>
        </div>
      </div>
      <Box className="policyDetail">
        {empGradeData.map((data, index) => (
          <Accordion
            className="Accordion_section policyyearSection gradepadding"
            onChange={handleChange("panel1")}
          >
            <Grid container spacing={0}>
              <Grid xs={10} className="px-0">
                <AccordionSummary
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  style={{
                    display: "inline-block",
                    width: "100%",
                    padding: "0",
                  }}
                >
                  <Typography className="title" style={{ width: "100%" }}>
                    <Grid container spacing={3}>
                      <Grid xs={6}>
                        <h5>HDFC ERGO</h5>
                      </Grid>
                      <Grid
                        xs={6}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <h4>CR: 20%</h4> <h4>17-03-2022 to 17-03-2023</h4>
                      </Grid>
                    </Grid>
                  </Typography>
                </AccordionSummary>
              </Grid>
              <Grid
                xs={2}
                style={{
                  alignSelf: "Center",
                  display: "flex",
                  justifyContent: "end",
                  padding: "0",
                }}
              >
                {data.deleteable === false ? null : (
                  <div className="text-right" style={{ alignSelf: "center" }}>
                    <Link
                      className="redBtn delete ml-3"
                      underline="none"
                      onClick={() => {
                        setEmpGradeData((addFeature) =>
                          empGradeData.filter((_, i) => i !== index)
                        );
                      }}
                    >
                      Remove
                    </Link>
                  </div>
                )}
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  style={{ paddingRight: "0" }}
                >
                  <Typography></Typography>
                </AccordionSummary>
              </Grid>
            </Grid>
            <AccordionDetails style={{ padding: "0" }}>
              <Grid container spacing={3} className="emp_switchFields">
                <Grid xs={12} display={"flex"} alignItems="center">
                  <span>Expiring Policy bought through us</span>
                  <input
                    type="checkbox"
                    className="switch"
                    onClick={handleClick}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid xs={12}>
                  <h6 className="heading">Previous Insurer & TPA Details</h6>
                </Grid>
                <Grid xs={4}>
                  <SearchDropdownDisable
                    class_name="inputField"
                    title="Select Insurer"
                    value={selectInsurer}
                    attrName={setSelectInsurer}
                    value_update={updateMasterState}
                    data={selectInsurer_data}
                    warn_status={false}
                    error_messg="Please select Insurer"
                  />
                </Grid>
                <Grid xs={6}>
                  <SelectDropdownDisable
                    class_name="inputField"
                    title="Select TPA"
                    value={selectTpa}
                    attrName={setSelectTpa}
                    value_update={updateMasterState}
                    dropdown_data={tpa_data}
                    warn_status={false}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid xs={12}>
                  <h6 className="heading">Policy Details</h6>
                </Grid>
                <Grid xs={4}>
                  <DatePickerDisable
                    class_name="inputField"
                    title="Policy Start Date"
                    value={startDate}
                    attrName={setStartDate}
                    value_update={updateMasterState}
                    error_message="Please enter Start Date"
                    warn_status={false}
                  />
                </Grid>
                <Grid xs={4}>
                  <DatePickerDisable
                    class_name="inputField"
                    title="Policy End Date"
                    value={endDate}
                    attrName={setEndDate}
                    value_update={updateMasterState}
                    error_message="Please enter Start Date"
                    warn_status={false}
                  />
                </Grid>
                <Grid xs={4}>
                  <RKTextFieldDisable
                    class_name="inputField"
                    title={"Premium"}
                    value={premium}
                    attrName={setPremium}
                    value_update={updateMasterState}
                    warn_status={false}
                  />
                </Grid>
                <Grid xs={4}>
                  <RKTextFieldDisable
                    class_name="inputField"
                    title="Total lives at policy inception"
                    value={totalLives}
                    attrName={setTotalLives}
                    value_update={updateMasterState}
                    warn_status={false}
                  />
                </Grid>
                <Grid xs={4}>
                  <RKTextFieldDisable
                    class_name="inputField"
                    title="Total employees at policy inception"
                    value={totalEmployee}
                    attrName={setTotalEmployee}
                    value_update={updateMasterState}
                    warn_status={false}
                  />
                </Grid>
                <Grid xs={4}>
                  <RKTextFieldDisable
                    class_name="inputField"
                    title="Total lives at policy expiry"
                    value={livesPolicy}
                    attrName={setLivesPolicy}
                    value_update={updateMasterState}
                    warn_status={false}
                  />
                </Grid>
                <Grid xs={4}>
                  <RKTextFieldDisable
                    class_name="inputField"
                    title="Total employees at policy expiry"
                    value={employeePolicy}
                    attrName={setEmployeePolicy}
                    value_update={updateMasterState}
                    warn_status={false}
                  />
                </Grid>
                <Grid xs={4}>
                  <RKTextFieldDisable
                    class_name="inputField"
                    title="Total Proposed Lives"
                    value={proposedLives}
                    attrName={setProposedLives}
                    value_update={updateMasterState}
                    warn_status={false}
                  />
                </Grid>
                <Grid xs={4}>
                  <RKTextFieldDisable
                    class_name="inputField"
                    title="Total Proposed Employees"
                    value={proposedEmployees}
                    attrName={setProposedEmployees}
                    value_update={updateMasterState}
                    warn_status={false}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid xs={12}>
                  <h6 className="heading">Previous Plan Details</h6>
                </Grid>

                <Grid xs={4} className="planTypeSection">
                  <div
                    className="radio_box plantypes"
                    onClick={() => {
                      setCoverageType(0);
                    }}
                  >
                    <input type="radio" name="test" defaultChecked />
                    <label>Flat Coevrage</label>
                  </div>
                </Grid>
                <Grid xs={4} className="planTypeSection">
                  <div
                    className="radio_box plantypes"
                    onClick={() => {
                      setCoverageType(1);
                    }}
                  >
                    <input type="radio" name="test" />
                    <label>Graded Coverage</label>
                  </div>
                </Grid>
                <Grid xs={4} className="planTypeSection">
                  <div
                    className="radio_box plantypes"
                    onClick={() => {
                      setCoverageType(0);
                    }}
                  >
                    <input type="radio" name="test" />
                    <label>Flexi Coverage</label>
                  </div>
                </Grid>

                {coverageType === 1 ? (
                  <>
                    <Grid xs={3}>
                      <SelectDropdownDisable
                        class_name="inputField"
                        title="Number of Grades"
                        value={numberGrade}
                        attrName={setNumberGrade}
                        value_update={updateMasterState}
                        dropdown_data={numberGrade_data}
                        warn_status={false}
                      />
                    </Grid>
                    <Grid xs={4}>
                      <SelectDropdownDisable
                        class_name="inputField"
                        title="Criterion for Grade Creation"
                        value={gradeCreation}
                        attrName={setGradeCreation}
                        value_update={updateMasterState}
                        dropdown_data={gradeCreation_data}
                        warn_status={false}
                      />
                    </Grid>
                    <Grid sm={5}>
                      <MultipleSearchDropdown
                        class_name="inputField"
                        title="Designations"
                        value={designations}
                        attrName={setDesignations}
                        value_update={updateMasterState}
                        multiple_data={designations_data}
                      />
                    </Grid>
                    <Grid sm={6}>
                      <MultipleSearchDropdown
                        class_name="inputField"
                        title="Sum Insured"
                        value={sumInsured}
                        attrName={setSumInsured}
                        value_update={updateMasterState}
                        multiple_data={sumInsured_data}
                      />
                    </Grid>
                  </>
                ) : null}
                {coverageType === 0 ? (
                  <Grid xs={6}>
                    <RKTextFieldDisable
                      class_name="inputField"
                      title="Sum Insured"
                      value={sumInsured}
                      attrName={setSumInsured}
                      value_update={updateMasterState}
                      warn_status={false}
                    />
                  </Grid>
                ) : null}
              </Grid>
              <Grid container spacing={3}>
                <Grid xs={12}>
                  <h6 className="heading">Previous Coverage scope</h6>
                </Grid>
                <Grid xs={12}>
                  <ul className="coverageAmt">
                    <li>
                      <div className="Checkboxx">
                        <input type="checkbox" name="check" checked />
                        <label className="employee">
                          <p>Employee</p>
                        </label>
                      </div>
                    </li>
                    <li>
                      <div className="Checkboxx ">
                        <input type="checkbox" name="check" checked />
                        <label className="spouse">
                          <p>Spouse</p>
                        </label>
                      </div>
                    </li>
                    <li>
                      <div className="Checkboxx ">
                        <input type="checkbox" name="check" checked />
                        <label className="kids">
                          <p>Kids</p>
                        </label>
                      </div>
                    </li>
                    <li>
                      <div className="Checkboxx ">
                        <input type="checkbox" name="check" />
                        <label className="parents">
                          <p>Parents</p>
                        </label>
                      </div>
                    </li>
                    <li>
                      <div className="Checkboxx ">
                        <input type="checkbox" name="check" />
                        <label className="parents">
                          <p>Parents In Law</p>
                        </label>
                      </div>
                    </li>
                  </ul>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid xs={12}>
                  <h6 className="heading">Previous Coverage Configuration</h6>
                </Grid>
                <Grid xs={4}>
                  <SelectDropdownDisable
                    class_name="inputField"
                    title="Basic Configuration"
                    value={basicConfiguration}
                    attrName={setBasicConfiguration}
                    value_update={updateMasterState}
                    dropdown_data={basicConfiguration_data}
                    warn_status={false}
                  />
                </Grid>
                <Grid xs={4}>
                  <SelectDropdownDisable
                    class_name="inputField"
                    title="Individual Configuration"
                    value={individualConfiguration}
                    attrName={setIndividualConfiguration}
                    value_update={updateMasterState}
                    dropdown_data={individualConfiguration_data}
                    warn_status={false}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid xs={12}>
                  <h6 className="heading">Previous Plan Settings</h6>
                </Grid>
                <Grid
                  xs={6}
                  className="switchFields"
                  display={"flex"}
                  alignItems="center"
                  sx={{ height: "70px" }}
                >
                  <span>Corporate Buffer</span>
                  <input
                    type="checkbox"
                    className="switch ml-3"
                    onClick={toggleShowcorporate}
                  />
                  {toggle === showcorporate ? (
                    <div className="ml-2" style={{ width: "300px" }}>
                      <RKTextFieldDisable
                        class_name="inputField"
                        title="Amount"
                        value={amount}
                        attrName={setAmount}
                        value_update={updateMasterState}
                        warn_status={false}
                      />
                    </div>
                  ) : null}
                </Grid>
                <Grid
                  xs={6}
                  className="switchFields"
                  display={"flex"}
                  alignItems="center"
                  sx={{ height: "70px" }}
                >
                  <span>CoPay</span>
                  <input
                    type="checkbox"
                    className="switch ml-3"
                    onClick={toggleshowcopayPercentage}
                  />
                  {toggle === showcopayPercentage ? (
                    <div className="ml-2" style={{ width: "300px" }}>
                      <RKTextFieldDisable
                        class_name="inputField"
                        title="Copay Percentage"
                        value={copayPercentage}
                        attrName={setCopayPercentage}
                        value_update={updateMasterState}
                        warn_status={false}
                      />
                    </div>
                  ) : null}
                </Grid>
                <Grid xs={4}>
                  <RKTextFieldDisable
                    class_name="inputField"
                    title="Additions Deletion of Members"
                    value={deletionMember}
                    attrName={setDeletionMember}
                    value_update={updateMasterState}
                    warn_status={false}
                  />
                </Grid>
                <Grid xs={4}>
                  <RKTextFieldDisable
                    class_name="inputField"
                    title="Claim Intimation Clause"
                    value={intimationClause}
                    attrName={setIntimationClause}
                    value_update={updateMasterState}
                    warn_status={false}
                  />
                </Grid>
                <Grid xs={4}>
                  <RKTextFieldDisable
                    class_name="inputField"
                    title="Claim Submission Clause"
                    value={submissionClause}
                    attrName={setSubmissionClause}
                    value_update={updateMasterState}
                    warn_status={false}
                  />
                </Grid>
                <Grid xs={4}>
                  <RKTextFieldDisable
                    class_name="inputField"
                    title="Mid Term Increase in Sum Insured"
                    value={midTerm}
                    attrName={setMidTerm}
                    value_update={updateMasterState}
                    warn_status={false}
                  />
                </Grid>
                <Grid xs={4}>
                  <RKTextField
                    class_name="inputField"
                    title="Special Condition 1 (Optional)"
                    value={conditionOne}
                    attrName={setConditionOne}
                    value_update={updateMasterState}
                    warn_status={false}
                  />
                </Grid>
                <Grid xs={4}>
                  <RKTextField
                    class_name="inputField"
                    title="Special Condition 2 (Optional)"
                    value={conditionTwo}
                    attrName={setConditionTwo}
                    value_update={updateMasterState}
                    warn_status={false}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid xs={12}>
                  <h6 className="heading">Claim Details</h6>
                </Grid>
                <Grid xs={4}>
                  <RKTextFieldDisable
                    class_name="inputField"
                    title="Post Endorsement Premium"
                    value={endorsementPremium}
                    attrName={setEndorsementPremium}
                    value_update={updateMasterState}
                    warn_status={false}
                  />
                </Grid>
                <Grid xs={4}>
                  <DatePickerDisable
                    class_name="inputField"
                    title="MIS Date"
                    value={misDate}
                    attrName={setMisDate}
                    value_update={updateMasterState}
                    error_message="Please enter Mis Date"
                    warn_status={false}
                  />
                </Grid>
                <Grid xs={4}>
                  <RKTextFieldDisable
                    class_name="inputField"
                    title="Claim Paid"
                    value={claimPaid}
                    attrName={setClaimPaid}
                    value_update={updateMasterState}
                    warn_status={false}
                  />
                </Grid>
                <Grid xs={4}>
                  <RKTextFieldDisable
                    class_name="inputField"
                    title="Outstanding Claims"
                    value={outstandingClaims}
                    attrName={setOutstandingClaims}
                    value_update={updateMasterState}
                    warn_status={false}
                  />
                </Grid>
                <Grid xs={4}>
                  <RKTextFieldDisable
                    class_name="inputField"
                    title="Claim Incurred"
                    value={claimIncurred}
                    attrName={setClaimIncurred}
                    value_update={updateMasterState}
                    warn_status={false}
                  />
                </Grid>
                <Grid xs={4}>
                  <RKTextFieldDisable
                    class_name="inputField"
                    title="Claim Ratio"
                    value={claimRatio}
                    attrName={setClaimRatio}
                    value_update={updateMasterState}
                    warn_status={false}
                  />
                </Grid>
                <Grid xs={6}>
                  <RKTextFieldDisable
                    class_name="inputField"
                    title="Annualize Projected Claim Outgo"
                    value={projectClaim}
                    attrName={setProjectClaim}
                    value_update={updateMasterState}
                    warn_status={false}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid xs={12}>
                  <h6 className="heading">Maternity & Waiting Period</h6>
                </Grid>
                <Grid xs={4}>
                  <SelectDropdownDisable
                    class_name="inputField"
                    title="Pre-existing diseases cover"
                    value={existingDisease}
                    attrName={setExistingDisease}
                    value_update={updateMasterState}
                    dropdown_data={existingDisease_data}
                    warn_status={false}
                  />
                </Grid>
                <Grid xs={4}>
                  <SelectDropdownDisable
                    class_name="inputField"
                    title="Waiting Period"
                    value={waitingPeriod}
                    attrName={setWaitingPeriod}
                    value_update={updateMasterState}
                    dropdown_data={waitingPeriod_data}
                    warn_status={false}
                  />
                </Grid>
                <Grid xs={4}>
                  <SelectDropdownDisable
                    class_name="inputField"
                    title="Exclusion Year"
                    value={exclusion}
                    attrName={setExclusion}
                    value_update={updateMasterState}
                    dropdown_data={exclusion_data}
                    warn_status={false}
                  />
                </Grid>
                <Grid xs={4}>
                  <SelectDropdownDisable
                    class_name="inputField"
                    title="Maternity cover"
                    value={maternity}
                    attrName={setMaternity}
                    value_update={updateMasterState}
                    dropdown_data={maternity_data}
                    warn_status={false}
                  />
                </Grid>
                <Grid xs={4}>
                  <SearchDropdownDisable
                    class_name="inputField"
                    title="Maternity limits for normal delivery"
                    value={maternityLimits}
                    attrName={setMaternityLimits}
                    value_update={updateMasterState}
                    data={maternityLimits_data}
                    warn_status={false}
                    error_messg="Please select Maternity"
                  />
                </Grid>
                <Grid xs={4}>
                  <SearchDropdownDisable
                    class_name="inputField"
                    title="Maternity limits c setion delivery"
                    value={sectionDelivery}
                    attrName={setSectionDelivery}
                    value_update={updateMasterState}
                    data={sectionDelivery_data}
                    warn_status={false}
                    error_messg="Please select Maternity"
                  />
                </Grid>
                <Grid xs={4}>
                  <SelectDropdownDisable
                    class_name="inputField"
                    title="9 Months waiting period"
                    value={awaitingPeriod}
                    attrName={setAwaitingPeriod}
                    value_update={updateMasterState}
                    dropdown_data={awaitingPeriod_data}
                    warn_status={false}
                  />
                </Grid>
                <Grid xs={4}>
                  <SelectDropdownDisable
                    class_name="inputField"
                    title="New born baby coverage"
                    value={babyCoverage}
                    attrName={setBabyCoverage}
                    value_update={updateMasterState}
                    dropdown_data={babyCoverage_data}
                    warn_status={false}
                  />
                </Grid>
                <Grid xs={4}>
                  <SelectDropdownDisable
                    class_name="inputField"
                    title="Pre/post natal coverage"
                    value={natalCoverage}
                    attrName={setNatalCoverage}
                    value_update={updateMasterState}
                    dropdown_data={natalCoverage_data}
                    warn_status={false}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid xs={12}>
                  <h6 className="heading">Hospitalization Expenses </h6>
                </Grid>
                <Grid xs={4}>
                  <SelectDropdownDisable
                    class_name="inputField"
                    title="Pre hospitalization expenses"
                    value={preHospitalization}
                    attrName={setPreHospitalization}
                    value_update={updateMasterState}
                    dropdown_data={preHospitalization_data}
                    warn_status={false}
                  />
                </Grid>
                <Grid xs={4}>
                  <SelectDropdownDisable
                    class_name="inputField"
                    title="Post hospitalization expenses"
                    value={postHospitalization}
                    attrName={setPostHospitalization}
                    value_update={updateMasterState}
                    dropdown_data={postHospitalization_data}
                    warn_status={false}
                  />
                </Grid>
                <Grid xs={4}>
                  <SelectDropdownDisable
                    class_name="inputField"
                    title="Domiciliary hospitalization"
                    value={domicileHospitalization}
                    attrName={setDomicileHospitalization}
                    value_update={updateMasterState}
                    dropdown_data={domicileHospitalization_data}
                    warn_status={false}
                  />
                </Grid>
                <Grid xs={4}>
                  <SelectDropdownDisable
                    class_name="inputField"
                    title="Terrorism Hospitalization"
                    value={terrorism}
                    attrName={setTerrorism}
                    value_update={updateMasterState}
                    dropdown_data={terrorism_data}
                    warn_status={false}
                  />
                </Grid>
                <Grid xs={4}>
                  <SelectDropdownDisable
                    class_name="inputField"
                    title="Room rent Restriction"
                    value={restriction}
                    attrName={setRestriction}
                    value_update={updateMasterState}
                    dropdown_data={restriction_data}
                    warn_status={false}
                  />
                </Grid>
                <Grid xs={4}>
                  <SelectDropdownDisable
                    class_name="inputField"
                    title="Emergency Ambulance Charges"
                    value={ambulance}
                    attrName={setAmbulance}
                    value_update={updateMasterState}
                    dropdown_data={ambulance_data}
                    warn_status={false}
                  />
                </Grid>
                <Grid xs={4}>
                  <SelectDropdownDisable
                    class_name="inputField"
                    title="Day Care Treatment"
                    value={treatment}
                    attrName={setTreatment}
                    value_update={updateMasterState}
                    dropdown_data={treatment_data}
                    warn_status={false}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid xs={12}>
                  <h6 className="heading">Other Coverages </h6>
                </Grid>
                <Grid xs={4}>
                  <SelectDropdownDisable
                    class_name="inputField"
                    title="Sub limit for various ailments/treatments"
                    value={ailments}
                    attrName={setAilments}
                    value_update={updateMasterState}
                    dropdown_data={ailments_data}
                    warn_status={false}
                  />
                </Grid>
                <Grid xs={4}>
                  <SelectDropdownDisable
                    class_name="inputField"
                    title="internal congenital diseases"
                    value={internal}
                    attrName={setInternal}
                    value_update={updateMasterState}
                    dropdown_data={internal_data}
                    warn_status={false}
                  />
                </Grid>
                <Grid xs={4}>
                  <SelectDropdownDisable
                    class_name="inputField"
                    title="External congenital diseases"
                    value={external}
                    attrName={setExternal}
                    value_update={updateMasterState}
                    dropdown_data={external_data}
                    warn_status={false}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid xs={12} style={{ textAlign: "end" }}>
                  <div className="actionBtns">
                    <Link
                      className="blueBtn addfile"
                      sx={{ textDecoration: "none", cursor: "pointer" }}
                      onClick={() => setUploadFile(true)}
                    >
                      Add Document
                    </Link>
                  </div>
                </Grid>
                <Grid xs={12}>
                  <div className="table">
                    <div
                      className="attatchment_table"
                      style={{ overflow: "hidden" }}
                    >
                      <ul className="heading">
                        <li>File Name</li>
                        <li>Document Type</li>
                        <li>Description</li>
                        <li>Date</li>
                        <li>Time</li>
                        <li>Actions</li>
                      </ul>

                      {attatchment_table_data.map((data, index) => (
                        <ul className="subheading">
                          <li>{data.file_name}</li>
                          <li>{data.document_type}</li>
                          <li>{data.description}</li>
                          <li>
                            <p className="date">{data.Date}</p>
                          </li>
                          <li>
                            <p className="time">{data.time}</p>
                          </li>
                          <li
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                            }}
                          >
                            <Link
                              className="blueBtn download ml-0"
                              sx={{
                                textDecoration: "none",
                                cursor: "pointer",
                              }}
                            ></Link>
                            <Link
                              className="redBtn delete"
                              onClick={() => {
                                member_table_data(true);
                              }}
                              sx={{
                                textDecoration: "none",
                                cursor: "pointer",
                              }}
                            ></Link>
                          </li>
                        </ul>
                      ))}
                    </div>
                  </div>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Box>
  );
}

export default DetailPolicyHistory;
