import { URL_CONSTANTS } from "../../APIVar/URLConstants";

import { DeleteAPI, GetAPI, PatchAPI, PostAPI } from "../API";

const get_client_list = (
  user: string,
  onSuccess: (data: any) => void,
  onError: (err: any) => void
) => {
  GetAPI.call(`${URL_CONSTANTS.get_client_list(user)}`, onSuccess, {}, onError);
};

const get_organization_data = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
  id: any
) => {
  GetAPI.call(
    `${URL_CONSTANTS.get_client_location}${id}`,
    onSuccess,
    {},
    onError
  );
};
const get_client_location_list = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
  id: any
) => {
  GetAPI.call(
    `${URL_CONSTANTS.get_client_location_list}${id}`,
    onSuccess,
    {},
    onError
  );
};

const check_ispolicy_attached_toMember = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
  id: any
) => {
  GetAPI.call(
    `${URL_CONSTANTS.check_ispolicy_attached_toMember}${id}`,
    onSuccess,
    {},
    onError
  );
};

const get_policy_tax = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void
) => {
  GetAPI.call(`${URL_CONSTANTS.get_policy_tax}`, onSuccess, {}, onError);
};


const get_tax_dropdown = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void
) => {
  GetAPI.call(`${URL_CONSTANTS.get_tax_dropdown}`, onSuccess, {}, onError);
};


const delete_attachment_policy = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
  policyId: any,
  attachmentsId: any
) => {
  DeleteAPI.call(
    `${URL_CONSTANTS.delete_attachment_policy}${attachmentsId}&policyId=${policyId}`,
    {},
    onSuccess,
    onError
  );
};

const insurance_name_dropdownlist = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
  type: any
) => {
  GetAPI.call(
    `${URL_CONSTANTS.insurance_name_dropdownlist}type=${type}`,
    onSuccess,
    {},
    onError
  );
};
const tpa_name_dropdownlist = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
) => {
  GetAPI.call(
    `${URL_CONSTANTS.tpa_name_dropdownlist}`,
    onSuccess,
    {},
    onError
  );
};

const insurance_address_dropdownlist = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
  id: any
) => {
  GetAPI.call(
    `${URL_CONSTANTS.insurance_address_dropdownlist}${id}`,
    onSuccess,
    {},
    onError
  );
};

// get policy list
const get_policy_list_view = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
  limit: number,
  skip: number,
  starDate: any,
  endDate: any,
  searchValue: string
) => {
  GetAPI.call(
    URL_CONSTANTS.get_policy_list(limit, skip, starDate, endDate, searchValue),
    onSuccess,
    {},
    onError
  );
};

// detail view page api
const get_policy_detail_view = (
  url: string,
  onSuccess: (data: any) => void,
  onError: (err: any) => void
) => {
  GetAPI.call(url, onSuccess, {}, onError);
};

const features_list = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
  code: any
) => {
  GetAPI.call(`${URL_CONSTANTS.features_list}${code}`, onSuccess, {}, onError);
};

const exclusion_list = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
  code: any
) => {
  GetAPI.call(`${URL_CONSTANTS.exclusion_list}${code}`, onSuccess, {}, onError);
};

const submit_add_policy = (
  dto: any,
  onSuccess: (data: any) => void,
  onError: (err: any) => void
) => {
  PostAPI.call(
    `${URL_CONSTANTS.submit_add_policy}`,
    dto,
    onSuccess,
    {},
    onError
  );
};

const add_policy_notes = (
  dto: any,
  id: any,
  onSuccess: (data: any) => void,
  onError: (err: any) => void
) => {
  PostAPI.call(
    `${URL_CONSTANTS.add_policy_notes}${id}`,
    dto,
    onSuccess,
    {},
    onError
  );
};

const policy_attachment = (
  dto: any,
  onSuccess: (data: any) => void,
  onError: (err: any) => void
) => {
  PostAPI.call(
    `${URL_CONSTANTS.policy_attachment}`,
    dto,
    onSuccess,
    {},
    onError
  );
};

const post_cd_balance = (
  dto: any,
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
  id: any
) => {
  PostAPI.call(
    `${URL_CONSTANTS.post_cd_balance}${id}`,
    dto,
    onSuccess,
    {},
    onError
  );
};

const get_cd_ledger = (
  url: string,
  onSuccess: (data: any) => void,
  onError: (err: any) => void
) => {
  GetAPI.call(url, onSuccess, {}, onError);
};

const get_policy_details = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
  id: any
) => {
  GetAPI.call(
    `${URL_CONSTANTS.get_policy_details}${id}`,
    onSuccess,
    {},
    onError
  );
};

const update_policy_details = (
  dto: any,
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
  id: any
) => {
  PatchAPI.call(
    `${URL_CONSTANTS.update_policy_details}${id}`,
    dto,
    onSuccess,
    {},
    onError
  );
};

const update_live_count = (
  dto: any,
  onSuccess: any,
  onError: any,
  id: string | undefined
) => {
  PatchAPI.call(
    `${URL_CONSTANTS.update_live_count}${id}`,
    dto,
    onSuccess,
    {},
    onError
  );
};


const checkGridCriteria = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
  url: any
) => {
  GetAPI.call(url, onSuccess, {}, onError);
};
const get_status = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
) => {
  GetAPI.call(
    URL_CONSTANTS.get_status_policy,
    onSuccess,
    {},
    onError
  );
};

const update_status = (
  dto: any,
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
  id: any
) => {
  PatchAPI.call(
    URL_CONSTANTS.update_policy_status(id),
    dto,
    onSuccess,
    {},
    onError
  );
};

const networkHospitalList = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
  url: string,
) => {
  GetAPI.call(
    url,
    onSuccess,
    {},
    onError
  );
};
const downloadEcards = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
  policyId:string,
  userType:string
) => {
  GetAPI.call(
    URL_CONSTANTS.download_ecard(policyId,userType),
    onSuccess,
    {},
    onError
  );
};
export const POLICIES_SERVICES = {
  get_client_list,
  get_organization_data,
  get_client_location_list,
  insurance_name_dropdownlist,
  tpa_name_dropdownlist,
  insurance_address_dropdownlist,
  features_list,
  exclusion_list,
  submit_add_policy,
  get_policy_list_view,
  add_policy_notes,
  update_live_count,
  policy_attachment,
  get_policy_detail_view,
  post_cd_balance,
  get_cd_ledger,
  get_policy_details,
  update_policy_details,
  delete_attachment_policy,
  get_policy_tax,
  check_ispolicy_attached_toMember,
  checkGridCriteria,
  get_status,
  update_status,
  get_tax_dropdown,
  networkHospitalList,
  downloadEcards,
};
