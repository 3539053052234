import React from "react";
import "./Notifications.scss";
import Grid from "@mui/material/Unstable_Grid2";
import { Box, Button, Link } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

function Notifications() {
  const [productTabsStatus, setProductTabsStatus] = React.useState("all");

  let notify = [
    {
      class_name: "claimassistant",
      title: "Claim Assistance Health",
      content:
        "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.",
    },
    {
      class_name: "employe",
      title: "New Employee",
      content:
        "There are many variations of passages of Lorem Ipsum available, by injected humour, or randomised words which don't look even slightly believable.",
    },
    {
      class_name: "support",
      title: "Support",
      content:
        "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.",
    },
  ];

  return (
    <Box className="notificationWrapper">
      <Grid container spacing={3}>
        <Grid xs={12}>
          <h3 className="mb-0">Notifications</h3>
        </Grid>
        <Grid xs={12}>
          <div className="productTabs">
            <Button
              className={
                productTabsStatus === "all" ? "defaultTab active" : "defaultTab"
              }
              onClick={() => {
                setProductTabsStatus("all");
              }}>
              New
            </Button>
            <Button
              className={
                productTabsStatus === "new" ? "defaultTab active" : "defaultTab"
              }
              onClick={() => {
                setProductTabsStatus("new");
              }}>
              Cleared
            </Button>
          </div>
        </Grid>
        <Grid xs={12}>
          <ul className="notifications d-none">
            {notify.map((data, index) => (
              <li className={data.class_name}>
                <Link title="Clear">
                  <CheckIcon />
                </Link>
                <h4>{data.title}</h4>
                <p>{data.content}</p>
              </li>
            ))}
          </ul>
        </Grid>
        <Grid xs={12}>
          <div className="quote">
            <h4>
              <span>“</span> It is the mark of an educated mind to be able to
              entertain a thought without accepting it. <span>”</span>
            </h4>
            <p>― Aristotle, Metaphysics</p>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Notifications;
