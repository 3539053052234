import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Box, Button, Link, CircularProgress } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { URL_CONSTANTS } from "../../../APIVar/URLConstants";
import { ALL_ROUTES } from "../../../Routes/all_routes";
import { BULK_UPLOAD_SERVICES } from "../../../Services/BulkUpload/BulkUploadServices";
import { TBulkUploadResponse } from "../../../Services/Types/TBulkUploadResponse";
import { useAppDispatch, useAppSelector } from "../../../Store/hooks";
import { bulkUploadSlice } from "../../../Store/Slice_Reducer/BulkUpload/BulkUploadSlice";
import "../BulkUpload.scss";
import { EMPLOYER_ALL_ROUTES } from "../../../Routes/employer_all_routes";
import { EMPLOYER_BULK_UPLOAD_SERVICES } from "../../../Services/EmployerServices/BulkUpload/Employer_Bulk_Upload";
import { EMPLOYER_URL_CONSTANTS } from "../../../APIVar/EmployerUrlConstant";
import NewLoader from "../../NewLoader/NewLoader";

function MapFieldsConfirmation({
  file,
  calledFrom,
  type,
  portal,
}: {
  file?: any;
  calledFrom?: string | null;
  type?: string | null;
  portal?: string | null | undefined;
}) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { userType,clientId : cid } = useAppSelector(state => state.userDetailsSlice)
  const bulkUploadData = useAppSelector((state) => state.bulkUpload);
  const [searchParams, setSearchParams] = useSearchParams();
  const [mapFields, setMapFields] = useState<any>({});
  const [buttonDisable, setbuttonDisable] = useState<boolean>(false);
  const [requiredFieldData, setRequiredFieldData] = useState<Array<string>>([]);
  const bulkUpload = useAppSelector((state) => state.bulkUpload);
  const [dropdown_list_data, setDropdown_list_data] = useState<Array<string>>(
    []
  );
  const [loader, setloader] = useState<boolean>(false);

  useEffect(() => {
    let requireddata: Array<string> = [];
    let obj: { [key: string]: string } = { ...mapFields };

    // data manipulation for the required field and the object for the whole values
    bulkUploadData?.map_field_data?.forEach((dataSubsection: any) => {
      dataSubsection?.subSections?.forEach((fieldDataArr: any) => {
        fieldDataArr?.fieldData?.forEach((dataValue: any, index: number) => {
          if (dataValue?.required === true) {
            requireddata.push(dataValue?.title);
            obj[dataValue.title] = dataValue.field.value;
          } else {
            obj[dataValue.title] = dataValue.field.value;
          }
        });
      });
    });
    const updatedData = Object.entries(obj).reduce(
      (acc: { [key: string]: string }, [key, value]) => {
        const trimmedValue = value?.replace(/(Column \d+)$/, "").trim();
        acc[key] = trimmedValue;
        return acc;
      },
      {}
    );

    setMapFields(updatedData);

    setRequiredFieldData(requireddata);
  }, []);

  useEffect(() => {
    get_unqiue_field_data();
  }, []);

  // unique field data get from the api response
  const get_unqiue_field_data = () => {
    let subpage = searchParams.get("subpage");

    const onSuccess = (res: any) => {
      let data: any[] = [];
      res.data.data.forEach((dataValue: any) => {
        if (dataValue.unique === true) {
          data.push(dataValue.title);
        }
      });
      if (subpage === "Dependent") {
        setDropdown_list_data([]);
      } else {
        setDropdown_list_data(data);

      }
    };
    const onError = (err: any) => {
      console.log("err", err);
    };

    if (portal === "employer") {
      if (calledFrom === EMPLOYER_ALL_ROUTES.EMPLOYEE) {
        EMPLOYER_BULK_UPLOAD_SERVICES.member_get_unique_field_data(
          userType?.toLowerCase(),
          onSuccess,
          onError
        );
      }
    } else {
      if (calledFrom === ALL_ROUTES.CONTACTS) {
        BULK_UPLOAD_SERVICES.contact_get_unique_field_data(onSuccess, onError);
      }

      if (calledFrom === ALL_ROUTES.CLIENTS) {
        BULK_UPLOAD_SERVICES.client_get_unique_field_data(onSuccess, onError);
      }

      if (calledFrom === ALL_ROUTES.EMPLOYEE) {
        BULK_UPLOAD_SERVICES.member_get_unique_field_data(
          userType?.toLowerCase(),
          onSuccess,
          onError
        );
      }

      if (calledFrom === ALL_ROUTES.PRODUCT_CONFIG_INSURERS) {
        BULK_UPLOAD_SERVICES.prod_config_insurer_unique_field_data(
          onSuccess,
          onError
        );
      }
    }
  };

  // api call for the bulk upload
  const onSubmit = () => {
    setloader(true);
console.log("portal",portal)
    if (buttonDisable) return
    setbuttonDisable(true)
    let API;

    if (portal === "employer") {
      if (calledFrom === EMPLOYER_ALL_ROUTES.EMPLOYEE) {
        API = EMPLOYER_URL_CONSTANTS.employer_bulk_upload_member;
      }
    } else {
      if (calledFrom === ALL_ROUTES.CONTACTS) {
        API = URL_CONSTANTS.bulk_upload_contact;
      }

      if (calledFrom === ALL_ROUTES.CLIENTS) {
        API = URL_CONSTANTS.bulk_upload_client;
      }

      if (calledFrom === ALL_ROUTES.EMPLOYEE) {
        API = URL_CONSTANTS.bulk_upload_member(userType?.toLowerCase());
      }

      if (calledFrom === ALL_ROUTES.PRODUCT_CONFIG_INSURERS) {
        API = URL_CONSTANTS.bulk_upload_product_config_insurer;
      }
    }

    let url = `${API}${bulkUpload.action_perform_to_duplicate_record}`;
    let uniquieData: Array<string> = [...dropdown_list_data];

    uniquieData =
      bulkUpload.find_duplicate_using === ""
        ? uniquieData
        : uniquieData.concat(bulkUpload.find_duplicate_using);

    // filter the data as per the string doenot exist duplicate in array
    let filterUniqueData = uniquieData.filter(
      (item, index) => uniquieData.indexOf(item) === index
    );
    // data changes to formdata
    const formData = new FormData();
    let dataObj
    if(userType === "EMPLOYER"){
      setMapFields({...mapFields,"Client":cid})
       dataObj = {...mapFields}
      dataObj["Client"] = cid
    }else{
      dataObj = {...mapFields}
    }
    console.log("dataObj",dataObj)
    let obj = JSON.stringify(dataObj);
    const requiredFieldDataApi = JSON.stringify(requiredFieldData);

    const unquiefield = JSON.stringify(filterUniqueData);
    formData.append("upload_file", file);
    formData.append("title", `"` + obj + `"`);
    formData.append("dateFormat", bulkUpload.data_time_format_of_selected_file);
    formData.append("requiredField", `"` + requiredFieldDataApi + `"`);
    formData.append("uniqueField", `"` + unquiefield + `"`);
    formData.append("id", bulkUpload.clientId_detail.id);
    formData.append("label", bulkUpload.clientId_detail.label);
    // formData.append("spocId", bulkUpload.clientId_detail.id);
    // formData.append("spocName", bulkUpload.clientId_detail.label);
    // formData.append("Client", bulkUpload.clientId_detail.label);
    // formData.append("ClientId", bulkUpload.clientId_detail.id);
    if (type) formData.append("type", type);
    // api calling without the patern of services api calling only for the bulk upload

    try {
      const user_token = sessionStorage.getItem("usertoken");
      axios
        .post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
            auth: user_token,
          },
        })
        .then((res: any) => {
          let results = res.data;
          let status = results.status;
          let message = results.message;
          let response: TBulkUploadResponse = results.data;
          dispatch(bulkUploadSlice.actions.setBulkUploadApiResponse(response));
          navigate(ALL_ROUTES.BULK_UPLOAD_LIST);
          setloader(false);
          if (status === "failed") {
            toast.error(message);
          }
        });
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  console.log("mapFields",mapFields)

  return (
    <>
      {loader && <NewLoader customMessage={"Just wait till your data get uploaded !"} />}
      <Box className="BulklayoutWrapper">
        {/* Page header */}
        <Grid container spacing={3} className="pageHeader">
          <Grid xs={6} className="pageTitle">
            <Link
              className="backStep"
              onClick={() => {
                dispatch(
                  bulkUploadSlice.actions.setBulkUploadPageStatus("MAP_FIELD")
                );
              }}
            >
              <ChevronLeftIcon />
            </Link>

            <div>
              <h3> Import from Spreadsheet</h3>
            </div>
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid xs={12} className="innerSection pl-10 pr-10">
            <Grid container spacing={3}>
              <Grid xs={12} className="mapfields_instructions mt-3 mb-4">
                <p>Confirm Mapping</p>

                <ul
                  className="mapcolumnList"
                  style={{ display: "flex", flexWrap: "wrap" }}
                >
                  {Object.keys(mapFields).map((key: any) => {
                    return (
                      <li style={{ width: "33%", marginBottom: "12px" }}>
                        {key}:{" "}
                        <span style={{ fontWeight: "bold" }}>
                          {mapFields[key]}
                        </span>
                      </li>
                    );
                  })}
                </ul>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={3} className="footerSection">
          <Grid xs={12} className="ctaBtn">
            <Button
              variant="outlined"
              className="borderBtn"
              onClick={() =>
                dispatch(
                  bulkUploadSlice.actions.setBulkUploadPageStatus("MAP_FIELD")
                )
              }
            >
              Previous
            </Button>
            <Button
              variant="outlined"
              className="borderBtn"
              onClick={() => {
                navigate(-1);
              }}
            >
              Cancel
            </Button>
            <Button variant="contained" className="submitBtn" onClick={onSubmit} disabled={buttonDisable} >
              Import
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>

  );
}

export default MapFieldsConfirmation;
