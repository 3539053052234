//used
import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { Box, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./FAQ.scss";

interface Props {
  data: { title: string; description: string; expanded: string };
}

const FAQ: React.FC<Props> = ({ data }) => {
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  return (
    <Box className="faq">
      <Accordion
        expanded={expanded === data.expanded}
        onChange={handleChange(data.expanded)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={data.expanded + "bh-content"}
          id={data.expanded + "bh-header"}
        >
          <Typography>{data.title}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>{data.description}</Typography>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default FAQ;
