import { createSlice } from "@reduxjs/toolkit";

import { PERMISSION_REDUCERS, PermissionReducer } from "./PermissionReducers";
import { Permission } from "../../../Services/Types/TUserData";

export const permissionSlice = createSlice<Permission, PermissionReducer>({
  name: "PERMISSION",
  initialState: {},
  reducers: PERMISSION_REDUCERS,
});
