import { URL_CONSTANTS } from "../../../APIVar/URLConstants";
import { GetAPI, PostAPI } from "../../API";



const updateMailConfig = (
  data: {
    security: boolean;
    type: string;
    smtp: string;
    port: number;
    user_name: string;
    password: string;
    from_email: string;
    to_email: string;
  },
  onSuccess: (data: any) => void,
  onError: (err: any) => void
) => {
  PostAPI.call(
    URL_CONSTANTS.update_mail_config_settings,
    data,
    onSuccess,
    onError
  );
};

const testMail = (
  id: string,
  onSuccess: (data: any) => void,
  onError: (err: any) => void
) => {
  GetAPI.call(
    URL_CONSTANTS.test_mail_config_settings(id),
    onSuccess,
    {},
    onError
  );
};

export const MAIL_SETTINGS_SERVICES = {
  updateMailConfig,
  testMail,
};
