import { URL_CONSTANTS } from "../../APIVar/URLConstants";
import { GetAPI, PatchAPI } from "../API";

const get_contact_list = (
  onSuccess: any,
  onError: any,
  limit: number,
  skip: number,
  starDate:any ,
  endDate:any,
  searchValue:string,
) => {
  GetAPI.call(
    `${URL_CONSTANTS.get_contact_list(limit, skip, starDate, endDate,searchValue)}`,
    onSuccess,
    {},
    onError
  );
};

const massModification = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void
) => {
  GetAPI.call(URL_CONSTANTS.massmodification, onSuccess, onError);
};

const updateMassmodification = (
  dto: any,
  onSuccess: (data: any) => void,
  {},
  onError: (err: any) => void,
) => {
  PatchAPI.call(
    URL_CONSTANTS.updateMassmodification,
    dto,
    onSuccess,
    onError
  );
};

export const CONTACTS_SERVICES = {
  get_contact_list,
  massModification,
  updateMassmodification,
};
