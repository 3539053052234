import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import HeaderEmployee from "../Components/Broker/Home/HeaderEmployee/HeaderEmployee";
import MobileLoader from "../Components/NewLoader/MobileLoader";
import ClaimAssistant from "../Pages/Desktop/Employeev2/ClaimAssistant/ClaimAssistant";
import Community from "../Pages/Desktop/Employeev2/Community/Community";
import Dashboard from "../Pages/Desktop/Employeev2/Dashboard/Dashboard";
import DoctorOnline from "../Pages/Desktop/Employeev2/DoctorOnline/DoctorOnline";
import EmployeeDetails from "../Pages/Desktop/Employeev2/Employees/EmployeeDetails/EmployeeDetails";
import EnrollmentDrive from "../Pages/Desktop/Employeev2/EnrollmentDrive/EnrollmentDrive";
import HomeEmployees from "../Pages/Desktop/Employeev2/HomeEmployees/HomeEmployees";
import "../Pages/Desktop/Employeev2/HomeEmployees/HomeEmployees.scss";
import MyDocument from "../Pages/Desktop/Employeev2/MyDocument/MyDocument";
import GroupHealthPolicyEmpV2 from "../Pages/Desktop/Employeev2/PolicyWallet/GroupHealthPolicy/GroupHealthPolicyEmpV2";
import PolicyWallet from "../Pages/Desktop/Employeev2/PolicyWallet/PolicyWallet";
import Profile from "../Pages/Desktop/Employeev2/Profile/Profile";
import RepairVehicle from "../Pages/Desktop/Employeev2/RepairVehicle/RepairVehicle";
import Riskmap from "../Pages/Desktop/Employeev2/Riskmap/Riskmap";
import Support from "../Pages/Desktop/Employeev2/Support/Support";
import Wellness from "../Pages/Desktop/Employeev2/Wellness/Wellness";
import useIsMobile from "../Supporting files/MobileProvider";
import "../index.scss";
import { EMPLOYEE_ALL_ROUTES } from "./employee_all_routes";

const Employee_Routes = () => {
  const location = useLocation();
  const isMobile = useIsMobile();
  const [allEmployeeRoutes, setAllEmployeeRoutes] = useState<Array<{
    path: EMPLOYEE_ALL_ROUTES;
    element: any;
    add_permission: boolean;
    view_permission: boolean;
  }>>([]);

  useEffect(() => {
    const routes: Array<{
      path: EMPLOYEE_ALL_ROUTES;
      element: any;
      add_permission: boolean;
      view_permission: boolean;
    }> = [
        {
          path: EMPLOYEE_ALL_ROUTES.DASHBOARD,
          element: <Dashboard />,
          view_permission: true,
          add_permission: true,
        },
        {
          path: EMPLOYEE_ALL_ROUTES.CLAIM_ASSISTANT,
          element: <ClaimAssistant />,
          view_permission: true,
          add_permission: true,
        },
        {
          path: EMPLOYEE_ALL_ROUTES.COMMUNITY,
          element: <Community />,
          view_permission: true,
          add_permission: true,
        },
        {
          path: EMPLOYEE_ALL_ROUTES.DOCTOR_ONLINE,
          element: <DoctorOnline />,
          view_permission: true,
          add_permission: true,
        },
        {
          path: EMPLOYEE_ALL_ROUTES.ENROLLMENT,
          element: <EnrollmentDrive />,
          view_permission: true,
          add_permission: true,
        },
        {
          path: EMPLOYEE_ALL_ROUTES.MY_DOCUMENT,
          element: <MyDocument />,
          view_permission: true,
          add_permission: true,
        },
        {
          path: EMPLOYEE_ALL_ROUTES.POLICY_WALLET,
          element: <PolicyWallet />,
          view_permission: true,
          add_permission: true,
        },
        {
          path: EMPLOYEE_ALL_ROUTES.PROFILE,
          element: <Profile />,
          view_permission: true,
          add_permission: true,
        },
        {
          path: EMPLOYEE_ALL_ROUTES.EMPLOYEE_DETAIL,
          element: <EmployeeDetails />,
          view_permission: true,
          add_permission: true,
        },
        {
          path: EMPLOYEE_ALL_ROUTES.REPAIR_VEHICLE,
          element: <RepairVehicle />,
          view_permission: true,
          add_permission: true,
        },
        {
          path: EMPLOYEE_ALL_ROUTES.REPAIR_VEHICLE,
          element: <RepairVehicle />,
          view_permission: true,
          add_permission: true,
        },
        {
          path: EMPLOYEE_ALL_ROUTES.RISKMAP,
          element: <Riskmap />,
          view_permission: true,
          add_permission: true,
        },
        {
          path: EMPLOYEE_ALL_ROUTES.SUPPORT,
          element: <Support />,
          view_permission: true,
          add_permission: true,
        },
        {
          path: EMPLOYEE_ALL_ROUTES.WELLNESS,
          element: <Wellness />,
          view_permission: true,
          add_permission: true,
        },
        {
          path: EMPLOYEE_ALL_ROUTES.GROUP_POLICY_DETAILS,
          element: <GroupHealthPolicyEmpV2 />,
          view_permission: true,
          add_permission: true,
        },
      ];

    setAllEmployeeRoutes(routes);
  }, []);

  const full_page_routes: Array<string> = [
    EMPLOYEE_ALL_ROUTES.GROUP_POLICY_DETAILS,
    EMPLOYEE_ALL_ROUTES.EMPLOYEE_DETAIL,
  ];

  return (
    <>
      {isMobile ? <MobileLoader /> : (
        <>
          {full_page_routes.includes(location.pathname as EMPLOYEE_ALL_ROUTES) ? (
            <Routes>
              {allEmployeeRoutes.map((data, index) =>
                data.view_permission && data.add_permission &&
                  full_page_routes.includes(data.path) ? (
                  // <Route path={data.path} element={isMobile ? <MobileLoader /> : data.element} />
                  <Route path={data.path} element={data.element} />
                ) : null
              )}
            </Routes>
          ) : (
            <Box className="employeeWrapper">
              <HeaderEmployee />
              <Grid container spacing={3}>
                <HomeEmployees />
                <Routes>
                  {allEmployeeRoutes.map((data, index) =>
                    data.view_permission && data.add_permission ? (
                      // <Route path={data.path} element={isMobile ? <MobileLoader /> : data.element} />
                      <Route path={data.path} element={data.element} />
                    ) : null
                  )}
                </Routes>
              </Grid>
            </Box>
          )}
        </>
      )}

    </>
  );
};

export default Employee_Routes;
