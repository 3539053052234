import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Box, Button, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ALL_ROUTES } from "../../../Routes/all_routes";
import { EMPLOYER_ALL_ROUTES } from "../../../Routes/employer_all_routes";
import { BULK_UPLOAD_SERVICES } from "../../../Services/BulkUpload/BulkUploadServices";
import { CLIENT_SERVICES } from "../../../Services/Clients/ClientService";
import { CONTACTS_SERVICES } from "../../../Services/Contacts/ContactsServices";
import { EMPLOYEE_SERVICES } from "../../../Services/Employee/EmployeeService";
import { EMPLOYER_BULK_UPLOAD_SERVICES } from "../../../Services/EmployerServices/BulkUpload/Employer_Bulk_Upload";
import {
  SelectOption,
  TBulkUploadField,
} from "../../../Services/Types/TBulkUpload";
import { useAppDispatch, useAppSelector } from "../../../Store/hooks";
import { bulkUploadSlice } from "../../../Store/Slice_Reducer/BulkUpload/BulkUploadSlice";
import { RootState } from "../../../Store/Store";
import SearchDropdown from "../../../Supporting files/SearchDropdown/SearchDropdown";
import NewLoader from "../../NewLoader/NewLoader";
import "../BulkUpload.scss";

function MapFields({
  file,
  calledFrom,
  portal,
  subpage,
}: {
  file?: any;
  calledFrom?: string | null;
  subpage?: string | null;
  portal?: string | null | undefined;
}) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { userType,clientId : cid } = useAppSelector(state => state.userDetailsSlice)
  const UserDetailsState = useAppSelector((state: RootState) => state.userDetailsSlice);
  const [loader, setloader] = useState<boolean>(true);
  const bulkUpload = useAppSelector((state) => state.bulkUpload);
  const [dropdownOptionData, setDropdownOptionData] = useState<SelectOption[]>([]);
  const [formDataField, setformDataField] = useState<TBulkUploadField>([]);
  const [selectedValues, setSelectedValues] = useState<SelectOption[]>([]);
  const [requiredFieldData, setRequiredFieldData] = useState<Array<string>>([]);
  const [objectKey, setObjectKey] = useState({});

  const [clientIdData, setClientIdData] = useState<Array<{ label: string; id: string }>>([]);
  const [clientId, setClientId] = useState<{
    id: string;
    label: string;
    warn: boolean;
  }>({ id: "", label: "", warn: false });

  useEffect(() => {
    load_form_data();
  }, []);

  useEffect(() => {
    if (portal !== "employer") {
      if (
        calledFrom === ALL_ROUTES.CONTACTS || calledFrom === ALL_ROUTES.EMPLOYEE
      ) {
        get_client_list();
      }

      // if (calledFrom === ALL_ROUTES.CLIENTS) {
      //   get_spoc_list();
      // }
    }
  }, [bulkUpload]);

  const get_spoc_list = () => {
    const onSuccess = (res: any) => {
      setClientIdData(res.data.data);
    };

    const onError = (err: any) => { };

    CLIENT_SERVICES.get_Spoc(onSuccess, onError);
  };

  // get client list
  const get_client_list = () => {
    const onSuccess = (res: any) => {
      let response = res.data.data;
      response.unshift({
        label: "None of these",
        id: "None of these",
      });
      setClientIdData(response);
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    EMPLOYEE_SERVICES.get_client_list(
      UserDetailsState?.userType?.toLowerCase(),
      onSuccess,
      onError
    );
  };

  const load_form_data = () => {
    const onSuccess = (res: any) => {
      const data: any = res.data.data;
      let requireddata: Array<string> = [];
      data.forEach((dataSubsection: any) =>
        dataSubsection.sub_section.forEach((fieldDataArr: any) =>
          fieldDataArr.fieldData.forEach((dataValue: any) => {
            if (dataValue.required === true) {
              requireddata.push(dataValue.title);
            }
          })
        )
      );
      // header data set from the excel file
      const header_data: any = bulkUpload.csv_file_header.map((dataHeader: any) => {
        if (typeof dataHeader === "number") {
          return {
            id: dataHeader.toString(),
            label: dataHeader.toString(),
          };
        } else {
          if (dataHeader.split(" ")[0] !== "") {
            return {
              id: dataHeader,
              label: dataHeader,
            };
          }
        }

      }).filter(data => data);
      setDropdownOptionData(header_data);
      setRequiredFieldData(requireddata);

      //manipulate the data of api response as per frontend requirement
      const manupilated_data: TBulkUploadField = data.map(
        (dataSubsection: any) => ({
          title: dataSubsection.title,
          subSections: dataSubsection.sub_section.map((fieldArray: any) => ({
            title: fieldArray.title,
            fieldData: fieldArray.fieldData.map((dataInput: any) => ({
              title: dataInput.title,
              required: dataInput.required,
              field: {
                value: "",
                warning: false,
              },
              dropdown_data: header_data,
            })),
          })),
        })
      );

      dispatch(
        bulkUploadSlice.actions.setBulkUploadFieldData(manupilated_data)
      );
      setformDataField(manupilated_data);
      setloader(false);
    };

    const onError = (err: any) => {
      console.log("err", err);
    };

    if (portal === "employer") {
      if (calledFrom === EMPLOYER_ALL_ROUTES.EMPLOYEE) {
        EMPLOYER_BULK_UPLOAD_SERVICES.LOAD_FIELD_DATA_FOR_BULK_UPLOAD(
          onSuccess,
          onError,
          subpage ?? "",
        );
      }

      setClientId({
        id: UserDetailsState.id,
        label: UserDetailsState.Organisation,
        warn: false,
      });
      dispatch(
        bulkUploadSlice.actions.setBulkUploadData({
          ...bulkUpload,
          clientId_detail: {
            id: UserDetailsState.id,
            label: UserDetailsState.Organisation,
          },
        })
      );
    } else {
      if (calledFrom === ALL_ROUTES.CONTACTS) {
        CONTACTS_SERVICES.LOAD_FIELD_DATA_FOR_BULK_UPLOAD(onSuccess, onError);
      }

      if (calledFrom === ALL_ROUTES.CLIENTS) {
        CLIENT_SERVICES.LOAD_FIELD_DATA_FOR_BULK_UPLOAD(onSuccess, onError);
      }

      if (calledFrom === ALL_ROUTES.EMPLOYEE) {
        if (subpage === "Employee") {
          EMPLOYEE_SERVICES.LOAD_FIELD_DATA_FOR_BULK_UPLOAD(
            onSuccess,
            onError,
            userType?.toLowerCase(),
            "EMPLOYEE"
          );
        } else if (subpage === "Dependent") {
          EMPLOYEE_SERVICES.LOAD_FIELD_DATA_FOR_BULK_UPLOAD(
            onSuccess,
            onError,
            userType?.toLowerCase(),
            "DEPENDENT"
          );
        }
      }

      if (calledFrom === ALL_ROUTES.PRODUCT_CONFIG_INSURERS) {
        BULK_UPLOAD_SERVICES.PROD_CONFIG_INSURER_LOAD_FIELD_DATA_FOR_BULK_UPLOAD(
          onSuccess,
          onError
        );
      }
    }
  };

  const handleFieldMappingChange = (
    attrName: [number, number, number],
    value: any
  ) => {
    const [mainSectionIndex, subSectionIndex, fieldDataIndex] = attrName;
    let updatedSelectedValue: any = [{ id: value.label, label: value.label }];
    var formdataValueArr = [...formDataField];
    for (var i = 0; i < formdataValueArr.length; i++) {
      const main_section = formdataValueArr[i];
      for (var j = 0; j < main_section.subSections.length; j++) {
        const sub_section = main_section.subSections[j];
        for (var k = 0; k < sub_section.fieldData.length; k++) {
          const field_value = sub_section.fieldData[k];
          if (field_value.field.value) {
            updatedSelectedValue.push({
              id: field_value.field.value,
              label: field_value.field.value,
            });
          } else if (value.label === "") {
            updatedSelectedValue = updatedSelectedValue.filter(
              (data: any) => data.label !== field_value.field.value
            );
          }
        }
      }
    }

    const updatedDropdownOptionData = dropdownOptionData.filter(
      (option) =>
        !updatedSelectedValue.some(
          (selectedValue: any) => selectedValue.id === option.id
        )
    );
    // update the value with particular index
    let updatedformDataField = [...formDataField];
    updatedformDataField[mainSectionIndex] = {
      ...updatedformDataField[mainSectionIndex],
      subSections: [...updatedformDataField[mainSectionIndex].subSections],
    };
    updatedformDataField[mainSectionIndex].subSections[subSectionIndex] = {
      ...updatedformDataField[mainSectionIndex].subSections[subSectionIndex],
      fieldData: [
        ...updatedformDataField[mainSectionIndex].subSections[subSectionIndex]
          .fieldData,
      ],
    };
    updatedformDataField[mainSectionIndex].subSections[
      subSectionIndex
    ].fieldData[fieldDataIndex] = {
      ...updatedformDataField[mainSectionIndex].subSections[subSectionIndex]
        .fieldData[fieldDataIndex],
      field: {
        ...updatedformDataField[mainSectionIndex].subSections[subSectionIndex]
          .fieldData[fieldDataIndex].field,
        value: value.label,
        warning: false,
      },
    };

    // filter the data of dropdown
    updatedformDataField = updatedformDataField.map(
      (mainSection: any, i: any) => {
        return {
          ...mainSection,

          subSections: mainSection.subSections.map(
            (subSection: any, j: any) => {
              return {
                ...subSection,

                fieldData: subSection.fieldData.map(
                  (fieldData: any, k: any) => {
                    return {
                      ...fieldData,

                      dropdown_data: [
                        ...updatedDropdownOptionData,

                        {
                          id: fieldData.field.value,
                          label: fieldData.field.value,
                        },
                      ],
                    };
                  }
                ),
              };
            }
          ),
        };
      }
    );

    // set key value pair for selected value
    let obj: any = { ...objectKey };
    obj[
      updatedformDataField[mainSectionIndex].subSections[
        subSectionIndex
      ].fieldData[fieldDataIndex].title
    ] = value;
    setObjectKey(obj);
    setformDataField(updatedformDataField);
    setSelectedValues(updatedSelectedValue);
  };

  // updatemasterstate
  const updateMasterState = (attrName: any, value: any) => {
    if (attrName === setClientId) {
      setClientId({
        id: value.id,
        label: value.label,
        warn: false,
      });
      dispatch(
        bulkUploadSlice.actions.updateBulkUploadData([
          {
            key: "clientId_detail",
            value: { id: value.id, label: value.label },
          },
        ])
      );
    } else {
      attrName(value);
    }
  };

  const onSubmit = () => {
    let check_status: boolean = false;
    const data: TBulkUploadField = formDataField?.map(
      (dataSubsection: any) => ({
        ...dataSubsection,
        subSections: dataSubsection?.subSections?.map((dataField: any) => ({
          ...dataField,
          fieldData: dataField?.fieldData?.map((inputData: any) => {
            // if (inputData.title === "Client" && userType === "EMPLOYER"
            //   && inputData.required === true &&
            //   inputData.field.value === ""
            // ) {
            //   inputData = {
            //     ...inputData,
            //     field: {
            //       value:
            //         inputData.title = "Client Column 2",
            //       warning: false,
            //     },
            //   };
            // }
            if (
              (inputData.title === "Relationship With Employee" ||
                inputData.title === "Member Type") &&
              subpage?.toLowerCase() === "employee" &&
              inputData.required === true &&
              inputData.field.value === ""
            ) {
              inputData = {
                ...inputData,
                field: {
                  value:
                    inputData.title === "Member Type" ? "Employee" : "Self",
                  warning: false,
                },
              };
            }

            if (inputData.title === "Location Name" || inputData.title === "Location Type") {
              inputData = {
                ...inputData,
                field: {
                  value: "Registered Address",
                  warning: false,
                },
              };
            }
            if (
              inputData.title === "Member Type" &&
              subpage?.toLowerCase() === "dependent" &&
              inputData.required === true &&
              inputData.field.value === ""
            ) {
              inputData = {
                ...inputData,
                field: {
                  value: "Dependent",
                  warning: false,
                },
              };
            }
            if (inputData.required === true && inputData.field.value === "") {
              check_status = true;
              return {
                ...inputData,
                field: {
                  value: "",
                  warning: true,
                },
              };
            } else {
              return inputData;
            }
          }),
        })),
      })
    );
    setformDataField(data);
    dispatch(bulkUploadSlice.actions.setBulkUploadFieldData(data));
    // let dataClientId = clientId;
    // dataClientId.warn = clientId.label === "" ? true : false;
    // setClientId(dataClientId);

    if (check_status === false) {
      if (calledFrom !== ALL_ROUTES.PRODUCT_CONFIG_INSURERS) {
        dispatch(
          bulkUploadSlice.actions.setBulkUploadPageStatus(
            "MAP_FIELD_CONFIRMATION"
          )
        );
      } else {
        dispatch(
          bulkUploadSlice.actions.setBulkUploadPageStatus(
            "MAP_FIELD_CONFIRMATION"
          )
        );
      }
    } else {
      toast.error("Fill Mandatory Fields");
    }
  };

  return (
    <>
      {loader && <NewLoader />}
      <Box className="BulklayoutWrapper">
        {/* Page header */}
        <Grid container spacing={3} className="pageHeader">
          <Grid xs={6} className="pageTitle">
            <Link
              className="backStep"
              onClick={() => {
                dispatch(
                  bulkUploadSlice.actions.setBulkUploadPageStatus(
                    "BULK_UPLOAD_PAGE"
                  )
                );
              }}
            >
              <ChevronLeftIcon />
            </Link>

            <div>
              <h3> Import from Spreadsheet</h3>
            </div>
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid xs={12} className="innerSection pl-10 pr-10 pb-17">
            <>
              <Grid container spacing={3}>
                <Grid xs={12} className="mapfields_instructions mt-3 mb-4">
                  <p>Map Fields</p>
                  <ul>
                    <li>
                      Map {`${calledFrom?.split("/")[1]}`} Form field names to
                      the columns of your imported source file.
                    </li>
                  </ul>
                  <p> Note :</p>
                  <ul>
                    <li>
                      {" "}
                      Each record will only be imported if all mandatory fields
                      have values. If not, that record will be skipped.
                    </li>
                  </ul>
                </Grid>
                {formDataField.map((mainSection, mainSectionIndex) => (
                  <Grid xs={12} padding="0" className="mb-4">
                    <Box className="detailSection">
                      <div>
                        <div className="sectionTitle">
                          <h4>{mainSection.title}</h4>
                        </div>
                        {mainSection.subSections.map(
                          (subSection, subSectionIndex) => (
                            <Grid container spacing={3}>
                              <Grid xs={12}>
                                <>
                                  <Grid container spacing={3}>
                                    <Grid xs={12}>
                                      <h5 className="subHeading">
                                        {subSection.title}
                                      </h5>
                                    </Grid>
                                    <Grid xs={2}>
                                      <p className="form_p text-right">
                                        EB Field :
                                      </p>
                                    </Grid>
                                    <Grid xs={4}>
                                      <p className="form_p ">Import Field</p>
                                    </Grid>
                                    <Grid xs={2}>
                                      <p className="form_p text-right">
                                        EB Field :
                                      </p>
                                    </Grid>
                                    <Grid xs={4}>
                                      <p className="form_p ">Import Field</p>
                                    </Grid>
                                  </Grid>
                                </>

                                <Grid xs={12} padding="0" paddingTop="12px">
                                  <hr />
                                </Grid>
                              </Grid>

                              <Grid xs={12}>
                                <>
                                  <Grid container>
                                    {subSection.fieldData?.map(
                                      (fieldData, fieldDataIndex) => {
                                        if (
                                          fieldData.title === "Id" ||
                                          (subpage?.toLowerCase() === "employee" && fieldData.title === "Primary Employee")
                                        ) {
                                          return null;
                                        }

                                        return (
                                          <Grid xs={6}>
                                            <>
                                              <Grid
                                                container
                                                spacing={3}
                                                sx={{ alignItems: "center" }}
                                              >
                                                <Grid
                                                  xs={4}
                                                  className="text-right"
                                                >
                                                  <h5 className="fieldTitle">
                                                    {fieldData.title === "policyId" ? "Policy Number" : fieldData.title === "street" ? "Building/street Name" : fieldData.title}{" "}
                                                    {fieldData.required ===
                                                      true ? (
                                                      <span
                                                        style={{ color: "red" }}
                                                      >
                                                        *
                                                      </span>
                                                    ) : null}
                                                  </h5>
                                                </Grid>
                                                <Grid xs={8} className="mb-2">
                                                  <SearchDropdown
                                                    class_name="inputField"
                                                    title={""}
                                                    value={
                                                      fieldData.title === "Client" && userType === "EMPLOYER" ? cid :
                                                        fieldData.title === "Member Type" && subpage?.toLowerCase() !== "dependent"
                                                          ? subpage : fieldData.title === "Relationship With Employee" &&
                                                            subpage?.toLowerCase() !== "dependent"
                                                            ? "Self" : fieldData.title === "Member Type" &&
                                                              subpage?.toLowerCase() === "dependent"
                                                              ? "Dependent" : (fieldData.title === "Location Name" || fieldData.title === "Location Type") ? "Registered Address" : fieldData.field.value
                                                    }
                                                    attrName={[
                                                      mainSectionIndex,
                                                      subSectionIndex,
                                                      fieldDataIndex,
                                                    ]}
                                                    value_update={handleFieldMappingChange}
                                                    data={fieldData.dropdown_data}
                                                    warn_status={fieldData.field.warning}
                                                    disabled={
                                                      (fieldData.title === "Client" && userType === "EMPLOYER") ||
                                                      fieldData.title === "Member Type" ||
                                                      (fieldData.title ===
                                                        "Relationship With Employee" &&
                                                        subpage?.toLowerCase() !==
                                                        "dependent") || (fieldData.title === "Location Name" || fieldData.title === "Location Type")
                                                    }
                                                    error_message={`Select ${fieldData.title}`}
                                                  />
                                                </Grid>
                                              </Grid>
                                            </>
                                          </Grid>
                                        )
                                      })}
                                  </Grid>
                                </>
                              </Grid>
                            </Grid>
                          )
                        )}
                      </div>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </>
          </Grid>
        </Grid>
        <Grid container spacing={3} className="footerSection">
          <Grid xs={12} className="ctaBtn">
            <Button
              variant="outlined"
              className="borderBtn"
              onClick={() =>
                dispatch(
                  bulkUploadSlice.actions.setBulkUploadPageStatus(
                    "BULK_UPLOAD_PAGE"
                  )
                )
              }
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              className="submitBtn"
              onClick={onSubmit}
            >
              Next
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default MapFields;
