import React, { useState, useRef } from "react";
import { Alert, Box, Link, Tooltip } from "@mui/material";
import MultipleSearchDropdown from "../MultipleSearchDropdown/MultipleSearchDropdown";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import { URL_CONSTANTS } from "../../APIVar/URLConstants";
import { Patch } from "../CallAPI";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
interface Props {
  attrName: any;
  title: string;
  value: any;
  value_update: Function;
  dropdown_data: Array<any>;
  info_status?: boolean;
  info_message?: string;
  warn_status?: boolean;
  class_name?: string;
  error_messg?: string;
  required?: boolean;
  params?: any;
  id?: string;
  disabled?: boolean;
}

const MultipleSearchDropdownDisable: React.FC<Props> = ({
  attrName,
  title,
  value,
  dropdown_data,
  class_name,
  value_update,
  info_status,
  info_message,
  error_messg,
  warn_status,
  required,
  params,
  id,
  disabled,
}) => {
  const [disbaleToggle, setDisableToggle] = useState<boolean>(false);

  const updateMasterState = (attrName: any, value: any) => {
    value_update(attrName, value);
  };
  const [focus, setFocus] = useState<boolean>(false);
  const refinput = useRef(null);
  // individual edit
  const edit = () => {
    setDisableToggle(!disbaleToggle);
    setFocus(!focus);
    if (disbaleToggle === true) {
      params();
    }
  };

  return (
    <Box className="individualEdit">
      {disbaleToggle === false ? (
        <div
          className={
            value.length === 0 ? "staticField nullValue" : "staticField"
          }
        >
          <label>{title}</label>
          {Array.isArray(value) ? (
            value?.map((item: any) => <p>{item.value}</p>)
          ) : (
            <p>{value}</p>
          )}
        </div>
      ) : (
        <MultipleSearchDropdown
          class_name={class_name}
          title={title}
          value_update={updateMasterState}
          value={value}
          attrName={attrName}
          multiple_data={dropdown_data}
          warn_status={warn_status}
          required={required}
          error_messg={error_messg}
          info_status={info_status}
          info_message={info_message}
          disabled={disabled}
        />
      )}
      {info_status === true ? (
        <Tooltip title={info_message === undefined ? "" : info_message}>
          <span className="infoMessage">
            <InfoOutlinedIcon />
          </span>
        </Tooltip>
      ) : null}

      {disabled === false && (
        <Link
          className={disbaleToggle === true ? "editUpdate visible" : "editUpdate"}
          onClick={edit}
        >
          {disbaleToggle === false ? <EditIcon /> : <CheckIcon />}
        </Link>
      )}

    </Box>
  );
};

export default MultipleSearchDropdownDisable;
