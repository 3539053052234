
const finalUrl: string = `${process.env.REACT_APP_BACKEND_URL}`;
//const finalUrl: string = `${"http://172.16.16.117:3082"}`;

const BASE_URL: any = `${finalUrl}/api/`;

export const EMPLOYER_URL_CONSTANTS = {
  GET_POLICIES_LIST: `${BASE_URL}employer/policy-list-view?`,
  get_policy_details: `${BASE_URL}employer/policy-detail-view?id=`,
  get_policy_detail_view: `${BASE_URL}employer/policy-tab-view?id=`,
  get_employee_list: `${BASE_URL}employer/member-list-view?`,
  get_employee_header: `${BASE_URL}employer/member-meta-data`,
  get_employer_location_list: `${BASE_URL}employer/member-location-list-view`,
  get_employer_department_list: `${BASE_URL}employer/member-departments-list-view?code=0`,
  get_employer_designation_list: `${BASE_URL}employer/member-departments-list-view?code=1`,
  get_employer_salary_bracket_list: `${BASE_URL}employer/member-departments-list-view?code=2`,
  get_employer_organisation_list: `${BASE_URL}employer/member-personal-view`,
  get_employee_detail_layout: `${BASE_URL}employer/member-detail-view?id=`,
  get_assigned_policies: `${BASE_URL}employer/member-policy-view?employeeId=`,
  get_dependent_assigned_policies: `${BASE_URL}employer/member-dependent-policy-view?`,
  get_employee_assignable_policies: `${BASE_URL}employer/member-policy-list-view?id=`,
  get_client_list: `${BASE_URL}employer/client-dropdown-list`,
  get_nominee: `${BASE_URL}employer/member-nominee?id=`,
  get_employee_layout: `${BASE_URL}employer/member-module`,
  get_employer_designation_department_salary_list: `${BASE_URL}employer/member-departments-list-view`,
  get_employees_by_clientId: `${BASE_URL}employer/employee-dropdown-list?`,
  get_client_location_list: `${BASE_URL}employer/client-location-dropdown-list?id=`,
  get_employee_assigned_policies: `${BASE_URL}employer/dependant-policy-list-view?id=`,
  create_employee: `${BASE_URL}employer/member`,
  update_password: `${BASE_URL}employer/member-change-password`,
  update_emp_password: `${BASE_URL}employer/change-password-personal-settings`,
  update_status: `${BASE_URL}employer/member-status`,
  get_endorsement_list: `${BASE_URL}employer/employee/endorsment?id=`,
  get_claims_list: `${BASE_URL}employer/employee/claim?id=`,

  GET_ENDORSEMENT_LIST: `${BASE_URL}employer/endorsment`,

  get_endorsements: `${BASE_URL}employer/endorsment`,

  employer_bulk_upload_member: `${BASE_URL}employer/member-bulk-upload-data?code=`,
  employer_member_field_data_for_bulk_upload: `${BASE_URL}employer/member-upload-field-view`,
  member_unique_field_data:(user:string)=> `${BASE_URL}${user}/member-upload-unique-field-view`,
  get_personal_settings: `${BASE_URL}employer/personal-settings`,
  get_emp_personal_settings: `${BASE_URL}employer/member-personal-view`,
  update_personal_settings: `${BASE_URL}employer/personal-settings`,
  get_user: `${BASE_URL}auth/employer/check`,
  assign_employee_policies: (empId: string) =>
    `${BASE_URL}employer/employee-assign-policy?employeeId=${empId}`,
  add_member_to_policy: (empId: string, code: number, isDep: string) =>
    `${BASE_URL}employer/assign-member-to-policy?employeeId=${empId}&code=${code}&is_dep=${isDep}`,
  // claim url
  create_claim: `${BASE_URL}employer/client/claim`,
  get_claim_list: `${BASE_URL}employer/client/claim`,
  get_claim_count: `${BASE_URL}employer/client/claim-dashboard`,
  claim_status_change: `${BASE_URL}employer/client/claim/status`,
  get_claim_document: `${BASE_URL}employer/client/claim/doc/`,
  get_employee_by_client: `${BASE_URL}employer/employee-dropdown-list?clientId=`,
  get_policy_by_employe: `${BASE_URL}employer/employee-policy-dropdown-view/`,
  get_claim_detail_data: `${BASE_URL}employer/client/claim-detail-view?id=`,
  get_physical_form_data: `${BASE_URL}employer/insurer-physical-form?id=`,
  claim_individual_edit: `${BASE_URL}employer/client/claim-indvidual-edit?id=`,
  claim_add_notes: `${BASE_URL}employer/client/claim-notes?id=`,

  // endorsement
  get_endorsement_list_view: `${BASE_URL}employer/endorsment`,
  get_member_detail: `${BASE_URL}employer/member-details?id=`,
  add_endorsement: `${BASE_URL}employer/endorsment`,

  endorsement_status_change: `${BASE_URL}employer/endorsment-status`,

  //Appearance

  save_appearance_data: `${BASE_URL}employer/appearence`,

  //Support
  create_support_ticket: `${BASE_URL}employer/support-data`,
  get_support_ticket: `${BASE_URL}employer/support-data-list`,

  //policy
  get_cd_ledger: `${BASE_URL}employer/policy-ledger?id=`,
};
