import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ALL_ROUTES } from "../../../../../Routes/all_routes";
import Modules from "../CustomisationSettings/Modules/Modules";
import Templates from "../CustomisationSettings/Templates/Templates";
import Appearance from "../GeneralSettings/Appearance/Appearance";
import EmailSettings from "../GeneralSettings/EmailSettings/EmailSettings";
import PersonalSettings from "../GeneralSettings/PersonalSettings/PersonalSettings";
import MainSettingMenu from "../MainSettingMenu/MainSettingMenu";
import Branches from "../OrganisationSettings/Branches/Branches";
import Department from "../OrganisationSettings/Department/Department";
import OrganisationDetails from "../OrganisationSettings/OrganisationDetails/OrganisationDetails";
import Designation from "../OrganisationSettings/Designation/Designation";
import Salary from "../OrganisationSettings/SalaryBracket/Salary";
import Users from "../UserAndControl/Users/Users";
import { useAppSelector } from "../../../../../Store/hooks";
import AccessControl from "../UserAndControl/AccessControl/AccessControl";
import { RootState } from "../../../../../Store/Store";

function SettingModulesForm() {
  const navigate = useNavigate();
  const location = useLocation();
  const [activePage, setActivePage] = React.useState("personalSettings");
  const [section, setSection] = React.useState("");
  const permission = useAppSelector((state: RootState) => {
    return state.permissionSlice.EMPLOYER;
  });

  useEffect(() => {
    const windowURL = window.location.search;
    const params = new URLSearchParams(windowURL);

    let section = params.get("section");
    let page = params.get("page");

    setSection(section ?? "");
    setActivePage(page ?? "");
  }, [window.location.search]);

  return (
    <Box className="newSettingWrapper h-100">
      <Grid xs={12}>
        <h3 className="pageTitle mb-7 ">
          <Link
            onClick={() => {
              navigate(ALL_ROUTES.SETTING);
            }}>
            <ChevronLeftIcon />
          </Link>
          Settings
        </h3>
      </Grid>
      <Grid container>
        <Grid xs sx={{ maxWidth: "250px" }}>
          {section.length > 0 ? <MainSettingMenu /> : null}
        </Grid>
        <Grid xs>
          {activePage === "modules" ? <Modules /> : null}
          {activePage === "templates" ? <Templates /> : null}
          {activePage === "personalSettings" ? <PersonalSettings /> : null}
          {activePage === "emailSettings" ? <EmailSettings /> : null}
          {activePage === "storageSettings" ? <EmailSettings /> : null}
          {activePage === "appearance" ? <Appearance /> : null}
          {activePage === "users" ? <Users /> : null}
          {activePage === "accessControl" ? <AccessControl /> : null}
          {activePage === "salary"
            ? (permission === undefined ||
              permission["Salary Brackets"]?.view === true) && <Salary />
            : null}
          {activePage === "designations"
            ? (permission === undefined ||
              permission?.Designation?.view === true) && <Designation />
            : null}
          {activePage === "branches"
            ? (permission === undefined ||
              permission?.Branches?.view === true) && <Branches />
            : null}
          {activePage === "departments"
            ? (permission === undefined ||
              permission?.Department?.view === true) && <Department />
            : null}
          {activePage === "companyDetails"
            ? (permission === undefined ||
              permission?.CompanyDetails?.view === true) && (
              <OrganisationDetails />
            )
            : null}
        </Grid>
      </Grid>
    </Box>
  );
}

export default SettingModulesForm;
