import React from "react";
import { Modal, TextField, Button, Box, Link, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import SlidingPanel from "react-sliding-side-panel";
import RKTextField from "../../../../Supporting files/RKTextField/RKTextField";
interface Props {
  attrName?: any;
  open_status?: boolean;
  value_update?: Function;
}

const Report: React.FC<Props> = ({ open_status, attrName, value_update }) => {
  const [downloadReportStatus, setDownloadReportStatus] = React.useState(false);
  const [emailReportStatus, setEmailReportStatus] = React.useState(false);
  const [emailReportPage, setEmailReportPage] = React.useState(1);
  const [addPolicyStatus, setAddPolicyStatus] = React.useState(false);
  const [endorsementStatus, setEndorsementStatus] = React.useState(false);
  const [pageStatus, setPageStatus] = React.useState<number>(1);
  const [oldValue, setOldValue] = React.useState<Date | null>(new Date());
  const [newValue, setNewValue] = React.useState<Date | null>(new Date());
  const [policyType, setPolicyType] = React.useState("");
  const [policyname, setPolicyName] = React.useState("");
  const [addemail, setAddEmail] = React.useState("");
  const [selectValue, setSelectValue] = React.useState("");
  const [oldsValue, setOldsValue] = React.useState("");
  const [newsValue, setNewsValue] = React.useState("");

  // const handleChange = (event: SelectChangeEvent, attrName: string) => {
  //   if (attrName === "policyType") {
  //     setPolicyType(event.target.value as string);
  //   } else if (attrName === "expiry") {
  //     setExpiryStatus(event.target.value as string);
  //   } else if (attrName === "policyname") {
  //     setPolicyName(event.target.value as string);
  //   }
  // };
  const columns: GridColDef[] = [
    { field: "id", headerName: "Emp. ID", width: 70 },
    { field: "reportName", headerName: "Report name", width: 800 },
    { field: "lastGenerated", headerName: "Last Generated", width: 800 },
  ];

  const rows = [
    {
      id: "100",
      reportName: "Endosment Request",
      lastGenerated: "01/01/2022",
    },
    {
      id: "101",
      reportName: "Endosment Request",
      lastGenerated: "01/01/2022",
    },
    {
      id: "102",
      reportName: "Endosment Request",
      lastGenerated: "01/01/2022",
    },
    {
      id: "103",
      reportName: "Endosment Request",
      lastGenerated: "01/01/2022",
    },
    {
      id: "104",
      reportName: "Endosment Request",
      lastGenerated: "01/01/2022",
    },
    {
      id: "105",
      reportName: "Endosment Request",
      lastGenerated: "01/01/2022",
    },
  ];
  const updateMasterState = (attrName: any, value: string) => {
    attrName(value);
  };
  const changePageStatus = (status: boolean) => {
    if (status === true) {
      if (pageStatus < 2) {
        setPageStatus(pageStatus + 1);
      }
    } else {
      if (pageStatus > 1) {
        setPageStatus(pageStatus - 1);
      }
    }
  };
  return (
    <Grid container spacing={3} className="claimAssistant">
      {/* <Modal open={emailReportStatus} className="modalWrapper">
        <div>
          <Box className="modalInner">
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Link
                  className="close-button"
                  onClick={() => {
                    setEmailReportStatus(false);
                  }}
                />
              </Grid>
            </Grid>
            {emailReportPage === 1 ? (
              <Grid container spacing={0} textAlign="center">
                <Grid item xs={12} className="mb-5">
                  <p className="popup-heading">Enter your email</p>
                </Grid>
                <Grid item xs={12} className="mb-5">
                  <h5>space for date range</h5>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label="Enter Email"
                    variant="outlined"
                    className="textarea"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{ textAlign: "center" }}
                  className="ctaBtn">
                  <Button
                    variant="contained"
                    className="submitBtn"
                    onClick={() => {
                      setEmailReportPage(2);
                    }}>
                    Send Here
                  </Button>
                </Grid>
              </Grid>
            ) : null}
            {emailReportPage === 2 ? (
              <Grid container spacing={0} textAlign="center">
                <Grid item xs={12} className="mb-5">
                  <h1>Img</h1>
                </Grid>

                <Grid item xs={12} className="mb-5">
                  <p className="popup-heading">Email has been sent to</p>
                  <Link  underline="none">
                    testemail@testmail.com
                  </Link>
                </Grid>
              </Grid>
            ) : null}
          </Box>
        </div>
      </Modal> */}

      <SlidingPanel
        type={"right"}
        isOpen={emailReportStatus}
        size={75}
        panelClassName="slidingPanel"
        backdropClicked={() => setEmailReportStatus(false)}>
        <Box className={open_status === true ? "modalInner show" : "modalInner"}>
          {pageStatus === 2 ? null : (
            <Grid container spacing={3} alignItems="center">
              <Grid xs={6}>
                <h4 className="mb-0 text-left">Raise New Request</h4>
              </Grid>
            </Grid>
          )}
          {pageStatus === 2 ? null : (
            <div className="scrollable_area">
              <Grid container spacing={3}>
                <Grid xs={12} sx={{ padding: "0px" }}>
                  <Typography>
                    <Grid
                      container
                      spacing={3}
                      justifyContent="center"
                      alignItems="center"
                      className="mb-4">
                      <Grid xs={12}>
                        <RKTextField
                          class_name="inputField"
                          title="Enter Email"
                          value={addemail}
                          value_update={updateMasterState}
                          attrName={setAddEmail}
                          warn_status={false}
                        />
                      </Grid>
                    </Grid>
                  </Typography>
                </Grid>
              </Grid>
            </div>
          )}
          {pageStatus === 2 ? (
            <Grid container spacing={2}>
              <Grid xs={12} className="tick_animation">
                <svg
                  className="checkmark"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 52 52">
                  <circle
                    className="checkmark__circle"
                    cx="26"
                    cy="26"
                    r="25"
                    fill="none"
                  />
                  <path
                    className="checkmark__check"
                    fill="none"
                    d="M14.1 27.2l7.1 7.2 16.7-16.8"
                  />
                </svg>
                <p className="quote_success">
                  Email has been sent successfully!
                </p>
              </Grid>
            </Grid>
          ) : null}
          <Grid container spacing={2}>
            {pageStatus === 2 ? null : (
              <Grid
                xs={12}
                style={{ textAlign: "center", position: "relative" }}
                className="ctaBtn">
                <Button
                  variant="contained"
                  className="submitBtn fixedbtn"
                  onClick={() => {
                    changePageStatus(true);
                  }}>
                  {pageStatus === 1 ? "Submit" : "Continue"}
                </Button>
              </Grid>
            )}
          </Grid>
        </Box>
      </SlidingPanel>

      <Modal open={downloadReportStatus} className="modalWrapper">
        <Box className="modalInner">
          <Grid container spacing={3}>
            <Grid xs={12}>
              <Link
                className="close-button"
                onClick={() => setDownloadReportStatus(false)}
              />
            </Grid>
          </Grid>

          <Grid container spacing={0} textAlign="center">
            <Grid xs={12} className="mb-5">
              <h1>space for date range</h1>
            </Grid>

            <Grid xs={12} style={{ textAlign: "center" }} className="ctaBtn">
              <Button
                variant="contained"
                className="submitBtn"
                onClick={() => setDownloadReportStatus(false)}>
                Download
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <Grid xs={6}>
        <h3 className="mb-0">Report</h3>
      </Grid>
      <Grid xs={6} className="text-right">
        <Link
          underline="none"
          className="blueBtn download"
          onClick={() => setDownloadReportStatus(true)}>
          Download
        </Link>
        <Link
          underline="none"
          className="greenBtn mail"
          onClick={() => {
            setEmailReportStatus(true);
          }}>
          Email
        </Link>
      </Grid>
      <Grid xs={12}>
        <div style={{ height: 500, width: "100%" }}>
          <DataGrid
            className="datagridTable"
            rows={rows}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10]}
            checkboxSelection
          />
        </div>
        <Modal open={addPolicyStatus} className="modalWrapper">
          <Box className="modalInner">
            <Grid container spacing={3}>
              <Grid xs={12}>
                <Link
                  className="close-button"
                  onClick={() => {
                    setAddPolicyStatus(false);
                  }}
                />
                <h5 className="popup-heading">Uploading file</h5>
              </Grid>
            </Grid>
            <Grid container spacing={0}>
              <Grid xs={12} className="mb-5">
                <Link className="md-link" underline="none">
                  67%... donot close or switch tab while it’s uploading
                </Link>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid xs={12} className="ctaBtn" style={{ textAlign: "center" }}>
                <Button variant="contained" className="submitBtn">
                  Upload
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Modal>
      </Grid>
    </Grid>
  );
};

export default Report;
