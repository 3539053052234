import React from "react";
import "../ProductConfig.scss";
import Grid from "@mui/material/Unstable_Grid2";
import { Box, Button, Link, Modal } from "@mui/material";
import UGTable from "../../../../../Supporting files/UGTable/UGTable";
import RKTextField from "../../../../../Supporting files/RKTextField/RKTextField";
import ModifyFeature from "../../../../../Components/Broker/ProductConfigurator/ModifyFeature/ModifyFeature";
// import AddCategory from "../../../../../Components/Broker/ProductConfigurator/Categories/AddCategory";
import RemovePopup from "../../../../../Components/Common/CarForm/RemovePopup/RemovePopup";

function GroupPersonalAccident() {
  const [productTabsStatus, setProductTabsStatus] =
    React.useState("Categories");

  const [hideDataStatus, setHideDataStatus] = React.useState(false);
  const [removeCategory, setRemoveCategory] = React.useState(false);
  //Features Variables
  const [addNewFeatures, setAddNewFeatures] = React.useState(false);
  const [modifyFeature, setModifyFeatures] = React.useState(false);
  const [addNewCategory, setAddNewCategory] = React.useState(false);

  //Category Data
  const [columns, setColumns] = React.useState([
    {
      field: "sr_no",
      headerName: "Sr. No.",
      width: 80,
      check_status: true,
    },
    {
      field: "category_name",
      headerName: "Category Name",
      minWidth: 260,
      check_status: true,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 3,
      minWidth: 150,
      check_status: true,
    },
    {
      field: "action_btns",
      headerName: "Action",
      width: 150,
      check_status: true,
      renderCell: (ValueFormatterParams: any) => {
        const { row } = ValueFormatterParams;
        return (
          <Link
            className="blueBtn edit ml-0"
            underline="none"
            onClick={() => {
              setAddNewCategory(true);
            }}>
            Edit
          </Link>
        );
      },
    },
  ]);
  const rows = [
    {
      id: 0,
      sr_no: "1",
      category_name: "Medical Cover",
      description: "Lorem ipsum dolor sit amet",
      action_btns: "Edit",
    },
    {
      id: 1,
      sr_no: "2",
      category_name: "Ayus Treatment",
      description: "Lorem ipsum dolor sit amet",
      action_btns: "Edit",
    },
  ];
  //Features Data
  const [featureColumns, setFeatureColumns] = React.useState([
    {
      field: "sr_no",
      headerName: "Sr. No.",
      width: 80,
      check_status: true,
    },
    {
      field: "category_name",
      headerName: "Category Name",
      minWidth: 200,
      check_status: true,
    },
    {
      field: "feature_name",
      headerName: "Feature Name",
      minWidth: 260,
      check_status: true,
    },
    {
      field: "field_type",
      headerName: "Field Type",
      width: 150,
      check_status: true,
    },
    {
      field: "value_type",
      headerName: "Value Type",
      width: 150,
      check_status: true,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 3,
      minWidth: 150,
      check_status: true,
    },
    {
      field: "action_btns",
      headerName: "Action",
      width: 150,
      check_status: true,
      renderCell: (ValueFormatterParams: any) => {
        const { row } = ValueFormatterParams;
        return (
          <Link
            className="blueBtn edit ml-0"
            underline="none"
            onClick={() => {
              setModifyFeatures(true);
            }}>
            Edit
          </Link>
        );
      },
    },
  ]);
  const featurerows = [
    {
      id: 0,
      sr_no: "1",
      category_name: "Medical Cover",
      feature_name: "Pre-Existing Disease",
      field_type: "Dropdown",
      value_type: "Numeric",
      description: "Pre-existing illnesses after the waiting period is over.",
      action_btns: "Edit",
    },
  ];
  //Insurer Data
  const [insurerColumns, setInsurerColumns] = React.useState([
    {
      field: "sr_no",
      headerName: "Sr. No.",
      width: 80,
      check_status: true,
    },
    {
      field: "insurer_name",
      headerName: "Insurer Name",
      flex: 3,
      minWidth: 340,
      check_status: true,
    },
    {
      field: "website",
      headerName: "Website",
      minWidth: 320,
      check_status: true,
      renderCell: (ValueFormatterParams: any) => {
        const { row } = ValueFormatterParams;
        return (
          <Link href={row.website} target="_blank" underline="none">
            {row.website}
          </Link>
        );
      },
    },
    {
      field: "network_hospitals",
      headerName: "Network Hospitals",
      minWidth: 160,
      check_status: true,
    },
    {
      field: "action_btns",
      headerName: "Action",
      width: 120,
      check_status: true,
      renderCell: (ValueFormatterParams: any) => {
        const { row } = ValueFormatterParams;
        return (
          <Link
            className="blueBtn edit ml-0"
            underline="none"
            onClick={() => {}}>
            Edit
          </Link>
        );
      },
    },
  ]);
  const insurerrows = [
    {
      id: 0,
      sr_no: "1",
      insurer_name: "Aviva Life Insurance Co. India Ltd.",
      website: "https://www.avivaindia.com/",
      network_hospitals: "5,000",
      action_btns: "Edit",
    },
    {
      id: 1,
      sr_no: "2",
      insurer_name:
        "Canara HSBC Oriental Bank of Commerce Life Insurance Company Limited",
      website: "https://www.canarahsbclife.com/index.html",
      network_hospitals: "10,000",
      action_btns: "Edit",
    },
    {
      id: 2,
      sr_no: "3",
      insurer_name: "Kotak Mahindra General Insurance Co Ltd",
      website: "https://www.kotakgeneralinsurance.com/",
      network_hospitals: "15,000",
      action_btns: "Edit",
    },
    {
      id: 3,
      sr_no: "4",
      insurer_name: "Royal Sundaram General Insurance Co Ltd",
      website: "https://www.royalsundaram.in/",
      network_hospitals: "8,000",
      action_btns: "Edit",
    },
  ];

  const updateMasterState = (attrName: any, value: any) => {
    attrName(value);

    if (attrName === setAddNewFeatures) {
      setHideDataStatus(value);
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid xs={12}>
        <h3 className="mb-0">Settings</h3>
      </Grid>

      {hideDataStatus === false ? (
        <>
          <Grid xs={8}>
            <div className="productTabs">
              {/* <Button
                className={
                  productTabsStatus === "Categories"
                    ? "defaultTab active"
                    : "defaultTab"
                }
                onClick={() => {
                  updateMasterState(setProductTabsStatus, "Categories");
                }}>
                Categories
              </Button>
              <Button
                className={
                  productTabsStatus === "Features"
                    ? "defaultTab active"
                    : "defaultTab"
                }
                onClick={() => {
                  updateMasterState(setProductTabsStatus, "Features");
                }}>
                Features
              </Button> */}
              {/* <Button
                className={
                  productTabsStatus === "Insurers"
                    ? "defaultTab active"
                    : "defaultTab"
                }
                onClick={() => {
                  updateMasterState(setProductTabsStatus, "Insurers");
                  setProductTabsStatus("Insurers");
                }}>
                Insurers
              </Button> */}
            </div>
          </Grid>
          <Grid xs={4} className="text-right">
            {productTabsStatus === "Categories" ? (
              <>
                <Link
                  className="redBtn delete"
                  underline="none"
                  onClick={() => {
                    setRemoveCategory(true);
                  }}>
                  Remove
                </Link>
                <Link
                  className="greenBtn addfile"
                  underline="none"
                  onClick={() => {
                    setAddNewCategory(true);
                  }}>
                  Add New
                </Link>
              </>
            ) : null}
            {productTabsStatus === "Features" ? (
              <>
                <Link
                  className="redBtn delete"
                  underline="none"
                  onClick={() => {}}>
                  Remove
                </Link>
                <Link
                  className="greenBtn addfile"
                  underline="none"
                  onClick={() => {
                    updateMasterState(setAddNewFeatures, true);
                  }}>
                  Add New
                </Link>
              </>
            ) : null}
            {productTabsStatus === "Insurers" ? <></> : null}
          </Grid>
          <Grid xs={12}>
            {productTabsStatus === "Categories" ? (
              <div style={{ height: 400, width: "100%" }}>
                <UGTable
                  header_data={columns}
                  data={rows}
                  value_update={updateMasterState}
                  attrName={"UGTable"}
                />
              </div>
            ) : null}
            {productTabsStatus === "Features" ? (
              <div style={{ height: 400, width: "100%" }}>
                <UGTable
                  header_data={featureColumns}
                  data={featurerows}
                  value_update={updateMasterState}
                  attrName={"UGTable"}
                />
              </div>
            ) : null}
            {productTabsStatus === "Insurers" ? (
              <div style={{ height: 400, width: "100%" }}>
                <UGTable
                  header_data={insurerColumns}
                  data={insurerrows}
                  value_update={updateMasterState}
                  attrName={"UGTable"}
                />
              </div>
            ) : null}
          </Grid>
        </>
      ) : null}
      <RemovePopup
        open_status={removeCategory}
        attrName={setRemoveCategory}
        value_update={updateMasterState}
      />

      {/* <AddFeatures
        open_status={addNewFeatures}
        attrName={setAddNewFeatures}
        value_update={updateMasterState}
      /> */}
      <ModifyFeature
        attrName={setModifyFeatures}
        value_update={updateMasterState}
        open_status={modifyFeature}
      />
      {/* <AddCategory
        attrName={setAddNewCategory}
        value_update={updateMasterState}
        open_status={addNewCategory}
      /> */}
    </Grid>
  );
}

export default GroupPersonalAccident;
