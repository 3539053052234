export enum EMPLOYER_ALL_ROUTES {
  STAR = "*",
  LOGIN = "/",
  SETTING = "/settings",
  DASHBOARD = "/",
  QUOTES = "/quotes",
  POLICIES = "/policies",
  POLICY_DETAILS = "/policy-details",
  BULK_UPLOAD_PAGE = "/bulk-upload-page",
  BULK_UPLOAD_LIST = "/bulk-upload-list",
  EMPLOYEE = "/member",
  ENDORSEMENT = "/endorsement",
  CLIAM_ASSISTANT = "/claim-assistant",
  CLAIM_DETAIL = "/claim-assitant-detail",
  TRENDING_QUESTION = "/trending-question",
  COMMUNITY = "/community",
  REPORT = "/report",
  SUPPORT = "/support",
  ADD_EMPLOYEE = "/add-new-member",
  EMPLOYEE_DETAILS_VIEW = "/member-detail-view",
  HOME_EMPLOYER = "/home-employer",
  SETTING_MODULE_FORM = "/setting-module-form",
  EDIT_USER = "/edit-user",
  CREATE_USER = "/create-user",
  CREATE_ACCESS_CONTROL = "/create-access-control",
  EDIT_ACCESS_CONTROL = "/edit-access-control",
  ADD_BRANCH = "/create-branch",
  EDIT_PERSONAL_DETAILS = "/edit-personal-details",
  ENDORSEMENT_DETAILS = "/endorsement_details",
  ENDORSEMENT_DETAILS_MEMBER_ADDITION = "/endorsement-details-addition",
  NETWORK_HOSPITAL = "/network-hospital",

}
