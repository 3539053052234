import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {
  Box,
  Button,
  FormControlLabel,
  Link,
  Radio,
  RadioGroup,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as XLSX from "xlsx";
import { ALL_ROUTES } from "../../Routes/all_routes";
import { EMPLOYER_ALL_ROUTES } from "../../Routes/employer_all_routes";
import { BULK_UPLOAD_SERVICES } from "../../Services/BulkUpload/BulkUploadServices";
import { EMPLOYER_BULK_UPLOAD_SERVICES } from "../../Services/EmployerServices/BulkUpload/Employer_Bulk_Upload";
import { bulkUploadSlice } from "../../Store/Slice_Reducer/BulkUpload/BulkUploadSlice";
import { useAppDispatch, useAppSelector } from "../../Store/hooks";
import RKTextField from "../../Supporting files/RKTextField/RKTextField";
import "./BulkUpload.scss";
import MapFields from "./MapFields/MapFields";
import MapFieldsConfirmation from "./MapFieldsConfirmation/MapFieldsConfirmation";

function BulkUploadPage() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { userType } = useAppSelector(state => state.userDetailsSlice)
  const { bulk_upload_page_status } = useAppSelector(
    (state) => state.bulkUpload
  );

  const [searchParams, setSearchParams] = useSearchParams();
  const [show, setShow] = useState<boolean>(false);
  const [fileWarn, setFileWarn] = useState<boolean>(false);
  const [duplicateValue, setDuplicateValue] = useState<string>("0");
  const [dropdown_list_data, setDropdown_list_data] = useState<
    Array<
      [
        {
          label: string;
          value: string;
          unique: boolean;
        }
      ]
    >
  >([]);

  const [dropdownValue, setDropDownValue] = useState({
    duplicateValue: "",
    dateFormat: "",
  });
  const [warnStatus, setWarnStatus] = useState<{
    duplicateValue: boolean;
    dateFormat: boolean;
  }>({
    duplicateValue: false,
    dateFormat: false,
  });

  const [selectedFile, setSelectedFile] = useState<any>(null);

  const dateTimeFormat_data = [
    { key: "MM-DD-YYYY", value: "MM-DD-YYYY" },
    { key: "YYYY-MM-DD", value: "YYYY-MM-DD" },
    { key: "DD/MM/YYYY", value: "DD/MM/YYYY" },
    { key: "YYYY/MM/DD", value: "YYYY/MM/DD" },
    { key: "DD-MM-YYYY", value: "DD-MM-YYYY" },
    { key: "MM-DD-YYYY", value: "MM-DD-YYYY" },
    { key: "dd/mm/yyyy", value: "dd/mm/yyyy" },
    { key: "mm/dd/yyyy", value: "mm/dd/yyyy" },
    { key: "yyyy/mm/dd", value: "yyyy/mm/dd" },
    { key: "dd-mm-yyyy", value: "dd-mm-yyyy" },
    { key: "mm-dd-yyyy", value: "mm-dd-yyyy" },
    { key: "yyyy-mm-dd", value: "yyyy-mm-dd" },
  ];

  useEffect(() => {
    get_unqiue_field_data();
  }, []);

  useEffect(() => {
    if (!selectedFile && bulk_upload_page_status !== "BULK_UPLOAD_PAGE") {
      //it will redirect to first page when we refresh the page
      dispatch(
        bulkUploadSlice.actions.setBulkUploadPageStatus("BULK_UPLOAD_PAGE")
      );
    }
  }, []);

  // function for upload the csv file
  const handleFileChange = (event: any) => {
    setFileWarn(false);
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("image", file);
    if (file) {
      const fileSizeInMB = file.size / (1024 * 1024);
      //check file is not more than 50mb
      if (fileSizeInMB > 50) {
        toast.error("File size exceeds the maximum limit of 50MB.");
        return;
      }
      const fileName = file.name.toLowerCase();
      const fileType = fileName.substring(fileName.lastIndexOf(".") + 1);
      if (
        ["xls", "xlsx", "vcf", "csv"].includes(fileType) &&
        !file.type.startsWith("image/")
      ) {
        setSelectedFile(file);
        let fileReader = new FileReader();
        fileReader.readAsBinaryString(file);
        fileReader.onload = (event) => {
          let data = event.target?.result;

          let workbook = XLSX.read(data, { type: "binary" });

          const sheetName = workbook.SheetNames[0]; // Assuming you only want the first sheet
          const worksheet = workbook.Sheets[sheetName];
          const header: any = XLSX.utils.sheet_to_json(worksheet, {
            header: 1,
            defval: "",
          })[0];

          const dataHeaderConcat = header?.map(
            (item: any, index: number) => `${item} Column ${index + 1}`
          );

          sessionStorage.setItem("csv_file", JSON.stringify(file));

          // Dispatch the action to save header and base64 value in Redux
          // const base64_file_data: string = data;
          dispatch(
            bulkUploadSlice.actions.updateBulkUploadData([
              {
                key: "csv_file",
                value: file,
              },
              {
                key: "csv_file_header",
                value: dataHeaderConcat,
              },
            ])
          );
        };
      } else {
        setSelectedFile(null);
        toast.error(
          "Only .xls, .xlsx, .vcf, or .csv files are allowed. Images are not permitted."
        );
      }
    }
  };

  // update the dropdown value
  const updateMasterState = (attrName: any, value: any) => {
    if (attrName[0] === "dropdownValue") {
      setDropDownValue({ ...dropdownValue, [attrName[1]]: value });

      setWarnStatus({
        ...warnStatus,
        [attrName[1]]: false,
      });
    } else {
      attrName(value);
    }
  };

  // unique field data get from the api response
  const get_unqiue_field_data = () => {
    let subpage = searchParams.get("subpage");
    const onSuccess = (res: any) => {
      let data: any[] = [];
      res.data.data.forEach((dataValue: any) => {
        if (dataValue.unique === true) {
          data.push({
            label: dataValue.title,
            value: dataValue.title,
            unique: dataValue.unique,
          });
        }
      });
      if(subpage === "Dependent"){
        setDropdown_list_data([]);
      }else{
        setDropdown_list_data(data);

      }
    };

    const onError = (err: any) => {
      console.log("err", err);
    };

    const calledFrom = searchParams.get("calledFrom");
    const portal = searchParams.get("portal");

    if (portal === "employer") {
      if (calledFrom === EMPLOYER_ALL_ROUTES.EMPLOYEE) {
        EMPLOYER_BULK_UPLOAD_SERVICES.member_get_unique_field_data(
          userType?.toLowerCase(),
          onSuccess,
          onError
        );
      }
    } else {
      if (calledFrom === ALL_ROUTES.CLIENTS) {
        BULK_UPLOAD_SERVICES.client_get_unique_field_data(onSuccess, onError);
      }

      if (calledFrom === ALL_ROUTES.CONTACTS) {
        BULK_UPLOAD_SERVICES.contact_get_unique_field_data(onSuccess, onError);
      }

      if (calledFrom === ALL_ROUTES.EMPLOYEE) {
        BULK_UPLOAD_SERVICES.member_get_unique_field_data(
          userType?.toLowerCase(),
          onSuccess,
          onError
        );
      }

      if (calledFrom === ALL_ROUTES.PRODUCT_CONFIG_INSURERS) {
        BULK_UPLOAD_SERVICES.prod_config_insurer_unique_field_data(
          onSuccess,
          onError
        );
      }
    }
  };

  // valditate data and checkout to the next step
  const onSubmit = () => {
    let dataWarn: any = fileWarn;
    dataWarn = selectedFile === null ? true : false;
    setFileWarn(dataWarn);
    let data: any = warnStatus;
    if (show === true) {
      data.duplicateValue = dropdownValue.duplicateValue === "" ? true : false;
      data.dateFormat = dropdownValue.dateFormat === "" ? true : false;
      setWarnStatus({ ...data });
    } else {
      setWarnStatus({
        duplicateValue: false,
        dateFormat: false,
      });
    }
    if (dataWarn === false) {
      if (
        show === true &&
        data.duplicateValue === false &&
        data.dateFormat === false
      ) {
        //dispatch the data onsubmit of form of mandotary data and date format in redux store
        dispatch(
          bulkUploadSlice.actions.updateBulkUploadData([
            {
              key: "data_time_format_of_selected_file",
              value: dropdownValue.dateFormat,
            },
            {
              key: "find_duplicate_using",
              value: dropdownValue.duplicateValue,
            },
          ])
        );
        dispatch(bulkUploadSlice.actions.setBulkUploadPageStatus("MAP_FIELD"));
      } else if (show === false) {
        dispatch(bulkUploadSlice.actions.setBulkUploadPageStatus("MAP_FIELD"));
      }
    }
  };

  const downloadSampleCsv = () => {
    let key = searchParams.get("calledFrom");
    let subpage = searchParams.get("subpage");
    let downloadUrl = "";

    if (key && key.includes("contacts")) {
      downloadUrl =
        "https://fspprodstorageev.blob.core.windows.net/tpa/a3544d65886c9223fa26.xlsx?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupyx&se=2026-01-31T15:10:20Z&st=2024-06-03T07:10:20Z&spr=https&sig=eNpVEVzIWDf%2FoeVUTYrZl1bc4Rt4VyNfUugdvRJ5Hkg%3D";
    }
    if (key && key.includes("clients")) {
      downloadUrl =
        "https://fspprodstorageev.blob.core.windows.net/claim/1d32f92787406ef6fef4.xlsx?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupyx&se=2026-01-31T15:10:20Z&st=2024-06-03T07:10:20Z&spr=https&sig=eNpVEVzIWDf%2FoeVUTYrZl1bc4Rt4VyNfUugdvRJ5Hkg%3D";
    }
    if (key && key.includes("employee") && subpage === "Employee") {
      downloadUrl =
        "https://fspprodstorageev.blob.core.windows.net/claim/371fc1e89061b44b64d8.xlsx?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupyx&se=2026-01-31T15:10:20Z&st=2024-06-03T07:10:20Z&spr=https&sig=eNpVEVzIWDf%2FoeVUTYrZl1bc4Rt4VyNfUugdvRJ5Hkg%3D";
    }
    if (key && key.includes("employee") && subpage === "Dependent") {
      downloadUrl =
        "https://fspprodstorageev.blob.core.windows.net/claim/3e82c5040472181dab86.xlsx?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupyx&se=2026-01-31T15:10:20Z&st=2024-06-03T07:10:20Z&spr=https&sig=eNpVEVzIWDf%2FoeVUTYrZl1bc4Rt4VyNfUugdvRJ5Hkg%3D";
    }
    if (downloadUrl) {
      // Create a temporary <a> element to trigger the download
      const link = document.createElement("a");
      let name: string = key?.split("/")[1] || "";
      link.href = downloadUrl;
      link.setAttribute("download", `${subpage ?? name}.sample.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.error("Download URL not found.");
    }
  };
  return (
    <>
      {bulk_upload_page_status === "BULK_UPLOAD_PAGE" ? (
        <Box className="BulklayoutWrapper">
          {/* Page header */}
          <Grid container spacing={3} className="pageHeader">
            <Grid xs={6} className="pageTitle">
              <Link
                className="backStep"
                onClick={() => {
                  navigate(-1);
                }}
              >
                <ChevronLeftIcon />
              </Link>

              <div>
                <h3> Import from Spreadsheet</h3>
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid xs={12} className="innerSection pl-10 pr-10">
              <>
                <Grid container spacing={3}>
                  <Grid xs={7} className="leftPart">
                    <>
                      <Grid container spacing={3}>
                        <Grid xs={12} marginBottom="10px">
                          <>
                            <p>Select a file to upload</p>
                            <Grid container spacing={3} paddingLeft={0}>
                              <Grid
                                xs={5}
                                className="inputField uploadFile"
                                paddingRight={0}
                              >
                                <RKTextField
                                  class_name="inputField"
                                  title="Upload File"
                                  value={
                                    selectedFile?.name ? selectedFile?.name : ""
                                  }
                                  attrName={""}
                                  value_update={updateMasterState}
                                  warn_status={false}
                                  disabled={true}
                                />
                              </Grid>
                              <Grid xs={1} paddingLeft={0}>
                                <Button
                                  variant="contained"
                                  className="browsebtn upload_btn"
                                  component="label"
                                >
                                  Browse
                                  <input
                                    hidden
                                    multiple
                                    type="file"
                                    accept=".xls, .xlsx, .vcf, .csv"
                                    onChange={(event: any) => {
                                      handleFileChange(event);
                                    }}
                                  />
                                </Button>
                                {fileWarn && (
                                  <span className="error_msg">Upload File</span>
                                )}
                              </Grid>
                              <Grid
                                xs={2}
                                className="ctaBtn"
                                display={"flex"}
                                alignItems={"center"}
                              >
                                <Button
                                  aria-controls="simple-menu"
                                  aria-haspopup="true"
                                  onClick={downloadSampleCsv}
                                  className="blueBtn download"
                                  style={{
                                    paddingLeft: 30,
                                    textTransform: "unset",
                                    minWidth: 180,
                                  }}
                                >
                                  Download Sample CSV
                                </Button>
                              </Grid>
                            </Grid>
                            <p className="fileUploadTypeDetails">
                              Files may be upto 50 MB, and .xls, .xlsx, .csv or
                              .vcf formats
                            </p>
                          </>
                        </Grid>
                        <Grid xs={12} marginBottom="10px">
                          <p>In case of duplicate records found</p>
                          <RadioGroup defaultValue="0">
                            <FormControlLabel
                              value="0"
                              control={<Radio />}
                              label="Skip"
                              onChange={(e: any) => {
                                setDuplicateValue(e.target.value);
                                dispatch(
                                  bulkUploadSlice.actions.updateBulkUploadData([
                                    {
                                      key: "action_perform_to_duplicate_record",
                                      value: e.target.value,
                                    },
                                  ])
                                );
                              }}
                            />
                            <FormControlLabel
                              value="1"
                              control={<Radio />}
                              label="Overwrite"
                              onChange={(e: any) => {
                                setDuplicateValue(e.target.value);
                                dispatch(
                                  bulkUploadSlice.actions.updateBulkUploadData([
                                    {
                                      key: "action_perform_to_duplicate_record",
                                      value: e.target.value,
                                    },
                                  ])
                                );
                              }}
                            />
                            {/* <FormControlLabel
                          value="2"
                          control={<Radio />}
                          label="Clone"
                          onChange={(e: any) => {
                            setDuplicateValue(e.target.value);
                          }}
                        /> */}
                          </RadioGroup>
                        </Grid>
                        {/* <Grid xs={12}>
                          <Link
                            className="advanced_option_link"
                            onClick={() => setShow(!show)}
                          >
                            Advanced Options
                          </Link>
                        </Grid>
                        {show && (
                          <Grid xs={12}>
                            <>
                              <Grid container padding={0} marginBottom="10px">
                                <Grid xs={6}>
                                  <SelectDropdown
                                    class_name="inputField"
                                    title="Find Duplicates Using"
                                    value={dropdownValue.duplicateValue}
                                    attrName={[
                                      "dropdownValue",
                                      "duplicateValue",
                                    ]}
                                    value_update={updateMasterState}
                                    dropdown_data={dropdown_list_data}
                                    warn_status={warnStatus.duplicateValue}
                                    error_messg="Select the value"
                                  />
                                </Grid>
                              </Grid>
                              <Grid container padding={0} marginBottom="10px">
                                <Grid xs={6}>
                                  <SelectDropdown
                                    class_name="inputField"
                                    title="Date/Time format of uploaded file"
                                    value={dropdownValue.dateFormat}
                                    attrName={["dropdownValue", "dateFormat"]}
                                    value_update={updateMasterState}
                                    dropdown_data={dateTimeFormat_data}
                                    warn_status={warnStatus.dateFormat}
                                    error_messg="Select the value"
                                  />
                                </Grid>
                              </Grid>
                            </>
                          </Grid>
                        )} */}
                        <Grid xs={12} className="ctaBtn">
                          <Button
                            variant="contained"
                            className="submitBtn"
                            onClick={onSubmit}
                          >
                            Continue
                          </Button>
                        </Grid>
                      </Grid>
                    </>
                  </Grid>
                  <Grid xs={5} className="rightPart">
                    <>
                      <Grid container spacing={3}>
                        <Grid xs={12} className="innerPart">
                          <>
                            <Grid container spacing={3}>
                              <Grid xs={12} padding="0">
                                <p>Supported Formats</p>
                                <ul>
                                  <li>.xls (MS Excel 97 - 2003 format)</li>
                                  <li>.xlsx (Excel format)</li>
                                  <li>.csv (Comma-separated values format)</li>
                                </ul>
                              </Grid>
                              <Grid xs={12} padding="0">
                                <p>Duplicate Records</p>
                                <ul>
                                  <li>Skip - It keeps the duplicate data.</li>
                                  <li>
                                    Overwrite - It replaces old data for new
                                    duplicate data.
                                  </li>
                                  {/* <li>Clone -</li> */}
                                </ul>
                              </Grid>
                              <Grid xs={12} padding="0">
                                <p>Important notes</p>
                                <ul>
                                  <li>
                                    Please ensure that the maximum records in
                                    the file are not more than 100.
                                  </li>
                                </ul>
                              </Grid>
                            </Grid>
                          </>
                        </Grid>
                      </Grid>
                    </>
                  </Grid>
                </Grid>
              </>
            </Grid>
          </Grid>
        </Box>
      ) : bulk_upload_page_status === "MAP_FIELD" ? (
        <MapFields
          file={selectedFile}
          calledFrom={searchParams.get("calledFrom")}
          subpage={searchParams.get("subpage")}
          portal={searchParams.get("portal")}
        />
      ) : (
        <MapFieldsConfirmation
          file={selectedFile}
          calledFrom={searchParams.get("calledFrom")}
          type={searchParams.get("type")}
          portal={searchParams.get("portal")}
        />
      )}
    </>
  );
}

export default BulkUploadPage;
