import { EMPLOYER_URL_CONSTANTS } from "../../../APIVar/EmployerUrlConstant";
import { URL_CONSTANTS } from "../../../APIVar/URLConstants";
import { PatchAPI } from "../../API";


const save_appearance_data = (
    onSuccess: any,
    onError: any,
    dto: {
        appearance: {
            theme: number
        }
    },
    user:string,
) => {
    PatchAPI.call(
        URL_CONSTANTS.save_appearance_data(user),
        dto,
        onSuccess,
        {},
        onError
    );
};

const save_appearance_data_employer = (
    onSuccess: any,
    onError: any,
    dto: {
        appearance: {
            theme: number
        }
    },
) => {
    PatchAPI.call(
        EMPLOYER_URL_CONSTANTS.save_appearance_data,
        dto,
        onSuccess,
        {},
        onError
    );
};

export const APPREANCE_SERVICES = {
    save_appearance_data,
    save_appearance_data_employer
};
