import { URL_CONSTANTS } from "../../APIVar/URLConstants";
import { GetAPI, PostAPI, DeleteAPI, PatchAPI } from "../API";

const get_header_field = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void
) => {
  GetAPI.call(URL_CONSTANTS.getHeaderData, onSuccess, onError);
};

const get_client_row_data = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
  limit: number,
  skip: number,
  starDate: any,
  endDate: any,
  searchValue: string
) => {
  GetAPI.call(
    URL_CONSTANTS.getClientRowData(limit, skip, starDate, endDate, searchValue),
    onSuccess,
    onError
  );
};

const get_add_new_client_layouts = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void
) => {
  GetAPI.call(URL_CONSTANTS.get_client_layout, onSuccess, onError);
};

const delete_clients = (
  dto: any,
  onSuccess: (data: any) => void,
  onError: (err: any) => void
) => {
  DeleteAPI.call(URL_CONSTANTS.delete_clients, dto, onSuccess, onError);
};

const get_edit_one_data = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
  id: any
) => {
  GetAPI.call(`${URL_CONSTANTS.get_one_client}${id}`, onSuccess, onError);
};

const get_Spoc = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void
) => {
  GetAPI.call(URL_CONSTANTS.get_spoc, onSuccess, onError);
};

const client_indivisualEdit = (
  dto: any,
  onSuccess: (data: any) => void,
  {},
  onError: (err: any) => void,
  id: any
) => {
  PatchAPI.call(
    `${URL_CONSTANTS.get_client_indivisual_edit}${id}`,
    dto,
    onSuccess,
    {},
    onError
  );
};
const updateInsurerIndividualEdit = (
  dto: any,
  onSuccess: (data: any) => void,
  { },
  onError: (err: any) => void,
  id: any
) => {
  PatchAPI.call(
    `${URL_CONSTANTS.get_client_indivisual_edit}${id}`,
    dto,
    onSuccess,
    {},
    onError
  );
};

const client_details_subsidiary = (
  dto: any,
  onSuccess: (data: any) => void,
  onError: (err: any) => void
) => {
  PatchAPI.call(
    URL_CONSTANTS.get_client_details_subsidiary,
    dto,
    onSuccess,
    onError
  );
};

const Is_subsidiary = (
  dto: any,
  id: any,
  onSuccess: (data: any) => void,
  onError: (err: any) => void
) => {
  PatchAPI.call(
    `${URL_CONSTANTS.Is_subsidiary}${id}`,
    dto,
    onSuccess,
    onError
  );
};

const get_clients_list = (
  user: string,
  onSuccess: (data: any) => void,
  onError: (err: any) => void
) => {
  GetAPI.call(URL_CONSTANTS.get_client_list(user), onSuccess, onError);
};

const get_parent_org_details = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
  id: any
) => {
  GetAPI.call(`${URL_CONSTANTS.get_parent_details}${id}`, onSuccess, onError);
};
const client_contact_list = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
  id: any
) => {
  GetAPI.call(`${URL_CONSTANTS.client_contact_list}${id}`, onSuccess, onError);
};

const delete_attachment_client = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
  clientId: any,
  attachmentsId: any
) => {
  DeleteAPI.call(
    `${URL_CONSTANTS.delete_attachment}?clientId=${clientId}&attachmentsId=${attachmentsId}`,
    {},
    onSuccess,
    {},
    onError
  );
};
const delete_location_client_details = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
  clientId: any,
  locationId: any
) => {
  DeleteAPI.call(
    `${URL_CONSTANTS.client_delete_details_location}?clientId=${clientId}&locationId=${locationId}`,
    onSuccess,
    onError
  );
};

const delete_location_client = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
  clientId: any,
  locationId: any
) => {
  DeleteAPI.call(
    `${URL_CONSTANTS.delete_location}?clientId=${clientId}&locationId=${locationId}`,
    onSuccess,
    onError
  );
};

const client_status = (
  dto: any,
  id: any,
  onSuccess: (data: any) => void,
  onError: (err: any) => void
) => {
  PatchAPI.call(`${URL_CONSTANTS.client_status}${id}`, dto, onSuccess, onError);
};

const getSpocList = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void
) => {
  GetAPI.call(URL_CONSTANTS.get_spocList, onSuccess, onError);
};

const client_subsidiary_list = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
  id: any
) => {
  GetAPI.call(
    `${URL_CONSTANTS.client_subsidary_list}${id}`,
    onSuccess,
    onError
  );
};

const dynamic_status_list = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
  id: any
) => {
  GetAPI.call(
    `${URL_CONSTANTS.dynamic_status_list}?id=${id}`,
    onSuccess,
    onError
  );
};
const policy_data = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
  limit: number,
  skip: number,
  id: any
) => {
  GetAPI.call(`${URL_CONSTANTS.get_policy_data(limit,skip,id)}`, onSuccess, onError);
};

const massModification = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void
) => {
  GetAPI.call(URL_CONSTANTS.massmodificationclient, onSuccess, onError);
};

const updateMassmodification = (
  dto: any,
  onSuccess: (data: any) => void,
  { },
  onError: (err: any) => void
) => {
  PatchAPI.call(
    URL_CONSTANTS.updateMassmodificationclient,
    dto,
    onSuccess,
    onError
  );
};

const LOAD_FIELD_DATA_FOR_BULK_UPLOAD = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void
) => {
  GetAPI.call(
    URL_CONSTANTS.client_field_data_for_bulk_upload,
    onSuccess,
    onError
  );
};
const csvDownload = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
  dto: any,
  user: string,
  model: string,
) => {
  PostAPI.call(
    URL_CONSTANTS.csv_download_client(user, model),
    dto,
    onSuccess,
    {},
    onError
  );
};
const check_isclient_attached_toPolicy = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
  id:any
) => {
  GetAPI.call(
    `${URL_CONSTANTS.check_isclient_attached_toPolicy}${id}`,
    onSuccess,
    onError
  );
};
export const CLIENT_SERVICES = {
  policy_data,
  get_header_field,
  get_client_row_data,
  get_add_new_client_layouts,
  delete_clients,
  get_edit_one_data,
  get_Spoc,
  client_indivisualEdit,
  client_details_subsidiary,
  get_clients_list,
  get_parent_org_details,
  delete_attachment_client,
  delete_location_client,
  client_status,
  client_contact_list,
  client_subsidiary_list,
  delete_location_client_details,
  updateInsurerIndividualEdit,
  dynamic_status_list,
  getSpocList,
  updateMassmodification,
  massModification,
  LOAD_FIELD_DATA_FOR_BULK_UPLOAD,
  Is_subsidiary,
  csvDownload,
  check_isclient_attached_toPolicy,
};
