import { Box, Button, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React from "react";
import SlidingPanel from "react-sliding-side-panel";
import CloseIcon from "@mui/icons-material/Close";
import SearchDropdown from "../../../../../Supporting files/SearchDropdown/SearchDropdown";
import DatePicker from "../../../../../Supporting files/DatePicker/DatePicker";
import RKTextField from "../../../../../Supporting files/RKTextField/RKTextField";
interface Props {
  open_status: boolean;
  attrName: any;
  value_update: Function;
}

const FourWheelerPopup: React.FC<Props> = ({
  open_status,
  attrName,
  value_update,
}) => {
  const [fullName, setFullName] = React.useState("");
  const [relationship, setRelationship] = React.useState("");
  const [memberDate, setMemberDate] = React.useState<Date | null>(null);

  const updateMasterState = (attrName: any, value: any) => {
    attrName(value);
  };

  const relationship_data = [
    { label: "Maruti Suzuki", value: "Maruti Suzuki" },
    { label: "Hyundai", value: "Hyundai" },
    { label: "Tata", value: "Tata" },
  ];
  const fueltype_data = [
    { label: "Petrol", value: "Petrol" },
    { label: "Diesel", value: "Diesel" },
    { label: "CNG", value: "CNG" },
    { label: "Electric", value: "Electric" },
  ];
  const vehicleType_data = [
    { label: "Two Wheeler", value: "Two Wheeler" },
    { label: "Four Wheeler", value: "Four Wheeler" },
  ];

  return (
    <>
      <SlidingPanel
        type={"right"}
        isOpen={open_status}
        size={75}
        panelClassName="slidingPanel"
        // backdropClicked={() => value_update(attrName, false)}
      >
        <>
          <Grid container spacing={2} alignItems="center">
            <Grid xs={10} className="headingTitle">
              <h3>
                <span>Add Your Vehicle</span>
              </h3>
              <p>
                Kindly provide essential details about your vehicle, such as the
                make and model etc.
              </p>
            </Grid>
            <Grid xs={2} textAlign="right">
              <Link
                className="closeIcon"
                onClick={() => {
                  value_update(attrName, false);
                }}
              >
                <CloseIcon />
              </Link>
            </Grid>
            <Grid xs={12}>
              <hr />
            </Grid>
          </Grid>
          <Box className="scrollable_area">
            <Grid container spacing={3}>
              <Grid xs={6}>
                <RKTextField
                  class_name="inputField"
                  title={"Registration Number"}
                  value={fullName}
                  attrName={setFullName}
                  value_update={updateMasterState}
                  warn_status={false}
                />
              </Grid>
              <Grid xs={6}>
                <SearchDropdown
                  class_name="inputField"
                  title="Vehicle Type"
                  value={relationship}
                  attrName={setRelationship}
                  value_update={updateMasterState}
                  data={vehicleType_data}
                  warn_status={false}
                  error_message={"please select type"}
                />
              </Grid>
              <Grid xs={6}>
                <SearchDropdown
                  class_name="inputField"
                  title="Make & Model"
                  value={relationship}
                  attrName={setRelationship}
                  value_update={updateMasterState}
                  data={relationship_data}
                  warn_status={false}
                  error_message={"please select make & model"}
                />
              </Grid>
              <Grid xs={6}>
                <SearchDropdown
                  class_name="inputField"
                  title="Fuel Type"
                  value={relationship}
                  attrName={setRelationship}
                  value_update={updateMasterState}
                  data={fueltype_data}
                  warn_status={false}
                  error_message={"please select fuel type"}
                />
              </Grid>
              <Grid xs={6}>
                <SearchDropdown
                  class_name="inputField"
                  title="Variant"
                  value={relationship}
                  attrName={setRelationship}
                  value_update={updateMasterState}
                  data={relationship_data}
                  warn_status={false}
                  error_message={"please select variant"}
                />
              </Grid>
              <Grid xs={6}>
                <SearchDropdown
                  class_name="inputField"
                  title="Insurer"
                  value={relationship}
                  attrName={setRelationship}
                  value_update={updateMasterState}
                  data={relationship_data}
                  warn_status={false}
                  error_message={"please select insurer"}
                />
              </Grid>
              <Grid xs={6}>
                <DatePicker
                  class_name="inputField"
                  title="Reg. Date"
                  value={memberDate}
                  attrName={setMemberDate}
                  value_update={updateMasterState}
                  error_message="Please enter DOB"
                  warn_status={false}
                />
              </Grid>
              <Grid xs={6}>
                <DatePicker
                  class_name="inputField"
                  title="Policy Expiry"
                  value={memberDate}
                  attrName={setMemberDate}
                  value_update={updateMasterState}
                  error_message="Please enter DOB"
                  warn_status={false}
                />
              </Grid>
            </Grid>
          </Box>
          <Grid container spacing={3}>
            <Grid xs={12} className="ctaBtn mt-4">
              <Button
                variant="contained"
                className="submitBtn fixedbtn"
                onClick={() => {}}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </>
      </SlidingPanel>
    </>
  );
};

export default FourWheelerPopup;
