export enum ALL_ROUTES {
  STAR = "*",
  LOGIN = "/",
  RESET_PASSWORD = "/reset-password",
  HOME = "/home",
  HOME_BROKER = "/home-broker",
  CUSTOM_MODULES = "/custom-modules",
  EDIT_CONTACT = "/edit-contact",
  CONTACT_DETAIL_VIEW = "/contact-detail",
  ADD_NEW_QUOTES = "/add-new-quotes",
  QUOTE_DETAIL = "/quote-details",
  ADD_NEW_CLIENT = "/add-new-client",
  EDIT_CLIENT = "/edit-client",
  CLIENT_DETAIL_VIEW = "/client-detail",
  SETTING = "/settings",
  TASK_MANGEMENT = "/task-management",
  TASK_DETAIL = "/task-detail",
  BULK_UPLOAD_PAGE = "/bulk-upload-page",
  MAP_FIELDS = "/map-fields",
  MAP_FILED_CONFIRMATION = "/map-fields-confirmation",
  BULK_UPLOAD_LIST = "/bulk-upload-list",
  ADD_NEW_INSURER = "/add-new-insurer",
  ADD_NEW_TPA = "/add-new-tpa",
  SETTING_MODULE_FORM = "/setting-module-form",
  INSURER_DETAILS = "/insurer-details",
  TPA_DETAILS = "/tpa-details",
  EDIT_INSURER = "/edit-insurer",
  EDIT_TPA = "/edit-tpa",
  ADD_EMPLOYEE = "/add-new-member",
  EDIT_EMPLOYEE = "/edit-member",
  ADD_BRANCH = "/create-branch",
  EDIT_BRANCH = "/edit-branch",
  EDIT_QUOTES = "/edit-quotes",
  BULK_ASSIGN = "/bulkassign",

  //Product config children START
  PRODUCT_CONFIG_GROUP_HEALTH = `/product-config/group-health`,
  PRODUCT_CONFIG_SUPER_TOPUP = `/product-config/super-topup`,
  PRODUCT_CONFIG_GROUP_TERM = `/product-config/group-term`,
  PRODUCT_CONFIG_GROUP_PERSONAL_ACCIDENT = `/product-config/personal-accident`,
  PRODUCT_CONFIG_GROUP_CRITICAL_ILLNESS = `/product-config/critical-illness`,
  PRODUCT_CONFIG_GROUP_TRAVEL = `/product-config/group-travel`,
  PRODUCT_CONFIG_VOLUNTARY_PLANS = `/product-config/voluntary-plans`,
  PRODUCT_CONFIG_INSURERS = `/product-config/insurer`,
  PRODUCT_CONFIG_TPA = `/product-config/tpa`,
  PRODUCT_CONFIG_SETTINGS = `/product-config/settings`,
  //Product config children END
  DASHBOARD = "/",
  QUOTES = "/quotes",
  POLICIES = "/policies",
  POLICY_RENEWAL = "/policy-renewal",
  EDIT_POLICY = "/edit-policy",
  CLIENTS = "/clients",
  CLIENTSS = "/clients/",
  CONTACTS = "/contacts",
  EMPLOYEE = "/member",
  ENDORSEMENT = "/endorsement",
  ENDORSEMENT_DETAILS = "/endorsement_details",
  ENDORSEMENT_DETAILS_MEMBER_ADDITION = "/endorsement-details-addition",
  CLIAM_ASSISTANT = "/claim-assistant",
  CLAIM_DETAIL = "/claim-assitant-detail",
  TRENDING_QUESTION = "/trending-question",
  COMMUNITY = "/community",
  REPORT = "/report",
  SUPPORT = "/support",
  POLICY_DETAILS = "/policy-details",
  ADD_NEW_POLICY = "/add-new-policy",
  ADD_NEW_CONTACT = "/add-new-contact",
  ADD_VOLUNTRY_PLAN = "/add-voluntry-plan",
  VOLUNTRY_DETAILS = "voluntry-details",
  EDIT_PERSONAL_DETAILS = "edit-personal-details",
  EDIT_ORG_DETAILS = "/setting-module-form/edit-org-details",
  NOTIFICATION = "/notification",
  SETTING_MODULE = "/Setting-module-form",
  EMPLOYEE_DETAILS_VIEW = "/member-detail-view",
  CREATE_USER = "/create-user",
  EDIT_USER = "/edit-user",
  EDIT_ACCESS_CONTROL = "/edit-access-control",
  CREATE_ACCESS_CONTROL = "/create-access-control",
  NETWORK_HOSPITAL = "/network-hospital",
  HOME_EMPLOYER = "/home-employer",
}
