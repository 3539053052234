import { URL_CONSTANTS } from "../../APIVar/URLConstants";
import {GetAPI, PatchAPI} from "../API"

const organisation_details = (onSuccess:(data:any)=>void, onError:(err:any)=>void,usertype: string)=>{
    GetAPI.call(`${URL_CONSTANTS.organisation_details(usertype)}`,onSuccess,{}, onError)
}

const update_organisation_details = (dto:any,onSuccess:(data:any)=>void, onError:(err:any)=>void)=>{
    PatchAPI.call(`${URL_CONSTANTS.update_organisation_details}`,dto,onSuccess,{}, onError)
}


export const ORGANISATION_SERVICES = {
    organisation_details,
    update_organisation_details,
    
}