import React from "react";
import SortableItem from "./SortableItem";
import { SortableContainer, SortableContainerProps } from "react-sortable-hoc";
import "./UGTable.scss";

interface Props extends SortableContainerProps {
  items: Array<{}>;
  onSortEnd: any;
  onClickFilterCheckbox: any;
  BE_filter_code: number;
}

const SortableList: React.FC<Props> = ({
  items,
  onSortEnd,
  onClickFilterCheckbox,
  BE_filter_code,
}) => {
  return (
    <ul>
      {items.map((value: any, index: number) => {
        const propsList = { value, onClickFilterCheckbox, BE_filter_code };

        return (
          <SortableItem {...propsList} key={`item-${index}`} index={index} />
        );
      })}
    </ul>
  );
};

export default SortableContainer(SortableList);
