import CloseIcon from "@mui/icons-material/Close";
import { Button, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useEffect, useState } from "react";
import SlidingPanel from "react-sliding-side-panel";
import { toast } from "react-toastify";
import { GROUP_SUPER_TOPUP } from "../../../../../Services/ProductConfigurator/GroupSuperTopUp";
import RKTextField from "../../../../../Supporting files/RKTextField/RKTextField";
import SelectDropdown from "../../../../../Supporting files/SelectDropdown/SelectDropdown";
import TextAreaField from "../../../../../Supporting files/TextAreaField/TextAreaField";
import "./AddFeatures.scss";

interface Props {
  open_status: boolean;
  attrName: any;
  value_update: Function;
  table_data: any;
  api_url: string;
  get_category_api_url: string;
  shortcutDisable?: boolean, setShortcutDisable?: Function
}

const EditFeature: React.FC<Props> = ({
  open_status,
  attrName,
  value_update,
  table_data,
  api_url,
  get_category_api_url,
  shortcutDisable, setShortcutDisable
}) => {
  const [rowsCategory, setRowsCategory] = useState<Array<{
    _id: Number;
    category: { name: string };
  }>>([]);
  const [editCategoryStep, setEditCategoryStep] = useState<{
    categoryId: {
      categoryId: string;
    };
    featureDetails: {
      name: string;
      fieldType: string;
      valueType: string;
      unit: string;
      description: string;
      customUnitType: string;
      dropDownOptions: any;
    };
    _id: string;
  }>({
    _id: "",
    categoryId: { categoryId: "" },
    featureDetails: {
      description: "",
      fieldType: "",
      name: "",
      unit: "",
      valueType: "",
      customUnitType: "",
      dropDownOptions: [
        {
          id: "",
          label: "",
        },
      ],
    },
  });
  const addFieldType_data = [
    { key: "Input Field", value: "Input Field" },
    { key: "Dropdown List", value: "Dropdown List" },
  ];
  const addValueType_data = [
    { key: "Numeric Only", value: "Numeric Only" },
    { key: "Alphanumeric", value: "Alphanumeric" },
    { key: "Text Only", value: "Text Only" },
  ];
  const unitType_data = [
    { key: "Days", value: "Days" },
    { key: "Months", value: "Months" },
    { key: "Years", value: "Years" },
    { key: "Free Text", value: "Free Text" },
    { key: "Currency", value: "Currency" },
    { key: "Percentage", value: "Percentage" },
  ];

  useEffect(() => {
    getCatageoryetails();
  }, [open_status]);

  useEffect(() => {
    if (table_data && table_data.categoryName) {
      let data: {
        categoryId: {
          categoryId: string;
        };
        featureDetails: {
          name: string;
          fieldType: string;
          valueType: string;
          unit: string;
          description: string;
          customUnitType: string;
          dropDownOptions: any;
        };
        _id: string;
      } = {
        categoryId: {
          categoryId:
            // table_data.categoryId.category &&
            table_data.categoryId,
        },
        featureDetails: {
          description: table_data.description,
          fieldType: table_data.fieldType,
          name: table_data.name,
          unit: table_data.unit,
          valueType: table_data.valueType,
          customUnitType: table_data.customUnitType,
          dropDownOptions: table_data.dropDownOptions,
        },
        _id: table_data._id,
      };

      setEditCategoryStep(data);
    }
  }, [table_data]);

  const updateMasterStateArray = (attrName: any, value: any) => {
    if (attrName[1] === "category_name") {
      attrName[0].categoryId = value;
    } else if (attrName[1] === "feature_name") {
      attrName[0].name = value;
    } else if (attrName[1] === "field_type") {
      attrName[0].fieldType = value;
      attrName[0].unit = "";
      attrName[0].valueType = "";
      attrName[0].customUnitType = "";
      attrName[0].fieldType = value;
    } else if (attrName[1] === "value_type") {
      attrName[0].valueType = value;
      attrName[0].unit = "";
      attrName[0].customUnitType = "";
    } else if (attrName[1] === "unit_type") {
      attrName[0].unit = value;
      attrName[0].customUnitType = "";
    } else if (attrName[1] === "custom_unit_type") {
      attrName[0].customUnitType = value;
    } else if (attrName[1] === "description") {
      attrName[0].description = value;
    } else if (attrName[2] === "Drop down Field value") {
      attrName[0][attrName[1]].label = value;
    }
    setEditCategoryStep({ ...editCategoryStep });
  };

  const getCatageoryetails = () => {
    const onSuccess = (res: any) => {
      let arrdata: any = [];
      res.data.data.forEach((e: any) => {
        arrdata.push({ key: e._id, value: e.category.name });
      });
      setRowsCategory(arrdata);
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    GROUP_SUPER_TOPUP.getCatageory(get_category_api_url, onSuccess, onError);
  };

  const editFeature = () => {
    let filteredOptions = editCategoryStep?.featureDetails?.dropDownOptions?.map(
      (data: any) => {
        return { id: data.id, label: data.label };
      }
    );
    let error = false;
    if (
      !editCategoryStep.categoryId ||
      !editCategoryStep.featureDetails.fieldType ||
      !editCategoryStep.featureDetails.name ||
      !editCategoryStep.featureDetails.description
    ) {
      error = true;
    }
    if (editCategoryStep.featureDetails.fieldType === "Input Field") {
      if (!editCategoryStep.featureDetails.valueType) {
        error = true;
      }
      if (editCategoryStep.featureDetails.valueType === "Numeric Only") {
        if (!editCategoryStep.featureDetails.unit) {
          error = true;
        }
      }
    }

    if (
      editCategoryStep?.featureDetails?.fieldType === "Dropdown List"
    ) {
      editCategoryStep.featureDetails.dropDownOptions.map(
        (Indata: any, objectIndex: number) => {
          if (Indata.label === "") {
            error = true;
          }
        }
      );
    }

    const onSuccess = (res: any) => {
      value_update(attrName, false);
      if (setShortcutDisable) {
        setShortcutDisable(false);
      }
    };
    const onError = (err: any) => {
      console.log("err", err);
    };

    if (!error) {
      GROUP_SUPER_TOPUP.updateFeature(
        api_url,
        {
          categoryId: editCategoryStep.categoryId.categoryId,
          featureDetails: {
            name: editCategoryStep.featureDetails.name,
            fieldType: editCategoryStep.featureDetails.fieldType,
            valueType: editCategoryStep.featureDetails.valueType,
            unit: editCategoryStep.featureDetails.unit,
            description: editCategoryStep.featureDetails.description,
            customUnitType: editCategoryStep?.featureDetails.customUnitType,
            dropDownOptions: filteredOptions,
          },
        },
        onSuccess,
        {},
        onError,
        table_data?._id ?? table_data?.id
      );
    } else {
      toast.error("Please enter Input field's value");
    }
  };

  const removePopup = () => {
    value_update(attrName, false);
    if (setShortcutDisable) {
      setShortcutDisable(false);
    }
  };

  const addInputField = () => {
    const newCatStep = {
      ...editCategoryStep,
      featureDetails: {
        ...editCategoryStep.featureDetails,
        dropDownOptions: [
          ...editCategoryStep.featureDetails.dropDownOptions,
          { id: "", label: "" },
        ],
      },
    };
    setEditCategoryStep(newCatStep);
  };

  const deleteInputField = (optionIndex: number) => {
    const newOptions = editCategoryStep.featureDetails.dropDownOptions;
    newOptions.splice(optionIndex, 1);

    const newCatStep = {
      ...editCategoryStep,
      featureDetails: {
        ...editCategoryStep.featureDetails,
        dropDownOptions: newOptions,
      },
    };
    setEditCategoryStep(newCatStep);
  };

  return (
    <>
      <SlidingPanel
        type={"right"}
        isOpen={open_status}
        size={75}
        panelClassName="slidingPanel"
      // backdropClicked={() => value_update(attrName, false)}
      >
        <div>
          <Grid container spacing={3} alignItems="center" className="mt-0">
            <Grid xs={10}>
              <h4 className="mb-0">Edit Feature</h4>
            </Grid>
            <Grid xs={2} className="pt-3 text-right">
              <CloseIcon onClick={removePopup} />
            </Grid>
            <Grid xs={12}>
              <hr />
            </Grid>
          </Grid>
          <div className="scrollable_area">
            <Grid container spacing={3}>
              <Grid xs={12}>
                <Typography>
                  <Grid container spacing={3} alignItems="center">
                    <Grid xs={6}>
                      <SelectDropdown
                        class_name="inputField"
                        title="Select Category"
                        value={
                          editCategoryStep.categoryId
                            ? editCategoryStep.categoryId.categoryId
                            : "Not avilable"
                        }
                        attrName={[
                          editCategoryStep.categoryId,
                          "category_name",
                        ]}
                        value_update={updateMasterStateArray}
                        dropdown_data={rowsCategory}
                      />
                    </Grid>
                    <Grid xs={6}>
                      <RKTextField
                        class_name="inputField"
                        title={"Feature Name"}
                        value={editCategoryStep?.featureDetails?.name}
                        attrName={[
                          editCategoryStep.featureDetails,
                          "feature_name",
                        ]}
                        value_update={updateMasterStateArray}
                        warn_status={false}
                      />
                    </Grid>
                    <Grid xs={4}>
                      <SelectDropdown
                        class_name="inputField"
                        title="Field Type"
                        value={editCategoryStep?.featureDetails?.fieldType}
                        attrName={[
                          editCategoryStep.featureDetails,
                          "field_type",
                        ]}
                        value_update={updateMasterStateArray}
                        dropdown_data={addFieldType_data}
                      />
                    </Grid>
                    <Grid xs={4}>
                      <SelectDropdown
                        class_name="inputField"
                        disabled={
                          editCategoryStep?.featureDetails?.fieldType ===
                          "Dropdown List"
                        }
                        title="Value Type"
                        value={editCategoryStep?.featureDetails?.valueType}
                        attrName={[
                          editCategoryStep.featureDetails,
                          "value_type",
                        ]}
                        value_update={updateMasterStateArray}
                        dropdown_data={addValueType_data}
                      />
                    </Grid>
                    <Grid xs={4}>
                      <SelectDropdown
                        class_name="inputField"
                        disabled={
                          editCategoryStep?.featureDetails?.valueType !==
                          "Numeric Only"
                        }
                        title="Unit Type"
                        value={editCategoryStep?.featureDetails?.unit}
                        attrName={[
                          editCategoryStep?.featureDetails,
                          "unit_type",
                        ]}
                        value_update={updateMasterStateArray}
                        dropdown_data={unitType_data}
                      />
                    </Grid>

                    {editCategoryStep?.featureDetails.unit === "Free Text" && (
                      <Grid xs={12}>
                        <RKTextField
                          class_name="inputField"
                          title={"Custom Unit Type"}
                          value={editCategoryStep?.featureDetails?.customUnitType}
                          attrName={[
                            editCategoryStep?.featureDetails,
                            "custom_unit_type",
                          ]}
                          value_update={updateMasterStateArray}
                          warn_status={false}
                        />
                      </Grid>
                    )}
                    {editCategoryStep?.featureDetails?.fieldType ===
                      "Dropdown List" ? (
                      <Grid xs={12} className="text-right">
                        <button
                          className="greenBtn addfile mb-3"
                          onClick={() => addInputField()}
                        >
                          Add DropDown Fields
                        </button>
                        {editCategoryStep.featureDetails.dropDownOptions.map(
                          (inputField: any, indexx: number) => (
                            <div key={indexx} className="input-container">
                              <Grid
                                container
                                spacing={2}
                                style={{ padding: "0" }}
                              >
                                <Grid xs={10}>
                                  <RKTextField
                                    class_name="inputField"
                                    title={`Drop down Field ${indexx + 1}`}
                                    value={inputField.label}
                                    attrName={[
                                      editCategoryStep?.featureDetails
                                        .dropDownOptions,
                                      indexx,
                                      "Drop down Field value",
                                    ]}
                                    value_update={updateMasterStateArray}
                                    warn_status={false}
                                  />
                                </Grid>
                                <Grid xs={2}>
                                  {indexx === 0 ? (
                                    ""
                                  ) : (
                                    <button
                                      className="redBtn delete"
                                      onClick={() => deleteInputField(indexx)}
                                    >
                                      Delete
                                    </button>
                                  )}
                                </Grid>
                              </Grid>
                            </div>
                          )
                        )}
                      </Grid>
                    ) : (
                      ""
                    )}
                    <Grid xs={12}>
                      <TextAreaField
                        class_name="inputField multiline"
                        title={"Brief Description"}
                        value={editCategoryStep?.featureDetails?.description}
                        attrName={[
                          editCategoryStep.featureDetails,
                          "description",
                        ]}
                        value_update={updateMasterStateArray}
                        warn_status={false}
                        alphanumeric={true}
                        specialcharacter={true}
                      // Enter error message
                      />
                    </Grid>
                  </Grid>
                </Typography>
              </Grid>
            </Grid>
          </div>
          <Grid container spacing={3}>
            <Grid xs={12} className="ctaBtn mt-4">
              <Button
                variant="contained"
                className="submitBtn fixedbtn"
                onClick={editFeature}
              >
                Update
              </Button>
            </Grid>
          </Grid>
        </div>
      </SlidingPanel>
    </>
  );
};

export default EditFeature;
