import Grid from "@mui/material/Unstable_Grid2";
import React, { useEffect, useState } from "react";
import { MenuItem, Button, Box, Link, Tooltip, Menu } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import "./TaskManagement.scss";
import CreateTask from "./CreateTask/CreateTask";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import SelectDropdown from "../../../../Supporting files/SelectDropdown/SelectDropdown";
import { add } from "date-fns/fp";
import { ALL_ROUTES } from "../../../../Routes/all_routes";

interface Props {
  intiateClaim: boolean;
}

const TaskManagement: React.FC<Props> = ({ intiateClaim }) => {
  const [boxGroupData, setBoxGroupData] = React.useState([
    {
      box_left_border: "",
      task_name: "",
      module: "",
      priority_status: "",
      priority_status_box_color: "",
      work_status: "",
      date: "",
      description: "",
    },
  ]);
  const [endorsementGroupData, setEndorsementGroupData] = React.useState([
    {
      box_left_border: "",
      task_name: "",
      module: "",
      priority_status: "",
      priority_status_box_color: "",
      work_status: "",
      date: "",
      description: "",
    },
  ]);
  const [claimsGroupData, setClaimsGroupData] = React.useState([
    {
      box_left_border: "",
      task_name: "",
      module: "",
      priority_status: "",
      priority_status_box_color: "",
      work_status: "",
      date: "",
      description: "",
    },
  ]);
  const [clientsGroupData, setClientsGroupData] = React.useState([
    {
      box_left_border: "",
      task_name: "",
      module: "",
      priority_status: "",
      priority_status_box_color: "",
      work_status: "",
      date: "",
      description: "",
    },
  ]);

  const [workStatus, setWorkStatus] = React.useState("Follow Up");
  const workStatus_data = [
    { key: "Follow Up", value: "Follow Up" },
    { key: "Follow Up", value: "Follow Up" },
    { key: "Follow Up", value: "Follow Up" },
  ];

  const [addFilter, setAddFilter] = React.useState(false);
  const [policyType, setPolicyType] = React.useState("");
  const [policyname, setPolicyName] = React.useState("");
  const [expiry, setExpiryStatus] = React.useState("");
  const [relation, setRelation] = React.useState("");
  const [selectDocument, setSelectDocument] = React.useState("");
  const [addTask, setAddTask] = React.useState(intiateClaim);
  const navigate = useNavigate();

  const [viewTabsStatus, setViewTabsStatus] = React.useState("list");

  const handleChange = (event: SelectChangeEvent, attrName: string) => {
    if (attrName === "policyType") {
      setPolicyType(event.target.value as string);
    } else if (attrName === "expiry") {
      setExpiryStatus(event.target.value as string);
    } else if (attrName === "policyname") {
      setPolicyName(event.target.value as string);
    } else if (attrName === "relation") {
      setRelation(event.target.value as string);
    }
  };

  useEffect(() => {
    // attach the event listener

    setBoxGroupData([
      {
        box_left_border: "high",
        task_name: "tsk124",
        module: "Quotes-QT23",
        priority_status: "Highest",
        priority_status_box_color: "red",
        work_status: "Follow up",
        date: "Dec 11",
        description:
          "This task is created for rohit to complete his follow up call on quote id QT1234567890.",
      },
      {
        box_left_border: "med",
        task_name: "tsk124",
        module: "Quotes-QT23",
        priority_status: "Normal",
        priority_status_box_color: "yellow",
        work_status: "Follow up",
        date: "Dec 11",
        description:
          "This task is created for rohit to complete his follow up call on quote id QT1234567890.",
      },
      {
        box_left_border: "low",
        task_name: "tsk124",
        module: "Quotes-QT23",
        priority_status: "Lowest",
        priority_status_box_color: "blue",
        work_status: "Follow up",
        date: "Dec 11",
        description:
          "This task is created for rohit to complete his follow up call on quote id QT1234567890.",
      },
    ]);
    setEndorsementGroupData([
      {
        box_left_border: "high",
        task_name: "tsk124",
        module: "Quotes-QT23",
        priority_status: "Highest",
        priority_status_box_color: "red",
        work_status: "Follow up",
        date: "Dec 11",
        description:
          "This task is created for rohit to complete his follow up call on quote id QT1234567890.",
      },
      {
        box_left_border: "med",
        task_name: "tsk124",
        module: "Quotes-QT23",
        priority_status: "Normal",
        priority_status_box_color: "yellow",
        work_status: "Follow up",
        date: "Dec 11",
        description:
          "This task is created for rohit to complete his follow up call on quote id QT1234567890.",
      },
      {
        box_left_border: "low",
        task_name: "tsk124",
        module: "Quotes-QT23",
        priority_status: "Lowest",
        priority_status_box_color: "blue",
        work_status: "Follow up",
        date: "Dec 11",
        description:
          "This task is created for rohit to complete his follow up call on quote id QT1234567890.",
      },
    ]);
    setClaimsGroupData([
      {
        box_left_border: "high",
        task_name: "tsk124",
        module: "Quotes-QT23",
        priority_status: "Highest",
        priority_status_box_color: "red",
        work_status: "Follow up",
        date: "Dec 11",
        description:
          "This task is created for rohit to complete his follow up call on quote id QT1234567890.",
      },
      {
        box_left_border: "med",
        task_name: "tsk124",
        module: "Quotes-QT23",
        priority_status: "Normal",
        priority_status_box_color: "yellow",
        work_status: "Follow up",
        date: "Dec 11",
        description:
          "This task is created for rohit to complete his follow up call on quote id QT1234567890.",
      },
      {
        box_left_border: "low",
        task_name: "tsk124",
        module: "Quotes-QT23",
        priority_status: "Lowest",
        priority_status_box_color: "blue",
        work_status: "Follow up",
        date: "Dec 11",
        description:
          "This task is created for rohit to complete his follow up call on quote id QT1234567890.",
      },
    ]);
    setClientsGroupData([
      {
        box_left_border: "high",
        task_name: "tsk124",
        module: "Quotes-QT23",
        priority_status: "Highest",
        priority_status_box_color: "red",
        work_status: "Follow up",
        date: "Dec 11",
        description:
          "This task is created for rohit to complete his follow up call on quote id QT1234567890.",
      },
      {
        box_left_border: "med",
        task_name: "tsk124",
        module: "Quotes-QT23",
        priority_status: "Normal",
        priority_status_box_color: "yellow",
        work_status: "Follow up",
        date: "Dec 11",
        description:
          "This task is created for rohit to complete his follow up call on quote id QT1234567890.",
      },
      {
        box_left_border: "low",
        task_name: "tsk124",
        module: "Quotes-QT23",
        priority_status: "Lowest",
        priority_status_box_color: "blue",
        work_status: "Follow up",
        date: "Dec 11",
        description:
          "This task is created for rohit to complete his follow up call on quote id QT1234567890.",
      },
    ]);
  }, []);

  //Table Data
  const [data, setData] = React.useState<
    Array<{
      title: string;
      title_class: string;
      data: Array<{
        open_status: boolean;
        title: string;
        status: string;
        due_date: string;
        priority: string;
        priority_class: string;
        linked_to: string;
        assignee: Array<string>;
        creation_date: string;
        creation_time: string;
        sub_data: Array<{
          title: string;
          status: string;
          due_date: string;
          priority: string;
          priority_class: string;
          linked_to: string;
          assignee: Array<string>;
          creation_date: string;
          creation_time: string;
        }>;
      }>;
    }>
  >([
    {
      title: "Started",
      title_class: "green",
      data: [
        {
          open_status: false,
          title: "Follow Up",
          status: "started",
          due_date: "31 Dec",
          priority: "High",
          priority_class: "high",
          linked_to: "Quotes-QT25",
          assignee: ["U", "R"],
          creation_date: "23 Dec",
          creation_time: "17:00",
          sub_data: [
            {
              title: "Second Call",
              status: "inqueue",
              due_date: "25 Nov",
              priority: "Highest",
              priority_class: "highest",
              linked_to: "Quotes-QT25",
              assignee: ["U", "R"],
              creation_date: "28 Dec",
              creation_time: "13:00",
            },
            {
              title: "Final Call",
              status: "completed",
              due_date: "20 Dec",
              priority: "Low",
              priority_class: "low",
              linked_to: "Quotes-QT28",
              assignee: ["U", "R"],
              creation_date: "20 Dec",
              creation_time: "15:00",
            },
            {
              title: "Follow Up",
              status: "inqueue",
              due_date: "31 Dec",
              priority: "Normal",
              priority_class: "normal",
              linked_to: "Quotes-QT24",
              assignee: ["U", "R"],
              creation_date: "26 Dec",
              creation_time: "19:00",
            },
          ],
        },
        {
          open_status: false,
          title: "Follow Up",
          status: "inqueue",
          due_date: "28 Dec",
          priority: "Low",
          priority_class: "low",
          linked_to: "Quotes-QT28",
          assignee: ["U", "R"],
          creation_date: "10 Dec",
          creation_time: "13:00",
          sub_data: [
            {
              title: "Second Call",
              status: "inqueue",
              due_date: "25 Nov",
              priority: "Highest",
              priority_class: "highest",
              linked_to: "Quotes-QT25",
              assignee: ["U", "R"],
              creation_date: "28 Dec",
              creation_time: "13:00",
            },
            {
              title: "Final Call",
              status: "completed",
              due_date: "20 Dec",
              priority: "Low",
              priority_class: "low",
              linked_to: "Quotes-QT28",
              assignee: ["U", "R"],
              creation_date: "20 Dec",
              creation_time: "15:00",
            },
            {
              title: "Follow Up",
              status: "inqueue",
              due_date: "31 Dec",
              priority: "Normal",
              priority_class: "normal",
              linked_to: "Quotes-QT24",
              assignee: ["U", "R"],
              creation_date: "26 Dec",
              creation_time: "19:00",
            },
          ],
        },
        {
          open_status: false,
          title: "Follow Up",
          status: "completed",
          due_date: "25 Dec",
          priority: "Highest",
          priority_class: "highest",
          linked_to: "Quotes-QT23",
          assignee: ["U", "R"],
          creation_date: "3 Dec",
          creation_time: "19:00",
          sub_data: [
            {
              title: "Second Call",
              status: "inqueue",
              due_date: "25 Nov",
              priority: "Highest",
              priority_class: "highest",
              linked_to: "Quotes-QT25",
              assignee: ["U", "R"],
              creation_date: "28 Dec",
              creation_time: "13:00",
            },
            {
              title: "Final Call",
              status: "completed",
              due_date: "20 Dec",
              priority: "Low",
              priority_class: "low",
              linked_to: "Quotes-QT28",
              assignee: ["U", "R"],
              creation_date: "20 Dec",
              creation_time: "15:00",
            },
            {
              title: "Follow Up",
              status: "inqueue",
              due_date: "31 Dec",
              priority: "Normal",
              priority_class: "normal",
              linked_to: "Quotes-QT24",
              assignee: ["U", "R"],
              creation_date: "26 Dec",
              creation_time: "19:00",
            },
          ],
        },
      ],
    },
    {
      title: "Sent to Insurer",
      title_class: "orange",
      data: [
        {
          open_status: false,
          title: "Follow Up",
          status: "inqueue",
          due_date: "15 Dec",
          priority: "Low",
          priority_class: "low",
          linked_to: "Quotes-QT13",
          assignee: ["U", "R"],
          creation_date: "3 Dec",
          creation_time: "16:00",
          sub_data: [
            {
              title: "Second Call",
              status: "inqueue",
              due_date: "25 Nov",
              priority: "Highest",
              priority_class: "highest",
              linked_to: "Quotes-QT25",
              assignee: ["U", "R"],
              creation_date: "28 Dec",
              creation_time: "13:00",
            },
            {
              title: "Final Call",
              status: "completed",
              due_date: "20 Dec",
              priority: "Low",
              priority_class: "low",
              linked_to: "Quotes-QT28",
              assignee: ["U", "R"],
              creation_date: "20 Dec",
              creation_time: "15:00",
            },
            {
              title: "Follow Up",
              status: "inqueue",
              due_date: "31 Dec",
              priority: "Normal",
              priority_class: "normal",
              linked_to: "Quotes-QT24",
              assignee: ["U", "R"],
              creation_date: "26 Dec",
              creation_time: "19:00",
            },
          ],
        },
        {
          open_status: false,
          title: "Follow Up",
          status: "completed",
          due_date: "18 Dec",
          priority: "Normal",
          priority_class: "normal",
          linked_to: "Quotes-QT18",
          assignee: ["U", "R"],
          creation_date: "10 Dec",
          creation_time: "15:00",
          sub_data: [
            {
              title: "Second Call",
              status: "inqueue",
              due_date: "25 Nov",
              priority: "Highest",
              priority_class: "highest",
              linked_to: "Quotes-QT25",
              assignee: ["U", "R"],
              creation_date: "28 Dec",
              creation_time: "13:00",
            },
            {
              title: "Final Call",
              status: "completed",
              due_date: "20 Dec",
              priority: "Low",
              priority_class: "low",
              linked_to: "Quotes-QT28",
              assignee: ["U", "R"],
              creation_date: "20 Dec",
              creation_time: "15:00",
            },
            {
              title: "Follow Up",
              status: "inqueue",
              due_date: "31 Dec",
              priority: "Normal",
              priority_class: "normal",
              linked_to: "Quotes-QT24",
              assignee: ["U", "R"],
              creation_date: "26 Dec",
              creation_time: "19:00",
            },
          ],
        },
      ],
    },
    {
      title: "In Queue",
      title_class: "lightblue",
      data: [
        {
          open_status: false,
          title: "Follow Up",
          status: "upcomingrenewal",
          due_date: "31 Dec",
          priority: "Normal",
          priority_class: "normal",
          linked_to: "Quotes-QT23",
          assignee: ["U", "R"],
          creation_date: "23 Dec",
          creation_time: "17:00",
          sub_data: [
            {
              title: "Second Call",
              status: "inqueue",
              due_date: "25 Nov",
              priority: "Highest",
              priority_class: "highest",
              linked_to: "Quotes-QT25",
              assignee: ["U", "R"],
              creation_date: "28 Dec",
              creation_time: "13:00",
            },
            {
              title: "Final Call",
              status: "completed",
              due_date: "20 Dec",
              priority: "Low",
              priority_class: "low",
              linked_to: "Quotes-QT28",
              assignee: ["U", "R"],
              creation_date: "20 Dec",
              creation_time: "15:00",
            },
            {
              title: "Follow Up",
              status: "inqueue",
              due_date: "31 Dec",
              priority: "Normal",
              priority_class: "normal",
              linked_to: "Quotes-QT24",
              assignee: ["U", "R"],
              creation_date: "26 Dec",
              creation_time: "19:00",
            },
          ],
        },
        {
          open_status: false,
          title: "Follow Up",
          status: "pendingwithinsurer",
          due_date: "31 Dec",
          priority: "Lowest",
          priority_class: "lowest",
          linked_to: "Quotes-QT23",
          assignee: ["U", "R"],
          creation_date: "23 Dec",
          creation_time: "17:00",
          sub_data: [
            {
              title: "Second Call",
              status: "inqueue",
              due_date: "25 Nov",
              priority: "Highest",
              priority_class: "highest",
              linked_to: "Quotes-QT25",
              assignee: ["U", "R"],
              creation_date: "28 Dec",
              creation_time: "13:00",
            },
            {
              title: "Final Call",
              status: "completed",
              due_date: "20 Dec",
              priority: "Low",
              priority_class: "low",
              linked_to: "Quotes-QT28",
              assignee: ["U", "R"],
              creation_date: "20 Dec",
              creation_time: "15:00",
            },
            {
              title: "Follow Up",
              status: "inqueue",
              due_date: "31 Dec",
              priority: "Normal",
              priority_class: "normal",
              linked_to: "Quotes-QT24",
              assignee: ["U", "R"],
              creation_date: "26 Dec",
              creation_time: "19:00",
            },
          ],
        },
      ],
    },
  ]);

  const updateMasterState = (attrName: any, value: string) => {
    if (attrName[0] === "data") {
      let index = attrName[1];
      let sub_index = attrName[2];

      let parsed_data = data;
      parsed_data[index].data[sub_index].status = value;

      setData(parsed_data);
    } else if (attrName[0] === "sub_data") {
      let index = attrName[1];
      let sub_index = attrName[2];
      let sub_sub_index = attrName[3];

      let parsed_data = data;
      parsed_data[index].data[sub_index].sub_data[sub_sub_index].status = value;

      setData(parsed_data);
    } else {
      attrName(value);
    }
  };

  const changeSubDataStatus = (index: number, index_data: number) => {
    let parsed_data = data;

    parsed_data[index].data[index_data].open_status =
      !parsed_data[index].data[index_data].open_status;

    setData(parsed_data);
  };

  const statusData = [
    { key: "started", value: "Started" },
    { key: "inqueue", value: "In Queue" },
    { key: "senttoinsurer", value: "Sent to Insurer" },
    { key: "upcomingrenewal", value: "Upcoming Renewal" },
    { key: "pendingwithinsurer", value: "Pending with Insurer" },
    { key: "endorsementrequestsent", value: "Endorsement Request Sent" },
    { key: "claimrequestsent", value: "Claim Request Sent" },
    { key: "completed", value: "Completed" },
  ];

  const sortByStatus = (index: number) => {
    let parsed_data: Array<{
      title: string;
      title_class: string;
      data: Array<{
        open_status: boolean;
        title: string;
        status: string;
        due_date: string;
        priority: string;
        priority_class: string;
        linked_to: string;
        assignee: Array<string>;
        creation_date: string;
        creation_time: string;
        sub_data: Array<{
          title: string;
          status: string;
          due_date: string;
          priority: string;
          priority_class: string;
          linked_to: string;
          assignee: Array<string>;
          creation_date: string;
          creation_time: string;
        }>;
      }>;
    }> = data;

    parsed_data[index].data.sort(function (a, b) {
      if (a.status < b.status) {
        return -1;
      }
      if (a.status > b.status) {
        return 1;
      }
      return 0;
    });

    setData(parsed_data);
  };

  const sortByPriority = (index: number) => {
    let parsed_data: Array<{
      title: string;
      title_class: string;
      data: Array<{
        open_status: boolean;
        title: string;
        status: string;
        due_date: string;
        priority: string;
        priority_class: string;
        linked_to: string;
        assignee: Array<string>;
        creation_date: string;
        creation_time: string;
        sub_data: Array<{
          title: string;
          status: string;
          due_date: string;
          priority: string;
          priority_class: string;
          linked_to: string;
          assignee: Array<string>;
          creation_date: string;
          creation_time: string;
        }>;
      }>;
    }> = data;

    parsed_data[index].data.sort(function (a, b) {
      if (a.status < b.status) {
        return -1;
      }
      if (a.status > b.status) {
        return 1;
      }
      return 0;
    });

    setData(parsed_data);
  };

  const dueDate = (index: number) => {
    let parsed_data: Array<{
      title: string;
      title_class: string;
      data: Array<{
        open_status: boolean;
        title: string;
        status: string;
        due_date: string;
        priority: string;
        priority_class: string;
        linked_to: string;
        assignee: Array<string>;
        creation_date: string;
        creation_time: string;
        sub_data: Array<{
          title: string;
          status: string;
          due_date: string;
          priority: string;
          priority_class: string;
          linked_to: string;
          assignee: Array<string>;
          creation_date: string;
          creation_time: string;
        }>;
      }>;
    }> = data;

    parsed_data[index].data.sort(function (a, b) {
      if (a.status < b.status) {
        return -1;
      }
      if (a.status > b.status) {
        return 1;
      }
      return 0;
    });

    setData(parsed_data);
  };

  const linkedTo = (index: number) => {
    let parsed_data: Array<{
      title: string;
      title_class: string;
      data: Array<{
        open_status: boolean;
        title: string;
        status: string;
        due_date: string;
        priority: string;
        priority_class: string;
        linked_to: string;
        assignee: Array<string>;
        creation_date: string;
        creation_time: string;
        sub_data: Array<{
          title: string;
          status: string;
          due_date: string;
          priority: string;
          priority_class: string;
          linked_to: string;
          assignee: Array<string>;
          creation_date: string;
          creation_time: string;
        }>;
      }>;
    }> = data;

    parsed_data[index].data.sort(function (a, b) {
      if (a.status < b.status) {
        return -1;
      }
      if (a.status > b.status) {
        return 1;
      }
      return 0;
    });

    setData(parsed_data);
  };

  const assignee = (index: number) => {
    let parsed_data: Array<{
      title: string;
      title_class: string;
      data: Array<{
        open_status: boolean;
        title: string;
        status: string;
        due_date: string;
        priority: string;
        priority_class: string;
        linked_to: string;
        assignee: Array<string>;
        creation_date: string;
        creation_time: string;
        sub_data: Array<{
          title: string;
          status: string;
          due_date: string;
          priority: string;
          priority_class: string;
          linked_to: string;
          assignee: Array<string>;
          creation_date: string;
          creation_time: string;
        }>;
      }>;
    }> = data;

    parsed_data[index].data.sort(function (a, b) {
      if (a.status < b.status) {
        return -1;
      }
      if (a.status > b.status) {
        return 1;
      }
      return 0;
    });

    setData(parsed_data);
  };

  const creationDate = (index: number) => {
    let parsed_data: Array<{
      title: string;
      title_class: string;
      data: Array<{
        open_status: boolean;
        title: string;
        status: string;
        due_date: string;
        priority: string;
        priority_class: string;
        linked_to: string;
        assignee: Array<string>;
        creation_date: string;
        creation_time: string;
        sub_data: Array<{
          title: string;
          status: string;
          due_date: string;
          priority: string;
          priority_class: string;
          linked_to: string;
          assignee: Array<string>;
          creation_date: string;
          creation_time: string;
        }>;
      }>;
    }> = data;

    parsed_data[index].data.sort(function (a, b) {
      if (a.status < b.status) {
        return -1;
      }
      if (a.status > b.status) {
        return 1;
      }
      return 0;
    });

    setData(parsed_data);
  };

  const creationTime = (index: number) => {
    let parsed_data: Array<{
      title: string;
      title_class: string;
      data: Array<{
        open_status: boolean;
        title: string;
        status: string;
        due_date: string;
        priority: string;
        priority_class: string;
        linked_to: string;
        assignee: Array<string>;
        creation_date: string;
        creation_time: string;
        sub_data: Array<{
          title: string;
          status: string;
          due_date: string;
          priority: string;
          priority_class: string;
          linked_to: string;
          assignee: Array<string>;
          creation_date: string;
          creation_time: string;
        }>;
      }>;
    }> = data;

    parsed_data[index].data.sort(function (a, b) {
      if (a.status < b.status) {
        return -1;
      }
      if (a.status > b.status) {
        return 1;
      }
      return 0;
    });

    setData(parsed_data);
  };

  const [folderData, setFolderData] = useState([
    {
      folder_title: "Folder 1",
      folder_value: "...",
    },
    {
      folder_title: "Folder 2",
      folder_value: "...",
    },
    {
      folder_title: "Folder 3",
      folder_value: "...",
    },
    {
      folder_title: "Folder 4",
      folder_value: "...",
    },
  ]);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <Grid container spacing={3} className="task_headerSection pt-3">
        <Grid xs={12}>
          <h3 className="pageTitle mb-0 ">
            <Link
              onClick={() => {
                navigate(-1);
              }}
            >
              <ChevronLeftIcon />
            </Link>
            Task Management
          </h3>
        </Grid>
      </Grid>
      <Grid container spacing={3} className="task_management_section">
        <Grid xs={2}>
          <div className="left_section">
            <div className="folder_heading">
              <ul>
                <li>Folder</li>
                <li>
                  <Link className="edit">Edit</Link>
                </li>
              </ul>
              <hr />
            </div>
            <div className="folder_section">
              {folderData.map((data, index) => (
                <ul className={index === 0 ? "black" : "white"}>
                  <li
                    className={
                      index === 0 ? "black folder_name" : "white folder_name"
                    }
                  >
                    {data.folder_title}
                  </li>
                  <li>
                    <Link
                      id={"basic-button" + index.toString()}
                      aria-controls={
                        open ? "basic-menu" + index.toString() : undefined
                      }
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      className={
                        index === 0
                          ? "black dotted_lines"
                          : "white dotted_lines"
                      }
                      onClick={(e) => handleClick(e)}
                    >
                      {data.folder_value}
                    </Link>
                    <Menu
                      id={"basic-menu" + index.toString()}
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      MenuListProps={{
                        "aria-labelledby": "basic-button" + index.toString(),
                      }}
                    >
                      <MenuItem onClick={handleClose}>Rename</MenuItem>
                      <MenuItem onClick={handleClose}>Delete Folder</MenuItem>
                    </Menu>
                  </li>
                </ul>
              ))}

              <p className="addnew">
                <Link
                  className="blueBtn addfile ml-0"
                  underline="none"
                  onClick={() => {
                    setFolderData((folderData) => [
                      ...folderData,
                      {
                        folder_title: "Folder 4",
                        folder_value: "...",
                      },
                    ]);
                  }}
                >
                  Add New Folder
                </Link>
              </p>
            </div>
          </div>
        </Grid>
        <Grid xs={10}>
          <div className="scrollsection">
            <Grid container spacing={2} className="claimAssistant">
              <CreateTask
                attrName={setAddTask}
                open_status={addTask}
                value_update={updateMasterState}
              />

              {/* Initiate claim popup */}

              <Grid xs={8}>
                <div style={{ display: "flex", justifyContent: "flexStart" }}>
                  {/* <h3 className="mb-0 mt-1">Task Management</h3> */}
                  <div className="productTabs ml-5">
                    <Button
                      className={
                        viewTabsStatus === "list"
                          ? "defaultTab active"
                          : "defaultTab"
                      }
                      onClick={() => {
                        updateMasterState(setViewTabsStatus, "list");
                      }}
                    >
                      List View
                    </Button>
                    <Button
                      className={
                        viewTabsStatus === "box"
                          ? "defaultTab active"
                          : "defaultTab"
                      }
                      onClick={() => {
                        updateMasterState(setViewTabsStatus, "box");
                      }}
                    >
                      KANBAN View
                    </Button>
                  </div>
                </div>
              </Grid>
              <Grid xs={4} className="text-right">
                {/* <Link  underline="none" className="blueBtn docs">
          Import Tasks
        </Link> */}
                <Link
                  underline="none"
                  className="greenBtn addfile"
                  onClick={() => setAddTask(true)}
                >
                  Create Task
                </Link>
              </Grid>
              {viewTabsStatus === "list" ? (
                <Grid xs={12} className="pt-0">
                  <Grid container spacing={3}>
                    {data.map((item, index) => (
                      <Grid xs={12}>
                        <div className="task_list mb-3">
                          <ul className="heading">
                            <li>
                              <p
                                className={
                                  "started_heading " + item.title_class
                                }
                              >
                                {item.title}
                              </p>
                            </li>
                            <li>
                              Status
                              <button
                                className="sorting-btn"
                                onClick={() => {
                                  sortByStatus(index);
                                }}
                              >
                                <img src="./images/sorting_btn.svg" alt="" />
                              </button>
                            </li>

                            <li>
                              Due Date
                              <button
                                className="sorting-btn"
                                onClick={() => {
                                  dueDate(index);
                                }}
                              >
                                <img src="./images/sorting_btn.svg" alt="" />
                              </button>
                            </li>

                            <li>
                              Priority
                              <button
                                className="sorting-btn"
                                onClick={() => {
                                  sortByPriority(index);
                                }}
                              >
                                <img src="./images/sorting_btn.svg" alt="" />
                              </button>
                            </li>

                            <li>
                              Linked To
                              <button
                                className="sorting-btn"
                                onClick={() => {
                                  linkedTo(index);
                                }}
                              >
                                <img src="./images/sorting_btn.svg" alt="" />
                              </button>
                            </li>

                            <li>
                              Assignee
                              <button
                                className="sorting-btn"
                                onClick={() => {
                                  assignee(index);
                                }}
                              >
                                <img src="./images/sorting_btn.svg" alt="" />
                              </button>
                            </li>

                            <li>
                              Creation Date
                              <button
                                className="sorting-btn"
                                onClick={() => {
                                  creationDate(index);
                                }}
                              >
                                <img src="./images/sorting_btn.svg" alt="" />
                              </button>
                            </li>

                            <li>
                              Creation Time
                              <button
                                className="sorting-btn"
                                onClick={() => {
                                  creationTime(index);
                                }}
                              >
                                <img src="./images/sorting_btn.svg" alt="" />
                              </button>
                            </li>
                          </ul>
                          <ul className="sub_heading">
                            {item.data.map((data, sub_index) => (
                              <ul className="sub_sub_heading">
                                <li
                                // onClick={() => {
                                //   navigate("/TaskDetail");
                                // }}
                                >
                                  <button
                                    className="table_right_arrow"
                                    onClick={() => {
                                      changeSubDataStatus(index, sub_index);
                                    }}
                                  >
                                    <img
                                      className={
                                        data.open_status === true
                                          ? "rotate_icon"
                                          : ""
                                      }
                                      src="./images/table_right_arrow.svg"
                                      alt=""
                                    />
                                  </button>
                                  <span
                                    onClick={() => {
                                      navigate(ALL_ROUTES.TASK_DETAIL);
                                    }}
                                  >
                                    {data.title}
                                  </span>
                                </li>
                                <li id={data.status}>
                                  <div className={"status_dropdown"}>
                                    <SelectDropdown
                                      class_name="inputFieldd"
                                      title=""
                                      value={data.status}
                                      attrName={["data", index, sub_index]}
                                      value_update={updateMasterState}
                                      dropdown_data={statusData}
                                      warn_status={false}
                                    />
                                  </div>
                                </li>
                                <li>{data.due_date}</li>
                                <li>
                                  <span
                                    className={
                                      "priority " + data.priority_class
                                    }
                                  >
                                    {data.priority}
                                  </span>
                                </li>
                                <li>{data.linked_to}</li>
                                <li>
                                  <Tooltip title="Udit">
                                    <span className="asignee_tag orgBg">U</span>
                                  </Tooltip>

                                  <Tooltip title="Rahul">
                                    <span className="asignee_tag violetBg">
                                      R
                                    </span>
                                  </Tooltip>
                                </li>
                                <li>{data.creation_date}</li>
                                <li>{data.creation_time}</li>
                                {/* Sub Data Start */}
                                {data.open_status === true ? (
                                  <>
                                    {data.sub_data.map((sub_data, iindex) => (
                                      <ul className="sub_category">
                                        <li>
                                          <button className="table_right_arrow">
                                            <img
                                              src="./images/table_right_arrow.svg"
                                              alt=""
                                            />
                                          </button>
                                          {sub_data.title}
                                        </li>
                                        <li id={sub_data.status}>
                                          <div className={" status_dropdown"}>
                                            <SelectDropdown
                                              class_name="inputFieldd"
                                              title=""
                                              value={sub_data.status}
                                              attrName={[
                                                "sub_data",
                                                index,
                                                sub_index,
                                                iindex,
                                              ]}
                                              value_update={updateMasterState}
                                              dropdown_data={statusData}
                                              warn_status={false}
                                            />
                                          </div>
                                        </li>
                                        <li>{sub_data.due_date}</li>
                                        <li>
                                          <span
                                            className={
                                              "priority " +
                                              sub_data.priority_class
                                            }
                                          >
                                            {sub_data.priority}
                                          </span>
                                        </li>
                                        <li>{sub_data.linked_to}</li>
                                        <li>
                                          <Tooltip title="Udit">
                                            <span className="asignee_tag orgBg">
                                              U
                                            </span>
                                          </Tooltip>

                                          <Tooltip title="Rahul">
                                            <span className="asignee_tag violetBg">
                                              R
                                            </span>
                                          </Tooltip>
                                        </li>
                                        <li>{sub_data.creation_date}</li>
                                        <li>{sub_data.creation_time}</li>
                                      </ul>
                                    ))}
                                  </>
                                ) : null}
                                {/* Sub Data End */}
                              </ul>
                            ))}
                          </ul>
                        </div>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              ) : null}
              {viewTabsStatus === "box" ? (
                <Grid xs={12}>
                  <Grid container spacing={3}>
                    <Grid xs={3}>
                      <div className="common_header green">
                        <h6>Started (5)</h6>
                      </div>

                      {boxGroupData.map((item, index) => (
                        <div
                          className={"common_box  mb-3 " + item.box_left_border}
                        >
                          <Grid container spacing={2}>
                            <Grid xs={6}>
                              <p className="duedate">{item.date}</p>
                            </Grid>
                            <Grid xs={6} style={{ textAlign: "right" }}>
                              {/* <a href="" className="module_name">{item.module}</a> */}
                              <Link
                                className="module_name"
                                underline="none"
                                onClick={() => {}}
                              >
                                {item.module}
                              </Link>
                            </Grid>

                            {/* <Grid xs={6} >
                        <SelectDropdown
                            class_name="inputField"
                            title=""
                            value={workStatus}
                            attrName={setWorkStatus}
                            value_update={updateMasterState}
                            dropdown_data={workStatus_data}
                            warn_status={false}
                        />
                      </Grid>
                      <Grid xs={6} alignSelf="center">
                        <p className="duedate">{item.date}</p>
                      </Grid> */}
                            <Grid xs={12}>
                              <div className="desc">
                                <p>{item.description}</p>
                              </div>
                            </Grid>
                            <Grid xs={6}>
                              <span
                                className={
                                  "prioritys " + item.priority_status_box_color
                                }
                              >
                                {item.priority_status}
                              </span>
                            </Grid>
                            <Grid xs={6} style={{ textAlign: "right" }}>
                              <Tooltip title="Udit">
                                <span className="asignee_tag orgBg">U</span>
                              </Tooltip>
                              <Tooltip title="Rahul">
                                <span className="asignee_tag violetBg">R</span>
                              </Tooltip>
                            </Grid>
                          </Grid>
                        </div>
                      ))}
                    </Grid>
                    <Grid xs={3}>
                      <div className="common_header orange">
                        <h6>Sent to Insurer (5)</h6>
                      </div>

                      {endorsementGroupData.map((item, index) => (
                        <div
                          className={"common_box  mb-3 " + item.box_left_border}
                        >
                          <Grid container spacing={2}>
                            <Grid xs={6}>
                              <p className="duedate">{item.date}</p>
                            </Grid>
                            <Grid xs={6} style={{ textAlign: "right" }}>
                              {/* <a href="" className="module_name">{item.module}</a> */}
                              <Link
                                className="module_name"
                                underline="none"
                                onClick={() => {}}
                              >
                                {item.module}
                              </Link>
                            </Grid>

                            {/* <Grid xs={6} >
                        <SelectDropdown
                            class_name="inputField"
                            title=""
                            value={workStatus}
                            attrName={setWorkStatus}
                            value_update={updateMasterState}
                            dropdown_data={workStatus_data}
                            warn_status={false}
                        />
                      </Grid>
                      <Grid xs={6} alignSelf="center">
                        <p className="duedate">{item.date}</p>
                      </Grid> */}
                            <Grid xs={12}>
                              <div className="desc">
                                <p>{item.description}</p>
                              </div>
                            </Grid>
                            <Grid xs={6}>
                              <span
                                className={
                                  "prioritys " + item.priority_status_box_color
                                }
                              >
                                {item.priority_status}
                              </span>
                            </Grid>
                            <Grid xs={6} style={{ textAlign: "right" }}>
                              <Tooltip title="Udit">
                                <span className="asignee_tag orgBg">U</span>
                              </Tooltip>
                              <Tooltip title="Rahul">
                                <span className="asignee_tag violetBg">R</span>
                              </Tooltip>
                            </Grid>
                          </Grid>
                        </div>
                      ))}
                    </Grid>
                    <Grid xs={3}>
                      <div className="common_header darkblue">
                        <h6>In Queue (5)</h6>
                      </div>
                      {claimsGroupData.map((item, index) => (
                        <div
                          className={"common_box mb-3 " + item.box_left_border}
                        >
                          <Grid container spacing={2}>
                            <Grid xs={6}>
                              <p className="duedate">{item.date}</p>
                            </Grid>
                            <Grid xs={6} style={{ textAlign: "right" }}>
                              {/* <a href="" className="module_name">{item.module}</a> */}
                              <Link
                                className="module_name"
                                underline="none"
                                onClick={() => {}}
                              >
                                {item.module}
                              </Link>
                            </Grid>

                            {/* <Grid xs={6} >
                        <SelectDropdown
                            class_name="inputField"
                            title=""
                            value={workStatus}
                            attrName={setWorkStatus}
                            value_update={updateMasterState}
                            dropdown_data={workStatus_data}
                            warn_status={false}
                        />
                      </Grid>
                      <Grid xs={6} alignSelf="center">
                        <p className="duedate">{item.date}</p>
                      </Grid> */}
                            <Grid xs={12}>
                              <div className="desc">
                                <p>{item.description}</p>
                              </div>
                            </Grid>
                            <Grid xs={6}>
                              <span
                                className={
                                  "prioritys " + item.priority_status_box_color
                                }
                              >
                                {item.priority_status}
                              </span>
                            </Grid>
                            <Grid xs={6} style={{ textAlign: "right" }}>
                              <Tooltip title="Udit">
                                <span className="asignee_tag orgBg">U</span>
                              </Tooltip>
                              <Tooltip title="Rahul">
                                <span className="asignee_tag violetBg">R</span>
                              </Tooltip>
                            </Grid>
                          </Grid>
                        </div>
                      ))}
                    </Grid>
                    <Grid xs={3}>
                      <div className="common_header lightblue">
                        <h6>Rejected (5)</h6>
                      </div>
                      {clientsGroupData.map((item, index) => (
                        <div
                          className={"common_box mb-3 " + item.box_left_border}
                        >
                          <Grid container spacing={2}>
                            <Grid xs={6}>
                              <p className="duedate">{item.date}</p>
                            </Grid>
                            <Grid xs={6} style={{ textAlign: "right" }}>
                              {/* <a href="" className="module_name">{item.module}</a> */}
                              <Link
                                className="module_name"
                                underline="none"
                                onClick={() => {}}
                              >
                                {item.module}
                              </Link>
                            </Grid>

                            {/* <Grid xs={6} >
                        <SelectDropdown
                            class_name="inputField"
                            title=""
                            value={workStatus}
                            attrName={setWorkStatus}
                            value_update={updateMasterState}
                            dropdown_data={workStatus_data}
                            warn_status={false}
                        />
                      </Grid>
                      <Grid xs={6} alignSelf="center">
                        <p className="duedate">{item.date}</p>
                      </Grid> */}
                            <Grid xs={12}>
                              <div className="desc">
                                <p>{item.description}</p>
                              </div>
                            </Grid>
                            <Grid xs={6}>
                              <span
                                className={
                                  "prioritys " + item.priority_status_box_color
                                }
                              >
                                {item.priority_status}
                              </span>
                            </Grid>
                            <Grid xs={6} style={{ textAlign: "right" }}>
                              <Tooltip title="Udit">
                                <span className="asignee_tag orgBg">U</span>
                              </Tooltip>
                              <Tooltip title="Rahul">
                                <span className="asignee_tag violetBg">R</span>
                              </Tooltip>
                            </Grid>
                          </Grid>
                        </div>
                      ))}
                    </Grid>
                  </Grid>
                  {/* <div>
            <div className="outer_box">
              <div className="mr-6">
                
              </div>
              <div className="mr-6">
                
              </div>
              <div className="mr-6">
                
                
              </div>
              <div className="mr-6">
                
                
              </div>
            </div>
          </div> */}
                </Grid>
              ) : null}
            </Grid>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TaskManagement;
