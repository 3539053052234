import { Box, Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { GridCloseIcon } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import SlidingPanel from "react-sliding-side-panel";
import { URL_CONSTANTS } from "../../../../../../APIVar/URLConstants";
import { AddKycDTO } from "../../../../../../Services/DTO/ProfileDTO";
import { PROFILE_SERVICE } from "../../../../../../Services/Employee/ProfileService";
import { RootState } from "../../../../../../Store/Store";
import { useAppSelector } from "../../../../../../Store/hooks";
import DatePicker from "../../../../../../Supporting files/DatePicker/DatePicker";
import { calculateAgeFromDob } from "../../../../../../Supporting files/HelpingFunction";
import RKTextField from "../../../../../../Supporting files/RKTextField/RKTextField";
import SelectDropdown from "../../../../../../Supporting files/SelectDropdown/SelectDropdown";
import { EMPLOYEE_POLICY_SERVICES } from "../../../../../../Services/Employee/EmployeePolicyService";

interface Props {
  open_status: boolean;
  attrName: any;
  value_update: Function;
  data: any;
  get_profile?: Function;
  addedMemberData?: any[];
}

const EditFamily: React.FC<Props> = ({
  open_status,
  attrName,
  value_update,
  data,
  get_profile,
  addedMemberData,
}) => {
  const [family_detail, setFamily_detail] = useState<AddKycDTO>({
    id: data?._id,
    fullName: data?.fullName,
    relationShip: data?.relationWithEmployee,
    dob: data?.dob,
    warnFullName: false,
    warnRelationShip: false,
    warnDob: false,
  });
  const usertypeinfo = useAppSelector(
    (state: RootState) => state.userDetailsSlice.userType
  );
  const userId = useAppSelector(
    (state: RootState) => state.userDetailsSlice.id
  );
  const [disableSave,setDisableSave] = useState(false)
  const [relationEmployees_data, SetrelationEmployees_data] = useState<any[]>(
    []
  );
  const relationEmployees_data1 = [
    { key: "Husband", value: "Husband" },
    { key: "Wife", value: "Wife" },
    { key: "Daughter", value: "Daughter" },
    { key: "Son", value: "Son" },
    { key: "Father", value: "Father" },
    { key: "Mother", value: "Mother" },
    { key: "Father-In-Law", value: "Father-In-Law" },
    { key: "Mother-In-Law", value: "Mother-In-Law" },
  ];
  useEffect(() => {
    if (addedMemberData) {
      const filteredData = relationEmployees_data1.filter((listData) => {
        if (listData.key === "Son" || listData.key === "Daughter") {
          return true;
        } else {
          return !addedMemberData.some(
            (memberData) =>
              memberData.relationWithEmployee === listData.key ||
              memberData.relationShip === listData.key
          );
        }
      });
      SetrelationEmployees_data(filteredData);
    }
  }, [open_status]);

  useEffect(() => {
    setFamily_detail({
      id: data?._id,
      fullName: data?.fullName,
      relationShip: data?.relationWithEmployee,
      dob: data?.dob,
    });
  }, [open_status, data]);

  const updateMasterState = (attrName: any, value: any) => {
    let key = attrName[1];
    let mainState = attrName[0];
    if (mainState === "family_detail") {
      setFamily_detail({
        ...family_detail,
        [key]: value,
        [`warn${key.charAt(0).toUpperCase()}${key.slice(1)}`]: false,
      });
    } else {
      attrName(value);
    }
  };

  const form_submit = () => {
  
    if(disableSave)  return;
    let data: any = family_detail;
    data.warnFullName = family_detail.fullName.length === 0 ? true : false;
    data.warnRelationShip =
      family_detail.relationShip.length === 0 ? true : false;
    data.warnDob = family_detail.dob === null ? true : false;

    setFamily_detail({ ...data });
    const windowURL = window.location.search;
    const params = new URLSearchParams(windowURL);

    const id = params.get("id");
    if (
      data.warnFullName === false &&
      data.warnRelationShip === false &&
      data.warnDob === false
    ) {
      let param = {
        fullName: family_detail.fullName,
        relationWithEmployee: family_detail.relationShip,
        dob: family_detail.dob,
      };
      const onSuccess = (res: any) => {
        value_update(attrName, false);
        if (get_profile) {
          get_profile();
        }
        setDisableSave(false)
      };
      const onError = (err: any) => {
        console.log("err", err);
        setDisableSave(false)
      };
      if(disableSave === false) {
        setDisableSave(true)
        PROFILE_SERVICE.update_family_detail(
          `${URL_CONSTANTS.update_family_detail(
            usertypeinfo.toLocaleLowerCase(),
            id ? id : userId,
            family_detail.id ? family_detail.id : ""
          )}`,
          param,
          onSuccess,
          onError
        );
        return;
      }
    }
  };

  return (
    <Box>
      <SlidingPanel
        type={"right"}
        isOpen={open_status}
        size={75}
        panelClassName="sliding-panel"
      >
        <div>
          <Grid container spacing={3} alignItems="center">
            <Grid xs={10}>
              <h4 className="mb-0 mt-3 text-left">Edit Family Member</h4>
            </Grid>
            <Grid xs={2} textAlign="end" className="mt-2">
              <GridCloseIcon
                onClick={() => {
                  value_update(attrName, false);
                }}
              />
            </Grid>
            <Grid xs={12}>
              <hr />
            </Grid>
          </Grid>
          <div className="scrollable_area">
            <div>
              <Grid container spacing={3}>
                <Grid xs={6}>
                  <RKTextField
                    class_name="inputField"
                    title={"Full Name"}
                    value={family_detail.fullName}
                    attrName={["family_detail", "fullName"]}
                    value_update={updateMasterState}
                    warn_status={family_detail.warnFullName}
                  />
                </Grid>
                <Grid xs={6}>
                  <SelectDropdown
                    class_name="inputField"
                    title="Relationship with employee"
                    value={family_detail.relationShip}
                    attrName={["family_detail", "relationShip"]}
                    value_update={updateMasterState}
                    dropdown_data={[
                      ...relationEmployees_data?.map((dataValue: any) => ({
                        key: dataValue.key,
                        value: dataValue.value,
                      })),
                      data?.relationWithEmployee !== "Son" &&
                        data?.relationWithEmployee !== "Daughter" && {
                          key: data?.relationWithEmployee,
                          value: data?.relationWithEmployee,
                        },
                    ]}
                    warn_status={family_detail.warnRelationShip}
                  />
                </Grid>
                <Grid xs={6}>
                  <DatePicker
                    class_name="inputField"
                    title="DOB"
                    max_date={new Date()}
                    value={family_detail.dob}
                    attrName={["family_detail", "dob"]}
                    value_update={updateMasterState}
                    error_message="Please enter Date of Birth"
                    warn_status={
                      family_detail.warnDob === undefined
                        ? false
                        : family_detail.warnDob
                    }
                  />
                </Grid>
                <Grid xs={6}>
                  <p className="health_ecard">
                    Age
                    <span>{calculateAgeFromDob(family_detail.dob)}</span>
                  </p>
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid
                  xs={12}
                  className="ctaBtn"
                  style={{ textAlign: "center" }}
                >
                  <Button
                    variant="contained"
                    className="submitBtn"
                    onClick={form_submit}
                    disabled={disableSave}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </SlidingPanel>
    </Box>
  );
};

export default EditFamily;
