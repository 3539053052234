import Employed from "@mui/icons-material/Badge";
import Active from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import Terminated from "@mui/icons-material/DirectionsRun";
import Void from "@mui/icons-material/DoDisturbOff";
import Optout from "@mui/icons-material/ExitToApp";
import Inactive from "@mui/icons-material/HighlightOff";
import {
  default as Enrolled,
  default as HowToRegIcon,
  default as Requested,
} from "@mui/icons-material/HowToReg";
import EnrollmentRequested from "@mui/icons-material/MarkChatRead";
import EnrolmentInitated from "@mui/icons-material/MobileFriendly";
import Probation from "@mui/icons-material/ModelTraining";
import NA from "@mui/icons-material/NotAccessible";
import Resigned from "@mui/icons-material/SubtitlesOff";
import { Box, Button, Link, Menu, MenuItem } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import SlidingPanel from "react-sliding-side-panel";
import { toast } from "react-toastify";
import { URL_CONSTANTS } from "../../../../../APIVar/URLConstants";
import { CLIENT_SERVICES } from "../../../../../Services/Clients/ClientService";
import { EMPLOYEE_SERVICES } from "../../../../../Services/Employee/EmployeeService";
import { E_BE_FILTER_CODE } from "../../../../../Services/Enum/E_UGTable";
import { RootState } from "../../../../../Store/Store";
import { useAppSelector } from "../../../../../Store/hooks";
import { useFormatCurrency } from "../../../../../Supporting files/CustomHooks";
import {
  capitalize,
  convertSingleBracketToCurrencyFormat,
  date_time_format,
  formatSumInsured,
  getCurrencyCodeRegex,
} from "../../../../../Supporting files/HelpingFunction";
import SelectDropdown from "../../../../../Supporting files/SelectDropdown/SelectDropdown";
import UGTable from "../../../../../Supporting files/UGTable/UGTable";
import AssignBulkPolicy from "../../../Broker/Policies/PolicyDetail/AssignBulkPolicy";

const Members = ({
  clientInfo,
  policyNumber,
}: {
  clientInfo: any;
  policyNumber: string;
}) => {
  const { formatCurrency } = useFormatCurrency();
  const windowURL = window.location.search;
  const params = new URLSearchParams(windowURL);
  const { timeZone, dateFormat, currency, countryCurrencyCode } =
    useAppSelector(
      (state: RootState) => state.userDetailsSlice.localInformation
    );
  const tz = useAppSelector(
    (state: RootState) => state.userDetailsSlice.localInformation.timeZone
  );
  const usertypeinfo = useAppSelector(
    (state) => state.userDetailsSlice.userType
  );
  const user_type_dateFormat = useAppSelector(
    (state: RootState) => state.userDetailsSlice.localInformation.dateFormat
  );
  const [sectionStatus, SetSectionStatus] = useState<string>("Riders");
  const id = params.get("id");
  const [contactList, setcontactList] = useState<any[]>([]);
  const [prioritystatusData, setprioritystatusData] = useState<any>([]);
  const [employementStatusData, setEmployementStatusData] = useState<any[]>([]);
  const Riders = useRef(null);
  const [prioritystatusEnrollmentData, setprioritystatusEnrollmentData] =
    useState<any>([]);
  const [headerData, setHeaderData] = useState<Array<{}>>([]);
  const [prioritystatusDataNominee, setprioritystatusDataNominee] = useState<
    any[]
  >([]);
  const [tableLimit, setTableLimit] = useState(25);
  const [tableSkip, setTableSkip] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [searchBarFilter, setsearchBarFilter] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [BulkAssign, setBulkAssign] = useState<boolean>(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDownloadAll = () => {
    downloadCsv(contactList);
    handleClose();
  };

  const handleDownloadSelected = () => {
    let csvData;
    if (tableLimit > contactList.length) {
      csvData = contactList;
    } else {
      csvData = contactList.slice(
        pageNumber ? pageNumber * tableLimit : 0,
        (pageNumber + 1) * tableLimit
      );
    }
    downloadCsv(csvData);
    handleClose();
  };
  const updateMasterState = (attrName: any, value: any) => {};

  const scrollToSection = (elementRef: any) => {
    elementRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    get_status_nominee();
    get_status_employement();
    get_status();
    get_status_enrollment();
  }, []);

  useEffect(() => {
    memberlistAccordingPolicy();
  }, [prioritystatusEnrollmentData]);

  const get_status = () => {
    const onSuccess = (res: any) => {
      let statusData = res.data.data.map((data: any) => ({
        label: data.value,
        value: data.label,
      }));
      setprioritystatusData(statusData);
    };

    const onError = (err: any) => {
      console.log("err", err);
    };

    EMPLOYEE_SERVICES.get_status(onSuccess, onError);
  };

  const get_status_nominee = () => {
    const onSuccess = (res: any) => {
      // let statusData = res.data.data.map((data: any) => ({
      //   label: data.value,
      //   value: data.label,
      // }));
      setprioritystatusDataNominee(res.data.data);
    };

    const onError = (err: any) => {
      console.log("err", err);
    };

    EMPLOYEE_SERVICES.get_status_nominee(onSuccess, onError);
  };

  const get_status_enrollment = () => {
    const onSuccess = (res: any) => {
      let statusData = res.data.data.map((data: any) => ({
        label: data.value,
        value: data.label,
      }));
      setprioritystatusEnrollmentData(statusData);
    };

    const onError = (err: any) => {
      console.log("err", err);
    };

    EMPLOYEE_SERVICES.get_status_enrolment(onSuccess, onError);
  };

  const get_status_employement = () => {
    const onSuccess = (res: any) => {
      let statusData = res.data.data.map((data: any) => ({
        label: data.value,
        value: data.label,
      }));
      setEmployementStatusData(statusData);
    };

    const onError = (err: any) => {
      console.log("err", err);
    };

    EMPLOYEE_SERVICES.get_status_employement(onSuccess, onError);
  };

  const memberlistAccordingPolicy = (limit?: number, skip?: number) => {
    const onSuccess = (res: any) => {
      let modified = res?.data?.data?.returningResult?.map((data: any) => {
        return {
          ...data,
          id: Date.now() + Math.floor(Math.random() * 1000),
        };
      });
      setcontactList(modified);
      setTotalRecords(res?.data?.data?.countData)

      let riderArray =
        modified[0]?.riders?.length > 0 &&
        modified[0]?.riders
          .map((rider: any) => [
            {
              field: `rider/${rider.name}`,
              headerName: `Member-Rider/${rider.name}`,
              flex: 2,
              minWidth: 200,
              check_status: true,
              renderCell: (ValueFormatterParams: any) => {
                const { row } = ValueFormatterParams;
                return formatCurrency(
                  row.member.Userledger?.find(
                    (data: any) => data.riderName === rider.name
                  )?.memberContribution ?? 0
                );
              },
            },
            {
              field: `CD-Rider/${rider.name}`,
              headerName: `CD-Rider/${rider.name}`,
              flex: 2,
              minWidth: 200,
              check_status: true,
              renderCell: (ValueFormatterParams: any) => {
                const { row } = ValueFormatterParams;
                return formatCurrency(
                  row.member.Userledger?.find(
                    (data: any) => data.riderName === rider.name
                  )?.debit ?? 0
                );
              },
            },
          ])
          .flat();
      let newarr = modified[0]?.riders?.length > 0 ? riderArray : [];
      setHeaderData([
        ...newarr,
        {
          field: "Id",
          headerName: "Member ID",
          flex: 2,
          minWidth: 200,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return row.member.Id ? row.member.Id : "---";
          },
        },
        {
          field: "First Name",
          headerName: "First Name",
          flex: 2,
          minWidth: 150,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return row.member["First Name"];
          },
        },
        {
          field: "Last Name",
          headerName: "Last Name",
          flex: 2,
          minWidth: 150,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return row.member["Last Name"];
          },
        },
        {
          field: "Userledger",
          headerName: "Member Contribution",
          flex: 2,
          minWidth: 150,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return formatCurrency(
              row?.member.Userledger?.find((value: any) => !value.riderName)
                ?.memberContribution ?? 0
            );
          },
        },
        {
          field: "deletionCredit",
          headerName: "Deletion Credit",
          flex: 2,
          minWidth: 150,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return formatCurrency(
              row.member.Userledger?.find((value: any) => !value.riderName)
                ?.credit ?? 0
            );
          },
        },
        {
          field: "edcontribution",
          headerName: "CD Contribution",
          flex: 2,
          minWidth: 150,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return formatCurrency(
              row.member.Userledger?.find((value: any) => !value.riderName)
                ?.debit ?? 0
            );
          },
        },
        {
          field: "status",
          headerName: "Member Status",
          minWidth: 200,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { member } = ValueFormatterParams.row;
            return (
              <div id={`_${member.status}`}>
                <div className="status_dropdown" id={`_${member.status}`}>
                  {member.status === 32 ? (
                    <Active id={`_${member.status.toString()}`} />
                  ) : member.status === 33 ? (
                    <Inactive id={`_${member.status.toString()}`} />
                  ) : member.status === 34 ? (
                    <Void id={`_${member.status.toString()}`} />
                  ) : null}
                  <SelectDropdown
                    class_name="inputFieldd"
                    title=""
                    value={member.status}
                    attrName={["setPriorityStatus", member]}
                    value_update={updateMasterState}
                    dropdown_data={prioritystatusData}
                    warn_status={false}
                    disabled
                  />
                </div>
              </div>
            );
          },
        },
        {
          field: "enrollment_status",
          headerName: "Enrollment Status",
          minWidth: 250,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { member } = ValueFormatterParams.row;
            return (
              <div id={`_${member.enrollment_status}`}>
                <div
                  className="status_dropdown"
                  id={`_${member.enrollment_status}`}
                >
                  {member.enrollment_status === 40 ? (
                    <EnrolmentInitated
                      id={`_${member.enrollment_status?.toString()}`}
                    />
                  ) : member.enrollment_status === 41 ? (
                    <Enrolled id={`_${member.enrollment_status?.toString()}`} />
                  ) : member.enrollment_status === 42 ? (
                    <Optout id={`_${member.enrollment_status?.toString()}`} />
                  ) : member.enrollment_status === 43 ? (
                    <NA id={`_${member.enrollment_status?.toString()}`} />
                  ) : member.enrollment_status === 60 ? (
                    <HowToRegIcon
                      id={`_${member.enrollment_status?.toString()}`}
                    />
                  ) : member.enrollment_status === 61 ? (
                    <Requested
                      id={`_${member.enrollment_status?.toString()}`}
                    />
                  ) : null}
                  <SelectDropdown
                    class_name="inputFieldd"
                    title=""
                    value={member.enrollment_status}
                    attrName={["setPriorityStatus", member]}
                    value_update={updateMasterState}
                    dropdown_data={prioritystatusEnrollmentData}
                    warn_status={false}
                    disabled
                  />
                </div>
              </div>
            );
          },
        },
        {
          field: "employement_status",
          headerName: "Employment Status",
          minWidth: 200,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { index } = ValueFormatterParams;
            const { member } = ValueFormatterParams.row;
            member.employement_status =
              member["Member Type"] === "Employee"
                ? member.employement_status
                : 39;
            return (
              <div
                id={`_${member.employement_status}`}
                style={{ borderRadius: "20px" }}
              >
                <div
                  className="status_dropdown"
                  id={`_${member.employement_status?.toString()}`}
                >
                  {member["Member Type"] !== "Employee" ? (
                    <NA id={`_${member.employement_status?.toString()}`} />
                  ) : member.employement_status === 35 ? (
                    <Resigned
                      id={`_${member.employement_status?.toString()}`}
                    />
                  ) : member.employement_status === 36 ? (
                    <Terminated
                      id={`_${member.employement_status?.toString()}`}
                    />
                  ) : member.employement_status === 37 ? (
                    <Employed
                      id={`_${member.employement_status?.toString()}`}
                    />
                  ) : member.employement_status === 38 ? (
                    <Probation
                      id={`_${member.employement_status?.toString()}`}
                    />
                  ) : member.employement_status === 39 ? (
                    <NA id={`_${member.employement_status?.toString()}`} />
                  ) : null}
                  {member["Member Type"] === "Employee" ? (
                    <SelectDropdown
                      class_name="inputFieldd"
                      title=""
                      value={member.employement_status}
                      attrName={["updatestatusEmployment", member, index]}
                      value_update={updateMasterState}
                      dropdown_data={employementStatusData}
                      warn_status={false}
                      disabled
                    />
                  ) : (
                    <span>NA</span>
                  )}
                </div>
              </div>
            );
          },
        },
        {
          field: "Gender",
          headerName: "Gender",
          flex: 2,
          minWidth: 150,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return capitalize(row.member.Gender);
          },
        },
        {
          field: "Date Of Birth",
          headerName: "Date Of Birth",
          flex: 2,
          minWidth: 200,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;

            return date_time_format(
              row.member["Date Of Birth"],
              user_type_dateFormat,
              tz
            );
          },
        },
        {
          field: "Contact Number",
          headerName: "Contact Number",
          flex: 2,
          minWidth: 200,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return row.member["Contact Number"];
          },
        },
        {
          field: "Email",
          headerName: "Email",
          flex: 2,
          minWidth: 200,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return row.member["Email"];
          },
        },
        {
          field: "Department",
          headerName: "Department",
          flex: 2,
          minWidth: 200,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return row.member["Department"];
          },
        },
        {
          field: "Designation",
          headerName: "Designation",
          flex: 2,
          minWidth: 200,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return row.member["Designation"];
          },
        },

        {
          field: "Date Of Joining",
          headerName: "Date Of Joining",
          flex: 2,
          minWidth: 200,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return row.member["Date Of Joining"]
              ? date_time_format(
                  row.member["Date Of Joining"],
                  user_type_dateFormat,
                  tz
                )
              : "--";
          },
        },
        {
          field: "Official Email",
          headerName: "Official Email",
          flex: 2,
          minWidth: 200,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return row.member["Official Email"];
          },
        },
        {
          field: "Office Contact Number",
          headerName: "Office Contact Number",
          flex: 2,
          minWidth: 200,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return row.member["Office Contact Number"];
          },
        },
        {
          field: "Salary Bracket",
          headerName: "Salary Bracket",
          flex: 2,
          minWidth: 200,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return row.member["Salary Bracket"];
          },
        },
        {
          field: "Salary",
          headerName: "Salary",
          flex: 2,
          minWidth: 200,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return row.member["Salary"]
              ? formatCurrency(row.member["Salary"])
              : "---";
          },
        },
        {
          field: "Member Type",
          headerName: "Member Type",
          flex: 2,
          minWidth: 200,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return row.member["Member Type"];
          },
        },
        {
          field: "Organization Employee ID",
          headerName: "Organization Employee ID",
          flex: 2,
          minWidth: 200,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return row.member["Organization Employee ID"];
          },
        },
        {
          field: "Organization Location",
          headerName: "Organization Location",
          flex: 2,
          minWidth: 200,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return row.member["Location"];
          },
        },
        {
          field: "Relationship With Employee",
          headerName: "Relationship With Employee",
          flex: 2,
          minWidth: 200,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return row.member["Relationship With Employee"];
          },
        },
        {
          field: "Primary Employee",
          headerName: "Primary Employee",
          flex: 2,
          minWidth: 200,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return row.member["Primary Employee"]
              ? row.member["Primary Employee"]
              : "---";
          },
        },
        {
          field: "Last Day of Working",
          headerName: "Last Day of Working",
          flex: 2,
          minWidth: 200,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return row.member["Last Day of Working"]
              ? date_time_format(
                  row.member["Last Day of Working"],
                  dateFormat,
                  timeZone
                )
              : "---";
          },
        },

        {
          field: "policyId",
          headerName: "Policy Id",
          flex: 2,
          minWidth: 200,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return row["policyId"];
          },
        },
        {
          field: "policyNumber",
          headerName: "Policy Number",
          flex: 2,
          minWidth: 200,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return row["policyNumber"];
          },
        },
        {
          field: "productType",
          headerName: "Product Type",
          flex: 2,
          minWidth: 200,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return row["productType"];
          },
        },
        {
          field: "grade",
          headerName: "Grade",
          flex: 2,
          minWidth: 200,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return row["grade"] ?? "--";
          },
        },
        {
          field: "sumInsured",
          headerName: "SI/SA",
          flex: 2,
          minWidth: 200,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return row["sumInsured"] ? formatCurrency(row["sumInsured"]) : "--";
          },
        },
        {
          field: "policyStartDate",
          headerName: "Policy StartDate",
          flex: 2,
          minWidth: 200,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return date_time_format(
              row["policyStartDate"],
              user_type_dateFormat,
              tz
            );
          },
        },
        {
          field: "policyEndDate",
          headerName: "Policy EndDate",
          flex: 2,
          minWidth: 200,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return date_time_format(
              row["policyEndDate"],
              user_type_dateFormat,
              tz
            );
          },
        },
        {
          field: "tpa",
          headerName: "Tpa",
          flex: 2,
          minWidth: 200,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return row["tpa"];
          },
        },
        {
          field: "insurer",
          headerName: "Insurer",
          flex: 2,
          minWidth: 200,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return row["insurer"];
          },
        },
        {
          field: "createdAt",
          headerName: "Created At",
          flex: 2,
          minWidth: 200,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return date_time_format(
              row.member["createdAt"],
              user_type_dateFormat,
              tz
            );
          },
        },
        {
          field: "updatedAt",
          headerName: "Updated At",
          flex: 2,
          minWidth: 200,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return date_time_format(
              row.member["updatedAt"],
              user_type_dateFormat,
              tz
            );
          },
        },
        {
          field: "fullName",
          headerName: "Nominee Name",
          flex: 2,
          minWidth: 200,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return row.member.nomineeDetail?.length > 0
              ? row.member.nomineeDetail
                  ?.map((nom: any) => nom?.fullName)
                  ?.map((item: any) => `${item}`)
                  ?.join(", ")
              : "--";
          },
        },
        {
          field: "relation",
          headerName: "Nominee Relationship With Employee",
          flex: 2,
          minWidth: 350,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return row.member.nomineeDetail?.length > 0
              ? row.member.nomineeDetail
                  ?.map((nom: any) => nom?.relation)
                  ?.map((item: any) => `${item}`)
                  ?.join(", ")
              : "--";
          },
        },
        {
          field: "dob",
          headerName: "Nominee DOB",
          flex: 2,
          minWidth: 200,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return row.member.nomineeDetail?.length > 0
              ? row.member.nomineeDetail
                  ?.map((nom: any) => nom?.dob)
                  ?.map((item: any) =>
                    date_time_format(item, dateFormat, timeZone)
                  )
                  ?.join(", ")
              : "--";
          },
        },
        {
          field: "Nominee Status",
          headerName: "Nominee Status",
          flex: 2,
          minWidth: 250,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;

            if (
              row.member.nomineeDetail?.length > 0 &&
              prioritystatusDataNominee?.length > 0
            ) {
              return (
                <div style={{ display: "flex", flexWrap: "wrap", gap: "5px" }}>
                  {row.member.nomineeDetail.map((nom: any) => {
                    const status = prioritystatusDataNominee?.find(
                      (status) => status.value === nom.enrolment_status
                    );
                    if (!status) {
                      return null;
                    }

                    return (
                      <div
                        key={nom.enrolment_status}
                        id={`_${nom.enrolment_status?.toString()}`}
                        style={{ width: "fit-content" }}
                      >
                        <div
                          className="status_dropdown"
                          id={`_${nom.enrolment_status?.toString()}`}
                        >
                          {nom.enrolment_status === 44 ? (
                            <Enrolled
                              id={`_${nom.enrolment_status?.toString()}`}
                            />
                          ) : nom.enrolment_status === 45 ? (
                            <Optout
                              id={`_${nom.enrolment_status?.toString()}`}
                            />
                          ) : (
                            (nom.enrolment_status === 63 ||
                              nom.enrolment_status === 64) && (
                              <EnrollmentRequested
                                id={`_${nom.enrolment_status?.toString()}`}
                              />
                            )
                          )}
                          <span>{status.label}</span>
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            } else {
              return "--";
            }
          },
        },
      ]);
    };
    const onError = (err: any) => {
      console.log(".....err", err);
    };
    EMPLOYEE_SERVICES.memberlistAccordingPolicy(
      onSuccess,
      onError,
      id??"",
      usertypeinfo.toLowerCase(),
      limit ? limit : 25,
      skip ? skip : 0,
    );
  };

  const downloadCsv = (csvData: any) => {
    const onSuccess = async (res: any) => {
      const data = await res;
      const blob = new Blob([data.data], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "members.csv";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    };
    const onError = (err: any) => {
      console.log("sucess", err);
    };
    const transformedData = csvData.map((item: any) => {
      const { member, ...rest } = item;
      return { ...member, ...rest };
    });

    const updatedArr = transformedData?.map((item: any) => {
      const statusObj: any =
        prioritystatusData &&
        prioritystatusData?.find((status: any) => status.label === item.status);
      const employement_status: any =
        employementStatusData &&
        employementStatusData?.find(
          (status: any) => status.label === item.employement_status
        );
      const enrollment_status: any =
        prioritystatusEnrollmentData &&
        prioritystatusEnrollmentData?.find(
          (status: any) => status.label === item.enrollment_status
        );

      const getNomineeStatus = () => {
        if (
          item?.nomineeDetail?.length > 0 &&
          prioritystatusDataNominee?.length > 0
        ) {
          return item?.nomineeDetail
            .map((nom: any) => {
              const status = prioritystatusDataNominee?.find(
                (status) => status.value === nom.enrolment_status
              );
              return status && status.label;
            })
            .join(", ");
        } else {
          return "--";
        }
      };
      let riderObject: any = {};

      if (contactList[0]?.riders?.length > 0) {
        contactList[0].riders.forEach((rider: any) => {
          riderObject[`Member-Rider/${rider.name}`] = formatCurrency(
            item.Userledger?.find((data: any) => data.riderName === rider.name)
              ?.memberContribution ?? 0
          );

          riderObject[`CD-Rider/${rider.name}`] = formatCurrency(
            item.Userledger?.find((data: any) => data.riderName === rider.name)
              ?.debit ?? 0
          );
        });
      }
      if (statusObj) {
        return {
          ...riderObject,
          "Member Contribution": formatCurrency(
            item.Userledger?.find((value: any) => !value.riderName)
              ?.memberContribution ?? 0
          ),
          "Deletion Credit": formatCurrency(
            item.Userledger?.find((value: any) => !value.riderName)?.credit ?? 0
          ),
          "CD Contribution": formatCurrency(
            item.Userledger?.find((value: any) => !value.riderName)?.debit ?? 0
          ),
          Title: item?.Title,
          "Member ID": item?.Id,
          "Member Status": statusObj.value,
          "Employment Status": employement_status.value,
          "Enrollment Status": enrollment_status.value,
          "First Name": item["First Name"],
          "Last Name": item["Last Name"],
          Gender: item?.Gender,
          "Date Of Birth":
            item["Date Of Birth"] !== "--"
              ? date_time_format(item["Date Of Birth"], dateFormat, timeZone)
              : item["Date Of Birth"],
          "Contact Number": item["Contact Number"],
          Email: item.Email,
          Department: item.Department,
          Designation: item.Designation,
          "Date Of Joining":
            item["Date Of Joining"] !== "--" && item["Date Of Joining"] !== ""
              ? date_time_format(item["Date Of Joining"], dateFormat, timeZone)
              : item["Date Of Joining"],
          "Last Day of Working":
            item["Last Day of Working"] !== "" &&
            item["Last Day of Working"] !== undefined
              ? date_time_format(
                  item["Last Day of Working"],
                  dateFormat,
                  timeZone
                )
              : "--",
          "Official Email": item["Official Email"],
          "Office Contact Number": item["Office Contact Number"],
          "SI/SA": formatSumInsured(item?.sumInsured, formatCurrency),
          "Salary Bracket":
            item["Salary Bracket"] !== "" &&
            item["Salary Bracket"] !== undefined
              ? convertSingleBracketToCurrencyFormat(
                  item["Salary Bracket"],
                  getCurrencyCodeRegex(currency),
                  countryCurrencyCode,
                  true
                ).replace(/₹/g, "Rs")
              : item["Salary Bracket"],
          Salary:
            item?.Salary !== "--" && item?.Salary !== undefined
              ? formatCurrency(item["Salary"])?.replace(/₹/g, "Rs")
              : item?.Salary,
          "Policy Id": item?.policyId,
          "Policy Number": item?.policyNumber,
          "Policy StartDate":
            item?.policyStartDate !== "--"
              ? date_time_format(item.policyStartDate, dateFormat, timeZone)
              : item.policyStartDate,
          "Policy EndDate":
            item?.policyEndDate !== "--"
              ? date_time_format(item.policyEndDate, dateFormat, timeZone)
              : item.policyEndDate,
          "Product Type": item?.productType,
          Tpa: item?.tpa,
          Insurer: item?.insurer,
          Grade: item?.grade,
          "Member Type": item["Member Type"],
          "Organization Employee ID": item["Organization Employee ID"],
          "Organization Location": item?.Location,
          "Relationship With Employee": item["Relationship With Employee"],
          "Primary Employee": item["Primary Employee"],
          Client: item.Client,
          "Nominee Name":
            item?.nomineeDetail?.length > 0
              ? item?.nomineeDetail
                  ?.map((nom: any) => nom?.fullName)
                  ?.map((item: any) => `${item}`)
                  ?.join(", ")
              : "--",
          "Nominee Relationship With Employee":
            item?.nomineeDetail?.length > 0
              ? item?.nomineeDetail
                  ?.map((nom: any) => nom?.relation)
                  ?.map((item: any) => `${item}`)
                  ?.join(", ")
              : "--",
          "Nominee DOB":
            item?.nomineeDetail?.length > 0
              ? item?.nomineeDetail
                  ?.map((nom: any) => nom?.dob)
                  ?.map(
                    (item: any) =>
                      `${date_time_format(item, dateFormat, timeZone)}`
                  )
                  ?.join(", ")
              : "--",
          "Nominee Status": getNomineeStatus(),
          "Created At": date_time_format(item?.createdAt, dateFormat, timeZone),
          "Updated At": date_time_format(item?.updatedAt, dateFormat, timeZone),
        };
      }
      // const empStatusObj: any = employementStatusData && employementStatusData?.find((status: any) => status.label === item.status);
      // if (empStatusObj) {
      //   return {
      //     ...item, employement_status: empStatusObj?.value,
      //   };
      // }

      return item;
    });

    headerData.forEach((item: any) => {
      item.field = item.headerName;
    });

    let dto = {
      headers: headerData,
      data: updatedArr,
    };

    CLIENT_SERVICES.csvDownload(
      onSuccess,
      onError,
      dto,
      usertypeinfo.toLowerCase(),
      "member"
    );
  };
  const downloadEcards = async () => {
    try {
      const response = await axios({
        url: URL_CONSTANTS.download_ecard(id ?? "", usertypeinfo.toLowerCase()), 
        method: 'GET',
        responseType: 'blob', 
        headers: {
          auth: `${sessionStorage.getItem("usertoken")}`
        }
      });
  
     // Extract filename from Content-Disposition header if available
     const contentDisposition:any = response.headers['content-disposition'];
     let fileName = `ecards.zip`; 

     if (contentDisposition) {
       const fileNameMatch = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
       if (fileNameMatch && fileNameMatch[1]) {
         fileName = fileNameMatch[1].replace(/['"]/g, ''); 
       }
     }

     // Create a URL for the Blob and initiate a download
     const url = window.URL.createObjectURL(response.data);
     const link = document.createElement('a');
     link.href = url;
 
     // Set the filename from the blob metadata or fallback if no filename was provided
     link.setAttribute('download', fileName);
      // Append link to the body and trigger a click
      document.body.appendChild(link);
      link.click();
  
      // Clean up after download
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error:any) {
      if(error.response.status === 404){
        toast.error("No E-Cards Available")
      }
      console.error('Error downloading the ZIP file:', error);
    }
  };
  
  return (
    <>
      <Grid container spacing={3}>
        <Grid
          xs={2}
          style={{
            maxWidth: "260px",
            position: "relative",
            top: "-75px",
            paddingLeft: "0",
          }}
        >
          <div
            className="leftSection h-100"
            style={{ position: "fixed", paddingLeft: "8px" }}
          >
            <Link
              className={
                sectionStatus === "Riders"
                  ? "active sectionLink Riders"
                  : "sectionLink Riders"
              }
              onClick={() => {
                scrollToSection(Riders);
                SetSectionStatus("Riders");
              }}
            >
              <span>Members</span>
            </Link>
          </div>
        </Grid>
        <Grid xs ref={Riders}>
          <Box className="voluntry_plans_outer">
            <Grid container>
              <Grid xs={12} textAlign={"right"}>
                {contactList?.length > 0 && (
                  <>
                    <Button
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      onClick={handleClick}
                      className="blueBtn download"
                      style={{ paddingLeft: 30, textTransform: "unset" }}
                    >
                      Download CSV
                    </Button>
                    <Button
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      onClick={downloadEcards}
                      className="blueBtn download"
                      style={{ paddingLeft: 30, textTransform: "unset" }}
                    >
                       E-Cards
                    </Button>
                    <Menu
                      id="simple-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                    >
                      <MenuItem onClick={handleDownloadAll}>All Items</MenuItem>
                      <MenuItem onClick={handleDownloadSelected}>
                        Selected Items
                      </MenuItem>
                    </Menu>
                  </>
                )}
                <Link
                  underline="none"
                  className="blueBtn addfile"
                  onClick={() => {
                    setBulkAssign(true);
                  }}
                >
                  Bulk Policy Assign
                </Link>
              </Grid>

              <Grid xs={12} marginTop={"12px"}>
                <UGTable
                  header_data={headerData}
                  data={contactList}
                  totalRecords={totalRecords}
                  value_update={updateMasterState}
                  attrName={"UGTable"}
                  BE_filter_code={E_BE_FILTER_CODE.EMPLOYEE}
                  refetch_data={memberlistAccordingPolicy}
                  setTableLimit={setTableLimit}
                  setTableSkip={setTableSkip}
                  setPageNumberData={setPageNumber}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      <Box className="sliding-90">
        <SlidingPanel
          type={"right"}
          isOpen={BulkAssign}
          size={90}
          panelClassName="sliding-panel"
        >
          <>
            <Grid container spacing={3} alignItems="center" className="mb-2">
              <Grid xs={8}>
                <h4 className="mb-0 mt-3 text-left">Assign Policy</h4>
              </Grid>
              <Grid xs={4} className="pt-3 text-right">
                <CloseIcon
                  onClick={() => {
                    setBulkAssign(false);
                  }}
                />
              </Grid>
              <Grid xs={12} className="mb-1">
                <hr />
              </Grid>
            </Grid>
            <div className="scrollableArea">
              <Grid container spacing={3} alignItems="center" className="mb-2">
                <Grid xs={12}>
                  <AssignBulkPolicy
                    clientId={clientInfo?.id}
                    id={id ?? ""}
                    policyNumber={policyNumber}
                    Client={`${clientInfo.clientId} - ${clientInfo.organisationName}`}
                  />
                </Grid>
              </Grid>
            </div>
          </>
        </SlidingPanel>
      </Box>
    </>
  );
};

export default Members;
