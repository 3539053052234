import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import RKTextField from "../RKTextField/RKTextField";

const ARMultiInputField = ({
  class_name,
  title = "",
  value = [],
  attrName = "",
  value_update = () => { },
  warn_status = false,
  error_messg = "Enter valid value",
  disabled,
  required,
  stopEdit,
}: {
  class_name: string;
  title: string;
  value: Array<string>;
  attrName: any;
  value_update: any;
  warn_status: boolean | undefined;
  error_messg?: string;
  required?: boolean;
  disabled?: boolean;
  stopEdit?:boolean
}) => {
  const [inputValue, setInputValue] = useState("");
  const [values, setValues] = useState<any>([]);
  const [borderHighlight, setborderHighlight] = useState<boolean>(false);
  const handleInputChange = (attrName: any, value: any) => {
    if (attrName) {
      setInputValue(value);
    }
  };

  const handleInputKeyDown = (event: any) => {
    if (event.key === "Enter") {
      if (inputValue?.trim() === "") return
      let data = [...values, { label: inputValue,new:true }]
      setValues([...values, { label: inputValue, new:true }]);
      setInputValue("");
      value_update(attrName, data)
    }
  };

  const handleDelete = (index: any) => {
    let data = values?.filter((value: any, i: any) => i !== index)
    setValues(data);
    value_update(attrName, data);
  };

  useEffect(() => {
    setValues(value)
  }, [])

  return (
    <div >
      <RKTextField
        class_name={class_name}
        title={title}
        value={inputValue}
        attrName={attrName}
        value_update={handleInputChange}
        warn_status={warn_status}
        error_messg={error_messg}
        required={required}
        onKeyDown={handleInputKeyDown}
        onBlur={() => {
          setborderHighlight(!borderHighlight)
        }}
      />
      {values?.length > 0 && (
         <div className="p-3 mt-3 structure_box" style={{ border: borderHighlight ? '1px solid #e5e7fe' : "1px solid #e5e7fe", borderRadius: '20px' }}>
          {values && values?.map((value: any, index: any) => (
            <Chip
              key={index}
              label={value?.label}
              onDelete={() => handleDelete(index)}
              style={{ margin: "4px" }}
              disabled={value.new ? false :stopEdit}
            />
          ))}
        </div>
      )}

    </div>
  );
};

export default ARMultiInputField;
