/* eslint-disable no-loop-func */
import { InputAdornment } from "@material-ui/core";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import EmailIcon from "@mui/icons-material/Email";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  FormControlLabel,
  Link,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { differenceInDays } from "date-fns";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import PolicyHistory from "../../../../../Components/Broker/PolicyHistory/PolicyHistory";
import SelectvoluntryPlans from "../../../../../Components/Broker/SelectVoluntryPlans/SelectVoluntryPlans";
import PoliciesWarningpopup from "../../../../../Components/Broker/WarningForPlicies/PoliciesWarningpopup";
import NewLoader from "../../../../../Components/NewLoader/NewLoader";
import { ALL_ROUTES } from "../../../../../Routes/all_routes";
import {
  coverageType,
  policiesDTONew,
} from "../../../../../Services/DTO/PoliciesDTO";
import {
  one_to_ten_data,
  one_to_thirty_days_data,
} from "../../../../../Services/DropDowndata";
import { EMPLOYEE_SERVICES } from "../../../../../Services/Employee/EmployeeService";
import { PolicyCoverageType } from "../../../../../Services/Enum/EPolicy";
import { premiumType } from "../../../../../Services/Enum/EPremiumPaymentType";
import { SumInsuredLimit } from "../../../../../Services/Enum/ESumInsuredLimits";
import { POLICIES_SERVICES } from "../../../../../Services/Policies/PoliciesServices";
import { TUserData } from "../../../../../Services/Types/TUserData";
import { RootState } from "../../../../../Store/Store";
import { useAppSelector } from "../../../../../Store/hooks";
import { useFormatCurrency } from "../../../../../Supporting files/CustomHooks";
import DatePicker from "../../../../../Supporting files/DatePicker/DatePicker";
import {
  add_days_in_date_getDateObject,
  areAgeBandsConsistent,
  calculateTotalTax,
  containsLeapYearFebruary,
  extractNumbersFromString,
  formatCurrencyAccToUser,
  getCurrencyCodeRegex,
  subtract_days_in_date_getDateObject,
  titleCase
} from "../../../../../Supporting files/HelpingFunction";
import MultipleSearchDropdown from "../../../../../Supporting files/MultipleSearchDropdown/MultipleSearchDropdown";
import RKTextField from "../../../../../Supporting files/RKTextField/RKTextField";
import SearchDropdown from "../../../../../Supporting files/SearchDropdown/SearchDropdown";
import SelectDropdown from "../../../../../Supporting files/SelectDropdown/SelectDropdown";
import TextAreaField from "../../../../../Supporting files/TextAreaField/TextAreaField";
import Exclusion from "../../Quotes/Exclusion/Exclusion";
import SelectFeature from "../../Quotes/SelectFeature/SelectFeature";
// import '../../../../../Supporting files/UGTable/Supporting.scss';

function EditPolicy() {
  const [disableSave, setDisableSave] = useState<boolean>(false);
  const { formatCurrency } = useFormatCurrency();
  const [editData, seteditData] = useState<any>();
  const userState: TUserData = useAppSelector(
    (state: RootState) => state.userDetailsSlice
  );
  const windowURL = window.location.search;
  const params = new URLSearchParams(windowURL);
  const id = params.get("id");
  const navigate = useNavigate();
  const [others, setOthers] = useState("");
  const taxPercentage = 0.18;
  const [ageBandData, setAgeBandData] = useState<
    Array<{ min_age: string; max_age: string }>
  >([{ min_age: "", max_age: "" }]);
  const [sectionStatus, SetSectionStatus] = useState<any>(
    "organizationDetails"
  );
  const [livesSelectedGrade, setlivesSelectedGrade] = useState<
    Array<{ selectedGrade: number }>
  >([{ selectedGrade: 0 }]);
  const [warningpopup, setwarningpopup] = useState<boolean>(false);
  const [planType, setPlanType] = useState(0);
  const [policyType, setPolicyType] = useState(0);
  const [policyStatus, setpolicyStatus] = useState<number | null>(null);
  const [enrolmentStatus, setenrolmentStatus] = useState<number | null>(null);
  const [showNominee, setShowNominee] = useState(false);
  const [showhealth, setShowhealth] = useState(false);
  const [showcorporate, setShowcorporate] = useState(false);
  const [isPolicyAttachedToMember, setIsPolicyAttachedToMember] =
    useState<boolean>(true);
  const [
    isPolicyAttachedToMemberoriginal,
    setIsPolicyAttachedToMemberoriginal,
  ] = useState<boolean>(true);
  const [addFeature, setAddFeature] = useState(false);
  const [selectvoluntryPlans, setSelectVoluntryPlans] = useState(false);
  const [Addexclusion, setAddExclusion] = useState(false);
  const [AddFeatureexclusionIndex, setAddFeatureExclusionIndex] =
    useState<number>(0);
  const [taxDropDown, settaxDropDown] = useState<any>([]);
  const [clientListdata, setClientListdata] = useState<
    Array<{ label: string; id: string }>
  >([]);
  const [organizationFullDetails, setorganizationFullDetails] = useState<any>(
    {}
  );
  const [empGradeData, setEmpGradeData] = useState<
    Array<{
      sum_insured: string;
      emp_count: string;
      deleteable: boolean;
      features: Array<string>;
    }>
  >([{ sum_insured: "", emp_count: "", deleteable: false, features: [] }]);
  const [clientLocationList, setClientLocationList] = useState<any>({});
  const [insurerList, setInsurerList] = useState<any>([]);
  const [tpaList, setTpaList] = useState<any>([]);
  const [selectedTpa, setSelectedTpa] = useState<any>();
  const [insurerAddressList, setInsurerAddressList] = useState<any>([]);
  const [featuresListdata, setfeaturesListdata] = useState<any>([]);
  const [exclusionsListdata, setexclusionsListdata] = useState<any>([]);
  const [featuresListdataGraded, setfeaturesListdataGraded] = useState<any>([]);
  const [featuresListdataGradedCopy, setfeaturesListdataGradedCopy] =
    useState<any>([]);
  const [selectedClientId, setSelectedClientId] = useState<any>("");
  const [dropdownSalarydesignationDep, setdropdownSalarydesignationDep] =
    useState<any>([]);
  const [
    showdropdownSalarydesignationDep,
    setshowdropdownSalarydesignationDep,
  ] = useState<any>([]);
  const [exclusionsListdataGraded, setexclusionsListdataGraded] = useState<any>(
    []
  );
  const [indivisualGradeLives, setindivisualGradeLives] = useState(0);
  const [liveGraded, setliveGraded] = useState(0);
  const [exclusionsListdataGradedCopy, setexclusionsListdataGradedCopy] =
    useState<any>([]);
  const [basicConfig_data, setbasicConfig_data] = useState<any>([
    { key: "1E", value: "1E" },
  ]);
  const [indvConfig_data, setindvConfig_data] = useState<any>([
    { key: "Nill", value: "Nill" },
  ]);
  const [modifiedArrayAgeBand, setModifiedArrayAgeBand] = useState<any>([]);
  const [ageBandMax, setageBandMax] = useState<string>("");
  const [minage_data, setminage_data] = useState<any>([]);
  const [maxage_data, setmaxage_data] = useState<any>([]);
  const [maxage_dataClone, setmaxage_dataClone] = useState<any>([]);
  const [addPoliciesdata, setAddPoliciesdata] = useState<policiesDTONew>({
    ageBand: [{ max: "", min: "", name: "" }],
    maxChildAge: "",
    organisationDetails: {
      clientId: "",
      id: "",
      industrySub: "",
      organisationEmail: "",
      location: [
        {
          id: "",
          name: "",
        },
      ],
      mobile: "",
      organisationName: "",
      ownership: "",
    },
    benefitsDetailsflat: {
      sumInsured: "",
      deductible: "",
      features: [{ featureId: "", groupId: "", name: "", value: "" }],
    },
    benefitsDetailsGrade: [
      {
        features: [{ featureId: "", groupId: "", name: "", value: "" }],
        name: "",
        sumInsured: "",
        deductible: "",
      },
    ],
    insurerDetails: { id: "", name: "", office: "" },
    tpa: { id: "", name: "" },
    payType: { type: premiumType.SPLIT, data: [{ name: "", value: 0 }] },
    planType: {
      basicConfig: "",
      coverageType: [
        {
          code: 0,
          name: "",
        },
      ],
      familyDefinition: "",
      gradeCriteria: "",
      name: PolicyCoverageType.FLAT,
      parentConfig: "",
      setOfParents: "both",
    },
    is_rider: false,
    rider: [
      {
        name: "",
        description: "",
      },
    ],
    benefits_detail_graded: [
      {
        grade_name: "Grade 1",
        riders: [
          {
            label: "",
            enable: false,
            amount: "",
            description: "",
          },
        ],
      },
    ],
    rider_premium_graded: [
      {
        proreta_by: "YEARLY",
        rider_name: "",
        premium_split: "",
        livesInfoGrade: [
          {
            gradeName: "Grade 1",
            data: [
              {
                min: "",
                max: "",
                coverageTypeData: [
                  {
                    name: "amount",
                    value: "",
                  },
                  {
                    code: "0",
                    name: "EMPLOYEE",
                    value: "",
                  },
                  {
                    name: "total Live",
                    value: 0,
                  },
                ],
                name: "age band",
              },
            ],
          },
        ],
      },
    ],
    benefits_detail_flat: [
      {
        label: "",
        enable: false,
        amount: "",
        description: "",
      },
    ],
    rider_premium_flat: [
      {
        proreta_by: "YEARLY",
        premium_split: "",
        rider_name: "",
        livesInfoFlat: [
          {
            min: "",
            max: "",
            name: "",
            coverageTypeData: [
              {
                code: "",
                name: "",
                value: 0,
              },
            ],
          },
        ],
      },
    ],
    policy: {
      cardType: "",
      criticalCd: 0,
      criticalPercentage: 0,
      enrolmentEndDate: null,
      enrolmentStartDate: null,
      gracePeriod: "",
      healthCard: false,
      healthCardIssued: "",
      corporateBuffer: null,
      isCorporateBuffer: false,
      maxMemberPerFamily: 0,
      maxNomination: "",
      newMemberPeriod: "",
      nomination: false,
      period: "",
      totalMemberPerFamily: 0,
      proreta_by: "",
    },
    policyCase: { code: 0, name: "", policyNumber: "" },
    policyDetails: {
      cdBalance: "",
      endDate: "",
      startDate: "",
    },
    premiumDetails: {
      grossPremium: "",
      netPremium: "",
      taxAmount: "",
      taxPercentage: [{ id: "", label: "" }],
    },
    productType: {
      code: "",
      name: "",
    },
  });
  const [expanded, setExpanded] = useState<Array<{ expanded: boolean }>>([]);
  const [premiumPaymentType, setPremiumPaymentType] = useState<premiumType>(
    premiumType.SPLIT
  );
  const [errorMaxChildAge, seterrorMaxChildAge] = useState(false);

  const [addPoliciesdataWarn, setAddPoliciesdataWarn] = useState<any>({
    employeeId: false,
    organisationDetails: {
      clientId: false,
      parentClientId: false,
      parentOrganistion: false,
      organisationName: false,
      ownership: false,
      industrySub: false,
      mobile: false,
      location: false,
    },

    productType: {
      name: false,
      code: false,
    },
    rider: [
      {
        name: false,
        description: false,
      },
    ],
    benefits_detail_flat: [
      {
        label: false,
        enable: false,
        amount: false,
        description: false,
      },
    ],
    rider_premium_flat: [
      {
        proreta_by: false,
        premium_split: false,
        rider_name: false,
        livesInfoFlat: [
          {
            min: false,
            max: false,
            name: false,
            coverageTypeData: [
              {
                code: false,
                name: false,
                value: false,
              },
            ],
          },
        ],
      },
    ],
    policyCase: {
      name: false,
      code: false,
      policyNumber: false,
    },
    insurerDetails: {
      name: false,
      office: false,
    },
    tpa: { name: false },
    planType: {
      name: false,
      coverageType: [
        {
          name: false,
          code: false,
        },
      ],
      basicConfig: false,
      parentConfig: false,
      familyDefinition: false,
    },
    ageBand: [
      {
        name: false,
        max: false,
        min: false,
      },
    ],
    maxChildAge: false,
    benefitsDetailsGrade: [
      {
        designation: false,
        deductible: false,
        name: false,
        sumInsured: false,
        features: [
          {
            name: false,
            value: false,
            featureId: false,
            groupId: false,
          },
        ],
        exclusion: [
          {
            name: false,
            description: false,
            exclusionId: false,
          },
        ],
      },
    ],
    benefitsDetailsflat: {
      deductible: false,
      sumInsured: true,
      features: false,
      exclusion: [
        {
          name: false,
          description: false,
        },
      ],
    },
    premiumDetails: {
      netPremium: false,
      taxPercentage: false,
      grossPremium: false,
    },
    policyDetails: {
      startDate: false,
      endDate: false,
      cdBalance: false,
    },
    policy: {
      criticalCd: false,
      criticalPercentage: false,
      enrolmentStartDate: false,
      enrolmentEndDate: false,
      period: false,
      gracePeriod: false,
      maxMemberPerFamily: false,
      totalMemberPerFamily: false,
      newMemberPeriod: false,
      nomination: false,
      maxNomination: false,
      corporateBuffer: false,
      isCorporateBuffer: false,
      healthCard: false,
      cardType: "",
      healthCardIssued: "",
    },
  });
  function isNumberInAgeBand(number: number): boolean {
    let error = true;  // Initialize as true, assume there's an error

    addPoliciesdata.ageBand.some((band: any) => {
        let min = band.min.includes('Up to') ? 0 : (band.min ? parseInt(band.min) : 0);
        let max = band.max ? parseInt(band.max) : Infinity;

        // If the number is within the range, set error to false
        if (number >= min && number <= max) {
            error = false;
            return true; // Stop further iteration as a valid band is found
        }

        // Continue searching other bands if the number is not within the current band
        return false;
    });

    // Set the error state based on the final result
    seterrorMaxChildAge(error);

    // Return true if there is an error, false otherwise
    return error;
}

useEffect(() => {
  if(addPoliciesdata.planType.coverageType.some(
    (obj) => obj.code === 2
  )){
    isNumberInAgeBand(Number(extractNumbersFromString(addPoliciesdata.maxChildAge)))
  }else{
    seterrorMaxChildAge(false);
  }
}, [...addPoliciesdata.ageBand,...addPoliciesdata.maxChildAge,...addPoliciesdata.planType.coverageType]);

  const policyStartDate =
    addPoliciesdata.policy.enrolmentStartDate &&
    new Date(addPoliciesdata.policy.enrolmentStartDate);
  const currentDate = new Date();
  const isTodayBeforeStartDate = policyStartDate
    ? currentDate <= policyStartDate
    : true;
  const [showLoader, setShowLoader] = useState(true);
  useEffect(() => {
    setIsPolicyAttachedToMember(
      !(policyStatus === 20 && isTodayBeforeStartDate) ||
      isPolicyAttachedToMemberoriginal
    );
  }, [policyStatus, policyStartDate]);

  useEffect(() => {
    let selected = tpaList?.find((item: any) => item.id === editData?.tpa);
    setSelectedTpa(selected);
  }, [editData, tpaList]);

  useEffect(() => {
    if (selectedTpa) {
      setAddPoliciesdata({
        ...addPoliciesdata,
        tpa: { id: selectedTpa.id, name: selectedTpa.label },
      });
    }
  }, [selectedTpa]);

  useEffect(() => {
    filterDropDownData();
  }, [dropdownSalarydesignationDep]);
  useEffect(() => {
    get_policy_details(id);
    check_ispolicy_attached_toMember(id);
    get_client_list();
    YearOptions();
    get_policy_tax();
    tpa_name_dropdownlist();
    setShowLoader(true);
  }, []);

  //exclusion
  useEffect(() => {
    if (addPoliciesdata?.benefitsDetailsGrade) {
      const updatedFeatureList = addPoliciesdata?.benefitsDetailsGrade?.map(
        (apiObject: any) => {
          const updatedFeatures = exclusionsListdata.map(
            (exclusionGroup: any) => {
              const matchingFeature = apiObject.exclusion.find(
                (apiFeature: any) =>
                  apiFeature.exclusionId === exclusionGroup.exclusion.id
              );

              const updatedExclusion = {
                ...exclusionGroup,
                exclusion: {
                  ...exclusionGroup.exclusion,
                  check: !!matchingFeature,
                },
              };

              return updatedExclusion;
            }
          );

          return updatedFeatures;
        }
      );

      setexclusionsListdataGraded(updatedFeatureList);
      //setfeaturesListdataGraded(updatedFeatureList)
    }
  }, [exclusionsListdata]);

  useEffect(() => {
    if (addPoliciesdata?.benefitsDetailsGrade) {
      const updatedFeatureList = addPoliciesdata?.benefitsDetailsGrade?.map(
        (apiObject: any) => {
          const updatedFeatures = featuresListdata.map((featureGroup: any) => {
            const updatedGroup = {
              ...featureGroup,
              features: featureGroup.features.map((feature: any) => {
                const matchingFeature = apiObject.features.find(
                  (apiFeature: any) => apiFeature.featureId === feature.id
                );
                if (matchingFeature) {
                  return {
                    ...feature,
                    value: matchingFeature.value,
                    check: true,
                  };
                } else {
                  return {
                    ...feature,
                    value: "",
                    check: false,
                  };
                }
              }),
            };
            return updatedGroup;
          });
          return updatedFeatures;
        }
      );
      setfeaturesListdataGraded(updatedFeatureList);
      setfeaturesListdataGradedCopy(updatedFeatureList);

      let updated_warn: Array<any> = [];

      if (addPoliciesdata?.benefitsDetailsGrade) {
        updated_warn = Array(addPoliciesdata?.benefitsDetailsGrade?.length)
          .fill(null)
          .map(() => ({
            designation: false,
            name: false,
            sumInsured: false,
            deductible: false,
            features: [
              {
                name: false,
                value: false,
                featureId: false,
                groupId: false,
              },
            ],
            exclusion: [
              {
                name: false,
                description: false,
                exclusionId: false,
              },
            ],
          }));
      }

      let updated_warnAgeband = Array(addPoliciesdata?.ageBand?.length)
        .fill(null)
        .map(() => ({
          name: false,
          max: false,
          min: false,
        }));
      setAddPoliciesdataWarn((prevState: any) => ({
        ...prevState,
        benefitsDetailsGrade: updated_warn,
        ageBand: updated_warnAgeband,
      }));
    }
  }, [featuresListdata]);

  const scrollToSection = (elementRef: any) => {
    elementRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleClicks = (ref: any) => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };
  useEffect(() => {
    get_graded_data();
  }, [
    ...addPoliciesdata.planType.coverageType,
    ...addPoliciesdata.benefitsDetailsGrade,
    ...addPoliciesdata.ageBand,
    ...addPoliciesdata.rider,
  ]);

  const indivisualgradedlives = () => {
    let totalValue = 0;
    switch (addPoliciesdata?.planType?.name) {
      case "GRADED":
        modifiedArrayAgeBand?.forEach((array: any) => {
          array?.data?.forEach((data: any) => {
            data?.coverageTypeData.forEach((coverageData: any) => {
              if (coverageData.name === "total Live") {
                totalValue += parseInt(coverageData.value);
              }
            });
          });
        });
        break;

      case "FLAT":
        modifiedArrayAgeBand?.forEach((array: any) => {
          array?.coverageTypeData.forEach((coverageData: any) => {
            if (coverageData.name === "total Live") {
              totalValue += parseInt(coverageData.value);
            }
          });
        });
        break;
      default:
        break;
    }

    setindivisualGradeLives(totalValue);
  };

  useEffect(() => {
    indivisualgradedlives();
  }, [modifiedArrayAgeBand]);
  const get_graded_data = () => {
    let data = { ...addPoliciesdata };
    let dataWarn = { ...addPoliciesdataWarn };
    if (data?.planType?.name === "FLAT") {
      let data_arr: any = data?.ageBand?.map((dataItem: any) => ({
        min: dataItem.min,
        max: dataItem.max,
        coverageTypeData: [
          ...data?.planType?.coverageType
            .map((dataCover: any) => ({
              code: String(dataCover.code),
              name: dataCover.name,
              value: "",
            }))
            .slice(0, 0), // elements before the insertion point
          { code: "", name: "perDay", value: "" }, // element to insert
          ...data?.planType?.coverageType
            .map((dataCover: any) => ({
              code: String(dataCover.code),
              name: dataCover.name,
              value: "",
            }))
            .slice(0), // elements after the insertion point
        ],
      }));

      // let data_arr: any = data?.ageBand?.map((dataItem: any) => ({
      //   min: dataItem.min,
      //   max: dataItem.max,
      //   coverageTypeData: data?.planType?.coverageType.map(
      //     (dataCover: any) => ({
      //       code: String(dataCover.code),
      //       name: dataCover.name,
      //       value: "",
      //     })
      //   ),
      // }));

      let data_arrWarn: any = data?.ageBand?.map((dataItem: any) => ({
        min: false,
        max: false,
        coverageTypeData: data?.planType?.coverageType.map(
          (dataCover: any) => ({
            code: false,
            name: false,
            value: false,
          })
        ),
      }));
      data = {
        ...data,
        rider_premium_flat: [...data.rider_premium_flat],
      };

      // data = {
      //   ...data,
      //   rider_premium_flat: data.rider_premium_flat.map(
      //     (riderdata, indexValue: number) => ({
      //       ...riderdata,
      //       livesInfoFlat: data_arr.map((dataRider: any, index: any) => ({
      //         ...dataRider,
      //         coverageTypeData: [
      //           {
      //             code: 0,
      //             name: "EMPLOYEE",
      //             value: addPoliciesdata.rider_premium_flat[indexValue]
      //               .livesInfoFlat[index]
      //               ? addPoliciesdata.rider_premium_flat[indexValue]
      //                 .livesInfoFlat[index].coverageTypeData[0].value
      //               : "",
      //           },
      //         ],
      //       })),
      //     })
      //   ),
      // };
      dataWarn = {
        ...dataWarn,
        rider_premium_flat: data.rider_premium_flat.map((riderdata) => ({
          proreta_by: false,
          premium_split: false,
          rider_name: false,
          livesInfoFlat: data_arrWarn,
        })),
      };
      setAddPoliciesdataWarn(dataWarn);

      let newDataArr = structuredClone(data_arr);
      if (newDataArr) {
        newDataArr.forEach((item: any) => {
          // Check if coverageTypeData exists before pushing items into it
          if (item?.coverageTypeData) {
            item?.coverageTypeData.unshift({
              name: "amount",
              value: "",
            });
          }
          if (item?.coverageTypeData) {
            item?.coverageTypeData.push({
              name: "total Live",
              value: "",
            });
          }
        });
      }
      // data = {
      //   ...data,
      //   livesInfoFlat: newDataArr,
      // };
      setAddPoliciesdata(data);
      setModifiedArrayAgeBand(data?.livesInfoFlat);

      // setModifiedArrayAgeBand(data.livesInfoFlat);
    }
    if (
      data?.planType?.name === "GRADED" &&
      data.benefitsDetailsGrade.length > 0
    ) {
      let updatedArr: any = [];
      for (let i = 0; i < addPoliciesdata.benefitsDetailsGrade.length; i++) {
        let data_arr: any = data?.ageBand?.map((dataItem: any) => ({
          min: dataItem.min,
          max: dataItem.max,
          coverageTypeData: [
            ...data?.planType?.coverageType
              .map((dataCover: any) => ({
                code: String(dataCover.code),
                name: dataCover.name,
                value: "",
              }))
              .slice(0, 0),
            { code: "", name: "perDay", value: "" },
            ...data?.planType?.coverageType
              .map((dataCover: any) => ({
                code: String(dataCover.code),
                name: dataCover.name,
                value: "",
              }))
              .slice(0),
          ],
        }));

        if (data_arr) {
          data_arr.forEach((item: any) => {
            // Check if coverageTypeData exists before pushing items into it
            if (item?.coverageTypeData) {
              item?.coverageTypeData.unshift({
                name: "amount",
                value: "",
              });
            }
            if (item?.coverageTypeData) {
              item?.coverageTypeData.push({
                name: "total Live",
                value: "",
              });
            }
          });
        }

        data = {
          ...data,
          livesInfoFlat: data_arr,
        };

        data = {
          ...data,
          rider_premium_graded: [...data.rider_premium_graded],
        };

        updatedArr.push(data_arr);
        // perDay amount not display
        // data = {
        //   ...data,
        //   rider_premium_graded: data.rider.map((riderdata, index) => ({
        //     proreta_by: addPoliciesdata.rider_premium_graded[index].proreta_by,
        //     premium_split:
        //       addPoliciesdata.rider_premium_graded[index].premium_split,
        //     rider_name: riderdata.name,
        //     livesInfoGrade: updatedArr.map((grade: any, idx: any) => ({
        //       gradeName: `Grade ${idx + 1}`, // Generating gradeName dynamically
        //       data: grade.map((element: any, eleIndex: any) => ({
        //         ...element,
        //         coverageTypeData: element?.coverageTypeData?.map(
        //           (coverageItem: any, coverageIndex: any) => {
        //             if (coverageItem.name === "amount" ) {
        //               return {
        //                 ...coverageItem,
        //                 value:
        //                   addPoliciesdata?.rider_premium_graded?.[index]
        //                     ?.livesInfoGrade?.[idx]?.data?.[eleIndex]
        //                     ?.coverageTypeData?.[0]?.value ?? "",
        //               };
        //             }
        //             return coverageItem;
        //           }
        //         ),
        //       })),
        //     })),
        //   })),
        // };

        setAddPoliciesdata(data);
      }

      let newArray = updatedArr?.map((dataArray: any, index: number) => {
        return {
          gradeName: "Grade " + (index + 1),
          data: dataArray.map((inner: any) => {
            return { ...inner, name: "age band" };
          }),
        };
      });

      setAddPoliciesdata((prevState) => ({
        ...prevState,
        livesInfoGrade: data?.livesInfoGrade,
        livesInfoFlat: [],
        benefitsDetailsflat: {},
      }));
      setModifiedArrayAgeBand(data?.livesInfoGrade);
    }
  };
  const updateMasterStateLives = (attrName: any, value: any) => {
    let dataArr: any = [...modifiedArrayAgeBand];
    let proRataUnit = addPoliciesdata.policy.proreta_by;
    let start = moment(
      new Date(addPoliciesdata.policyDetails.startDate),
      "DD-MM-YYYY"
    );

    let yearDays = 365;
    if (containsLeapYearFebruary(new Date(value), 365)) {
      yearDays = 366;
    }

    let unit =
      proRataUnit === "YEARLY"
        ? yearDays
        : proRataUnit === "MONTHLY"
          ? 30
          : proRataUnit === "WEEKLY"
            ? 7
            : 1;
    if (attrName[2] === "flatData") {
      dataArr[attrName[0]].coverageTypeData[attrName[1]].value =
        parseFloat(value) || 0;
      dataArr[attrName[0]].coverageTypeData[attrName[1] + 1].value =
        value / unit;
      let totalValue = 0;
      dataArr[attrName[0]].coverageTypeData?.forEach((obj: any) => {
        const name = obj.name;
        if (name !== "amount" && name !== "total Live" && name !== "perDay") {
          const value = parseFloat(obj.value) || 0;
          totalValue += value;
        }
      });

      dataArr[attrName[0]].coverageTypeData[
        dataArr[attrName[0]].coverageTypeData.length - 1
      ].value = totalValue;
    } else if (attrName[2] === "gradeData") {
      dataArr[liveGraded].data[attrName[0]].coverageTypeData[
        attrName[1]
      ].value = value;
      dataArr[liveGraded].data[attrName[0]].coverageTypeData[
        attrName[1] + 1
      ].value = value / unit;
      let total: number = 0;
      let totalValue = 0;

      dataArr[liveGraded].data[attrName[0]].coverageTypeData?.forEach(
        (obj: any) => {
          const name = obj.name;
          if (name !== "amount" && name !== "total Live" && name !== "perDay") {
            const value = parseFloat(obj.value) || 0;
            totalValue += value;
          }
        }
      );

      dataArr[liveGraded].data[attrName[0]].coverageTypeData[
        dataArr[liveGraded].data[attrName[0]].coverageTypeData.length - 1
      ].value = totalValue;
    }
    // console.log("dataArr", dataArr[liveGraded][attrName[0]], dataArr[liveGraded][attrName[0]] === addPoliciesdata.rider_premium_graded[0].livesInfoGrade[0].data[0], addPoliciesdata.rider_premium_graded[0].livesInfoGrade[0].data[0])
    setModifiedArrayAgeBand(dataArr);
  };

  const updateMasterState = (attrName: any, value: any) => {
    type ValueTypes = keyof policiesDTONew;
    let data: policiesDTONew = { ...addPoliciesdata };
    let dataWarn = { ...addPoliciesdataWarn };
    let sectionName: ValueTypes = attrName[1];
    let layout_name: any = attrName[0];
    let fieldname: any = attrName[2];
    let dropDown_key = attrName[3];
    let index_value = attrName[4];
    if (attrName !== "others") {
      if (sectionName === "organisationDetails") {
        if (dropDown_key === "dropdown") {
          data = {
            ...data,
            [sectionName]: { ...data[sectionName], [fieldname]: value?.label },
          };
          data = {
            ...data,
            [sectionName]: { ...data[sectionName], id: value?.id },
          };
          dataWarn = {
            ...dataWarn,
            [sectionName]: { ...dataWarn[sectionName], [fieldname]: false },
          };

          if (fieldname === "clientId") {
            setSelectedClientId(value?.id);
            get_organization_data(value?.id);
            get_client_location_list(value?.id);
          }
        }
        if (dropDown_key === "multiselect") {
          if (fieldname === "location") {
            let arr: any = value ? value : [];
            let newArr: any = [];
            arr.map((data: any) => {
              newArr.push({ name: data.value, id: data.id });
            });

            data = {
              ...data,
              [sectionName]: { ...data[sectionName], [fieldname]: newArr },
            };
            dataWarn = {
              ...dataWarn,
              [sectionName]: { ...dataWarn[sectionName], [fieldname]: false },
            };
          }
        }
      } else if (sectionName === "productType") {
        data = {
          ...data,
          policyCase: {
            name: "FRESH",
            code: 0,
            policyNumber: "",
          },
          insurerDetails: { id: "", name: "", office: "" },
          tpa: { id: "", name: "" },
          planType: {
            name: PolicyCoverageType.FLAT,
            coverageType: [
              {
                code: 0,
                name: "EMPLOYEE",
              },
            ],
            basicConfig: "1E",
            parentConfig: "Nill",
            familyDefinition: "",
            gradeCriteria: "",
            setOfParents: "both",
          },
          is_rider: false,
          rider: [
            {
              name: "",
              description: "",
            },
          ],
          benefits_detail_flat: [
            {
              label: "",
              enable: false,
              amount: "",
              description: "",
            },
          ],
          benefits_detail_graded: [
            {
              grade_name: "Grade 1",
              riders: [
                {
                  label: "",
                  enable: false,
                  amount: "",
                  description: "",
                },
              ],
            },
          ],
          rider_premium_graded: [
            {
              proreta_by: "YEARLY",
              rider_name: "",
              premium_split: "",
              livesInfoGrade: [
                {
                  gradeName: "Grade 1",
                  data: [
                    {
                      min: "",
                      max: "",
                      coverageTypeData: [
                        {
                          name: "amount",
                          value: "500",
                        },
                        {
                          code: "0",
                          name: "EMPLOYEE",
                          value: "",
                        },
                        {
                          name: "total Live",
                          value: 0,
                        },
                      ],
                      name: "age band",
                    },
                  ],
                },
              ],
            },
          ],
          rider_premium_flat: [
            {
              proreta_by: "YEARLY",
              premium_split: "",
              rider_name: "",
              livesInfoFlat: [
                {
                  min: "",
                  max: "",
                  name: "",
                  coverageTypeData: [
                    {
                      code: "",
                      name: "",
                      value: 0,
                    },
                  ],
                },
              ],
            },
          ],
          payType: {
            type: premiumType.SPLIT,
            data: [
              {
                name: "EMPLOYEE",
                value: 0,
              },
            ],
          },
          ageBand: [
            {
              name: "",
              max: "",
              min: "",
            },
          ],
          maxChildAge: "",
          benefitsDetailsflat: {
            sumInsured: "",
            deductible: "",
            features: [
              {
                name: "",
                value: "",
                featureId: "",
                groupId: "",
              },
            ],
          },
          benefitsDetailsGrade: [
            {
              gradeDivisions: [{ id: "", label: "" }],
              name: "Grade 1",
              sumInsured: "",
              deductible: "",
              features: [
                {
                  name: "",
                  value: "",
                  featureId: "",
                  groupId: "",
                },
              ],
              exclusion: [
                {
                  name: "",
                  description: "",
                  exclusionId: "",
                },
              ],
            },
          ],
          premiumDetails: {
            netPremium: "",
            taxPercentage: [],
            taxAmount: "",
            grossPremium: "",
          },
          policyDetails: {
            startDate: null,
            endDate: null,
            cdBalance: "0",
          },
          policy: {
            criticalCd: 0,
            criticalPercentage: 0,
            enrolmentStartDate: null,
            enrolmentEndDate: null,
            period: "",
            gracePeriod: "4 Days",
            maxMemberPerFamily: 10,
            totalMemberPerFamily: 1,
            newMemberPeriod: "10 Days",
            nomination: false,
            maxNomination: 0,
            healthCard: false,
            cardType: "",
            healthCardIssued: "",
            isCorporateBuffer: false,
            corporateBuffer: null,
            proreta_by: "YEARLY",
          },
          livesInfoFlat: [
            {
              min: "",
              max: "",
              name: "",
              coverageTypeData: [
                {
                  code: "",
                  name: "",
                  value: "",
                },
              ],
            },
          ],
          livesInfoGrade: [],
        };

        const num =
          value === "Group Term"
            ? 0
            : value === "Group Health"
              ? 1
              : value === "Group Travel"
                ? 2
                : value === "Group Personal Accident"
                  ? 3
                  : value === "Group Critical Illness"
                    ? 4
                    : value === "Group Super TopUp"
                      ? 5
                      : null;
        let productType: any = { name: value, code: num };
        data = { ...data, [sectionName]: productType };
        dataWarn = {
          ...dataWarn,
          [sectionName]: { ...dataWarn[sectionName], name: false },
        };
        // Api for feature list
        features_list(num);
        // Api for exclusion list
        exclusion_list(num);
        // Api for insurer list
        insurance_name_dropdownlist(
          value === "Group Term" ? "LIFE" : "GENERAL"
        );
        if (value === "Group Term") {
          data = {
            ...data,
            policy: {
              ...data.policy,
              maxMemberPerFamily: 1,
            },
          };
        }
      } else if (sectionName === "policyCase") {
        if (dropDown_key === "radio") {
          const num = value === "FRESH" ? 0 : value === "RENEWAL" ? 1 : null;
          let policyCase: any = { name: value, code: num };
          data = { ...data, [sectionName]: policyCase };
          dataWarn = {
            ...dataWarn,
            [sectionName]: { ...dataWarn[sectionName], name: false },
          };
        } else if (dropDown_key === "input_field") {
          data = {
            ...data,
            [sectionName]: { ...data[sectionName], [fieldname]: value },
          };
          dataWarn = {
            ...dataWarn,
            [sectionName]: { ...dataWarn[sectionName], [fieldname]: false },
          };
        }
      } else if (sectionName === "insurerDetails") {
        if (dropDown_key === "dropdown") {
          if (fieldname === "name") {
            data = {
              ...data,
              [sectionName]: {
                ...data[sectionName],
                [fieldname]: value?.label,
              },
            };
            data = {
              ...data,
              [sectionName]: { ...data[sectionName], id: value?.id },
            };
            insurance_address_dropdownlist(value?.id);
            dataWarn = {
              ...dataWarn,
              [sectionName]: { ...dataWarn[sectionName], [fieldname]: false },
            };
          }
          if (fieldname === "office") {
            data = {
              ...data,
              [sectionName]: { ...data[sectionName], [fieldname]: value },
            };
            dataWarn = {
              ...dataWarn,
              [sectionName]: { ...dataWarn[sectionName], [fieldname]: false },
            };
          }
        }
      } else if (sectionName === "tpa") {
        if (dropDown_key === "dropdown") {
          if (fieldname === "name") {
            data = {
              ...data,
              [sectionName]: {
                ...data[sectionName],
                [fieldname]: value?.label,
              },
            };
            data = {
              ...data,
              [sectionName]: { ...data[sectionName], id: value?.id },
            };
            dataWarn = {
              ...dataWarn,
              [sectionName]: { ...dataWarn[sectionName], [fieldname]: false },
            };
          }
        }
      } else if (sectionName === "rider") {
        if (fieldname === "name") {
          const obj = {
            description: data.rider[index_value]?.description,
            name: value,
          };
          const obj1 = {
            description: data.rider[index_value]?.description,
            label: value,
            amount: data.benefits_detail_flat[index_value]?.amount,
            enable: data.benefits_detail_flat[index_value]?.enable,
          };
          const obj2 = {
            ...data.rider_premium_flat?.[index_value],
            rider_name: value,
          };
          data.rider.splice(index_value, 1, obj);
          data.benefits_detail_flat.splice(index_value, 1, obj1);
          data.rider_premium_flat.splice(index_value, 1, obj2);
        } else if (fieldname === "description") {
          const obj = {
            description: value,
            name: data.rider[index_value]?.name,
          };
          const obj1 = {
            description: value,
            label: data.benefits_detail_flat[index_value]?.label,
            amount: data.benefits_detail_flat[index_value]?.amount,
            enable: data.benefits_detail_flat[index_value]?.enable,
          };
          data.rider.splice(index_value, 1, obj);
          data.benefits_detail_flat.splice(index_value, 1, obj1);
        }
      } else if (sectionName === "benefits_detail_flat") {
        if (fieldname === "amount") {
          const obj = {
            ...data.benefits_detail_flat[index_value],
            amount: value,
          };
          data.benefits_detail_flat.splice(index_value, 1, obj);
        }
      } else if (sectionName === "benefits_detail_graded") {
        if (fieldname === "amount") {
          data.benefits_detail_graded[index_value].riders[attrName[5]].amount =
            value;
        }
      } else if (sectionName === "rider_premium_flat") {
        if (fieldname === "proreta_by") {
          data.rider_premium_flat[attrName[3]].proreta_by = value;
        } else if (fieldname === "premium_split") {
          data.rider_premium_flat[attrName[3]].premium_split = value;
        } else {
          const updatedPremium = structuredClone(
            data.rider_premium_flat[attrName[2]]
          ) as any;
          updatedPremium.livesInfoFlat[attrName[3]].coverageTypeData[0].value =
            value;
          let proRataUnit = updatedPremium.proreta_by;

          let start = moment(
            new Date(data.policyDetails.startDate),
            "DD-MM-YYYY"
          );
          let yearDays = 365;
          if (containsLeapYearFebruary(new Date(value), 365)) {
            yearDays = 366;
          }

          let unit =
            proRataUnit === "YEARLY"
              ? yearDays
              : proRataUnit === "MONTHLY"
                ? 30
                : proRataUnit === "WEEKLY"
                  ? 7
                  : 1;
          updatedPremium.livesInfoFlat[attrName[3]].coverageTypeData[1].value =
            value / unit;
          data.rider_premium_flat.splice(attrName[2], 1, updatedPremium);
          // data.rider_premium_flat.splice(attrName[2], 1, updatedPremium);
        }

        // data.rider_premium_flat[attrName[2]].livesInfoFlat[attrName[3]].coverageTypeData[0].value = value
      } else if (sectionName === "rider_premium_graded") {
        if (fieldname === "proreta_by") {
          data.rider_premium_graded[attrName[3]].proreta_by = value;
        } else if (fieldname === "premium_split") {
          data.rider_premium_graded[attrName[3]].premium_split = value;
        } else {
          const updatedPremium = structuredClone(
            data.rider_premium_graded[attrName[2]]
          );
          let proRataUnit = updatedPremium.proreta_by;
          let start = moment(
            new Date(data.policyDetails.startDate),
            "DD-MM-YYYY"
          );
          let yearDays = 365;
          if (containsLeapYearFebruary(new Date(value), 365)) {
            yearDays = 366;
          }

          let unit =
            proRataUnit === "YEARLY"
              ? yearDays
              : proRataUnit === "MONTHLY"
                ? 30
                : proRataUnit === "WEEKLY"
                  ? 7
                  : 1;
          updatedPremium.livesInfoGrade[attrName[3]].data[
            attrName[4]
          ].coverageTypeData[0].value = value;
          updatedPremium.livesInfoGrade[attrName[3]].data[
            attrName[4]
          ].coverageTypeData[1].value = value / unit;
          data.rider_premium_graded.splice(attrName[2], 1, updatedPremium);
          // updatedPremium.livesInfoGrade[attrName[3]].data[
          //   attrName[4]
          // ].coverageTypeData[0].value = value;
          // data.rider_premium_graded.splice(attrName[2], 1, updatedPremium);
        }

        // data.rider_premium_flat[attrName[2]].livesInfoFlat[attrName[3]].coverageTypeData[0].value = value
      } else if (sectionName === "planType") {
        if (dropDown_key === "radio") {
          data = {
            ...data,
            [sectionName]: { ...data[sectionName], [fieldname]: value },
          };
          dataWarn = {
            ...dataWarn,
            [sectionName]: { ...dataWarn[sectionName], [fieldname]: false },
          };
          if (value === "GRADED") {
            data = {
              ...data,
              policyCase: {
                name: "FRESH",
                code: 0,
                policyNumber: "",
              },
              insurerDetails: { id: "", name: "", office: "" },
              tpa: { id: "", name: "" },
              planType: {
                name: PolicyCoverageType.GRADED,
                coverageType: [
                  {
                    code: 0,
                    name: "EMPLOYEE",
                  },
                ],
                basicConfig: "1E",
                parentConfig: "Nill",
                familyDefinition: "",
                gradeCriteria: "",
                setOfParents: "both",
              },
              is_rider: false,
              rider: [
                {
                  name: "",
                  description: "",
                },
              ],
              benefits_detail_flat: [
                {
                  label: "",
                  enable: false,
                  amount: "",
                  description: "",
                },
              ],
              benefits_detail_graded: [
                {
                  grade_name: "Grade 1",
                  riders: [
                    {
                      label: "",
                      enable: false,
                      amount: "",
                      description: "",
                    },
                  ],
                },
              ],
              rider_premium_graded: [
                {
                  proreta_by: "YEARLY",
                  rider_name: "",
                  premium_split: "",
                  livesInfoGrade: [
                    {
                      gradeName: "Grade 1",
                      data: [
                        {
                          min: "",
                          max: "",
                          coverageTypeData: [
                            {
                              name: "amount",
                              value: "500",
                            },
                            {
                              code: "0",
                              name: "EMPLOYEE",
                              value: "",
                            },
                            {
                              name: "total Live",
                              value: 0,
                            },
                          ],
                          name: "age band",
                        },
                      ],
                    },
                  ],
                },
              ],
              rider_premium_flat: [
                {
                  proreta_by: "YEARLY",
                  premium_split: "",
                  rider_name: "",
                  livesInfoFlat: [
                    {
                      min: "",
                      max: "",
                      name: "",
                      coverageTypeData: [
                        {
                          code: "",
                          name: "",
                          value: 0,
                        },
                      ],
                    },
                  ],
                },
              ],
              payType: {
                type: premiumType.SPLIT,
                data: [
                  {
                    name: "EMPLOYEE",
                    value: 0,
                  },
                ],
              },
              ageBand: [
                {
                  name: "",
                  max: "",
                  min: "",
                },
              ],
              maxChildAge: "",
              benefitsDetailsflat: {
                sumInsured: "",
                deductible: "",
                features: [
                  {
                    name: "",
                    value: "",
                    featureId: "",
                    groupId: "",
                  },
                ],
              },
              benefitsDetailsGrade: [
                {
                  gradeDivisions: [{ id: "", label: "" }],
                  name: "Grade 1",
                  sumInsured: "",
                  deductible: "",
                  features: [
                    {
                      name: "",
                      value: "",
                      featureId: "",
                      groupId: "",
                    },
                  ],
                  exclusion: [
                    {
                      name: "",
                      description: "",
                      exclusionId: "",
                    },
                  ],
                },
              ],
              premiumDetails: {
                netPremium: "",
                taxPercentage: [],
                taxAmount: "",
                grossPremium: "",
              },
              policyDetails: {
                startDate: null,
                endDate: null,
                cdBalance: "0",
              },
              policy: {
                criticalCd: 0,
                criticalPercentage: 0,
                enrolmentStartDate: null,
                enrolmentEndDate: null,
                period: "",
                gracePeriod: "4 Days",
                maxMemberPerFamily: 10,
                totalMemberPerFamily: 1,
                newMemberPeriod: "10 Days",
                nomination: false,
                maxNomination: 0,
                healthCard: false,
                cardType: "",
                healthCardIssued: "",
                isCorporateBuffer: false,
                corporateBuffer: null,
                proreta_by: "YEARLY",
              },
              livesInfoFlat: [
                {
                  min: "",
                  max: "",
                  name: "",
                  coverageTypeData: [
                    {
                      code: "",
                      name: "",
                      value: "",
                    },
                  ],
                },
              ],
              livesInfoGrade: [],
            };
          } else if (value === "FLAT") {
            data = {
              ...data,
              benefitsDetailsGrade: [],
              premiumDetails: {
                netPremium: "",
                taxPercentage: [],
                taxAmount: "",
                grossPremium: "",
              },
              policyDetails: {
                startDate: null,
                endDate: null,
                cdBalance: "0",
              },
              policy: {
                criticalCd: 0,
                criticalPercentage: 0,
                enrolmentStartDate: null,
                enrolmentEndDate: null,
                period: "",
                gracePeriod: "4 Days",
                maxMemberPerFamily: 10,
                totalMemberPerFamily: 1,
                newMemberPeriod: "10 Days",
                nomination: false,
                maxNomination: 0,
                healthCard: false,
                cardType: "",
                healthCardIssued: "",
                isCorporateBuffer: false,
                corporateBuffer: null,
                proreta_by: "YEARLY",
              },
              planType: {
                name: PolicyCoverageType.FLAT,
                coverageType: [
                  {
                    code: 0,
                    name: "EMPLOYEE",
                  },
                ],
                basicConfig: "1E",
                parentConfig: "Nill",
                familyDefinition: "",
                gradeCriteria: "",
                setOfParents: "both",
              },
              rider_premium_graded: [],
              benefits_detail_graded: [],
              rider_premium_flat: [
                {
                  proreta_by: "YEARLY",
                  premium_split: "",
                  rider_name: "",
                  livesInfoFlat: [
                    {
                      min: "",
                      max: "",
                      name: "",
                      coverageTypeData: [
                        {
                          code: "",
                          name: "",
                          value: 0,
                        },
                      ],
                    },
                  ],
                },
              ],
              benefits_detail_flat: [
                {
                  label: "",
                  enable: false,
                  amount: "",
                  description: "",
                },
              ],
              benefitsDetailsflat: {
                sumInsured: "",
                deductible: "",
                features: [
                  {
                    name: "",
                    value: "",
                    featureId: "",
                    groupId: "",
                  },
                ],
              },
            };
          }
        } else if (dropDown_key === "checkbox") {
          if (value !== "EMPLOYEE") {
            let coverage_type_array: coverageType[] =
              data.planType.coverageType;

            const existingIndex = coverage_type_array.findIndex(
              (item: any) => item.name === value
            );
            if (existingIndex === -1) {
              // Add coverage type if it doesn't exist
              if (value === "KIDS") {
                if (
                  !coverage_type_array.some(
                    (item: any) => item.name === "SPOUSE"
                  )
                ) {
                  coverage_type_array = [
                    ...coverage_type_array,
                    {
                      code: 1,
                      name: "SPOUSE",
                    },
                  ];
                }
              }

              if (value === "SPOUSE") {
                if (
                  coverage_type_array.some((item: any) => item.name === "KIDS")
                ) {
                  //do nothing
                }
              }

              coverage_type_array = [
                ...coverage_type_array,
                {
                  code:
                    value === "EMPLOYEE"
                      ? 0
                      : value === "SPOUSE"
                        ? 1
                        : value === "KIDS"
                          ? 2
                          : value === "PARENTS"
                            ? 3
                            : value === "PARENTSINLAW"
                              ? 4
                              : null,
                  name: value,
                },
              ];
            } else {
              if (value === "SPOUSE") {
                if (
                  coverage_type_array.some((item: any) => item.name === "KIDS")
                ) {
                  //do nothing
                  return;
                }
              }

              // Remove coverage type if it exists
              const updatedCoverageTypes = [...coverage_type_array];
              updatedCoverageTypes.splice(existingIndex, 1);
              coverage_type_array = updatedCoverageTypes;
            }

            const newData = data.payType.data.filter((item: any) => {
              if (coverage_type_array.some((e: any) => e.name === item.name)) {
                return true;
              }
            });

            for (let i = 0; i < coverage_type_array.length; i++) {
              let pushObj = true;
              for (let j = 0; j < newData.length; j++) {
                if (coverage_type_array[i].name === newData[j].name) {
                  pushObj = false;
                  break;
                }
              }

              if (pushObj) {
                newData.push({ name: coverage_type_array[i].name, value: 0 });
              }
            }

            data = {
              ...data,
              payType: { ...data.payType, data: newData },
            };

            for (let i = 0; i <= coverage_type_array.length; i++) {
              if (coverage_type_array[i]?.name === "EMPLOYEE") {
                setbasicConfig_data([{ key: "1E", value: "1E" }]);
              } else if (coverage_type_array[i]?.name === "SPOUSE") {
                setbasicConfig_data([{ key: "1E+1S", value: "1E+1S" }]);
              } else if (coverage_type_array[i]?.name === "KIDS") {
                if (
                  !coverage_type_array.includes({ code: 1, name: "SPOUSE" })
                ) {
                  let updated_data = coverage_type_array;
                  if (
                    !data.planType.coverageType.some(
                      (obj: any) => obj.code === 1
                    )
                  ) {
                    if (!newData.some((obj: any) => obj.name === "SPOUSE")) {
                      if (
                        data.payType.data.some((e: any) => e.name === "SPOUSE")
                      ) {
                        newData.splice(
                          1,
                          0,
                          ...data.payType.data.filter(
                            (item: any) => item.name === "SPOUSE"
                          )
                        );
                      } else {
                        newData.splice(1, 0, { name: "SPOUSE", value: 0 });
                      }
                    }

                    updated_data = [
                      ...updated_data,
                      { code: 1, name: "SPOUSE" },
                    ];
                  }

                  data = {
                    ...data,
                    [sectionName]: {
                      ...data[sectionName],
                      [fieldname]: updated_data,
                    },
                    payType: { ...data.payType, data: newData },
                  };
                }
                setbasicConfig_data([
                  { key: "1E+1S+1C", value: "1E+1S+1C" },
                  { key: "1E+1S+1C+1C", value: "1E+1S+1C+1C" },
                  { key: "1E+1S+1C+1C+1C", value: "1E+1S+1C+1C+1C" },
                  { key: "1E+1S+1C+1C+1C+1C", value: "1E+1S+1C+1C+1C+1C" },
                ]);
              } else if (coverage_type_array[i]?.name === "PARENTS") {
                setindvConfig_data([{ key: "1P+1P ", value: "1P+1P " }]);
              } else if (coverage_type_array[i]?.name === "PARENTSINLAW") {
                setindvConfig_data([{ key: "1PIL+1PIL", value: "1PIL+1PIL" }]);
              } else if (
                data.planType.coverageType.some((obj: any) => obj.code === 3) &&
                data.planType.coverageType.some((obj: any) => obj.code === 4)
              ) {
                setindvConfig_data([
                  { key: "1P+1P+1PIL+1PIL", value: "1P+1P+1PIL+1PIL" },
                ]);
              }
            }
            data.planType.coverageType = coverage_type_array;
            if (
              data.planType.setOfParents === "single" &&
              extractNumbersFromString(data.planType.parentConfig).length === 4
            ) {
              data.policy.totalMemberPerFamily =
                extractNumbersFromString(data.planType.basicConfig).length +
                extractNumbersFromString(data.planType.parentConfig).length -
                2;
            }
          }
        } else if (dropDown_key === "input_fields") {
          if (value === "Department") {
            get_dep_designation_salary("", "DEPARTMENTS");
          } else if (value === "Designation") {
            get_dep_designation_salary("", "DESIGNATIONS");
          } else if (value === "Salary Bracket") {
            get_dep_designation_salary("", "SALARY_BRACKETS");
          }

          data = {
            ...data,
            [sectionName]: { ...data[sectionName], [fieldname]: value },
          };
          if (fieldname === "basicConfig") {
            let basicConfigNumber: number =
              extractNumbersFromString(value).length;
            let parentConfigNumber: number = extractNumbersFromString(
              data.planType.parentConfig
            ).length;
            data.policy.totalMemberPerFamily =
              basicConfigNumber + parentConfigNumber;
            if (
              data.planType.setOfParents === "single" &&
              extractNumbersFromString(data.planType.parentConfig).length === 4
            ) {
              data.policy.totalMemberPerFamily =
                extractNumbersFromString(value).length +
                extractNumbersFromString(data.planType.parentConfig).length -
                2;
            }
          } else if (fieldname === "parentConfig") {
            let basicConfigNumber: number = extractNumbersFromString(
              data.planType.basicConfig
            ).length;
            let parentConfigNumber: number =
              extractNumbersFromString(value).length;
            data.policy.totalMemberPerFamily =
              basicConfigNumber + parentConfigNumber;
            if (
              data.planType.setOfParents === "single" &&
              extractNumbersFromString(value).length === 4
            ) {
              data.policy.totalMemberPerFamily =
                extractNumbersFromString(data.planType.basicConfig).length +
                extractNumbersFromString(value).length -
                2;
            }
          }
        }
      } else if (sectionName === "ageBand") {
        if (fieldname === "min") {
          breakAgeBand(value.label, "onchange");
          setageBandMax(value.label);
          const obj: any = {
            min: value.label,
            max: "",
            name: "Age band ",
          };
          const objWarn: any = {
            min: false,
            max: dataWarn.ageBand[index_value]?.max,
            name: "Age band ",
          };
          data.ageBand.splice(index_value, 1, obj);
          //dataWarn.ageBand.splice(index_value, 1, objWarn);
        } else if (fieldname === "max") {
          // breakAgeBand(value.label)
          // setageBandMax(value.label)
          // const objWarn: any = {
          //   min: dataWarn.ageBand[index_value]?.min,
          //   max: false,
          //   name: "Age band ",
          // };
          const obj = {
            min: data.ageBand[index_value]?.min,
            max: value.label,
            name: "Age band ",
          };
          data.ageBand.splice(index_value, 1, obj);
          //dataWarn.ageBand.splice(index_value, 1, objWarn);
        }
        dataWarn = {
          ...dataWarn,
          [sectionName]: { ...dataWarn[sectionName], [fieldname]: false },
        };
      } else if (sectionName === "maxChildAge") {
        data = {
          ...data,
          [sectionName]: value.value,
        };
      } else if (layout_name === "slidepanelfeatures") {
        setAddFeature(false);
        setAddExclusion(false);
      } else if (sectionName === "premiumDetails") {
        data = {
          ...data,
          ["policy"]: {
            ...data["policy"],
            ["criticalPercentage"]: 0,
            criticalCd: 0,
          },
        };
        if (dropDown_key === "multiselect") {
          if (fieldname === "taxPercentage") {
            let arr: any = value ? value : [];
            let newArr: any = [];
            arr.map((data: any) => {
              newArr.push({ name: data.value });
            });
            data = {
              ...data,
              [sectionName]: { ...data[sectionName], [fieldname]: newArr },
            };
            dataWarn = {
              ...dataWarn,
              [sectionName]: { ...dataWarn[sectionName], [fieldname]: false },
            };
          }
        }
        if (fieldname === "netPremium") {
          let totalTaxAmount = calculateTotalTax(
            value,
            data.premiumDetails.taxPercentage
          );
          value = extractNumbersFromString(value);
          data = {
            ...data,
            [sectionName]: { ...data[sectionName], [fieldname]: value },
          };
          dataWarn = {
            ...dataWarn,
            [sectionName]: { ...dataWarn[sectionName], [fieldname]: false },
          };

          // Set the calculated values in the state

          data = {
            ...data,
            [sectionName]: {
              ...data[sectionName],
              taxAmount: totalTaxAmount,
            },
          };
          data = {
            ...data,
            [sectionName]: {
              ...data[sectionName],
              grossPremium: totalTaxAmount + Number(value),
            },
          };
        } else if (fieldname === "taxPercentage") {
          let totalTaxAmount = calculateTotalTax(
            +data.premiumDetails.netPremium,
            value
          );

          data = {
            ...data,
            [sectionName]: {
              ...data[sectionName],
              [fieldname]: value,
            },
          };
          data = {
            ...data,
            [sectionName]: {
              ...data[sectionName],
              taxAmount: totalTaxAmount,
            },
          };
          data = {
            ...data,
            [sectionName]: {
              ...data[sectionName],
              grossPremium:
                Number(data.premiumDetails.netPremium) + totalTaxAmount,
            },
          };
        } else if (fieldname === "grossPremium") {
          data = {
            ...data,
            [sectionName]: { ...data[sectionName], [fieldname]: value },
          };
        }
      } else if (sectionName === "policyDetails") {
        if (fieldname === "startDate") {
          data = {
            ...data,
            [sectionName]: {
              ...data[sectionName],
              [fieldname]: moment(value).format(),
            },
          };
          let end_date = add_days_in_date_getDateObject(value, "364");
          data = {
            ...data,
            [sectionName]: {
              ...data[sectionName],
              endDate: moment(end_date).format(),
            },
          };

          dataWarn = {
            ...dataWarn,
            [sectionName]: { ...dataWarn[sectionName], [fieldname]: false },
          };
        } else if (fieldname === "endDate") {
          data = {
            ...data,
            [sectionName]: {
              ...data[sectionName],
              [fieldname]: moment(value).format(),
            },
          };
          let start_date = subtract_days_in_date_getDateObject(value, "365");
          data = {
            ...data,
            [sectionName]: {
              ...data[sectionName],
              startDate: moment(start_date).format(),
            },
          };
        } else if (fieldname === "cdBalance") {
          data = {
            ...data,
            [sectionName]: { ...data[sectionName], [fieldname]: value },
          };

          dataWarn = {
            ...dataWarn,
            [sectionName]: { ...dataWarn[sectionName], [fieldname]: false },
          };
        }
      } else if (sectionName === "policy") {
        if (fieldname === "criticalCd") {
          if (!data.premiumDetails.grossPremium) {
            return toast.warning(
              "Please Enter Net Premium and Applicable taxes"
            );
          }
          if (parseInt(value) <= Number(data.premiumDetails.grossPremium)) {
            data = {
              ...data,
              [sectionName]: {
                ...data[sectionName],
                [fieldname]: parseInt(value),
              },
            };
            dataWarn = {
              ...dataWarn,
              [sectionName]: { ...dataWarn[sectionName], [fieldname]: false },
            };
            const calculatedPercentage: number = +(
              (+value / +data.premiumDetails.grossPremium) *
              100
            ).toFixed(2);
            data = {
              ...data,
              [sectionName]: {
                ...data[sectionName],
                ["criticalPercentage"]: calculatedPercentage,
              },
            };
          }
        } else if (fieldname === "criticalPercentage") {
          if (parseInt(value) <= 100 || parseInt(value) === 0) {
            data = {
              ...data,
              [sectionName]: {
                ...data[sectionName],
                [fieldname]: parseInt(value),
              },
            };
            let Part: number = +(+(
              +data.premiumDetails.grossPremium *
              (value / 100)
            ).toFixed(2));
            data = {
              ...data,
              [sectionName]: {
                ...data[sectionName],
                ["criticalCd"]: Part,
              },
            };
          }
        } else if (dropDown_key === "date_picker") {
          if (fieldname === "enrolmentStartDate") {
            data = {
              ...data,
              [sectionName]: {
                ...data[sectionName],
                enrolmentEndDate: null,
                period: "",
              },
            };
          } else {
            data.policyDetails.startDate = null;
            data.policyDetails.endDate = null;
          }
          data = {
            ...data,
            [sectionName]: {
              ...data[sectionName],
              [fieldname]: moment(value).format(),
            },
          };
          if (
            data.policy.enrolmentStartDate &&
            data.policy.enrolmentStartDate
          ) {
            // let time_period = calculateDaysDifference(
            //   data.policy.enrolmentStartDate,
            //   data.policy.enrolmentEndDate
            // );
            let time_period: any = (
              differenceInDays(
                new Date(
                  new Date(data.policy.enrolmentEndDate).setHours(0, 0, 0, 0)
                ),
                new Date(
                  new Date(data.policy.enrolmentStartDate).setHours(0, 0, 0, 0)
                )
              ) + 1
            )?.toString();

            if (fieldname === "enrolmentEndDate") {
              data = {
                ...data,
                [sectionName]: { ...data[sectionName], period: time_period },
              };
            }
          }
          dataWarn = {
            ...dataWarn,
            [sectionName]: { ...dataWarn[sectionName], [fieldname]: false },
          };
        } else if (dropDown_key === "drop_down_common") {
          data = {
            ...data,
            [sectionName]: { ...data[sectionName], [fieldname]: value },
          };
          dataWarn = {
            ...dataWarn,
            [sectionName]: { ...dataWarn[sectionName], [fieldname]: false },
          };
          if (fieldname === "gracePeriod") {
            data.policyDetails.startDate = null;
            data.policyDetails.endDate = null;
          }
        }
      } else if (layout_name === "FLAT") {
        data = {
          ...data,
          benefitsDetailsflat: {
            ...data["benefitsDetailsflat"],
            features: value,
          },
        };
        data = {
          ...data,
          benefitsDetailsGrade: [],
        };
        dataWarn = {
          ...dataWarn,
          benefitsDetailsflat: {
            ...data["benefitsDetailsflat"],
            features: false,
          },
        };
      } else if (layout_name === "sendaddExclusion") {
        data = {
          ...data,
          benefitsDetailsflat: {
            ...data["benefitsDetailsflat"],
            exclusion: value,
          },
        };
        dataWarn = {
          ...dataWarn,
          benefitsDetailsflat: {
            ...data["benefitsDetailsflat"],
            exclusion: false,
          },
        };
        if (dropDown_key === "gradedExclusion") {
          if (addPoliciesdata?.planType?.name === "GRADED") {
            let indexx: any = fieldname;
            let arr: any = [];
            value?.map((el: any) =>
              el.exclusion?.check === true
                ? arr.push({
                  description: el.exclusion.description,
                  exclusionId: el.exclusion.id,
                  name: el.exclusion.title,
                })
                : null
            );
            let warndata = {
              description: false,
              exclusionId: false,
              name: false,
            };
            data["benefitsDetailsGrade"][indexx].exclusion = arr;
            dataWarn["benefitsDetailsGrade"][indexx].exclusion = warndata;

            let graded_data: any = JSON.parse(
              localStorage.getItem("addPolicy") || ""
            );
            graded_data[indexx] = value;

            setexclusionsListdataGraded(graded_data);
            // setexclusionsListdataGradedCopy(graded_data);
          }
        }
      } else if (sectionName === "benefitsDetailsflat") {
        // Code for handling benefitsDetailsflat section
        if (fieldname === "sumInsured") {
          // Code for handling sumInsured field

          const val = Number(extractNumbersFromString(value));

          let warn = false;

          if (val > SumInsuredLimit.MAX_VALUE) {
            return;
          }

          if (!val) {
            warn = true;
          }

          data = {
            ...data,
            [sectionName]: { ...data[sectionName], [fieldname]: value },
          };
          dataWarn = {
            ...dataWarn,
            [sectionName]: {
              ...dataWarn[sectionName],
              [fieldname]: warn,
            },
          };
        } else if (fieldname === "deductible") {
          // Code for handling deductible field

          data = {
            ...data,
            [sectionName]: { ...data[sectionName], [fieldname]: extractNumbersFromString(value) },
          };
          dataWarn = {
            ...dataWarn,
            [sectionName]: { ...dataWarn[sectionName], [fieldname]: false },
          };
        }
      } else if (sectionName === "benefitsDetailsGrade") {
        // Code for handling benefitsDetailsGrade section

        data = {
          ...data,
          benefitsDetailsflat: {},
        };
        if (fieldname === "gradeDivisions") {
          data[sectionName][index_value].gradeDivisions = value;
          dataWarn[sectionName][index_value].designation = false;
          filterDropDownData();
        } else if (fieldname === "sumInsured") {
          const val = Number(extractNumbersFromString(value));

          if (val > SumInsuredLimit.MAX_VALUE) {
            return;
          }

          let warn = false;

          if (index_value > 0) {
            if (val <= Number(data[sectionName][index_value - 1].sumInsured)) {
              toast.warn(
                "Sum insured value cannot be less than or equal to previous grade."
              );
              warn = true;
            }
          }

          if (data[sectionName][index_value + 1]?.sumInsured) {
            if (val >= Number(data[sectionName][index_value + 1].sumInsured)) {
              toast.warn(
                "Sum insured value cannot be greater than or equal to next grade."
              );
              warn = true;
            }
          }

          data[sectionName][index_value].sumInsured = val.toString();
          dataWarn[sectionName][index_value].sumInsured = warn;
        } else if (fieldname === "deductible") {
          data[sectionName][index_value].deductible = value;
          dataWarn[sectionName][index_value].deductible = false;
        }
      } else if (layout_name === "GRADED") {
        let indexx: any = sectionName;
        let arr: any = [];
        value?.map((data: any) =>
          data?.features?.map((el: any) =>
            el.check === true && el.value
              ? arr.push({
                name: el.name,
                value: el.value,
                featureId: el.id,
                groupId: data._id,
                unit: el.unit,
              })
              : null
          )
        );
        let warndata = {
          featureId: false,
          groupId: false,
          name: false,
          value: false,
        };
        data["benefitsDetailsGrade"][indexx].features = arr;
        // dataWarn["benefitsDetailsGrade"][indexx].features = warndata;

        let graded_data: any = gradedAddDataCopy();
        graded_data[indexx] = value;

        setfeaturesListdataGraded(graded_data);
        setfeaturesListdataGradedCopy(graded_data);
      }
    } else {
      setOthers(value);
    }
    setAddPoliciesdata(data);
    setAddPoliciesdataWarn(dataWarn);
  };

  const updatePayTypeValues = (attrName: any, value: any) => {
    let data: policiesDTONew = addPoliciesdata;

    if (attrName[1] === "payType") {
      const newData = data.payType.data.map((item: any) => {
        let retVal = item;
        if (item.name === attrName[3]) {
          const val = value.replace(/[^0-9.]/g, "");

          if (!(val < 0 || val > 100)) {
            retVal = { ...retVal, value: val ? val : 0 };
          }
        }
        return retVal;
      });

      data = {
        ...data,
        payType: { ...data.payType, data: newData },
      };
    }

    setAddPoliciesdata(data);
  };

  function toggleShowNominee(e: any) {
    let data: policiesDTONew = { ...addPoliciesdata };
    setShowNominee(e.target.checked);
    data = {
      ...data,
      policy: { ...data["policy"], nomination: e.target.checked },
    };
    if (e.target.checked === false) {
      data = {
        ...data,
        policy: { ...data["policy"], maxNomination: 0 },
      };
    }
    setAddPoliciesdata(data);
  }

  function toggleShowhealth(e: any) {
    setShowhealth(e.target.checked);
    let data: policiesDTONew = { ...addPoliciesdata };
    data = {
      ...data,
      policy: { ...data["policy"], healthCard: e.target.checked },
    };

    if (e.target.checked === false) {
      data = {
        ...data,
        policy: { ...data["policy"], healthCardIssued: "" },
      };
      data = {
        ...data,
        policy: { ...data["policy"], cardType: "" },
      };
    }
    setAddPoliciesdata(data);
  }

  function toggleShowcorporate(e: any) {
    let data: policiesDTONew = { ...addPoliciesdata };
    setShowcorporate(e.target.checked);
    data = {
      ...data,
      policy: {
        ...data["policy"],
        isCorporateBuffer: e.target.checked,
      },
    };

    if (e.target.checked === false) {
      data = {
        ...data,
        policy: {
          ...data["policy"],
          corporateBuffer: 0,
        },
      };
    }
    setAddPoliciesdata(data);
  }

  const gradedDesignation_data = [
    { key: "Salary Bracket", value: "Salary Bracket" },
    { key: "Designation", value: "Designation" },
    { key: "Department", value: "Department" },
    { key: "Custom", value: "Custom" },
  ];
  const prorata = [
    {
      key: "YEARLY",
      value: "Yearly",
    },
    { key: "MONTHLY", value: "Monthly" },
    { key: "WEEKLY", value: "Weekly" },
    { key: "DAILY", value: "Daily" },
  ];
  const cardType_data = [
    { key: "E - Card", value: "E - Card" },
    { key: "Physical Card", value: "Physical Card" },
  ];
  const healthcardIssued_data = [
    {
      key: "Per Individual Member of the Family",
      value: "Per Individual Member of the Family",
    },
    { key: "Per Employee Only", value: "Per Employee Only" },
  ];

  const breakAgeBand = (value?: any, from?: any) => {
    let min;
    let max;
    if (addPoliciesdata.ageBand.length === 1) {
      min = maxage_dataClone.slice(0);
      if (from === "onchange") {
        max = maxage_dataClone.slice(
          Number(extractNumbersFromString(value ?? ageBandMax))
        );
      } else {
        max = maxage_dataClone.slice(
          Number(extractNumbersFromString(addPoliciesdata.ageBand[0]?.min)) ??
          ageBandMax
        );
      }
    } else {
      min = maxage_dataClone.slice(
        Number(
          extractNumbersFromString(
            addPoliciesdata.ageBand[addPoliciesdata.ageBand.length - 2]?.max ??
            ageBandMax
          )
        )
      );
      max = maxage_dataClone.slice(
        Number(extractNumbersFromString(value ?? ageBandMax))
      );
    }
    setminage_data(min);
    setmaxage_data(max);
  };

  const add_band_for_lives = (index: number) => {
    let data = [...ageBandData];
    data.splice(index + 1, 0, { min_age: "", max_age: "" });
    setAgeBandData(data);

    let dataMain: policiesDTONew = addPoliciesdata;
    let dataMainwarn = addPoliciesdataWarn;

    dataMain.ageBand.splice(index + 1, 0, { min: "", max: "", name: "" });
    // dataMainwarn.ageBand.splice(index + 1, 0, {
    //   min: false,
    //   max: false,
    //   name: "",
    // });

    setAddPoliciesdata(dataMain);

    setAddPoliciesdataWarn(dataMainwarn);
  };

  const remove_band_for_lives = (index: number) => {
    let data = [...ageBandData];
    data.splice(index, 1);
    setAgeBandData(data);

    let dataMain: policiesDTONew = addPoliciesdata;
    dataMain.ageBand.splice(index, 1);
    setAddPoliciesdata(dataMain);
    setageBandMax(
      extractNumbersFromString(
        dataMain.ageBand[dataMain.ageBand.length - 1].max
      )
    );
    breakAgeBand(
      extractNumbersFromString(
        dataMain.ageBand[dataMain.ageBand.length - 1].max
      ),
      "ondelete"
    );
  };

  const YearOptions = () => {
    const options = [];
    options.push({ label: "Up to 1 Year", value: "Up to 1 Year" });
    for (let i = 2; i <= 100; i++) {
      const label = `${i} ${i === 1 ? "Year" : "Years"}`;
      const value = `${i} Years`;
      options.push({ label, value });
    }
    setminage_data(options);
    setmaxage_dataClone(options);
    setmaxage_data(options);
  };

  // Get Client list for dropdown
  const get_client_list = () => {
    const onSuccess = (res: any) => {
      setClientListdata(res.data.data);
    };
    const onError = (err: any) => {
      console.log("Client list", err);
    };
    POLICIES_SERVICES.get_client_list(
      userState?.userType?.toLowerCase(),
      onSuccess,
      onError
    );
  };

  // Get organization data after select client
  const get_organization_data = (id: any) => {
    const onSuccess = (res: any) => {
      if (res.status === 200) {
        setTimeout(() => {
          setShowLoader(false);
        }, 1500);
      }
      setorganizationFullDetails(res.data.data[0]);
    };
    const onError = (err: any) => {
      console.log("organization_data", err);
      setShowLoader(false);
    };
    POLICIES_SERVICES.get_organization_data(onSuccess, onError, id);
  };

  // Get is policy Attached to any member or not
  const check_ispolicy_attached_toMember = (id: any) => {
    const onSuccess = (res: any) => {
      setIsPolicyAttachedToMember(res.data.data.isAttached);
      setIsPolicyAttachedToMemberoriginal(res.data.data.isAttached);
      setwarningpopup(res.data.data.isAttached);
    };
    const onError = (err: any) => {
      console.log("is policy Attached ", err);
    };
    POLICIES_SERVICES.check_ispolicy_attached_toMember(onSuccess, onError, id);
  };

  // GetLocation list data after select client
  const get_client_location_list = (id: any) => {
    const onSuccess = (res: any) => {
      let arr: any = res.data.data;
      let newArr: any = [];
      arr.map((data: any) => {
        newArr.push({ key: data.label, value: data.label, id: data.id });
      });
      setClientLocationList(newArr);
    };
    const onError = (err: any) => {
      console.log("get_client_location_list", err);
    };
    POLICIES_SERVICES.get_client_location_list(onSuccess, onError, id);
  };

  // Get insurance_name_dropdownlist data
  const insurance_name_dropdownlist = (type: any) => {
    const onSuccess = (res: any) => {
      let arr: any = res.data.data;
      let newArr: any = [];
      arr.map((data: any) => {
        newArr.push({ id: data.id, label: data.label });
      });
      setInsurerList(newArr);
    };
    const onError = (err: any) => {
      console.log("insurance_name_dropdownlist", err);
    };
    POLICIES_SERVICES.insurance_name_dropdownlist(onSuccess, onError, type);
  };
  // Get insurance_address_dropdownlist data
  const insurance_address_dropdownlist = (id: any) => {
    const onSuccess = (res: any) => {
      let arr: any = res.data.data;
      let newArr: any = [];
      arr.map((data: any) => {
        newArr.push({ key: data.label, value: data.label });
      });
      setInsurerAddressList(newArr);
    };
    const onError = (err: any) => {
      console.log("insurance_address_dropdownlist", err);
    };
    POLICIES_SERVICES.insurance_address_dropdownlist(onSuccess, onError, id);
  };

  // Get tpa_name_dropdownlist data
  const tpa_name_dropdownlist = () => {
    const onSuccess = (res: any) => {
      let arr: any = res.data.data;
      let newArr: any = [];
      arr.map((data: any) => {
        newArr.push({ id: data.id, label: data.label });
      });
      setTpaList(newArr);
    };
    const onError = (err: any) => {
      console.log("tpa_name_dropdownlist", err);
    };
    POLICIES_SERVICES.tpa_name_dropdownlist(onSuccess, onError);
  };

  // Get features list data
  const features_list = (code: any, ExistingFeatureData?: any) => {
    const onSuccess = (res: any) => {
      setfeaturesListdata(res.data.data);
      gradedAddData(res.data.data);
      update_feature_list(res.data.data, ExistingFeatureData);
    };
    const onError = (err: any) => {
      console.log("features_list", err);
    };
    POLICIES_SERVICES.features_list(onSuccess, onError, code);
  };

  const update_feature_list = (newFeatures: any, ExistingFeatureData: any) => {
    const arrToUse = newFeatures ? newFeatures : featuresListdata;
    const selectedFeatures = ExistingFeatureData;
    if (addPoliciesdata?.planType?.name === "FLAT") {
      const updatedFeatures = arrToUse.map((featureGroup: any) => {
        const updatedGroup = {
          ...featureGroup,
          features: featureGroup.features.map((feature: any) => {
            const matchingFeature = selectedFeatures?.find(
              (apiFeature: any) => apiFeature.featureId === feature.id
            );
            return {
              ...feature,
              value: matchingFeature?.value || "",
              check: !!matchingFeature,
            };
          }),
        };
        return updatedGroup;
      });
      setfeaturesListdata(updatedFeatures);
    }
  };

  const update_exclusion_list = (updatedData: any, dataToSave: any) => {
    const arrToUse = updatedData ? updatedData : exclusionsListdata;
    if (addPoliciesdata?.planType?.name === "FLAT") {
      const updatedExclusions = arrToUse.map((exclusionGroup: any) => {
        let temData = dataToSave.benefitsDetailsflat.exclusion
          ? dataToSave
          : addPoliciesdata;
        const matchingFeature =
          temData.benefitsDetailsflat.exclusion &&
          temData.benefitsDetailsflat.exclusion.find(
            (apiFeature: any) =>
              apiFeature.exclusionId === exclusionGroup.exclusion.id
          );
        const updatedExclusion = {
          ...exclusionGroup,
          exclusion: {
            ...exclusionGroup.exclusion,
            check: !!matchingFeature,
          },
        };

        return updatedExclusion;
      });

      setexclusionsListdata(updatedExclusions);

      return updatedExclusions;
    }
  };
  const update_policy_details = () => {
    if (disableSave) return;
    const onSuccess = (res: any) => {
      toast.success(" Updated successfully");
      navigate(ALL_ROUTES.POLICIES);
    };
    const onError = (err: any) => {
      setDisableSave(false);
      console.log("update_policy_details", err);
    };
    let data: any = addPoliciesdata;
    data = {
      ...addPoliciesdata,
      tpa: data.tpa.id,
      // status: data.status.toString(),
      policy: {
        ...addPoliciesdata.policy,
        corporateBuffer: addPoliciesdata.policy.isCorporateBuffer
          ? addPoliciesdata.policy.corporateBuffer
          : 0,
        maxNomination: addPoliciesdata.policy.nomination
          ? addPoliciesdata.policy.maxNomination
          : 0,
      },
      payType: {
        type: premiumPaymentType,
        data:
          premiumPaymentType === premiumType.SPLIT
            ? addPoliciesdata.payType.data.map((item: any) => {
              return { name: item.name, value: item.value };
            })
            : [],
      },
    };

    if (data.planType.gradeCriteria === "Others") {
      data.planType.gradeCriteria = others;
    }

    if (data.planType.name === PolicyCoverageType.FLAT) {
      data = {
        ...data,
        benefitsDetailsGrade: [],
        // {
        //   features: [{ featureId: "", groupId: "", name: "", value: "" }],
        //   name: "",
        //   sumInsured: "",
        // },
        // ],
      };
    } else if (data.planType.name === PolicyCoverageType.GRADED) {
      const newData = data.benefitsDetailsGrade.map((item: any) => {
        const newDivisions =
          item.gradeDivisions &&
          item.gradeDivisions.map((item1: any) => {
            return {
              id: item1.id,
              label: item1.label,
            };
          });

        return {
          exclusion: item.exclusion,
          features: item.features,
          gradeDivisions: newDivisions,
          name: item.name,
          sumInsured: item.sumInsured,
          deductible: item.deductible,
        };
      });

      data = {
        ...data,
        benefitsDetailsflat: {},
        benefitsDetailsGrade: newData,
      };
    }

    delete data.updatedAt;
    delete data.createdAt;
    delete data._id;
    delete data.status;
    delete data.btype_status;
    delete data.enrolment_status;

    const removeIdField = (data: any): any => {
      if (typeof data === "object" && data !== null) {
        if (Array.isArray(data)) {
          return data.map((item) => removeIdField(item));
        } else {
          const newData: any = {};
          for (let key in data) {
            if (key !== "_id") {
              newData[key] = removeIdField(data[key]);
            }
          }
          return newData;
        }
      }
      return data;
    };
    let dataArr: any[] = [];
    data.premiumDetails.taxPercentage.forEach((newData: any) => {
      const { id, key, label, value } = newData;
      dataArr.push({
        id: id || key,
        label: label || value,
      });
    });

    data = {
      ...data,
      premiumDetails: {
        ...data.premiumDetails,
        taxPercentage: dataArr,
      },
    };

    setDisableSave(true);
    const removeIdFromData = JSON.parse(JSON.stringify(removeIdField(data)));
    POLICIES_SERVICES.update_policy_details(
      removeIdFromData,
      onSuccess,
      onError,
      id
    );
  };

  // Get features list data
  const exclusion_list = (code: any, dataToSave?: any) => {
    const onSuccess = (res: any) => {
      const updatedData = res.data.data.map((item: any) => {
        return {
          ...item,
          exclusion: {
            ...item.exclusion,
            check: false,
            id: item._id,
          },
        };
      });
      setexclusionsListdata(updatedData);
      exclusionsgradedAddData(updatedData);
      update_exclusion_list(updatedData, dataToSave);
    };
    const onError = (err: any) => {
      console.log("exclusion_list", err);
    };
    POLICIES_SERVICES.exclusion_list(onSuccess, onError, code);
  };


  const submithandle = () => {

    // Check the planType and update the corresponding data fields
    if (addPoliciesdata?.planType?.name === "FLAT") {
      let data: policiesDTONew = { ...addPoliciesdata };
      data = {
        ...data,
        benefitsDetailsGrade: [],
      };
      setAddPoliciesdata(data);
    } else if (addPoliciesdata?.planType?.name === "GRADED") {
      let data = { ...addPoliciesdata };
      data = {
        ...data,
        benefitsDetailsflat: {},
      };
      setAddPoliciesdata(data);
    }

    const data: any = addPoliciesdataWarn;

    // Check and update the data fields in the organisationDetails section
    data.organisationDetails.clientId = addPoliciesdata.organisationDetails
      .clientId
      ? false
      : true;
    data.organisationDetails.location =
      addPoliciesdata.organisationDetails.clientId &&
        addPoliciesdata.organisationDetails.location[0]?.name
        ? false
        : true;

    // Check and update the data fields in the policyCase section
    data.policyCase.policyNumber = addPoliciesdata.policyCase.policyNumber
      ? false
      : true;

    // Check and update the data fields in the insurerDetails section
    data.insurerDetails.name = addPoliciesdata.insurerDetails.name
      ? false
      : true;
    data.insurerDetails.office = addPoliciesdata.insurerDetails.office
      ? false
      : true;
    let check_status_agebandMin = false;
    if (
      addPoliciesdata.planType.coverageType.some((data) => data.name === "KIDS")
    ) {
      data.maxChildAge = addPoliciesdata.maxChildAge ? false : true;
      check_status_agebandMin = addPoliciesdata.maxChildAge ? false : true;;
    }

    let check_status_agebandMax = false;
    let check_status_graded = false;
    let check_status_rider = false;

    // Iterate over ageBand data and check/update the min and max fields
    const dataError = (addPoliciesdata.ageBand || []).map(
      (dataa: any, index: any) => {
        if (!data.ageBand) {
          data.ageBand = []; // Initialize ageBand if it doesn't exist
        }
        if (!data.ageBand[index]) {
          data.ageBand[index] = {}; // Initialize the element at index if it doesn't exist
        }
        data.ageBand[index].min = dataa?.min
          ? false
          : ((check_status_agebandMin = true), true);
        data.ageBand[index].max = dataa?.max
          ? false
          : ((check_status_agebandMax = true), true);
      }
    );
    if (addPoliciesdata.is_rider) {
      const datariderError = addPoliciesdata.rider.map(
        (dataa: any, index: any) => {
          data.rider[index].name = dataa.name
            ? false
            : ((check_status_rider = true), true);
          data.rider[index].description = dataa.description
            ? false
            : ((check_status_rider = true), true);
        }
      );
    }
    // Check and update the data fields based on the planType and productType
    if (addPoliciesdata?.planType?.name === "FLAT") {
      data.benefitsDetailsflat.features = addPoliciesdata.benefitsDetailsflat
        ?.features?.[0]?.value
        ? false
        : true;
      data.benefitsDetailsflat.exclusion = addPoliciesdata.benefitsDetailsflat
        ?.exclusion
        ? false
        : true;

      if (addPoliciesdata?.productType?.name === "Group Super TopUp") {
        data.benefitsDetailsflat.deductible = addPoliciesdata
          .benefitsDetailsflat?.deductible
          ? false
          : true;
      }
      if (addPoliciesdata?.productType?.name === "Group Term") {
        const benefits_detail_flatError =
          addPoliciesdata.benefits_detail_flat.map((dataa: any, index: any) => {
            if (dataa.enable) {
              data.benefits_detail_flat[index].amount = dataa.amount
                ? false
                : ((check_status_rider = true), true);
            } else {
              data.benefits_detail_flat[index].amount = false;
              check_status_rider = false;
            }
          });
        // if rider is true, check all warning
        // const rider_premium_flat =
        //   addPoliciesdata.is_rider &&
        //   addPoliciesdata.rider_premium_flat.map((dataa: any, index: any) => {
        //     dataa?.livesInfoFlat.map((liveData: any, indexLive: any) => {
        //       data.rider_premium_flat[index].livesInfoFlat[
        //         indexLive
        //       ].coverageTypeData[0].value = liveData?.coverageTypeData[0]?.value
        //           ? false
        //           : ((check_status_rider = true), true);
        //     });
        //     data.rider_premium_flat[index].premium_split = dataa.premium_split
        //       ? false
        //       : ((check_status_rider = true), true);
        //   });
        // if rider is false, make all warning false

        const rider_premium_flat_check =
          !addPoliciesdata.is_rider &&
          addPoliciesdata.rider_premium_flat.map((dataa: any, index: any) => {
            dataa?.livesInfoFlat.map((liveData: any, indexLive: any) => {
              data.rider_premium_flat[index].livesInfoFlat[
                indexLive
              ].coverageTypeData[0].value = false;
              check_status_rider = false;
            });
            data.rider_premium_flat[index].premium_split = false;
            check_status_rider = false;
          });
      }
    } else if (addPoliciesdata?.planType?.name === "GRADED") {
      const dataError = addPoliciesdata?.benefitsDetailsGrade?.map(
        (value: any, index: any) => {
          if (data.benefitsDetailsGrade[index]?.gradeDivisions) {
            data.benefitsDetailsGrade[index].designation = value.gradeDivisions[
              index
            ]
              ? false
              : ((check_status_graded = true), true);
          }

          if (addPoliciesdata?.productType?.name === "Group Super TopUp") {
            data.benefitsDetailsGrade[index].deductible =
              value?.deductible !== ""
                ? false
                : ((check_status_graded = true), true);
          }

          value?.features?.map((el: any, indexx: any) => {
            if (
              data.benefitsDetailsGrade[index]?.features &&
              data.benefitsDetailsGrade[index].features[indexx]
            ) {
              data.benefitsDetailsGrade[index].features[indexx].name = el?.name
                ? false
                : ((check_status_graded = true), true);
            }
          });

          value?.exclusion?.map((el: any, indexx: any) => {
            if (
              data.benefitsDetailsGrade[index]?.exclusion &&
              data.benefitsDetailsGrade[index].exclusion[indexx]
            ) {
              data.benefitsDetailsGrade[index].exclusion[indexx].name = el?.name
                ? false
                : ((check_status_graded = true), true);
            }
          });
        }
      );
    }

    // Check and update the remaining data fields
    data.premiumDetails.netPremium = addPoliciesdata.premiumDetails.netPremium
      ? false
      : true;
    data.premiumDetails.taxPercentage =
      addPoliciesdata.premiumDetails.taxPercentage?.length > 0 ? false : true;
    data.planType.familyDefinition = addPoliciesdata?.planType?.familyDefinition
      ? false
      : true;
    const startMoment = moment(addPoliciesdata.policyDetails.startDate);
    const endMoment = moment(addPoliciesdata.policyDetails.endDate);

    if (startMoment.isSame(endMoment)) {
      data.policyDetails.startDate = true;
    } else {
      data.policyDetails.startDate = false;
    }

    const startMomentEnroll = moment(addPoliciesdata.policy.enrolmentStartDate);
    const endMomentEnroll = moment(addPoliciesdata.policy.enrolmentEndDate);

    if (startMomentEnroll.isSame(endMomentEnroll)) {
      //temparory false
      data.policy.enrolmentStartDate = false;
      data.policy.enrolmentEndDate = false;
    } else {
      data.policy.enrolmentStartDate = false;
      data.policy.enrolmentEndDate = false;
    }

    data.policyDetails.startDate = addPoliciesdata?.policyDetails?.startDate
      ? false
      : true;
    data.policyDetails.endDate = addPoliciesdata?.policyDetails?.endDate
      ? false
      : true;

    data.policyDetails.cdBalance = addPoliciesdata.policyDetails.cdBalance
      ? false
      : true;
    data.policy.criticalCd = addPoliciesdata.policy.criticalCd ? false : true;

    if (addPoliciesdata.policy.nomination === true) {
      data.policy.maxNomination = addPoliciesdata.policy.maxNomination
        ? false
        : true;
    } else {
      data.policy.maxNomination = false;
    }

    if (addPoliciesdata.policy.isCorporateBuffer === true) {
      if (addPoliciesdata.policy.corporateBuffer) {
        data.policy.corporateBuffer =
          Number(addPoliciesdata.policy.corporateBuffer) < 1 ||
            addPoliciesdata.policy.corporateBuffer === ""
            ? true
            : false;
      }
    } else {
      data.policy.corporateBuffer = false;
    }

    if (addPoliciesdata.policy.healthCard === true) {
      data.policy.cardType =
        addPoliciesdata.policy.cardType &&
          addPoliciesdata.policy.healthCardIssued
          ? false
          : true;
    } else {
      data.policy.cardType = false;
    }

    setAddPoliciesdataWarn({ ...data });
    // Check if all fields are valid and update policy details if they are
    if (
      (addPoliciesdata?.planType?.name === "FLAT"
        ? data.benefitsDetailsflat.features === false &&
        data.benefitsDetailsflat.exclusion === false
        : check_status_graded === false) &&
      data.organisationDetails.clientId === false &&
      data.organisationDetails.location === false &&
      data.policyCase.policyNumber === false &&
      data.insurerDetails.name === false &&
      data.insurerDetails.office === false &&
      data.premiumDetails.netPremium === false &&
      data.premiumDetails.taxPercentage === false &&
      data.planType.familyDefinition === false &&
      data.policyDetails.startDate === false &&
      data.policy.enrolmentStartDate === false &&
      data.policy.enrolmentEndDate === false &&
      data.policyDetails.cdBalance === false &&
      data.policy.criticalCd === false &&
      data.policy.maxNomination === false &&
      data.policy.cardType === false &&
      data.policy.corporateBuffer === false &&
      check_status_agebandMin === false &&
      check_status_agebandMax === false &&
      check_status_rider === false &&
      errorMaxChildAge === false
    ) {
      update_policy_details();
    }
  };

  // Filtering data of get_dep_designation_salary
  const filterDropDownData = () => {
    const clonedArray = addPoliciesdata?.benefitsDetailsGrade?.map(
      (item: any) => ({
        ...item,
        gradeDivisions: item?.gradeDivisions?.map((division: any) => ({
          id: division.id || division.key,
          label: division.label || division.key,
        })),
      })
    );
    let updatedDropdownSalarydesignationDep = dropdownSalarydesignationDep;

    // Iterate over each grade in benefitsDetailsGrade
    clonedArray.forEach((grade: any) => {
      // Iterate over each designation in gradeDivisions
      grade?.gradeDivisions?.forEach((designation: any) => {
        // Filter the dropdown data based on the designation label
        updatedDropdownSalarydesignationDep =
          updatedDropdownSalarydesignationDep.filter((dropDownData: any) => {
            return dropDownData.label !== designation.label;
          });
      });
    });

    // Update the state with the filtered dropdown data
    setshowdropdownSalarydesignationDep(updatedDropdownSalarydesignationDep);
  };

  const get_dep_designation_salary = (clientID: any, type: any) => {
    const onSuccess = (res: any) => {
      if (type === "SALARY_BRACKETS") {
        let arr = [];

        let below = res.data.data[0].data[0].label.split("-");
        res.data.data[0].data.forEach((data: any) => {
          let splitdata = data.label.split("-");
          arr.push({
            id: `${formatCurrency(splitdata[0])} - ${formatCurrency(
              splitdata[1]
            )}`,
            label: `${formatCurrency(splitdata[0])} - ${formatCurrency(
              splitdata[1]
            )}`,
          });
        });

        let above =
          res.data.data[0].data[res.data.data[0].data.length - 1].label.split(
            "-"
          );
        arr.push({
          label: `Above - ${formatCurrency(above[1])}`,
          id: `Above - ${formatCurrency(above[1])}`,
        });

        setdropdownSalarydesignationDep(arr);
      } else {
        setdropdownSalarydesignationDep(res.data.data[0].data);
      }
    };
    const onError = (err: any) => {
      console.log(err);
    };
    EMPLOYEE_SERVICES.get_dep_designation_salary(
      onSuccess,
      onError,
      userState.userType.toLowerCase(),
      selectedClientId ? selectedClientId : clientID,
      type
    );
  };
  function toggleEnableRidersFeatureGrade(
    e: any,
    gradeIndex: number,
    riderIndex: number
  ) {
    const newData = structuredClone(addPoliciesdata);
    newData.benefits_detail_graded[gradeIndex].riders[riderIndex].enable =
      e.target.checked;
    if (!e.target.checked) {
      newData.benefits_detail_graded[gradeIndex].riders[riderIndex].amount = "";
    }
    setAddPoliciesdata(newData);
  }

  // get_policy_tax data
  const get_policy_tax = () => {
    const onSuccess = (res: any) => {
      let arr: any = [];
      res.data.data?.taxList?.map((data: any, index: any) => {
        arr.push({
          id: data.taxType,
          label: `${data.taxName}- ${data.taxRate}`,
          type: data.taxType,
        });
      });
      settaxDropDown(arr);
    };
    const onError = (err: any) => {
      console.log("get_policy_tax", err);
    };
    POLICIES_SERVICES.get_policy_tax(onSuccess, onError);
  };

  const gradedAddData = (data: any) => {
    const modifiedData = data.map((item: any) => ({
      ...item,
      features: item.features.map((feature: any) => ({
        ...feature,
        value: "",
        check: false,
        groupid: feature._id,
        id: feature.id,
      })),
    }));
    let updated_data: any = [];
    updated_data = [
      ...Array(addPoliciesdata?.benefitsDetailsGrade?.length),
    ].map(() => modifiedData);
    setfeaturesListdataGraded((prevState: any) => [
      ...prevState,
      ...updated_data,
    ]);
    //setfeaturesListdataGraded(updated_data);
  };
  const gradedAddDataCopy = () => {
    if (addPoliciesdata?.planType?.name === "GRADED") {
      const modifiedData = featuresListdata.map((item: any) => ({
        ...item,
        features: item.features.map((feature: any) => ({
          ...feature,
          value: "",
          check: false,
          groupid: featuresListdata._id,
          id: feature.id,
        })),
      }));
      let updated_data: any = [];
      updated_data = [
        ...Array(addPoliciesdata?.benefitsDetailsGrade?.length),
      ].map(() => modifiedData);

      if (featuresListdataGradedCopy.length > 0) {
        return featuresListdataGradedCopy;
      }
      return updated_data;
    }
  };

  const exclusionsgradedAddData = (updatedData: any) => {
    let updated_data: any = [];
    updated_data = [
      ...Array(addPoliciesdata?.benefitsDetailsGrade?.length + 1),
    ].map(() => updatedData);
    setexclusionsListdataGraded((prevState: any) => [
      ...prevState,
      updated_data,
    ]);
    // setexclusionsListdataGraded(updated_data);
  };
  const [polDetail, setPolDetail] = useState({
    policyCase: {
      policyNumber: "",
    },
  });
  // Get get_policy_details
  const get_policy_details = (id: any) => {
    const onSuccess = (res: any) => {
      setPolDetail(res.data.data);
      setpolicyStatus(res.data.data.status);
      setenrolmentStatus(res.data.data.enrolment_status);
      if (res.data.data?.benefits_detail_flat?.length < 1) {
        let newLivesSelectedGrade = res.data.data?.rider_premium_graded.map(
          (element: any) => ({ selectedGrade: 0 })
        );
        setlivesSelectedGrade(newLivesSelectedGrade);
      }
      const newExpanded = res.data.data.benefitsDetailsGrade.map(
        (item: any) => {
          return { expanded: false };
        }
      );
      setExpanded(newExpanded);
      seteditData(res.data.data);
      insurance_address_dropdownlist(res.data.data.insurerDetails.id);
      setPremiumPaymentType(res.data.data.payType.type);
      get_organization_data(res.data.data?.organisationDetails?.id);
      get_client_location_list(res.data.data?.organisationDetails?.id);
      setSelectedClientId(res.data.data?.organisationDetails?.id);
      if (res.data.data?.planType.gradeCriteria === "Department") {
        get_dep_designation_salary(
          res.data.data?.organisationDetails?.id,
          "DEPARTMENTS"
        );
      } else if (res.data.data?.planType.gradeCriteria === "Designation") {
        get_dep_designation_salary(
          res.data.data?.organisationDetails?.id,
          "DESIGNATIONS"
        );
      } else if (res.data.data?.planType.gradeCriteria === "Salary Bracket") {
        get_dep_designation_salary(
          res.data.data?.organisationDetails?.id,
          "SALARY_BRACKETS"
        );
      }
      insurance_name_dropdownlist("GENERAL");
      setbasicConfig_data([
        {
          key: res.data.data?.planType?.basicConfig,
          value: res.data.data?.planType?.basicConfig,
        },
      ]);
      setindvConfig_data([
        {
          key: res.data.data?.planType?.parentConfig,
          value: res.data.data?.planType?.parentConfig,
        },
      ]);
      if (res.data.data?.planType?.name === "GRADED") {
        setPlanType(1);
      }
      setInsurerAddressList([
        {
          key: res.data.data?.insurerDetails?.office,
          value: res.data.data?.insurerDetails?.office,
        },
      ]);

      let dataWarn: any = { ...addPoliciesdataWarn };
      // res.data.data.benefitsDetailsGrade = [
      //   ...res.data.data.benefitsDetailsGrade,
      //   {
      //     designation: "",
      //     deductible:'',
      //     name: "",
      //     sumInsured: "",
      //     features: [
      //       {
      //         name: "",
      //         value: "",
      //         featureId: "",
      //         groupId: "",
      //       },
      //     ],
      //     exclusion: [
      //       {
      //         name: "",
      //         description: "",
      //         exclusionId: "",
      //       },
      //     ],
      //   },
      // ];
      dataWarn.benefitsDetailsGrade = [
        ...dataWarn.benefitsDetailsGrade,
        {
          designation: false,
          deductible: false,
          name: false,
          sumInsured: false,
          features: [
            {
              name: false,
              value: false,
              featureId: false,
              groupId: false,
            },
          ],
          exclusion: [
            {
              name: false,
              description: false,
              exclusionId: false,
            },
          ],
        },
      ];
      dataWarn = {
        ...dataWarn,
        rider: res.data.data.rider.map(() => ({
          name: false,
          description: false,
        })),
        benefits_detail_flat: res.data.data.benefits_detail_flat.map(() => ({
          label: false,
          enable: false,
          amount: false,
          description: false,
        })),
      };

      let dataToSave = res.data.data;

      if (
        res.data.data.payType.data.length !==
        res.data.data.planType.coverageType.length
      ) {
        const newData = res.data.data.planType.coverageType.map((item: any) => {
          return {
            name: item.name,
            value: 0,
          };
        });

        dataToSave = {
          ...res.data.data,
          payType: {
            data: newData,
          },
        };
      }

      dataToSave = {
        ...dataToSave,
        organisationDetails: {
          ...dataToSave.organisationDetails,
          location: dataToSave.organisationDetails.location.map(
            (locationData: any) => {
              return { name: locationData.name, id: locationData.id };
            }
          ),
        },
      };
      setAddPoliciesdata(dataToSave);
      setAddPoliciesdataWarn(dataWarn);
      gradedAddData(featuresListdata);
      exclusionsgradedAddData(exclusionsListdata);
      // Api for feature lists
      const num =
        res.data.data?.productType?.name === "Group Term"
          ? 0
          : res.data.data?.productType?.name === "Group Health"
            ? 1
            : res.data.data?.productType?.name === "Group Travel"
              ? 2
              : res.data.data?.productType?.name === "Group Personal Accident"
                ? 3
                : res.data.data?.productType?.name === "Group Critical Illness"
                  ? 4
                  : res.data.data?.productType?.name === "Group Super TopUp"
                    ? 5
                    : null;
      features_list(num, dataToSave.benefitsDetailsflat.features);
      // Api for exclusion list
      exclusion_list(num, dataToSave);
    };
    const onError = (err: any) => {
      console.log("get_policy_details", err);
    };
    POLICIES_SERVICES.get_policy_details(onSuccess, onError, id);
  };
  function toggleEnableRiders(e: any) {
    let data = { ...addPoliciesdata };
    data = {
      ...data,
      is_rider: e.target.checked,
    };
    if (!e.target.checked) {
      data = {
        ...data,
        rider: [],
      };
    }
    if (e.target.checked) {
      data = {
        ...data,
        rider: [
          {
            name: "",
            description: "",
          },
        ],
      };
    }
    setAddPoliciesdata(data);
  }
  function toggleEnableRidersFeaure(e: any, index: number) {
    let data = { ...addPoliciesdata };
    data.benefits_detail_flat[index].enable = e.target.checked;

    if (!e.target.checked) {
      data.benefits_detail_flat[index].amount = "";
    }
    if (e.target.checked) {
    }
    setAddPoliciesdata(data);
  }

  const organizationDetails = useRef(null);
  const products = useRef(null);
  const policyCase = useRef(null);
  const insurerDetail = useRef(null);
  const plantype = useRef(null);
  const premiumPayment = useRef(null);
  const Riders = useRef(null);
  const ageBand = useRef(null);
  const FeaturesExclusions = useRef(null);
  const premiumdetails = useRef(null);
  const planSetting = useRef(null);
  const detailspolicy = useRef(null);
  const RiderDetail = useRef(null);
  const relationLives = useRef(null);

  return (
    <>
      {showLoader && <NewLoader />}
      <>
        <Box className="layoutWrapper">
          {/* Page header */}
          <Grid container spacing={3} className="pageHeader">
            <Grid xs={12} className="pageTitle">
              <Link
                className="backStep"
                onClick={() => {
                  navigate(-1);
                }}
              >
                <ChevronLeftIcon />
              </Link>
              <div>
                <h3>Edit Policy</h3>
                <p>Policy No : {polDetail?.policyCase?.policyNumber}</p>
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid xs={12}>
              <>
                <Grid container spacing={3}>
                  <Grid xs className="leftSection h-100">
                    <Link
                      className={
                        sectionStatus === "organizationDetails"
                          ? "active sectionLink organizationDetails"
                          : "sectionLink organizationDetails"
                      }
                      onClick={() => {
                        scrollToSection(organizationDetails);
                        SetSectionStatus("organizationDetails");
                      }}
                    >
                      <span>Organization Details</span>
                    </Link>
                    <Link
                      className={
                        sectionStatus === "products"
                          ? "active sectionLink products"
                          : "sectionLink products"
                      }
                      onClick={() => {
                        scrollToSection(products);
                        SetSectionStatus("products");
                      }}
                    >
                      <span>Product Type</span>
                    </Link>
                    <Link
                      className={
                        sectionStatus === "policyCase"
                          ? "active sectionLink policyCase"
                          : "sectionLink policyCase"
                      }
                      onClick={() => {
                        scrollToSection(policyCase);
                        SetSectionStatus("policyCase");
                      }}
                    >
                      <span>Policy Case</span>
                    </Link>
                    <Link
                      className={
                        sectionStatus === "insurerDetail"
                          ? "active sectionLink insurerDetail"
                          : "sectionLink insurerDetail"
                      }
                      onClick={() => {
                        scrollToSection(insurerDetail);
                        SetSectionStatus("insurerDetail");
                      }}
                    >
                      <span>Insurer Details</span>
                    </Link>
                    <Link
                      className={
                        sectionStatus === "plantype"
                          ? "active sectionLink plantype"
                          : "sectionLink plantype"
                      }
                      onClick={() => {
                        handleClicks(sectionStatus);
                        scrollToSection(plantype);
                        SetSectionStatus("plantype");
                      }}
                    >
                      {" "}
                      <span>Plan Type</span>
                    </Link>
                    <Link
                      className={
                        sectionStatus === "premiumPayment"
                          ? "active sectionLink premiumPayment"
                          : "sectionLink premiumPayment"
                      }
                      onClick={() => {
                        handleClicks(sectionStatus);
                        scrollToSection(premiumPayment);
                        SetSectionStatus("premiumPayment");
                      }}
                    >
                      {" "}
                      <span>Premium Payment Configuration</span>
                    </Link>
                    <Link
                      className={
                        sectionStatus === "relationLives"
                          ? "active sectionLink relationLives"
                          : "sectionLink relationLives"
                      }
                      onClick={() => {
                        handleClicks(sectionStatus);
                        scrollToSection(relationLives);
                        SetSectionStatus("relationLives");
                      }}
                    >
                      <span>Relationship-Lives Information</span>
                    </Link>
                    {addPoliciesdata.productType.name === "Group Term" && (
                      <Link
                        className={
                          sectionStatus === "Riders"
                            ? "active sectionLink Riders"
                            : "sectionLink Riders"
                        }
                        onClick={() => {
                          handleClicks(sectionStatus);
                          scrollToSection(Riders);
                          SetSectionStatus("Riders");
                        }}
                      >
                        {" "}
                        <span>Riders</span>
                      </Link>
                    )}
                    <Link
                      className={
                        sectionStatus === "ageBand"
                          ? "active sectionLink ageBand"
                          : "sectionLink ageBand"
                      }
                      onClick={() => {
                        handleClicks(sectionStatus);
                        scrollToSection(ageBand);
                        SetSectionStatus("ageBand");
                      }}
                    >
                      {" "}
                      <span>Age Bands for Lives</span>
                    </Link>
                    {planType === 0 || planType === 1 || planType === 2 ? (
                      <Link
                        className={
                          sectionStatus === "FeaturesExclusions"
                            ? "active sectionLink FeaturesExclusions"
                            : "sectionLink FeaturesExclusions"
                        }
                        onClick={() => {
                          handleClicks(sectionStatus);
                          scrollToSection(FeaturesExclusions);
                          SetSectionStatus("FeaturesExclusions");
                        }}
                      >
                        {" "}
                        <span>SI/SA Features & Exclusions</span>
                      </Link>
                    ) : null}
                    <Link
                      className={
                        sectionStatus === "premiumdetails"
                          ? "active sectionLink premiumdetails"
                          : "sectionLink premiumdetails"
                      }
                      onClick={() => {
                        handleClicks(sectionStatus);
                        scrollToSection(premiumdetails);
                        SetSectionStatus("premiumdetails");
                      }}
                    >
                      <span>Premium Details</span>
                    </Link>
                    <Link
                      className={
                        sectionStatus === "planSetting"
                          ? "active sectionLink planSetting"
                          : "sectionLink planSetting"
                      }
                      onClick={() => {
                        handleClicks(sectionStatus);
                        scrollToSection(planSetting);
                        SetSectionStatus("planSetting");
                      }}
                    >
                      {" "}
                      <span>Plans Settings</span>
                    </Link>
                    <Link
                      className={
                        sectionStatus === "detailspolicy"
                          ? "active sectionLink detailspolicy"
                          : "sectionLink detailspolicy"
                      }
                      onClick={() => {
                        handleClicks(sectionStatus);
                        scrollToSection(detailspolicy);
                        SetSectionStatus("detailspolicy");
                      }}
                    >
                      {" "}
                      <span>Policy Details</span>
                    </Link>
                    {addPoliciesdata.is_rider && (
                      <Link
                        className={
                          sectionStatus === "RiderDetail"
                            ? "active sectionLink RiderDetail"
                            : "sectionLink RiderDetail"
                        }
                        onClick={() => {
                          handleClicks(sectionStatus);
                          scrollToSection(RiderDetail);
                          SetSectionStatus("RiderDetail");
                        }}
                      >
                        {" "}
                        <span>Rider Details</span>
                      </Link>
                    )}
                  </Grid>
                  <Grid xs className="rightSection h-100">
                    <>
                      {/* Organization Details */}
                      <Grid container spacing={3} className="px-0">
                        <Grid xs={12}>
                          <Box
                            className="detailSection mb-0"
                            ref={organizationDetails}
                            onMouseEnter={() => {
                              SetSectionStatus("organizationDetails");
                            }}
                          >
                            <div className="sectionTitle">
                              <h4>Organization Details</h4>
                            </div>

                            <Grid
                              xs={12}
                              className="radio_group no-border mt-3"
                            >
                              <div className="client_div">
                                <SearchDropdown
                                  class_name="inputField"
                                  title="Search Client ID"
                                  value={`${addPoliciesdata?.organisationDetails?.clientId}- ${addPoliciesdata?.organisationDetails?.organisationName}`}
                                  attrName={[
                                    "addPoliciesdata",
                                    "organisationDetails",
                                    "clientId",
                                    "dropdown",
                                  ]}
                                  value_update={updateMasterState}
                                  data={clientListdata}
                                  warn_status={
                                    addPoliciesdataWarn.organisationDetails
                                      .clientId
                                  }
                                  error_message="Please select client"
                                  required={true}
                                  disabled={
                                    isPolicyAttachedToMember ||
                                    policyStatus !== 20
                                  }
                                />
                              </div>
                            </Grid>
                            {addPoliciesdata?.organisationDetails?.clientId ? (
                              <>
                                <Grid container spacing={3}>
                                  <Grid xs={12}>
                                    <p className="clientsID">
                                      Client ID{" "}
                                      <span>
                                        {organizationFullDetails?.clientId}
                                      </span>
                                    </p>
                                    {/* <p className="clientsID">
                          Parent Client ID <span>CLT23456789</span>
                        </p>
                        <p className="clientsID">
                          Parent Organization <span>CLT23456789</span>
                        </p> */}
                                  </Grid>
                                </Grid>
                                <Grid container spacing={3}>
                                  <Grid xs={3}>
                                    <p className="companyDetails">
                                      Organization Name
                                      <span>
                                        {organizationFullDetails?.Organisation}
                                      </span>
                                    </p>
                                  </Grid>
                                  <Grid xs={3}>
                                    <p className="companyDetails">
                                      Ownership{" "}
                                      <span>
                                        {organizationFullDetails?.Ownership}
                                      </span>
                                    </p>
                                  </Grid>
                                  <Grid xs={3}>
                                    <p className="companyDetails">
                                      Industry - Sub-industry
                                      <span>
                                        {
                                          organizationFullDetails[
                                          "Sub-Industry"
                                          ]
                                        }
                                      </span>
                                    </p>
                                  </Grid>
                                  <Grid xs={3}>
                                    <p className="contactNumber">
                                      <PhoneIphoneIcon />
                                      {
                                        organizationFullDetails[
                                        "Organization Phone Number"
                                        ]
                                      }
                                    </p>
                                    <Link
                                      className="email_list"
                                      href=""
                                      target="_blank"
                                    >
                                      <EmailIcon />
                                      {
                                        organizationFullDetails[
                                        "Organization Email"
                                        ]
                                      }
                                    </Link>
                                  </Grid>
                                </Grid>
                                <Grid container spacing={3}>
                                  <Grid xs={12}>
                                    <MultipleSearchDropdown
                                      class_name="inputField"
                                      title="Location Name"
                                      value={
                                        addPoliciesdata?.organisationDetails
                                          ?.location
                                      }
                                      attrName={[
                                        "addPoliciesdata",
                                        "organisationDetails",
                                        "location",
                                        "multiselect",
                                      ]}
                                      value_update={updateMasterState}
                                      multiple_data={clientLocationList}
                                      required
                                      warn_status={
                                        addPoliciesdataWarn.organisationDetails
                                          .location
                                      }
                                      disabled={
                                        isPolicyAttachedToMember ||
                                        policyStatus !== 20
                                      }
                                      error_messg="Please select location"
                                    />
                                  </Grid>
                                </Grid>
                              </>
                            ) : (
                              ""
                            )}
                          </Box>
                        </Grid>
                      </Grid>
                      {/* Product Type */}
                      <Grid container spacing={3} className="px-0">
                        <Grid xs={12}>
                          <Box
                            className="detailSection mb-0"
                            onMouseEnter={() => {
                              SetSectionStatus("products");
                            }}
                            ref={products}
                          >
                            <div className="sectionTitle">
                              <h4>Product Type</h4>
                            </div>
                            <Grid container spacing={3}>
                              <Grid className="productTypeSection">
                                <div className="radioBox productcategory">
                                  <input
                                    type="radio"
                                    value="Group Term"
                                    id="individual"
                                    name="plantype"
                                    checked={
                                      addPoliciesdata.productType.name ===
                                      "Group Term"
                                    }
                                    onChange={(e) => {
                                      updateMasterState(
                                        [
                                          "addPoliciesdata",
                                          "productType",
                                          "LIFE",
                                          "radio",
                                        ],
                                        e.target.value
                                      );
                                    }}
                                    disabled={
                                      !(
                                        policyStatus === 20 &&
                                        isTodayBeforeStartDate
                                      )
                                    }
                                  />
                                  <label className="producttype grpTerm">
                                    <hr />
                                    Group Term Insurance
                                    <p>
                                      This plan aim to offer financial
                                      protection to the nominee or the
                                      beneficiary.
                                    </p>
                                  </label>
                                </div>
                              </Grid>
                              <Grid className="productTypeSection">
                                <div className="radioBox productcategory">
                                  <input
                                    type="radio"
                                    value="Group Super TopUp"
                                    id="individual"
                                    name="plantype"
                                    checked={
                                      addPoliciesdata.productType.name ===
                                      "Group Super TopUp"
                                    }
                                    onChange={(e) => {
                                      updateMasterState(
                                        [
                                          "addPoliciesdata",
                                          "productType",
                                          "TOPUP",
                                          "radio",
                                        ],
                                        e.target.value
                                      );
                                    }}
                                    disabled={
                                      !(
                                        policyStatus === 20 &&
                                        isTodayBeforeStartDate
                                      )
                                    }
                                  />
                                  <label className="producttype grptopup">
                                    <hr />
                                    Group Super TopUp
                                    <p>
                                      This plan aim to offer financial
                                      protection to the nominee or the
                                      beneficiary.
                                    </p>
                                  </label>
                                </div>
                              </Grid>
                              <Grid className="productTypeSection">
                                <div className="radioBox">
                                  <input
                                    type="radio"
                                    value="Group Health"
                                    id="individual1"
                                    name="plantype"
                                    checked={
                                      addPoliciesdata.productType.name ===
                                      "Group Health"
                                    }
                                    onChange={(e) => {
                                      updateMasterState(
                                        [
                                          "addPoliciesdata",
                                          "productType",
                                          "GENERAL",
                                          "radio",
                                        ],
                                        e.target.value
                                      );
                                    }}
                                    disabled={
                                      !(
                                        policyStatus === 20 &&
                                        isTodayBeforeStartDate
                                      )
                                    }
                                  />
                                  <label className="producttype grpHealth">
                                    <hr />
                                    Group Health Insurance
                                    <p>
                                      Group critical Illness helps employees
                                      prepare financially for an unexpected life
                                      event.
                                    </p>
                                  </label>
                                </div>
                              </Grid>
                              {/* <Grid className="productTypeSection">
                            <div className="radioBox">
                              <input
                                type="radio"
                                value="Group Travel"
                                id="individual2"
                                name="plantype"
                                checked={addPoliciesdata.productType.name ==="Group Travel" }
                                onChange={(e) => {
                                  updateMasterState(
                                    [
                                      "addPoliciesdata",
                                      "productType",
                                      "HEALTH",
                                      "radio",
                                    ],
                                    e.target.value
                                  );
                                }}
                                                                                                    disabled={!(policyStatus === 20 && isTodayBeforeStartDate)}


                                disabled
                              />
                              <label className="producttype grpTravel">
                                <hr />
                                Group Travel Insurance
                                <p>
                                  A group health insurance plan provides medical
                                  coverage for a group of people.
                                </p>
                              </label>
                            </div>
                          </Grid> */}
                              <Grid className="productTypeSection">
                                <div className="radioBox">
                                  <input
                                    type="radio"
                                    value="Group Personal Accident"
                                    id="individual3"
                                    name="plantype"
                                    checked={
                                      addPoliciesdata.productType.name ===
                                      "Group Personal Accident"
                                    }
                                    onChange={(e) => {
                                      updateMasterState(
                                        [
                                          "addPoliciesdata",
                                          "productType",
                                          "TRAVEL",
                                          "radio",
                                        ],
                                        e.target.value
                                      );
                                    }}
                                    disabled={
                                      !(
                                        policyStatus === 20 &&
                                        isTodayBeforeStartDate
                                      )
                                    }
                                  />
                                  <label className="producttype grpPA">
                                    <hr />
                                    Group Personal Accident
                                    <p>
                                      That is offered to protect employees
                                      against the medical expenses incurred.
                                    </p>
                                  </label>
                                </div>
                              </Grid>
                              <Grid className="productTypeSection">
                                <div className="radioBox">
                                  <input
                                    type="radio"
                                    value="Group Critical Illness"
                                    id="individual4"
                                    name="plantype"
                                    checked={
                                      addPoliciesdata.productType.name ===
                                      "Group Critical Illness"
                                    }
                                    onChange={(e) => {
                                      updateMasterState(
                                        [
                                          "addPoliciesdata",
                                          "productType",
                                          "ACCIDENT",
                                          "radio",
                                        ],
                                        e.target.value
                                      );
                                    }}
                                    disabled={
                                      !(
                                        policyStatus === 20 &&
                                        isTodayBeforeStartDate
                                      )
                                    }
                                  />
                                  <label className="producttype grpCI">
                                    <hr />
                                    Group Critical Illness
                                    <p>
                                      Group critical Illness helps employees
                                      prepare financially for an unexpected life
                                      event.
                                    </p>
                                  </label>
                                </div>
                              </Grid>
                            </Grid>
                          </Box>
                        </Grid>
                      </Grid>

                      {/* Policy Case */}
                      <Grid container spacing={3} className="px-0">
                        <Grid xs={12} lg={6} xl={6}>
                          <Box
                            className="detailSection mb-0"
                            ref={policyCase}
                            onMouseEnter={() => {
                              SetSectionStatus("policyCase");
                            }}
                          >
                            <div className="sectionTitle">
                              <h4>Policy Case</h4>
                            </div>
                            <Grid container spacing={3}>
                              <Grid className="PolicyTypeSection">
                                <div
                                  className="radioBox policytype"
                                  onClick={() => {
                                    setPolicyType(0);
                                  }}
                                >
                                  <input
                                    type="radio"
                                    id="fresh"
                                    value="FRESH"
                                    name="planstype"
                                    disabled={
                                      !(
                                        policyStatus === 20 &&
                                        isTodayBeforeStartDate
                                      )
                                    }
                                    checked={
                                      addPoliciesdata.policyCase.name ===
                                      "FRESH"
                                    }
                                    onChange={(e) => {
                                      updateMasterState(
                                        [
                                          "addPoliciesdata",
                                          "policyCase",
                                          "FRESH",
                                          "radio",
                                        ],
                                        e.target.value
                                      );
                                    }}
                                  />
                                  <label className="policytype fresh">
                                    <hr />
                                    Fresh Policy
                                    <p>Buying policy for the first time.</p>
                                  </label>
                                </div>
                              </Grid>
                              {/* <>
                              <Grid className="PolicyTypeSection">
                                <div
                                  className="radioBox"
                                  onClick={() => {
                                    setPolicyType(1);
                                  }}
                                >
                                  <input
                                    type="radio"
                                    id="renewal"
                                    name="planstype"
                                    disabled={
                                      !(
                                        policyStatus === 20 &&
                                        isTodayBeforeStartDate
                                      )
                                    }
                                    value="RENEWAL"
                                    checked={
                                      addPoliciesdata.policyCase.name ===
                                      "RENEWAL"
                                    }
                                    onChange={(e) => {
                                      updateMasterState(
                                        [
                                          "addPoliciesdata",
                                          "policyCase",
                                          "RENEWAL",
                                          "radio",
                                        ],
                                        e.target.value
                                      );
                                    }}
                                  />
                                  <label className="policytype renew">
                                    <hr />
                                    Renewal Policy
                                    <p>Existing Policy will be expiring.</p>
                                  </label>
                                </div>
                              </Grid>
                            </> */}
                            </Grid>
                            <Grid xs={6}>
                              <RKTextField
                                class_name="inputField"
                                title="Policy Number"
                                value={
                                  addPoliciesdata?.policyCase?.policyNumber
                                }
                                attrName={[
                                  "addPoliciesdata",
                                  "policyCase",
                                  "policyNumber",
                                  "input_field",
                                ]}
                                value_update={updateMasterState}
                                warn_status={
                                  addPoliciesdataWarn.policyCase.policyNumber
                                }
                                error_messg="Enter your policy number"
                                required
                                disabled={
                                  isPolicyAttachedToMember ||
                                  !(
                                    policyStatus === 20 &&
                                    isTodayBeforeStartDate
                                  )
                                }
                              />
                            </Grid>
                          </Box>
                        </Grid>
                      </Grid>
                      {/* policy history */}
                      {policyType === 1 ? <PolicyHistory /> : null}

                      {/*Insurer Section */}
                      <Grid container spacing={3} className="px-0">
                        <Grid xs={12}>
                          <Box
                            className="detailSection mb-0"
                            ref={insurerDetail}
                            onMouseEnter={() => {
                              SetSectionStatus("insurerDetail");
                            }}
                          >
                            <div className="sectionTitle">
                              <h4>Insurer Details</h4>
                            </div>
                            <Box className="insurerView">
                              <Grid container spacing={3}>
                                <Grid xs={6}>
                                  <SearchDropdown
                                    class_name="inputField"
                                    title="Insurer"
                                    value={
                                      addPoliciesdata?.insurerDetails?.name
                                    }
                                    attrName={[
                                      "addPoliciesdata",
                                      "insurerDetails",
                                      "name",
                                      "dropdown",
                                    ]}
                                    value_update={updateMasterState}
                                    data={insurerList}
                                    warn_status={
                                      addPoliciesdataWarn.insurerDetails.name
                                    }
                                    error_message="Select your Insurer"
                                    required
                                    disabled={
                                      isPolicyAttachedToMember ||
                                      !(
                                        policyStatus === 20 &&
                                        isTodayBeforeStartDate
                                      )
                                    }
                                  />
                                </Grid>
                                <Grid xs={6}>
                                  <SelectDropdown
                                    class_name="inputField"
                                    title="Insurer Office"
                                    attrName={[
                                      "addPoliciesdata",
                                      "insurerDetails",
                                      "office",
                                      "dropdown",
                                    ]}
                                    value={
                                      addPoliciesdata?.insurerDetails?.office
                                    }
                                    value_update={updateMasterState}
                                    dropdown_data={insurerAddressList}
                                    warn_status={
                                      addPoliciesdataWarn.insurerDetails.office
                                    }
                                    error_messg="Select insurer's office"
                                    required
                                    disabled={
                                      isPolicyAttachedToMember ||
                                      !(
                                        policyStatus === 20 &&
                                        isTodayBeforeStartDate
                                      )
                                    }
                                  />
                                </Grid>
                                <Grid xs={6}>
                                  <SearchDropdown
                                    class_name="inputField"
                                    title="TPA"
                                    value={addPoliciesdata.tpa.name}
                                    attrName={[
                                      "addPoliciesdata",
                                      "tpa",
                                      "name",
                                      "dropdown",
                                    ]}
                                    value_update={updateMasterState}
                                    data={tpaList}
                                    warn_status={addPoliciesdataWarn.tpa.name}
                                    error_message="Select your TPA"
                                    required
                                    disabled={
                                      isPolicyAttachedToMember ||
                                      !(
                                        policyStatus === 20 &&
                                        isTodayBeforeStartDate
                                      )
                                    }
                                  />
                                </Grid>
                              </Grid>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                      {/* Plan Type */}
                      <Grid container spacing={3} className="px-0">
                        <Grid xs={12}>
                          <Box
                            className="detailSection mb-0"
                            onMouseEnter={() => {
                              SetSectionStatus("plantype");
                            }}
                            ref={plantype}
                          >
                            <div className="sectionTitle">
                              <h4>Plan Type</h4>
                            </div>
                            <Grid container spacing={3}>
                              <Grid xs={4} className="planTypeSection">
                                <div className="radioBox plantype">
                                  <input
                                    type="radio"
                                    value="FLAT"
                                    name="radioButtonName"
                                    checked={
                                      addPoliciesdata.planType.name === "FLAT"
                                    }
                                    onClick={() => {
                                      setPlanType(0);
                                    }}
                                    onChange={(e) => {
                                      updateMasterState(
                                        [
                                          "addPoliciesdata",
                                          "planType",
                                          "name",
                                          "radio",
                                        ],
                                        e.target.value
                                      );
                                    }}
                                    disabled={
                                      isPolicyAttachedToMember ||
                                      !(
                                        policyStatus === 20 &&
                                        isTodayBeforeStartDate
                                      )
                                    }
                                  />
                                  <label>
                                    Flat Coverage
                                    <span>
                                      Common average amount for all employees in
                                      the policy.
                                    </span>
                                  </label>
                                </div>
                              </Grid>
                              <Grid xs={4} className="planTypeSection">
                                <div className="radioBox plantype">
                                  <input
                                    type="radio"
                                    value="GRADED"
                                    name="radioButtonName"
                                    checked={
                                      addPoliciesdata.planType.name === "GRADED"
                                    }
                                    onClick={() => {
                                      setPlanType(1);
                                    }}
                                    onChange={(e) => {
                                      updateMasterState(
                                        [
                                          "addPoliciesdata",
                                          "planType",
                                          "name",
                                          "radio",
                                        ],
                                        e.target.value
                                      );
                                    }}
                                    disabled={
                                      isPolicyAttachedToMember ||
                                      !(
                                        policyStatus === 20 &&
                                        isTodayBeforeStartDate
                                      )
                                    }
                                  />
                                  <label>
                                    Graded Coverage
                                    <span>
                                      Different average amount for different
                                      employees in the policy.
                                    </span>
                                  </label>
                                </div>
                              </Grid>
                              {/* <Grid xs={4} className="planTypeSection">
                            <div className="radioBox plantype">
                              <input
                                type="radio"
                                value="FLEXI"
                                name="radioButtonName"
                                onClick={() => {
                                  setPlanType(2);
                                }}
                                onChange={(e) => {
                                  updateMasterState(
                                    [
                                      "addPoliciesdata",
                                      "planType",
                                      "name",
                                      "radio",
                                    ],
                                    e.target.value
                                  );
                                }}
                              />
                              <label>
                                Flexi
                                <span>
                                  Lorem ipsum dolor sit amet this ready only text
                                  here.
                                </span>
                              </label>
                            </div>
                          </Grid> */}
                              {/* Coverage Section */}
                              <Grid xs={12}>
                                {planType === 0 || planType === 1 ? (
                                  <Box className="coverageDetail">
                                    <Grid container spacing={3}>
                                      <Grid xs={12}>
                                        <h5 className="empsecHeading">
                                          Select Coverage Type
                                        </h5>
                                      </Grid>
                                      <Grid xs={12}>
                                        <ul
                                          id="checkboxContainer"
                                          className="coverageAmt"
                                        >
                                          <li>
                                            <div
                                              className={
                                                addPoliciesdata?.planType?.coverageType.some(
                                                  (item: any) =>
                                                    item.name === "EMPLOYEE"
                                                )
                                                  ? "Checkbox_two checked"
                                                  : "Checkbox_two"
                                              }
                                            >
                                              <input
                                                type="checkbox"
                                                name="check"
                                                value="EMPLOYEE"
                                                onChange={(e) => {
                                                  updateMasterState(
                                                    [
                                                      "addPoliciesdata",
                                                      "planType",
                                                      "coverageType",
                                                      "checkbox",
                                                    ],
                                                    e.target.value
                                                  );
                                                }}
                                                checked
                                                disabled={
                                                  isPolicyAttachedToMember
                                                }
                                              />
                                              <label className="employee">
                                                <p>Employee</p>
                                              </label>
                                            </div>
                                          </li>
                                          {addPoliciesdata.productType.name !==
                                            "Group Term" && (
                                              <>
                                                <li>
                                                  <div
                                                    className={
                                                      addPoliciesdata?.planType?.coverageType.some(
                                                        (item: any) =>
                                                          item.name === "SPOUSE"
                                                      )
                                                        ? "Checkbox_two checked"
                                                        : "Checkbox_two"
                                                    }
                                                  >
                                                    {addPoliciesdata.planType.coverageType.some(
                                                      (obj) => obj.code === 1
                                                    ) ? (
                                                      <input
                                                        type="checkbox"
                                                        name="check"
                                                        value="SPOUSE"
                                                        checked
                                                        disabled={
                                                          isPolicyAttachedToMember
                                                        }
                                                        onChange={(e) => {
                                                          updateMasterState(
                                                            [
                                                              "addPoliciesdata",
                                                              "planType",
                                                              "coverageType",
                                                              "checkbox",
                                                            ],
                                                            e.target.value
                                                          );
                                                        }}
                                                      />
                                                    ) : (
                                                      <input
                                                        type="checkbox"
                                                        name="check"
                                                        value="SPOUSE"
                                                        disabled={
                                                          isPolicyAttachedToMember
                                                        }
                                                        onChange={(e) => {
                                                          updateMasterState(
                                                            [
                                                              "addPoliciesdata",
                                                              "planType",
                                                              "coverageType",
                                                              "checkbox",
                                                            ],
                                                            e.target.value
                                                          );
                                                        }}
                                                      />
                                                    )}

                                                    <label className="spouse">
                                                      <p>Spouse</p>
                                                    </label>
                                                  </div>
                                                </li>
                                                <li>
                                                  <div
                                                    className={
                                                      addPoliciesdata?.planType?.coverageType.some(
                                                        (item: any) =>
                                                          item.name === "KIDS"
                                                      )
                                                        ? "Checkbox_two checked"
                                                        : "Checkbox_two"
                                                    }
                                                  >
                                                    <input
                                                      type="checkbox"
                                                      name="check"
                                                      disabled={
                                                        isPolicyAttachedToMember
                                                      }
                                                      value="KIDS"
                                                      onChange={(e) => {
                                                        updateMasterState(
                                                          [
                                                            "addPoliciesdata",
                                                            "planType",
                                                            "coverageType",
                                                            "checkbox",
                                                          ],
                                                          e.target.value
                                                        );
                                                      }}
                                                    />
                                                    <label className="kids">
                                                      <p>Kids</p>
                                                    </label>
                                                  </div>
                                                </li>
                                                <li>
                                                  <div
                                                    className={
                                                      addPoliciesdata?.planType?.coverageType.some(
                                                        (item: any) =>
                                                          item.name === "PARENTS"
                                                      )
                                                        ? "Checkbox_two checked"
                                                        : "Checkbox_two"
                                                    }
                                                  >
                                                    <input
                                                      type="checkbox"
                                                      name="check"
                                                      disabled={
                                                        isPolicyAttachedToMember
                                                      }
                                                      value="PARENTS"
                                                      onChange={(e) => {
                                                        updateMasterState(
                                                          [
                                                            "addPoliciesdata",
                                                            "planType",
                                                            "coverageType",
                                                            "checkbox",
                                                          ],
                                                          e.target.value
                                                        );
                                                      }}
                                                    />
                                                    <label className="parents">
                                                      <p>Parents</p>
                                                    </label>
                                                  </div>
                                                </li>
                                                <li>
                                                  <div
                                                    className={
                                                      addPoliciesdata?.planType?.coverageType.some(
                                                        (item: any) =>
                                                          item.name ===
                                                          "PARENTSINLAW"
                                                      )
                                                        ? "Checkbox_two checked"
                                                        : "Checkbox_two"
                                                    }
                                                  >
                                                    <input
                                                      type="checkbox"
                                                      name="check"
                                                      value="PARENTSINLAW"
                                                      disabled={
                                                        isPolicyAttachedToMember
                                                      }
                                                      onChange={(e) => {
                                                        updateMasterState(
                                                          [
                                                            "addPoliciesdata",
                                                            "planType",
                                                            "coverageType",
                                                            "checkbox",
                                                          ],
                                                          e.target.value
                                                        );
                                                      }}
                                                    />
                                                    <label className="parents">
                                                      <p>Parents In Law</p>
                                                    </label>
                                                  </div>
                                                </li>
                                              </>
                                            )}
                                        </ul>
                                      </Grid>
                                    </Grid>
                                    <Grid container spacing={3}>
                                      <Grid xs={12}>
                                        <h5 className="subHeading">
                                          Coverage Configuration
                                        </h5>
                                      </Grid>
                                      <Grid sm={6} md={4} lg={3} xl={3}>
                                        <SelectDropdown
                                          class_name="inputField"
                                          title="Basic Configuration"
                                          value={
                                            addPoliciesdata.planType.basicConfig
                                          }
                                          attrName={[
                                            "addPoliciesdata",
                                            "planType",
                                            "basicConfig",
                                            "input_fields",
                                          ]}
                                          value_update={updateMasterState}
                                          dropdown_data={basicConfig_data}
                                          warn_status={
                                            addPoliciesdataWarn.planType
                                              .basicConfig
                                          }
                                          disabled={isPolicyAttachedToMember}
                                          required
                                        />
                                      </Grid>
                                      <Grid sm={6} md={4} lg={3} xl={3}>
                                        <SelectDropdown
                                          class_name="inputField"
                                          title="Individual Configuration"
                                          value={
                                            addPoliciesdata.planType
                                              .parentConfig
                                          }
                                          attrName={[
                                            "addPoliciesdata",
                                            "planType",
                                            "parentConfig",
                                            "input_fields",
                                          ]}
                                          value_update={updateMasterState}
                                          dropdown_data={indvConfig_data}
                                          warn_status={false}
                                          required
                                          disabled={isPolicyAttachedToMember}
                                        />
                                      </Grid>
                                      {addPoliciesdata.planType.coverageType.some(
                                        (data) => data.name === "KIDS"
                                      ) && (
                                          <Grid sm={6} md={4} lg={3} xl={3}>
                                            <SearchDropdown
                                              class_name="inputField"
                                              title="Maximum Age for Children"
                                              value={addPoliciesdata.maxChildAge}
                                              attrName={[
                                                "addPoliciesdata",
                                                "maxChildAge",
                                                "maxChildAge",
                                              ]}
                                              value_update={updateMasterState}
                                              data={maxage_dataClone}
                                              error_message="select max child age"
                                              warn_status={
                                                addPoliciesdataWarn.maxChildAge
                                              }
                                              disabled={isPolicyAttachedToMember}
                                            />
                                          </Grid>
                                        )}
                                    </Grid>
                                    <Grid container spacing={3}>
                                      <Grid sm={6} md={8} lg={5} xl={5}>
                                        <RKTextField
                                          class_name="inputField"
                                          title="Family Definition"
                                          disabled={isPolicyAttachedToMember}
                                          value={
                                            addPoliciesdata.planType
                                              .familyDefinition
                                          }
                                          attrName={[
                                            "addPoliciesdata",
                                            "planType",
                                            "familyDefinition",
                                            "input_fields",
                                          ]}
                                          value_update={updateMasterState}
                                          warn_status={
                                            addPoliciesdataWarn.planType
                                              .familyDefinition
                                          }
                                          required
                                        />
                                      </Grid>
                                      {addPoliciesdata.planType.parentConfig.includes(
                                        "1P+"
                                      ) &&
                                        addPoliciesdata.planType.parentConfig.includes(
                                          "1PIL"
                                        ) && (
                                          <Grid
                                            xs={12}
                                            className="payment_radio_group"
                                          >
                                            <>
                                              <RadioGroup
                                                row
                                                defaultValue="both"
                                              >
                                                <FormControlLabel
                                                  checked={
                                                    addPoliciesdata.planType
                                                      .setOfParents === "both"
                                                  }
                                                  value="both"
                                                  control={
                                                    <Radio
                                                      sx={{
                                                        "&.Mui-checked": {
                                                          color: "#6b62dc",
                                                        },
                                                      }}
                                                    />
                                                  }
                                                  label="Include both the set of Parents and Parents in law"
                                                  onClick={() => {
                                                    setAddPoliciesdata(
                                                      (prev) => ({
                                                        ...prev,
                                                        policy: {
                                                          ...prev.policy,
                                                          totalMemberPerFamily:
                                                            extractNumbersFromString(
                                                              prev.planType
                                                                .basicConfig
                                                            ).length +
                                                            extractNumbersFromString(
                                                              prev.planType
                                                                .parentConfig
                                                            ).length,
                                                        },
                                                        planType: {
                                                          ...prev.planType,
                                                          setOfParents: "both",
                                                        },
                                                      })
                                                    );
                                                  }}
                                                />
                                                <FormControlLabel
                                                  checked={
                                                    addPoliciesdata.planType
                                                      .setOfParents === "single"
                                                  }
                                                  value="single"
                                                  control={<Radio />}
                                                  label="Include only a set of Parents or Parents in law"
                                                  onClick={() => {
                                                    setAddPoliciesdata(
                                                      (prev) => ({
                                                        ...prev,
                                                        planType: {
                                                          ...prev.planType,
                                                          setOfParents:
                                                            "single",
                                                        },
                                                      })
                                                    );
                                                    if (
                                                      extractNumbersFromString(
                                                        addPoliciesdata.planType
                                                          .parentConfig
                                                      ).length === 4
                                                    ) {
                                                      setAddPoliciesdata(
                                                        (prev) => ({
                                                          ...prev,
                                                          policy: {
                                                            ...prev.policy,
                                                            totalMemberPerFamily:
                                                              extractNumbersFromString(
                                                                prev.planType
                                                                  .basicConfig
                                                              ).length +
                                                              extractNumbersFromString(
                                                                prev.planType
                                                                  .parentConfig
                                                              ).length -
                                                              2,
                                                          },
                                                        })
                                                      );
                                                    }
                                                  }}
                                                />
                                              </RadioGroup>
                                            </>
                                          </Grid>
                                        )}
                                    </Grid>
                                    {planType === 1 ? (
                                      <Grid container spacing={3}>
                                        <Grid xs={12}>
                                          <h5 className="subHeading">
                                            Criteria for Grade Creation
                                          </h5>
                                        </Grid>
                                        <Grid sm={6} md={4} lg={3} xl={3}>
                                          <SelectDropdown
                                            class_name="inputField"
                                            title="Select Criteria"
                                            value={
                                              addPoliciesdata.planType
                                                .gradeCriteria
                                            }
                                            attrName={[
                                              "addPoliciesdata",
                                              "planType",
                                              "gradeCriteria",
                                              "input_fields",
                                            ]}
                                            value_update={updateMasterState}
                                            dropdown_data={
                                              gradedDesignation_data
                                            }
                                            warn_status={false}
                                            disabled={isPolicyAttachedToMember}
                                          />
                                        </Grid>
                                        {addPoliciesdata.planType
                                          .gradeCriteria === "Others" ? (
                                          <Grid sm={6} md={4} lg={4} xl={3}>
                                            <RKTextField
                                              class_name="inputField"
                                              title="Others"
                                              value={others}
                                              attrName={"others"}
                                              value_update={updateMasterState}
                                              warn_status={false}
                                              disabled={
                                                isPolicyAttachedToMember
                                              }
                                            />
                                          </Grid>
                                        ) : null}
                                      </Grid>
                                    ) : null}
                                  </Box>
                                ) : null}
                              </Grid>
                            </Grid>
                          </Box>
                        </Grid>
                      </Grid>

                      {/* payment configuration */}
                      <Grid container spacing={3} className="px-0">
                        <Grid xs={12}>
                          <Box
                            className="detailSection mb-0"
                            onMouseEnter={() => {
                              SetSectionStatus("premiumPayment");
                            }}
                            ref={premiumPayment}
                          >
                            <div className="sectionTitle">
                              <h4>Premium Payment Configuration</h4>
                            </div>
                            <div className="premium_payment_section">
                              <Grid container spacing={3}>
                                <Grid xs={12}>
                                  <h5 className="subHeading">
                                    Premium Payment Type
                                  </h5>
                                </Grid>
                                <Grid xs={12} className="payment_radio_group">
                                  <>
                                    <RadioGroup row defaultValue="new">
                                      {premiumPaymentType ===
                                        premiumType.STANDARD && (
                                          <FormControlLabel
                                            checked={
                                              premiumPaymentType ===
                                              premiumType.STANDARD
                                            }
                                            value="existing"
                                            control={<Radio />}
                                            label="Standard Premium"
                                            onClick={() =>
                                              setPremiumPaymentType(
                                                premiumType.STANDARD
                                              )
                                            }
                                            disabled={isPolicyAttachedToMember}
                                          />
                                        )}
                                      {premiumPaymentType ===
                                        premiumType.SPLIT && (
                                          <FormControlLabel
                                            checked={
                                              premiumPaymentType ===
                                              premiumType.SPLIT
                                            }
                                            value="new"
                                            control={<Radio />}
                                            label="Split Premium"
                                            onClick={() =>
                                              setPremiumPaymentType(
                                                premiumType.SPLIT
                                              )
                                            }
                                            disabled={isPolicyAttachedToMember}
                                          />
                                        )}
                                    </RadioGroup>
                                  </>
                                </Grid>
                              </Grid>
                              {premiumPaymentType === premiumType.SPLIT ? (
                                <Grid container spacing={3}>
                                  <Grid sm={12} md={12} lg={6} xl={6}>
                                    <div className="payment_inner_section">
                                      {addPoliciesdata.payType?.data.map(
                                        (pType: any, index: any) => {
                                          return (
                                            <RKTextField
                                              class_name="inputField"
                                              // type="number"
                                              title={pType.name}
                                              value={
                                                addPoliciesdata.payType?.data.find(
                                                  (el: any) =>
                                                    el.name === pType.name
                                                )?.value
                                              }
                                              attrName={[
                                                "addPoliciesdata",
                                                "payType",
                                                "data",
                                                pType.name,
                                              ]}
                                              value_update={updatePayTypeValues}
                                              warn_status={false}
                                              inputProps={{
                                                endAdornment: (
                                                  <InputAdornment position="end">
                                                    %
                                                  </InputAdornment>
                                                ),
                                              }}
                                              disabled={
                                                isPolicyAttachedToMember
                                              }
                                            />
                                          );
                                        }
                                      )}
                                    </div>
                                  </Grid>
                                </Grid>
                              ) : null}
                              <Grid container spacing={3}>
                                <>
                                  {premiumPaymentType ===
                                    premiumType.STANDARD ? (
                                    <Grid xs={12} className="pt-0">
                                      <h6>
                                        100 % of the premium value will be paid
                                        by the employer
                                      </h6>
                                    </Grid>
                                  ) : (
                                    <Grid xs={12} className="pt-0">
                                      <h6>
                                        Define % of the premium value to be paid
                                        by the employee for the respective
                                        associated relationship
                                      </h6>
                                    </Grid>
                                  )}
                                </>
                              </Grid>
                            </div>
                          </Box>
                        </Grid>
                      </Grid>

                      {/* Rider section start */}
                      {addPoliciesdata.productType.name === "Group Term" && (
                        <Grid container spacing={3} className="px-0">
                          <Grid xs={12}>
                            <Box
                              className="detailSection mb-0"
                              ref={Riders}
                              onMouseEnter={() => {
                                SetSectionStatus("Riders");
                              }}
                            >
                              <div className="sectionTitle">
                                <h4>Riders</h4>
                              </div>

                              <Grid container spacing={3}>
                                <Grid
                                  xs
                                  lg={8}
                                  className="switchFields"
                                  display={"flex"}
                                  alignItems="center"
                                  sx={{ height: "70px" }}
                                >
                                  <span
                                    style={{
                                      fontSize: "16px",
                                      marginLeft: "12px",
                                    }}
                                  >
                                    Enable Riders
                                  </span>
                                  <input
                                    disabled={isPolicyAttachedToMember}
                                    type="checkbox"
                                    className="switch ml-3"
                                    checked={addPoliciesdata.is_rider}
                                    onClick={(e) => {
                                      toggleEnableRiders(e);
                                    }}
                                  />
                                </Grid>
                                <Grid xs="auto">
                                  {addPoliciesdata.is_rider && (
                                    <Link
                                      className="blueBtn addfile ml-1"
                                      sx={{
                                        textDecoration: "none",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        if (!addPoliciesdata.is_rider) {
                                          return toast.warn(
                                            "Please enable Rider"
                                          );
                                        }
                                        setAddPoliciesdata((prev) => ({
                                          ...prev,
                                          rider: [
                                            ...(prev.rider || []),
                                            {
                                              name: "",
                                              description: "",
                                            },
                                          ],
                                          benefits_detail_flat: [
                                            ...(prev.benefits_detail_flat ||
                                              []),
                                            {
                                              label: "",
                                              enable: false,
                                              amount: "",
                                              description: "",
                                            },
                                          ],
                                          rider_premium_flat: [
                                            ...prev.rider_premium_flat,
                                            {
                                              proreta_by: "YEARLY",
                                              premium_split: "",
                                              rider_name: "",
                                              livesInfoFlat: [
                                                {
                                                  min: "",
                                                  max: "",
                                                  name: "",
                                                  coverageTypeData: [
                                                    {
                                                      code: "",
                                                      name: "",
                                                      value: 0,
                                                    },
                                                  ],
                                                },
                                              ],
                                            },
                                          ],
                                        }));
                                        setAddPoliciesdataWarn((prev: any) => ({
                                          ...prev,
                                          rider: [
                                            ...(prev.rider || []),
                                            {
                                              name: false,
                                              description: false,
                                            },
                                          ],
                                          benefits_detail_flat: [
                                            ...(prev.benefits_detail_flat ||
                                              []),
                                            {
                                              label: false,
                                              enable: false,
                                              amount: false,
                                              description: false,
                                            },
                                          ],
                                          rider_premium_flat: [
                                            ...prev.rider_premium_flat,
                                            {
                                              proreta_by: false,
                                              premium_split: false,
                                              rider_name: false,
                                              livesInfoFlat: [
                                                {
                                                  min: false,
                                                  max: false,
                                                  name: false,
                                                  coverageTypeData: [
                                                    {
                                                      code: false,
                                                      name: false,
                                                      value: false,
                                                    },
                                                  ],
                                                },
                                              ],
                                            },
                                          ],
                                        }));
                                      }}
                                    >
                                      Add
                                    </Link>
                                  )}
                                </Grid>
                              </Grid>
                              {addPoliciesdata.is_rider &&
                                addPoliciesdata.rider?.map(
                                  (rider: any, index: number) => (
                                    <Grid container spacing={3}>
                                      <Grid xs lg={4}>
                                        <RKTextField
                                          class_name="inputField"
                                          title={"Rider Name"}
                                          disabled={isPolicyAttachedToMember}
                                          value={rider.name}
                                          attrName={[
                                            "addPoliciesdata",
                                            "rider",
                                            "name",
                                            "input_fields",
                                            index,
                                          ]}
                                          value_update={updateMasterState}
                                          warn_status={
                                            addPoliciesdataWarn?.rider[index]
                                              ?.name
                                          }
                                        />
                                      </Grid>
                                      <Grid xs lg={4}>
                                        <TextAreaField
                                          class_name="inputField multiline"
                                          title={"Description"}
                                          value={rider.description}
                                          disable={isPolicyAttachedToMember}
                                          attrName={[
                                            "addPoliciesdata",
                                            "rider",
                                            "description",
                                            "input_fields",
                                            index,
                                          ]}
                                          value_update={updateMasterState}
                                          warn_status={
                                            addPoliciesdataWarn?.rider[index]
                                              ?.description
                                          }
                                          required
                                          specialcharacter
                                          alphanumeric
                                        />
                                      </Grid>
                                      {!isPolicyAttachedToMember &&
                                        index !== 0 && (
                                          <Grid xs="auto">
                                            <Link
                                              className="redBtn delete ml-1"
                                              sx={{
                                                textDecoration: "none",
                                                cursor: "pointer",
                                              }}
                                              onClick={() => {
                                                setAddPoliciesdata((prev) => {
                                                  const newRider = prev.rider
                                                    ? [...prev.rider]
                                                    : [];
                                                  const benefits_detail_flat =
                                                    prev.benefits_detail_flat
                                                      ? [
                                                        ...prev.benefits_detail_flat,
                                                      ]
                                                      : [];
                                                  const rider_premium_flat =
                                                    prev.rider_premium_flat
                                                      ? [
                                                        ...prev.rider_premium_flat,
                                                      ]
                                                      : [];
                                                  if (newRider) {
                                                    newRider.splice(index, 1);
                                                    benefits_detail_flat.splice(
                                                      index,
                                                      1
                                                    );
                                                    rider_premium_flat.splice(
                                                      index,
                                                      1
                                                    );
                                                  }
                                                  return {
                                                    ...prev,
                                                    rider: newRider,
                                                    benefits_detail_flat,
                                                    rider_premium_flat,
                                                  };
                                                });
                                                setAddPoliciesdataWarn(
                                                  (prev: any) => {
                                                    const newRider = prev.rider
                                                      ? [...prev.rider]
                                                      : [];
                                                    const benefits_detail_flat =
                                                      prev.benefits_detail_flat
                                                        ? [
                                                          ...prev.benefits_detail_flat,
                                                        ]
                                                        : [];
                                                    const rider_premium_flat =
                                                      prev.rider_premium_flat
                                                        ? [
                                                          ...prev.rider_premium_flat,
                                                        ]
                                                        : [];
                                                    if (newRider) {
                                                      newRider.splice(index, 1);
                                                      benefits_detail_flat.splice(
                                                        index,
                                                        1
                                                      );
                                                      rider_premium_flat.splice(
                                                        index,
                                                        1
                                                      );
                                                    }
                                                    return {
                                                      ...prev,
                                                      rider: newRider,
                                                      benefits_detail_flat,
                                                      rider_premium_flat,
                                                    };
                                                  }
                                                );
                                              }}
                                            />
                                          </Grid>
                                        )}
                                    </Grid>
                                  )
                                )}
                            </Box>
                          </Grid>
                        </Grid>
                      )}

                      {/* Age Bands for Lives */}
                      <Grid container spacing={3} className="px-0">
                        <Grid xs={12}>
                          <Box
                            className="detailSection "
                            ref={ageBand}
                            onMouseEnter={() => {
                              SetSectionStatus("ageBand");
                            }}
                          >
                            <div className="sectionTitle">
                              <h4>Age Bands for Lives</h4>
                            </div>
                            <Box className="band_lives">
                              <Grid container spacing={3}>
                                {addPoliciesdata?.ageBand?.map(
                                  (data: any, index: number) => (
                                    <Grid sm={12} md={12} lg={6} xl={6}>
                                      <h5 className="mb-3 mt-2">
                                        Age Band {index + 1}
                                      </h5>
                                      <div className="bandlives_inner">
                                        <SearchDropdown
                                          class_name="inputField"
                                          title="Min Age"
                                          value={
                                            addPoliciesdata?.ageBand &&
                                            addPoliciesdata.ageBand[index]?.min
                                          }
                                          attrName={[
                                            "addPoliciesdata",
                                            "ageBand",
                                            "min",
                                            "drop_down",
                                            index,
                                          ]}
                                          value_update={updateMasterState}
                                          data={minage_data}
                                          warn_status={
                                            addPoliciesdataWarn?.ageBand[index]
                                              ?.min
                                          }
                                          error_message="Please select min age"
                                          disabled={
                                            isPolicyAttachedToMember ||
                                            index <= ageBandData.length - 2
                                          }
                                        />
                                        <SearchDropdown
                                          class_name="inputField"
                                          title="Max Age"
                                          value={
                                            addPoliciesdata?.ageBand &&
                                            addPoliciesdata?.ageBand[index]?.max
                                          }
                                          attrName={[
                                            "addPoliciesdata",
                                            "ageBand",
                                            "max",
                                            "drop_down",
                                            index,
                                          ]}
                                          value_update={updateMasterState}
                                          data={maxage_data}
                                          warn_status={
                                            addPoliciesdataWarn?.ageBand[index]
                                              ?.max
                                          }
                                          error_message="Please select max age"
                                          disabled={
                                            isPolicyAttachedToMember ||
                                            index <= ageBandData.length - 2 ||
                                            !addPoliciesdata.ageBand[
                                              ageBandData.length - 1
                                            ]?.min
                                          }
                                        />
                                        <div
                                          className="actionBtns"
                                          style={{ display: "flex" }}
                                        >
                                          {!isPolicyAttachedToMember &&
                                            index > ageBandData.length - 2 && (
                                              <>
                                                <Link
                                                  className="blueBtn addfile ml-1"
                                                  sx={{
                                                    textDecoration: "none",
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() => {
                                                    let check =
                                                      addPoliciesdata.ageBand.some(
                                                        (data) =>
                                                          !data.max || !data.min
                                                      );

                                                    if (!check) {
                                                      add_band_for_lives(index);
                                                      breakAgeBand("", "onadd");
                                                    } else {
                                                      return toast.warning(
                                                        "Please select empty Age band"
                                                      );
                                                    }
                                                  }}
                                                />
                                                {index === 0 ? null : (
                                                  <Link
                                                    className="redBtn delete ml-1"
                                                    sx={{
                                                      textDecoration: "none",
                                                      cursor: "pointer",
                                                    }}
                                                    onClick={() =>
                                                      remove_band_for_lives(
                                                        index
                                                      )
                                                    }
                                                  />
                                                )}
                                              </>
                                            )}
                                        </div>
                                      </div>
                                    </Grid>
                                  )
                                )}
                              </Grid>
                              {errorMaxChildAge &&
                            <span style={{color:"#eb5757",fontSize:"11px" ,padding:"12px 0 0 12px"}}>Max child age not included in selected age bands</span>
                            }
                            {areAgeBandsConsistent(addPoliciesdata.ageBand)? "" : <span style={{color:"#eb5757",fontSize:"14px" ,padding:"12px 0 0 12px"}}>Note: Selected age bands are Inconsistent</span>}

                            </Box>
                          </Box>
                          {planType === 0 ? (
                            <>
                              <Box
                                className="detailSection mb-0"
                                ref={FeaturesExclusions}
                                onMouseEnter={() => {
                                  SetSectionStatus("FeaturesExclusions");
                                }}
                              >
                                <div className="sectionTitle">
                                  <h4>SI/SA Features & Exclusions</h4>
                                  <div className="actionBtns ">
                                    {addPoliciesdata?.planType?.name ===
                                      "FLAT" ? (
                                      ""
                                    ) : (
                                      <>
                                        <Link
                                          className="rounded_add_icons mr-3"
                                          sx={{
                                            textDecoration: "none",
                                            cursor: "pointer",
                                          }}
                                          onClick={() => {
                                            setEmpGradeData((empGradeData) => [
                                              ...empGradeData,
                                              {
                                                sum_insured: "5000",
                                                emp_count: "5",
                                                features: ["sdf", "sdfsd"],
                                                deleteable: true,
                                              },
                                            ]);
                                          }}
                                        >
                                          Add Grade
                                        </Link>
                                      </>
                                    )}

                                    <p className="lives">
                                      {/* Total Lives - <span> 300</span> */}
                                    </p>
                                  </div>
                                </div>
                                <Box className="employeeDetail">
                                  {empGradeData.map((data, index) => (
                                    <Grid
                                      container
                                      spacing={0}
                                      className=" gradedSection ml-3 mr-3 mb-4"
                                    >
                                      <Grid xs={12} className="pb-3">
                                        <>
                                          <Grid container spacing={3}>
                                            <Grid xs={6}>
                                              <p>
                                                {" "}
                                                {addPoliciesdata?.productType
                                                  ?.name === "Group Super TopUp"
                                                  ? "Super Top-Up Sum Insured"
                                                  : addPoliciesdata?.productType
                                                    ?.name === "Group Term"
                                                    ? "Sum Assured"
                                                    : "Sum Insured"}
                                              </p>{" "}
                                            </Grid>
                                            <Grid
                                              xs={6}
                                              sx={{ textAlign: "right" }}
                                            >
                                              {index === 0 ? (
                                                ""
                                              ) : (
                                                <Link
                                                  className="redBtn delete ml-1"
                                                  sx={{
                                                    textDecoration: "none",
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() =>
                                                    remove_band_for_lives(index)
                                                  }
                                                />
                                              )}
                                            </Grid>
                                            <Grid xs={12}>
                                              <RKTextField
                                                class_name="inputField"
                                                title={
                                                  addPoliciesdata?.productType
                                                    ?.name === "Group Term"
                                                    ? "Sum Assured"
                                                    : "Sum Insured"
                                                }
                                                value={formatCurrencyAccToUser(
                                                  Number(
                                                    extractNumbersFromString(
                                                      addPoliciesdata
                                                        .benefitsDetailsflat
                                                        .sumInsured
                                                        ? addPoliciesdata
                                                          .benefitsDetailsflat
                                                          .sumInsured
                                                        : "0"
                                                    )
                                                  ),
                                                  getCurrencyCodeRegex(
                                                    userState.localInformation
                                                      .currency
                                                  ),
                                                  userState.localInformation
                                                    .countryCurrencyCode,
                                                  0
                                                )}
                                                attrName={[
                                                  "addPoliciesdata",
                                                  "benefitsDetailsflat",
                                                  "sumInsured",
                                                  "input_fields",
                                                  index,
                                                ]}
                                                value_update={updateMasterState}
                                                warn_status={
                                                  addPoliciesdataWarn
                                                    .benefitsDetailsflat
                                                    .sum_insured
                                                }
                                                error_messg="Enter Sum Insured amount"
                                                required
                                              />
                                            </Grid>
                                            {addPoliciesdata?.productType
                                              ?.name === "Group Super TopUp" ? (
                                              <Grid
                                                xs={6}
                                                className="super_topup"
                                                style={{
                                                  display: "flex",
                                                  alignItems: "center",
                                                }}
                                              >
                                                <h6 className="mb-0">
                                                  {" "}
                                                  Deductible
                                                </h6>
                                                <div
                                                  className="ml-3"
                                                  style={{ width: "300px" }}
                                                >
                                                  <RKTextField
                                                    class_name="inputField"
                                                    title="Enter value for Deductible"
                                                    value={formatCurrency(
                                                      Number(addPoliciesdata?.benefitsDetailsflat?.deductible))}
                                                    attrName={[
                                                      "addPoliciesdata",
                                                      "benefitsDetailsflat",
                                                      "deductible",
                                                      "input_fields",
                                                      index,
                                                    ]}
                                                    value_update={
                                                      updateMasterState
                                                    }
                                                    warn_status={
                                                      addPoliciesdataWarn
                                                        .benefitsDetailsflat
                                                        .deductible
                                                    }
                                                    error_messg="Enter deductible amount"
                                                    required
                                                  />
                                                </div>
                                              </Grid>
                                            ) : (
                                              ""
                                            )}
                                            {addPoliciesdata.productType.name ==
                                              "Group Term" && (
                                                <Grid xs={12}>
                                                  {addPoliciesdata.is_rider &&
                                                    addPoliciesdata?.benefits_detail_flat?.map(
                                                      (
                                                        riders: any,
                                                        index: number
                                                      ) =>
                                                        riders.label && (
                                                          <Grid
                                                            container
                                                            spacing={3}
                                                          >
                                                            <Grid
                                                              xs
                                                              lg={4}
                                                              className="switchFields"
                                                              display={"flex"}
                                                              alignItems="center"
                                                              sx={{
                                                                height: "70px",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize:
                                                                    "14px",
                                                                  marginLeft:
                                                                    "12px",
                                                                  fontWeight: 600,
                                                                }}
                                                              >
                                                                {riders?.label}
                                                              </span>
                                                              <input
                                                                type="checkbox"
                                                                checked={
                                                                  riders.enable
                                                                }
                                                                disabled={
                                                                  isPolicyAttachedToMember
                                                                }
                                                                className="switch ml-3"
                                                                onClick={(e) => {
                                                                  toggleEnableRidersFeaure(
                                                                    e,
                                                                    index
                                                                  );
                                                                }}
                                                              />
                                                            </Grid>
                                                            {riders.enable && (
                                                              <Grid xs lg={4}>
                                                                <RKTextField
                                                                  class_name="inputField"
                                                                  title={
                                                                    "Rider SA"
                                                                  }
                                                                  value={formatCurrency(
                                                                    Number(
                                                                      riders?.amount
                                                                    )
                                                                  )}
                                                                  disabled={
                                                                    isPolicyAttachedToMember
                                                                  }
                                                                  attrName={[
                                                                    "addPoliciesdata",
                                                                    "benefits_detail_flat",
                                                                    "amount",
                                                                    "input_fields",
                                                                    index,
                                                                  ]}
                                                                  value_update={
                                                                    updateMasterState
                                                                  }
                                                                  warn_status={
                                                                    addPoliciesdataWarn
                                                                      ?.benefits_detail_flat[
                                                                      index
                                                                    ]?.amount
                                                                  }
                                                                />
                                                              </Grid>
                                                            )}
                                                          </Grid>
                                                        )
                                                    )}
                                                </Grid>
                                              )}
                                            <Grid xs={12}>
                                              <div className="sectionsHeading">
                                                <h6>Features</h6>
                                                <div className="actionBtns ">
                                                  <Link
                                                    className="rounded_add_icons addfile blueBtn"
                                                    sx={{
                                                      textDecoration: "none",
                                                      cursor: "pointer",
                                                    }}
                                                    onClick={() => {
                                                      setShowLoader(true);
                                                      function demo() {
                                                        setTimeout(() => {
                                                          setAddFeature(true);
                                                        }, 300);
                                                      }
                                                      demo();
                                                    }}
                                                  >
                                                    Add/Edit
                                                  </Link>
                                                </div>
                                              </div>
                                            </Grid>
                                            {/* {addPoliciesdata
                                              ?.benefitsDetailsflat
                                              ?.features?.[0]?.value ? ( */}
                                            <Box className="featuresDetail px-0">
                                              <Grid container spacing={3}>
                                                {addPoliciesdata?.benefitsDetailsflat.features?.map(
                                                  (data: any) => (
                                                    <Grid xs={6}>
                                                      <div className="features_list">
                                                        <ul>
                                                          <li>
                                                            {data.name}
                                                            <span>
                                                              {data.value}{" "}
                                                              {data.unit ===
                                                                "Percentage"
                                                                ? "%"
                                                                : data.unit ===
                                                                  "Currency"
                                                                  ? ""
                                                                  : data.unit}
                                                            </span>
                                                          </li>
                                                          {addPoliciesdataWarn
                                                            .benefitsDetailsflat
                                                            .features ===
                                                            true ? (
                                                            <span
                                                              className="inputField"
                                                              style={{
                                                                color:
                                                                  "#eb5757",
                                                                fontSize:
                                                                  "11px",
                                                                position:
                                                                  "relative",
                                                                right: "0",
                                                                bottom: "-20px",
                                                              }}
                                                            >
                                                              Please Update
                                                              features
                                                            </span>
                                                          ) : (
                                                            ""
                                                          )}
                                                        </ul>
                                                      </div>
                                                    </Grid>
                                                  )
                                                )}
                                              </Grid>
                                            </Box>
                                            {/* ) : (
                                              ""
                                            )} */}

                                            <Grid xs={12}>
                                              <div className="sectionsHeading">
                                                <h6>Exclusion</h6>
                                                {addPoliciesdataWarn
                                                  .benefitsDetailsflat
                                                  .exclusion === true ? (
                                                  <span
                                                    className="inputField"
                                                    style={{
                                                      color: "#eb5757",
                                                      fontSize: "11px",
                                                      position: "relative",
                                                      right: "0",
                                                      bottom: "-20px",
                                                    }}
                                                  >
                                                    Please Update exclusion
                                                  </span>
                                                ) : (
                                                  ""
                                                )}

                                                <div className="actionBtns ">
                                                  <Link
                                                    className="rounded_add_icons addfile blueBtn"
                                                    sx={{
                                                      textDecoration: "none",
                                                      cursor: "pointer",
                                                    }}
                                                    onClick={() => {
                                                      setShowLoader(true);
                                                      setTimeout(() => {
                                                        setAddExclusion(true);
                                                      }, 300);
                                                    }}
                                                  >
                                                    Add/Edit
                                                  </Link>
                                                </div>
                                              </div>
                                            </Grid>
                                            <Box className="exclusionDetail px-0">
                                              <Grid container spacing={3}>
                                                <Grid xs={12}>
                                                  <div className="features_list">
                                                    <ul>
                                                      {addPoliciesdata?.benefitsDetailsflat?.exclusion?.map(
                                                        (
                                                          data: any,
                                                          indexx: any
                                                        ) => (
                                                          <>
                                                            {addPoliciesdataWarn
                                                              ?.benefitsDetailsGrade[
                                                              index
                                                            ]?.exclusion[indexx]
                                                              ?.name ===
                                                              true ? (
                                                              <span
                                                                className="inputField"
                                                                style={{
                                                                  color:
                                                                    "#eb5757",
                                                                  fontSize:
                                                                    "11px",
                                                                  position:
                                                                    "relative",
                                                                  right: "0",
                                                                  bottom:
                                                                    "-20px",
                                                                }}
                                                              >
                                                                Please Update
                                                                features
                                                              </span>
                                                            ) : (
                                                              ""
                                                            )}
                                                            {data.name ? (
                                                              <li>
                                                                {data.name}
                                                                <span>
                                                                  {
                                                                    data.description
                                                                  }
                                                                </span>
                                                              </li>
                                                            ) : (
                                                              ""
                                                            )}
                                                          </>
                                                        )
                                                      )}
                                                    </ul>
                                                  </div>
                                                </Grid>
                                              </Grid>
                                            </Box>
                                          </Grid>
                                        </>
                                      </Grid>
                                    </Grid>
                                  ))}
                                </Box>
                              </Box>
                            </>
                          ) : planType === 1 || planType === 2 ? (
                            <Box
                              className="detailSection mb-0"
                              ref={FeaturesExclusions}
                              onMouseEnter={() => {
                                SetSectionStatus("FeaturesExclusions");
                              }}
                            >
                              <div className="sectionTitle">
                                <h4>SI/SA Features & Exclusions</h4>
                                <div className="actionBtns ">
                                  {!isPolicyAttachedToMember && (
                                    <Link
                                      className="rounded_add_icons mr-3"
                                      sx={{
                                        textDecoration: "none",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        let data: policiesDTONew = {
                                          ...addPoliciesdata,
                                        };
                                        let dataWarn: any = {
                                          ...addPoliciesdataWarn,
                                        };
                                        data.benefitsDetailsGrade = [
                                          ...data.benefitsDetailsGrade,
                                          {
                                            gradeDivisions: [
                                              { id: "", label: "" },
                                            ],
                                            deductible: "",
                                            name: "",
                                            sumInsured: (
                                              Number(
                                                extractNumbersFromString(
                                                  data.benefitsDetailsGrade[
                                                    data.benefitsDetailsGrade
                                                      .length - 1
                                                  ].sumInsured
                                                )
                                              ) + 1
                                            ).toString(),
                                            features: [
                                              {
                                                name: "",
                                                value: "",
                                                featureId: "",
                                                groupId: "",
                                              },
                                            ],
                                            exclusion: [
                                              {
                                                name: "",
                                                description: "",
                                                exclusionId: "",
                                              },
                                            ],
                                          },
                                        ];
                                        data.benefits_detail_graded = [
                                          ...data.benefits_detail_graded,
                                          {
                                            grade_name: `Grade ${data.benefits_detail_graded
                                                .length + 1
                                              }`,
                                            riders: [
                                              ...data.benefits_detail_graded[
                                                data.benefits_detail_graded
                                                  .length - 1
                                              ].riders,
                                            ],
                                          },
                                        ];
                                        dataWarn.benefitsDetailsGrade = [
                                          ...dataWarn.benefitsDetailsGrade,
                                          {
                                            designation: false,
                                            deductible: false,
                                            name: false,
                                            sumInsured: false,
                                            features: [
                                              {
                                                name: false,
                                                value: false,
                                                featureId: false,
                                                groupId: false,
                                              },
                                            ],
                                            exclusion: [
                                              {
                                                name: false,
                                                description: false,
                                                exclusionId: false,
                                              },
                                            ],
                                          },
                                        ];
                                        setAddPoliciesdata(data);
                                        setAddPoliciesdataWarn(dataWarn);
                                        gradedAddData(featuresListdata);
                                        exclusionsgradedAddData(
                                          exclusionsListdata
                                        );
                                        filterDropDownData();
                                        setlivesSelectedGrade((prev) => [
                                          ...prev,
                                          { selectedGrade: 0 },
                                        ]);
                                        setExpanded([
                                          ...expanded.map(() => {
                                            return { expanded: false };
                                          }),
                                          { expanded: true },
                                        ]);
                                      }}
                                    >
                                      Add Grade
                                    </Link>
                                  )}

                                  <p className="lives">
                                    {/* Total Lives - <span> 300</span> */}
                                  </p>
                                </div>
                              </div>
                              <Box className="employeeDetail">
                                {addPoliciesdata?.benefitsDetailsGrade &&
                                  addPoliciesdata?.benefitsDetailsGrade.map(
                                    (data: any, index: any) => (
                                      <Accordion
                                        className="Accordion_section gradedSection gradepadding"
                                        onChange={() => {
                                          let newExpanded: Array<{
                                            expanded: boolean;
                                          }> = [...expanded];
                                          if (newExpanded[index]) {
                                            newExpanded[index].expanded =
                                              !expanded[index]?.expanded;
                                          }
                                          setExpanded(newExpanded);
                                        }}
                                        expanded={expanded[index]?.expanded}
                                      >
                                        <Grid container spacing={0}>
                                          <Grid xs={4} className="px-0">
                                            <AccordionSummary
                                              aria-controls="panel1a-content"
                                              id="panel1a-header"
                                              style={{
                                                display: "inline-block",
                                                width: "110px",
                                              }}
                                            >
                                              <Typography
                                                className="title"
                                                style={{ width: "150px" }}
                                              >
                                                <h5>Grade {index + 1} :-</h5>
                                              </Typography>
                                            </AccordionSummary>
                                          </Grid>
                                          <Grid
                                            xs={8}
                                            style={{
                                              alignSelf: "Center",
                                              display: "flex",
                                              justifyContent: "end",
                                            }}
                                          >
                                            <h4>Grade {index + 1} </h4>
                                            {index === 0 ? null : (
                                              <>
                                                {!isPolicyAttachedToMember && (
                                                  <div
                                                    className="text-right"
                                                    style={{
                                                      alignSelf: "center",
                                                    }}
                                                  >
                                                    <Link
                                                      className="redBtn delete ml-3"
                                                      underline="none"
                                                      onClick={() => {
                                                        let data = {
                                                          ...addPoliciesdata,
                                                        };
                                                        let dataExpand = [
                                                          ...expanded,
                                                        ];
                                                        data.benefitsDetailsGrade.splice(
                                                          index,
                                                          1
                                                        );
                                                        dataExpand.splice(
                                                          index,
                                                          1
                                                        );
                                                        // data.benefitsDetailsGrade = data.benefitsDetailsGrade.filter((_,i)=>{return  i !== index});
                                                        setAddPoliciesdata(
                                                          data
                                                        );
                                                        setExpanded(dataExpand);
                                                      }}
                                                    >
                                                      Remove
                                                    </Link>
                                                  </div>
                                                )}
                                              </>
                                            )}
                                            <AccordionSummary
                                              expandIcon={<ExpandMoreIcon />}
                                              aria-controls="panel1a-content"
                                              id="panel1a-header"
                                            >
                                              <Typography></Typography>
                                            </AccordionSummary>
                                          </Grid>
                                        </Grid>

                                        <AccordionDetails
                                          style={{ padding: "0" }}
                                        >
                                          <Grid container spacing={0}>
                                            <Grid xs={12} className="pb-3">
                                              <Grid container spacing={3}>
                                                <Grid
                                                  xs={3}
                                                  style={{
                                                    alignSelf: "center",
                                                  }}
                                                >
                                                  <p>
                                                    {" "}
                                                    {addPoliciesdata
                                                      ?.productType?.name ===
                                                      "Group Super TopUp"
                                                      ? "Super Top-Up Sum Insured"
                                                      : addPoliciesdata
                                                        ?.productType
                                                        ?.name ===
                                                        "Group Term"
                                                        ? "Sum Assured"
                                                        : "Sum Insured"}
                                                  </p>
                                                </Grid>
                                                <Grid xs={9}>
                                                  {/* Multiselect dropdown */}
                                                  {addPoliciesdata.planType
                                                    .gradeCriteria !==
                                                    "Custom" && (
                                                      <MultipleSearchDropdown
                                                        class_name="inputField"
                                                        title={
                                                          addPoliciesdata
                                                            ?.planType
                                                            ?.gradeCriteria
                                                        }
                                                        value={
                                                          data.gradeDivisions
                                                        }
                                                        attrName={[
                                                          "addPoliciesdata",
                                                          "benefitsDetailsGrade",
                                                          "gradeDivisions",
                                                          "input_fields",
                                                          index,
                                                        ]}
                                                        value_update={
                                                          updateMasterState
                                                        }
                                                        multiple_data={
                                                          showdropdownSalarydesignationDep
                                                        }
                                                        warn_status={
                                                          addPoliciesdataWarn
                                                            ?.benefitsDetailsGrade[
                                                            index
                                                          ]?.designation
                                                        }
                                                        error_messg={
                                                          "Please select Grade"
                                                        }
                                                        disabled={
                                                          isPolicyAttachedToMember
                                                        }
                                                      />
                                                    )}
                                                </Grid>
                                                <Grid xs={12}>
                                                  <RKTextField
                                                    class_name="inputField"
                                                    title={
                                                      addPoliciesdata
                                                        ?.productType?.name ===
                                                        "Group Term"
                                                        ? "Sum Assured"
                                                        : "Sum Insured"
                                                    }
                                                    value={formatCurrencyAccToUser(
                                                      Number(data?.sumInsured),
                                                      getCurrencyCodeRegex(
                                                        userState
                                                          .localInformation
                                                          .currency
                                                      ),
                                                      userState.localInformation
                                                        .countryCurrencyCode,
                                                      0
                                                    )}
                                                    attrName={[
                                                      "addPoliciesdata",
                                                      "benefitsDetailsGrade",
                                                      "sumInsured",
                                                      "input_fields",
                                                      index,
                                                    ]}
                                                    value_update={
                                                      updateMasterState
                                                    }
                                                    warn_status={
                                                      addPoliciesdataWarn
                                                        .benefitsDetailsGrade[
                                                        index
                                                      ]?.sumInsured
                                                    }
                                                    error_messg="Enter Sum Insured amount"
                                                    required
                                                  />
                                                </Grid>
                                                {addPoliciesdata?.productType
                                                  ?.name ===
                                                  "Group Super TopUp" ? (
                                                  <Grid
                                                    xs={6}
                                                    className="super_topup"
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "center",
                                                    }}
                                                  >
                                                    <h6 className="mb-0">
                                                      {" "}
                                                      Deductible
                                                    </h6>
                                                    <div
                                                      className="ml-3"
                                                      style={{
                                                        width: "300px",
                                                      }}
                                                    >
                                                      <RKTextField
                                                        class_name="inputField"
                                                        title="Enter value for Deductible"
                                                        value={formatCurrency(
                                                          data?.deductible
                                                        )}
                                                        attrName={[
                                                          "addPoliciesdata",
                                                          "benefitsDetailsGrade",
                                                          "deductible",
                                                          "input_fields",
                                                          index,
                                                        ]}
                                                        value_update={
                                                          updateMasterState
                                                        }
                                                        warn_status={
                                                          addPoliciesdataWarn
                                                            ?.benefitsDetailsGrade[
                                                            index
                                                          ]?.deductible
                                                        }
                                                        error_messg="Enter deductible amount"
                                                        required
                                                      />
                                                    </div>
                                                  </Grid>
                                                ) : (
                                                  ""
                                                )}
                                                <Grid xs={12} width={"100%"}>
                                                  {addPoliciesdata.productType
                                                    .name === "Group Term" && (
                                                      <Grid xs={12}>
                                                        {addPoliciesdata.is_rider &&
                                                          addPoliciesdata.benefits_detail_graded[
                                                            index
                                                          ]?.riders?.map(
                                                            (
                                                              riders: any,
                                                              RiderIndex: number
                                                            ) =>
                                                              riders.label && (
                                                                <Grid
                                                                  container
                                                                  spacing={3}
                                                                >
                                                                  <Grid
                                                                    xs
                                                                    lg={4}
                                                                    className="switchFields"
                                                                    display={
                                                                      "flex"
                                                                    }
                                                                    alignItems="center"
                                                                    sx={{
                                                                      height:
                                                                        "70px",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize:
                                                                          "14px",
                                                                        marginLeft:
                                                                          "12px",
                                                                        fontWeight: 600,
                                                                      }}
                                                                    >
                                                                      {
                                                                        riders?.label
                                                                      }
                                                                    </span>
                                                                    <input
                                                                      type="checkbox"
                                                                      className="switch ml-3"
                                                                      checked={
                                                                        riders.enable
                                                                      }
                                                                      disabled={
                                                                        isPolicyAttachedToMember
                                                                      }
                                                                      onClick={(
                                                                        e
                                                                      ) => {
                                                                        toggleEnableRidersFeatureGrade(
                                                                          e,
                                                                          index,
                                                                          RiderIndex
                                                                        );
                                                                      }}
                                                                    />
                                                                  </Grid>
                                                                  {riders.enable && (
                                                                    <Grid
                                                                      xs
                                                                      lg={4}
                                                                    >
                                                                      <RKTextField
                                                                        class_name="inputField"
                                                                        title={
                                                                          "Rider SA"
                                                                        }
                                                                        value={
                                                                          riders?.amount
                                                                        }
                                                                        disabled={
                                                                          isPolicyAttachedToMember
                                                                        }
                                                                        attrName={[
                                                                          "addPoliciesdata",
                                                                          "benefits_detail_graded",
                                                                          "amount",
                                                                          "input_fields",
                                                                          index,
                                                                          RiderIndex,
                                                                        ]}
                                                                        value_update={
                                                                          updateMasterState
                                                                        }
                                                                        warn_status={
                                                                          addPoliciesdataWarn
                                                                            .benefits_detail_flat[
                                                                            index
                                                                          ]
                                                                            ?.amount
                                                                        }
                                                                      />
                                                                    </Grid>
                                                                  )}
                                                                </Grid>
                                                              )
                                                          )}
                                                      </Grid>
                                                    )}
                                                </Grid>

                                                <Grid xs={12} width={"100%"}>
                                                  <div className="sectionsHeading">
                                                    <h6>Features</h6>

                                                    <div className="actionBtns ">
                                                      <Link
                                                        className="rounded_add_icons addfile blueBtn"
                                                        sx={{
                                                          textDecoration:
                                                            "none",
                                                          cursor: "pointer",
                                                        }}
                                                        onClick={() => {
                                                          setShowLoader(true);
                                                          setAddFeatureExclusionIndex(
                                                            index
                                                          );
                                                          localStorage.setItem(
                                                            "selectedAddPolicy",
                                                            JSON.stringify(
                                                              featuresListdataGraded[
                                                              index
                                                              ]
                                                            )
                                                          );
                                                          setTimeout(() => {
                                                            setAddFeature(true);
                                                          }, 300);
                                                        }}
                                                      >
                                                        Add/Edit
                                                      </Link>
                                                    </div>
                                                  </div>
                                                </Grid>

                                                <Box className="featuresDetail px-0">
                                                  <Grid container spacing={3}>
                                                    {addPoliciesdata?.benefitsDetailsGrade[
                                                      index
                                                    ].features?.map(
                                                      (
                                                        data: any,
                                                        indexx: any
                                                      ) => (
                                                        <>
                                                          <Grid xs={6}>
                                                            <div className="features_list">
                                                              <ul>
                                                                {addPoliciesdataWarn
                                                                  ?.benefitsDetailsGrade[
                                                                  index
                                                                ]?.features[
                                                                  indexx
                                                                ]?.name ===
                                                                  true ? (
                                                                  <span
                                                                    className="inputField"
                                                                    style={{
                                                                      color:
                                                                        "#eb5757",
                                                                      fontSize:
                                                                        "11px",
                                                                      position:
                                                                        "relative",
                                                                      right:
                                                                        "0",
                                                                      bottom:
                                                                        "-20px",
                                                                    }}
                                                                  >
                                                                    Please
                                                                    Update
                                                                    features
                                                                  </span>
                                                                ) : (
                                                                  ""
                                                                )}
                                                                {addPoliciesdata
                                                                  .benefitsDetailsGrade[
                                                                  index
                                                                ].features[
                                                                  indexx
                                                                ].value ? (
                                                                  <li>
                                                                    {data.name}
                                                                    <span>
                                                                      {
                                                                        data.value
                                                                      }{" "}
                                                                      {data.unit ===
                                                                        "Percentage"
                                                                        ? "%"
                                                                        : data.unit ===
                                                                          "Currency"
                                                                          ? ""
                                                                          : data.unit}
                                                                    </span>
                                                                  </li>
                                                                ) : null}
                                                              </ul>
                                                            </div>
                                                          </Grid>
                                                        </>
                                                      )
                                                    )}
                                                  </Grid>
                                                </Box>

                                                <Grid xs={12} width={"100%"}>
                                                  <div className="sectionsHeading">
                                                    <h6>Exclusion</h6>
                                                    <div className="actionBtns ">
                                                      <Link
                                                        className="rounded_add_icons addfile blueBtn"
                                                        sx={{
                                                          textDecoration:
                                                            "none",
                                                          cursor: "pointer",
                                                        }}
                                                        onClick={() => {
                                                          setShowLoader(true);
                                                          setTimeout(() => {
                                                            setAddExclusion(
                                                              true
                                                            );
                                                          }, 300);

                                                          // let updated_data: any =
                                                          //   [];
                                                          // updated_data = [
                                                          //   ...Array(
                                                          //     addPoliciesdata
                                                          //       .benefitsDetailsGrade
                                                          //       .length
                                                          //   ),
                                                          // ].map(
                                                          //   () =>
                                                          //     addPoliciesdata?.planType?.name === "FLAT"? exclusionsListdata:exclusionsListdataGraded
                                                          // );

                                                          localStorage.setItem(
                                                            "addPolicy",
                                                            JSON.stringify(
                                                              exclusionsListdataGraded
                                                            )
                                                          );

                                                          setexclusionsListdataGradedCopy(
                                                            exclusionsListdataGraded
                                                          );

                                                          localStorage.setItem(
                                                            "selectedAddPolicy",
                                                            JSON.stringify(
                                                              exclusionsListdataGraded[
                                                              index
                                                              ]
                                                            )
                                                          );

                                                          setAddFeatureExclusionIndex(
                                                            index
                                                          );
                                                        }}
                                                      >
                                                        Add/Edit
                                                      </Link>
                                                    </div>
                                                  </div>
                                                </Grid>
                                                <Box className="exclusionDetail px-0">
                                                  <Grid container spacing={3}>
                                                    <Grid xs={12}>
                                                      <div className="features_list">
                                                        <ul>
                                                          {addPoliciesdata?.benefitsDetailsGrade[
                                                            index
                                                          ].exclusion?.map(
                                                            (
                                                              data: any,
                                                              indexx: any
                                                            ) => (
                                                              <>
                                                                {addPoliciesdataWarn
                                                                  ?.benefitsDetailsGrade[
                                                                  index
                                                                ]?.exclusion[
                                                                  indexx
                                                                ]?.name ===
                                                                  true ? (
                                                                  <span
                                                                    className="inputField"
                                                                    style={{
                                                                      color:
                                                                        "#eb5757",
                                                                      fontSize:
                                                                        "11px",
                                                                      position:
                                                                        "relative",
                                                                      right:
                                                                        "0",
                                                                      bottom:
                                                                        "-20px",
                                                                    }}
                                                                  >
                                                                    Please
                                                                    Update
                                                                    features
                                                                  </span>
                                                                ) : (
                                                                  ""
                                                                )}

                                                                {data.name ? (
                                                                  <li>
                                                                    {data.name}
                                                                    <span>
                                                                      {
                                                                        data.description
                                                                      }
                                                                    </span>
                                                                  </li>
                                                                ) : (
                                                                  ""
                                                                )}
                                                              </>
                                                            )
                                                          )}
                                                        </ul>
                                                      </div>
                                                    </Grid>
                                                  </Grid>
                                                </Box>
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                        </AccordionDetails>
                                      </Accordion>
                                    )
                                  )}
                              </Box>
                            </Box>
                          ) : null}
                          {addFeature ? (
                            <SelectFeature
                              open_status={addFeature}
                              attrName={setAddFeature}
                              value_update={updateMasterState}
                              data_list={
                                addPoliciesdata?.planType?.name === "FLAT"
                                  ? featuresListdata
                                  : featuresListdataGraded[
                                  AddFeatureexclusionIndex
                                  ]
                              }
                              component_name={addPoliciesdata?.planType.name}
                              index_value={AddFeatureexclusionIndex}
                              leng={
                                addPoliciesdata?.benefitsDetailsGrade.length
                              }
                              productType={addPoliciesdata.productType.name}
                              setShowLoader={setShowLoader}
                            />
                          ) : null}
                          {Addexclusion ? (
                            <Exclusion
                              open_status={Addexclusion}
                              attrName={setAddExclusion}
                              value_update={updateMasterState}
                              data_list={
                                addPoliciesdata?.planType?.name === "FLAT"
                                  ? exclusionsListdata
                                  : exclusionsListdataGraded[
                                  AddFeatureexclusionIndex
                                  ]
                              }
                              component_name={addPoliciesdata?.planType.name}
                              index_value={AddFeatureexclusionIndex}
                              productType={addPoliciesdata.productType.name}
                              setShowLoader={setShowLoader}
                            />
                          ) : null}

                          <SelectvoluntryPlans
                            open_status={selectvoluntryPlans}
                            attrName={setSelectVoluntryPlans}
                            value_update={updateMasterState}
                          />
                        </Grid>
                      </Grid>

                      {/* hide this section  */}
                      {/* <Box className="detailSection">
                <div className="sectionTitle">
                  <h4>Lives Information</h4>
                  <div className="total_lives">
                    <p>
                      300 <span>Total Lives</span>
                    </p>
                  </div>
                </div>
                <div className="policy_lives">
                  <Grid container spacing={3}>
                    <Grid sm={12} md={8} lg={8} xl={9}>
                      {planType === 1 ? (
                        <ul className="coverageAmt">
                          <li>
                            <div className="radioBox ">
                              <input type="radio" name="coverages" />
                              <label>Grade 1</label>
                            </div>
                          </li>
                          <li>
                            <div className="radioBox">
                              <input type="radio" name="coverages" />
                              <label>Grade 2</label>
                            </div>
                          </li>
                          <li>
                            <div className="radioBox">
                              <input
                                type="radio"
                                name="coverages"
                                defaultChecked
                              />
                              <label>Grade 3</label>
                            </div>
                          </li>
                          <li>
                            <div className="radioBox">
                              <input type="radio" name="coverages" />
                              <label>Grade 4</label>
                            </div>
                          </li>
                          <li>
                            <div className="radioBox">
                              <input type="radio" name="coverages" />
                              <label>Grade 5</label>
                            </div>
                          </li>
                        </ul>
                      ) : null}
                    </Grid>

                    <Grid sm={12} md={2} lg={2} xl={1}>
                      {planType === 1 ? (
                        <RKTextField
                          class_name="inputField"
                          title="Grade 1 Lives"
                          value={spouseAge}
                          attrName={setSpouseAge}
                          value_update={updateMasterState}
                          warn_status={false}
                        />
                      ) : null}
                    </Grid>

                    <Grid sm={12} md={2} lg={2} xl={2}>
                      <SelectDropdown
                        class_name="inputField"
                        title="Sum Insured"
                        value={sumInsuredOne}
                        attrName={setSumInsuredOne}
                        value_update={updateMasterState}
                        dropdown_data={sumInsuredOne_data}
                        warn_status={false}
                      />
                    </Grid>
                    <Grid xs={12}>
                      <div className="table">
                        <div className="age-table">
                          <ul className="heading">
                            <li>0 - 18 yrs</li>
                            <li>19 yrs - 35 yrs</li>
                            <li>36 yrs - 45 yrs</li>
                            <li>46 yrs - 55 yrs</li>
                            <li>56 yrs - 60 yrs</li>
                            <li>61 yrs - 65 yrs</li>
                            <li>66 yrs - 70 yrs</li>
                            <li>71 yrs - 75 yrs</li>
                            <li>76 yrs - 80 yrs</li>
                          </ul>

                          {ageData.map((agemainData, agemainIndex) => (
                            <ul className="subheading">
                              {agemainData.map((agesubData, agesubIndex) => (
                                <li>
                                  <RKTextField
                                    class_name="inputField"
                                    title=""
                                    value={agesubData.value}
                                    attrName={[
                                      "ageData",
                                      agemainIndex,
                                      agesubIndex,
                                      agesubData.key,
                                    ]}
                                    value_update={updateMasterState}
                                    warn_status={false}
                                  />
                                </li>
                              ))}
                            </ul>
                          ))}
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </Box> */}
                      {/* premium Details */}
                      <Grid
                        ref={premiumdetails}
                        onMouseEnter={() => {
                          SetSectionStatus("premiumdetails");
                        }}
                        container
                        spacing={3}
                        className="px-0"
                      >
                        <Grid xs={12}>
                          <Box className="detailSection mb-0">
                            <div className="sectionTitle">
                              <h4>Premium Details</h4>
                            </div>
                            <Grid container spacing={3}>
                              <Grid xs={6} xl={3}>
                                <RKTextField
                                  class_name="inputField"
                                  title="Net Premium"
                                  //value={addPoliciesdata?.premiumDetails?.netPremium}
                                  value={formatCurrencyAccToUser(
                                    addPoliciesdata.premiumDetails.netPremium,
                                    getCurrencyCodeRegex(
                                      userState.localInformation.currency
                                    ),
                                    userState.localInformation
                                      .countryCurrencyCode,
                                    0
                                  )}
                                  attrName={[
                                    "addPoliciesdata",
                                    "premiumDetails",
                                    "netPremium",
                                    "input_fields",
                                  ]}
                                  value_update={updateMasterState}
                                  warn_status={
                                    addPoliciesdataWarn.premiumDetails
                                      .netPremium
                                  }
                                  validation_type="numeric"
                                  error_messg="Enter Net Premium amount"
                                  required
                                />
                              </Grid>
                              <Grid xs={6} xl={3}>
                                <MultipleSearchDropdown
                                  class_name="inputField"
                                  title="Select Applicable Tax/Taxes"
                                  value={
                                    addPoliciesdata?.premiumDetails
                                      ?.taxPercentage
                                  }
                                  attrName={[
                                    "addPoliciesdata",
                                    "premiumDetails",
                                    "taxPercentage",
                                    "input_fields",
                                  ]}
                                  value_update={updateMasterState}
                                  warn_status={
                                    addPoliciesdataWarn.premiumDetails
                                      .taxPercentage
                                  }
                                  multiple_data={taxDropDown}
                                  required
                                  disabled={isPolicyAttachedToMember}
                                />
                              </Grid>
                              <Grid xs={6} xl={3}>
                                <RKTextField
                                  class_name="inputField"
                                  title="Tax Amount"
                                  //value={addPoliciesdata?.premiumDetails?.taxAmount}
                                  value={formatCurrencyAccToUser(
                                    addPoliciesdata?.premiumDetails?.taxAmount,
                                    getCurrencyCodeRegex(
                                      userState.localInformation.currency
                                    ),
                                    userState.localInformation
                                      .countryCurrencyCode,
                                    0
                                  )}
                                  attrName={[
                                    "addPoliciesdata",
                                    "premiumDetails",
                                    "taxAmount",
                                    "input_fields",
                                  ]}
                                  value_update={updateMasterState}
                                  warn_status={false}
                                  validation_type="numeric"
                                  required
                                  disabled={true}
                                />
                              </Grid>
                              <Grid xs={6} xl={3}>
                                <RKTextField
                                  class_name="inputField"
                                  title="Gross Premium"
                                  value={formatCurrencyAccToUser(
                                    addPoliciesdata?.premiumDetails
                                      ?.grossPremium,
                                    getCurrencyCodeRegex(
                                      userState.localInformation.currency
                                    ),
                                    userState.localInformation
                                      .countryCurrencyCode,
                                    0
                                  )}
                                  //value={addPoliciesdata?.premiumDetails?.grossPremium}
                                  attrName={[
                                    "addPoliciesdata",
                                    "premiumDetails",
                                    "grossPremium",
                                    "input_fields",
                                  ]}
                                  value_update={updateMasterState}
                                  warn_status={false}
                                  validation_type="numeric"
                                  required
                                  disabled={isPolicyAttachedToMember}
                                />
                              </Grid>
                            </Grid>
                          </Box>
                        </Grid>
                      </Grid>

                      {/* Plan Settings */}
                      <Grid container spacing={3} className="px-0 ">
                        <Grid xs={12}>
                          <Box
                            className="detailSection"
                            ref={planSetting}
                            onMouseEnter={() => {
                              SetSectionStatus("planSetting");
                            }}
                          >
                            <div className="sectionTitle">
                              <h4>Plans Settings</h4>
                            </div>
                            <Grid container spacing={3}>
                              <Grid xs={4}>
                                <RKTextField
                                  class_name="inputField"
                                  title="Critical CD Balance Amount"
                                  value={formatCurrency(
                                    Number(
                                      addPoliciesdata?.policy?.criticalCd
                                        ? addPoliciesdata?.policy?.criticalCd
                                        : null
                                    )
                                  )}
                                  attrName={[
                                    "addPoliciesdata",
                                    "policy",
                                    "criticalCd",
                                    "input_fields",
                                  ]}
                                  value_update={updateMasterState}
                                  warn_status={
                                    addPoliciesdataWarn.policy.criticalCd
                                  }
                                  validation_type="numeric"
                                  required
                                  error_messg="Please enter CD balance amount"
                                  disabled={isPolicyAttachedToMember}
                                />
                              </Grid>
                              <Grid xs={4}>
                                <RKTextField
                                  class_name="inputField"
                                  title="Critical CD Balance %"
                                  value={
                                    addPoliciesdata?.policy?.criticalPercentage
                                  }
                                  attrName={[
                                    "addPoliciesdata",
                                    "policy",
                                    "criticalPercentage",
                                    "input_fields",
                                  ]}
                                  value_update={updateMasterState}
                                  warn_status={false}
                                  validation_type="numericfloatvalue"
                                  required
                                  disabled={isPolicyAttachedToMember}
                                />
                              </Grid>
                              <Grid xs={4}>
                                <DatePicker
                                  class_name="inputField"
                                  title="Enrolment Start Date"
                                  value={
                                    addPoliciesdata?.policy?.enrolmentStartDate
                                  }
                                  attrName={[
                                    "addPoliciesdata",
                                    "policy",
                                    "enrolmentStartDate",
                                    "date_picker",
                                  ]}
                                  value_update={updateMasterState}
                                  error_message="Please enter Start Date"
                                  warn_status={
                                    addPoliciesdataWarn.policy
                                      .enrolmentStartDate
                                  }
                                  // min_date={
                                  //   addPoliciesdata?.policyDetails?.startDate
                                  // }
                                  // max_date={
                                  //   addPoliciesdata?.policyDetails?.endDate
                                  // }
                                  required
                                  disabled={isPolicyAttachedToMember}
                                />
                              </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                              <Grid xs={4}>
                                <DatePicker
                                  class_name="inputField"
                                  title="Enrolment End Date"
                                  value={
                                    addPoliciesdata?.policy?.enrolmentEndDate
                                  }
                                  attrName={[
                                    "addPoliciesdata",
                                    "policy",
                                    "enrolmentEndDate",
                                    "date_picker",
                                  ]}
                                  value_update={updateMasterState}
                                  error_message="Please enter End Date"
                                  warn_status={
                                    addPoliciesdataWarn.policy.enrolmentEndDate
                                  }
                                  min_date={
                                    addPoliciesdata?.policy?.enrolmentStartDate
                                      ? addPoliciesdata?.policy
                                        ?.enrolmentStartDate
                                      : undefined
                                  }
                                  max_date={
                                    addPoliciesdata?.policyDetails?.endDate
                                  }
                                  required
                                  disabled={isPolicyAttachedToMember}
                                />
                              </Grid>
                              <Grid xs={4}>
                                <RKTextField
                                  class_name="inputField"
                                  title="Enrolment Peroid"
                                  value={
                                    addPoliciesdata?.policy?.period
                                      ? `${addPoliciesdata?.policy?.period} Days`
                                      : ""
                                  }
                                  attrName={[
                                    "addPoliciesdata",
                                    "policy",
                                    "period",
                                    "input_disabled",
                                  ]}
                                  value_update={updateMasterState}
                                  warn_status={false}
                                  disabled
                                />
                              </Grid>
                              <Grid xs={4}>
                                <SelectDropdown
                                  class_name="inputField"
                                  title="Grace Period"
                                  value={addPoliciesdata?.policy?.gracePeriod}
                                  attrName={[
                                    "addPoliciesdata",
                                    "policy",
                                    "gracePeriod",
                                    "drop_down_common",
                                  ]}
                                  value_update={updateMasterState}
                                  dropdown_data={one_to_thirty_days_data}
                                  warn_status={false}
                                  required
                                  disabled={isPolicyAttachedToMember}
                                />
                              </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                              <Grid xs={4}>
                                <SelectDropdown
                                  class_name="inputField"
                                  title="Maximum Members per Family"
                                  value={
                                    addPoliciesdata?.policy?.maxMemberPerFamily
                                  }
                                  attrName={[
                                    "addPoliciesdata",
                                    "policy",
                                    "maxMemberPerFamily",
                                    "drop_down_common",
                                  ]}
                                  value_update={updateMasterState}
                                  dropdown_data={one_to_ten_data}
                                  warn_status={false}
                                  required
                                  disabled={isPolicyAttachedToMember}
                                />
                              </Grid>
                              <Grid xs={4}>
                                <SelectDropdown
                                  class_name="inputField"
                                  title="Total Members per Family"
                                  value={
                                    addPoliciesdata?.policy
                                      ?.totalMemberPerFamily
                                  }
                                  attrName={[
                                    "addPoliciesdata",
                                    "policy",
                                    "totalMemberPerFamily",
                                    "drop_down_common",
                                  ]}
                                  value_update={updateMasterState}
                                  dropdown_data={one_to_ten_data}
                                  warn_status={false}
                                  required
                                  disabled={isPolicyAttachedToMember}
                                />
                              </Grid>
                              <Grid xs={4}>
                                <SelectDropdown
                                  class_name="inputField"
                                  title="New Employee Enrolment Period"
                                  value={
                                    addPoliciesdata?.policy?.newMemberPeriod
                                  }
                                  attrName={[
                                    "addPoliciesdata",
                                    "policy",
                                    "newMemberPeriod",
                                    "drop_down_common",
                                  ]}
                                  value_update={updateMasterState}
                                  dropdown_data={one_to_thirty_days_data}
                                  warn_status={false}
                                  required
                                  disabled={isPolicyAttachedToMember}
                                />
                              </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                              <Grid
                                xs={4}
                                className="switchFields"
                                display={"flex"}
                                alignItems="center"
                                sx={{ height: "70px" }}
                              >
                                <span>Nomination</span>
                                {addPoliciesdata?.policy?.nomination ===
                                  true ? (
                                  <input
                                    type="checkbox"
                                    checked
                                    className="switch ml-3"
                                    onClick={(e) => {
                                      toggleShowNominee(e);
                                    }}
                                    disabled={isPolicyAttachedToMember}
                                  />
                                ) : (
                                  <input
                                    type="checkbox"
                                    className="switch ml-3"
                                    onClick={(e) => {
                                      toggleShowNominee(e);
                                    }}
                                    disabled={isPolicyAttachedToMember}
                                  />
                                )}
                                {addPoliciesdata?.policy?.nomination ===
                                  true ? (
                                  <div
                                    className="ml-2"
                                    style={{ width: "300px" }}
                                  >
                                    <SelectDropdown
                                      class_name="inputField"
                                      title="Maximum No. of Nominee"
                                      value={
                                        addPoliciesdata?.policy?.maxNomination
                                      }
                                      attrName={[
                                        "addPoliciesdata",
                                        "policy",
                                        "maxNomination",
                                        "drop_down_common",
                                      ]}
                                      value_update={updateMasterState}
                                      dropdown_data={one_to_ten_data}
                                      warn_status={
                                        addPoliciesdataWarn.policy.maxNomination
                                      }
                                      disabled={isPolicyAttachedToMember}
                                    />
                                  </div>
                                ) : null}
                              </Grid>
                              <Grid
                                xs={4}
                                className="switchFields"
                                display={"flex"}
                                alignItems="center"
                                sx={{ height: "70px" }}
                              >
                                <span>Health Card</span>
                                {addPoliciesdata?.policy?.healthCard ===
                                  true ? (
                                  <input
                                    type="checkbox"
                                    checked
                                    className="switch ml-3"
                                    onClick={(e) => {
                                      toggleShowhealth(e);
                                    }}
                                    disabled={isPolicyAttachedToMember}
                                  />
                                ) : (
                                  <input
                                    type="checkbox"
                                    className="switch ml-3"
                                    onClick={(e) => {
                                      toggleShowhealth(e);
                                    }}
                                    disabled={isPolicyAttachedToMember}
                                  />
                                )}

                                {addPoliciesdata?.policy?.healthCard ===
                                  true ? (
                                  <div
                                    className="ml-2"
                                    style={{ width: "300px" }}
                                  >
                                    <SelectDropdown
                                      class_name="inputField"
                                      title="Card Type"
                                      value={addPoliciesdata?.policy?.cardType}
                                      attrName={[
                                        "addPoliciesdata",
                                        "policy",
                                        "cardType",
                                        "drop_down_common",
                                      ]}
                                      value_update={updateMasterState}
                                      dropdown_data={cardType_data}
                                      warn_status={
                                        addPoliciesdataWarn.policy.cardType
                                      }
                                      disabled={isPolicyAttachedToMember}
                                    />
                                  </div>
                                ) : null}
                              </Grid>
                              <Grid xs={4}>
                                <SelectDropdown
                                  class_name="inputField"
                                  title="Health Card Issued"
                                  value={
                                    addPoliciesdata?.policy?.healthCardIssued
                                  }
                                  attrName={[
                                    "addPoliciesdata",
                                    "policy",
                                    "healthCardIssued",
                                    "drop_down_common",
                                  ]}
                                  value_update={updateMasterState}
                                  dropdown_data={healthcardIssued_data}
                                  warn_status={
                                    addPoliciesdataWarn.policy.healthCardIssued
                                  }
                                  disabled={
                                    !isPolicyAttachedToMember &&
                                      addPoliciesdata?.policy?.cardType &&
                                      addPoliciesdata?.policy?.healthCard
                                      ? false
                                      : true
                                  }
                                />
                              </Grid>
                              <Grid xs={12} className="px-0">
                                <>
                                  <Grid container spacing={3}>
                                    {/* <Grid
                                    xs={4}
                                    className="switchFields"
                                    display={"flex"}
                                    alignItems="center"
                                    sx={{ height: "70px" }}
                                  >
                                    <span>Corporate Buffer</span>
                                    {addPoliciesdata?.policy
                                      ?.isCorporateBuffer === true ? (
                                      <input
                                        type="checkbox"
                                        checked
                                        className="switch ml-3"
                                        onClick={(e) => {
                                          toggleShowcorporate(e);
                                        }}
                                        disabled={isPolicyAttachedToMember}
                                      />
                                    ) : (
                                      <input
                                        type="checkbox"
                                        className="switch ml-3"
                                        onClick={(e) => {
                                          toggleShowcorporate(e);
                                        }}
                                        disabled={isPolicyAttachedToMember}
                                      />
                                    )}

                                    {addPoliciesdata?.policy
                                      ?.isCorporateBuffer === true ? (
                                      <div
                                        className="ml-2"
                                        style={{ width: "350px" }}
                                      >
                                        <RKTextField
                                          class_name="inputField"
                                          title="Corporate Buffer Value"
                                          value={formatCurrency(
                                            Number(
                                              addPoliciesdata?.policy
                                                ?.corporateBuffer
                                                ? addPoliciesdata?.policy
                                                  ?.corporateBuffer
                                                : null
                                            )
                                          )}
                                          attrName={[
                                            "addPoliciesdata",
                                            "policy",
                                            "corporateBuffer",
                                            "drop_down_common",
                                          ]}
                                          value_update={updateMasterState}
                                          warn_status={
                                            addPoliciesdataWarn.policy
                                              .corporateBuffer
                                          }
                                          validation_type="numeric"
                                          required
                                          error_messg="Please enter corporate buffer value"
                                          disabled={isPolicyAttachedToMember}
                                        />
                                      </div>
                                    ) : null}
                                  </Grid> */}

                                    <Grid xs={4}>
                                      <SelectDropdown
                                        class_name="inputField"
                                        title="Pro- Rata Premium Pricing By"
                                        value={
                                          addPoliciesdata.policy.proreta_by
                                        }
                                        attrName={[
                                          "addPoliciesdata",
                                          "policy",
                                          "proreta_by",
                                          "drop_down_common",
                                        ]}
                                        value_update={updateMasterState}
                                        dropdown_data={prorata}
                                        warn_status={false}
                                        required
                                        disabled={isPolicyAttachedToMember}
                                      />
                                    </Grid>
                                  </Grid>
                                </>
                              </Grid>
                            </Grid>
                          </Box>
                        </Grid>
                      </Grid>

                      {/* PolicyDetails */}
                      <Grid container spacing={3} className="px-0">
                        <Grid xs={12}>
                          <Box
                            className={`detailSection ${addPoliciesdata.is_rider ? "" : "lastsection"
                              }`}
                            onMouseEnter={() => {
                              SetSectionStatus("detailspolicy");
                            }}
                            ref={detailspolicy}
                          >
                            <div className="sectionTitle">
                              <h4>Policy Details</h4>
                            </div>
                            <Grid container spacing={3}>
                              <Grid xs={4}>
                                <DatePicker
                                  class_name="inputField"
                                  title="Policy Start Date"
                                  value={
                                    addPoliciesdata?.policyDetails?.startDate
                                  }
                                  attrName={[
                                    "addPoliciesdata",
                                    "policyDetails",
                                    "startDate",
                                    "date",
                                  ]}
                                  value_update={updateMasterState}
                                  error_message="Please enter start Date"
                                  warn_status={
                                    addPoliciesdataWarn.policyDetails.startDate
                                  }
                                  show_future={false}
                                  required
                                  min_date={add_days_in_date_getDateObject(
                                    addPoliciesdata.policy.enrolmentEndDate,
                                    (
                                      Number(
                                        extractNumbersFromString(
                                          addPoliciesdata.policy.gracePeriod
                                        )
                                      ) + 1
                                    ).toString()
                                  ).toDate()}
                                  disabled={isPolicyAttachedToMember}
                                />
                              </Grid>
                              <Grid xs={4}>
                                <DatePicker
                                  class_name="inputField"
                                  title="Policy End Date"
                                  value={
                                    addPoliciesdata?.policyDetails?.endDate
                                  }
                                  attrName={[
                                    "addPoliciesdata",
                                    "policyDetails",
                                    "endDate",
                                    "date",
                                  ]}
                                  value_update={updateMasterState}
                                  error_message="Please enter End Date"
                                  warn_status={
                                    addPoliciesdataWarn.policyDetails.endDate
                                  }
                                  show_future={false}
                                  required
                                  disabled={isPolicyAttachedToMember || true}
                                />
                              </Grid>

                              {/* <Grid xs={4}>
                              <RKTextField
                                class_name="inputField"
                                title="CD Balance"
                                value={formatCurrencyAccToUser(
                                  addPoliciesdata?.policyDetails?.cdBalance,
                                  getCurrencyCodeRegex(
                                    userState.localInformation.currency
                                  ),
                                  userState.localInformation
                                    .countryCurrencyCode,
                                  0
                                )}
                                //value={addPoliciesdata?.policyDetails?.cdBalance}
                                attrName={[
                                  "addPoliciesdata",
                                  "policyDetails",
                                  "cdBalance",
                                  "input_fields",
                                ]}
                                value_update={updateMasterState}
                                warn_status={
                                  addPoliciesdataWarn.policyDetails.cdBalance
                                }
                                validation_type="numeric"
                                required
                                error_messg="Please enter CD balance"
                                disabled={isPolicyAttachedToMember}
                              />
                            </Grid> */}
                            </Grid>
                          </Box>
                        </Grid>
                      </Grid>
                      {/* Relationship-Lives Information */}
                      <Grid container spacing={3} className="px-0">
                        <Grid xs={12}>
                          <Box
                            className={`detailSection ${addPoliciesdata.is_rider ? "" : "lastsection"
                              }`}
                            onMouseEnter={() => {
                              SetSectionStatus("relationLives");
                            }}
                            ref={relationLives}
                          >
                            <div className="sectionTitle">
                              <h4>Relationship-Lives Information</h4>
                              <div className="total_lives">
                                <p>
                                  {indivisualGradeLives
                                    ? indivisualGradeLives
                                    : 0}{" "}
                                  <span>Total Lives</span>
                                </p>
                              </div>
                            </div>

                            <div className="policy_lives">
                              <Grid container spacing={3}>
                                {addPoliciesdata?.planType?.name === "FLAT" ? (
                                  <>
                                    <Grid xs={12}>
                                      <div className="table">
                                        <div className="livesinformation-table">
                                          <ul className="heading">
                                            <li>Age Band (in years)</li>
                                            <li>
                                              Premium Pricing for
                                              Addition/Deletion per Member
                                            </li>
                                            <li>Premium Pricing of Per Day</li>
                                            {addPoliciesdata?.planType?.coverageType?.map(
                                              (data: any) => (
                                                <li>{titleCase(data.name)}</li>
                                              )
                                            )}

                                            <li>Total Lives Count/Age Band</li>
                                          </ul>

                                          {modifiedArrayAgeBand?.map(
                                            (data: any, ageindex: any) => (
                                              <ul
                                                className="subheading"
                                                key={ageindex}
                                              >
                                                <li>
                                                  {data.min}-{data.max}
                                                </li>
                                                {data.coverageTypeData?.map(
                                                  (
                                                    dataArr: any,
                                                    index: number
                                                  ) => (
                                                    <li key={index}>
                                                      <RKTextField
                                                        validation_type="numeric"
                                                        class_name="inputField"
                                                        title={
                                                          index === 0 ||
                                                            index === 1
                                                            ? "Amount"
                                                            : "Lives"
                                                        }
                                                        value={
                                                          index === 0
                                                            ? formatCurrency(
                                                              Number(
                                                                dataArr.value
                                                              )
                                                            )
                                                            : index === 1
                                                              ? formatCurrency(
                                                                Number(
                                                                  dataArr.value
                                                                ),
                                                                2
                                                              )
                                                              : dataArr.value
                                                        }
                                                        attrName={[
                                                          ageindex,
                                                          index,
                                                          "flatData",
                                                        ]}
                                                        value_update={
                                                          dataArr.name ===
                                                            "total Live"
                                                            ? () => { }
                                                            : updateMasterStateLives
                                                        }
                                                        warn_status={false}
                                                        charcterlimit={
                                                          dataArr.name ===
                                                            "amount"
                                                            ? 12
                                                            : dataArr.name ===
                                                              "total Live"
                                                              ? 10
                                                              : 5
                                                        }
                                                        disabled={
                                                          index === 0
                                                            ? false
                                                            : true
                                                        }
                                                      />
                                                    </li>
                                                  )
                                                )}
                                              </ul>
                                            )
                                          )}
                                        </div>
                                      </div>
                                    </Grid>
                                  </>
                                ) : addPoliciesdata.planType.name ===
                                  "GRADED" ? (
                                  <Grid sm={12} md={12} lg={12}>
                                    <Grid container spacing={3}>
                                      {modifiedArrayAgeBand?.length > 0 && (
                                        <>
                                          <Grid xs={8}>
                                            <ul className="coverageAmt mb-4">
                                              {modifiedArrayAgeBand &&
                                                modifiedArrayAgeBand.map(
                                                  (
                                                    data: any,
                                                    mainIndex: any
                                                  ) => (
                                                    <li key={mainIndex}>
                                                      <div className="radioBox ">
                                                        <input
                                                          type="radio"
                                                          name="coveragesd"
                                                          onClick={() =>
                                                            setliveGraded(
                                                              mainIndex
                                                            )
                                                          }
                                                          checked={
                                                            liveGraded ===
                                                              mainIndex
                                                              ? true
                                                              : false
                                                          }
                                                        />
                                                        <label>
                                                          Grade {mainIndex + 1}
                                                        </label>
                                                      </div>
                                                    </li>
                                                  )
                                                )}
                                            </ul>
                                          </Grid>

                                          <Grid xs={12}>
                                            <div className="table">
                                              <div className="livesinformation-table">
                                                <ul className="heading">
                                                  <li>Age Band (in years)</li>
                                                  <li>
                                                    Premium Pricing for
                                                    Addition/Deletion per Member
                                                  </li>
                                                  <li>
                                                    Premium Pricing of Per Day
                                                  </li>
                                                  {addPoliciesdata?.planType?.coverageType?.map(
                                                    (data: any) => (
                                                      <li>
                                                        {titleCase(data.name)}
                                                      </li>
                                                    )
                                                  )}

                                                  <li>
                                                    Total Lives Count/Age Band
                                                  </li>
                                                </ul>
                                                {modifiedArrayAgeBand[
                                                  liveGraded
                                                ]?.data?.length > 0 &&
                                                  modifiedArrayAgeBand[
                                                    liveGraded
                                                  ]?.data?.map(
                                                    (
                                                      data: any,
                                                      ageindex: any
                                                    ) => (
                                                      <ul
                                                        key={ageindex}
                                                        className="subheading"
                                                      >
                                                        <li>
                                                          {data.min}-{data.max}
                                                        </li>
                                                        {data?.coverageTypeData?.map(
                                                          (
                                                            dataArr: any,
                                                            index: number
                                                          ) => (
                                                            <li>
                                                              <RKTextField
                                                                validation_type="numeric"
                                                                class_name="inputField"
                                                                title={
                                                                  // data
                                                                  //   ?.coverageTypeData
                                                                  //   .length -
                                                                  //   2 ===
                                                                  index === 0 ||
                                                                    index === 1
                                                                    ? "Amount"
                                                                    : "Lives"
                                                                }
                                                                value={
                                                                  index === 0
                                                                    ? formatCurrencyAccToUser(
                                                                      dataArr?.value,
                                                                      getCurrencyCodeRegex(
                                                                        userState
                                                                          .localInformation
                                                                          .currency
                                                                      ),
                                                                      userState
                                                                        .localInformation
                                                                        .countryCurrencyCode,
                                                                      0
                                                                    )
                                                                    : index ===
                                                                      1
                                                                      ? formatCurrencyAccToUser(
                                                                        dataArr?.value,
                                                                        getCurrencyCodeRegex(
                                                                          userState
                                                                            .localInformation
                                                                            .currency
                                                                        ),
                                                                        userState
                                                                          .localInformation
                                                                          .countryCurrencyCode,
                                                                        2
                                                                      )
                                                                      : dataArr?.value
                                                                }
                                                                attrName={[
                                                                  ageindex,
                                                                  index,
                                                                  "gradeData",
                                                                ]}
                                                                value_update={
                                                                  dataArr?.name ===
                                                                    "total Live"
                                                                    ? () => { }
                                                                    : updateMasterStateLives
                                                                }
                                                                warn_status={
                                                                  false
                                                                }
                                                                charcterlimit={
                                                                  dataArr?.name ===
                                                                    "amount"
                                                                    ? 12
                                                                    : dataArr?.name ===
                                                                      "total Live"
                                                                      ? 10
                                                                      : 5
                                                                }
                                                                disabled={
                                                                  // data
                                                                  //   ?.coverageTypeData
                                                                  //   .length -
                                                                  //   2 ===
                                                                  index === 0
                                                                    ? false
                                                                    : true
                                                                }
                                                              />
                                                            </li>
                                                          )
                                                        )}
                                                      </ul>
                                                    )
                                                  )}
                                              </div>
                                            </div>
                                          </Grid>
                                        </>
                                      )}
                                    </Grid>
                                  </Grid>
                                ) : (
                                  ""
                                )}
                              </Grid>
                            </div>
                          </Box>
                        </Grid>
                      </Grid>
                      {/* prorata rates for riders in group Term */}
                      {addPoliciesdata.is_rider && (
                        <Grid container spacing={3} className="px-0">
                          <Grid xs={12}>
                            <Box
                              className="detailSection lastsection"
                              ref={RiderDetail}
                              onMouseEnter={() => {
                                SetSectionStatus("RiderDetail");
                              }}
                            >
                              <div className="sectionTitle">
                                <h4>Rider Details</h4>
                              </div>

                              {addPoliciesdata?.planType?.name ===
                                PolicyCoverageType.FLAT &&
                                addPoliciesdata.rider_premium_flat.map(
                                  (
                                    rider_premium_flat: any,
                                    riderIndex: number
                                  ) => (
                                    <>
                                      <Grid xs={12}>
                                        <h5 className="subHeading">
                                          {rider_premium_flat.rider_name}
                                        </h5>
                                      </Grid>
                                      <Grid
                                        container
                                        spacing={3}
                                        className="ml-1 mr-1"
                                      >
                                        <Grid xs={12}>
                                          <Box className="detailSection">
                                            <div className="sectionTitle">
                                              <h4>
                                                Rider Premium Split
                                                Configuration & Rider Premium
                                                Pricing
                                              </h4>
                                            </div>

                                            <div className="policy_lives">
                                              <Grid container spacing={3}>
                                                <>
                                                  <Grid xs={12}>
                                                    <Grid xs={4}>
                                                      <Grid xs={12}>
                                                        <h5 className="subHeadingh">
                                                          Premium value
                                                        </h5>
                                                      </Grid>
                                                      <SelectDropdown
                                                        class_name="inputField"
                                                        title="Pro- Rata Premium Pricing By"
                                                        value={
                                                          rider_premium_flat.proreta_by
                                                        }
                                                        attrName={[
                                                          "addPoliciesdata",
                                                          "rider_premium_flat",
                                                          "proreta_by",
                                                          riderIndex,
                                                        ]}
                                                        value_update={
                                                          updateMasterState
                                                        }
                                                        dropdown_data={prorata}
                                                        warn_status={false}
                                                        required
                                                        disabled={
                                                          isPolicyAttachedToMember
                                                        }
                                                      />
                                                    </Grid>
                                                    <Grid container spacing={3}>
                                                      <Grid
                                                        sm={6}
                                                        md={6}
                                                        lg={3}
                                                        xl={3}
                                                      >
                                                        <Grid xs={12}>
                                                          <h5 className="subHeadingh">
                                                            Premium Split
                                                            Configuration
                                                          </h5>
                                                        </Grid>
                                                        <div className="payment_inner_section">
                                                          <RKTextField
                                                            class_name="inputField"
                                                            // type="number"
                                                            title={"Employee"}
                                                            value={
                                                              rider_premium_flat?.premium_split
                                                            }
                                                            attrName={[
                                                              "addPoliciesdata",
                                                              "rider_premium_flat",
                                                              "premium_split",
                                                              riderIndex,
                                                            ]}
                                                            value_update={
                                                              updateMasterState
                                                            }
                                                            warn_status={false}
                                                            inputProps={{
                                                              endAdornment: (
                                                                <InputAdornment position="end">
                                                                  %
                                                                </InputAdornment>
                                                              ),
                                                            }}
                                                            error_messg="Enter Split amount"
                                                            disabled={
                                                              isPolicyAttachedToMember
                                                            }
                                                          />
                                                        </div>
                                                      </Grid>
                                                    </Grid>
                                                    <div className="table mt-2">
                                                      <div className="livesinformation-table">
                                                        <Grid xs={12}>
                                                          <h5 className="subHeadingh">
                                                            Premium Pricing
                                                          </h5>
                                                        </Grid>
                                                        <ul className="heading">
                                                          {rider_premium_flat?.livesInfoFlat?.map(
                                                            (
                                                              data: any,
                                                              ageindex: any
                                                            ) => (
                                                              <li>
                                                                {data.min}-
                                                                {data.max}
                                                              </li>
                                                            )
                                                          )}
                                                        </ul>

                                                        <ul className="subheading">
                                                          {rider_premium_flat?.livesInfoFlat?.map(
                                                            (
                                                              data: any,
                                                              ageindex: any
                                                            ) => (
                                                              <li>
                                                                <RKTextField
                                                                  validation_type="numeric"
                                                                  class_name="inputField mb-4"
                                                                  title={
                                                                    "Amount"
                                                                  }
                                                                  value={formatCurrency(
                                                                    Number(
                                                                      data
                                                                        ?.coverageTypeData[0]
                                                                        .value
                                                                    )
                                                                  )}
                                                                  attrName={[
                                                                    "addPoliciesdata",
                                                                    "rider_premium_flat",
                                                                    riderIndex,
                                                                    ageindex,
                                                                  ]}
                                                                  value_update={
                                                                    updateMasterState
                                                                  }
                                                                  warn_status={
                                                                    false
                                                                  }
                                                                  disabled={
                                                                    isPolicyAttachedToMember
                                                                  }
                                                                />
                                                                <RKTextField
                                                                  validation_type="numeric"
                                                                  class_name="inputField"
                                                                  title={
                                                                    "Per Day Amount"
                                                                  }
                                                                  value={formatCurrency(
                                                                    Number(
                                                                      data
                                                                        ?.coverageTypeData[1]
                                                                        ?.value ||
                                                                      0
                                                                    ),
                                                                    2
                                                                  )}
                                                                  attrName={[
                                                                    "addPoliciesdata",
                                                                    "rider_premium_flat",
                                                                    riderIndex,
                                                                    ageindex,
                                                                  ]}
                                                                  value_update={() => { }}
                                                                  warn_status={
                                                                    false
                                                                  }
                                                                  disabled
                                                                />
                                                              </li>
                                                            )
                                                          )}
                                                        </ul>
                                                      </div>
                                                    </div>
                                                  </Grid>
                                                </>
                                              </Grid>
                                            </div>
                                          </Box>
                                        </Grid>
                                      </Grid>
                                    </>
                                  )
                                )}

                              {addPoliciesdata?.planType?.name ===
                                PolicyCoverageType.GRADED &&
                                addPoliciesdata.rider_premium_graded.map(
                                  (
                                    rider_premium_flat: any,
                                    riderIndex: number
                                  ) => (
                                    <>
                                      <Grid xs={12}>
                                        <h5 className="subHeading">
                                          {rider_premium_flat.rider_name}
                                        </h5>
                                      </Grid>
                                      <Grid
                                        container
                                        spacing={3}
                                        className="ml-1 mr-1"
                                      >
                                        <Grid xs={12}>
                                          <Box className="detailSection">
                                            <div className="sectionTitle">
                                              <h4>
                                                Rider Premium Split
                                                Configuration & Rider Premium
                                                Pricing
                                              </h4>
                                            </div>

                                            <div className="policy_lives">
                                              <Grid container spacing={3}>
                                                <Grid sm={12} md={12} lg={12}>
                                                  <Grid container spacing={3}>
                                                    <Grid
                                                      sm={6}
                                                      md={6}
                                                      lg={4}
                                                      xl={4}
                                                      width={"12%"}
                                                    >
                                                      <Grid xs={12}>
                                                        <h5 className="subHeadingh">
                                                          Premium value
                                                        </h5>
                                                      </Grid>
                                                      <div className="payment_inner_section">
                                                        <SelectDropdown
                                                          class_name="inputField"
                                                          title="Pro- Rata Premium Pricing By"
                                                          value={
                                                            rider_premium_flat.proreta_by
                                                          }
                                                          attrName={[
                                                            "addPoliciesdata",
                                                            "rider_premium_graded",
                                                            "proreta_by",
                                                            riderIndex,
                                                          ]}
                                                          value_update={
                                                            updateMasterState
                                                          }
                                                          dropdown_data={
                                                            prorata
                                                          }
                                                          warn_status={false}
                                                          required
                                                          disabled={
                                                            isPolicyAttachedToMember
                                                          }
                                                        />
                                                      </div>
                                                    </Grid>
                                                  </Grid>
                                                  <Grid container spacing={3}>
                                                    <Grid
                                                      sm={6}
                                                      md={6}
                                                      lg={3}
                                                      xl={3}
                                                    >
                                                      <Grid xs={12}>
                                                        <h5 className="subHeadingh">
                                                          Premium Split
                                                          Configuration
                                                        </h5>
                                                      </Grid>
                                                      <div className="payment_inner_section">
                                                        <RKTextField
                                                          class_name="inputField"
                                                          // type="number"
                                                          title={"Employee"}
                                                          value={
                                                            rider_premium_flat.premium_split
                                                          }
                                                          attrName={[
                                                            "addPoliciesdata",
                                                            "rider_premium_graded",
                                                            "premium_split",
                                                            riderIndex,
                                                          ]}
                                                          value_update={
                                                            updateMasterState
                                                          }
                                                          warn_status={false}
                                                          inputProps={{
                                                            endAdornment: (
                                                              <InputAdornment position="end">
                                                                %
                                                              </InputAdornment>
                                                            ),
                                                          }}
                                                          error_messg="Enter Split amount"
                                                          disabled={
                                                            isPolicyAttachedToMember
                                                          }
                                                        />
                                                      </div>
                                                    </Grid>
                                                  </Grid>
                                                  <Grid container spacing={3}>
                                                    <>
                                                      <Grid xs={8}>
                                                        <ul
                                                          className="coverageAmt mb-4"
                                                          key={riderIndex}
                                                        >
                                                          {rider_premium_flat.livesInfoGrade &&
                                                            rider_premium_flat.livesInfoGrade.map(
                                                              (
                                                                data: any,
                                                                mainIndex: any
                                                              ) => (
                                                                <li
                                                                  key={`${riderIndex}-${mainIndex}`}
                                                                >
                                                                  <div
                                                                    className="radioBox"
                                                                    key={`${riderIndex}-${mainIndex}`}
                                                                  >
                                                                    <input
                                                                      key={`${riderIndex}-${mainIndex}`}
                                                                      type="radio"
                                                                      name={`abc${riderIndex}-${mainIndex}`}
                                                                      onClick={() => {
                                                                        setlivesSelectedGrade(
                                                                          (
                                                                            prev
                                                                          ) => {
                                                                            const updatedGrades =
                                                                              [
                                                                                ...prev,
                                                                              ];
                                                                            updatedGrades[
                                                                              riderIndex
                                                                            ] =
                                                                            {
                                                                              selectedGrade:
                                                                                mainIndex,
                                                                            };
                                                                            return updatedGrades;
                                                                          }
                                                                        );
                                                                      }}
                                                                      checked={
                                                                        livesSelectedGrade[
                                                                          riderIndex
                                                                        ]
                                                                          ?.selectedGrade ==
                                                                        mainIndex
                                                                      }
                                                                    />
                                                                    <label>
                                                                      Grade
                                                                      {mainIndex +
                                                                        1}
                                                                    </label>
                                                                  </div>
                                                                </li>
                                                              )
                                                            )}
                                                        </ul>
                                                      </Grid>

                                                      <Grid xs={12}>
                                                        <div className="table">
                                                          <div className="livesinformation-table">
                                                            <Grid xs={12}>
                                                              <h5 className="subHeadingh">
                                                                Premium Pricing
                                                              </h5>
                                                            </Grid>
                                                            <ul className="heading">
                                                              {rider_premium_flat?.livesInfoGrade[
                                                                livesSelectedGrade[
                                                                  riderIndex
                                                                ]?.selectedGrade
                                                              ]?.data?.map(
                                                                (data: any) => (
                                                                  <li>
                                                                    {data.min}-
                                                                    {data.max}
                                                                  </li>
                                                                )
                                                              )}
                                                            </ul>

                                                            <ul className="subheading">
                                                              {rider_premium_flat?.livesInfoGrade[
                                                                livesSelectedGrade[
                                                                  riderIndex
                                                                ]?.selectedGrade
                                                              ]?.data?.map(
                                                                (
                                                                  data: any,
                                                                  ageindex: any
                                                                ) => (
                                                                  <li>
                                                                    <RKTextField
                                                                      validation_type="numeric"
                                                                      class_name="inputField mb-4"
                                                                      title={
                                                                        "Amount"
                                                                      }
                                                                      value={formatCurrency(
                                                                        Number(
                                                                          data
                                                                            ?.coverageTypeData[0]
                                                                            .value
                                                                        )
                                                                      )}
                                                                      attrName={[
                                                                        "addPoliciesdata",
                                                                        "rider_premium_graded",
                                                                        riderIndex,
                                                                        livesSelectedGrade[
                                                                          riderIndex
                                                                        ]
                                                                          ?.selectedGrade,
                                                                        ageindex,
                                                                      ]}
                                                                      value_update={
                                                                        updateMasterState
                                                                      }
                                                                      warn_status={
                                                                        false
                                                                      }
                                                                      disabled={
                                                                        isPolicyAttachedToMember
                                                                      }
                                                                    />
                                                                    <RKTextField
                                                                      validation_type="numeric"
                                                                      class_name="inputField"
                                                                      title={
                                                                        "Per Day Amount"
                                                                      }
                                                                      value={formatCurrency(
                                                                        Number(
                                                                          data
                                                                            ?.coverageTypeData[1]
                                                                            ?.value ||
                                                                          0
                                                                        ),
                                                                        2
                                                                      )}
                                                                      attrName={[
                                                                        "addPoliciesdata",
                                                                        "rider_premium_flat",
                                                                        riderIndex,
                                                                        ageindex,
                                                                      ]}
                                                                      value_update={() => { }}
                                                                      warn_status={
                                                                        false
                                                                      }
                                                                      disabled
                                                                    />
                                                                  </li>
                                                                )
                                                              )}
                                                            </ul>
                                                          </div>
                                                        </div>
                                                      </Grid>
                                                    </>
                                                  </Grid>
                                                </Grid>
                                              </Grid>
                                            </div>
                                          </Box>
                                        </Grid>
                                      </Grid>
                                    </>
                                  )
                                )}
                            </Box>
                          </Grid>
                        </Grid>
                      )}
                      {/* Rate for Addition/Deletion per life */}

                      {/* hide this section  */}
                      {/* <Box className="detailSection">
                <div className="sectionTitle">
                  <h4>Rate for Addition/Deletion per life on Pro-Rata Basis</h4>
                  <div className="total_lives">
                    <p>
                      300 <span>Total Lives</span>
                    </p>
                  </div>
                </div>
                <div className="policy_lives">
                  <Grid container spacing={3}>
                    <Grid sm={12} md={8} lg={8} xl={10}>
                      {planType === 1 ? (
                        <ul className="coverageAmt">
                          <li>
                            <div className="radioBox ">
                              <input type="radio" name="coverages" />
                              <label>Grade 1</label>
                            </div>
                          </li>
                          <li>
                            <div className="radioBox">
                              <input type="radio" name="coverages" />
                              <label>Grade 2</label>
                            </div>
                          </li>
                          <li>
                            <div className="radioBox">
                              <input
                                type="radio"
                                name="coverages"
                                defaultChecked
                              />
                              <label>Grade 3</label>
                            </div>
                          </li>
                          <li>
                            <div className="radioBox">
                              <input type="radio" name="coverages" />
                              <label>Grade 4</label>
                            </div>
                          </li>
                          <li>
                            <div className="radioBox">
                              <input type="radio" name="coverages" />
                              <label>Grade 5</label>
                            </div>
                          </li>
                        </ul>
                      ) : null}
                    </Grid>

                    <Grid sm={12} md={4} lg={4} xl={2}>
                      <SelectDropdown
                        class_name="inputField"
                        title="Sum Insured"
                        value={sumInsuredOne}
                        attrName={setSumInsuredOne}
                        value_update={updateMasterState}
                        dropdown_data={sumInsuredOne_data}
                        warn_status={false}
                      />
                    </Grid>
                    <Grid xs={12}>
                      <div className="table">
                        <div className="rate-table">
                          <ul className="heading">
                            <li>0 - 18 yrs</li>
                            <li>19 yrs - 35 yrs</li>
                            <li>36 yrs - 45 yrs</li>
                            <li>46 yrs - 55 yrs</li>
                            <li>56 yrs - 60 yrs</li>
                            <li>61 yrs - 65 yrs</li>
                            <li>66 yrs - 70 yrs</li>
                            <li>71 yrs - 75 yrs</li>
                            <li>76 yrs - 80 yrs</li>
                          </ul>

                          {rateData.map((mainData, mainIndex) => (
                            <ul className="subheading">
                              {mainData.map((subData, subIndex) => (
                                <li>
                                  <RKTextField
                                    class_name="inputField"
                                    title=""
                                    value={subData.value}
                                    attrName={[
                                      "rateData",
                                      mainIndex,
                                      subIndex,
                                      subData.key,
                                    ]}
                                    value_update={updateMasterState}
                                    warn_status={false}
                                  />
                                </li>
                              ))}
                            </ul>
                          ))}
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </Box> */}

                      {/* Attatchment Section */}
                      {/* <Grid container spacing={3} className="px-0">
                <Grid xs={12}>
                  <Box className="detailSection mb-0" ref={attatchments}>
                    <div className="sectionTitle">
                      <h4>Attachments </h4>

                      <div className="actionBtns">
                        <Link
                          className="blueBtn addfile"
                          sx={{ textDecoration: "none", cursor: "pointer" }}
                          onClick={() => setUploadFile(true)}
                        >
                          Add Document
                        </Link>
                      </div>
                    </div>

                   

                    <Attachment
                      open_status={attachment}
                      attrName={setAttachment}
                      value_update={updateMasterState}
                    />
                
                  </Box>
                </Grid>
              </Grid> */}
                      {/* Email Section */}
                      {/* <Grid container spacing={3} className="px-0">
                <Grid xs={12}>
                  <Box className="detailSection mb-0" ref={emailsection}>
                    <div className="sectionTitle">
                      <h4>Emails </h4>
                      <div className="actionBtns">
                        <Link
                          className="redBtn delete"
                          sx={{ textDecoration: "none", cursor: "pointer" }}
                        >
                          Delete
                        </Link>

                        <Link
                          className="blueBtn edit"
                          sx={{ textDecoration: "none", cursor: "pointer" }}
                        >
                          Compose
                        </Link>
                      </div>
                    </div>

                   

                    <EmailComponent
                      open_status={emailcomp}
                      attrName={setEmailComp}
                      value_update={updateMasterState}
                    />
                  
                  </Box>
                </Grid>
              </Grid> */}
                      {/* Task Section */}
                      {/* <Grid container spacing={3} className="px-0">
                <Grid xs={12}>
                  <Box className="detailSection mb-0" ref={tasks}>
                    <div className="sectionTitle">
                      <h4>Tasks </h4>
                      <div className="actionBtns">
                        <Link
                          className="blueBtn addfile"
                          sx={{ textDecoration: "none", cursor: "pointer" }}
                        >
                          Add Task
                        </Link>
                      </div>
                    </div>
                    <div className="mx-3">
                      <TasksSection />
                    </div>
                  </Box>
                </Grid>
              </Grid> */}
                      {/* Notes Section */}
                      {/* <Grid container spacing={3} className="px-0">
                <Grid xs={12}>
                  <Box className="detailSection" ref={notes}>
                    <div className="sectionTitle">
                      <h4>Notes</h4>

                      <div className="actionBtns">
                        <Link
                          className="blueBtn addfile"
                          sx={{ textDecoration: "none", cursor: "pointer" }}
                          onClick={() => {
                            setPostQueryForm(true);
                          }}
                        >
                          Add Notes
                        </Link>
                      </div>
                    </div>

                    <NotesSection data={notesData} />
                  </Box>
                </Grid>
              </Grid> */}
                    </>
                  </Grid>

                  {/* Footer Section */}
                  <Grid container spacing={3} className="footerSection">
                    <Grid xs={12} className="ctaBtn">
                      <Button
                        variant="outlined"
                        className="borderBtn"
                        onClick={() => {
                          navigate(-1);
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        className="submitBtn"
                        disabled={disableSave}
                        onClick={() => {
                          submithandle();
                        }}
                      >
                        Update
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            </Grid>
          </Grid>
        </Box>

        <PoliciesWarningpopup
          popupStatus={warningpopup}
          setPopupStatus={setwarningpopup}
          title={`Only the values of the Premium, Sum Insured, Features and Exclusions may be altered for the - ${addPoliciesdata?.policyCase?.policyNumber}-${addPoliciesdata.productType.name}`}
        />
      </>
    </>
  );
}

export default EditPolicy;
