import { useSelector } from 'react-redux';
import {
    RemoveFieldPopup,
    RemovePermenentPopup,
    RemoveSectionPopup,
    RemoveSubSectionPopup
} from '../CustomModulePopups/RemovePopup copy/index';

function GetRemoveModal() {
    
    const isOpen = useSelector((state)=> {return state.layoutReducer.removeModalOpen});
    const dataToRemove = useSelector((state)=> {return state.layoutReducer.dataToRemove});

    const sendModal = (status, data)=>{
        if(!data){
            return
        }
        const modalName = data.modalType;
        switch (modalName) {
            case "FR":
                return (<RemoveFieldPopup title_stepOne= {data.data.field_lable}
                    title_value_stepOne= "Are you sure you want to remove this field?"
                    content_stepOne= "The field you are going to remove will be moved to the Unused Fields section."
                    btnText_stepOne= "Yes, Remove"
                    title_stepTwo= "Successfully"
                    content_stepTwo= {`${data.data.field_lable} has been moved to Unused Fields!`}/>)
        
            case "PR":
                return (<RemovePermenentPopup title_stepOne= {data.item.component.field_lable}
                title_value_stepOne= "Are you sure you want to permanently remove this field?"
                content_stepOne= "Deleting this field will remove it from the layout & permanently delete the data associated with it."
                btnText_stepOne= "Yes, Remove"
                title_stepTwo= "Successfully"
                content_stepTwo= "Removed"/>)

            case "SR":
                return (<RemoveSectionPopup title_stepOne= {data.data.name}
                    title_value_stepOne= "Are you sure you want to remove this Main Section Permanently?"
                    content_stepOne= "The Main Section will be remove permenantly"
                    btnText_stepOne= "Yes, Remove"
                    title_stepTwo= "Successfully"
                    content_stepTwo= "Removed"/>)

            case "SSR":
                return (<RemoveSubSectionPopup title_stepOne= {data.data.name}
                    title_value_stepOne= "Are you sure you want to remove this Sub Section, permanently?"
                    content_stepOne= "The Sub Section will be remove permenantly"
                    btnText_stepOne= "Yes, Remove"
                    title_stepTwo= "Successfully"
                    content_stepTwo= {`${data.data.name} has been removed!`}/>)
    
            default:
                break;
        }
    }

  return (
    <>
        {sendModal(isOpen, dataToRemove)}
    </>
  )
}

export default GetRemoveModal