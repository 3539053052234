import { TextField } from "@mui/material";
import "./SearchBox.scss";
import SearchIcon from "@mui/icons-material/Search";
interface Props {
  value_update: Function;
  custom?: boolean;
  fullWidth?: boolean;
}
const SearchBox: React.FC<Props> = ({
  value_update,
  custom,
  fullWidth
}) => {

  const onchange = (e: any) => {
    value_update("searchBarfilter", e.target.value)
  }
  return (
    <div className="searchBox" style={{ width: "100%", maxWidth: fullWidth ? "650px" : custom ? "400px" : "" }}>
      <SearchIcon />
      <TextField
        fullWidth
        variant="outlined"
        type="text"
        placeholder="Search..."
        onChange={onchange}
      />
    </div>
  );
}

export default SearchBox;
