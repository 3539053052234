import { URL_CONSTANTS } from "../../../APIVar/URLConstants";
import { GetAPI } from "../../API";

const employerDashboardCount = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
  startdate: any,
  endDate: any,
  country: any,
) => {
  GetAPI.call(
    `${URL_CONSTANTS.employer_dashboard_stats}?startDate=${startdate}&endDate=${endDate}&country=${country}`,
    onSuccess,
    {},
    onError
  );
};

const employerActivePolicies = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
) => {
  GetAPI.call(
    URL_CONSTANTS.employer_active_policies,
    onSuccess,
    {},
    onError,
  );
};

export const EMPLOYER_DASHBOARD_SERVICES = {
  employerDashboardCount, employerActivePolicies,
};
