import {
  Box,
  Button,
  Link,
  Modal,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import React, { useEffect } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import RKTextField from "../../../../../Supporting files/RKTextField/RKTextField";
import SelectDropdown from "../../../../../Supporting files/SelectDropdown/SelectDropdown";
import CheckBox from "../../../../../Supporting files/CheckBox/CheckBox";
import SearchDropdown from "../../../../../Supporting files/SearchDropdown/SearchDropdown";
import { ToggelModalAction } from "../../../../../Store/actions/modalAction";
import { useSelector, useDispatch } from "react-redux";
import {
  addSection,
  addsubSection,
} from "../../../../../Store/actions/layoutAction";
import shortid from "shortid";

const AddNewSectionPopup = ({ open_status }) => {
  const [addField, setAddField] = React.useState("Main Section");
  const [addSubSectionField, setAddSubSectionField] =
    React.useState("Sub Section");
  const [tabName, setTabName] = React.useState("Overview");
  const [warnAddField, setWarnAddField] = React.useState(false);
  const [warnSelectMain, setwarnSelectMain] = React.useState(false);
  const [mainSecToggle, setMainSecToggle] = React.useState(0);
  const [mainSection, setMainSection] = React.useState("Select");
  const [showSubSection, setShowSubSection] = React.useState(false);
  const [toggle, setToggle] = React.useState(false);
  const modal = useSelector((state) => {
    return state.modalReducer;
  });
  const dispatch = useDispatch();
  const layoutData = useSelector(({ layoutReducer }) => {
    return layoutReducer;
  });

  const mainSection_data = [{ key: "Select", value: "Select" }];

  const [mainSection_dropDown, setMainSection_dropDown] =
    React.useState(mainSection_data);
  const tabname_data = [
    { key: "Overview", value: "Overview" },
    { key: "Overview", value: "Overview" },
  ];

  useEffect(() => {
    layoutData.layout.map((e) => {
      mainSection_data.push({ key: e.id, value: e.name });
    });

    setMainSection_dropDown(mainSection_data);
  }, []);

  const updateMasterState = (attrName, value) => {
    attrName(value);
  };

  const handleClick = () => {
    setToggle(!toggle);
  };

  const submitHandler = () => {
    if (mainSecToggle) {
      dispatch(addsubSection(layoutData, mainSection, addSubSectionField));
    } else {
      dispatch(
        addSection(
          layoutData,
          addField,
          addSubSectionField,
          layoutData.components
        )
      );
    }
    dispatch(ToggelModalAction(modal.isOpen, "", null));
  };

  return (
    <Modal
      open={open_status}
      className="modalWrapper"
      onClose={() => {
        // value_update(attrName, false);
      }}>
      <Box className="modalInner sm-width">
        <Grid container spacing={2} alignItems="center">
          <Grid xs={2} className="mb-4"></Grid>
          <Grid xs={8}>
            <h2 className="popup-heading">Add New Section</h2>
          </Grid>
          <Grid xs={2} className="text-right">
            <Link
              className="close-button"
              onClick={() => {
                // value_update(attrName, false);
                dispatch(ToggelModalAction(modal.isOpen, "", null));
              }}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} className="mb-3" justifyContent="center">
          <Grid xs={12} className="radio_group">
            <RadioGroup defaultValue="Skip">
              <FormControlLabel
                value="Skip"
                control={<Radio />}
                label="Main Section"
                onClick={() => setMainSecToggle(0)}
              />
              <FormControlLabel
                value="Overwrite"
                control={<Radio />}
                label="Sub-Section"
                onClick={() => setMainSecToggle(1)}
              />
            </RadioGroup>
          </Grid>
        </Grid>
        {mainSecToggle === 0 ? (
          <Grid container spacing={2} className="mb-3" justifyContent="center">
            <Grid xs={12}>
              <RKTextField
                class_name="inputField"
                title="Section Name"
                value={addField}
                attrName={setAddField}
                value_update={updateMasterState}
                warn_status={warnAddField}
                error_messg="Please enter new section name"
              />
            </Grid>
            {showSubSection ? (
              <Grid xs={12}>
                <RKTextField
                  class_name="inputField"
                  title="Sub-Section Name"
                  value={addSubSectionField}
                  attrName={setAddSubSectionField}
                  value_update={updateMasterState}
                  warn_status={warnAddField}
                  error_messg="Please enter new section name"
                />
              </Grid>
            ) : (
              ""
            )}
            {/* <Grid
              xs={12}
              className="switchFields add_tab"
              display={"flex"}
              alignItems="center"
            >
              <span>Add to tab</span>
              <input type="checkbox" className="switch" onClick={handleClick} />
            </Grid>
            <Grid xs={12}>
              <h4 className="tab_noteheading">
                Note: Enabling this features lets you add this particular
                section to the given tab name.
              </h4>
            </Grid> */}
            {toggle === true ? (
              <Grid xs={12}>
                <SelectDropdown
                  class_name="inputField"
                  title="Select Tab Name"
                  value={tabName}
                  attrName={setTabName}
                  value_update={updateMasterState}
                  dropdown_data={tabname_data}
                  warn_status={false}
                />
              </Grid>
            ) : null}
            <Grid xs={12} className="text-right">
              <Link
                underline="none"
                className="blueBtn addfile"
                onClick={(e) => {
                  e.preventDefault();
                  setShowSubSection(true);
                }}>
                Add Sub Section
              </Link>
            </Grid>
            {!showSubSection ? (
              ""
            ) : (
              <>
                {/* <Grid xs={11}>
                  <RKTextField
                    class_name="inputField"
                    title="Sub-Section Name"
                    value={addSubSectionField}
                    attrName={setAddSubSectionField}
                    value_update={updateMasterState}
                    warn_status={warnAddField}
                    error_messg="Please enter new section name"
                  />
                </Grid>
                <Grid xs={1}>
                  <Link
                    
                    className="redBtn delete ml-0"
                    underline="none"
                  ></Link>
                </Grid> */}
              </>
            )}
          </Grid>
        ) : null}

        {mainSecToggle === 1 ? (
          <Grid container spacing={2} className="mb-3" justifyContent="center">
            <Grid xs={12}>
              <SelectDropdown
                class_name="inputField"
                title="Main Section"
                value={mainSection}
                attrName={setMainSection}
                value_update={updateMasterState}
                dropdown_data={mainSection_dropDown}
                warn_status={warnSelectMain}
                error_messg="Please select main section name"
              />
            </Grid>
            <Grid xs={12}>
              <RKTextField
                class_name="inputField"
                title="Sub-Section Name"
                value={addSubSectionField}
                attrName={setAddSubSectionField}
                value_update={updateMasterState}
                warn_status={warnAddField}
                error_messg="Please enter new sub section name"
              />
            </Grid>
          </Grid>
        ) : null}

        <Grid container spacing={2}>
          <Grid
            xs={12}
            style={{ textAlign: "center", position: "relative" }}
            className="ctaBtn">
            <Button
              variant="contained"
              className="submitBtn"
              onClick={() => {
                if (mainSecToggle !== 1 && addField.toString().length > 0) {
                  setWarnAddField(false);
                  submitHandler();
                } else if (mainSecToggle === 1) {
                  if (mainSection === "Select") {
                    setwarnSelectMain(true);
                  } else if (addSubSectionField.toString().length === 0) {
                    return setWarnAddField(true);
                  } else {
                    submitHandler();
                    setwarnSelectMain(false);
                    setWarnAddField(false);
                  }
                } else {
                  return setWarnAddField(true);
                }
              }}>
              Done
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default AddNewSectionPopup;
