import { URL_CONSTANTS } from "../../APIVar/URLConstants";
import { DeleteAPI, DeleteSingleAPI, GetAPI, PatchAPI, PostAPI, PutAPI } from "../API";

const createAddInsurer = (
  dto: any,
  onSuccess: any,
  config: any,
  onError: any
) => {
  PostAPI.call(URL_CONSTANTS.create_add_insurer, dto, onSuccess, {}, onError);
};
const createAddTpa = (
  dto: any,
  onSuccess: any,
  config: any,
  onError: any
) => {
  PostAPI.call(URL_CONSTANTS.create_add_tpa, dto, onSuccess, {}, onError);
};
const getSpocList = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void
) => {
  GetAPI.call(URL_CONSTANTS.get_spocList, onSuccess, onError);
};

const getInsurer = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
  type: any,
  searchvalue: any
) => {
  GetAPI.call(`${URL_CONSTANTS.get_insurer}${type}&searchValue=${searchvalue}`, onSuccess, onError);
};
const getTpa = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
  searchvalue: any
) => {
  GetAPI.call(`${URL_CONSTANTS.get_Tpa}&searchValue=${searchvalue}`, onSuccess, onError);
};

const addToList = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
  searchvalue: any
) => {
  PatchAPI.call(`${URL_CONSTANTS.add_to_list}&id=${searchvalue}`, onSuccess, onError);
};

const getIntegratedDropdownTpa = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void
) => {
  GetAPI.call(`${URL_CONSTANTS.get_Integrated_Tpa}`, onSuccess, onError);
};

const deleteInsurer = (
  dto: any,
  onSuccess: (data: any) => void,
  config: any,
  onError: (err: any) => void
) => {
  DeleteAPI.call(URL_CONSTANTS.delete_insurer, dto, onSuccess, {}, onError);
};

const deleteTpa = (
  dto: any,
  onSuccess: (data: any) => void,
  config: any,
  onError: (err: any) => void
) => {
  DeleteAPI.call(URL_CONSTANTS.delete_Tpa, dto, onSuccess, {}, onError);
};

const deleteInsurerLocation = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
  insurenceId: any,
  locationId: any
) => {
  DeleteAPI.call(`${URL_CONSTANTS.location_delete}insurenceId=${insurenceId}&itemId=${locationId}`, onSuccess, onError);
};

const deleteTpaLocation = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
  insurenceId: any,
  locationId: any
) => {
  DeleteAPI.call(`${URL_CONSTANTS.location_delete_tpa}tpaId=${insurenceId}&itemId=${locationId}`, onSuccess, onError);
};
const getInsuranceDetail = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
  id: string | undefined
) => {
  GetAPI.call(`${URL_CONSTANTS.get_detail_view}${id}`, onSuccess, onError);
};


const getTpaDetail = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
  id: any,
  usertypeinfo: string,
) => {
  GetAPI.call(`${URL_CONSTANTS.get_detail_view_tpa(id, usertypeinfo)}`, onSuccess, onError);
};

const updateInsurer = (
  dto: any,
  onSuccess: any,
  config: any,
  onError: any,
  id: string | undefined
) => {
  PatchAPI.call(
    `${URL_CONSTANTS.update_insurer}${id}`,
    dto,
    onSuccess,
    {},
    onError
  );
};

const updateTpa = (
  dto: any,
  onSuccess: any,
  config: any,
  onError: any,
  id: string | undefined
) => {
  PutAPI.call(
    `${URL_CONSTANTS.update_tpa}${id}`,
    dto,
    onSuccess,
    {},
    onError
  );
};


const updateInsurerIndividualEdit = (
  dto: any,
  onSuccess: any,
  config: any,
  onError: any,
  id: string | undefined
) => {
  PatchAPI.call(
    `${URL_CONSTANTS.individual_edit_insurer}${id}`,
    dto,
    onSuccess,
    {},
    onError
  );
};



const updateTpaIndividualEdit = (
  dto: any,
  onSuccess: any,
  config: any,
  onError: any,
  id: string | undefined
) => {
  PatchAPI.call(
    `${URL_CONSTANTS.individual_edit_tpa}${id}`,
    dto,
    onSuccess,
    {},
    onError
  );
};

//sngle delete

const delete_insurer_item = (
  id: string,
  onSuccess: (data: any) => void,
  config: any,
  onError: (err: any) => void,
  type: any
) => {
  DeleteSingleAPI.call(
    `${URL_CONSTANTS.individual_delete}${id}${type}`,
    onSuccess,
    {},
    onError
  );
};

const delete_tpa_item = (
  id: string,
  onSuccess: (data: any) => void,
  config: any,
  onError: (err: any) => void,
  type: any
) => {
  DeleteSingleAPI.call(
    `${URL_CONSTANTS.individual_delete_tpa}${id}${type}`,
    onSuccess,
    {},
    onError
  );
};
const isInsuranceAssigned = (
  id: any,
  onSuccess: (data: any) => void,
  onError: (err: any) => void
) => {
  GetAPI.call(`${URL_CONSTANTS.check_is_Insurer_attached}${id}`, onSuccess, onError);
};


export const ADD_NEW_INSURER = {
  createAddInsurer,
  getSpocList,
  getInsurer,
  deleteInsurer,
  deleteTpa,
  getInsuranceDetail,
  updateInsurer,
  updateTpa,
  updateInsurerIndividualEdit,
  updateTpaIndividualEdit,
  delete_insurer_item,
  delete_tpa_item,
  deleteInsurerLocation,
  isInsuranceAssigned,
  createAddTpa,
  getTpa,
  getTpaDetail,
  deleteTpaLocation,
  getIntegratedDropdownTpa,
  addToList
};
