import { URL_CONSTANTS } from "../../APIVar/URLConstants";
import { GetAPI, PostAPI } from "../API";

const contact_get_unique_field_data = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void
) => {
  GetAPI.call(URL_CONSTANTS.contact_get_unique_field_data, onSuccess, onError);
};

const client_get_unique_field_data = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void
) => {
  GetAPI.call(URL_CONSTANTS.client_unique_field_data, onSuccess, onError);
};

const member_get_unique_field_data = (
  user:string,
  onSuccess: (data: any) => void,
  onError: (err: any) => void
) => {
  GetAPI.call(URL_CONSTANTS.member_unique_field_data(user), onSuccess, onError);
};

const prod_config_insurer_unique_field_data = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void
) => {
  GetAPI.call(
    URL_CONSTANTS.product_config_insurer_unique_field_data,
    onSuccess,
    onError
  );
};

const PROD_CONFIG_INSURER_LOAD_FIELD_DATA_FOR_BULK_UPLOAD = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void
) => {
  GetAPI.call(
    URL_CONSTANTS.load_prod_config_insurer_field_data_for_bulk_upload,
    onSuccess,
    onError
  );
};

const upload_bulk_upload = (
  url: string,
  dto: any,
  onSuccess: any,
  onError: any
) => {
  PostAPI.call(
    url,
    dto,
    onSuccess,
    {
      "Content-Type": "multipart/form-data",
    },
    onError
  );
};

export const BULK_UPLOAD_SERVICES = {
  contact_get_unique_field_data,
  upload_bulk_upload,
  client_get_unique_field_data,
  member_get_unique_field_data,
  prod_config_insurer_unique_field_data,
  PROD_CONFIG_INSURER_LOAD_FIELD_DATA_FOR_BULK_UPLOAD,
};
