import { GET_LAYOUT, UPDATE_LAYOUT_FIELD_PROPERTIES, NEW_SECTION_OR_SUBSECTION_ADDED, DELETE, CLEAR, ITEMSWAPPED, GET_UNUSED_FIELDS, SET_UNUSED_FIELDS, REMOVE_UNUSED_FIELDS, CALL_REMOVE_MODAL, CLOSE_REMOVE_MODAL } from '../constants/layoutConstants';

let initState = {
    layout: [],
    unusedFields: [],
    isUpdated: false,
    isUnusedDelete: false,
    isUnUsedFeached: false,
    isGet: false,
    isDeleted: false,
    isAdded: false,
    isSwapped: false,
    removeModalOpen: false,
    deleteItem: {},
    updatedComponent: {}
}

export const layoutReducer = (state = initState, action) =>
{

    switch (action.type) {

        case GET_LAYOUT:
            return { ...state, isGet: true, layout: action.layout, components: action.components }

        case GET_UNUSED_FIELDS:
            return { ...state, isUnUsedFeached: true, unusedFields: action.unsuedFields }

        case SET_UNUSED_FIELDS:
            return { ...state, unusedFields: action.unsuedFields }

        case REMOVE_UNUSED_FIELDS:
            return { ...state, isDeleted: true, unusedFields: action.unsuedFields }

        case ITEMSWAPPED:
            return { ...state, isSwapped: true, isGet: true, layout: action.layout, components: action.components }

        case UPDATE_LAYOUT_FIELD_PROPERTIES:
            return { ...state, isGet: true, isUpdated: true, layout: action.layout, updatedComponent: action.component, components: action.components }

        case NEW_SECTION_OR_SUBSECTION_ADDED:
            return { ...state, isAdded: true, layout: action.layout }

        case DELETE:
            return { ...state, isDeleted: true, layout: action.layout, deleteItem: action.item }

        case CALL_REMOVE_MODAL:
            return { ...state, removeModalOpen: true, dataToRemove: action.dataToRemove }

        case CLOSE_REMOVE_MODAL:
            return { ...state, removeModalOpen: false, dataToRemove: action.dataToRemove }

        case CLEAR:
            return { ...state, isUpdated: false, isAdded: false, isDeleted: false }

        default:
            return state
    }
}