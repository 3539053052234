import { Box, Button, Link, Modal } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useEffect, useState } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import ModifyFeature from "../../../../../Components/Broker/ProductConfigurator/ModifyFeature/ModifyFeature";
import RemovePopup from "../../../../../Components/Common/CarForm/RemovePopup/RemovePopup";
import { ALL_ROUTES } from "../../../../../Routes/all_routes";
import { E_BE_FILTER_CODE } from "../../../../../Services/Enum/E_UGTable";
import { ADD_NEW_INSURER } from "../../../../../Services/ProductConfigurator/AddNewInsurer";
import { useAppDispatch, useAppSelector } from "../../../../../Store/hooks";
import SearchBox from "../../../../../Supporting files/SearchBarAnimation/SearchBox";
import UGTable from "../../../../../Supporting files/UGTable/UGTable";
import "../ProductConfig.scss";
import { bulkUploadSlice } from "../../../../../Store/Slice_Reducer/BulkUpload/BulkUploadSlice";
import PoliciesWarningpopup from "../../../../../Components/Broker/WarningForPlicies/PoliciesWarningpopup";
import { date_time_format } from "../../../../../Supporting files/HelpingFunction";
import { RootState } from "../../../../../Store/Store";
import NewLoader from "../../../../../Components/NewLoader/NewLoader";

function Insurers() {
  const dispatch = useAppDispatch();
  const user_type_dateFormat = useAppSelector((state) => state.userDetailsSlice.localInformation.dateFormat);
  const tz = useAppSelector((state: RootState) => state.userDetailsSlice.localInformation.timeZone);
  const [tableLimit, setTableLimit] = useState();
  const [tableSkip, setTableSkip] = useState();
  const [productTabsStatus, setProductTabsStatus] = useState(sessionStorage.getItem("typeInsurer") ?? "GENERAL");
  const [hideDataStatus, setHideDataStatus] = useState(false);
  const [removeCategory, setRemoveCategory] = useState(false);
  //Features Variables
  const [addNewFeatures, setAddNewFeatures] = useState(false);
  const [modifyFeature, setModifyFeatures] = useState(false);
  const [deleteStatus, setDeleteStatus] = useState(false);
  const [array_delete, setArray_Delete] = useState<Array<{ id: number }>>([]);
  const [isLifeInsuranceAssigned, setIsLifeInsuranceAssigned] = useState(false);
  const [isGeneralInsuranceAssigned, setIsGeneralInsuranceAssigned] = useState(false);
  const [warningpopup, setwarningpopup] = useState<boolean>(false);
  const navigate = useNavigate();
  const [searchBarFilter, setsearchBarFilter] = useState("");
  const [insurerList, setInsurerList] = useState([]);
  const { BROKER } = useAppSelector((state) => state.permissionSlice);
  const [AddPermission, setAddPermission] = useState(true);
  const [EditPermission, setEditPermission] = useState(true);
  const [RemovePermission, setRemovePermission,] = useState(true);
  const [BulkPermission, setBulkPermission,] = useState(true);
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    setAddPermission(BROKER?.ProductConfigurator.add === undefined ? false : BROKER?.ProductConfigurator.add);
    setEditPermission(BROKER?.ProductConfigurator.edit === undefined ? false : BROKER?.ProductConfigurator.edit);
    setRemovePermission(BROKER?.ProductConfigurator.remove === undefined ? false : BROKER?.ProductConfigurator.remove);
    setBulkPermission(BROKER?.ProductConfigurator.upload === undefined ? false : BROKER?.ProductConfigurator.upload);
  }, []);
  //Category Data

  const [generalColumns, setGeneralColumns] = useState([
    {
      field: "logo",
      headerName: "Logo",
      minWidth: 250,
      check_status: true,
      renderCell: (ValueFormatterParams: any) => {
        const { row } = ValueFormatterParams;
        return (
          <img
            src={row.logo}
            style={{
              maxHeight: "60px",
              background: "#FFFFFF",
              maxWidth: "250px",
              borderRadius: "5px",
              paddingLeft: "5px",
              paddingRight: "5px",
              objectFit: "cover",
            }}
            alt="logo"
          />
        );
      },
    },
    {
      field: "name",
      headerName: "Name",
      width: 600,
      check_status: true,
      renderCell: (ValueFormatterParams: any) => {
        const { row } = ValueFormatterParams;
        return (
          <button
            className="truncate_text"
            style={{
              cursor: "pointer",
              fontWeight: "400",
              color: "#6b62dc",
              background: "transparent",
              textDecoration: "none",
            }}
            onClick={() => {
              let data = sessionStorage.getItem("typeInsurer");

              navigate(
                `${ALL_ROUTES.INSURER_DETAILS}?type=${data ?? "GENERAL"}&id=${row.id}`
              );
            }}
          >
            {row.name}
          </button>
        );
      },
    },
    // {
    //   field: "networkCount",
    //   headerName: "Network Hospitals",
    //   width: 200,
    //   check_status: true,
    //   renderCell: (ValueFormatterParams: any) => {
    //     const { row } = ValueFormatterParams;
    //     return <>{"0"}</>;
    //   },
    // },
    {
      field: "website",
      headerName: "Website Url",
      flex: 3,
      minWidth: 300,
      check_status: true,
      renderCell: (ValueFormatterParams: any) => {
        const { row } = ValueFormatterParams;
        return (
          <Link href={row.website_url} target="_blank" underline="none">
            {row.website}
          </Link>
        );
      },
    },
    {
      field: "createdAt",
      headerName: "Created at",
      width: 200,
      check_status: true,
      renderCell: (ValueFormatterParams: any) => {
        const { row } = ValueFormatterParams;
        return (
          <p>{date_time_format(row.createdAt, user_type_dateFormat, tz)}</p>
        );
      },
    },
    {
      field: "updatedAt",
      headerName: "Updated at",
      width: 200,
      check_status: true,
      renderCell: (ValueFormatterParams: any) => {
        const { row } = ValueFormatterParams;
        return (
          <p>{date_time_format(row.updatedAt, user_type_dateFormat, tz)}</p>
        );
      },
    },
  ]);
  let selected_data: Array<{ id: number }> = [];

  // get list data of insurer
  useEffect(() => {
    get_insurer();
  }, [deleteStatus, productTabsStatus]);

  // get insure detail
  const get_insurer = () => {
    const onSuccess = (res: any) => {
      if (res.status === 200) {
        setTimeout(() => {
          setShowLoader(false);
        }, 1500);
      }
      const response: any = res.data.data;
      const dataArr: any = [];
      response.forEach((e: any) => {
        dataArr.push({
          id: e._id,
          logo: e.insurerBasicInfo.logo,
          name: e.insurerBasicInfo.name,
          email: e.insurerBasicInfo.email,
          networkCount: e.insurerBasicInfo.networkCount,
          contactNumber: e.insurerBasicInfo.contactNumber,
          website: e.insurerBasicInfo.website,
          createdAt: e.createdAt,
          updatedAt: e.updatedAt
        });
      });
      setInsurerList(dataArr);
    };
    const onError = (err: any) => {
      console.log(err);
    };
    let data = sessionStorage.getItem("typeInsurer");

    ADD_NEW_INSURER.getInsurer(
      onSuccess,
      onError,
      data ?? productTabsStatus,
      searchBarFilter
    );
  };

  const updateMasterState = (attrName: any, value: any) => {
    if (attrName === "selected_data") {
      selected_data = value;
    } else if (attrName === "searchBarfilter") {
      setsearchBarFilter(value);
    } else {
      attrName(value);
    }
    setArray_Delete(value);

    if (attrName === setAddNewFeatures) {
      setHideDataStatus(value);
    }
  };

  const delete_contact_multiple = () => {
    const onSuccess = (res: any) => {
      setDeleteStatus(false);
    };
    const onError = (err: any) => {
      console.log("false", err);
    };
    ADD_NEW_INSURER.deleteInsurer(
      { ids: array_delete },
      onSuccess,
      {},
      onError
    );
  };

  let timeoutId: any;
  useEffect(() => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    const delayedApiCall = () => {
      get_insurer();
    };

    timeoutId = setTimeout(delayedApiCall, 500);

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [searchBarFilter]);

  useEffect(() => {
    let generelInsurance = false;

    if (productTabsStatus === "GENERAL") {
      if (Array.isArray(array_delete)) {
        array_delete.forEach((item: any, index: number) => {
          const onSuccess = (res: any) => {
            if (!generelInsurance) {
              generelInsurance = res.data.data.isAttached;
              setIsGeneralInsuranceAssigned(generelInsurance);
            }
          };

          const onError = (err: any) => {
            console.log("err", err);
          };

          if (!generelInsurance) {
            ADD_NEW_INSURER.isInsuranceAssigned(item, onSuccess, onError);
          }
        });
      } else {
        console.log("array_delete is not an array or is undefined.");
      }
    }

    let lifeInsurance = false;

    if (productTabsStatus === "LIFE") {
      if (Array.isArray(array_delete)) {
        array_delete.forEach((item: any, index: number) => {
          const onSuccess = (res: any) => {
            if (!lifeInsurance) {
              lifeInsurance = res.data.data.isAttached;
              setIsLifeInsuranceAssigned(lifeInsurance);
            }
          };

          const onError = (err: any) => {
            console.log("false", err);
          };

          if (!lifeInsurance) {
            ADD_NEW_INSURER.isInsuranceAssigned(item, onSuccess, onError);
          }
        });
      } else {
        console.log("array_delete is not an array or is undefined.");
      }
    }
  }, [array_delete]);

  return (
    <>
      {showLoader ? (
        <NewLoader />
      ) : (
        <Grid container spacing={3}>
          <Grid xs={12}>
            <h3 className="mb-0">Insurers</h3>
          </Grid>
          <>
            <Grid xs={7} style={{ display: "flex", alignItems: "center" }}>
              <div className="productTabs mr-3">
                <Button
                  className={
                    productTabsStatus === "GENERAL"
                      ? "defaultTab active"
                      : "defaultTab"
                  }
                  onClick={() => {
                    sessionStorage.setItem("typeInsurer", "GENERAL");
                    updateMasterState(setProductTabsStatus, "GENERAL");
                    setProductTabsStatus("GENERAL");
                  }}
                >
                  General Insurance
                </Button>
                <Button
                  className={
                    productTabsStatus === "LIFE"
                      ? "defaultTab active"
                      : "defaultTab"
                  }
                  onClick={() => {
                    sessionStorage.setItem("typeInsurer", "LIFE");
                    setProductTabsStatus("LIFE");

                    updateMasterState(setProductTabsStatus, "LIFE");
                  }}
                >
                  Life Insurance
                </Button>
              </div>
              <SearchBox value_update={updateMasterState} />
            </Grid>
            <Grid xs={5} className="text-right">
              {/* <Link
                title="Bulk Upload"
                underline="none"
                className="blueBtn upload"
                onClick={() => {
                  dispatch(
                    bulkUploadSlice.actions.setBulkUploadData(
                      bulkUploadSlice.getInitialState()
                    )
                  );
                  navigate({
                    pathname: ALL_ROUTES.BULK_UPLOAD_PAGE,
                    search: createSearchParams({
                      calledFrom: ALL_ROUTES.PRODUCT_CONFIG_INSURERS,
                      type: productTabsStatus,
                    }).toString(),
                  });
                }}
              >
                Bulk Upload
              </Link> */}

              {productTabsStatus === "GENERAL" ? (
                <>
                  {RemovePermission && (
                    <Link
                      className={
                        isGeneralInsuranceAssigned
                          ? `redBtnlight delete`
                          : `redBtn delete`
                      }
                      underline="none"
                      onClick={() => {
                        isGeneralInsuranceAssigned
                          ? setwarningpopup(true)
                          : setDeleteStatus(true);
                      }}
                    >
                      Remove
                    </Link>
                  )}
                  {AddPermission && (
                    <Link
                      className="greenBtn addfile"
                      underline="none"
                      onClick={() => {
                        navigate(ALL_ROUTES.ADD_NEW_INSURER);
                        sessionStorage.setItem(
                          "typeInsurer",
                          productTabsStatus
                        );
                      }}
                    >
                      Add New
                    </Link>
                  )}
                  {array_delete.length > 0 &&
                    EditPermission && (
                      <Link
                        className="blueBtn edit"
                        underline="none"
                        onClick={() => {
                          navigate(
                            `${ALL_ROUTES.EDIT_INSURER}?id=${array_delete[0]}`
                          );
                          sessionStorage.setItem(
                            "typeInsurer",
                            productTabsStatus
                          );
                        }}
                      >
                        Edit
                      </Link>
                    )}
                </>
              ) : null}
              {productTabsStatus === "LIFE" ? (
                <>
                  {RemovePermission && (
                    <Link
                      className={
                        isLifeInsuranceAssigned
                          ? `redBtnlight delete`
                          : `redBtn delete`
                      }
                      underline="none"
                      onClick={() => {
                        isLifeInsuranceAssigned
                          ? setwarningpopup(true)
                          : setRemoveCategory(true);
                      }}
                    >
                      Remove
                    </Link>
                  )}
                  {AddPermission && (
                    <Link
                      className="greenBtn addfile"
                      underline="none"
                      onClick={() => {
                        updateMasterState(setAddNewFeatures, true);
                        navigate(ALL_ROUTES.ADD_NEW_INSURER);
                        sessionStorage.setItem(
                          "typeInsurer",
                          productTabsStatus
                        );
                      }}
                    >
                      Add New
                    </Link>
                  )}
                  {array_delete.length > 0 &&
                    EditPermission && (
                      <Link
                        className="blueBtn edit"
                        underline="none"
                        onClick={() => {
                          navigate(
                            `${ALL_ROUTES.EDIT_INSURER}?id=${array_delete[0]}`
                          );
                          sessionStorage.setItem(
                            "typeInsurer",
                            productTabsStatus
                          );
                        }}
                      >
                        Edit
                      </Link>
                    )}
                </>
              ) : null}
            </Grid>
            <Grid xs={12}>
              {productTabsStatus === "LIFE" ? (
                <div style={{ height: 400, width: "100%" }}>
                  <UGTable
                    header_data={generalColumns}
                    data={insurerList}
                    value_update={updateMasterState}
                    attrName={"UGTable"}
                    BE_filter_code={E_BE_FILTER_CODE.GENERAL_INSURANCE}
                    refetch_data={get_insurer}
                    setTableLimit={setTableLimit}
                    setTableSkip={setTableSkip}
                    paginationMode="client"
                  />
                </div>
              ) : null}
              {productTabsStatus === "GENERAL" ? (
                <div style={{ height: 400, width: "100%" }}>
                  <UGTable
                    header_data={generalColumns}
                    data={insurerList}
                    value_update={updateMasterState}
                    attrName={"UGTable"}
                    BE_filter_code={E_BE_FILTER_CODE.LIFE_INSURANCE}
                    refetch_data={get_insurer}
                    setTableLimit={setTableLimit}
                    setTableSkip={setTableSkip}
                    paginationMode="client"
                  />
                </div>
              ) : null}
            </Grid>
          </>

          <Modal open={deleteStatus} className="modalWrapper">
            {array_delete.length === 0 ? (
              <Box className="modalInner">
                <Grid container spacing={1}>
                  <Grid xs={12}>
                    <Link
                      className="close-button"
                      onClick={() => setDeleteStatus(false)}
                    />
                    <h5 className="popup-heading">No Insurer Selected</h5>
                    <p className="text-center">
                      Please select Insurer for delete
                    </p>
                  </Grid>
                </Grid>
              </Box>
            ) : (
              <Box className="modalInner">
                <Grid container spacing={1}>
                  <Grid xs={12}>
                    <Link
                      className="close-button"
                      onClick={() => setDeleteStatus(false)}
                    />
                    <h5 className="popup-heading">Are you sure ?</h5>
                  </Grid>
                  <Grid xs={12}>
                    <Link className="md-link" underline="none">
                      You want to delete this {array_delete.length} Insurer.
                    </Link>
                  </Grid>
                  <Grid xs={12} className="ctaBtn" textAlign={"center"}>
                    <Button
                      onClick={delete_contact_multiple}
                      variant="contained"
                      className="submitBtn"
                    >
                      Yes, Delete
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            )}
          </Modal>
          <PoliciesWarningpopup
            popupStatus={warningpopup}
            setPopupStatus={setwarningpopup}
            title={`One of the ${productTabsStatus.toLocaleLowerCase()} insurance is assigned to the policy, so you will not be able to delete that ${productTabsStatus.toLocaleLowerCase()} insurance`}
          />
          <RemovePopup
            open_status={removeCategory}
            attrName={setRemoveCategory}
            value_update={updateMasterState}
          />
          <ModifyFeature
            attrName={setModifyFeatures}
            value_update={updateMasterState}
            open_status={modifyFeature}
          />
        </Grid>
      )}{" "}
    </>
  );
}

export default Insurers;
