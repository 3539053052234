import { Button, Grid } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Post } from "../../../../Supporting files/CallAPI";
import "./Login.scss";
// import { GenerateTokken, Post } from "../../../../Supporting files/CallAPI";
import { toast } from "react-toastify";
import { URL_CONSTANTS } from "../../../../APIVar/URLConstants";
import { ALL_ROUTES } from "../../../../Routes/all_routes";
import Session from "../../../../Session/Session";
import RKTextField from "../../../../Supporting files/RKTextField/RKTextField";
import useIsMobile from "../../../../Supporting files/MobileProvider";
import { Box } from "@mui/material";

export default function ResetPassword() {
  const isMobile = useIsMobile();
  const [createPassword, setCreatePassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [warncreatePassword, setWarnCreatePassword] = useState(false);
  const [warnconfirmPassword, setWarnConfirmPassword] = useState(false);
  const [param, setParam] = useState("");
  const [errorLogin, setErrorLogin] = useState("");
  const [passwordSection, setPasswordSection] = useState<boolean>(true);
  const [navigateLogin, setNavigateLogin] = useState<boolean>(false);
  const navigate = useNavigate();
  const [trueConditions, setTrueConditions] = useState(0);
  const [passwordValidityObj, setPasswordValidityObj] = useState<{
    isValid: boolean;
    containsNameOrEmail: boolean;
    hasMinLength: boolean;
    hasSymbolOrNumber: boolean;
  }>({
    containsNameOrEmail: false,
    hasMinLength: false,
    hasSymbolOrNumber: false,
    isValid: false,
  });
  const isValidPassword = () => {
    const containsNameOrEmail = false; //createPassword.includes(editUser?.email);
    const hasMinLength = createPassword.length >= 8;
    const symbolOrNumberRegex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?0-9]+/;
    const hasSymbolOrNumber = symbolOrNumberRegex.test(createPassword);
    const isValid = !containsNameOrEmail && hasMinLength && hasSymbolOrNumber;
    const returnObj: any = {
      isValid,
      containsNameOrEmail,
      hasMinLength,
      hasSymbolOrNumber,
    };
    let numTrues: number = 0;
    for (let i = 0; i < Object.keys(returnObj).length; i++) {
      if (returnObj[Object.keys(returnObj)[i]]) {
        numTrues += 1;
      }
    }

    setTrueConditions(numTrues);
    setPasswordValidityObj(returnObj);
  };

  useEffect(() => {
    isValidPassword();
  }, [createPassword]);

  useEffect(() => {
    Session.setTokken("");
    Session.setUserType("");
  }, []);

  function updateMasterState(attrName: any, value: string) {
    switch (attrName) {
      case setCreatePassword:
        setCreatePassword(value);
        setWarnCreatePassword(value.toString().length > 0 ? false : true);
        break;
      case setConfirmPassword:
        setConfirmPassword(value);
        setWarnConfirmPassword(value.toString().length > 0 ? false : true);
        break;

      default:
        console.log(attrName, value);
        break;
    }
  }

  //Forgot Password
  const validate_forgot_password_form = () => {
    let warn_password = createPassword.toString().length >= 6 ? false : true;
    let warn_confirm_password = confirmPassword.toString() === createPassword.toString() ? false : true;
    setWarnCreatePassword(warn_password);
    setWarnConfirmPassword(warn_confirm_password);
    if (trueConditions === 3 && warn_password === false && warn_confirm_password === false) {
      call_forgot_password();
    }
  };
  const queryParameters = new URLSearchParams(window.location.search);
  const type: any = queryParameters.get("token");

  const call_forgot_password = () => {
    let params = {
      newPassword: createPassword,
      confirmNewPassword: confirmPassword,
    };
    Post(`${URL_CONSTANTS.reset_password}?token=${type}`, params)?.then(
      (data: any) => {
        let status = data[0];
        let response = data[1];
        if (status === "success") {
          setPasswordSection(false);
          setNavigateLogin(true);
        }
        if (status?.status === "failed") {
          let param = status?.errors[0].param;
          let error = status?.errors[0].message;
          setParam(param);
          setErrorLogin(error);
          toast.error(param + "" + "" + error);
        }
        // if (status.response.data.status === "failed") {
        //   let param = status?.response?.data?.errors[0].param;
        //   let error = status?.response?.data?.errors[0].message;
        //   setParam(param);
        //   setErrorLogin(error);
        //   alert(param + "" + "" + error);
        // }
      }
    )?.catch((err: any) => {
      console.log(err, "err")
    });
  };

  return (
    <>
      {isMobile ? (
        <Box className="m_login">
          <Grid container>
            {passwordSection &&
              <Grid xs={12}>
                <Box className="px-4">
                  <Grid container>
                    <Grid xs={12} className="text-center mt-15" >
                      <h2 className="head mb-0">Create new password</h2>
                      <p className="para px-6">Your new password must be different from previous used
                        passwords.</p>
                    </Grid>
                    <Grid xs={12} className="text-center mt-6">
                      <img src={"mobile/mnewPw.svg"} alt="pw" />
                    </Grid>
                    <form noValidate className="formFields w-100">
                      <Grid xs={12} className="text-center mt-4">
                        <RKTextField
                          class_name="inputField"
                          title="Create Password"
                          type="password"
                          value={createPassword}
                          password_status={true}
                          attrName={setCreatePassword}
                          value_update={updateMasterState}
                          warn_status={warncreatePassword}
                          error_messg={
                            createPassword.length === 0
                              ? "Enter your password."
                              : createPassword.length < 3
                                ? "Min. 3 Character Required"
                                : ""
                          }
                        />
                      </Grid>
                      <Grid xs={12} className="text-center mt-4">
                        <RKTextField
                          class_name="inputField"
                          title="Confirm Password"
                          type="password"
                          value={confirmPassword}
                          password_status={true}
                          attrName={setConfirmPassword}
                          value_update={updateMasterState}
                          warn_status={warnconfirmPassword}
                          error_messg={
                            confirmPassword.length === 0
                              ? "Enter your password."
                              : confirmPassword.length < 3
                                ? "Min. 3 Character Required"
                                : createPassword !== confirmPassword ?
                                  "Confirm password must match" :
                                  ""
                          }
                        />
                      </Grid>
                      <Grid xs={12}>
                        <div className="confirmation_content mt-4">
                          <p>
                            Password Strength:{" "}
                            {trueConditions >= 3 ? (
                              <span>Strong</span>
                            ) : trueConditions <= 1 ? (
                              <span style={{ color: "red" }}>Weak</span>
                            ) : (
                              <span style={{ color: "yellow" }}>Medium</span>
                            )}
                          </p>
                          <ul>
                            {passwordValidityObj.containsNameOrEmail ?
                              (<li className="error"> Must not contain your name or email</li>) :
                              (<li className="success"> Must not contain your name or email </li>)}
                            {passwordValidityObj.hasMinLength ?
                              (<li className="success">At least 8 characters</li>) :
                              (<li className="error">At least 8 characters</li>)}
                            {passwordValidityObj.hasSymbolOrNumber ?
                              (<li className="success">Contains a symbol or a number</li>) :
                              (<li className="error">Contains a symbol or a number</li>)}
                          </ul>
                        </div>
                      </Grid>
                      <Grid xs={12} className="text-center mt-4 mb-5">
                        <Button className="mSubmit"
                          size="large"
                          onClick={validate_forgot_password_form}
                        >
                          Sign In
                        </Button>
                      </Grid>
                    </form>
                  </Grid>
                </Box>
              </Grid>}
            {navigateLogin &&
              <Grid xs={12}>
                <Box className="px-4">
                  <Grid container>
                    <Grid xs={12} className="text-center resetPw" >
                      <h2 className="head mb-0">Password has been reset</h2>
                      <p className="para px-12">Your password has been reset. Please login using the new
                        password.</p>
                    </Grid>
                    <Grid xs={12} className="text-center mt-4">
                      <Button className="mSubmit"
                        size="large"
                        onClick={() => {
                          navigate(ALL_ROUTES.LOGIN);
                        }}>
                        Login again
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>}
          </Grid>
        </Box>
      ) : (
        <Grid className="loginWrapper" container spacing={0}>
          <Grid item xs={5} className="leftcol">
            <img src="./images/reset_password.svg" alt="logo" />
          </Grid>
          <Grid item xs={7} className="formsection">
            <div className="innerWrapper">
              {passwordSection === true ? (
                <>
                  <h2>Create new password</h2>
                  <p>
                    Your new password must be different from previous used
                    passwords.
                  </p>
                  <form noValidate className="formFields">
                    <RKTextField
                      class_name="inputField mt-10 mb-8"
                      title="Create Password"
                      type="password"
                      value={createPassword}
                      password_status={true}
                      attrName={setCreatePassword}
                      value_update={updateMasterState}
                      warn_status={warncreatePassword}
                      error_messg={
                        createPassword.length === 0
                          ? "Enter your password."
                          : createPassword.length < 3
                            ? "Min. 3 Character Required"
                            : ""
                      }
                    />
                    <RKTextField
                      class_name="inputField mt-10 mb-8"
                      title="Confirm Password"
                      type="password"
                      value={confirmPassword}
                      password_status={true}
                      attrName={setConfirmPassword}
                      value_update={updateMasterState}
                      warn_status={warnconfirmPassword}
                      error_messg={
                        confirmPassword.length === 0
                          ? "Enter your password."
                          : confirmPassword.length < 3
                            ? "Min. 3 Character Required"
                            : createPassword !== confirmPassword ?
                              "Confirm password must match" :
                              ""
                      }
                    />
                    <Grid xs={12}>
                      <div className="confirmation_content">
                        <p>
                          Password Strength:{" "}
                          {trueConditions >= 3 ? (
                            <span>Strong</span>
                          ) : trueConditions <= 1 ? (
                            <span style={{ color: "red" }}>Weak</span>
                          ) : (
                            <span style={{ color: "yellow" }}>Medium</span>
                          )}
                        </p>
                        <ul>
                          {passwordValidityObj.containsNameOrEmail ?
                            (<li className="error"> Must not contain your name or email</li>) :
                            (<li className="success"> Must not contain your name or email </li>)}
                          {passwordValidityObj.hasMinLength ?
                            (<li className="success">At least 8 characters</li>) :
                            (<li className="error">At least 8 characters</li>)}
                          {passwordValidityObj.hasSymbolOrNumber ?
                            (<li className="success">Contains a symbol or a number</li>) :
                            (<li className="error">Contains a symbol or a number</li>)}
                        </ul>
                      </div>
                    </Grid>

                    <div className="ctaBtn">
                      <Button
                        className="submitBtn"
                        size="large"
                        style={{ marginTop: "10px" }}
                        onClick={validate_forgot_password_form}>
                        Submit
                      </Button>
                    </div>
                  </form>
                </>
              ) : null}
              {navigateLogin === true ? (
                <div className="innerWrapper">
                  <h2>Password has been reset</h2>
                  <p>
                    Your password has been reset. Please login using the new
                    password.
                  </p>
                  <form noValidate className="formFields">
                    <div className="ctaBtn">
                      <Button
                        className="submitBtn"
                        size="large"
                        style={{ marginTop: "10px" }}
                        onClick={() => {
                          navigate(ALL_ROUTES.LOGIN);
                        }}>
                        Login again
                      </Button>
                    </div>
                  </form>
                </div>
              ) : null}
            </div>
          </Grid>
        </Grid>
      )}
    </>

  );
}
