import { URL_CONSTANTS } from "../../APIVar/URLConstants";
import { GetAPI, PostAPI, DeleteAPI, PatchAPI, DeleteSingleAPI } from "../API";
import { ecardDTO, nomineeDTO } from "../DTO/Employee/EmployeeDTO";

const create_Nominee = (
  dto: any,
  onSuccess: any,
  config: any,
  onError: any,
  apiUrl: string
) => {
  PostAPI.call(apiUrl, dto, onSuccess, {}, onError);
};

// get nominee list
const get_nominee_list = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
  id: string
) => {
  GetAPI.call(`${URL_CONSTANTS.get_nominee}${id}`, onSuccess, onError);
};

// update nominee
const update_nominee = (
  dto: nomineeDTO,
  onSuccess: any,
  config: any,
  onError: any,
  id: string
) => {
  PatchAPI.call(
    `${URL_CONSTANTS.update_nominee}${id}`,
    dto,
    onSuccess,
    {},
    onError
  );
};

// delete nominee
const delete_nominee = (
  id: string,
  onSuccess: (data: any) => void,
  config: any,
  onError: (err: any) => void
) => {
  DeleteSingleAPI.call(
    `${URL_CONSTANTS.nominee_delete}${id}`,
    onSuccess,
    {},
    onError
  );
};

// health e card

const create_health_ecard = (
  dto: ecardDTO,
  onSuccess: any,
  config: any,
  onError: any
) => {
  PostAPI.call(URL_CONSTANTS.create_health_ecard, dto, onSuccess, {}, onError);
};

// get health ecard list
const get_health_list = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
  id: string
) => {
  GetAPI.call(`${URL_CONSTANTS.get_health_card}${id}`, onSuccess, onError);
};

const update_health_ecard = (
  dto: ecardDTO,
  onSuccess: any,
  config: any,
  onError: any,
  id: string
) => {
  PatchAPI.call(
    `${URL_CONSTANTS.update_health_card}${id}`,
    dto,
    onSuccess,
    {},
    onError
  );
};

const delete_health = (
  id: string,
  onSuccess: (data: any) => void,
  config: any,
  onError: (err: any) => void
) => {
  DeleteSingleAPI.call(
    `${URL_CONSTANTS.health_delete}${id}`,
    onSuccess,
    {},
    onError
  );
};

const assign_deassign_nominee_policy = (
  onSuccess: any,
  onError: any,
  dto: any,
  empId: string,
  code: string
) => {
  //1 deassign, 0 assign
  PatchAPI.call(
    `${URL_CONSTANTS.assign_deassign_nominee_policy(empId, code)}`,
    dto,
    onSuccess,
    {},
    onError
  );
};

export const EMPLOYEE_NOMINEE_SERVICES = {
  create_Nominee,
  get_nominee_list,
  update_nominee,
  create_health_ecard,
  get_health_list,
  update_health_ecard,
  delete_nominee,
  delete_health,
  assign_deassign_nominee_policy,
};
