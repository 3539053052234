// minimum age data
export const minimumage_data = [
  { key: "Upto 1 Years", value: "Upto 1 Years" },
  { key: "1 Years", value: "1 Years" },
  { key: "2 Years", value: "2 Years" },
  { key: "3 Years", value: "3 Years" },
  { key: "4 Years", value: "4 Years" },
  { key: "5 Years", value: "5 Years" },
  { key: "6 Years", value: "6 Years" },
  { key: "7 Years", value: "7 Years" },
  { key: "8 Years", value: "8 Years" },
  { key: "9 Years", value: "9 Years" },
  { key: "10 Years", value: "10 Years" },
  { key: "11 Years", value: "11 Years" },
  { key: "12 Years", value: "12 Years" },
  { key: "13 Years", value: "13 Years" },
  { key: "14 Years", value: "14 Years" },
  { key: "15 Years", value: "16 Years" },
  { key: "17 Years", value: "17 Years" },
  { key: "18 Years", value: "18 Years" },
];

// maximum age data
export const maximumage_data = [
  { key: "18 Years", value: "18 Years" },
  { key: "19 Years", value: "19 Years" },
  { key: "20 Years", value: "20 Years" },
  { key: "21 Years", value: "21 Years" },
  { key: "22 Years", value: "22 Years" },
  { key: "23 Years", value: "23 Years" },
  { key: "24 Years", value: "24 Years" },
  { key: "25 Years", value: "25 Years" },
  { key: "26 Years", value: "26 Years" },
  { key: "27 Years", value: "27 Years" },
  { key: "28 Years", value: "28 Years" },
  { key: "29 Years", value: "29 Years" },
  { key: "30 Years", value: "30 Years" },
];

// claim
export const physical_type_data = [{ key: "Claim", value: "Claim" }];

// claim types
export const physical_subtype_data = [
  { label: "Cashless Request", value: "Cashless Request" },
  { label: "Claim Intimation", value: "Claim Reimbursement" },
];

// product type
export const physical_product_data = [
  { key: "Group Health", value: "Group Health" },
  { key: "Group Term", value: "Group Term" },
  { key: "Group Super TopUp", value: "Group Super TopUp" },
  { key: "Group Personal Accident", value: "Group Personal Accident" },
  { key: "Group Critical Illness", value: "Group Critical Illness" },
];
export const physical_product_data_general = [
  { key: "Group Health", value: "Group Health" },
  { key: "Group Super TopUp", value: "Group Super TopUp" },
  { key: "Group Personal Accident", value: "Group Personal Accident" },
  { key: "Group Critical Illness", value: "Group Critical Illness" },
];
export const physical_product_data_life = [
  { key: "Group Term", value: "Group Term" },
];
