import shortid from "shortid";

export const SIDEBAR_ITEM = "sidebarItem";
export const ROW = "row";
export const COLUMN = "column";
export const COMPONENT = "component";
export const SECTION = "section";
export const FIELD = "field";
export const SUBSECTION = "subsection";

export const SIDEBAR_ITEMS = [
  {
    id: shortid.generate(),
    class_name: "singleline",
    type: SIDEBAR_ITEM,
    isArchived: false,
    component: {
      type: "Single Line",
      content: "Singleline",
      field_type: "singleline",
    },
  },
  {
    id: shortid.generate(),
    type: SIDEBAR_ITEM,
    class_name: "multiline",
    isArchived: false,
    component: {
      type: "Multi Line",
      content: "Multiline",
      field_type: "multiline",
    },
  },
  {
    id: shortid.generate(),
    type: SIDEBAR_ITEM,
    class_name: "phone",
    isArchived: false,
    component: {
      type: "Phone",
      content: "Phone",
      field_type: "phone",
    },
  },
  {
    id: shortid.generate(),
    type: SIDEBAR_ITEM,
    class_name: "singleselect",
    isArchived: false,
    component: {
      type: "Single Select",
      content: "Singleselect",
      field_type: "singleselect",
    },
  },
  {
    id: shortid.generate(),
    type: SIDEBAR_ITEM,
    class_name: "multiselect",
    isArchived: false,
    component: {
      type: "Multi Select",
      content: "Multiselect",
      field_type: "multiselect",
    },
  },
  {
    id: shortid.generate(),
    type: SIDEBAR_ITEM,
    class_name: "currency",
    isArchived: false,
    component: {
      type: "Currency",
      content: "Currency",
      field_type: "currency",
    },
  },
  {
    id: shortid.generate(),
    type: SIDEBAR_ITEM,
    class_name: "checkbox",
    isArchived: false,
    component: {
      type: "Checkbox",
      content: "Checkbox",
      field_type: "checkbox",
    },
  },
  {
    id: shortid.generate(),
    type: SIDEBAR_ITEM,
    class_name: "datepicker",
    isArchived: false,
    component: {
      type: "Datepicker",
      content: "Datepicker",
      field_type: "datepicker",
    },
  },
  {
    id: shortid.generate(),
    type: SIDEBAR_ITEM,
    class_name: "email",
    isArchived: false,
    component: {
      type: "Email",
      content: "Email",
      field_type: "email",
    },
  },
  {
    id: shortid.generate(),
    type: SIDEBAR_ITEM,
    class_name: "number",
    isArchived: false,
    component: {
      type: "Number",
      content: "Number",
      field_type: "number",
    },
  },
  {
    id: shortid.generate(),
    type: SIDEBAR_ITEM,
    class_name: "searchselect",
    isArchived: false,
    component: {
      type: "Search Select",
      content: "Searchselect",
      field_type: "searchselect",
    },
  },
  {
    id: shortid.generate(),
    type: SIDEBAR_ITEM,
    class_name: "url",
    isArchived: false,
    component: {
      type: "URL",
      content: "URL",
      field_type: "url",
    },
  },
  {
    id: shortid.generate(),
    type: SIDEBAR_ITEM,
    class_name: "address",
    isArchived: false,
    component: {
      type: "Address",
      content: "Building/Street/Area, Country, State, City & Pincode",
      field_type: "address",
      isLocked: true,
      lockType: 2,
    },
  },
];


export const UNUSED_SIDEBAR_ITEMS = []
