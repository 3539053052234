import { URL_CONSTANTS } from "../../APIVar/URLConstants";
import {GetAPI, PostAPI, DeleteAPI, PatchAPI, PutAPI} from "../API"



const get_status_stages = (onSuccess:(data:any)=>void, onError:(err:any)=>void,layoutId:any)=>{
    GetAPI.call(`${URL_CONSTANTS.get_stages_status}?layoutId=${layoutId}`,onSuccess, onError)
}

const post_stages_data = (dto:any,onSuccess:(data:any)=>void, onError:(err:any)=>void)=>{
    PostAPI.call(`${URL_CONSTANTS.get_stages_status}`,dto,onSuccess, onError)
}


const post_status_data = (dto:any,onSuccess:(data:any)=>void, onError:(err:any)=>void,id:any)=>{
    PutAPI.call(`${URL_CONSTANTS.post_status_data}?id=${id}`,dto,onSuccess, onError,id)
}

const delete_status_or_stages = (onSuccess:(data:any)=>void, onError:(err:any)=>void,parentid:any,itemtype:any,itemid:any)=>{
    DeleteAPI.call(`${URL_CONSTANTS.delete_status_or_stages}?id=${parentid}&type=${itemtype}&itemId=${itemid}`,onSuccess, onError)
}

const get_single_stage_status = (onSuccess:(data:any)=>void, onError:(err:any)=>void,parentid:any,itemtype:any,itemid:any)=>{
    GetAPI.call(`${URL_CONSTANTS.get_single_stage_status}?id=${parentid}&type=${itemtype}&itemId=${itemid}`,onSuccess, onError)
}


const update_stage_status = (dto:any,onSuccess:(data:any)=>void, onError:(err:any)=>void,parentid:any)=>{
    PutAPI.call(`${URL_CONSTANTS.update_stage_status}?id=${parentid}`,dto,onSuccess, onError)
}

const get_summary_deatils = (onSuccess:(data:any)=>void, onError:(err:any)=>void,id:any,type:any)=>{
    GetAPI.call(`${URL_CONSTANTS.get_summary_deatils}?id=${id}&type=${type}`,onSuccess, onError)
}


export const CUSTOME_MODULE_SERVICES = {
    get_status_stages,
    post_stages_data,
    post_status_data,
    delete_status_or_stages,
    get_single_stage_status,
    update_stage_status,
    get_summary_deatils
}