import React, { useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import "./MyDocument.scss";
import { Link } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";

function MyDocument() {
  const [file, setFile] = useState("");
  const [progress, setProgress] = useState(0);
  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 70);
      });
    }, 500);
  }, []);

  function handleChange(e: any) {
    setFile(URL.createObjectURL(e.target.files[0]));
  }
  return (
    <Grid xs className="mydocument_wrapper">
      <Grid container spacing={3}>
        <Grid xs={12} className="main_heading mb-10">
          <h5>
            My
            <span> Documents</span>
          </h5>
          <p>
            Add your KYC documents. Additionally, you may also upload any other
            relevant policy documents for review.
          </p>
        </Grid>
        <Grid xs={12} sx={{ display: "flex" }} className="mb-10">
          <div className="kyc_doc">
            <img src="./images/upload_document_icon.svg" alt="" height={32} />
            <h5>
              Drop KYC doc <br /> or{" "}
              <Link component="label">
                {" "}
                <input
                  hidden
                  accept="image/*"
                  multiple
                  type="file"
                  onChange={handleChange}
                />
                Browse
              </Link>
            </h5>
          </div>
          <div className="upload_files">
            <div className="mr-4">
              <img
                src="./images/upload_document_icontwo.svg"
                alt=""
                height={44}
              />
            </div>
            <div>
              <h5>Aadhar_card.jpg</h5>
              <ul>
                <li>
                  <Link>
                    <img src="./images/doc_eye_icon.svg" alt="" height={10} />
                  </Link>
                </li>
                <li>
                  <Link>
                    <img
                      src="./images/doc_download_icon.svg"
                      alt=""
                      height={13}
                    />
                  </Link>
                </li>
                <li>
                  <Link>
                    <img
                      src="./images/doc_delete_icon.svg"
                      alt=""
                      height={14}
                    />
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </Grid>
        <Grid xs={12} className="main_heading mb-10">
          <h5>
            Policy
            <span> Documents</span>
          </h5>
          <p>You can add your policy documents here.</p>
        </Grid>
        <Grid xs={4}>
          <div className="policy_doc">
            <img
              src={file.length > 0 ? file : "./images/policy_doc_img.svg"}
              alt=""
              height={200}
            />
            <h6>
              Drag & drop your files here. <br /> or{" "}
              <Link component="label">
                {" "}
                <input
                  hidden
                  accept="image/*"
                  multiple
                  type="file"
                  onChange={handleChange}
                />
                Browse
              </Link>
            </h6>
          </div>
        </Grid>
        <Grid xs={8}>
          <div className="upload_doc">
            <h6>
              Uploaded <span>Documents</span>
            </h6>
          </div>
          <div className="uploaddoc_innersection">
            <div className="img_upload_section">
              <img src="./images/pdf_img_icon.svg" alt="" height={40} />
              <h6>Health_Insurance_Policy.pdf</h6>
            </div>
            <div className="action-btn">
              <h6>2.4 MB</h6>
              <Link className="redBtn delete ml-0"></Link>
            </div>
          </div>
          <div className="uploaddoc_innersection">
            <div className="img_upload_section">
              <img src="./images/pdf_img_icon.svg" alt="" height={40} />
              <h6>Car_Insurance_Policy.pdf</h6>
              <LinearProgress variant="determinate" value={progress} />
            </div>
            <div className="action-btn">
              <Link>
                <img
                  src="./images/employee_cross_icon.svg"
                  alt=""
                  height={24}
                />
              </Link>
            </div>
          </div>
          <div className="uploaddoc_innersection">
            <div className="img_upload_section">
              <img src="./images/jpg_img_icon.svg" alt="" height={40} />
              <h6>Bike_Insurance_Policy.jpg</h6>
            </div>
            <div className="action-btn">
              <h6 className="upload_failed">Upload failed</h6>
              <Link>
                <img src="./images/upload_failed_icon.svg" alt="" height={24} />
              </Link>
            </div>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default MyDocument;
