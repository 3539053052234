import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { URL_CONSTANTS } from "../../../../APIVar/URLConstants";
import { EMPLOYEE_POLICY_SERVICES } from "../../../../Services/Employee/EmployeePolicyService";
import { EMPLOYEE_NOMINEE_SERVICES } from "../../../../Services/Employee/NomineeService";
import { EMPLOYER_EMPLOYEE_SERVICES } from "../../../../Services/EmployerServices/Employee/EmployeeServices";
import { TAssignablePolicies } from "../../../../Services/Types/TEmployeeDetailPolicies";
import { TNomineeDetail } from "../../../../Services/Types/TNominee";
import { useAppSelector } from "../../../../Store/hooks";
import { calculateAgeFromDob, customFormatDate, } from "../../../../Supporting files/HelpingFunction";
import "../../../../formLayout.scss";
import GlobalDeletePopup from "../../../Broker/GlobalDeletePopup/GlobalDeletePopup";
import NewLoader from "../../../NewLoader/NewLoader";
import AddNominee from "./AddNomineeDetail";
import EditNominee from "./EditNomineDetail";
interface Props {
  attrName?: any;
  value_update?: Function;
  id: string;
  selectedClient: any;
}

const Nominee: React.FC<Props> = ({
  id,
  attrName,
  value_update,
  selectedClient,
}) => {
  const usertypeinfo = useAppSelector((state) => state.userDetailsSlice.userType);
  const [addNominee, setAddNominee] = useState(false);
  const [editNominee, setEditNominee] = useState(false);
  const [editNomineeState, setEditNomineeState] = useState<TNomineeDetail>({
    _id: "",
    age: "",
    createdAt: "",
    dob: "",
    employeeId: "",
    fullName: "",
    relation: "",
  });
  const [detailsformember, setDetailsformember] = useState<any>();
  const [nomineeTableData, setNomineeTableData] = useState<TNomineeDetail[]>([
    {
      _id: "",
      age: "",
      createdAt: "",
      dob: "",
      employeeId: "",
      fullName: "",
      relation: "",
    },
  ]);
  const [removeNominee, setRemoveNominee] = useState<any>(null);
  const [deleteStatusStep, setDeleteStatusStep] = useState(1);
  const [assignable_policies, setAssignable_policies] = useState<TAssignablePolicies[]>([]);
  const [sectionStatus, SetSectionStatus] = useState<string>("nomneesDetails");
  const nomneesDetails = useRef(null);
  const scrollToSection = (elementRef: any) => {
    elementRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  const [showLoader, setShowLoader] = useState(true);
  const { BROKER, EMPLOYER } = useAppSelector((state) => state.permissionSlice);
  const [editPermission, setEditPermission] = useState<any>(null);

  useEffect(() => {
    if (usertypeinfo === "BROKER") {
      setEditPermission(BROKER?.Employees.edit === undefined ? false : BROKER?.Employees.edit);
    } else if (usertypeinfo === "EMPLOYER") {
      // setEditPermission(EMPLOYER?.Employees.edit === undefined ? false : EMPLOYER?.Employees.edit);
      setEditPermission(true);
    }
    setShowLoader(true);
    get_nominee_detail();
    getAssignedPolicies();
  }, [])

  useEffect(() => {
    getAssignablePolicies();
  }, [selectedClient?.id]);

  const getAssignablePolicies = () => {
    const onSuccess = (res: any) => {
      setAssignable_policies(res.data.data);
    };
    const onError = (err: any) => {
      console.log(err);
    };

    {
      usertypeinfo === "EMPLOYER"
        ? EMPLOYER_EMPLOYEE_SERVICES.get_assignable_policies(
          onSuccess,
          onError,
          selectedClient?.id
        )
        : EMPLOYEE_POLICY_SERVICES.get_assignable_policies(
          onSuccess,
          onError,
          selectedClient?.id
        );
    }
  };

  const get_nominee_detail = () => {
    const onSuccess = (res: any) => {
      setNomineeTableData(res.data.data.filter((item: any) => item));
    };
    const onError = (err: any) => { };
    {
      id &&
        (usertypeinfo === "EMPLOYER"
          ? EMPLOYER_EMPLOYEE_SERVICES.get_nominee_list(onSuccess, onError, id)
          : EMPLOYEE_NOMINEE_SERVICES.get_nominee_list(onSuccess, onError, id));
    }
  };

  //delete employee
  const delete_nominee = (attrName?: any, step?: any) => {
    const onSuccess = (res: any) => {
      setDeleteStatusStep(2);
      get_nominee_detail();
    };
    const onError = (err: any) => {
      console.log("error", err);
    };
    EMPLOYEE_NOMINEE_SERVICES.delete_nominee(
      editNomineeState._id,
      onSuccess,
      {},
      onError
    );
    if (attrName === "data") {
      setDeleteStatusStep(step);
    }
  };

  const updateMasterState = (attrName: any, value: any) => {
    if (attrName === "delete_step") {
      setDeleteStatusStep(value);
    } else {
      attrName(value);
    }
  };

  const getAssignedPolicies = () => {
    const onSuccess = (res: any) => {
      setDetailsformember(res.data.data.policyDetails);
      setTimeout(() => {
        setShowLoader(false);
      }, 1500);
    };

    const onError = (err: any) => {
      console.log(err);
      setShowLoader(false);
    };

    {
      usertypeinfo === "EMPLOYER"
        ? EMPLOYER_EMPLOYEE_SERVICES.get_assigned_policies(
          onSuccess,
          onError,
          id
        )
        : EMPLOYEE_POLICY_SERVICES.get_assigned_policies(
          onSuccess,
          onError,
          id
        );
    }
  };

  const nomineeAssignDeassignPolicy = (
    code: string,
    policyId: string,
    nomineeId: string
  ) => {
    const onSuccess = (res: any) => {
      get_nominee_detail();
      if (code === "0") {
        toast.success("Successfully assigned policy to nominee.");
      } else {
        toast.success("Successfully Un-Assigned policy to nominee.");
      }
    };

    const onError = (err: any) => {
      console.log(err);
    };

    const data: {
      policyId: string;
      nomineeId: string;
    } = {
      policyId,
      nomineeId,
    };

    EMPLOYEE_NOMINEE_SERVICES.assign_deassign_nominee_policy(
      onSuccess,
      onError,
      data,
      id,
      code
    );
  };

  return (
    <>
      {showLoader && (<NewLoader />)}

      <Grid container spacing={3}>
        <Grid
          xs={2}
          style={{
            maxWidth: "260px",
            position: "relative",
            top: "-75px",
            paddingLeft: "0",
          }}
        >
          <div
            className="leftSection h-100"
            style={{ position: "fixed", paddingLeft: "8px" }}
          >
            <Link
              className={
                sectionStatus === "nomneesDetails"
                  ? "active sectionLink nomneesDetails"
                  : "sectionLink nomneesDetails"
              }
              onClick={() => {
                scrollToSection(nomneesDetails);
                SetSectionStatus("nomneesDetails");
              }}
            >
              <span>Nominees Details</span>
            </Link>
          </div>
        </Grid>
        <Grid xs>
          <Box className="detailSection mt-4">
            <div className="sectionTitle">
              <h4>Nominee Details</h4>
              <div className="actionBtns">
                <Link
                  className="greenBtn addfile"
                  sx={{ textDecoration: "none", cursor: "pointer" }}
                  onClick={() => {
                    if (editPermission === false) {
                      return toast.error("Permissions Not Allowed");
                    }
                    if (usertypeinfo === "EMPLOYEE") return
                    setAddNominee(true)
                  }}
                >
                  Add Nominee
                </Link>
              </div>
            </div>
            <div>
              <Grid container spacing={3}>
                <Grid xs={12}>
                  <div className="employee_table">
                    <div className="employee_innerpart" >
                      <ul className="heading" style={{ justifyContent: "unset" }}>
                        <li>Full Name</li>
                        <li>Relationship</li>
                        <li>DOB</li>
                        <li>Age</li>
                        {detailsformember?.length > 0 && detailsformember?.map((item: any) => {
                          return (
                            <li>
                              {item.policyNumber}/{item.productType}
                            </li>
                          );
                        })}
                        {/* currently commented this code , will handle this is 2nd phase */}

                        {/* {usertypeinfo === "BROKER" && <li>Actions</li>} */}
                      </ul>

                      {nomineeTableData?.map((data, index) => {
                        if (!data._id) {
                          return null;
                        }
                        return (
                          <ul className="subheading" style={{ justifyContent: "unset" }}>
                            <li>{data.fullName}</li>
                            <li>{data.relation}</li>
                            <li>{customFormatDate(data.dob)}</li>
                            <li>{calculateAgeFromDob(data.dob)}</li>

                            {detailsformember?.length > 0 && detailsformember?.map((item: any) => {
                              const policyIds = data.policy?.map((item) => item.policyId);
                              let checked = false;
                              if (policyIds?.includes(item._id)) {
                                checked = true;
                              }
                              return (
                                <li>
                                  <div className="Checkbox">
                                    {usertypeinfo === "BROKER" ? (
                                      <input
                                        type="checkbox"
                                        name="check"
                                        checked={checked}
                                        disabled={editPermission === false}
                                        onChange={(e) => {
                                          if (checked) {
                                            return toast.warning("To unassign a nominee from a policy go to the policies tab");
                                          } else {
                                            return toast.warning("To assign a nominee to a policy go to the policies tab");
                                          }
                                          // below code for make nominee asigned or deasigned don't remove this code
                                          detailsformember.map(
                                            (memberDetails: any) => {
                                              if (!e.target.checked) {
                                                nomineeAssignDeassignPolicy(
                                                  "1",
                                                  item._id,
                                                  data._id
                                                );
                                                getAssignedPolicies();
                                              } else if (e.target.checked) {
                                                if (
                                                  memberDetails.policyNumber ===
                                                  item.policyNumber
                                                ) {
                                                  if (
                                                    !memberDetails
                                                      .policySettings.nomination
                                                  ) {
                                                    return toast.error(
                                                      `Nomination not allowed in policy - ${item.policyNumber}`
                                                    );
                                                  } else {
                                                    if (
                                                      memberDetails.nominee
                                                        ?.length >=
                                                      Number(
                                                        memberDetails
                                                          .policySettings
                                                          .maxNomination
                                                      )
                                                    ) {
                                                      return toast.error(
                                                        `You have reached your total number of nominee -  ${item.policyNumber}`
                                                      );
                                                    } else {
                                                      nomineeAssignDeassignPolicy(
                                                        "0",
                                                        item._id,
                                                        data._id
                                                      );
                                                      getAssignedPolicies();
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          );
                                        }}
                                      />
                                    ) : (
                                      <input
                                        type="checkbox"
                                        name="check"
                                        checked={checked}
                                        disabled={editPermission === false}
                                      />
                                    )}
                                    <label className="employee"></label>
                                  </div>
                                </li>
                              );
                            })}
                            {/* {assignable_policies.map((item: any) => {
                              const policyIds = data.policy?.map((item) => item.policyId);
                              let checked = false;
                              if (policyIds?.includes(item._id)) {
                                checked = true;
                              }
                              return (
                                <li>
                                  <div className="Checkbox">
                                    {usertypeinfo === "BROKER" ? (
                                      <input
                                        type="checkbox"
                                        name="check"
                                        checked={checked}
                                        disabled={editPermission === false}
                                        onChange={(e) => {
                                          if (checked) {
                                            return toast.warning("To unassign a nominee from a policy go to the policies tab");
                                          } else {
                                            return toast.warning("To assign a nominee to a policy go to the policies tab");
                                          }
                                          // below code for make nominee asigned or deasigned don't remove this code
                                          detailsformember.map(
                                            (memberDetails: any) => {
                                              if (!e.target.checked) {
                                                nomineeAssignDeassignPolicy(
                                                  "1",
                                                  item._id,
                                                  data._id
                                                );
                                                getAssignedPolicies();
                                              } else if (e.target.checked) {
                                                if (
                                                  memberDetails.policyNumber ===
                                                  item.policyNumber
                                                ) {
                                                  if (
                                                    !memberDetails
                                                      .policySettings.nomination
                                                  ) {
                                                    return toast.error(
                                                      `Nomination not allowed in policy - ${item.policyNumber}`
                                                    );
                                                  } else {
                                                    if (
                                                      memberDetails.nominee
                                                        ?.length >=
                                                      Number(
                                                        memberDetails
                                                          .policySettings
                                                          .maxNomination
                                                      )
                                                    ) {
                                                      return toast.error(
                                                        `You have reached your total number of nominee -  ${item.policyNumber}`
                                                      );
                                                    } else {
                                                      nomineeAssignDeassignPolicy(
                                                        "0",
                                                        item._id,
                                                        data._id
                                                      );
                                                      getAssignedPolicies();
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          );
                                        }}
                                      />
                                    ) : (
                                      <input
                                        type="checkbox"
                                        name="check"
                                        checked={checked}
                                        disabled={editPermission === false}
                                      />
                                    )}

                                    <label className="employee"></label>
                                  </div>
                                </li>
                              );
                            })} */}

                            {/* currently commented this code , will handle this is 2nd phase */}
                            {/* {usertypeinfo === "BROKER" && (
                                <li
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                  }}
                                >
                                  <Link
                                    className="blueBtn edit ml-0"
                                    onClick={() => {
                                      setEditNomineeState(data);
                                      setEditNominee(true);
                                    }}
                                    sx={{
                                      textDecoration: "none",
                                      cursor: "pointer",
                                    }}
                                  ></Link>
                                  <Link
                                    className="redBtn delete"
                                    onClick={() => {
                                      setEditNomineeState(data);
                                      setRemoveNominee(true);
                                    }}
                                    sx={{
                                      textDecoration: "none",
                                      cursor: "pointer",
                                    }}
                                  ></Link> 
                                </li>
                              )} */}
                          </ul>
                        );
                      })}
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Box>
        </Grid>
      </Grid >

      <AddNominee
        id={id}
        open_status={addNominee}
        attrName={setAddNominee}
        value_update={updateMasterState}
        refetchData={get_nominee_detail}
        apiUrl={URL_CONSTANTS.create_nominee(usertypeinfo.toLowerCase())}
        onlyNew={true}
      />

      <EditNominee
        id={id}
        open_status={editNominee}
        attrName={setEditNominee}
        value_update={updateMasterState}
        data={editNomineeState}
        refetchData={get_nominee_detail}
      />

      <GlobalDeletePopup
        open_status={removeNominee}
        value_update={updateMasterState}
        attrName={setRemoveNominee}
        delete_step={deleteStatusStep}
        delete_item={() => {
          delete_nominee("data", deleteStatusStep);
        }}
        title="health"
      />
    </>
  );
};
export default Nominee;