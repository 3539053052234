import { URL_CONSTANTS } from "../../APIVar/URLConstants";
import { DeleteAPI, GetAPI, PatchAPI, PostAPI } from "../API";

const create_new_user = (dto: any, onSuccess: Function, onError: Function) => {
  PostAPI.call(URL_CONSTANTS.users_reister, dto, onSuccess, {}, onError);
};

const create_new_role = (dto: any, onSuccess: Function, onError: Function) => {
  PostAPI.call(URL_CONSTANTS.post_user_role_data, dto, onSuccess, {}, onError);
};
const create_new_role_employer = (dto: any, onSuccess: Function, onError: Function) => {
  PostAPI.call(URL_CONSTANTS.post_user_role_data_employer, dto, onSuccess, {}, onError);
};

const get_user_data = (dto: any, onSuccess: Function, onError: Function) => {
  PostAPI.call(
    URL_CONSTANTS.get_user_parent_user_data,
    dto,
    onSuccess,
    {},
    onError
  );
};

const getEmployerUser = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
) => {
  GetAPI.call(
    URL_CONSTANTS.get_employer_user_data,
    onSuccess,
    {},
    onError
  );
};

const user_data_by_id = (id: any, onSuccess: Function, onError: Function) => {
  GetAPI.call(
    `${URL_CONSTANTS.user_edit_get_data_by_id}${id}`,
    onSuccess,
    onError
  );
};

const user_update_data_by_id = (dto: any, onSuccess: any, onError: any) => {
  PatchAPI.call(
    URL_CONSTANTS.user_update_data_by_id,
    dto,
    onSuccess,
    {},
    onError
  );
};
// delete user
const delete_user = (
  dto: any,
  onSuccess: (data: any) => void,
  config: any,
  onError: (err: any) => void
) => {
  DeleteAPI.call(URL_CONSTANTS.user_delete, dto, onSuccess, {}, onError);
};

// dropdown data for the user role
const get_user_role_data = (
  id: any,
  onSuccess: Function,
  onError: Function
) => {
  GetAPI.call(
    `${URL_CONSTANTS.get_user_role}`,
    onSuccess,
    onError
  );
};

const get_user_role_data_employer = (
  id: any,
  onSuccess: Function,
  onError: Function
) => {
  GetAPI.call(
    `${URL_CONSTANTS.get_user_role_employer}`,
    onSuccess,
    onError
  );
};
// change status
const user_change_status = (
  dto: any,
  onSuccess: Function,
  onError: Function,
  id: string
) => {
  PatchAPI.call(
    `${URL_CONSTANTS.user_change_status}${id}`,
    dto,
    onSuccess,
    {},
    onError
  );
};

// access control data
const get_accessControl_data = (
  dto: any,
  onSuccess: Function,
  onError: Function
) => {
  PostAPI.call(
    URL_CONSTANTS.get_access_control_data,
    dto,
    onSuccess,
    {},
    onError
  );
};
// delete user access control
const delete_user_accessControl = (
  dto: any,
  onSuccess: (data: any) => void,
  config: any,
  onError: (err: any) => void
) => {
  DeleteAPI.call(
    URL_CONSTANTS.delete_access_control_data,
    dto,
    onSuccess,
    {},
    onError
  );
};
const access_control_user_data_by_id = (
  id: any,
  onSuccess: Function,
  onError: Function
) => {
  GetAPI.call(
    `${URL_CONSTANTS.get_user_access_control_data_by_id}${id}`,
    onSuccess,
    onError
  );
};

const user_access_control_update = (dto: any, onSuccess: any, onError: any) => {
  PatchAPI.call(
    URL_CONSTANTS.user_access_control_update_data,
    dto,
    onSuccess,
    {},
    onError
  );
};

const get_branch_department_dropdown_list = (
  url: string,
  onSuccess: Function,
  onError: Function
) => {
  GetAPI.call(url, onSuccess, onError);
};

const get_status = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
) => {
  GetAPI.call(
    URL_CONSTANTS.get_user_status,
    onSuccess,
    {},
    onError
  );
};

const update_status = (
  dto: any,
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
  id: any
) => {
  PatchAPI.call(
    `${URL_CONSTANTS.update_user_status}${id}`,
    dto,
    onSuccess,
    {},
    onError
  );
};

const get_status_access_control = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
) => {
  GetAPI.call(
    URL_CONSTANTS.get_access_control_status,
    onSuccess,
    {},
    onError
  );
};

const update_status_access_control = (
  dto: any,
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
  id: any
) => {
  PatchAPI.call(
    `${URL_CONSTANTS.update_access_control_status}${id}`,
    dto,
    onSuccess,
    {},
    onError
  );
};

const isBeingUsed = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
  id: string,
) => {
  GetAPI.call(
    `${URL_CONSTANTS.isStatusBeingUsed}${id}&type=ROLE`,
    onSuccess,
    {},
    onError
  );
};

const update_password_broker = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
  dto:any,
  userType: any
) => {
  PatchAPI.call(
    URL_CONSTANTS.update_password_broker(userType),
    dto,
    onSuccess,
    {},
    onError
  );
};

const isBeingUsedRole = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
  id:string,
) => {
  GetAPI.call(
      `${URL_CONSTANTS.isRoleBeingUsed}${id}`,
      onSuccess,
      {},
      onError
  );
};


export const ACCESS_LEVEL_SERVICES = {
  create_new_role,
  create_new_role_employer,
  get_user_data,
  getEmployerUser,
  user_data_by_id,
  user_update_data_by_id,
  delete_user,
  get_user_role_data,
  get_user_role_data_employer,
  user_change_status,
  get_accessControl_data,
  delete_user_accessControl,
  access_control_user_data_by_id,
  user_access_control_update,
  get_branch_department_dropdown_list,
  create_new_user,
  get_status,
  update_status,
  get_status_access_control,
  update_status_access_control,
  isBeingUsed,
  update_password_broker,
  isBeingUsedRole,
};
