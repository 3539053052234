//not used
import {
  Button,
  FormControl,
  InputLabel,
  Link,
  MenuItem,
  Modal,
  TextField,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Box } from "@mui/system";
import React from "react";
import { GROUP_HEALTH_SERVICES } from "../../../../Services/ProductConfigurator/GroupHealthServices";
interface Props {
  attrName: any;
  open_status: boolean;
  value_update: Function;
  ids?: any;
}

const RemovePopup: React.FC<Props> = ({
  attrName,
  open_status,
  value_update,
  ids,
}) => {
  return (
    <></>
    // <Modal open={open_status} className="modalWrapper">
    //   {ids?.length === 0 ? (
    //     <Box className="modalInner">
    //       <Grid container spacing={3}>
    //         <Grid xs={12}>
    //           <Link
    //             className="close-button"
    //             onClick={() => {
    //               value_update(attrName, false);
    //             }}
    //           />
    //           <h5 className="popup-heading">Select Item to delete</h5>
    //         </Grid>
    //       </Grid>
    //     </Box>
    //   ) : (
    //     <Box className="modalInner">
    //       <Grid container spacing={3}>
    //         <Grid xs={12}>
    //           <Link
    //             className="close-button"
    //             onClick={() => {
    //               value_update(attrName, false);
    //             }}
    //           />
    //           <h5 className="popup-heading">Are you Sure?</h5>
    //         </Grid>
    //       </Grid>
    //       <Grid container spacing={3} justifyContent="center">
    //         <Grid xs={12} className="inputField">
    //           <p style={{ color: "red", textAlign: "center" }}>
    //             You want to {ids?.length} remove it
    //             {/* You want to remove it */}
    //           </p>
    //         </Grid>

    //         <Grid xs={12} className="ctaBtn" style={{ textAlign: "center" }}>
    //           <Button className="submitBtn" onClick={delete_catageory}>
    //             yes, Remove
    //           </Button>
    //         </Grid>
    //       </Grid>
    //     </Box>
    //   )}
    // </Modal>
  );
};

export default RemovePopup;
