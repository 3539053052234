import { createSlice } from "@reduxjs/toolkit";
import { TAddPolicy } from "../../../Services/Types/TAddPolicy";
import { ADD_POLICY_REDUCERS, AddPolicyReducer } from "./AddPolicyReducers";

export const addPolicySlice = createSlice<TAddPolicy, AddPolicyReducer>({
  name: "add_policy",
  initialState: {
    organisationDetails: {
      id: "",
      clientId: "",
      organisationName: "",
      ownership: "",
      industrySub: "",
      mobile: "",
      location: [
        {
          name: "",
        },
      ],
    },

    productType: {
      name: "",
      code: "",
    },
    policyCase: {
      name: "FRESH",
      code: 0,
      policyNumber: "",
    },
    insurerDetails: {
      id: "",
      name: "",
      office: "",
    },
    planType: {
      name: "FLAT",
      coverageType: [],
      basicConfig: "1E",
      parentConfig: "Nill",
      familyDefinition: "",
      gradeCriteria: "",
    },
    ageBand: [
      {
        name: "",
        max: "",
        min: "",
      },
    ],
    benefitsDetailsflat: {
      sumInsured: "2 Lakhs",
      features: [
        {
          name: "",
          value: "",
          featureId: "",
          groupId: "",
        },
      ],
    },
    benefitsDetailsGrade: [
      {
        designation: "",
        name: "",
        sumInsured: "2 Lakhs",
        features: [
          {
            name: "",
            value: "",
            featureId: "",
            groupId: "",
          },
        ],
        exclusion: [
          {
            name: "",
            description: "",
            exclusionId: "",
          },
        ],
      },
    ],
    premiumDetails: {
      netPremium: "",
      taxPercentage: "",
      grossPremium: "",
    },
    policyDetails: {
      startDate: new Date(),
      endDate: new Date(),
      cdBalance: "",
    },
    policy: {
      criticalCd: 0,
      criticalPercentage: 0,
      enrolmentStartDate: new Date(),
      enrolmentEndDate: new Date(),
      period: "",
      gracePeriod: "4 Days",
      maxMemberPerFamily: 4,
      totalMemberPerFamily: 10,
      newMemberPeriod: "10 Days",
      nomination: false,
      maxNomination: 2,
      healthCard: false,
      cardType: "",
      healthCardIssued: "",
      isCorporateBuffer: false,
      corporateBuffer: 0,
    },
  },
  reducers: ADD_POLICY_REDUCERS,
});
