import { URL_CONSTANTS } from "../../APIVar/URLConstants";
import { GetAPI } from "../API";

const LOAD_FIELD_DATA_FOR_BULK_UPLOAD = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void
) => {
  GetAPI.call(
    URL_CONSTANTS.load_contacts_field_data_for_bulk_upload,
    onSuccess,
    onError
  );
};

export const CONTACTS_SERVICES = {
  LOAD_FIELD_DATA_FOR_BULK_UPLOAD,
};
