import Rejected from "@mui/icons-material/Cancel";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Expired from "@mui/icons-material/GppBad";
import Requested from "@mui/icons-material/MarkChatRead";
import SentToTPA from "@mui/icons-material/MarkEmailRead";
import PartialApproval from "@mui/icons-material/Rule";
import Required from "@mui/icons-material/TipsAndUpdates";
import Approved from "@mui/icons-material/Verified";
import { Box, Button, Link, Modal } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Attachment from "../../../../../Components/Broker/Attachment/Attachment";
import NotesSection from "../../../../../Components/Broker/NotesSection/NotesSection";
import DocumentChecklistView from "../../../../../Components/Broker/ProductConfigurator/DocumentChecklist/DocumentChecklist";
import BulkUploadForm from "../../../../../Components/Common/BulkUploadFormSP/BulkUploadForm";
import PostQueryForm from "../../../../../Components/Common/PostQuerySP/PostQueryForm";
import NewLoader from "../../../../../Components/NewLoader/NewLoader";
import { Claim_SERVICES } from "../../../../../Services/Claim/ClaimServices";
import { TClaimDeatil } from "../../../../../Services/Types/Claim/TClaimDetail";
import { TSearchDropdown } from "../../../../../Services/Types/Dropdown";
import { useAppSelector } from "../../../../../Store/hooks";
import { useFormatCurrency } from "../../../../../Supporting files/CustomHooks";
import DatePicker from "../../../../../Supporting files/DatePicker/DatePicker";
import {
  date_time_format,
  get_city_list_via_name,
  get_country_list,
  get_state_list,
} from "../../../../../Supporting files/HelpingFunction";
import RKTextField from "../../../../../Supporting files/RKTextField/RKTextField";
import SearchDropdownDisable from "../../../../../Supporting files/SearchDropdownDisable/SearchDropdownDisable";
import SelectDropdown from "../../../../../Supporting files/SelectDropdown/SelectDropdown";
import "../../../../../formLayout.scss";
import "../Claimassistant.scss";
import RKTextFieldDisable from "../../../../../Supporting files/RKTextFieldDisable/RKTextFieldDisable";

function ClaimAssistantDetail() {
  const navigate = useNavigate();
  const { formatCurrency } = useFormatCurrency();
  const [priorityStatus, setPriorityStatus] = useState(47);
  const { userType, username, ["Organization Email"]: userEmail } = useAppSelector((state: any) => state.userDetailsSlice);
  const { dateFormat, timeZone } = useAppSelector((state: any) => state.userDetailsSlice.localInformation);
  const [viewTabsStatus, setViewTabsStatus] = useState("overview");
  const [detailInfo, setDetailInfo] = useState<TClaimDeatil>({
    paid: 0,
    outstanding: 0,
    status: "",
    transactionId: "",
    branchName: "",
    remarks: "",
    firStatus: false,
    claimAmt: 0,
    insuredPerson: "",
    reason: "",
    firDocument: "",
    upload: [],
    requestType: "",
    claimId: "",
    clientId: "",
    clientName: "",
    memberId: "",
    memberName: "",
    policyNumber: "",
    policyType: "",
    docCount: 0,
    createdAt: "",
    updatedAt: "",
    policyId: "",
    hospitalName: "",
    country: { label: "", id: "" },
    state: { label: "", id: "" },
    city: { label: "", id: "" },
    department: "",
    admisionDate: "",
    dischargeDate: "",
    tpa_status:"",
    reasonForHospitalization: "",
    notes: [],
    tpaData: { documentChecklist: [],integrated:false },
  });
  const [notesData, setNotesData] = useState<Array<{ name: string; date: Date; description: string }>>([]);
  const [attachment, setAttachment] = useState(false);
  const [policyId, setpolicyId] = useState("PL1421");
  const [deleteStatus, setDeleteStatus] = useState(false);
  const [removeStatus, setRemoveStatus] = useState(false);
  const [uploadFile, setUploadFile] = useState(false);
  const [sectionStatus, SetSectionStatus] = useState<string>("personalDetails");
  const [subSection, setSubSection] = useState("memberdetails");
  const [postQueryForm, setPostQueryForm] = useState(false);
  const [id, setId] = useState("");
  const [individualKey, setIndividualKey] = useState<{
    key: string;
    value: string;
  }>({
    key: "",
    value: "",
  });
  const [country_data, setCountry_data] = useState<TSearchDropdown[]>([]);
  const [state_data, setState_data] = useState<TSearchDropdown[]>([]);
  const [city_data, setCity_data] = useState<TSearchDropdown[]>([]);
  const [prioritystatusData, setprioritystatusData] = useState([]);
  const personalDetails = useRef(null);
  const hospitalDetails = useRef(null);
  const claimInfo = useRef(null);
  const docChecklist = useRef(null);
  const attatchmentsdetails = useRef(null);
  const memberdetails = useRef(null);
  const basicdetails = useRef(null);
  const claimdetails = useRef(null);
  const expenses = useRef(null);
  const tpaStatusInfo = useRef(null);
  const tpaRemarkInfo = useRef(null);
  const tpaDatesInfo = useRef(null);
  const tpaIdInfo = useRef(null);
  const notes = useRef(null);
  const [showLoader, setShowLoader] = useState(true);
  const [editPermission, setEditPermission] = useState(false);
  const [info, setInfo] = useState({
    expenses: { amount: "" },
    important_dates: { claim_register_date: "" },
    important_ids: { insurance_claim_no: "" },
    important_status: {status:""},
    remarks : {deduction_reasons:""}
  });

  useEffect(() => {
    // setEditPermission(BROKER?.Claims.edit === undefined ? false : BROKER.Claims.edit)
  }, [])

  useEffect(() => {
    get_status();
    get_country_list((cb: any) => {
      setCountry_data(cb);
    });
    setShowLoader(true);
  }, []);

  const get_status = () => {
    const onSuccess = (res: any) => {
      let statusData = res.data.data.map((data: any) => {
        return { label: data.value, value: data.label };
      });
      setprioritystatusData(statusData);
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    Claim_SERVICES.get_status(onSuccess, onError);
  };
  const update_status = (value: number, id: string) => {
    const onSuccess = (res: any) => { };
    const onError = (err: any) => {
      console.log("err", err);
    };
    Claim_SERVICES.update_status(
      {
        value: value,
      },
      onSuccess,
      onError,
      id
    );
  };

  useEffect(() => {
    if (detailInfo?.country?.label != "") {
      get_state_list(detailInfo?.country?.label, (cb: any) => {
        setState_data(cb);
      });
    }
    if (detailInfo?.country?.label != "" && detailInfo?.state?.label != "") {
      get_city_list_via_name(
        detailInfo?.country?.label,
        detailInfo?.state?.label,
        (cb: any) => {
          setCity_data(cb);
        }
      );
    }
  }, [detailInfo?.country?.label, detailInfo?.state?.label]);

  useEffect(() => {
    const windowURL = window.location.search;
    const params = new URLSearchParams(windowURL);
    const id = params.get("id");
    setId(id ?? "");
    if (postQueryForm === false) {
      detail_view_api(id ?? "");
    }
  }, [postQueryForm]);

  const detail_view_api = (id: string) => {
    const onSuccess = (res: any) => {
      if (res.status === 200) {
        setTimeout(() => {
          setShowLoader(false);
        }, 1500);
      }
      setPriorityStatus(res.data.data[0].status);
      setDetailInfo(res.data.data[0]);
      setInfo(res.data.data[0].tpa_claim_detail_info);
    };
    const onError = (err: any) => {
      console.log("err", err);
      setShowLoader(false);
    };
    Claim_SERVICES.get_claim_detail_data(
      id,
      onSuccess,
      onError,
      userType.toLowerCase()
    );
  };

  const scrollToSection = (elementRef: any) => {
    elementRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleClicks = (ref: any) => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  const updateMasterState = (attrName: any, value: any) => {
    if (attrName === "notes_new_data") {
      setPostQueryForm(false)

      setNotesData((notesData) => [
        ...notesData,
        { name: userEmail, date: new Date(), description: value },
      ]);
      const onSuccess = (res: any) => { };
      const onError = (err: any) => {
        console.log("err", err);
      };
      Claim_SERVICES.claim_add_notes(
        userType.toLocaleLowerCase(),
        id,
        { notes: { name: userEmail, date: new Date(), description: value } },
        onSuccess,
        onError
      );
    } else if (attrName === setPriorityStatus) {
      update_status(value, id);
      setPriorityStatus(value);
    } else if (attrName === "attachment_deleted") {
      detail_view_api(id ?? "");
    } else if (attrName === "attachment_new_data") {
      claim_attachment(value);
    } else if (attrName[0] === "detailData") {
      setIndividualKey({
        key: attrName[1],
        value: value && value.label ? value.label : value,
      });
      setDetailInfo((prev) => ({
        ...prev,
        [attrName[1]]: value,
      }));
      if (attrName[1] === "country") {
        setDetailInfo((prev) => ({
          ...prev,
          country: { label: value.label, id: value.id, warning: false },
          state: {
            label: value.label === "N/A" ? "N/A" : "",
            id: value.label === "N/A" ? "N/A" : "",
            warning: false,
          },
          city: {
            label: value.label === "N/A" ? "N/A" : "",
            id: value.label === "N/A" ? "N/A" : "",
            warning: false,
          },
        }));
      } else if (attrName[1] === "state") {
        setDetailInfo((prev) => ({
          ...prev,
          state: { label: value.label, id: value.id, warning: false },
          city: {
            label: value.label === "N/A" ? "N/A" : "",
            id: value.label === "N/A" ? "N/A" : "",
            warning: false,
          },
        }));
      }
    } else if (attrName === setUploadFile) {
      setUploadFile(value);
    }
  };

  const individualEdit = () => {
    if (individualKey.value === "") {
      toast.error("Enter the value");
      return;
    } else if (
      detailInfo.country?.label === "" ||
      detailInfo.state?.label === "" ||
      detailInfo.city?.label === ""
    ) {
      toast.error("Country State City not be empty");
    } else {
      const onSuccess = (res: any) => {
        if (res.status === 200) {
          toast.success(res.data.message);
        }
      };
      const onError = (err: any) => {
        console.log("err", err);
      };
      Claim_SERVICES.individual_edit(id, individualKey, onSuccess, onError);
    }
  };

  // individual edit function
  const claim_attachment = (value: any) => {
    let dto: any = {
      claimId: id,
      path: "0",
      attachments: {
        doc: value.doc,
        doc_name: value.doc_name,
        description: value.description,
      },
    };
    const onSuccess = (res: any) => {
      if (res.status === 200) {
        setUploadFile(false)
        toast.success("File uploaded sucessfully");
        detail_view_api(id ?? "");
      }
    };
    const onError = (err: any) => {
      console.log("err", err);
      if (err?.response?.data?.status === "failed") {
        toast.error(err.response.data.errors[0]?.param + " " + err.response.data.errors[0]?.message)
      }
    };
    Claim_SERVICES.claim_attachment(userType?.toLowerCase(), dto, onSuccess, onError);
  };

  return (
    <>
      {showLoader && (<NewLoader />)}
      <Box className="layoutWrapper">
        {/* Page header */}
        <Grid container spacing={3} className="pageHeader">
          <Grid xs={6} className="pageTitle">
            <Link
              className="backStep"
              onClick={() => {
                navigate(-1);
              }}
            >
              <ChevronLeftIcon />
            </Link>
            <span className="detailIcon">
              {detailInfo?.clientName?.substring(0, 1)}
            </span>
            <div>
              <h3>{detailInfo?.requestType.toLowerCase() === "intimation" ? "Reimbursement" :detailInfo?.requestType}</h3>
              <p>
                {detailInfo?.claimId} - Created on:{" "}
                {date_time_format(
                  detailInfo?.createdAt,
                  dateFormat,
                  timeZone
                )}
              </p>
            </div>
          </Grid>
          <Grid
            xs={6}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            {/* Status Dropdown */}
            <div id={`_${priorityStatus}`}>
              <div className="status_dropdown" id={`_${priorityStatus}`}>
                {priorityStatus === 47 ? (
                  <Requested id={`_${priorityStatus.toString()}`} />
                ) : priorityStatus === 48 ? (
                  <SentToTPA id={`_${priorityStatus.toString()}`} />
                ) : priorityStatus === 49 ? (
                  <Required id={`_${priorityStatus.toString()}`} />
                ) : priorityStatus === 50 ? (
                  <Approved id={`_${priorityStatus.toString()}`} />
                ) : priorityStatus === 51 ? (
                  <Rejected id={`_${priorityStatus.toString()}`} />
                ) : priorityStatus === 52 ? (
                  <Expired id={`_${priorityStatus.toString()}`} />
                ) : priorityStatus === 53 ? (
                  <PartialApproval id={`_${priorityStatus.toString()}`} />
                ) : priorityStatus === 65 ? (
                  <PartialApproval id={`_${priorityStatus.toString()}`} />
                ) : priorityStatus === 66 ? (
                  <PartialApproval id={`_${priorityStatus.toString()}`} />
                ) : priorityStatus === 67 ? (
                  <PartialApproval id={`_${priorityStatus.toString()}`} />
                ) : priorityStatus === 68 ? (
                  <PartialApproval id={`_${priorityStatus.toString()}`} />
                ) : priorityStatus === 69 ? (
                  <PartialApproval id={`_${priorityStatus.toString()}`} />
                ) : priorityStatus === 70 ? (
                  <PartialApproval id={`_${priorityStatus.toString()}`} />
                ) : null}
                <SelectDropdown
                  class_name="inputFieldd"
                  title=""
                  value={priorityStatus}
                  attrName={setPriorityStatus}
                  value_update={updateMasterState}
                  dropdown_data={prioritystatusData}
                  warn_status={false}
                  disabled
                />
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid xs={12}>
            <Grid container spacing={3}>
              <Grid xs className="leftSection h-100">
                {viewTabsStatus === "overview" && (
                  <>
                    <Link
                      className={
                        sectionStatus === "personalDetails"
                          ? "active sectionLink personalDetails"
                          : "sectionLink personalDetails"
                      }
                      onClick={() => {
                        scrollToSection(personalDetails);
                        SetSectionStatus("personalDetails");
                      }}
                    >
                      <span> Member Info</span>
                    </Link>
                    <ul className="accordion_list">
                      <li>
                        <Link
                          className={
                            subSection === "memberdetails"
                              ? "inner_link active memberdetails"
                              : "inner_link memberdetails"
                          }
                          onClick={() => {
                            setSubSection("memberdetails");
                            handleClicks(subSection);
                            scrollToSection(memberdetails);
                          }}
                        >
                          Member & Policy Details
                        </Link>
                      </li>
                    </ul>
                    <Link
                      className={
                        sectionStatus === "hospitalDetails"
                          ? "active sectionLink hospitalDetails"
                          : "sectionLink hospitalDetails"
                      }
                      onClick={() => {
                        scrollToSection(hospitalDetails);
                        SetSectionStatus("hospitalDetails");
                      }}
                    >
                      <span> Hospital Treatment</span>
                    </Link>
                    <ul className="accordion_list">
                      <li>
                        <Link
                          className={
                            subSection === "basicdetails"
                              ? "inner_link active basicdetails"
                              : "inner_link basicdetails"
                          }
                          onClick={() => {
                            setSubSection("basicdetails");
                            SetSectionStatus("hospitalDetails");
                            handleClicks(subSection);
                            scrollToSection(basicdetails);
                          }}
                        >
                          Basic Details
                        </Link>
                      </li>
                    </ul>
                    <Link
                      className={
                        sectionStatus === "claimInfo"
                          ? "active sectionLink claimInfo"
                          : "sectionLink claimInfo"
                      }
                      onClick={() => {
                        scrollToSection(claimInfo);
                        SetSectionStatus("claimInfo");
                      }}
                    >
                      <span> Claim Info</span>
                    </Link>
                    <ul className="accordion_list">
                      <li>
                        <Link
                          className={
                            subSection === "claimdetails"
                              ? "inner_link active claimdetails"
                              : "inner_link claimdetails"
                          }
                          onClick={() => {
                            setSubSection("claimdetails");
                            SetSectionStatus("claimInfo");
                            handleClicks(subSection);
                            scrollToSection(claimdetails);
                          }}
                        >
                          Claim Details
                        </Link>
                      </li>
                    </ul>
                    <Link
                      className={
                        sectionStatus === "docChecklist"
                          ? "active sectionLink docChecklist"
                          : "sectionLink docChecklist"
                      }
                      onClick={() => {
                        handleClicks(sectionStatus);
                        scrollToSection(docChecklist);
                        SetSectionStatus("docChecklist");
                      }}
                    >
                      <span>Document Checklist</span>
                    </Link>
                    <Link
                      className={
                        sectionStatus === "attatchmentsdetails"
                          ? "active sectionLink attatchmentsdetails"
                          : "sectionLink attatchmentsdetails"
                      }
                      onClick={() => {
                        handleClicks(sectionStatus);
                        scrollToSection(attatchmentsdetails);
                        SetSectionStatus("attatchmentsdetails");
                      }}
                    >
                      <span>Attatchments</span>
                    </Link>
                  </>
                )}
                {viewTabsStatus === "additional" && (
                  <>
                    <Link
                      className={
                        sectionStatus === "expenses"
                          ? "active sectionLink expenses"
                          : "sectionLink expenses"
                      }
                      onClick={() => {
                        scrollToSection(expenses);
                        SetSectionStatus("expenses");
                      }}
                    >
                      <span>Expenses</span>
                    </Link>
                    <Link
                      className={
                        sectionStatus === "tpaDatesInfo"
                          ? "active sectionLink tpaDatesInfo"
                          : "sectionLink tpaDatesInfo"
                      }
                      onClick={() => {
                        scrollToSection(tpaDatesInfo);
                        SetSectionStatus("tpaDatesInfo");
                      }}
                    >
                      <span>TPA Dates Information</span>
                    </Link>
                    <Link
                      className={
                        sectionStatus === "tpaIdInfo"
                          ? "active sectionLink tpaIdInfo"
                          : "sectionLink tpaIdInfo"
                      }
                      onClick={() => {
                        scrollToSection(tpaIdInfo);
                        SetSectionStatus("tpaIdInfo");
                      }}
                    >
                      <span>TPA ID Information</span>
                    </Link>
                    <Link
                      className={
                        sectionStatus === "tpaStatusInfo"
                          ? "active sectionLink tpaStatusInfo"
                          : "sectionLink tpaStatusInfo"
                      }
                      onClick={() => {
                        scrollToSection(tpaStatusInfo);
                        SetSectionStatus("tpaStatusInfo");
                      }}
                    >
                      <span>TPA Status Information</span>
                    </Link>
                    <Link
                      className={
                        sectionStatus === "tpaRemarkInfo"
                          ? "active sectionLink tpaRemarkInfo"
                          : "sectionLink tpaRemarkInfo"
                      }
                      onClick={() => {
                        scrollToSection(tpaRemarkInfo);
                        SetSectionStatus("tpaRemarkInfo");
                      }}
                    >
                      <span>TPA Status Information</span>
                    </Link>
                  </>
                )}
                {viewTabsStatus === "notes" && (
                  <>
                    <Link
                      className={
                        sectionStatus === "notes"
                          ? "active sectionLink notes"
                          : "sectionLink notes"
                      }
                      onClick={() => {
                        scrollToSection(notes);
                        SetSectionStatus("notes");
                      }}
                    >
                      <span>Notes</span>
                    </Link>
                  </>
                )}
              </Grid>
              <Grid xs className="rightSection h-100">
                <Box>
                  <Grid container columnSpacing={3} alignItems="center">
                    <Grid
                      lg={7}
                      md={7}
                      xs={12}
                      margin={"12px 0"}
                      className="pl-0"
                    >
                      <div className="productTabs ">
                        <Button
                          className={
                            viewTabsStatus === "overview"
                              ? "defaultTab active"
                              : "defaultTab"
                          }
                          onClick={() => {
                            SetSectionStatus("personalDetails");
                            setSubSection("memberdetails");
                            setViewTabsStatus("overview");
                            setSubSection("memberdetails");
                          }}
                        >
                          Overview
                        </Button>
                        <Button
                          className={
                            viewTabsStatus === "additional"
                              ? "defaultTab active"
                              : "defaultTab"
                          }
                          onClick={() => {
                            SetSectionStatus("expenses");
                            setViewTabsStatus("additional");
                          }}
                        >
                          Additional Details
                        </Button>
                        <Button
                          className={
                            viewTabsStatus === "notes"
                              ? "defaultTab active"
                              : "defaultTab"
                          }
                          onClick={() => {
                            setViewTabsStatus("notes");
                            SetSectionStatus("notes");
                          }}
                        >
                          Notes
                        </Button>
                      </div>
                    </Grid>
                    <Grid
                      margin={"12px 0"}
                      lg={5}
                      md={5}
                      xs={12}
                      style={{ textAlign: "right" }}
                      className="text-sm-center"
                    >
                      <p className="lastupdate">
                        Last Update:{" "}
                        {date_time_format(
                          detailInfo?.updatedAt,
                          dateFormat,
                          timeZone
                        )}
                      </p>
                    </Grid>
                  </Grid>

                  {viewTabsStatus === "overview" && (
                    <>
                      <Box className="detailSection" ref={personalDetails}>
                        <div className="sectionTitle">
                          <h4>Member Info</h4>
                        </div>
                        <div ref={memberdetails}>
                          <Grid container spacing={3}>
                            <Grid xs={12}>
                              <h5 className="subHeading">
                                Member & Policy Details
                              </h5>
                            </Grid>
                          </Grid>
                          <Grid container spacing={3}>
                            <Grid xs={4} md={6} lg={4}>
                              <RKTextField
                                class_name="inputField"
                                title="Member ID"
                                value={`${detailInfo?.memberId} - ${detailInfo?.memberName}`}
                                attrName={"setMemberId"}
                                value_update={updateMasterState}
                                warn_status={false}
                                disabled={true}
                              />
                            </Grid>
                            <Grid xs={6} md={6} lg={4}>
                              <RKTextField
                                class_name="inputField"
                                title="Client ID"
                                value={`${detailInfo?.clientId} - ${detailInfo?.clientName}`}
                                attrName={"setclientId"}
                                value_update={updateMasterState}
                                warn_status={false}
                                disabled={true}
                              />
                            </Grid>
                          </Grid>
                          <Grid container spacing={3}>
                            {/* <Grid xs={6} md={4} lg={3}>
                                <RKTextField
                                  class_name="inputField"
                                  title="Policy ID"
                                  value={detailInfo?.policyId}
                                  attrName={setpolicyId}
                                  value_update={updateMasterState}
                                  warn_status={false}
                                  disabled={true}
                                />
                              </Grid> */}
                            <Grid xs={6} md={3}>
                              <RKTextField
                                class_name="inputField"
                                title="Policy Number"
                                value={detailInfo?.policyNumber}
                                attrName={"setpolicyNumber"}
                                value_update={updateMasterState}
                                warn_status={false}
                                disabled={true}
                              />
                            </Grid>
                            <Grid xs={6} md={3}>
                              <RKTextField
                                class_name="inputField"
                                title="Insurer Name"
                                value={detailInfo?.insurer}
                                attrName={"setpolicyNumber"}
                                value_update={updateMasterState}
                                warn_status={false}
                                disabled={true}
                              />
                            </Grid>
                            <Grid xs={6} md={3}>
                              <RKTextField
                                class_name="inputField"
                                title="TPA Name"
                                value={detailInfo?.tpaName}
                                attrName={"setpolicyNumber"}
                                value_update={updateMasterState}
                                warn_status={false}
                                disabled={true}
                              />
                            </Grid>
                          </Grid>
                        </div>
                      </Box>

                      <Box className="detailSection" ref={hospitalDetails}>
                        <div className="sectionTitle">
                          <h4>Hospital/Treatment/Claim Info</h4>
                        </div>
                        <div ref={basicdetails}>
                          <Grid container spacing={3}>
                            <Grid xs={12}>
                              <h5 className="subHeading">Basic Details</h5>
                            </Grid>
                          </Grid>
                          <Grid container spacing={3}>
                            <Grid xs={6} md={6} lg={3}>
                              <RKTextField
                                class_name="inputField"
                                title="Hospital"
                                value={detailInfo?.hospitalName}
                                attrName={"setHospital"}
                                value_update={updateMasterState}
                                warn_status={false}
                                disabled={true}
                              />
                            </Grid>

                            <Grid xs={6} md={6} lg={3}>
                              <SearchDropdownDisable
                                class_name="inputField"
                                title="Country"
                                value={
                                  detailInfo?.country === undefined
                                    ? ""
                                    : detailInfo.country
                                }
                                attrName={["detailData", "country"]}
                                value_update={updateMasterState}
                                data={country_data}
                                warn_status={false}
                                params={individualEdit}
                                fieldDisable={
                                  detailInfo.status === "Request Raised"
                                    ? false
                                    : true
                                }
                              />
                            </Grid>
                            <Grid xs={6} md={6} lg={3}>
                              <SearchDropdownDisable
                                class_name="inputField"
                                title="State"
                                value={
                                  detailInfo?.state === undefined
                                    ? ""
                                    : detailInfo.state
                                }
                                attrName={["detailData", "state"]}
                                value_update={updateMasterState}
                                data={state_data}
                                warn_status={false}
                                params={individualEdit}
                                fieldDisable={
                                  detailInfo.status === "Request Raised"
                                    ? false
                                    : true
                                }
                              />
                            </Grid>
                            <Grid xs={6} md={6} lg={3}>
                              <SearchDropdownDisable
                                class_name="inputField"
                                params={individualEdit}
                                title="City"
                                value={
                                  detailInfo?.city === undefined
                                    ? ""
                                    : detailInfo.city
                                }
                                attrName={["detailData", "city"]}
                                value_update={updateMasterState}
                                data={city_data}
                                warn_status={false}
                                fieldDisable={
                                  detailInfo.status === "Request Raised"
                                    ? false
                                    : true
                                }
                              />
                            </Grid>
                            {/* <Grid sm={6} md={5} lg={3} xl={3}>
                                <RKTextField
                                  class_name="inputField"
                                  title="Department"
                                  value={detailInfo?.department}
                                  attrName={"setDepartment"}
                                  value_update={updateMasterState}
                                  warn_status={false}
                                  disabled={true}
                                />
                              </Grid> */}
                            <Grid xs={6} md={6} lg={3}>
                              <DatePicker
                                class_name="inputField"
                                title="Admission Date"
                                value={
                                  detailInfo?.admisionDate === undefined
                                    ? null
                                    : detailInfo.admisionDate
                                }
                                attrName={"setAdmissionDate"}
                                value_update={updateMasterState}
                                error_message="Please enter admission date"
                                warn_status={false}
                                disabled={true}
                              />
                            </Grid>
                            <Grid xs={6} md={6} lg={3}>
                              <DatePicker
                                class_name="inputField"
                                title="Discharge Date"
                                value={
                                  detailInfo?.dischargeDate === undefined
                                    ? null
                                    : detailInfo.dischargeDate
                                }
                                attrName={"setDischargeDate"}
                                value_update={updateMasterState}
                                error_message="Please enter admission date"
                                warn_status={false}
                                disabled={true}
                              />
                            </Grid>
                            <Grid xs={6} md={6} lg={3}>
                              <RKTextField
                                class_name="inputField"
                                title="Illness Reason for Hospitalisation/Claim"
                                value={detailInfo?.reasonForHospitalization || detailInfo?.reason}
                                attrName={"setIllness"}
                                value_update={updateMasterState}
                                warn_status={false}
                                disabled={true}
                              />
                            </Grid>
                            <Grid
                              xs={12}
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              <div className="Checkbox">
                                <input
                                  type="checkbox"
                                  name="check"
                                  checked={detailInfo?.firStatus}
                                />
                                <label className="employee"></label>
                              </div>
                              <h5 className="mb-0">
                                If it is a legal case, kindly submit the FIR
                                Report
                              </h5>
                            </Grid>
                          </Grid>
                        </div>
                      </Box>
                      <Box className="detailSection" ref={claimInfo}>
                        <div className="sectionTitle">
                          <h4>Claim Info</h4>
                        </div>
                        <div ref={claimdetails}>
                          <Grid container spacing={3}>
                            <Grid xs={6}>
                              <h5 className="subHeading">Claim Details</h5>
                            </Grid>
                            <Grid
                              xs={6}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "end"
                              }}
                            >
                              TPA Claim Status 
                              <div id={`_${priorityStatus}`} style={{ marginLeft: 10 }}>
                                <div className="status_dropdown disabled_sd" id={`_${priorityStatus}`}>
                                  {priorityStatus === 47 ? (
                                    <Requested id={`_${priorityStatus.toString()}`} />
                                  ) : priorityStatus === 48 ? (
                                    <SentToTPA id={`_${priorityStatus.toString()}`} />
                                  ) : priorityStatus === 49 ? (
                                    <Required id={`_${priorityStatus.toString()}`} />
                                  ) : priorityStatus === 50 ? (
                                    <Approved id={`_${priorityStatus.toString()}`} />
                                  ) : priorityStatus === 51 ? (
                                    <Rejected id={`_${priorityStatus.toString()}`} />
                                  ) : priorityStatus === 52 ? (
                                    <Expired id={`_${priorityStatus.toString()}`} />
                                  ) : priorityStatus === 53 ? (
                                    <PartialApproval id={`_${priorityStatus.toString()}`} />
                                  ) : priorityStatus === 65 ? (
                                    <PartialApproval id={`_${priorityStatus.toString()}`} />
                                  ) : priorityStatus === 66 ? (
                                    <PartialApproval id={`_${priorityStatus.toString()}`} />
                                  ) : priorityStatus === 67 ? (
                                    <PartialApproval id={`_${priorityStatus.toString()}`} />
                                  ) : priorityStatus === 68 ? (
                                    <PartialApproval id={`_${priorityStatus.toString()}`} />
                                  ) : priorityStatus === 69 ? (
                                    <PartialApproval id={`_${priorityStatus.toString()}`} />
                                  ) : priorityStatus === 70 ? (
                                    <PartialApproval id={`_${priorityStatus.toString()}`} />
                                  ) : null}
                                  {/* <span>{detailInfo?.tpa_status}</span> */}

                                  <SelectDropdown
                                    class_name="inputFieldd"
                                    title=""
                                    value={detailInfo?.tpa_status}
                                    attrName={setPriorityStatus}
                                    value_update={updateMasterState}
                                    dropdown_data={[{ label: detailInfo?.tpa_status, id: detailInfo?.tpa_status, }]}
                                    warn_status={false}
                                    disabled={editPermission === false}
                                  />
                                </div>
                              </div>
                            </Grid>
                          </Grid>
                          <Grid container spacing={3}>
                            <Grid container spacing={3}>
                              <Grid xs={6} md={6} lg={3}>
                                <RKTextField
                                  class_name="inputField"
                                  title="Claim Refrence Number"
                                  value={detailInfo?.reference_no}
                                  attrName={"reference_no"}
                                  value_update={updateMasterState}
                                  warn_status={false}
                                  disabled={true}
                                  info_status
                                  info_message="Generated through TPA"
                                />
                              </Grid>
                              <Grid xs={6} md={6} lg={3}>
                                <RKTextField
                                  class_name="inputField"
                                  validation_type="name"
                                  title="Balance SI"
                                  value={formatCurrency(detailInfo?.balance_si || "0")}
                                  attrName={["detailData", "balance_si"]}
                                  value_update={updateMasterState}
                                  warn_status={false}
                                  //params={individualEdit}
                                  disabled={editPermission === false}
                                />
                              </Grid>
                              <Grid xs={6} md={6} lg={3}>
                                <RKTextField
                                  class_name="inputField"
                                  title="Requested Claim Amount"
                                  value={formatCurrency(detailInfo?.claimAmt)}
                                  attrName={"setClaimAmount"}
                                  value_update={updateMasterState}
                                  warn_status={false}
                                  disabled={true}
                                />
                              </Grid>
                              <Grid xs={6} md={6} lg={3}>
                                <RKTextField
                                  validation_type="numeric"
                                  class_name="inputField"
                                  title="Paid Claim Amount"
                                  value={formatCurrency(
                                    Number(detailInfo?.paid?.toString())
                                  )}
                                  attrName={["detailData", "paid"]}
                                  value_update={updateMasterState}
                                  warn_status={false}
                                  disabled
                                />
                              </Grid>
                              <Grid xs={6} md={6} lg={3}>
                                <RKTextField
                                  validation_type="numeric"
                                  class_name="inputField"
                                  title="Outstanding Claim Amount"
                                  value={formatCurrency(
                                    Number(detailInfo?.outstanding?.toString())
                                  )}
                                  attrName={["detailData", "outstanding"]}
                                  value_update={updateMasterState}
                                  warn_status={false}
                                  disabled
                                />
                              </Grid>
                              <Grid xs={6} md={6} lg={3}>
                                <RKTextField
                                  class_name="inputField"
                                  title="Transaction ID"
                                  value={detailInfo?.transactionId}
                                  attrName={["detailData", "transactionId"]}
                                  value_update={updateMasterState}
                                  warn_status={false}
                                  disabled
                                />
                              </Grid>
                              <Grid xs={6} md={6} lg={3}>
                                <RKTextField
                                  class_name="inputField"
                                  validation_type="name"
                                  title="Bank Info"
                                  value={detailInfo.branchName}
                                  attrName={["detailData", "branchName"]}
                                  value_update={updateMasterState}
                                  warn_status={false}
                                  disabled={editPermission === false}
                                />
                              </Grid>
                              <Grid xs={6} md={6} lg={3}>
                                <RKTextField
                                  class_name="inputField"
                                  validation_type="name"
                                  title="Remarks"
                                  value={detailInfo?.remarks}
                                  attrName={["detailData", "remarks"]}
                                  value_update={updateMasterState}
                                  warn_status={false}
                                  disabled//={editPermission === false}
                                />
                              </Grid>
                              <Grid xs={12} md={12} lg={6}>
                                <RKTextField //Disable
                                  class_name="inputField"
                                  validation_type="name"
                                  title="Ailment"
                                  value={detailInfo?.ailment}
                                  attrName={["detailData", "ailment"]}
                                  value_update={updateMasterState}
                                  warn_status={false}
                                  // params={individualEdit}
                                  disabled//={editPermission === false}
                                />
                              </Grid>
                              <Grid xs={12} md={12} lg={6}>
                                <RKTextField //Disable
                                  class_name="inputField"
                                  validation_type="name"
                                  title="Deficiency IR Requirment"
                                  value={detailInfo?.deficiency_ir_req}
                                  attrName={["detailData", "deficiency_ir_req"]}
                                  value_update={updateMasterState}
                                  warn_status={false}
                                  // params={individualEdit}
                                  disabled//={editPermission === false}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </div>
                      </Box>

                      <Box ref={docChecklist}>
                        <DocumentChecklistView
                          type="tpa"
                          role="viewOnly"
                          checklist={detailInfo?.tpaData?.documentChecklist}
                        />
                      </Box>

                      <Box
                        className="detailSection"
                        ref={attatchmentsdetails}
                      >
                        <div className="sectionTitle">
                          <h4>Bills & Attachments </h4>

                          <div className="actionBtns">
                            <Link
                              className="blueBtn addfile"
                              sx={{
                                textDecoration: "none",
                                cursor: "pointer",
                              }}
                              onClick={() => setUploadFile(true)}
                            >
                              Add Document
                            </Link>
                          </div>
                        </div>
                        <Attachment
                          open_status={attachment}
                          attrName={setAttachment}
                          value_update={updateMasterState}
                          dataArray={detailInfo?.upload}
                          deleteurl={Claim_SERVICES.delete_attachment_claim}
                        />
                      </Box>
                    </>
                  )}
                  {viewTabsStatus === "additional" &&
                    <>
                      <Box className="detailSection" ref={expenses}>
                        <div className="sectionTitle">
                          <h4>Expenses</h4>
                        </div>
                        <div>
                          <Grid container spacing={3}>
                            <Grid xs={12}>
                              <div className="employee_table tpa_table">
                                <div className="employee_innerpart content_table" >
                                  <ul className="heading">
                                    <li>Item</li>
                                    <li className="title">Value</li>
                                  </ul>
                                  {info?.expenses && (
                                    Object.entries(info?.expenses)?.map(([keys, values]: [string, string]) => {
                                      let data = keys?.split('_')?.map(word => word.charAt(0).toUpperCase() + word.slice(1));
                                      let final = data.join(' ');
                                      return (
                                        <ul className="subheading">
                                          <li>{final}</li>
                                          <li className="title">{formatCurrency(values)}</li>
                                        </ul>
                                      )
                                    }))}
                                </div>
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </Box>
                      <Box className="detailSection" ref={tpaDatesInfo}>
                        <div className="sectionTitle">
                          <h4>TPA Dates Information</h4>
                        </div>
                        <div>
                          <Grid container spacing={3}>
                            <Grid xs={12}>
                              <div className="employee_table tpa_table">
                                <div className="employee_innerpart content_table" >
                                  <ul className="heading">
                                    <li>Item</li>
                                    <li className="title">Value</li>
                                  </ul>
                                  {info?.important_dates && (Object.entries(info?.important_dates)?.map(([keys, values]: [string, string]) => {
                                    let data = keys?.split('_')?.map(word => word.charAt(0).toUpperCase() + word.slice(1));
                                    let final = data.join(' ');
                                    return (
                                      <ul className="subheading">
                                        <li>{final}</li>
                                        <li className="title">{values != "" ? date_time_format(moment(values, "DD/MM/YYYY HH:mm:ss").toDate(), dateFormat, timeZone) : "--"}</li>
                                      </ul>
                                    )
                                  }))}
                                </div>
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </Box>
                      <Box className="detailSection" ref={tpaIdInfo}>
                        <div className="sectionTitle">
                          <h4>TPA ID Information</h4>
                        </div>
                        <div>
                          <Grid container spacing={3}>
                            <Grid xs={12}>
                              <div className="employee_table tpa_table">
                                <div className="employee_innerpart content_table" >
                                  <ul className="heading">
                                    <li>Item</li>
                                    <li className="title">Value</li>
                                  </ul>
                                  {info?.important_ids && (Object.entries(info?.important_ids)?.map(([keys, values]: [string, string]) => {
                                    let data = keys?.split('_')?.map(word => word.charAt(0).toUpperCase() + word.slice(1));
                                    let final = data.join(' ');
                                    return (
                                      <ul className="subheading">
                                        <li>{final}</li>
                                        <li className="title">{values}</li>
                                      </ul>
                                    )
                                  }))}
                                </div>
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </Box>
                      <Box className="detailSection" ref={tpaIdInfo}>
                        <div className="sectionTitle">
                          <h4>TPA Status Information</h4>
                        </div>
                        <div>
                          <Grid container spacing={3}>
                            <Grid xs={12}>
                              <div className="employee_table tpa_table">
                                <div className="employee_innerpart content_table" >
                                  <ul className="heading">
                                    <li>Item</li>
                                    <li className="title">Value</li>
                                  </ul>
                                  {info?.important_status && (Object.entries(info?.important_status)?.map(([keys, values]: [string, string]) => {
                                    let data = keys?.split('_')?.map(word => word.charAt(0).toUpperCase() + word.slice(1));
                                    let final = data.join(' ');
                                    return (
                                      <ul className="subheading">
                                        <li>{final}</li>
                                        <li className="title">{values}</li>
                                      </ul>
                                    )
                                  }))}
                                </div>
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </Box>
                      <Box className="detailSection" ref={tpaIdInfo}>
                        <div className="sectionTitle">
                          <h4>TPA Remarks</h4>
                        </div>
                        <div>
                          <Grid container spacing={3}>
                            <Grid xs={12}>
                              <div className="employee_table tpa_table">
                                <div className="employee_innerpart content_table" >
                                  <ul className="heading">
                                    <li>Item</li>
                                    <li className="title">Value</li>
                                  </ul>
                                  {info?.remarks && (Object.entries(info?.remarks)?.map(([keys, values]: [string, string]) => {
                                    let data = keys?.split('_')?.map(word => word.charAt(0).toUpperCase() + word.slice(1));
                                    let final = data.join(' ');
                                    return (
                                      <ul className="subheading">
                                        <li>{final}</li>
                                        <li className="title">{values}</li>
                                      </ul>
                                    )
                                  }))}
                                </div>
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </Box>
                    </>
                  }
                  {viewTabsStatus === "notes" && (
                    <>
                      <Box className="detailSection">
                        <div className="sectionTitle">
                          <h4>Notes</h4>

                          <div className="actionBtns">
                            <Link
                              className="blueBtn addfile"
                              sx={{
                                textDecoration: "none",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setPostQueryForm(true);
                              }}
                            >
                              Add Notes
                            </Link>
                          </div>
                        </div>

                        <NotesSection data={detailInfo?.notes} />
                      </Box>
                    </>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <BulkUploadForm
          open_status={uploadFile}
          attrName={setUploadFile}
          value_update={updateMasterState}
        />
        <PostQueryForm
          attrName={setPostQueryForm}
          open_status={postQueryForm}
          value_update={updateMasterState}
        />

        <Modal open={deleteStatus} className="modalWrapper">
          <Box className="modalInner">
            <Grid container spacing={1}>
              <Grid xs={12}>
                <Link
                  className="close-button"
                  onClick={() => {
                    setDeleteStatus(false);
                  }}
                />
                <h5 className="popup-heading">Are you sure ?</h5>
              </Grid>
              <Grid xs={12}>
                <Link className="md-link" underline="none">
                  You are about to Delete this Attatchment.
                </Link>
              </Grid>
              <Grid xs={12} className="ctaBtn" textAlign={"center"}>
                <Button variant="contained" className="submitBtn">
                  Yes, Delete
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Modal>
        <Modal open={removeStatus} className="modalWrapper">
          <Box className="modalInner">
            <Grid container spacing={1}>
              <Grid xs={12}>
                <Link
                  className="close-button"
                  onClick={() => {
                    setRemoveStatus(false);
                  }}
                />
                <h5 className="popup-heading">Are you sure ?</h5>
              </Grid>
              <Grid xs={12}>
                <Link className="md-link" underline="none">
                  You are want to remove this Claim.
                </Link>
              </Grid>
              <Grid xs={12} className="ctaBtn" textAlign={"center"}>
                <Button variant="contained" className="submitBtn">
                  Yes, Delete
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Modal>
      </Box>
    </>
  );
}
export default ClaimAssistantDetail;
