import shortid from "shortid";

export const SIDEBAR_ITEM = "sidebarItem";
export const ROW = "row";
export const COLUMN = "column";
export const COMPONENT = "component";
export const SECTION = "section";
export const FIELD = "field";

export const SIDEBAR_ITEMS = [
  {
    id: shortid.generate(),
    class_name: "singleline",
    type: SIDEBAR_ITEM,
    component: {
      type: "Single Line",
      content: "Single Line Text",
      field_type: "singleline",
    },
  },
  {
    id: shortid.generate(),
    type: SIDEBAR_ITEM,
    class_name: "multiline",
    component: {
      type: "Multi Line",
      content: "Multiline Text",
      field_type: "multiline",
    },
  },
  {
    id: shortid.generate(),
    type: SIDEBAR_ITEM,
    class_name: "phone",
    component: {
      type: "Phone",
      content: "Contact Number",
      field_type: "phone",
    },
  },
  {
    id: shortid.generate(),
    type: SIDEBAR_ITEM,
    class_name: "singleselect",
    component: {
      type: "Single Select",
      content: "Select Dropdown",
      field_type: "singleselect",
    },
  },
  {
    id: shortid.generate(),
    type: SIDEBAR_ITEM,
    class_name: "multiselect",
    component: {
      type: "Multi Select",
      content: "Multiselect Dropdown",
      field_type: "multiselect",
    },
  },
  {
    id: shortid.generate(),
    type: SIDEBAR_ITEM,
    class_name: "currency",
    component: {
      type: "Currency",
      content: "Enter Currency",
      field_type: "currency",
    },
  },
  {
    id: shortid.generate(),
    type: SIDEBAR_ITEM,
    class_name: "checkbox",
    component: {
      type: "Checkbox",
      content: "Checkbox Label",
      field_type: "checkbox",
    },
  },
  {
    id: shortid.generate(),
    type: SIDEBAR_ITEM,
    class_name: "datepicker",
    component: {
      type: "Datepicker",
      content: "Select Date",
      field_type: "datepicker",
    },
  },
  {
    id: shortid.generate(),
    type: SIDEBAR_ITEM,
    class_name: "email",
    component: {
      type: "Email",
      content: "Email Address",
      field_type: "email",
    },
  },
  {
    id: shortid.generate(),
    type: SIDEBAR_ITEM,
    class_name: "number",
    component: {
      type: "Number",
      content: "Numeric Field",
      field_type: "number",
    },
  },
  {
    id: shortid.generate(),
    type: SIDEBAR_ITEM,
    class_name: "searchselect",
    component: {
      type: "Search Select",
      content: "Search Dropdown",
      field_type: "searchselect",
    },
  },
  {
    id: shortid.generate(),
    type: SIDEBAR_ITEM,
    class_name: "url",
    component: {
      type: "URL",
      content: "Web URL",
      field_type: "url",
    },
  },
  {
    id: shortid.generate(),
    type: SIDEBAR_ITEM,
    class_name: "address",
    component: {
      type: "Address",
      content: "Address",
      field_type: "address",
    },
  },
];
