import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Box, Button, Link, Modal } from "@mui/material";

import SlidingPanel from "react-sliding-side-panel";
import RKTextField from "../../../../../../../Supporting files/RKTextField/RKTextField";
import SelectDropdown from "../../../../../../../Supporting files/SelectDropdown/SelectDropdown";
import { TAXATION_SERVICES } from "../../../../../../../Services/Settings/OrganisationSettings/TaxationServices";
import { UpdateTaxDTO } from "../../../../../../../Services/DTO/Settings/Organisation/TaxationDTO";
import { toast } from "react-toastify";
import { GridCloseIcon } from "@mui/x-data-grid";

interface Props {
  open_status: boolean;
  attrName: any;
  value_update: Function;
  refetch_data: Function;
  edit_obj: any;
  parentId: string;
  taxTypeOptions: Array<{}>;
}

const EditTaxPopup: React.FC<Props> = ({
  open_status,
  attrName,
  value_update,
  refetch_data,
  edit_obj,
  parentId,
  taxTypeOptions,
}) => {
  const [taxname, setTaxName] = React.useState("");
  const [rate, setRate] = React.useState("");
  const [taxtype, setTaxType] = React.useState('');
  const [taxnameWarn, setTaxNameWarn] = useState<boolean>(false);
  const [rateWarn, setRateWarn] = useState<boolean>(false);
  const [taxtypeWarn, setTaxTypeWarn] = useState<boolean>(false);
  const [taxtype1, setTaxType1] = useState<Array<{ label: string; id: string }>
  >([
    { id: "Standard Tax", label: "Standard Tax" },
    { id: "Surcharge", label: "Surcharge" },
  ]);

  useEffect(() => {
    setTaxName(edit_obj.taxName);
    setRate(edit_obj.taxRate);
    setTaxType(edit_obj.taxType);
  }, [open_status, edit_obj]);
  
  const updateMasterState = (attrName: any, value: any) => {
    if (attrName === "setRate") {
      const val = value.replace(/[^0-9]/g, '');
      if (val === "0") return setRate("");
      
      if (Number(val) >= 0 && Number(val) <= 100) {
        setRate(val)
      }
    } else {
      attrName(value);
    }
  };

  const updateTax = (): void => {
    const onSuccess = (res: any) => {
      toast.success("Updated tax rate");
      refetch_data();
      value_update(attrName, false);
    };

    const onError = (err: any) => {
      console.log("err", err);
    };

    const data: UpdateTaxDTO = {
      taxName: taxname,
      taxRate: rate,
      taxType: taxtype,
    };

    let taxwarn = !taxname || taxname === "" ? true : false;
    let ratewarn = !rate || rate === "" ? true : false;
    let typewarn = !taxtype || taxtype === "" ? true : false;
    setTaxNameWarn(taxwarn);
    setRateWarn(ratewarn);
    setTaxTypeWarn(typewarn);

    if (taxwarn === false && ratewarn === false && typewarn === false) {
      TAXATION_SERVICES.update_tax(
        onSuccess,
        onError,
        parentId,
        edit_obj._id,
        data
      );
    }

  };

  return (
    <SlidingPanel
      type={"right"}
      isOpen={open_status}
      size={75}
    // backdropClicked={() => value_update(attrName, false)}
    >
      <div>
        <Grid container spacing={3} alignItems="center">
          <Grid xs={10}>
            <h4 className="mb-0 mt-4 ml-3">Edit Tax</h4>
          </Grid>
          <Grid xs={2} textAlign="end" className="mt-3">
            <GridCloseIcon
              onClick={() => {
                value_update(attrName, false);
                setTaxName("");
                setRate("");
                setTaxType("");
                setTaxNameWarn(false);
                setRateWarn(false);
                setTaxTypeWarn(false);
              }}
            />
          </Grid>
          <Grid xs={12} className="mb-5">
            <hr />
          </Grid>
        </Grid>
        <div className="scrollable_area">
          <Grid container spacing={3}>
            <Grid xs={12}>
              <RKTextField
                class_name="inputField"
                title={"Tax Name"}
                value={taxname}
                attrName={setTaxName}
                value_update={updateMasterState}
                warn_status={taxnameWarn}
                error_messg="Enter Tax Name"
              />
            </Grid>
            <Grid xs={12}>
              <RKTextField
                class_name="inputField"
                title={"Rate (%)"}
                value={rate}
                attrName={"setRate"}
                value_update={updateMasterState}
                warn_status={rateWarn}
                validation_type="numericfloatvalue"
                error_messg="Enter Rate %"
              />
            </Grid>
            <Grid xs={12}>
              <SelectDropdown
                class_name="inputField"
                title="Tax Type"
                value={taxtype}
                attrName={setTaxType}
                value_update={updateMasterState}
                dropdown_data={taxtype1}
                warn_status={taxtypeWarn}
                error_messg="Enter Tax Type"
              />
            </Grid>
          </Grid>
        </div>
        <Grid container spacing={3}>
          <Grid xs={12} className="ctaBtn mt-4">
            <Button
              variant="contained"
              className="submitBtn fixedbtn"
              onClick={updateTax}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </div>
    </SlidingPanel>
  );
};

export default EditTaxPopup;
