import { Button, Link, Modal } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Box } from "@mui/system";

const GlobalDeletePopup = ({
  attrName,
  open_status,
  value_update,
  ids,
  delete_item,
  delete_step,
  title,
  delete_step_attr,
  type,
}: {
  attrName?: any;
  open_status: boolean;
  value_update: Function;
  ids?: any;
  delete_item?: any;
  delete_step?: number;
  title?: string;
  delete_step_attr?: any;
  type?: string;
}) => {

  const deleteditem = () => {
    value_update("delete_step", 1);
    value_update(attrName, false);
  };

  return (
    <>
      <Modal open={open_status} className="modalWrapper">
        <>
          {delete_step === 1 ? (
            <Box className="modalInner deletepopup">
              <Grid container spacing={1}>
                <Grid xs={12} sx={{ width: "100%" }}>
                  <Link
                    className="close-button"
                    onClick={() => {
                      value_update(attrName, false);
                    }}
                  />
                  <div className="img-prt">
                    <img
                      src="./images/deletepopup_deleteicon.svg"
                      alt=""
                      className="img"
                    />
                  </div>
                  {ids?.length === 0 ? ("") : (
                    <h5 className="popup-heading">
                      Are you sure you want
                      <br /> to delete this?
                    </h5>
                  )}
                </Grid>
                <Grid xs={12} sx={{ width: "100%" }}>
                  <Link className="md-link mb-10" underline="none">
                    {ids?.length === 0
                      ? `Please Select the ${title}`
                      : `You are about to delete ${ids?.length}`}
                  </Link>
                </Grid>
                {ids?.length === 0 ? ("") : (
                  <Grid
                    xs={12}
                    className="ctaBtn"
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Button
                      variant="contained"
                      className="submitBtn"
                      onClick={delete_item}
                    >
                      Yes, Delete
                    </Button>
                    <Button
                      variant="contained"
                      className="cancelBtn"
                      onClick={() => {
                        value_update(attrName, false);
                      }}
                    >
                      cancel
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Box>
          ) : delete_step === 2 ? (
            <Box className="modalInner deletepopup">
              <Grid container spacing={1}>
                <Grid xs={12} sx={{ width: "100%" }}>
                  <Link
                    className="close-button"
                    onClick={() => {
                      value_update(attrName, false);
                    }}
                  />
                  <div className="img-prt">
                    <img
                      src="./images/deletePopup_tickicon.svg"
                      alt=""
                      className="img"
                    />
                  </div>
                  <h5 className="popup-heading">Deleted Successfully!</h5>
                </Grid>
                <Grid xs={12} sx={{ width: "100%" }}>
                  <Link className="md-link mb-10" underline="none">
                    Your {title} has been Deleted
                  </Link>
                </Grid>
                <Grid
                  xs={12}
                  className="ctaBtn"
                  style={{ textAlign: "center", width: "100%" }}
                >
                  <Button
                    onClick={deleteditem}
                    variant="contained"
                    className="submitBtn"
                  >
                    Okay
                  </Button>
                </Grid>
              </Grid>
            </Box>
          ) : null}
        </>
      </Modal>
    </>
  );
};

export default GlobalDeletePopup;
