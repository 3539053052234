import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Box, Button, Link, Modal } from "@mui/material";
import SelectDropdown from "../../../../../Supporting files/SelectDropdown/SelectDropdown";
import SearchDropdown from "../../../../../Supporting files/SearchDropdown/SearchDropdown";
import RKTextField from "../../../../../Supporting files/RKTextField/RKTextField";
import CheckBox from "../../../../../Supporting files/CheckBox/CheckBox";
import SlidingPanel from "react-sliding-side-panel";
import "./../CustomModules.scss";
import { ToggelModalAction } from "../../../../../Store/actions/modalAction";
import { useSelector, useDispatch } from "react-redux";
import { UpdateLayoutPropertiesAction } from "../../../../../Store/actions/layoutAction";
import { checkErrors } from "../CustomModuleV1/helpers";
import { ROW, COLUMN } from "../CustomModuleV1/constants";
import CloseIcon from "@mui/icons-material/Close";
import shortid from "shortid";

const URLProperties = ({ open_status }) => {
  const modal = useSelector((state) => {
    return state.modalReducer;
  });
  const layoutData = useSelector(({ layoutReducer }) => {
    return layoutReducer;
  });

  const [tooltiptext, setTooltipText] = React.useState(
    modal.data.tipText ? modal.data.tipText : ""
  );
  const [required, setRequired] = React.useState(
    modal.data.required ? modal.data.required : false
  );
  const [tooltip, setTooltip] = React.useState(
    modal.data.toolTip ? modal.data.toolTip : false
  );
  const [title, setTitle] = React.useState(modal.data.field_lable);
  const [characterLimit, setCharacterLimit] = React.useState(
    modal.data.characterLimit ? modal.data.characterLimit : ""
  );

  const [isError, setError] = React.useState(false);
  const [isSuccess, setSuccess] = React.useState(false);

  const updateMasterState = (attrName, value) => {
    attrName(value);
  };
  const dispatch = useDispatch();

  const submitHandler = () => {
    let objectToUpdate = {
      ...modal.data,
      field_lable: title,
      characterLimit,
      required,
      tipText: tooltiptext,
      toolTip: tooltip,
    };
    let path = modal.path.split("-");
    //layoutData.layout[path[0]].children[path[1]].children[path[2]].children[path[3]]= objectToUpdate

    let columnObj = {
      id: shortid.generate(),
      type: COLUMN,
      children: [objectToUpdate],
    };


    let isError = checkErrors(title);

    if (!isError) {

      if (!objectToUpdate.from) {
        if (path.length === 5) {
          layoutData.layout[path[0]].children[path[1]].children[path[2]].children[
            path[3]
          ].children.splice(path[4], 0, objectToUpdate);
        } else if (path.length === 4) {
          layoutData.layout[path[0]].children[path[1]].children[
            path[2]
          ].children.splice(path[3], 0, columnObj);
        } else if (path.length === 3) {
          layoutData.layout[path[0]].children[path[1]].children.splice(
            path[2],
            0,
            { id: shortid.generate(), type: ROW, children: [columnObj] }
          );
        } else if (path.length === 2) {
          layoutData.layout[path[0]].children.splice(path[1], 0, {
            id: shortid.generate(),
            type: ROW,
            name: "New Sub Section",
            children: [
              {
                id: shortid.generate(),
                type: COLUMN,
                children: [objectToUpdate],
              },
            ],
          });
        }
      } else {
        layoutData.layout[path[0]].children[path[1]].children[path[2]].children[
          path[3]
        ].children[path[4]] = objectToUpdate; //[path[3]]
      }

      dispatch(UpdateLayoutPropertiesAction(layoutData.layout, objectToUpdate));
      setError(false);
      setSuccess(true);
      dispatch(ToggelModalAction(modal.isOpen, "", null));
    } else {
      setError(true);
      setSuccess(false);
    }
  };

  useEffect(() => {
    if (isError || isSuccess) {
      setTimeout(() => {
        setError(false);
        setSuccess(false);
      }, 2000);
    }
  }, [isError, isSuccess]);

  const checkErrorOnBackdrop = () => {
    let isError = checkErrors(title);
    if (!isError) {
      //dispatch(ToggelModalAction(modal.isOpen, "", null))
      setError(false);
    } else {
      setError(true);
    }
  };

  return (
    <SlidingPanel
      type={"right"}
      isOpen={open_status}
      size={75}
      backdropClicked={checkErrorOnBackdrop}
    >
      <div>
        <Grid container spacing={3} alignItems="center">
          <Grid xs={11} className="pt-3">
            <h4 className="mb-0">Field Properties - Website URL</h4>
          </Grid>

          {/* {modal.data.from ? (
            <Grid
              xs={1}
              className="pt-3"
              onClick={() => {
                dispatch(ToggelModalAction(modal.isOpen, "", null));
              }}
            >
              <CloseIcon />
            </Grid>
          ) : (
            ""
          )} */}

          <Grid
              xs={1}
              className="pt-3"
              onClick={() => {
                dispatch(ToggelModalAction(modal.isOpen, "", null));
              }}
            >
              <CloseIcon />
          </Grid>

          <Grid xs={12} className="mb-4">
            <hr />
          </Grid>
        </Grid>
        <div className="scrollable_area pt-3">
          <Grid container spacing={3}>
            <Grid xs={12}>
              <RKTextField
                class_name="inputField"
                title="Field Label"
                value={title}
                attrName={setTitle}
                value_update={updateMasterState}
                error_messg="Field Label is Required!"
                warn_status={isError}
              />
            </Grid>
            <Grid xs={12}>
              <RKTextField
                class_name="inputField"
                title="Character Limit"
                validation_type="numeric"
                value={characterLimit}
                attrName={setCharacterLimit}
                value_update={updateMasterState}
                warn_status={false}
              />
            </Grid>
            <Grid xs={12}>
              <CheckBox
                title="Required"
                name="required"
                id="required"
                value={required}
                attrName={setRequired}
                value_update={updateMasterState}
                default_check={true}
              />
            </Grid>
            <Grid xs={12} className="checkbox_feature ">
              <CheckBox
                title="Show Tooltip"
                name="tooltip"
                id="tooltip"
                value={tooltip}
                attrName={setTooltip}
                value_update={updateMasterState}
                default_check={false}
              />
            </Grid>
            {tooltip === true ? (
              <Grid xs={12}>
                <RKTextField
                  class_name="inputField"
                  title="Enter Tooltip Text"
                  value={tooltiptext}
                  attrName={setTooltipText}
                  value_update={updateMasterState}
                  warn_status={false}
                />
              </Grid>
            ) : null}
          </Grid>

          <Grid container spacing={3}></Grid>

          {isSuccess ? (
            <div className="successMessage">
              <h4>Field Property Updated!</h4>
            </div>
          ) : (
            ""
          )}
        </div>
        <Grid xs={12} className="ctaBtn mt-4 text-center">
          <Button
            variant="contained"
            className="submitBtn "
            onClick={submitHandler}
          >
            Done
          </Button>
        </Grid>
      </div>
    </SlidingPanel>
  );
};

export default URLProperties;
