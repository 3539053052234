import { Box, Button, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React from "react";
import SlidingPanel from "react-sliding-side-panel";
import CloseIcon from "@mui/icons-material/Close";
import SearchDropdown from "../../../../../Supporting files/SearchDropdown/SearchDropdown";
import DatePicker from "../../../../../Supporting files/DatePicker/DatePicker";
import RKTextField from "../../../../../Supporting files/RKTextField/RKTextField";
import SelectDropdown from "../../../../../Supporting files/SelectDropdown/SelectDropdown";
interface Props {
  open_status: boolean;
  attrName: any;
  value_update: Function;
}

const TravelPopup: React.FC<Props> = ({
  open_status,
  attrName,
  value_update,
}) => {
  const [travel, setTravel] = React.useState("");
  const [planType, setPlanType] = React.useState("");
  const [frequency, setFrequency] = React.useState("");
  const [mobile, setMobile] = React.useState("");
  const [memberDate, setMemberDate] = React.useState<Date | null>(null);
  const [endDate, setEndDate] = React.useState<Date | null>(null);

  const updateMasterState = (attrName: any, value: any) => {
    attrName(value);
  };

  const travel_data = [
    { label: "India", value: "India" },
    { label: "New Zealand", value: "New Zealand" },
    { label: "England", value: "England" },
    { label: "Australia", value: "Australia" },
  ];
  const planType_data = [
    { Key: "Individual/ Family", value: "Individual/ Family" },
    { Key: "Student", value: "Student" },
  ];
  const frequency_data = [
    { Key: "Single Trip", value: "Single Trip" },
    { Key: "Multi Trip", value: "Multi Trip" },
  ];

  return (
    <>
      <SlidingPanel
        type={"right"}
        isOpen={open_status}
        size={75}
        panelClassName="slidingPanel"
        // backdropClicked={() => value_update(attrName, false)}
      >
        <>
          <Grid container spacing={2} alignItems="center">
            <Grid xs={10} className="headingTitle">
              <h3>
                <span>Add Your Details</span>
              </h3>
              <p>Kindly provide essential details about your Travel.</p>
            </Grid>
            <Grid xs={2} textAlign="right">
              <Link
                className="closeIcon"
                onClick={() => {
                  value_update(attrName, false);
                }}
              >
                <CloseIcon />
              </Link>
            </Grid>
            <Grid xs={12}>
              <hr />
            </Grid>
          </Grid>
          <Box className="scrollable_area">
            <Grid container spacing={3}>
              <Grid xs={6}>
                <SearchDropdown
                  class_name="inputField"
                  title="Where you want to travel?"
                  value={travel}
                  attrName={setTravel}
                  value_update={updateMasterState}
                  data={travel_data}
                  warn_status={false}
                  error_message={"please select type"}
                />
              </Grid>
              <Grid xs={6}>
                <SelectDropdown
                  class_name="inputField"
                  title="Plan Type"
                  value={planType}
                  attrName={setPlanType}
                  value_update={updateMasterState}
                  dropdown_data={planType_data}
                  warn_status={false}
                />
              </Grid>
              <Grid xs={6}>
                <SelectDropdown
                  class_name="inputField"
                  title="Frequency"
                  value={frequency}
                  attrName={setFrequency}
                  value_update={updateMasterState}
                  dropdown_data={frequency_data}
                  warn_status={false}
                />
              </Grid>

              <Grid xs={6}>
                <DatePicker
                  class_name="inputField"
                  title="Start Date"
                  value={memberDate}
                  attrName={setMemberDate}
                  value_update={updateMasterState}
                  error_message="Please enter Start Date"
                  warn_status={false}
                />
              </Grid>
              <Grid xs={6}>
                <DatePicker
                  class_name="inputField"
                  title="End Date"
                  value={endDate}
                  attrName={setEndDate}
                  value_update={updateMasterState}
                  error_message="Please enter End Date"
                  warn_status={false}
                />
              </Grid>
              <Grid xs={6}>
                <RKTextField
                  class_name="inputField"
                  title= "Mobile"
                  value={mobile}
                  attrName={setMobile}
                  value_update={updateMasterState}
                  warn_status={false}
                />
              </Grid>
            </Grid>
          </Box>
          <Grid container spacing={3}>
            <Grid xs={12} className="ctaBtn mt-4">
              <Button
                variant="contained"
                className="submitBtn fixedbtn"
                onClick={() => {}}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </>
      </SlidingPanel>
    </>
  );
};

export default TravelPopup;
