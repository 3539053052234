import React, { useRef } from "react";
import { useDrag } from "react-dnd";
import { COLUMN } from "./constants";
import DropZone from "./DropZone";
import Component from "./Component";
import { Link } from "@mui/material";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useSelector } from "react-redux";
import { GetLayoutAction, RemoveItem } from "../../../../../Store/actions/layoutAction";
import { useDispatch } from "react-redux";

const style = {};
const Column = ({ data, components, handleDrop, path, childrenCount }) => {
  const ref = useRef(null);
  const dispatch = useDispatch();
  const layoutData = useSelector(({layoutReducer})=>{return layoutReducer});

  //console.log(childrenCount)
  //console.log(layoutData.layout[0]?layoutData.layout[0].children.length:"")

  const [{ isDragging }, drag] = useDrag({
    item: {
      type: COLUMN,
      id: data.id,
      children: data.children,
      path
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  });

  const opacity = isDragging ? 0 : 1;
  //drag(ref);

  const renderComponent = (component, currentPath) => {
    // console.log(currentPath)
    return (
      <Component
        key={component.id}
        data={component}
        components={components}
        path={currentPath}
      />
    );
  };

  const removeColumn = ()=>{
    dispatch(RemoveItem(data, components, path, layoutData));
  }

  return (
    <div
      ref={ref}
      style={{ ...style, opacity }}
      className="draggable column"
    >
      {childrenCount!==1 && !data.preDefiend? <div className="actionBtns text-right">
          {/* <Link
            onClick={removeColumn}
            className="delete_btn">
            <DeleteForeverIcon />
          </Link> */}
        </div>:""}
     
      {/* {data.id} */}
      {data.children.map((component, index) => {
        const currentPath = `${path}-${index}`;

        return (
          <React.Fragment key={component.id}>
            {/* <DropZone
              data={{
                path: currentPath,
                childrenCount: data.children.length,
                type: component.field_type
              }}
              onDrop={handleDrop}
            /> */}
            {/* {component.field_type!=="address"?<DropZone
              data={{
                path: currentPath,
                childrenCount: data.children.length,
                type: component.field_type
              }}
              onDrop={handleDrop}
            />:<DropZone/>} */}
            {renderComponent(component, currentPath)}
          </React.Fragment>
        );
      })}
      
      {/* <DropZone
        data={{
          path: `${path}-${data.children.length}`,
          childrenCount: data.children.length,
          type: data
        }}
        onDrop={handleDrop}
        isLast
      /> */}
    </div>
  );
};
export default Column;
