import { URL_CONSTANTS } from "../../APIVar/URLConstants";

import { GetAPI, PostAPI } from "../API";

const broker_dashboard_stats = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
  startdate: any,
  endDate: any,
  country:any,
) => {
  GetAPI.call(
    `${URL_CONSTANTS.broker_dashboard_stats}?startDate=${startdate}&endDate=${endDate}&country=${country}`,
    onSuccess,
    {},
    onError
  );
};


export const HOMEBROKER_SERVICES = {
  broker_dashboard_stats,
};
