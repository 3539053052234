import { URL_CONSTANTS } from "../../APIVar/URLConstants";
import { GetAPI, PostAPI, DeleteAPI, PatchAPI } from "../API";
import {
  groupPersonalAccidentAddDTO,
  groupPersonalAccidentDeleteDTO,
  groupPersonalAccidentExculsionAddDTO,
  groupPersonalAccidentFeatureAddDTO,
} from "../DTO/ProductConfigurator/GroupPersonalAccidentDTO";

const createCatageory = (
  dto: groupPersonalAccidentAddDTO[],
  onSuccess: any,
  config: any,
  onError: any
) => {
  PostAPI.call(
    URL_CONSTANTS.create_group_personal_accident_catagory,
    dto,
    onSuccess,
    {},
    onError
  );
};
const getCatageory = (
  searchBarFilter:any,
  onSuccess: (data: any) => void,
  onError: (err: any) => void
) => {
  GetAPI.call(
    `${URL_CONSTANTS.get_group_personal_accident_catageory}${searchBarFilter}`,
    onSuccess,
    onError
  );
};
const deleteCatageory = (
  dto: groupPersonalAccidentDeleteDTO,
  onSuccess: (data: any) => void,
  config: any,
  onError: (err: any) => void
) => {
  DeleteAPI.call(
    URL_CONSTANTS.delete_group_personal_accident_catagory,
    dto,
    onSuccess,
    {},
    onError
  );
};
const updateCatageory = (
  dto: groupPersonalAccidentAddDTO,
  onSuccess: any,
  config: any,
  onError: any,
  id: string | undefined
) => {
  PatchAPI.call(
    `${URL_CONSTANTS.update_group_personal_accident_catagory}${id}`,
    dto,
    onSuccess,
    {},
    onError
  );
};
// exculsion api method
const getExculsion = (
  searchBarFilter:any,
  onSuccess: (data: any) => void,
  onError: (err: any) => void
) => {
  GetAPI.call(
    `${URL_CONSTANTS.get_group_personal_accident_exclusion}${searchBarFilter}`,
    onSuccess,
    onError
  );
};
const createExcuslion = (
  dto: groupPersonalAccidentExculsionAddDTO[],
  onSuccess: any,
  config: any,
  onError: any
) => {
  PostAPI.call(
    URL_CONSTANTS.create_group_personal_accident_exclusion,
    dto,
    onSuccess,
    {},
    onError
  );
};
const updateExclusion = (
  dto: groupPersonalAccidentExculsionAddDTO,
  onSuccess: any,
  config: any,
  onError: any,
  id: string | undefined
) => {
  PatchAPI.call(
    `${URL_CONSTANTS.update_group_personal_accident_exclusion}${id}`,
    dto,
    onSuccess,
    {},
    onError
  );
};
const deleteExclusion = (
  dto: groupPersonalAccidentDeleteDTO,
  onSuccess: (data: any) => void,
  config: any,
  onError: (err: any) => void
) => {
  DeleteAPI.call(
    URL_CONSTANTS.delete_group_personal_accident_exclusion,
    dto,
    onSuccess,
    {},
    onError
  );
};
// feature api method
const getFeature = (
  searchBarFilter:any,
  onSuccess: (data: any) => void,
  onError: (err: any) => void
) => {
  GetAPI.call(
    `${URL_CONSTANTS.get_group_personal_accident_feature}${searchBarFilter}`,
    onSuccess,
    onError
  );
};
const createFeature = (
  dto: groupPersonalAccidentFeatureAddDTO[],
  onSuccess: any,
  config: any,
  onError: any
) => {
  PostAPI.call(
    URL_CONSTANTS.create_group_personal_accident_feature,
    dto,
    onSuccess,
    {},
    onError
  );
};
const updateFeature = (
  dto: groupPersonalAccidentFeatureAddDTO,
  onSuccess: any,
  config: any,
  onError: any,
  id: string | undefined
) => {
  PatchAPI.call(
    `${URL_CONSTANTS.update_group_personal_accident_feature}${id}`,
    dto,
    onSuccess,
    {},
    onError
  );
};
const deleteFeature = (
  dto: groupPersonalAccidentDeleteDTO,
  onSuccess: (data: any) => void,
  config: any,
  onError: (err: any) => void
) => {
  DeleteAPI.call(
    URL_CONSTANTS.delete_group_personal_accident_feature,
    dto,
    onSuccess,
    {},
    onError
  );
};
export const GROUP_PERSONAL_ACCIDENT_SERVICES = {
  createCatageory,
  getCatageory,
  deleteCatageory,
  updateCatageory,
  getExculsion,
  createExcuslion,
  updateExclusion,
  deleteExclusion,
  getFeature,
  createFeature,
  updateFeature,
  deleteFeature,
};
