import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Alert, Link } from "@mui/material";

import SlidingPanel from "react-sliding-side-panel";
import CheckBox from "../../../../../Supporting files/CheckBox/CheckBox";
import { GridCloseIcon } from "@mui/x-data-grid";
import "./../../../../../SlideModalOpen.scss";
import { useNavigate } from "react-router-dom";
import { ALL_ROUTES } from "../../../../../Routes/all_routes";
import SearchBox from "../../../../../Supporting files/SearchBarAnimation/SearchBox";

interface Props {
  open_status: boolean;
  attrName: any;
  value_update: Function;
  data_list?: any;
  component_name?: any;
  index_value?: any;
  productType?:any;
  setShowLoader: Function;
}

const Exclusion: React.FC<Props> = ({
  open_status,
  attrName,
  value_update,
  data_list,
  index_value,
  component_name,
  productType,
  setShowLoader,
}) => {
  const [addexclusion, setAddExclusion] = useState<any>([]);
  const [sendaddExclusion, setsendAddExclusion] = useState<any>([]);
  const navigate = useNavigate();
  const [searchBarFilter, setsearchBarFilter] = useState<string>("");

  const updateMasterStateArray = (attrName: any, value: any) => {
    if (attrName === "searchBarfilter") {
      setsearchBarFilter(value);
    }
    const array_data = [...addexclusion];

    if (attrName[0] === "value") {
      //array_data[attrName[0]].data[attrName[1]].value = value;
    } else if (attrName[1] === "checkbox_status") {
      array_data[attrName[0]].exclusion.check = value;
      //array_data[attrName[0]].data[attrName[1]].check = value;
    }
    setAddExclusion(array_data);

    let arr: any = [];
    array_data.map((el: any) =>
      el.exclusion.check === true
        ? arr.push({
          description: el.exclusion.description,
          exclusionId: el.exclusion.id,
          name: el.exclusion.title,
        })
        : null
    );

    setsendAddExclusion(component_name === "FLAT" ? arr : array_data);
  };


  const onSubmit = () => {
    value_update(["slidepanelfeatures"], false)
    if(sendaddExclusion.length > 0){
      value_update(["sendaddExclusion", component_name, index_value, "gradedExclusion"], sendaddExclusion);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setShowLoader && setShowLoader(false);
    }, 1000);
    if (component_name === "FLAT") {
      setAddExclusion(data_list);
    } else {
      let selectedAddPolicy: any = JSON.parse(
        localStorage.getItem("selectedAddPolicy") ?? ""
      );
      if (Array.isArray(selectedAddPolicy[0])) {
        setAddExclusion(selectedAddPolicy[index_value]);
      } else {
        setAddExclusion(selectedAddPolicy);
      }
    }
  }, [open_status]);

  const filteredData = Array.isArray(addexclusion)&&addexclusion.length > 0 && addexclusion?.filter((data: any) =>
    data?.exclusion?.title?.toLowerCase().includes(searchBarFilter.toLowerCase()) ||
    data?.exclusion?.description?.toLowerCase().includes(searchBarFilter.toLowerCase())
  );

  return (
    <SlidingPanel
      type={"right"}
      isOpen={open_status}
      size={75}
      panelClassName="slidingPanel"
      backdropClicked={() => value_update(attrName, false)}
    >
      <>
        <Grid container spacing={3} alignItems="center">
          <Grid xs={10} className="pt-3">
            <h4 className="mb-0">Exclusions</h4>
          </Grid>
          <Grid xs={2} textAlign="end" className="mt-2">
            <GridCloseIcon
              onClick={() => value_update(["slidepanelfeatures"], false)}
            />
          </Grid>
          <Grid xs={12} className="mr-3 pt-0">
            <hr className="mb-0" />
          </Grid>
        </Grid>
        {addexclusion.length > 0 ?
          <Grid container spacing={3} alignItems="center">
            <Grid xs={12}>
              <SearchBox value_update={updateMasterStateArray} fullWidth={true} />
            </Grid>
            <Grid xs={12} className="pt-0">
              <div className="scrollable_area">
                {Array.isArray(filteredData)&&filteredData?.map((data: any, index: any) => (
                  <div className={"coverage_section premium_section mr-3 mb-6 "}>
                    <Grid container spacing={3} style={{ alignItems: "center" }}>
                      <>
                        <Grid xs={12} className="checkbox_feature pt-6 pb-6">
                          <CheckBox
                            title={
                              <p>
                                {data?.exclusion?.title}
                                <span>{data?.exclusion?.description}</span>
                              </p>
                            }
                            name={index.toString() + index.toString()}
                            id={index.toString() + index.toString()}
                            value={addexclusion[index]?.exclusion?.check}
                            attrName={[index, "checkbox_status"]}
                            value_update={updateMasterStateArray}
                            default_check={false}
                          />
                        </Grid>
                        <Grid xs={12} className="mb-0 pt-0 pb-0"></Grid>
                      </>
                    </Grid>
                  </div>
                ))}
              </div>
            </Grid>

          <Grid xs={12} className="footer_sec">
            <Link className="save_btn" onClick={onSubmit}>
              Submit
            </Link>
          </Grid>
        </Grid>
        :<>
       <Alert severity="warning">
    Since there are currently no active features for <strong>{productType}</strong>,
    kindly add them in the Product Configurator module of Group Health
    Product Section. Thanks!
</Alert>
        <a
          onClick={() => {
            navigate(ALL_ROUTES.PRODUCT_CONFIG_GROUP_HEALTH);
          }}
        >
          Click here to add exclusion
        </a>
      </>}
      </>
    </SlidingPanel>
  );
};

export default Exclusion;
