import { Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useEffect, useState } from "react";
import { GridCloseIcon } from "@mui/x-data-grid";
import SlidingPanel from "react-sliding-side-panel";
import { toast } from "react-toastify";
import { AddCurrencyEntryDTO } from "../../../../../../Services/DTO/Settings/Organisation/CurrenciesDTO";
import { ECurrencyCode } from "../../../../../../Services/Enum/ECurrencyCode";
import { CURRENCIES_SERVICES } from "../../../../../../Services/Settings/OrganisationSettings/CurrenciesServices";
import { formatCurrencyAccToUser } from "../../../../../../Supporting files/HelpingFunction";
import RKTextField from "../../../../../../Supporting files/RKTextField/RKTextField";
import SearchDropdown from "../../../../../../Supporting files/SearchDropdown/SearchDropdown";
import SelectDropdown from "../../../../../../Supporting files/SelectDropdown/SelectDropdown";

interface Props {
  open_status: boolean;
  attrName: any;
  value_update: Function;
  add_cuurency_status: boolean;
  getCurrencyEntries: any;
  currencyDropdownData: any;
  existingValues?: {
    code: ECurrencyCode | null;
    createdBy: string;
    decimal: number;
    format: string;
    name: string;
    symbol: string;
    _id: string;
  };
}

const decimalPlaces_data = [
  { key: "1", value: "1" },
  { key: "2", value: "2" },
  { key: "3", value: "3" },
  { key: "4", value: "4" },
];

const AddCurrency: React.FC<Props> = ({
  open_status,
  attrName,
  value_update,
  add_cuurency_status,
  getCurrencyEntries,
  currencyDropdownData,
  existingValues,
}) => {
  const [disableSave, setDisableSave] = useState<boolean>(false);
  const [pageStatus, setPageStatus] = React.useState<number>(1);
  const [selectedCurrency, setSelectedCurrency] = useState<{
    currency: ECurrencyCode | null;
    currency_name: string;
    currency_symbol: string;
    id: string;
    label: string;
    iso2: string;
  }>({
    currency: null,
    currency_name: "",
    currency_symbol: "",
    id: "",
    label: "",
    iso2: "",
  });
  const [format, setFormat] = React.useState("");
  const [currencyWarn, setcurrencyWarn] = React.useState(false);
  const [decimalPlaces, setDecimalPlaces] = useState<number>(1);

  const [editMode, setEditMode] = useState<boolean>(false);

  useEffect(() => {
    setPageStatus(1);
    setSelectedCurrency({
      currency: null,
      currency_name: "",
      currency_symbol: "",
      id: "",
      label: "",
      iso2: "",
    });
    setFormat("");
  }, [open_status]);

  useEffect(() => {
    if (existingValues) {
      setEditMode(true);
      setFormat(existingValues.format);
      setDecimalPlaces(existingValues.decimal);
      setSelectedCurrency({
        currency: existingValues.code,
        currency_name: existingValues.name,
        currency_symbol: existingValues.symbol,
        id: existingValues._id,
        iso2: currencyDropdownData.filter((item: any) =>
          item.label.includes(existingValues.name)
        )[0]?.iso2,
        label:
          existingValues.name +
          " " +
          existingValues.code +
          " " +
          existingValues.symbol,
      });
    } else {
      setEditMode(false);
    }
  }, [existingValues]);

  const updateMasterState = (attrName: any, value: any) => {
    if (value.id === "") return;
    const calledFromSelectCurrency: boolean = attrName === setSelectedCurrency;
    if (attrName === setSelectedCurrency || attrName === setDecimalPlaces) {
      const formattedNum = formatCurrencyAccToUser(
        12345.6789,
        calledFromSelectCurrency ? value?.currency : selectedCurrency?.currency,
        calledFromSelectCurrency ? value?.iso2 : selectedCurrency?.iso2,
        Number(calledFromSelectCurrency ? decimalPlaces : value)
      );

      setFormat(formattedNum);
    }
    attrName(value);
  };

  const handleNewCurrencySave = () => {
    if (disableSave) return;
    if (!selectedCurrency.currency_name) {
      return setcurrencyWarn(true);
    }

    const onSuccess = () => {
      setPageStatus(3);
      getCurrencyEntries();
      setTimeout(() => {
        value_update(attrName, false);
      }, 1300);
      setDisableSave(false);
    };

    const onError = (err: any) => {
      setDisableSave(false);
      if (err?.response?.data?.status === "failed") {
        toast.error(err?.response?.data?.errors[0]?.message);
      } else {
        toast.error("Error while adding currency");
      }
    };

    const data: AddCurrencyEntryDTO = {
      code: selectedCurrency.currency,
      decimal: decimalPlaces,
      format: format,
      name: selectedCurrency.currency_name,
      symbol: selectedCurrency.currency_symbol,
    };
    setDisableSave(true);
    CURRENCIES_SERVICES.create_currency_entry(onSuccess, onError, data);
  };

  const handleEditCurrency = () => {
    if (disableSave) return;
    const onSuccess = () => {
      getCurrencyEntries();
      value_update(attrName, false);
      setDisableSave(false);
    };

    const onError = () => {
      setDisableSave(false);
      toast.error("Error while updating currency");
    };

    const data: AddCurrencyEntryDTO = {
      code: selectedCurrency.currency,
      decimal: decimalPlaces,
      format: format,
      name: selectedCurrency.currency_name,
      symbol: selectedCurrency.currency_symbol,
    };
    setDisableSave(true);
    CURRENCIES_SERVICES.update_currency_entry(
      onSuccess,
      onError,
      data,
      selectedCurrency.id
    );
  };

  return (
    <div>
      <SlidingPanel
        type={"right"}
        isOpen={open_status}
        size={75}
        panelClassName="slidingPanel"
        // backdropClicked={() => value_update(attrName, false)}
      >
        <div className="">
          <Grid container spacing={3} alignItems="center">
            <Grid xs={10}>
              {pageStatus === 1 ? (
                <h4 className="mb-2 mt-0 ml-0 text-left">
                  {editMode ? "Edit" : "New"} Currency
                </h4>
              ) : pageStatus === 2 ? (
                <h4 className="mb-0 mt-0 ml-0 text-left">
                  Add Exchange Rate - AED
                </h4>
              ) : null}
            </Grid>
            <Grid xs={2} textAlign="end" className="mt-3">
              <GridCloseIcon
                onClick={() => {
                  setcurrencyWarn(false);
                  value_update(attrName, false);
                }}
              />
            </Grid>
            <Grid xs={12} className="mb-5">
              <hr />
            </Grid>
          </Grid>
          <div className="scrollable_area">
            {pageStatus === 1 ? (
              <Grid container spacing={3}>
                <Grid xs={12}>
                  <>
                    <Grid container spacing={3}>
                      <Grid xs={12}>
                        <SearchDropdown
                          class_name="inputField"
                          title="Currency Name & Symbol"
                          value={selectedCurrency}
                          attrName={setSelectedCurrency}
                          value_update={updateMasterState}
                          data={currencyDropdownData}
                          warn_status={currencyWarn}
                          value_type_id_value={false}
                          disabled={editMode}
                          required
                        />
                      </Grid>

                      <Grid xs={12}>
                        <RKTextField
                          disabled
                          class_name="inputField"
                          title="Currency Code"
                          value={selectedCurrency.currency ?? ""}
                          attrName={setSelectedCurrency}
                          value_update={updateMasterState}
                          warn_status={false}
                        />
                      </Grid>

                      <Grid xs={12}>
                        <RKTextField
                          disabled
                          class_name="inputField"
                          title="Currency Symbol"
                          value={selectedCurrency.currency_symbol}
                          attrName={setSelectedCurrency}
                          value_update={updateMasterState}
                          warn_status={false}
                        />
                      </Grid>

                      <Grid xs={12}>
                        <SelectDropdown
                          class_name="inputField"
                          title="Decimal Places*"
                          value={decimalPlaces}
                          attrName={setDecimalPlaces}
                          value_update={updateMasterState}
                          dropdown_data={decimalPlaces_data}
                          warn_status={false}
                        />
                      </Grid>
                      <Grid xs={12}>
                        <RKTextField
                          disabled
                          class_name="inputField"
                          title="Format*"
                          value={format}
                          attrName={setFormat}
                          value_update={updateMasterState}
                          warn_status={false}
                        />
                      </Grid>
                      {/* {add_cuurency_status === false ? (
                      <Grid xs={12}>
                        <Link
                          underline="none"
                          className="greenBtn addfile ml-0"
                          onClick={() => {
                            //setAddCurrency(true);
                            setPageStatus(pageStatus + 1);
                          }}
                        >
                          Add Currency
                        </Link>
                      </Grid>
                    ) : null} */}
                    </Grid>
                  </>
                </Grid>
              </Grid>
            ) : null}
            {/* {pageStatus === 2 ? (
              <Grid container spacing={3} style={{ marginTop: "25px" }}>
                <Grid xs={12}>
                  <Grid container spacing={3}>
                    <Grid xs={12}>
                      <DatePicker
                        class_name="inputField"
                        title="Date of Birth"
                        value={Date}
                        attrName={setDate}
                        value_update={updateMasterState}
                        error_message="Please enter Date of Birth"
                        warn_status={false}
                      />
                    </Grid>
                    <Grid xs={12}>
                      <SelectDropdown
                        class_name="inputField"
                        title="Exchange Rate (in INR)"
                        value={exchangeRate}
                        attrName={setExchangeRate}
                        value_update={updateMasterState}
                        dropdown_data={exchangeRate_data}
                        warn_status={false}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ) : null} */}
            {pageStatus === 3 ? (
              <Grid
                container
                spacing={2}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <Grid xs={12}>
                  <svg
                    className="checkmark"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 52 52"
                  >
                    <circle
                      className="checkmark__circle"
                      cx="26"
                      cy="26"
                      r="25"
                      fill="none"
                    />
                    <path
                      className="checkmark__check"
                      fill="none"
                      d="M14.1 27.2l7.1 7.2 16.7-16.8"
                    />
                  </svg>
                  <p className="quote_success text-center">
                    New Currency has been added successfully!
                  </p>
                </Grid>
              </Grid>
            ) : null}
          </div>
          {pageStatus !== 3 ? (
            <Grid container spacing={3} style={{ paddingBottom: "15px" }}>
              <Grid xs={12} className="ctaBtn" style={{ textAlign: "center" }}>
                <Button
                  variant="contained"
                  className="submitBtn"
                  disabled={disableSave}
                  onClick={() => {
                    if (editMode) {
                      handleEditCurrency();
                    } else {
                      handleNewCurrencySave();
                    }
                  }}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          ) : null}
        </div>
      </SlidingPanel>
    </div>
  );
};

export default AddCurrency;
